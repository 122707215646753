/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import queryString from 'query-string';
import { BASE_URL } from 'config';

// TODO base url /api to /http

export const requestWithFiles = axios.create({
  baseURL: `${BASE_URL}/http`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  transformRequest: [
    (data) => {
      const newData = new FormData();
      if (data) {
        for (const prop in data) {
          if (Object.prototype.hasOwnProperty.call(data, prop)) {
            if (Array.isArray(data[prop]) && data[prop][0] instanceof File) {
              data[prop].forEach((file) => newData.append(prop, file));
            } else {
              newData.append(prop, data[prop]);
            }
          }
        }
      }
      return newData;
    },
  ],
  withCredentials: true,
  timeout: 300000,
});

const makeRequest = axios.create({
  baseURL: `${BASE_URL}/http`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  transformRequest: [(data) => queryString.stringify(data)],
  withCredentials: true,
  timeout: 60000,
});

const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response;
};

// interceptor for handling network errors
makeRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) return Promise.reject(error);

    if (isNetworkError(error)) {
      const newError = {
        response: {
          data: {
            error: {
              message: 'Błąd serwera - spróbuj ponownie za chwilę',
              status: '500',
            },
          },
        },
      };
      return Promise.reject(newError);
    }
    return Promise.reject(error);
  },
);

export default makeRequest;
