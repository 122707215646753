import { trainingService } from 'services/training';
import * as types from './types';

export const getTrainingMaterials = query => dispatch => {
  dispatch({ type: types.GET_TRAINING_MATERIALS_REQUEST });

  trainingService
    .getTrainingMaterials(query)
    .then(payload => {
      dispatch({ type: types.GET_TRAINING_MATERIALS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_TRAINING_MATERIALS_FAILURE, err });
    });
};

export const getTrainingMaterial = id => dispatch => {
  dispatch({ type: types.GET_TRAINING_MATERIAL_REQUEST });

  trainingService
    .getTrainingMaterial(id)
    .then(payload => {
      dispatch({ type: types.GET_TRAINING_MATERIAL_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_TRAINING_MATERIAL_FAILURE, err });
    });
};

export const onUploadProgress = progress => dispatch => {
  dispatch({ type: types.SET_TRAINING_MATERIAL_UPLOAD_PROGRESS, payload: progress });
};

export const setCurrentTrainingMaterial = material => dispatch => {
  dispatch({ type: types.SET_CURRENT_TRAINING_MATERIAL, payload: material });
};

export const clearCurrentTrainingMaterial = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_TRAINING_MATERIAL });
};

export const clearTraining = () => dispatch => {
  dispatch({ type: types.CLEAR_TRAINING });
};
