import { colors } from 'theme/styles';

export const SWIPE_X_PERC_OFFSET = 50;
export const SWIPE_Y_PERC_OFFSET = 30;

export const NUMBER_OF_DISPLAYED_ZIPCODES = 100;
export const TERMINAL_SPUID_BASE_URL = 'https://pep.pl/terminal-platniczy?spuid=';

export const marketingMaterialTypesOptions = [
  { display: 'Materiały marketingowe', value: 'ads' },
  { display: 'Banner reklamowy - partnerzy', value: 'banner' },
  { display: 'Banner reklamowy - przedstawiciele', value: 'banner-ph' },
];

export const trainingMaterialTypesOptions = [
  { display: 'Plik', value: 'file' },
  { display: 'Wideo', value: 'video' },
];

export const partnerCooperationOptions = [
  { display: 'Lead', value: 'LEAD' },
  { display: 'Instalacja terminala', value: 'TERMINAL' },
  { display: 'Podpisywanie umów', value: 'CONTRACT' },
];

export const partnerProgramOptions = [
  { display: 'Ogólnopolski', value: 'NATIONWIDE' },
  { display: 'Inny', value: 'OTHER' },
  { display: 'Kasiarze', value: 'CASHER' },
  { display: 'Digital', value: 'DIGITAL' },
  { display: 'Producent SOFT', value: 'PRODUCENT_SOFT' },
  { display: 'Ambasador', value: 'AMBASADOR' },
];

export const mapTypeToIcon = {
  pdf: 'file-pdf',
  zip: 'file-archive',
  image: 'file-image',
  jpeg: 'file-image',
  jpg: 'file-image',
  png: 'file-image',
  gif: 'file-image',
  bmp: 'file-image',
  default: 'file',
};

export const mapMimeTypeToIcon = {
  default: 'file',
  'application/pdf': 'file-pdf',
  'application/gzip': 'file-archive',
  'application/vnd.rar': 'file-archive',
  'application/x-tar': 'file-archive',
  'application/zip': 'file-archive',
  'application/x-7z-compressed': 'file-archive',
  'image/svg+xml': 'file-image',
  'image/jpeg': 'file-image',
  'image/png': 'file-image',
  'image/gif': 'file-image',
  'image/bmp': 'file-image',
  'text/css': 'file-code',
  'text/csv': 'file-csv',
  'application/msword': 'file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-word',
  'text/html': 'file-code',
  'text/javascript': 'file-code',
  'application/json': 'file',
  'audio/mpeg': 'file-audio',
  'video/mp4': 'file-video',
  'video/mpeg': 'file-video',
  'application/x-httpd-php': 'file-code',
  'application/vnd.ms-powerpoint': 'file-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'file-powerpoint',
  'text/plain': 'file',
  'audio/webm': 'file-audio',
  'video/webm': 'file-video',
  'image/webp': 'file-image',
  'application/vnd.ms-excel': 'file-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
  'application/xml': 'file-code',
  'text/xml': 'file-code',
};

export const filesSelect = [
  { display: 'Obraz', value: 'IMAGE', icon: mapTypeToIcon.image },
  { display: 'Pdf', value: 'PDF', icon: mapTypeToIcon.pdf },
  { display: 'Archiwum Zip', value: 'ZIP', icon: mapTypeToIcon.zip },
  { display: 'Inny', value: 'OTHER', icon: mapTypeToIcon.default },
];

export const provinces = [
  { display: 'Dolnośląskie', value: 'DOLNOSLASKIE', alts: ['DOLNOŚLĄSKIE'] },
  { display: 'Kujawsko-pomorskie', value: 'KUJAWSKO-POMORSKIE' },
  { display: 'Lubelskie', value: 'LUBELSKIE' },
  { display: 'Lubuskie', value: 'LUBUSKIE' },
  { display: 'Łódzkie', value: 'LODZKIE', alts: ['ŁÓDZKIE'] },
  { display: 'Małopolskie', value: 'MALOPOLSKIE', alts: ['MAŁOPOLSKIE'] },
  { display: 'Mazowieckie', value: 'MAZOWIECKIE' },
  { display: 'Opolskie', value: 'OPOLSKIE' },
  { display: 'Podkarpackie', value: 'PODKARPACKIE' },
  { display: 'Podlaskie', value: 'PODLASKIE' },
  { display: 'Pomorskie', value: 'POMORSKIE' },
  { display: 'Śląskie', value: 'SLASKIE', alts: ['ŚLĄSKIE'] },
  { display: 'Świętokrzyskie', value: 'SWIETOKRZYSKIE', alts: ['ŚWIĘTOKRZYSKIE'] },
  { display: 'Warmińsko-mazurskie', value: 'WARMINSKO-MAZURSKIE', alts: ['WARMIŃSKO-MAZURSKIE'] },
  { display: 'Wielkopolskie', value: 'WIELKOPOLSKIE' },
  { display: 'Zachodniopomorskie', value: 'ZACHODNIOPOMORSKIE' },
];

export const mapStatusToColor = {
  new: '#e8a838',
  Nowy: '#e8a838',

  taken: '#f1e15b',
  Podjęty: '#f1e15b',

  duplicate: '#e8c1a0',
  Zduplikowany: '#e8c1a0',

  rejected_pep: '#97e3d5',
  'Odrzucony - PeP': '#97e3d5',

  rejected_client: '#61cdbb',
  'Odrzucony - Klient': '#61cdbb',

  rejected_ph: '#615322',
  'Odrzucony - PH': '#615322',

  success: '#f47560',
  install_terminal: '#f47560',
  'Terminal zainstalowano': '#f47560',
  'Terminal zainst.': '#f47560',

  import: '#695ddf',
  Zaimportowany: '#695ddf',

  in_progress: '#6699ff',
  'W trakcie': '#6699ff',

  inactive: '#0099ff',
  Nieaktywny: '#0099ff',

  all: '#333333',
  Wszystkie: '#333333',
};

export const rolesOrder = {
  admin: 100,
  dsp: 90,
  dr: 80,
  skf: 75,
  merchantPep: 70,
  partner: 60,
  subPartner: 50,
};

export const RecommendationsStatusObj = {
  NEW: 'Nowy',
  DUPLICATE: 'Zduplikowany',
  IN_PROGRESS: 'W trakcie',
  REJECTED: 'Odrzucony',
  SUCCESS: 'Zrealizowany',
};

const statusObjDefault = {
  new: 'Nowy',
  failed: 'Nieudany',
  taken: 'Podjęty',
  duplicate: 'Zduplikowany',
  rejected: 'Odrzucony',
  rejected_pep: 'Odrzucony - PeP',
  rejected_client: 'Odrzucony - Klient',
  rejected_ph: 'Odrzucony - PH',
  calculation: 'Szacowany',
  import: 'Zaimportowany',
  in_progress: 'W trakcie',
  inactive: 'Nieaktywny',
};

export const mapStatusObj = {
  ...statusObjDefault,
  success: 'Terminal zainstalowano',
  install_terminal: 'Terminal zainstalowano',
};

export const mapStatusObjShort = {
  ...statusObjDefault,
  success: 'Terminal zainst.',
  install_terminal: 'Terminal zainst.',
  all: 'Wszystkie',
};

export const acceptCommonMimeTypes =
  'text/csv, text/html, text/plain, audio/mpeg, audio/ogg, audio/webm, video/ogg, video/mp2t, video/x-msvideo, video/mpeg, video/webm, image/webp, image/bmp, image/gif, image/jpeg, image/png, image/svg+xml, image/tiff, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-bzip, application/x-bzip2, application/vnd.rar, application/x-tar, application/zip, application/x-7z-compressed, application/xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/gzip, application/json, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const bannersMimeTypes = 'image/gif, image/jpeg, image/png, image/svg+xml';
export const trainingsMimeTypes = 'image/tiff, image/jpeg, image/png, application/pdf';
export const commonFilesMimeTypes =
  'application/x-bzip, application/x-bzip2, application/vnd.rar, application/x-tar, application/zip, application/x-7z-compressed, application/pdf, image/gif, image/jpeg, image/png, image/svg+xml';
export const integratinOrdersAttachmentsMimeTypes = 'image/tiff, image/jpeg, image/png, application/pdf';
export const leadMerchantFormStep5MimeTypes = 'image/tiff, image/jpeg, image/png';

export const translateProps = {
  agreement: 'zgoda',
  clientId: 'ID klienta',
  closedAt: 'Zarchiwizowano',
  comment: 'Komentarz',
  companyName: 'Nazwa firmy',
  contactPerson: 'Osoba kontaktowa',
  conversationId: 'ID konwersacji',
  createdAt: 'Zgłoszony',
  deletedAt: 'Zarchiwizowano',
  frontNumLead: 'Lead ID',
  userCompanyName: 'Partner',
  id: 'ID lead',
  nip: 'Nip',
  krs: 'Krs',
  phone: 'Telefon',
  postCode: 'Kod pocztowy',
  potentialPOS: 'Potencjał docelowy',
  preferredContactDate: 'Czas kontaktu',
  products: 'Produkty',
  taken: 'Podjęty',
  leadMaster: 'Leadmaster',
  queue: 'Przypisany',
  status: 'Status',
  takenDate: 'Data podjęcia',
  takenUser: 'Podjęty przez',
  updatedAt: '',
  userId: 'ID Użytkownik',
  crmAccount: 'Konto w CRM',
  crmAccountId: 'Id konta w CRM',
  crmPh: 'Przedstawiciel w CRM',
  crmPhId: 'ID przedstawiciela w CRM',
  coordinator: 'Faktury',
  mainPartner: 'Partner główny',
  name: 'Nazwa',
  fullName: 'Imię i nazwisko',
  active: 'Aktywny',
  cooperation: 'Typ współpracy',
  partnerProgram: 'Kanał partnerski',
  numLocal: 'Numer lokalu',
  numBuilding: 'Numer budynku',
  harvesting: 'Osoba pozyskująca',
  street: 'Ulica',
  city: 'Miasto',
  invoiceAccess: 'Dostęp do fakturowania',
  leadMasterAssoc: 'Leadmaster Assoc',
  companyId: 'Id Partnera (firmy)',
  avatar: 'Zdjęcie awatara',
  surname: 'Nazwisko',
  structureId: 'Id struktury',
  roleId: 'Id roli',
  dedicatedPhs: 'Przedstawiciele dedykowani ID',
  dedicatedPhAssoc: 'Przedstawiciele dedykowani',
};

export const historyTypes = {
  API_TOKEN: {
    _TYPE: 'API_TOKEN',
    display: 'api token',
  },
  WEB_USER: {
    _TYPE: 'WEB_USER',
    display: 'aplikacja web',
  },
  SPUID: {
    _TYPE: 'SPUID',
    display: 'spuid',
  },
  SYSTEM: {
    _TYPE: 'SYSTEM',
    display: 'System',
  },
};

export const historyActions = {
  RE_ADD_LEAD: {
    _TYPE: 'RE_ADD_LEAD',
    display: 'Ponowienie leada',
  },
  AUTOMATIC_CREATE_MERCHANT_CRM_PEP: {
    _TYPE: 'AUTOMATIC_CREATE_MERCHANT_CRM_PEP',
    display: 'Automatyczna rejestracja w CRM',
  },
  // Client
  ACLAS_SEND_ORDER: {
    _TYPE: 'ACLAS_SEND_ORDER',
    display: 'Wysyłka leada do firmy Aclas',
  },
  RESEND_PROCESSED_UNSUCCESSFULLY_CRM_PEP_FORM: {
    _TYPE: 'RESEND_PROCESSED_UNSUCCESSFULLY_CRM_PEP_FORM',
    display: 'Ponowne wysłanie formularza do CRM - błąd',
  },
  RESEND_PROCESSED_SUCCESSFULLY_CRM_PEP_FORM: {
    _TYPE: 'RESEND_PROCESSED_SUCCESSFULLY_CRM_PEP_FORM',
    display: 'Ponowne wysłanie formularza do CRM - poprawnie',
  },
  PROCESSED_UNSUCCESSFULLY_CRM_PEP_FORM: {
    _TYPE: 'PROCESSED_UNSUCCESSFULLY_CRM_PEP_FORM',
    display: 'Formularz został wysłany do CRM - błąd',
  },
  PROCESSED_SUCCESSFULLY_CRM_PEP_FORM: {
    _TYPE: 'PROCESSED_SUCCESSFULLY_CRM_PEP_FORM',
    display: 'Formularz został wysłany do CRM - poprawnie',
  },
  CHANGE_STATUS_CRM_PEP_FORM: {
    _TYPE: 'CHANGE_STATUS_CRM_PEP_FORM',
    display: 'Zmiana statusu przez CRM dla formularza',
  },
  DETACH_CRM_FROM_LEAD: {
    _TYPE: 'DETACH_CRM_FROM_LEAD',
    display: 'Ręczne odpięcie leada od CRM',
  },
  AUTO_TAKE_LEAD_BY_SYSTEM: {
    _TYPE: 'AUTO_TAKE_LEAD_BY_SYSTEM',
    display: 'Automatyczne podjęcie systemowe',
  },
  RESET_CRM_FIELDS: {
    _TYPE: 'RESET_CRM_FIELDS',
    display: 'Odpięcie konta w PeP CRM',
  },
  CREATE_MERCHANT_CRM_PEP: {
    _TYPE: 'CREATE_MERCHANT_CRM_PEP',
    display: 'Rejestracja w PeP CRM',
  },
  ADD_LEAD: {
    _TYPE: 'ADD_LEAD',
    display: 'Dodano leada',
    icon: 'plus-circle',
  },
  EDIT_LEAD: {
    _TYPE: 'EDIT_LEAD',
    display: 'Edycja leada',
    icon: 'edit',
  },
  MANUAL_ASSIGNED_LEAD: {
    _TYPE: 'MANUAL_ASSIGNED_LEAD',
    display: 'Ręczna zmiana  przedstawiciela',
  },
  AUTOMATIC_ASSIGNED_LEAD: {
    _TYPE: 'AUTOMATIC_ASSIGNED_LEAD',
    display: 'Automatyczne przypisanie przedstawiciela',
  },
  TAKEN_LEAD: {
    _TYPE: 'TAKEN_LEAD',
    display: 'Podjęcie leada',
  },
  REASSIGN_LEAD: {
    _TYPE: 'REASSIGN_LEAD',
    display: 'Zmiana przedstawiciela',
  },
  CRON_PEP_CRM_UPDATE_STATUS: {
    _TYPE: 'CRON_PEP_CRM_UPDATE_STATUS',
    display: 'Cron - aktualizacja statusów',
  },
  CRON_REPAIR_GHOST_LEAD: {
    _TYPE: 'CRON_REPAIR_GHOST_LEAD',
    display: 'Cron - weryfikacja opuszczonych leadów',
  },
  CRON_TELESPRZEDAZ_AUTO_TAKE: {
    _TYPE: 'CRON_TELESPRZEDAZ_AUTO_TAKE',
    display: 'Cron - automatyczne podejmowanie',
  },
  TRANSFER_LEAD: { _TYPE: 'TRANSFER_LEAD', display: 'Transfer leada' },
  REASON_DUPLICATION: { _TYPE: 'REASON_DUPLICATION', display: 'Powód duplikacji' },
  REJECT_LEAD_PH: { _TYPE: 'REJECT_LEAD_PH', display: 'Odrzucenie przez przedstawiciela' },
  // Partner
  CREATED_COMPANY: {
    _TYPE: 'CREATED_COMPANY',
    display: 'Dodano partnera',
  },
  EDIT_COMPANY: {
    _TYPE: 'EDIT_COMPANY',
    display: 'Edycja partnera',
  },
  DEACTIVATION_COMPANY: {
    _TYPE: 'DEACTIVATION_COMPANY',
    display: 'Deaktywacja partnera',
  },
  RESTORE_COMPANY: {
    _TYPE: 'RESTORE_COMPANY',
    display: 'Aktywacja partnera',
  },
  TRANSFER_USER_COMPANY: {
    _TYPE: 'TRANSFER_USER_COMPANY',
    display: 'Transfer użytkownika',
  },
  EXPORT_LEADS_BY_USER: {
    _TYPE: 'EXPORT_LEADS_BY_USER',
    display: 'Eksport leadów przez użytkownika',
  },
  EDIT_PROFIL_USER_COMPANY: {
    _TYPE: 'EDIT_PROFIL_USER_COMPANY',
    display: 'Edycja profilu użytkownika',
  },
  EDIT_AVATAR_USER_COMPANY: {
    _TYPE: 'EDIT_AVATAR_USER_COMPANY',
    display: 'Edycja awarata użytkownika',
  },
  RESET_PASSWORD_USER_COMPANY: {
    _TYPE: 'RESET_PASSWORD_USER_COMPANY',
    display: 'Reset hasła użytkownika',
  },
  RESET_PASSWORD_CONFIRM_USER_COMPANY: {
    _TYPE: 'RESET_PASSWORD_CONFIRM_USER_COMPANY',
    display: 'Potwierdzenie resetu hasła użytkownika',
  },
  CHANGE_PASSWORD_USER_COMPANY: {
    _TYPE: 'CHANGE_PASSWORD_USER_COMPANY',
    display: 'Zmiana hasła z poziomu profilu użytkownika',
  },
  CREATED_USER_BULK_IMPORT: {
    _TYPE: 'CREATED_USER_BULK_IMPORT',
    display: 'Grupowy import użytkowników',
  },
};

export const duplicateTypes = [
  { value: 'DEFAULT', display: 'Podstawowy', icon: 'check-circle' },
  { value: 'SIMPLE', display: 'Uproszczony', icon: 'info-circle' },
];

export const invoiceStatus = [
  { value: 'NEW', display: 'Nowy' },
  { value: 'SAVED', display: 'Zapisany' },
  { value: 'ACCEPTED', display: 'Zatwierdzony' },
  { value: 'REJECTED', display: 'Odrzucony' },
];

export const specialPartners = [
  { id: 'ca0f9b6e-4e2e-42bb-907f-b88d41ea0daa', display: 'Telesprzedaż' },
  { id: '9b4ec718-07ad-4f68-b045-7013289ea349', display: 'Instalacja Terminali' },
];

export const integrationOrdersStatuses = [
  { id: 1, name: 'ZAREJESTROWANE', display: 'Zarejestrowane' },
  { id: 2, name: 'ZLECONE DO REALIZACJI', display: 'Zlecone do realizacji' },
  { id: 3, name: 'PODJĘTE', display: 'Podjęte' },
  { id: 4, name: 'ZREALIZOWANE', display: 'Zrealizowane', color: colors.greenText },
  { id: 5, name: 'ANULOWANE', display: 'Anulowane', color: colors.red },
  { id: 6, name: 'ODRZUCONE', display: 'Odrzucone', color: colors.red },
];

export const integrationOrdersTypes = [
  { id: 1, name: 'Type 1', display: 'Typ 1' },
  { id: 2, name: 'Type 2', display: 'Typ 2' },
  { id: 3, name: 'Type 3', display: 'Typ 3' },
];

export const notificationsTypes = [
  { name: 'DEFAULT', display: 'Ogólne', icon: 'envelope-open-text' },
  { name: 'INFO', display: 'Informacyjne', icon: 'info' },
  { name: 'SUCCESS', display: 'Potwierdzenia', icon: 'check' },
  { name: 'WARNING', display: 'Ostrzeżenia', icon: 'question' },
  { name: 'ERROR', display: 'Błędy', icon: 'exclamation' },
];

export const MessagesListTypes = {
  newLead: 'NEW_LEAD',
  newPatron: 'NEW_PATRON',
  active: 'ACTIVE',
};

export const mapCronSlugToOptions = {
  ALL: {},
  GENERATE_ANALYSIS_PH_LEADS: {
    phIds: {
      type: 'array',
      format: 'id',
      heading: 'Lista ignorowanych przedstawicieli',
      fetchUsers: 'merchantPep',
      label: 'Szukaj przedstawiciela',
    },
    // name: {
    //   type: 'text',
    //   format: 'string',
    //   heading: 'Inne opcje',
    //   label: 'Imie',
    // },
  },
};
1;
export const chatModes = {
  normal: 'normal',
  archive: 'archive',
};

export const p24OffersOptions = [
  { display: '1.29% + 0,30zł', value: '264779' },
  { display: '0.99% + 0,30zł', value: '264783' },
];

export const nationalities = [
  { id: 'AF', display: 'Afganistan' },
  { id: 'AL', display: 'Albania' },
  { id: 'DZ', display: 'Algieria' },
  { id: 'AD', display: 'Andora' },
  { id: 'AO', display: 'Angola' },
  { id: 'AG', display: 'Antigua i Barbuda' },
  { id: 'SA', display: 'Arabia Saudyjska' },
  { id: 'AR', display: 'Argentyna' },
  { id: 'AM', display: 'Armenia' },
  { id: 'AW', display: 'Aruba' },
  { id: 'AU', display: 'Australia' },
  { id: 'AT', display: 'Austria' },
  { id: 'AZ', display: 'Azerbejdżan' },
  { id: 'BS', display: 'Bahamy' },
  { id: 'BH', display: 'Bahrajn' },
  { id: 'BD', display: 'Bangladesz' },
  { id: 'BB', display: 'Barbados' },
  { id: 'BE', display: 'Belgia' },
  { id: 'BZ', display: 'Belize' },
  { id: 'BJ', display: 'Benin' },
  { id: 'BT', display: 'Bhutan' },
  { id: 'BY', display: 'Białoruś' },
  { id: 'BO', display: 'Boliwia' },
  { id: 'BA', display: 'Bośnia i Hercegowina' },
  { id: 'BW', display: 'Botswana' },
  { id: 'BR', display: 'Brazylia' },
  { id: 'BN', display: 'Brunei' },
  { id: 'BG', display: 'Bułgaria' },
  { id: 'BF', display: 'Burkina Faso' },
  { id: 'BI', display: 'Burundi' },
  { id: 'CL', display: 'Chile' },
  { id: 'CN', display: 'Chiny' },
  { id: 'HR', display: 'Chorwacja' },
  { id: 'CW', display: 'Curaçao' },
  { id: 'CY', display: 'Cypr' },
  { id: 'TD', display: 'Czad' },
  { id: 'ME', display: 'Czarnogóra' },
  { id: 'CZ', display: 'Czechy' },
  { id: 'DK', display: 'Dania' },
  { id: 'CD', display: 'Demokratyczna Republika Konga' },
  { id: 'DM', display: 'Dominika' },
  { id: 'DO', display: 'Dominikana' },
  { id: 'DJ', display: 'Dżibuti' },
  { id: 'EG', display: 'Egipt' },
  { id: 'EC', display: 'Ekwador' },
  { id: 'ER', display: 'Erytrea' },
  { id: 'EE', display: 'Estonia' },
  { id: 'SZ', display: 'Eswatini (Suasi)' },
  { id: 'ET', display: 'Etiopia' },
  { id: 'FJ', display: 'Fidżi' },
  { id: 'PH', display: 'Filipiny' },
  { id: 'FI', display: 'Finlandia' },
  { id: 'FR', display: 'Francja' },
  { id: 'GA', display: 'Gabon' },
  { id: 'GM', display: 'Gambia' },
  { id: 'GH', display: 'Ghana' },
  { id: 'GR', display: 'Grecja' },
  { id: 'GD', display: 'Grenada' },
  { id: 'GE', display: 'Gruzja' },
  { id: 'GY', display: 'Gujana' },
  { id: 'GT', display: 'Gwatemala' },
  { id: 'GN', display: 'Gwinea' },
  { id: 'GW', display: 'Gwinea Bissau' },
  { id: 'GQ', display: 'Gwinea Równikowa' },
  { id: 'HT', display: 'Haiti' },
  { id: 'ES', display: 'Hiszpania' },
  { id: 'NL', display: 'Holandia' },
  { id: 'HN', display: 'Honduras' },
  { id: 'IN', display: 'Indie' },
  { id: 'ID', display: 'Indonezja' },
  { id: 'IQ', display: 'Irak' },
  { id: 'IR', display: 'Iran' },
  { id: 'IE', display: 'Irlandia' },
  { id: 'IS', display: 'Islandia' },
  { id: 'IL', display: 'Izrael' },
  { id: 'JM', display: 'Jamajka' },
  { id: 'JP', display: 'Japonia' },
  { id: 'YE', display: 'Jemen' },
  { id: 'JE', display: 'Jersey' },
  { id: 'JO', display: 'Jordania' },
  { id: 'KH', display: 'Kambodża' },
  { id: 'CM', display: 'Kamerun' },
  { id: 'CA', display: 'Kanada' },
  { id: 'QA', display: 'Katar' },
  { id: 'KZ', display: 'Kazachstan' },
  { id: 'KE', display: 'Kenia' },
  { id: 'KG', display: 'Kirgistan' },
  { id: 'KI', display: 'Kiribati' },
  { id: 'CO', display: 'Kolumbia' },
  { id: 'KM', display: 'Komory' },
  { id: 'CG', display: 'Kongo' },
  { id: 'KR', display: 'Korea Południowa' },
  { id: 'KP', display: 'Korea Północna' },
  { id: 'XK', display: 'Kosowo' },
  { id: 'CR', display: 'Kostaryka' },
  { id: 'CU', display: 'Kuba' },
  { id: 'KW', display: 'Kuwejt' },
  { id: 'LA', display: 'Laos' },
  { id: 'LS', display: 'Lesotho' },
  { id: 'LB', display: 'Liban' },
  { id: 'LR', display: 'Liberia' },
  { id: 'LY', display: 'Libia' },
  { id: 'LI', display: 'Liechtenstein' },
  { id: 'LT', display: 'Litwa' },
  { id: 'LU', display: 'Luksemburg' },
  { id: 'LV', display: 'Łotwa' },
  { id: 'MK', display: 'Macedonia Północna' },
  { id: 'MG', display: 'Madagaskar' },
  { id: 'MW', display: 'Malawi' },
  { id: 'MV', display: 'Malediwy' },
  { id: 'MY', display: 'Malezja' },
  { id: 'ML', display: 'Mali' },
  { id: 'MT', display: 'Malta' },
  { id: 'MA', display: 'Maroko' },
  { id: 'MR', display: 'Mauretania' },
  { id: 'MU', display: 'Mauritius' },
  { id: 'MX', display: 'Meksyk' },
  { id: 'FM', display: 'Mikronezja' },
  { id: 'MM', display: 'Mjanma (Birma)' },
  { id: 'MD', display: 'Mołdawia' },
  { id: 'MC', display: 'Monako' },
  { id: 'MN', display: 'Mongolia' },
  { id: 'MZ', display: 'Mozambik' },
  { id: 'NA', display: 'Namibia' },
  { id: 'NR', display: 'Nauru' },
  { id: 'NP', display: 'Nepal' },
  { id: 'DE', display: 'Niemcy' },
  { id: 'NE', display: 'Niger' },
  { id: 'NG', display: 'Nigeria' },
  { id: 'NI', display: 'Nikaragua' },
  { id: 'NO', display: 'Norwegia' },
  { id: 'NZ', display: 'Nowa Zelandia' },
  { id: 'OM', display: 'Oman' },
  { id: 'PK', display: 'Pakistan' },
  { id: 'PW', display: 'Palau' },
  { id: 'PS', display: 'Palestyna' },
  { id: 'PA', display: 'Panama' },
  { id: 'PG', display: 'Papua-Nowa Gwinea' },
  { id: 'PY', display: 'Paragwaj' },
  { id: 'PE', display: 'Peru' },
  { id: 'PL', display: 'Polska' },
  { id: 'PT', display: 'Portugalia' },
  { id: 'ZA', display: 'Republika Południowej Afryki' },
  { id: 'CF', display: 'Republika Środkowoafrykańska' },
  { id: 'CV', display: 'Republika Zielonego Przylądka' },
  { id: 'RU', display: 'Rosja' },
  { id: 'RO', display: 'Rumunia' },
  { id: 'RW', display: 'Rwanda' },
  { id: 'EH', display: 'Sahara Zachodnia' },
  { id: 'KN', display: 'Saint Kitts i Nevis' },
  { id: 'LC', display: 'Saint Lucia' },
  { id: 'VC', display: 'Saint Vincent i Grenadyny' },
  { id: 'SV', display: 'Salwador' },
  { id: 'WS', display: 'Samoa' },
  { id: 'SM', display: 'San Marino' },
  { id: 'SN', display: 'Senegal' },
  { id: 'RS', display: 'Serbia' },
  { id: 'SC', display: 'Seszele' },
  { id: 'SL', display: 'Sierra Leone' },
  { id: 'SG', display: 'Singapur' },
  { id: 'SX', display: 'Sint Maarten' },
  { id: 'SK', display: 'Słowacja' },
  { id: 'SI', display: 'Słowenia' },
  { id: 'SO', display: 'Somalia' },
  { id: 'LK', display: 'Sri Lanka' },
  { id: 'US', display: 'Stany Zjednoczone' },
  { id: 'SD', display: 'Sudan' },
  { id: 'SS', display: 'Sudan Południowy' },
  { id: 'SR', display: 'Surinam' },
  { id: 'SY', display: 'Syria' },
  { id: 'CH', display: 'Szwajcaria' },
  { id: 'SE', display: 'Szwecja' },
  { id: 'TJ', display: 'Tadżykistan' },
  { id: 'TH', display: 'Tajlandia' },
  { id: 'TW', display: 'Tajwan' },
  { id: 'TZ', display: 'Tanzania' },
  { id: 'TL', display: 'Timor Wschodni' },
  { id: 'TG', display: 'Togo' },
  { id: 'TO', display: 'Tonga' },
  { id: 'TT', display: 'Trynidad i Tobago' },
  { id: 'TN', display: 'Tunezja' },
  { id: 'TR', display: 'Turcja' },
  { id: 'TM', display: 'Turkmenistan' },
  { id: 'TV', display: 'Tuvalu' },
  { id: 'UG', display: 'Uganda' },
  { id: 'UA', display: 'Ukraina' },
  { id: 'UY', display: 'Urugwaj' },
  { id: 'UZ', display: 'Uzbekistan' },
  { id: 'VU', display: 'Vanuatu' },
  { id: 'VA', display: 'Watykan' },
  { id: 'VE', display: 'Wenezuela' },
  { id: 'HU', display: 'Węgry' },
  { id: 'GB', display: 'Wielka Brytania' },
  { id: 'VN', display: 'Wietnam' },
  { id: 'IT', display: 'Włochy' },
  { id: 'CI', display: 'Wybrzeże Kości Słoniowej' },
  { id: 'IM', display: 'Wyspa Man' },
  { id: 'MH', display: 'Wyspy Marshalla' },
  { id: 'SB', display: 'Wyspy Salomona' },
  { id: 'ST', display: 'Wyspy Świętego Tomasza i Książęca' },
  { id: 'ZM', display: 'Zambia' },
  { id: 'ZW', display: 'Zimbabwe' },
  { id: 'AE', display: 'Zjednoczone Emiraty Arabskie' },
];
