import { integrationOrdersService } from 'services/integrationOrders';
import * as types from './types';

export const getIntegrationOrders = query => dispatch => {
  dispatch({ type: types.GET_INTEGRATION_ORDERS_REQUEST });

  integrationOrdersService
    .getIntegrationOrders(query)
    .then(payload => {
      dispatch({ type: types.GET_INTEGRATION_ORDERS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_INTEGRATION_ORDERS_FAILURE, err });
    });
};

export const getIntegrationOrderDetails = id => dispatch => {
  dispatch({ type: types.GET_INTEGRATION_ORDER_DETAILS_REQUEST });

  integrationOrdersService
    .getIntegrationOrderDetails(id)
    .then(payload => {
      dispatch({ type: types.GET_INTEGRATION_ORDER_DETAILS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_INTEGRATION_ORDER_DETAILS_FAILURE, err });
    });
};

export const setIntegrationOrdersError = error => dispatch => {
  dispatch({ type: types.SET_INTEGRATION_ORDERS_ERROR, payload: error });
};

export const setCurrentIntegrationOrder = order => dispatch => {
  dispatch({ type: types.SET_CURRENT_INTEGRATION_ORDER, payload: order });
};

export const setCurrentIntegrationOrderError = error => dispatch => {
  dispatch({ type: types.SET_CURRENT_INTEGRATION_ORDER_ERROR, payload: error });
};

export const setCurrentIntegrationOrderLoading = state => dispatch => {
  dispatch({ type: types.SET_CURRENT_INTEGRATION_ORDER_LOADING, payload: state });
};

export const clearCurrentIntegrationOrder = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_INTEGRATION_ORDER });
};

export const clearIntegrationOrdersError = () => dispatch => {
  dispatch({ type: types.CLEAR_INTEGRATION_ORDERS_ERROR });
};

export const clearCurrentIntegrationOrderError = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_INTEGRATION_ORDER_ERROR });
};

export const clearIntegrationOrders = () => dispatch => {
  dispatch({ type: types.CLEAR_INTEGRATION_ORDERS_REDUCER });
};
