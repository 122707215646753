import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllProducts } from 'redux/product';
import { breakpoints } from 'theme/styles';
// import { logout } from 'redux/user';
import { getPepStructures } from 'redux/admin';
import { productService } from 'services/product';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import SidePanel from 'components/layout/SidePanel';
import ProductsTable from 'components/layout/Tables/ProductsTable';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import { TopSection } from 'components/layout/Tables/TableComponents';
import SuccessPopup from 'components/layout/SuccessPopup';
import AddProduct from 'components/forms/Admin/AddProduct';
import EditProduct from 'components/forms/Admin/EditProduct';

const headers = [
  { displayName: 'Lista produktów Polskich ePłatności', property: 'name' },
  // { displayName: 'Stawka jednorazowa', property: 'oneTimeFee' },
  // { displayName: 'Stawka cykliczna', property: 'cyclicFee' },
  { displayName: 'Opiekun sprzedażowy', property: 'ospObj' },
  { displayName: 'Aktywny', property: 'active' },
  { displayName: 'Widoczny\nna formularzu', property: 'isVisible' },
  { displayName: 'Ogranicz do\nAmbasador', property: 'ambassadorVisible' },
  { displayName: 'Ogranicz do\nProducent soft', property: 'softProducentVisible' },
  { displayName: 'Ogranicz do\nKasiarze', property: 'casherVisible' },
];

const AdminProductsPage = ({
  getAllProducts,
  getPepStructures,
  productsData,
  isLoading,
  error,
  // logout
}) => {
  const [productsOrder, setProductsOrder] = useState(null);
  const [editOrderMode, setEditOrderMode] = useState(false);
  const [isNewProductVisible, setNewProductVisible] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [deleteProductId, setDeleteProductId] = useState('');
  const [deleteProductError, setDeleteProductError] = useState('');

  useEffect(() => {
    getAllProducts();
    getPepStructures();
  }, [getAllProducts, getPepStructures]);

  const toggleEditOrder = () => setEditOrderMode(state => !state);

  const toggleNewProductPanel = () => {
    setNewProductVisible(!isNewProductVisible);
  };

  const openEditProductPanel = id => {
    const selectedProduct = productsData.find(item => item.id === id);
    setEditProduct(selectedProduct);
  };

  const closeEditProductPanel = () => {
    setEditProduct(null);
  };

  const handleDelete = id => {
    productService
      .deleteProduct(id)
      .then(() => {
        setDeleteProductId('');
        getAllProducts();
      })
      .catch(() => console.log(setDeleteProductError('Błąd usuwania produktu')));
  };

  const handleUpdateOrder = () => {
    if (!productsOrder) return;
    productService
      .updateOrder(JSON.stringify(productsOrder))
      .then(() => {
        getAllProducts();
      })
      .catch(error => console.log(error));
  };

  const orderButtonHandler = () => {
    if (editOrderMode) handleUpdateOrder();
    toggleEditOrder();
  };

  const showDeletePopup = id => {
    const productName = productsData.find(product => product.id === id).name;
    return (
      <SuccessPopup fixed>
        <span>Czy na pewno chcesz usunąć produkt {productName}?</span>
        <div className="buttons">
          <Button secondary onClick={() => setDeleteProductId('')}>
            Anuluj
          </Button>
          <Button onClick={() => handleDelete(id)}>Usuń</Button>
        </div>
        {deleteProductError !== '' && (
          <span style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>
            {deleteProductError}
          </span>
        )}
      </SuccessPopup>
    );
  };

  const showErrorPopup = error => <ErrorPopup fixed>{error.message}</ErrorPopup>;

  return (
    <PageTemplate>
      <StyledTopSection style={{ alignItems: 'flex-start' }}>
        <Button onClick={toggleNewProductPanel}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj produkt
        </Button>
        <Button onClick={orderButtonHandler} secondary gray={!editOrderMode}>
          <FontAwesomeIcon icon="sort" style={{ marginRight: '6px' }} />
          {editOrderMode ? 'Zapisz' : 'Edytuj kolejność'}
        </Button>
      </StyledTopSection>
      {!isLoading && error && showErrorPopup(error)}
      {isLoading ? (
        <Loading />
      ) : (
        <ProductsTable
          handleEdit={openEditProductPanel}
          handleDelete={setDeleteProductId}
          data={productsData}
          headers={headers}
          editOrderMode={editOrderMode}
          setOrder={setProductsOrder}
        />
      )}
      {deleteProductId !== '' && showDeletePopup(deleteProductId)}
      <SidePanel small toggleClose={closeEditProductPanel} isVisible={editProduct !== null}>
        {editProduct !== null && (
          <EditProduct
            refetchProducts={getAllProducts}
            handleClose={closeEditProductPanel}
            productData={editProduct}
          />
        )}
      </SidePanel>
      <SidePanel small toggleClose={toggleNewProductPanel} isVisible={isNewProductVisible}>
        <AddProduct refetchProducts={getAllProducts} />
      </SidePanel>
    </PageTemplate>
  );
};

AdminProductsPage.defaultProps = {};

AdminProductsPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  productsData: PropTypes.instanceOf(Array).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getPepStructures: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.products.isLoading,
  productsData: state.products.data,
  error: state.products.error,
});

const mapDispatchToProps = {
  getAllProducts,
  getPepStructures,
  // logout,
};

const StyledTopSection = styled(TopSection)`
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.mobileTiny}) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdminProductsPage);
