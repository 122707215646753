import styled from 'styled-components';
import {colors } from 'theme/styles';
import closeIcon from 'assets/icons/close.svg';

export default styled.button`
  width: 28px;
  height: 28px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${colors.orange};
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 60% 60%;
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 15px;
`;
