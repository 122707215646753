import { collaborationService } from 'services/collaboration';
import * as types from './types';

export const getRecommendedUsers = query => dispatch => {
  dispatch({ type: types.GET_ALL_RECOMMENDED_USERS_REQUEST });
  return collaborationService
    .getRecommendedUsers(query)
    .then(payload => dispatch({ type: types.GET_ALL_RECOMMENDED_USERS_SUCCESS, payload }))
    .catch(err => dispatch({ type: types.GET_ALL_RECOMMENDED_USERS_FAILURE, err }));
};

export const getRecommendedUser = id => dispatch => {
  dispatch({ type: types.GET_RECOMMENDED_USER_REQUEST });
  return collaborationService
    .getRecommendedUser(id)
    .then(payload => {
      dispatch({ type: types.GET_RECOMMENDED_USER_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_RECOMMENDED_USER_FAILURE, err });
    });
};

export const setCurrentRecommendedUser = user => dispatch => {
  dispatch({ type: types.SET_CURRENT_RECOMMENDED_USER, payload: user });
};

export const clearCurrentRecommendedUser = () => dispatch =>
  dispatch({ type: types.CLEAR_CURRENT_RECOMMENDED_USER });

export const clearCollaboration = () => dispatch => dispatch({ type: types.CLEAR_RECOMMENDED_STATE });
