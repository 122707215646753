import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import NoData from 'components/UI/NoData';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';

const ChallengesTable = ({ headers, data, offset, handleEdit, handleDelete }) => {
  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => (
              <col key={header.property} className={header.property} />
            ))}
            <col className="actions" />
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {data.map((challenge, index) => (
              <tr key={challenge.id}>
                <td>{offset + index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = challenge.id + header.property;
                  switch (header.property) {
                    case 'dateFrom':
                    case 'dateTo':
                      return (
                        <td key={uniqueKey}>
                          {moment(challenge[header.property]).format('YYYY-MM-DD')}
                        </td>
                      );
                    case 'special':
                      return (
                        <td key={uniqueKey}>
                          {challenge.special ? <FontAwesomeIcon title="Wyzwanie specjalne" icon="check" /> : '-'}
                        </td>
                      );
                    default:
                      return <td key={uniqueKey}>{challenge[header.property]}</td>;
                  }
                })}
                <td key="edit">
                  <span className="icons">
                    <FontAwesomeIcon title="Edytuj" icon="edit" onClick={() => handleEdit(challenge)} />
                    <FontAwesomeIcon title="Usuń" icon="trash-alt" onClick={() => handleDelete(challenge)} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData message="Brak wyzwań" />
      )}
    </TableWrapper>
  );
};

ChallengesTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ChallengesTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;
    text-align: center;

    @media (min-width: 940px) {
      col.actions {
        width: 140px;
      }
    }

    tr {
      th:first-child,
      td:first-child {
        text-align: left;
        padding-left: 20px;
      }

      th,
      td {
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};

          &:last-child {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 40px;

        &:nth-of-type(even) {
          background-color: ${colors.white};
        }

        td {
          font-size: ${font.size.m};
          text-transform: none;
          color: ${colors.black};

          & > svg {
            color: ${colors.orange};
          }

          & > .icons {
            display: inline-block;
            min-width: 60px;
            color: ${colors.orange};
            svg {
              cursor: pointer;
              margin: 0 7px;
            }
          }
        }
      }
    }
  }
`;
