import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { copyToClipboard } from 'utils/helpers';
import { font, colors } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LinkBox = ({ link, title }) => {
  const [clickedIcon, setClickedIcon] = useState(false);

  const handleCopy = text => {
    setClickedIcon(true);
    setTimeout(() => setClickedIcon(null), 1500);
    copyToClipboard(text);
  };

  return (
    <StyledLinkBox>
      <span>{title}</span>
      <p>{link}</p>
      <StyledCopy clickedIcon={clickedIcon} onClick={() => handleCopy(link)}>
        <FontAwesomeIcon icon={clickedIcon ? 'clipboard-check' : 'clipboard'} />
      </StyledCopy>
    </StyledLinkBox>
  );
};

LinkBox.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LinkBox;

const StyledLinkBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 2px solid ${colors.blueGray};
  width: 100%;

  span {
    position: absolute;
    top: -2px;
    left: 14px;
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    background-color: ${colors.white};
    font-size: ${font.size.s};
    color: ${colors.mediumGray};
    transform: translateY(-50%);
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    margin: 0;
    flex: 1;
    font-size: ${font.size.s};
    padding: 1rem 2rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const StyledCopy = styled.div`
  padding: 4px 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: ${colors.blueGray};
  cursor: pointer;

  & > svg {
    font-size: 1em;
    color: ${({ clickedIcon }) => (clickedIcon ? colors.orange : colors.mediumGray)};
  }
`;
