import React, { useEffect, useCallback, useState } from 'react';
// import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  clearIntegrationOrdersError,
  getIntegrationOrders,
  clearIntegrationOrders,
  setIntegrationOrdersError,
} from 'redux/integrationOrders';
import {
  integrationOrdersStatuses, 
  // integrationOrdersTypes
} from 'config/constants';
import { removeNullsAndEmpty } from 'utils/helpers';
import { useUrl } from 'utils/hooks';
import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import usePagination from 'hooks/usePagination';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import IntegrationOrdersTable from 'components/layout/Tables/IntegrationOrdersTable';
import Pagination from 'components/UI/Pagination';
import ErrorPopup from 'components/layout/ErrorPopup';
import Heading from 'components/UI/Heading';
import SearchInput from 'components/UI/SearchInput';
import { routes } from 'routes';
import { integrationOrdersService } from 'services/integrationOrders';
import SuccessPopup from 'components/layout/SuccessPopup';
import DateFromTo from 'components/UI/DateFromTo';
import { colors } from 'theme/styles';

const getHeadersByRole = role =>
  [
    {
      displayName: 'Data',
      property: 'data_zlecenia',
      type: 'date-time',
      style: { whiteSpace: 'nowrap' },
      // advancedSearch: 'data_zlecenia',
      // advancedSearchPlaceholder: 'YYYY-MM-DD',
    },
    { displayName: 'Typ', property: 'typ_zlecenia', advancedSearch: 'typ_zlecenia'},
    { displayName: 'Numer zlecenia', property: 'numer_zlecenia', advancedSearch: 'numer_zlecenia' },
    ['admin', 'dsp', 'dr', 'skf'].includes(role) && {
      displayName: 'Nazwa partnera',
      property: 'nazwa_partnera',
      advancedSearch: 'nazwa_partnera',
    },
    ['admin', 'dsp', 'dr', 'skf'].includes(role) && {
      displayName: 'NIP partnera',
      property: 'nip_partnera',
      advancedSearch: 'nip_partnera',
    },
    { displayName: 'Terminal', property: 'terminal', advancedSearch: 'terminal' },
    { displayName: 'NIP', property: 'nip', advancedSearch: 'nip' },
    { displayName: 'Status', property: 'status_zlecenia' },
  ].filter(Boolean);

const IntegrationOrdersPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [askForAction, setAskForAction] = useState(false);

  const { pagination, handlePerPage, setPage, setPagination } = usePagination();

  const [active, setActive] = useUrl('active', '1');
  const [status, setStatus] = useUrl('status');
  // const [type, setType] = useUrl('type');
  const [sortDirect, setSortDirect] = useUrl('sortDirect', 'DESC');
  const [date, setDate] = useUrl('date');
  const [search, setSearch] = useUrl('search');

  const user = useSelector(s => s.user.data);
  const userRole = user?.role?.slug;

  const ordersData = useSelector(s => s.orders.integrationOrders.data.rows);
  const ordersPagination = useSelector(s => s.orders.integrationOrders.data.pagination);
  const count = useSelector(s => s.orders.integrationOrders.data.count);
  const isLoading = useSelector(s => s.orders.integrationOrders.isLoading);
  const error = useSelector(s => s.orders.integrationOrders.error);

  const fetchOrdersCb = useCallback(() => {
    const urlBasedParams = queryString.parse(location.search);

    const query = removeNullsAndEmpty({
      ...urlBasedParams,
      limit: pagination.perPage,
      page: pagination.page,
      active,
      sortBy: 'data_zlecenia',
    });

    dispatch(getIntegrationOrders(query));
  }, [dispatch, active, location.search, pagination.page, pagination.perPage]);

  useEffect(() => {
    if (!user) return;
    if (['admin', 'dsp', 'dr', 'skf'].includes(userRole)) return;
    if (!user.canInstallTerminal) history.replace(routes.leads);
  });

  useEffect(() => {
    fetchOrdersCb();
    return () => dispatch(clearIntegrationOrders());
  }, [dispatch, fetchOrdersCb]);

  // Update paginations state based on redux
  useEffect(() => {
    setPagination(prevPag => ({
      ...prevPag,
      ...ordersPagination,
      count,
    }));
  }, [count, ordersPagination, setPagination]);

  const handleClick = item => {
    history.push(routes.integrationOrderDetails.replace(':id', item.zlecenie_id));
  };

  const handleAction = () => {
    let statusId = false;

    if (!askForAction) return;

    if (askForAction.type === 'accept') statusId = 3;
    else if (askForAction.type === 'reject') statusId = 6;

    if (!statusId) return;

    integrationOrdersService
      .updateIntegrationOrderStatus(askForAction.id, statusId)
      .then(res => {
        console.log(res);
        fetchOrdersCb();
      })
      .catch(err => {
        console.log(err);
        dispatch(setIntegrationOrdersError(err.error.message || 'Coś poszło nie tak'));
        setAskForAction(false);
      })
      .finally(() => setAskForAction(false));
  };

  const handleAccept = item => {
    setAskForAction({
      id: item.zlecenie_id,
      type: 'accept',
    });
  };

  const handleReject = item => {
    setAskForAction({
      id: item.zlecenie_id,
      type: 'reject',
    });
  };

  const handleCanSeeAction = item => {
    if (!['partner', 'subPartner'].includes(userRole)) return false;
    return item.status_id === 2 ? true : false || false;
  };

  return (
    <PageTemplate>
      {askForAction && (
        <SuccessPopup fixed style={{ textAlign: 'center' }}>
          <Heading
            centered
            style={{ marginBottom: '20px', color: askForAction.type === 'accept' ? colors.greenText : colors.red }}
          >
            Czy jesteś pewien, że chcesz {askForAction.type === 'accept' ? 'zaakceptować' : 'odrzucić'} zgłoszenie
          </Heading>
          <div className="buttons">
            <Button secondary onClick={handleAction}>
              {askForAction.type === 'accept' ? 'Zaakceptuj' : 'Odrzuć'}
            </Button>
            <Button onClick={() => setAskForAction(false)}>Anuluj</Button>
          </div>
        </SuccessPopup>
      )}
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error?.message || error || 'Coś poszło nie tak'}</p>
          <div className="buttons">
            <Button onClick={() => dispatch(clearIntegrationOrdersError())}>Zamknij</Button>
          </div>
        </ErrorPopup>
      )}
      <TopSection>
        <TableSorter>
          {/* <SortItem>
            <span>Typ</span>
            <Select small value={type || ''} onChange={e => setType(e.target.value)}>
              <option value="">&nbsp;</option>
              {integrationOrdersTypes.map(t => (
                <option key={t.id} value={t.id}>
                  {t.display}
                </option>
              ))}
            </Select>
          </SortItem> */}
          <SortItem>
            <span>Status</span>
            <Select small value={status || ''} onChange={e => setStatus(e.target.value)}>
              <option value="">&nbsp;</option>
              {integrationOrdersStatuses.map(s => (
                <option key={s.id} value={s.id}>
                  {s.display}
                </option>
              ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={sortDirect || ''} onChange={e => setSortDirect(e.target.value)}>
              <option value="DESC">Najnowsze</option>
              <option value="ASC">Najstarsze</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Zrealizowane i anulowane</span>
            <Select small value={active || ''} onChange={e => setActive(e.target.value)}>
              <option value="1">Ukryj</option>
              <option value="0">Pokaż</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Okres</span>
            <DateFromTo handleDate={setDate} date={date} />
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput
              handleSearch={val => {
                setSearch(val);
                setPage(1);
              }}
              value={search}
            />
          </SortItem>
        </TableSorter>
      </TopSection>
      <IntegrationOrdersTable
        headers={getHeadersByRole(userRole)}
        data={ordersData}
        handleRowClick={handleClick}
        isLoading={isLoading}
        offset={(pagination.currentPage - 1) * (pagination.perPage || 20)}
        actions={[
          {
            title: 'Akceptuj',
            icon: 'check',
            color: colors.greenText,
            handler: handleAccept,
            canSeeAction: handleCanSeeAction,
          },
          {
            title: 'Odrzuć',
            icon: 'times',
            color: colors.red,
            handler: handleReject,
            canSeeAction: handleCanSeeAction,
          },
        ]}
        actionsAllowed={order => order.status_id === 2}
        hideActions={['admin', 'dsp', 'dr', 'skf'].includes(userRole)}
      />
      <BottomSection>
        <BottomItem>Elementów: {count}</BottomItem>
        <Pagination allowPageSet currentPage={pagination.currentPage} maxPages={pagination.totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={pagination.perPage} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
    </PageTemplate>
  );
};

export default IntegrationOrdersPage;
