import makeRequest from 'services/makeRequest';

const GET_SYSTEM_MESSAGES_URL = '/chat/admin-group-conversations';
const DOWNLOAD_WS_ATTACHMENT_URL = '/ws-chat/download-attachment';

// GET Archiwizacja konwersacji: http/ws-chat/archive-user-conversation/:id-konwersacji
const ARCHIVE_WS_CONVERSATION_URL = '/ws-chat/archive-user-conversation';
// GET Unarchiwizacja konwersacji http/ws-chat/unarchive-user-conversation/id-konwersacji
const UNARCHIVE_WS_CONVERSATION_URL = '/ws-chat/unarchive-user-conversation';


const archiveWsConversation = conversationId =>
  makeRequest({
    method: 'get',
    url: `${ARCHIVE_WS_CONVERSATION_URL}/${conversationId}`,
  });

const unarchiveWsConversation = conversationId =>
  makeRequest({
    method: 'get',
    url: `${UNARCHIVE_WS_CONVERSATION_URL}/${conversationId}`,
  });

const downloadAttachment = messageId =>
  makeRequest({
    method: 'get',
    url: `${DOWNLOAD_WS_ATTACHMENT_URL}/${messageId}`,
    responseType: 'blob',
  });

const getSystemMessages = () =>
  makeRequest({
    method: 'get',
    url: GET_SYSTEM_MESSAGES_URL,
  });

  
export const chatService = {
  getSystemMessages,
  downloadAttachment,
  archiveWsConversation,
  unarchiveWsConversation,
};
