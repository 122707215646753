// const noFunctions = ['Skarbnik Gminy', 'Skarbnik Powiatu'];

export const functionsArr = [
  { display: 'Właściciel', pep_slug: 'Wlasciciel', value: 1 },
  { display: 'Współwłaściciel', pep_slug: 'Wspolwlasciciel', value: 2 },
  { display: 'Prezes', pep_slug: 'Prezes', value: 3 },
  { display: 'Wiceprezes', pep_slug: 'Wiceprezes', value: 4 },
  { display: 'Członek zarządu', pep_slug: 'Czlonek_zarzadu', value: 5 },
  { display: 'Prokurent', pep_slug: 'Prokurent', value: 6 },
  { display: 'Dyrektor generalny', pep_slug: 'Dyrektor_generalny', value: 7 },
  { display: 'Pełnomocnik', pep_slug: 'Pelnomocnik', value: 8 },
  { display: 'Dyrektor finansowy', pep_slug: 'Dyrektor_finansowy', value: 9 },
  { display: 'Główny księgowy', pep_slug: 'Glowny_ksiegowy', value: 10 },
  { display: 'Inna', pep_slug: 'Inna', value: 11 },
  //   Nowe
  { display: 'Administrator Parafii', pep_slug: 'Administrator_Parafii', value: 12, legacyValue: 11 },
  { display: 'Burmistrz', pep_slug: 'Burmistrz', value: 13, legacyValue: 11 },
  // Decyzja o usunięciu, zostawiam dla informacji
  // { display: 'Członek Zarządu', pep_slug: 'Czlonek_Zarzadu', value: 14, legacyValue: 11 },
  { display: 'Członek Zarządu Powiatu', pep_slug: 'Czlonek_Zarzadu_Powiatu', value: 15, legacyValue: 11 },
  { display: 'Członek Zarządu Województwa', pep_slug: 'Czlonek_Zarzadu_Wojewodztwa', value: 16, legacyValue: 11 },
  { display: 'Dyrektor', pep_slug: 'Dyrektor', value: 17, legacyValue: 11 },
  { display: 'Fundator', pep_slug: 'Fundator', value: 18, legacyValue: 11 },
  { display: 'Kierownik', pep_slug: 'Kierownik', value: 20, legacyValue: 11 },
  { display: 'Komendant', pep_slug: 'Komendant', value: 21, legacyValue: 11 },
  { display: 'Komplementariusz', pep_slug: 'Komplementariusz', value: 22, legacyValue: 11 },
  { display: 'Marszałek Województwa', pep_slug: 'Marszalek_Wojewodztwa', value: 23, legacyValue: 11 },
  { display: 'Naczelnik', pep_slug: 'Naczelnik', value: 24, legacyValue: 11 },
  { display: 'Nadleśniczy', pep_slug: 'Nadlesniczy', value: 25, legacyValue: 11 },
  { display: 'Partner', pep_slug: 'Partner', value: 26, legacyValue: 11 },
  { display: 'Pastor', pep_slug: 'Pastor', value: 27, legacyValue: 11 },
  { display: 'Prezes Sądu', pep_slug: 'Prezes_Sadu', value: 29, legacyValue: 11 },
  { display: 'Prezes Zarządu', pep_slug: 'Prezes_Zarzadu', value: 30, legacyValue: 3 },
  { display: 'Prezydent miasta', pep_slug: 'Prezydent_miasta', value: 31, legacyValue: 11 },
  { display: 'Proboszcz', pep_slug: 'Proboszcz', value: 32, legacyValue: 11 },
  { display: 'Prokurator', pep_slug: 'Prokurator', value: 33, legacyValue: 11 },
  { display: 'Skarbnik', pep_slug: 'Skarbnik', value: 35, legacyValue: 11 },
  { display: 'Starosta Powiatu', pep_slug: 'Starosta_Powiatu', value: 36, legacyValue: 11 },
  { display: 'Wiceprezes Zarządu', pep_slug: 'Wiceprezes_Zarzadu', value: 37, legacyValue: 4 },
  { display: 'Wójt', pep_slug: 'Wojt', value: 39, legacyValue: 11 },
  { display: 'Wspólnik', pep_slug: 'Wspolnik', value: 40, legacyValue: 11 },
  { display: 'Zastępca Dyrektora', pep_slug: 'Zastepca_Dyrektora', value: 42, legacyValue: 11 },
  { display: 'Zastępca Kierownika', pep_slug: 'Zastepca_Kierownika', value: 43, legacyValue: 11 },
  { display: 'Zastępca Wójta', pep_slug: 'Zastepca_Wojta', value: 44, legacyValue: 11 },
];

const defaultRelations = { minOwners: 0, maxOwners: 0, minSignatory: 1, maxSignatory: 99999 };

export const functionToIdMap = new Map();
functionsArr.forEach(person => functionToIdMap.set(person.value, person));

const matrixArr = [
  {
    name: 'Działalność gospodarcza',
    value: 9,
    oldValue: 6,
    legalFormGusCodes: [9, 99],
    functionId: 1,
    functionsIds: [1, 8],
    relations: { minOwners: 1, maxOwners: 1, minSignatory: 1, maxSignatory: 1 },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółdzielnia',
    value: 140,
    oldValue: 7,
    legalFormGusCodes: [140, 142, 180],
    functionId: 5,
    functionsIds: [5, 8, 30],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Fundacja',
    value: 148,
    oldValue: null,
    legalFormGusCodes: [148],
    functionId: 5,
    functionsIds: [5, 8, 18, 30],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Gmina',
    value: 429,
    oldValue: null,
    legalFormGusCodes: [429],
    functionId: 8,
    functionsIds: [8, 10, 35, 39, 44],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Inne kościoły i związki wyznaniowe',
    value: 51,
    oldValue: null,
    legalFormGusCodes: [51],
    functionId: 8,
    functionsIds: [8, 27],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Kościół Katolicki',
    value: 50,
    oldValue: null,
    legalFormGusCodes: [50],
    functionId: 8,
    functionsIds: [8, 12, 32],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Województwo',
    value: 431,
    oldValue: null,
    legalFormGusCodes: [431],
    functionId: 8,
    functionsIds: [8, 10, 16, 23, 35],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Wspólnota mieszkaniowa',
    value: 85,
    oldValue: null,
    legalFormGusCodes: [85],
    functionId: 5,
    functionsIds: [2, 5, 8, 30],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Jednostka samorządu terytorialnego nieposiadająca osobowości prawnej',
    value: 100100,
    oldValue: null,
    legalFormGusCodes: [44, 49, 53, 80, 146, 381, 383, 384, 385, 386, 387, 393, 395, 397, 403, 439, 999],
    functionId: 17,
    functionsIds: [10, 17, 20, 21, 24, 25, 35, 42, 43],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Powiat',
    value: 430,
    oldValue: null,
    legalFormGusCodes: [430],
    functionId: 8,
    functionsIds: [8, 15, 35, 36],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Skarb Państwa',
    value: 409,
    oldValue: null,
    legalFormGusCodes: [124, 132, 141, 161, 165, 401, 402, 406, 409, 428],
    functionId: 17,
    functionsIds: [8, 17, 24, 25, 29, 33],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółka akcyjna',
    value: 116,
    oldValue: 4,
    legalFormGusCodes: [116, 122, 126, 134, 135, 136, 171, 179],
    functionId: 5,
    functionsIds: [5, 6, 8, 30],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółka cywilna',
    value: 19,
    oldValue: 1,
    legalFormGusCodes: [19, 23],
    functionId: 40,
    functionsIds: [40],
    relations: { minOwners: 0, maxOwners: 0, minSignatory: 2, maxSignatory: 99999 },
    isSignatoryAgreementRequired: true,
    agreement: true,
  },
  {
    name: 'Spółka z o.o.',
    value: 117,
    oldValue: 3,
    legalFormGusCodes: [117],
    functionId: 5,
    functionsIds: [5, 6, 8, 30],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółka jawna',
    value: 118,
    oldValue: 2,
    legalFormGusCodes: [114, 118],
    functionId: 40,
    functionsIds: [6, 8, 40],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółka Partnerska',
    value: 115,
    oldValue: null,
    legalFormGusCodes: [115],
    functionId: 26,
    functionsIds: [8, 26],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółka komandytowa',
    value: 100001,
    oldValue: null,
    legalFormGusCodes: [120],
    functionId: 22,
    functionsIds: [1, 2, 5, 6, 8, 10, 13, 17, 22, 26, 27, 29, 30, 31, 32, 35, 37, 39, 40],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółka komandytowo-akcyjna',
    value: 100002,
    oldValue: null,
    legalFormGusCodes: [121],
    functionId: 5,
    functionsIds: [1, 2, 5, 6, 8, 10, 13, 17, 22, 26, 27, 29, 30, 31, 32, 35, 37, 39, 40],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
  {
    name: 'Spółka komandytowa i komandytowo-akcyjna, w których komplementariuszem jest spółka z o.o.',
    value: 100003,
    oldValue: null,
    legalFormGusCodes: [120, 121],
    functionId: 5,
    functionsIds: [1, 2, 5, 6, 8, 10, 13, 17, 22, 26, 27, 29, 30, 31, 32, 35, 37, 39, 40],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
    subOption: true,
    subOptionForCodes: [120, 121, 100001, 100002],
  },
  {
    name: 'Spółka komandytowa i komandytowo-akcyjna, w których komplementariuszem jest osoba fizyczna',
    value: 100004,
    oldValue: null,
    legalFormGusCodes: [120, 121],
    functionId: 5,
    functionsIds: [1, 2, 5, 6, 8, 10, 13, 17, 22, 26, 27, 29, 30, 31, 32, 35, 37, 39, 40],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
    subOption: true,
    subOptionForCodes: [120, 121, 100001, 100002],
  },
  {
    name: 'Spółka komandytowa i komandytowo-akcyjna, w których komplementariuszem jest np. spółka jawna',
    value: 100005,
    oldValue: null,
    legalFormGusCodes: [120, 121],
    functionId: 5,
    functionsIds: [1, 2, 5, 6, 8, 10, 13, 17, 22, 26, 27, 29, 30, 31, 32, 35, 37, 39, 40],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
    subOption: true,
    subOptionForCodes: [120, 121, 100001, 100002],
  },
  {
    name: 'Stowarzyszenie',
    value: 155,
    oldValue: null,
    legalFormGusCodes: [55, 60, 70, 76, 133, 137, 138, 143, 145, 147, 152, 154, 155, 156, 157, 158, 159, 160, 162, 163, 164, 166, 167, 168, 169, 172, 174, 175, 177, 178, 181, 182, 183, 184, 382, 388, 389, 390, 391, 392, 394, 396, 398],
    functionId: 5,
    functionsIds: [5, 8, 30],
    relations: { ...defaultRelations },
    isSignatoryAgreementRequired: true,
    agreement: false,
  },
];

export default matrixArr;
