/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOutside from 'hooks/useOutside';
import { colors, font } from 'theme/styles';

const Dropdown = ({ children, width, ...rest }) => {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);

  useOutside(wrapperRef, () => setOpen(false));

  return (
    <Wrapper ref={wrapperRef} {...rest} contentWidth={width}>
      <IconWrapper onClick={() => setOpen(p => !p)}>
        <FontAwesomeIcon icon="ellipsis-h" />
      </IconWrapper>
      {open && (
        <ItemsWrapper onClick={() => setOpen(false)} width={width}>
          {children}
        </ItemsWrapper>
      )}
    </Wrapper>
  );
};

Dropdown.defaultProps = {
  width: 220,
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  width: PropTypes.number,
};

const IconWrapper = styled.div`
  display: inline-block;
  border-radius: 50%;
  color: ${colors.textBlack};
  padding: 0 0.5rem;
  transform: color 0.3s;
  position: relative;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 110%;
    padding-top: 110%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    border-radius: 50%;
    z-index: -1;
  }

  &:hover {
    color: ${colors.orange};
  }

  &:hover::before {
    background-color: ${colors.lighterGray};
  }
`;

const ItemsWrapper = styled.div`
  width: ${({ width }) => `${width}px`};
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0.6rem 0.4rem;
  border: 1px solid ${colors.lightGray};
  user-select: none;
  background-color: ${colors.white};
  z-index: 200;
  border-radius: 4px;
  box-shadow: 0px 3px 15px ${colors.black}30;
`;

const Item = styled.div`
  padding: 0.6rem 1rem;
  font-size: ${font.size.s};
  cursor: pointer;
  color: ${({ color }) => color || 'inherit'};

  &:hover {
    background-color: ${colors.blueGray};
  }

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${colors.greenText};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}

  & + & {
    border-top: 2px solid ${colors.lighterGray};
  }

`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;

  ${({ forwardedRef, contentWidth }) =>
    forwardedRef?.current?.offsetLeft < contentWidth &&
    css`
      ${ItemsWrapper} {
        left: 0;
        right: initial;
      }
    `}
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${colors.lightGray};
  margin: 0.4rem 0;
`;

Dropdown.Item = Item;
Dropdown.Divider = Divider;
export default Dropdown;
