export default class Place {
  constructor({
    streetName = '',
    buildingNumber = '',
    apartmentNumber = '',
    city = '',
    zipCode = '',
    country = '',
    voivodeship = '',
    county = '',
    community = '',
    sameAs = true,
  }) {
    this.zipCode = zipCode;
    this.city = city;
    this.streetName = streetName;
    this.buildingNumber = buildingNumber;
    this.apartmentNumber = apartmentNumber;
    this.country = country;
    this.voivodeship = voivodeship;
    this.county = county;
    this.community = community;
    this.sameAs = sameAs;
  }
}
