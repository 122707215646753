import { userService } from 'services/user';
import { clearAdmin } from 'redux/admin';
import { clearChat } from 'redux/chat';
import { clearLeads } from 'redux/lead';
import { clearProducts } from 'redux/product';
import { clearPartner } from 'redux/partner';
import * as types from './types';

export const getUserInfo = () => dispatch => {
  dispatch({ type: types.GET_ME_REQUEST });

  return userService
    .getMe()
    .then(payload => {
      dispatch({ type: types.GET_ME_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_ME_FAILURE, err });
    });
};

export const updateUser = data => dispatch => {
  dispatch({ type: types.UPDATE_REQUEST });

  return userService
    .update(data)
    .then(payload => {
      dispatch({ type: types.UPDATE_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.UPDATE_FAILURE, err });
    });
};

export const authenticate = (email, password) => dispatch => {
  dispatch({ type: types.AUTH_REQUEST });

  return userService
    .login(email, password)
    .then(() => {
      dispatch({ type: types.AUTH_SUCCESS });
      dispatch(getUserInfo());
    })
    .catch(err => {
      dispatch({ type: types.AUTH_FAILURE, err });
    });
};

export const logout = () => dispatch => {
  dispatch({ type: types.LOGOUT_REQUEST });

  dispatch(clearAdmin());
  dispatch(clearChat());
  dispatch(clearLeads());
  dispatch(clearProducts());
  dispatch(clearPartner());

  document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';

  return userService
    .logout()
    .then(() => {
      dispatch({ type: types.LOGOUT_SUCCESS });
    })
    .catch(() => {
      dispatch({ type: types.LOGOUT_SUCCESS });
    });
};
