import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import { TERMINAL_SPUID_BASE_URL } from 'config/constants';
import { colors } from 'theme/styles';
import Heading from 'components/UI/Heading';
import LinkBox from 'components/UI/LinkBox';
import SimpleButton from 'components/UI/SimpleButton';
// import LeadsImport from 'components/layout/LeadsImport';

const loadImage = async url => {
  const img = document.createElement('img');
  img.src = url;
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
};

const UserIntegrations = () => {
  const user = useSelector(s => s.user.data);

  const downloadPng = async (format = 'png') => {
    const svg = document.getElementById('QRCode');
    const svgAsXML = new XMLSerializer().serializeToString(svg);
    const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`;
    const img = await loadImage(svgData);
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    canvas.getContext('2d').drawImage(img, 0, 0, 512, 512);
    const dataURL = await canvas.toDataURL(`image/${format}`, 1.0);
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'strefa-qr.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadSvg = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgData]);
    const element = document.createElement('a');
    element.download = 'strefa-qr.svg';
    element.href = window.URL.createObjectURL(blob);
    element.click();
    element.remove();
  };

  if (!['partner', 'subPartner'].includes(user.role?.slug)) return null;

  return (
    <>
      <Heading size="xl">Integracje</Heading>
      <div style={{ margin: '20px 0' }}>
        <LinkBox link={TERMINAL_SPUID_BASE_URL + user.id} title="Twój indywidualny link do zbierania leadów" />
      </div>
      <div>
        <QRWrapper>
          <QRCodeSVG id="QRCode" value={TERMINAL_SPUID_BASE_URL + user.id} includeMargin />
        </QRWrapper>
        <QRButtonsWrapper>
          <SimpleButton onClick={() => downloadPng()}>Pobierz PNG</SimpleButton>
          <SimpleButton onClick={() => downloadSvg()}>Pobierz SVG</SimpleButton>
        </QRButtonsWrapper>
      </div>
    </>
  );
};

export default UserIntegrations;

// styled components
const QRWrapper = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid ${colors.blueLighter};
  margin-bottom: 2rem;
`;

const QRButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
`;
