import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
// import { isMobile } from 'react-device-detect';
import { colors } from 'theme/styles';
import { provinces, RecommendationsStatusObj } from 'config/constants';
import { recommendationSchema } from 'utils/validation';
import { collaborationService } from 'services/collaboration';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';

import Loading from 'components/UI/Loading';
import { FormWrapperCommon, Columns, FieldHeading, FormColumn } from 'components/forms/FormComponents';

const RecommendationForm = ({ toggleClose, initialValues, editMode, adminMode, refetch }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { collaborationRegulationUrl } = useSelector((s) => s.options.data);

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleClose = (resetForm) => {
    setSuccess(false);
    setError('');
    resetForm({});
    toggleClose();
  };

  const handleSubmit = (values) => {
    let copyValues;

    if (adminMode) {
      copyValues = {
        status: values.status,
      };
    } else {
      copyValues = { ...values };
      delete copyValues.consent;
      delete copyValues.regulations;
      delete copyValues.status;
    }

    setError('');
    setLoading(true);

    const thenFn = (data) => {
      setSuccess(
        data.data.status === 'duplicate'
          ? 'NIP znajduje się w systemach PeP. Skontaktuj się z opiekunem'
          : 'Zobaczysz go w liście swoich leadów',
      );
      setLoading(false);
      refetch();
    };

    const catchFn = (err) => {
      console.log(err);
      setError(err.response.data.error.message);
      setLoading(false);
    };

    if (initialValues.id) {
      collaborationService.updateRecommendedUser(initialValues.id, copyValues).then(thenFn).catch(catchFn);
    } else {
      collaborationService.createRecommendedUser(copyValues).then(thenFn).catch(catchFn);
    }
  };

  const {
    companyName = '',
    nip = '',
    phone = '',
    contactPerson = '',
    city = '',
    voivodeship = '',
    status = '',
  } = initialValues;

  let { regulations = false, consent = false } = initialValues;

  if (editMode) {
    regulations = true;
    consent = true;
  }

  return (
    <Wrapper>
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Dodaj polecenie</Heading>
      <Formik
        initialValues={{
          companyName,
          nip,
          phone,
          contactPerson,
          city,
          voivodeship,
          regulations,
          consent,
          status,
        }}
        validationSchema={recommendationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>{editMode ? 'Zaktualizowano polecenie' : 'Dodałeś potencjalnego partnera'}</Heading>
                <p>{success}</p>
                <div className="buttons">
                  {!adminMode && <Button onClick={() => handleReturn(true, resetForm)}>Dodaj kolejne</Button>}
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>{editMode ? 'Błąd podczas aktualizacji' : 'Błąd podczas dodawania partnera'}</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                {editMode
                  ? ' Zaktualizuj dane partnera'
                  : ' Wypełnij dane potencjalnego partnera, którego chcesz zgłosić'}
              </FieldHeading>
              <Columns>
                <FormColumn>
                  <Input
                    disabled={adminMode}
                    name="companyName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nazwa Firmy"
                    value={values.companyName || ''}
                    error={touched.companyName && errors.companyName}
                  />
                  <ErrorMessage component={ErrorLabel} name="companyName" />
                  <Input
                    disabled={adminMode}
                    name="nip"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer NIP"
                    value={values.nip || ''}
                    error={touched.nip && errors.nip}
                  />
                  <ErrorMessage component={ErrorLabel} name="nip" />
                  <Input
                    disabled={adminMode}
                    name="phone"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer Telefonu"
                    value={values.phone || ''}
                    error={touched.phone && errors.phone}
                  />
                  <ErrorMessage component={ErrorLabel} name="phone" />
                </FormColumn>
                <FormColumn>
                  <Input
                    disabled={adminMode}
                    name="contactPerson"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Osoba Kontaktowa"
                    value={values.contactPerson || ''}
                    error={touched.contactPerson && errors.contactPerson}
                  />
                  <ErrorMessage component={ErrorLabel} name="contactPerson" />
                  <Input
                    disabled={adminMode}
                    name="city"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Miasto"
                    value={values.city || ''}
                    error={touched.city && errors.city}
                  />
                  <ErrorMessage component={ErrorLabel} name="city" />
                  <Select
                    disabled={adminMode}
                    name="voivodeship"
                    value={values.voivodeship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Województwo"
                  >
                    <option value="">&nbsp;</option>
                    {provinces.map((province) => (
                      <option key={province.value} value={province.value}>
                        {province.display}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage component={ErrorLabel} name="voivodeship" />
                  {adminMode && (
                    <>
                      <Select
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Status rekomendacji"
                      >
                        {Object.entries(RecommendationsStatusObj).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage component={ErrorLabel} name="status" />
                    </>
                  )}
                </FormColumn>
              </Columns>
              <FieldHeading medium size="m">
                Oświadczenia
              </FieldHeading>
              <div style={{ textAlign: 'justify' }}>
                <Checkbox
                  disabled={editMode}
                  name="regulations"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={[
                    'Oświadczam, że uzyskałem/-łam od Partnera, którego polecam w ramach Programu Poleceń Partnerskich zgodę na udostępnienie Polskie ePłatności Sp. z o.o. z siedzibą w Rzeszowie, ul. T. Rejtana 20B, 25-310 Rzeszów jego danych osobowych oraz na ich przetwarzanie przez Polskie ePłatności Sp. z o.o. z siedzibą w Rzeszowie dla celów przystąpienia przeze mnie do Programu Poleceń Partnerskich i realizacji postanowień Regulaminu Programu Poleceń Partnerskich, zgodnie z przepisami Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) oraz postanowieniami ',
                    <StyledLink key="Regulamin1" href={collaborationRegulationUrl || '#'} target='_blank' download>
                      Regulaminu Programu Poleceń Partnerskich.
                    </StyledLink>,
                  ]}
                  value={values.regulations}
                  checked={values.regulations}
                  error={Boolean(touched.regulations && errors.regulations)}
                />
                <ErrorMessage component={ErrorLabel} name="regulations" />
                <Checkbox
                  disabled={editMode}
                  name="consent"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={[
                    'Oświadczam, że zapoznałem się z ',
                    <StyledLink key="Regulamin2" href={collaborationRegulationUrl || '#'} target='_blank' download>
                      Regulaminem Programu Poleceń Partnerskich
                    </StyledLink>,
                    ' i akceptuję wszystkie w nim zawarte warunki.',
                  ]}
                  value={values.consent}
                  checked={values.consent}
                  error={Boolean(touched.consent && errors.consent)}
                />
                <ErrorMessage component={ErrorLabel} name="consent" />
              </div>
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

RecommendationForm.defaultProps = {
  initialValues: {
    companyName: '',
    nip: '',
    phone: '',
    contactPerson: '',
    city: '',
    voivodeship: '',
    regulations: false,
    consent: false,
    id: '',
    status: '',
  },
  editMode: false,
  adminMode: false,
};

RecommendationForm.propTypes = {
  initialValues: PropTypes.shape({
    companyName: PropTypes.string,
    nip: PropTypes.string,
    phone: PropTypes.string,
    contactPerson: PropTypes.string,
    city: PropTypes.string,
    voivodeship: PropTypes.string,
    regulations: PropTypes.bool,
    consent: PropTypes.bool,
    id: PropTypes.string,
    status: PropTypes.string,
  }),
  editMode: PropTypes.bool,
  toggleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  adminMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  products: state.products.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationForm);

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;

const StyledLink = styled.a`
  color: ${colors.orange};

  &:link,
  &:active {
    color: ${colors.orange};
  }

  &:hover,
  &:focus {
    color: ${colors.red};
  }
`;
