import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { marketingMaterialsSchema } from 'utils/validation';
import { marketingService } from 'services/marketing';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import { acceptCommonMimeTypes, bannersMimeTypes, marketingMaterialTypesOptions } from 'config/constants';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import FileInput from 'components/UI/FileInput';
import Select from 'components/UI/Select';

const MarketingForm = ({ toggleClose, initialValues, editMode, refetch }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleClose = resetForm => {
    setSuccess(false);
    setError('');
    resetForm({});
    toggleClose();
  };

  const handleSubmit = values => {
    setError('');
    setLoading(true);

    const thenFn = data => {
      console.log(data);
      setLoading(false);
      setSuccess(true);
      refetch();
    };

    const catchFn = err => {
      console.log(err);
      setLoading(false);
      setError(err.response?.data?.error?.message || 'Wystąpił błąd.');
    };

    if (initialValues.id) {
      const updateValues = { type: values.type, name: values.name, active: values.active, link: values.link };
      marketingService
        .updateMarketingMaterial(initialValues.id, updateValues)
        .then(thenFn)
        .catch(catchFn);
    } else {
      const vals = {
        name: values.name,
        active: values.active,
        type: values.type,
        marketingMaterial: [values.marketingMaterial, values.marketingMaterial2],
        link: values.link,
      };

      marketingService
        .addMarketingMaterial(vals)
        .then(thenFn)
        .catch(catchFn);
    }
  };

  const { name, type, link } = initialValues;
  const active = initialValues.active === '1';
  const marketingMaterial = ['banner', 'banner-ph'].includes(initialValues.type)
    ? initialValues.fileName.split('###')[0] || ''
    : initialValues.fileName || '';
  const marketingMaterial2 = ['banner', 'banner-ph'].includes(initialValues.type)
    ? initialValues.fileName.split('###')[1] || ''
    : initialValues.fileName || '';

  return (
    <Wrapper>
      {loading && (
        <SuccessPopup>
          <Heading>Trwa wysyłanie materiałów</Heading>
          <Loading customStyle={{ marginTop: '20px', marginBottom: '20px' }} />
        </SuccessPopup>
      )}
      <Heading size="xl">Materiały marketingowe</Heading>
      <Formik
        initialValues={{
          name,
          marketingMaterial,
          marketingMaterial2,
          link,
          active,
          type,
        }}
        validationSchema={marketingMaterialsSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>{editMode ? 'Zaktualizowano materiały' : 'Dodałeś materiały'}</Heading>
                {/* <p>{success}</p> */}
                <div className="buttons">
                  {!editMode && <Button onClick={() => handleReturn(true, resetForm)}>Dodaj kolejne</Button>}
                  <Button gray={!editMode} onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>{editMode ? 'Błąd podczas aktualizacji' : 'Błąd podczas dodawania materiałów'}</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                {editMode ? ' Zaktualizuj materiały' : ' Uzupełnij dane'}
              </FieldHeading>
              <Select
                name="type"
                value={values.type}
                disabled={editMode}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Typ"
                preFilled
              >
                {marketingMaterialTypesOptions.map(t => (
                  <option key={t.value} value={t.value}>
                    {t.display}
                  </option>
                ))}
              </Select>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nazwa"
                value={values.name || ''}
                error={touched.name && errors.name}
              />
              <ErrorMessage component={ErrorLabel} name="name" />
              {['banner', 'banner-ph'].includes(values.type) ? (
                <>
                  <FieldHeading size="s">
                    Dodaj banner - poziomy - <small className="color-red">1000 x 200</small>
                  </FieldHeading>
                  <Field
                    disabled={editMode}
                    name="marketingMaterial"
                    id="marketingMaterial"
                    accept={bannersMimeTypes}
                    component={FileInput}
                  />
                  <ErrorMessage style={{ marginTop: '1.4rem' }} component={ErrorLabel} name="marketingMaterial" />
                  <FieldHeading size="s">
                    Dodaj banner - pionowy - <small className="color-red">300 x 600</small>
                  </FieldHeading>
                  <Field
                    disabled={editMode}
                    name="marketingMaterial2"
                    id="marketingMaterial2"
                    accept={bannersMimeTypes}
                    component={FileInput}
                  />
                  <ErrorMessage style={{ marginTop: '1.4rem' }} component={ErrorLabel} name="marketingMaterial2" />
                  <Input
                    name="link"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Link"
                    value={values.link || ''}
                    error={touched.link && errors.link}
                  />
                  <ErrorMessage component={ErrorLabel} name="link" />
                </>
              ) : (
                <>
                  <FieldHeading size="s">Dodaj plik</FieldHeading>
                  <Field
                    disabled={editMode}
                    name="marketingMaterial"
                    id="marketingMaterial"
                    accept={acceptCommonMimeTypes}
                    component={FileInput}
                  />
                  <ErrorMessage style={{ marginTop: '1.4rem' }} component={ErrorLabel} name="marketingMaterial" />
                </>
              )}
              <FieldHeading size="s">Status</FieldHeading>
              <Checkbox
                name="active"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Aktywny"
                value={values.active}
                checked={values.active}
                error={Boolean(touched.active && errors.active)}
              />
              {['banner', 'banner-ph'].includes(values.type) && values.active && (
                <p>
                  <strong>UWAGA!</strong> - nowy banner nadpisze aktualnie aktywny banner reklamowy danego typu.
                </p>
              )}
              <ErrorMessage component={ErrorLabel} name="active" />
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

MarketingForm.defaultProps = {
  initialValues: {
    id: '',
    name: '',
    marketingMaterial: '',
    marketingMaterial2: '',
    link: '',
    active: true,
    fileName: '',
    banner1: '',
    banner2: '',
    type: 'ads',
  },
  editMode: false,
};

MarketingForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    marketingMaterial: PropTypes.string,
    marketingMaterial2: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
    fileName: PropTypes.string,
    banner1: PropTypes.string,
    banner2: PropTypes.string,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  editMode: PropTypes.bool,
  toggleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  products: state.products.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarketingForm);

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
