import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { partnerUserSchema } from 'utils/validation';
import { partnerService } from 'services/partner';
import { removeNulls } from 'utils/helpers';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import { FormWrapperCommon }  from 'components/forms/FormComponents'

const AddPartnerUser = ({ refetchUsers, toggleClose }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = () => {
    setSuccess(false);
    setError('');
    toggleClose();
  };

  const handleSubmit = values => {
    const newValues = removeNulls(values);
    setError('');
    setLoading(true);
    partnerService
      .addUser(newValues)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchUsers();
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  const successPopup = (
    <SuccessPopup>
      <Heading>Dodałeś subpartnera</Heading>
      <div className="buttons">
        <Button onClick={handleReturn}>Wróć</Button>
      </div>
    </SuccessPopup>
  );

  const errorPopup = (
    <ErrorPopup>
      <Heading>Błąd dodawania subpartnera</Heading>
      <p>{error}</p>
      <div className="buttons">
        <Button onClick={handleReturn}>Spróbuj ponownie</Button>
      </div>
    </ErrorPopup>
  );

  return (
    <Wrapper>
      {success && successPopup}
      {error && errorPopup}
      {loading && <Loading absolute />}
      <Heading size="xl">Dodaj subpartnera</Heading>
      <Formik
        initialValues={{
          name: '',
          surname: '',
          phone: '',
          email: '',
        }}
        validationSchema={partnerUserSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSubmit(values);
          resetForm({});
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <StyledForm>
            <FieldHeading big size="m">
              Wprowadź dane:
            </FieldHeading>
            <Input
              name="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Imię"
              value={values.name}
              error={touched.name && errors.name}
            />
            <ErrorMessage component={ErrorLabel} name="name" />
            <Input
              name="surname"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Nazwisko"
              value={values.surname}
              error={touched.surname && errors.surname}
            />
            <ErrorMessage component={ErrorLabel} name="surname" />
            <Input
              name="phone"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Numer telefonu"
              value={values.phone}
              error={touched.phone && errors.phone}
            />
            <ErrorMessage component={ErrorLabel} name="phone" />
            <Input
              name="email"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Adres email"
              value={values.email}
              error={touched.email && errors.email}
            />
            <ErrorMessage component={ErrorLabel} name="email" />
            <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
              Prześlij
            </Button>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};

AddPartnerUser.defaultProps = {
  toggleClose: () => {},
};

AddPartnerUser.propTypes = {
  refetchUsers: PropTypes.func.isRequired,
  toggleClose: PropTypes.func,
};

export default AddPartnerUser;

// styled components
const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;

const FieldHeading = styled(Heading)`
  margin-bottom: 5px;
  margin-top: 15px;
  ${({ big }) =>
    big &&
    css`
      margin-bottom: 30px;
      margin-top: 5px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
    `}
`;
