import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { colors, font, breakpoints } from 'theme/styles';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import calendarIcon from 'assets/icons/calendar.svg';
import clearIcon from 'assets/icons/clear.svg';

const DateFromTo = React.forwardRef(({ handleDate, updatedDate, maxDate, minDate, name }, ref) => {
  const [displayDate, setDisplayDate] = useState(updatedDate);

  const handleChange = input => {
    setDisplayDate(input);
    if (input === null) {
      handleDate('');
    } else {
      handleDate([moment(input[0]).format('YYYY-MM-DD'), moment(input[1]).format('YYYY-MM-DD')]);
    }
  };

  return (
    <Styles>
      <DateRangePicker
        ref={ref}
        calendarIcon={<CalendarButton />}
        clearIcon={<ClearButton />}
        onChange={handleChange}
        name={name}
        value={displayDate}
        locale="pl-PL"
        maxDate={maxDate ? new Date() : null}
        minDate={minDate ? new Date() : null}
      />
    </Styles>
  );
});

DateFromTo.displayName = "DateFromTo";

DateFromTo.defaultProps = {
  updatedDate: null,
  maxDate: true,
  minDate: false,
  name: 'date',
};

DateFromTo.propTypes = {
  handleDate: PropTypes.func.isRequired,
  updatedDate: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  maxDate: PropTypes.bool,
  minDate: PropTypes.bool,
  name: PropTypes.string,
};

export default DateFromTo;

const Styles = styled.div`
  .react-daterange-picker__wrapper {
    height: 30px;
    border: none;
  }
  .react-daterange-picker__inputGroup {
    border-bottom: 1px solid ${colors.darkGray};
    min-width: 60px;
    margin: 0 5px;
    font-size: ${font.size.s};
  }
  .react-daterange-picker__button {
    padding: 0;
  }

  @media (max-width: ${breakpoints.filters}) {
    .react-daterange-picker,
    .react-daterange-picker__wrapper {
      width: 100%;
    }

    .react-daterange-picker__inputGroup {
      justify-content: space-evenly;
    }

    .react-daterange-picker__button {
      position: absolute;
      top: 4px;
      right: 0;
      transform: translateY(-100%);

      & > div {
        width: 26px;
        height: 26px;
      }

      &:nth-last-of-type(1) {
        transform: translate(-100%, -100%);
      }
    }
  }
`;

const ClearButton = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${clearIcon});
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 50% 50%;
  cursor: pointer;
`;

const CalendarButton = styled(ClearButton)`
  background-image: url(${calendarIcon});
`;
