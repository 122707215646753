import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { getSpOptions } from 'redux/admin';
import { routes } from 'routes';
import { colors, font } from 'theme/styles';
import { TopSection } from 'components/layout/Tables/TableComponents';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';
import Block from 'components/UI/Block';
import OptionsLeads from 'components/forms/Admin/OptionsLeads';
import OptionsOthers from 'components/forms/Admin/OptionsOthers';

const AdminOptionsPage = () => {
  const dispatch = useDispatch();

  const getSpOptionsCb = useCallback(() => dispatch(getSpOptions()), [dispatch]);

  useEffect(() => {
    getSpOptionsCb();
  }, [getSpOptionsCb]);

  return (
    <PageTemplate>
      <StyledTopSection>
        <div>
          <Heading size="xxl">Ustawienia Strefy Partnera</Heading>
          <p className="semi" style={{ marginTop: '1rem' }}>
            <span className="color-red">UWAGA!</span> Poniższe opcje służą do konfiguracji Strefy Partnera, jeżeli nie
            wiesz co robisz prosimy o nieedytowanie poniższych pól.
          </p>
        </div>
      </StyledTopSection>
      <InnerHeader>
        <InnerNvButton to={routes.adminOptionsTabLeads}>Globalne leadowe</InnerNvButton>
        <InnerNvButton to={routes.adminOptionsTabOthers}>Inne</InnerNvButton>
        {/* <InnerNvButton to={routes.adminOptionsTabPartners}>Partnerzy</InnerNvButton>
        <InnerNvButton to={routes.adminOptionsTabCrons}>Crony</InnerNvButton>
        <InnerNvButton to={routes.adminOptionsTabOthers}>Inner</InnerNvButton> */}
      </InnerHeader>
      <Block>
        <Switch>
          <Route exact path={routes.adminOptionsTabLeads} render={() => <OptionsLeads refetch={getSpOptionsCb} />} />
          <Route exact path={routes.adminOptionsTabOthers} render={() => <OptionsOthers refetch={getSpOptionsCb} />} />
          {/* <Route exact path={routes.adminOptionsTabPartners} render={() => <Heading>Ustawienia partnerskie</Heading>} />
          <Route
            exact
            path={routes.adminOptionsTabCrons}
            render={() => <Heading>Ustawienia zadań systemowych</Heading>}
          />
          <Route exact path={routes.adminOptionsTabOthers} render={() => <Heading>Inne</Heading>} /> */}
          <Redirect to={routes.adminOptionsTabLeads} />
        </Switch>
      </Block>
    </PageTemplate>
  );
};

export default AdminOptionsPage;

const StyledTopSection = styled(TopSection)`
  margin-bottom: 5rem;
`;
const InnerHeader = styled.div`
  position: relative;
  background-color: ${colors.lighterGray};
  padding: 10px 20px;
`;

const InnerNvButton = styled(NavLink)`
  color: ${colors.darkGray};
  font-weight: ${font.weight.semibold};
  letter-spacing: 0.5px;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  font-size: ${font.size.m};
  text-decoration: none;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 0;
    background-color: ${colors.orange};
    bottom: -10px;
    left: 10px;
    transition: width 0.15s ease-in;
  }

  &.active,
  &:hover {
    color: ${colors.orange};

    &::before {
      width: calc(100% - 20px);
    }
  }
`;
