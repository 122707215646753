import invoiceDefv1 from './invoice-version-1';
import invoiceDefv2 from './invoice-version-2';
import invoiceDefv3 from './invoice-version-3';
import invoiceDefv4 from './invoice-version-4';
import billingsDefv1 from './billingPdf';
import testData from './testConfig';
import billingsData from './billingsData';
import merchantForm from './merchantForm';
import merchantFormAML from './merchantFormAML';

export default {
  testData,
  billingsMockedData: billingsData,
  V1: invoiceDefv1,
  V2: invoiceDefv2,
  V3: invoiceDefv3,
  V4: invoiceDefv4,
  Billings: billingsDefv1,
  MerchantForm: merchantForm,
  MerchantFormAML: merchantFormAML
};
