/* eslint-disable camelcase */
import makeRequest, { requestWithFiles } from 'services/makeRequest';

const LOGIN_USER_URL = '/auth/login';
const REGISTER_USER_URL = '/auth/register';
const LOGOUT_USER_URL = '/auth/logout';

const USER_UPDATE_URL = '/user/update';
const USER_UPDATE_SETTINGS_URL = '/user/settings';

const USER_DATA_URL = '/user/me';


const SET_PASSWORD_URL = '/auth/active-account-set-password';
const RESET_PASSWORD_URL = '/auth/reset-password';
const RESET_PASSWORD_CONFIRM_URL = '/auth/reset-password';
const CHANGE_PASSWORD_URL = '/user/change-password';

const UPDATE_AVATAR_URL = '/user/update-avatar';
const USERS_BULK_IMPORT_URL = '/user/bulk-import';

const updateSettings = data => {
  return makeRequest({
    method: 'post',
    url: USER_UPDATE_SETTINGS_URL,
    data,
  });
};

const login = (email, password) => {
  return makeRequest({
    method: 'post',
    url: LOGIN_USER_URL,
    data: {
      email,
      password,
    },
  });
};

const update = data => {
  return makeRequest({
    method: 'post',
    url: USER_UPDATE_URL,
    data,
  });
};

const getMe = () => {
  return makeRequest({
    method: 'get',
    url: USER_DATA_URL,
  });
};

const logout = () => {
  return makeRequest({
    method: 'get',
    url: LOGOUT_USER_URL,
  });
};

const register = (email, password, password_repeat, name, surname, phone) => {
  return makeRequest({
    method: 'post',
    url: REGISTER_USER_URL,
    data: {
      email,
      password,
      password_repeat,
      name,
      surname,
      phone,
    },
  });
};

const setPassword = (password, id, token) => {
  return makeRequest({
    method: 'post',
    url: `${SET_PASSWORD_URL}/${id}/${token}`,
    data: {
      password,
    },
  });
};

const changePassword = (id, data) => {
  return makeRequest({
    method: 'post',
    url: `${CHANGE_PASSWORD_URL}/${id}`,
    data,
  });
};

const resetPassword = email => {
  return makeRequest({
    method: 'post',
    url: `${RESET_PASSWORD_URL}`,
    data: { email },
  });
};

const resetPasswordConfirm = (id, token) => {
  return makeRequest({
    method: 'get',
    url: `${RESET_PASSWORD_CONFIRM_URL}/${id}/${token}`,
  });
};

const updateAvatar = data => {
  return requestWithFiles({
    method: 'post',
    url: UPDATE_AVATAR_URL,
    data,
  });
};


const usersBulkImport = csv => {
  return requestWithFiles({
    method: 'post',
    url: USERS_BULK_IMPORT_URL,
    data: {
      csv
    }
  });
};

export const userService = {
  login,
  logout,
  register,
  getMe,
  update,
  updateAvatar,
  setPassword,
  changePassword,
  resetPassword,
  resetPasswordConfirm,
  usersBulkImport,
  updateSettings
};
