const { default: makeRequest } = require('./makeRequest');

const BASE_URL = '/utility';

const getGusEmail = (nip) =>
  makeRequest({
    method: 'GET',
    url: `${BASE_URL}/gus/${nip}`,
  });

export const utilityService = {
  getGusEmail,
};
