import { io } from 'socket.io-client';
import { BASE_URL } from 'config/index';

export const socket = io(BASE_URL, {
  path: '/ws',
  withCredentials: true,
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 5,
  autoConnect: false,
  closeOnBeforeunload: true,
});

window._socket = socket;
