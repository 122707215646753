/* eslint-disable no-case-declarations */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import { assignMessageToUser, insertInArray } from 'utils/helpers';
import {
  addPreviousMessages,
  chatTypes,
  setLocalLastSeenMessages,
  setMessagesLoading,
  setPanelActive,
} from 'redux/chat';
import { socket } from 'services/socket';
import { chatModes } from 'config/constants';
import usePrevious from 'hooks/usePrevious';
import SendMessage from 'components/forms/SendMessage';
import Message from './Message';
import Heading from '../Heading';
import Loading from '../Loading';

const MessagesList = ({ messages, conversationName, conversationId, newConversationLeadId, type }) => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);

  const [noMore, setNoMore] = useState(false);

  const myId = useSelector(s => s.user.data.id);
  const conversation = useSelector(s => s.chat.activeConversation.conversation);

  const isSocketConnected = useSelector(state => state.socket.connected);

  const localLastSeenMessages = useSelector(s => s.chat.localLastSeenMessages[conversationId]);
  const activeConversation = useSelector(s => s.chat.activeConversation.conversation);
  const isLoadingMessages = useSelector(s => s.chat.activeConversation.isLoading);
  const initialMessagesLoaded = useSelector(s => s.chat.activeConversation.initialLoaded);

  const lastStatus = useSelector(s => s.chat.activeConversation.lastStatus);
  const prevMessages = usePrevious(messages.length);

  const chatMode = useSelector(state => state.chat.chatMode);

  let throttleTimeout = null;

  const callback = () => {
    if (
      !isSocketConnected ||
      noMore ||
      isLoadingMessages ||
      wrapperRef.current.scrollTop > 200 ||
      messages.length < 30
    ) {
      throttleTimeout = null;
      return;
    }
    dispatch(setMessagesLoading(true));
    socket.emit(`chat:${queryParams.type}:messages`, { offset: messages.length, conversationId }, response => {
      dispatch(setMessagesLoading(false));
      if (response.length === 0) setNoMore(true);
      const messages = assignMessageToUser(conversation.users, response);
      dispatch(addPreviousMessages(messages, conversationId));
    });
    throttleTimeout = null;
  };

  useEffect(() => {
    return () => dispatch(setLocalLastSeenMessages(activeConversation.id));
  }, [activeConversation.id, dispatch]);

  useEffect(() => {
    const target = wrapperRef.current;
    switch (lastStatus) {
      case chatTypes.STATUSES.INITIAL_LOADED:
      case chatTypes.STATUSES.NEW:
        const hookEl = target.children[target.children.length - 1];
        if (hookEl) hookEl.scrollIntoView({ behavior: 'smooth' });
        break;
      case chatTypes.STATUSES.PREVIOUS:
        if (prevMessages === 0) break;
        const scrollToElement = target.children[target.children.length - prevMessages];
        if (scrollToElement) scrollToElement.scrollIntoView({ behavior: 'auto', block: 'center' });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length]);

  // Reset component when conversation has changed && unmount was not triggered
  // useEffect(() => {
  //   // setNoMore(false);
  // }, [activeConversation]);

  useEffect(() => {
    const target = wrapperRef.current;
    const handleScroll = () => {
      if (throttleTimeout === null) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        throttleTimeout = setTimeout(callback, 200);
      }
    };
    target.addEventListener('scroll', handleScroll);
    return () => {
      clearTimeout(throttleTimeout);
      target.removeEventListener('scroll', handleScroll);
    };
  }, [messages.length, isLoadingMessages]);

  const createMessages = () => {
    if (!messages) return;

    let newMessages = messages;
    let lastVisited;

    if (localLastSeenMessages) {
      lastVisited = localLastSeenMessages;
    } else if (activeConversation.details) {
      const lastVisitsArr = JSON.parse(activeConversation.details).lastVisits || [];
      const userLastVisit = lastVisitsArr.find(o => o.userId === myId);
      if (userLastVisit) lastVisited = userLastVisit.lastSeen;
    }

    if (lastVisited) {
      const firstUnreadedIndex = messages.findIndex(msg => {
        if (msg.user && msg.user.id !== myId) {
          return moment(lastVisited).isBefore(moment(msg.createdAt));
        }
        return false;
      });

      if (firstUnreadedIndex !== -1) {
        newMessages = insertInArray(messages, firstUnreadedIndex, {
          type: 'NEW_MESSAGES',
          user: {
            id: 'NONE',
          },
        });
      }
    }

    // eslint-disable-next-line consistent-return
    return newMessages.map((msg, i, arr) => {
      if (msg.type && msg.type === 'NEW_MESSAGES') return <NewMessagesDivider key={msg.type}>nowe</NewMessagesDivider>;

      if (!msg.user)
        return (
          <Message
            key={msg.id}
            messageId={msg.id}
            name={msg.type === 'no_access' ? 'Informacja' : '???'}
            surname=" "
            fromMe={false}
            bulk={false}
            date={msg.createdAt}
            text={msg.message}
            avatar=""
            attachment={{ name: msg.attachment, displayName: msg.attachmentFullName, size: msg.attachmentSize }}
          />
        );

      return (
        <Message
          key={msg.id}
          messageId={msg.id}
          name={msg.user.name}
          surname={msg.user.surname}
          fromMe={msg.user.id === myId}
          bulk={arr[i - 1] ? arr[i - 1].user.id === arr[i].user.id : false}
          date={msg.createdAt}
          text={msg.message}
          avatar={msg.user.avatar}
          attachment={{ name: msg.attachment, displayName: msg.attachmentFullName, size: msg.attachmentSize }}
        />
      );
    });
  };

  return (
    <Wrapper>
      <Header>
        {typeof conversationName === 'string' ? (
          <StyledHeading size="xl"> {conversationName} </StyledHeading>
        ) : (
          conversationName
        )}
        <StyledIcon icon="list" onClick={() => dispatch(setPanelActive(true))} />
      </Header>
      {!initialMessagesLoaded && isLoadingMessages && <Loading />}
      <MessagesWrapper ref={wrapperRef}>{createMessages()}</MessagesWrapper>
      {chatMode === chatModes.archive ? (
        <p className='center color-orange uppercase' style={{padding: '0.5rem 0'}}>Aktualnie przeglądasz archiwalną konwersację, aby móc wysyłać wiadomości nalezy ją przywrócić. </p>
      ) : (
        <SendMessage newConversationLeadId={newConversationLeadId} type={type} />
      )}
    </Wrapper>
  );
};

export default MessagesList;

MessagesList.defaultProps = {
  newConversationLeadId: null,
};

MessagesList.propTypes = {
  messages: PropTypes.instanceOf(Array).isRequired,
  conversationName: PropTypes.node.isRequired,
  conversationId: PropTypes.string.isRequired,
  newConversationLeadId: PropTypes.string,
  type: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.blueLighter};
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
`;

const MessagesWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  margin-bottom: 1rem;
  /* scroll-behavior: smooth; */
  background-color: ${colors.blueGrayLight};
  padding: 1rem;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

const StyledHeading = styled(Heading)`
  // TODO, mobile i overflow
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  display: none;

  @media (max-width: 768px) {
    display: inline-block;
    height: 3rem;
    width: 1.2em !important;
    border: none;
    margin-left: 1.5rem;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`;

const NewMessagesDivider = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.6rem;
  font-size: ${font.size.xm};
  font-weight: ${font.weight.semibold};
  letter-spacing: 0.2px;
  color: ${colors.orange};
  text-transform: uppercase;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0px;
    border-bottom: 1px solid ${colors.orange};
    flex: 1;
    margin: 0 1rem;
  }
`;
