/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import { displayTime } from 'utils/helpers';
import { mapStatusObj, translateProps, historyActions, historyTypes, specialPartners } from 'config/constants';
import Button from 'components/UI/Button';
import HorizontalLine from 'components/UI/HorizontalLine';
import NoData from 'components/UI/NoData';

const getTitle = (log) => {
  const { extra = {} } = log;

  if (log.actionType === historyActions.RE_ADD_LEAD._TYPE && extra && extra.oldLeadFrontNumLead)
    return (
      <p title={log.leadId}>
        {historyActions[log.actionType]?.display || ''} - {extra.oldLeadFrontNumLead || 'brak danych'} &rarr;{' '}
        {log.frontNumLead}
      </p>
    );

  return (
    <p title={log.leadId}>
      {historyActions[log.actionType]?.display || ''} - {log.frontNumLead}
    </p>
  );
};

const createAdditionalInfo = (log) => (
  <>
    {log.leadId && (
      <p>
        <span>Lead id: </span>
        {log.leadId}
      </p>
    )}
    <p>
      <span>Sposób autoryzacji: </span>
      {historyTypes[log.authType]?.display}
    </p>
    {log.authUser && (
      <div>
        <HorizontalLine title="Akcja wykonana przez:" />
        <p>
          <span>Imię i nazwisko: </span>
          {log.authUser.fullName}
        </p>
        <p>
          <span>Email: </span>
          {log.authUser.email}
        </p>
        <p>
          <span>Rola: </span>
          {log.authUser.role.name}
        </p>
      </div>
    )}
  </>
);

const createDiffTable = (log) => {
  return (
    <HistoryItemDiffTable>
      <thead>
        <tr>
          <th className="propName">Pole</th>
          <th>Przed edycją</th>
          <th>Po edycji</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(log.diff || {})
          .filter((diff) => !['updatedAt'].includes(diff[0]))
          .map((diff) => {
            const propName = diff[0];
            let oldProp = diff[1] ? diff[1].__old : '-';
            let newProp = diff[1] ? diff[1].__new : '-';

            // If prop value is array
            if (diff[1].length) {
              oldProp = log.prevData[propName].map((el) => el.name || el).join('\n') || '';
              newProp = log.nextData[propName].map((el) => el.name || el).join('\n') || '';
            }

            if (propName === 'status') {
              oldProp = mapStatusObj[oldProp];
              newProp = mapStatusObj[newProp];
            }

            if (propName === 'takenDate') {
              oldProp = oldProp ? moment(oldProp).format('HH:mm - DD.MM.YYYY') : 'brak';
              newProp = newProp ? moment(newProp).format('HH:mm - DD.MM.YYYY') : 'brak';
            }

            if (propName === 'takenUser' && specialPartners.some((p) => p.id === newProp || p.id === oldProp)) {
              oldProp = specialPartners.find((p) => p.id === oldProp)?.display || oldProp;
              newProp = specialPartners.find((p) => p.id === newProp)?.display || newProp;
            }

            if (oldProp === null) oldProp = 'brak';
            if (newProp === null) newProp = 'brak';
            if (typeof oldProp === 'boolean') oldProp = oldProp.toString();
            if (typeof newProp === 'boolean') newProp = newProp.toString();

            return (
              <tr key={propName}>
                <td>{translateProps[propName]}</td>
                <td>{oldProp}</td>
                <td>{newProp}</td>
              </tr>
            );
          })}
      </tbody>
    </HistoryItemDiffTable>
  );
};

const createDetails = (log) => {
  let isTaken = false;
  const { extra = {} } = log;
  switch (log.actionType) {
    case historyActions.CHANGE_STATUS_CRM_PEP_FORM._TYPE:
      if (!extra) return null;
      return (
        <>
          <HorizontalLine title="Dodatkowe informacje:" />
          <p>
            Zmiana statusu z {extra.from || '-'} na {extra.to || '-'}
          </p>
        </>
      );
    case historyActions.ACLAS_SEND_ORDER._TYPE: {
      if (!extra) return null;
      return (
        <>
          <HorizontalLine title="Dodatkowe informacje:" />
          <p>
            <span>Błąd</span>
            <pre>{JSON.stringify(extra, null, 2)}</pre>
          </p>
        </>
      );
    }
    case historyActions.AUTOMATIC_CREATE_MERCHANT_CRM_PEP._TYPE:
    case historyActions.CREATE_MERCHANT_CRM_PEP._TYPE: {
      return (
        <>
          <HorizontalLine title="Dane konta w PeP CRM" />
          {extra?.MERCHANT_LEAD_CRM?.LEAD_CRM_RESULT && (
            <>
              {extra.MERCHANT_LEAD_CRM.GUS?.RESULT && (
                <>
                  <p
                    style={{
                      color: extra.MERCHANT_LEAD_CRM.GUS.STATUS ? colors.greenText : colors.red,
                    }}
                  >
                    <span>Status GUS: </span>
                    {extra.MERCHANT_LEAD_CRM.GUS.RESULT}
                  </p>
                </>
              )}
              {extra.MERCHANT_LEAD_CRM.CRM_PH?.RESULT && (
                <>
                  <p
                    style={{
                      color: extra.MERCHANT_LEAD_CRM.CRM_PH.STATUS ? colors.greenText : colors.red,
                    }}
                  >
                    <span>Status PH w CRM: </span>
                    {extra.MERCHANT_LEAD_CRM.CRM_PH.RESULT}
                  </p>
                  {log.nextData?.crmPhId && (
                    <p style={{ marginBottom: '1em', padding: '0 0.75em' }}>
                      <span>Crm ID przedstawiciela: </span>
                      {log.nextData.crmPhId}
                    </p>
                  )}
                </>
              )}
              <p
                style={{
                  color: extra.MERCHANT_LEAD_CRM.LEAD_CRM_STATUS ? colors.greenText : colors.red,
                }}
              >
                <span>Dodanie konta do CRM: </span>
                {extra.MERCHANT_LEAD_CRM.LEAD_CRM_RESULT}
              </p>
              {log.nextData?.crmAccountId && (
                <p style={{ marginBottom: '1em', padding: '0 0.75em' }}>
                  <span>Crm ID konta: </span>
                  {log.nextData.crmAccountId}
                </p>
              )}
            </>
          )}
        </>
      );
    }
    case historyActions.ADD_LEAD._TYPE: {
      const { assignMachineResult = null } = extra;
      if (!assignMachineResult) return null;
      return (
        <>
          <HorizontalLine title="Maszynka przypisań" />
          {assignMachineResult.steps?.LEAD_PRODUCTS && (
            <>
              <p>
                <span>Czy produkt terminalowy: </span>
                {assignMachineResult.steps.LEAD_PRODUCTS.LEAD_PRODUCTS_TERMINAL ? 'Tak' : 'Nie'}
              </p>
              <p>
                <span>Czy posiada inne produkty: </span>
                {assignMachineResult.steps.LEAD_PRODUCTS.LEAD_PRODUCTS_OTHER ? 'Tak' : 'Nie'}
              </p>
            </>
          )}
          {assignMachineResult.steps?.STREFA_PARTNERA && (
            <p style={{ color: assignMachineResult.steps?.STREFA_PARTNERA.SP_RESULT ? colors.red : colors.greenText }}>
              <span>Weryfikacja statusu - Strefa Partnera: </span>
              {assignMachineResult.steps.STREFA_PARTNERA.SP_REASON}
            </p>
          )}
          {assignMachineResult.steps?.PEP_CRM && (
            <p style={{ color: assignMachineResult.steps?.PEP_CRM.CRM_RESULT ? colors.red : colors.greenText }}>
              <span>Weryfikacja statusu - PEP CRM: </span>
              {assignMachineResult.steps.PEP_CRM.CRM_REASON}
            </p>
          )}
          {assignMachineResult.selectedAccount?.status && assignMachineResult.selectedAccount?.account && (
            <div style={{ marginBottom: '1em', padding: '0 0.75em' }}>
              <p>
                <span>Status CRM: </span>
                {assignMachineResult.selectedAccount.account.Status}
              </p>
              <p>
                <span>Data aktualizacji CRM: </span>
                {displayTime(assignMachineResult.selectedAccount.account.DataModyfikacji)}
              </p>
              <HistoryItemDiffTable style={{ tableLayout: 'fixed', marginTop: 0, fontSize: '0.8em' }}>
                <thead>
                  <tr>
                    <th>ImportanceNumber</th>
                    <th>Index</th>
                    <th>Duplikat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{assignMachineResult.selectedAccount.status.importanceNumber}</td>
                    <td>{assignMachineResult.selectedAccount.status.index}</td>
                    <td>{assignMachineResult.selectedAccount.status.duplicate}</td>
                  </tr>
                </tbody>
              </HistoryItemDiffTable>
            </div>
          )}
          {assignMachineResult.steps?.LEAD_ASSIGN_MACHINE?.LEAD_ASSIGN_REASON && (
            <p>
              <span>Ścieżka przypisania: </span>
              {assignMachineResult.steps?.LEAD_ASSIGN_MACHINE?.LEAD_ASSIGN_REASON}
            </p>
          )}
          {assignMachineResult.steps?.FINISH_PROCESS_LEAD?.STATUS && (
            <p>
              <span>Status leada: </span>
              {mapStatusObj[assignMachineResult.steps.FINISH_PROCESS_LEAD.STATUS]}
            </p>
          )}
          {assignMachineResult.steps?.MERCHANT_LEAD_CRM?.LEAD_CRM_RESULT && (
            <>
              <HorizontalLine title="Tworzenie konta w CRM" />
              {assignMachineResult.steps.MERCHANT_LEAD_CRM.GUS?.RESULT && (
                <>
                  <p
                    style={{
                      color: assignMachineResult.steps.MERCHANT_LEAD_CRM.GUS.STATUS ? colors.greenText : colors.red,
                    }}
                  >
                    <span>Status GUS: </span>
                    {assignMachineResult.steps.MERCHANT_LEAD_CRM.GUS.RESULT}
                  </p>
                </>
              )}
              {assignMachineResult.steps.MERCHANT_LEAD_CRM.CRM_PH?.RESULT && (
                <>
                  <p
                    style={{
                      color: assignMachineResult.steps.MERCHANT_LEAD_CRM.CRM_PH.STATUS ? colors.greenText : colors.red,
                    }}
                  >
                    <span>Status PH w CRM: </span>
                    {assignMachineResult.steps.MERCHANT_LEAD_CRM.CRM_PH.RESULT}
                  </p>
                  {log.nextData?.crmPhId && (
                    <p style={{ marginBottom: '1em', padding: '0 0.75em' }}>
                      <span>Crm ID przedstawiciela: </span>
                      {log.nextData.crmPhId}
                    </p>
                  )}
                </>
              )}
              <p
                style={{
                  color: assignMachineResult.steps.MERCHANT_LEAD_CRM.LEAD_CRM_STATUS ? colors.greenText : colors.red,
                }}
              >
                <span>Dodanie konta do CRM: </span>
                {assignMachineResult.steps.MERCHANT_LEAD_CRM.LEAD_CRM_RESULT}
              </p>
              {log.nextData?.crmAccountId && (
                <p style={{ marginBottom: '1em', padding: '0 0.75em' }}>
                  <span>Crm ID konta: </span>
                  {log.nextData.crmAccountId}
                </p>
              )}
            </>
          )}
        </>
      );
    }
    case historyActions.CRON_PEP_CRM_UPDATE_STATUS._TYPE:
      return (
        <>
          {extra.status_altar && (
            <p>
              <span>Status: </span>
              {extra.status_altar}
            </p>
          )}
          {createDiffTable(log)}
        </>
      );
    case historyActions.DETACH_CRM_FROM_LEAD._TYPE:
    case historyActions.EDIT_LEAD._TYPE:
    case historyActions.CRON_REPAIR_GHOST_LEAD._TYPE:
    case historyActions.CRON_TELESPRZEDAZ_AUTO_TAKE._TYPE:
    case historyActions.AUTO_TAKE_LEAD_BY_SYSTEM._TYPE:
      return createDiffTable(log);
    case historyActions.AUTOMATIC_ASSIGNED_LEAD._TYPE:
      return (
        <>
          <hr />
          {extra.assignedType && (
            <p>
              <span>Typ przypisania: </span>
              <span className="capitalize"> {extra.assignedType.toLowerCase()}</span>
            </p>
          )}
          {extra.queue && (
            <p>
              <span>Lead przypisany do: </span>
              {extra.queue?.user?.fullName} - {extra.queue?.user?.email}
            </p>
          )}
          {log.diff?.takenUser && (
            <p>
              <span>Status po przypisaniu: </span>
              {mapStatusObj[log.diff?.status?.__new] || ''}
            </p>
          )}
        </>
      );
    case historyActions.REASSIGN_LEAD._TYPE:
      if (extra && extra.assignedUser && log.nextData && log.nextData.takenUser) {
        isTaken = extra.assignedUser.id === log.nextData.takenUser;
        return (
          <>
            <hr />
            <p>
              <span>Nowy Ph: </span>
              {extra.assignedUser.name} {extra.assignedUser.surname} - {extra.assignedUser.email}
            </p>
            <p>
              <span>Stan po przypisaniu: </span>
              {isTaken ? 'Podjęto' : 'Przypisano'}
            </p>
          </>
        );
      }
      return null;
    case historyActions.MANUAL_ASSIGNED_LEAD._TYPE:
      return (
        <>
          <HorizontalLine title="Przypisany przedstawiciel" />
          <p>
            <span>Przypisany przedstawiciel: </span>
            {extra.assignedUser.name} {extra.assignedUser.surname} - {extra.assignedUser.email}
          </p>
          {log.nextData?.crmPhId && (
            <p>
              <span>Crm ID przedstawiciela: </span>
              {log.nextData.crmPhId}
            </p>
          )}
          <p>
            <span>Stan po przypisaniu: </span>
            {isTaken ? 'Podjęto' : 'Przypisano'}
          </p>
          {log.extra.prevAssignedUser && (
            <>
              <HorizontalLine title="Poprzedni przedstawiciel" />
              <p>
                <span>Poprzedni przedstawiciel: </span>
                {extra.prevAssignedUser.name} {extra.prevAssignedUser.surname} - {extra.prevAssignedUser.email}
              </p>
            </>
          )}
        </>
      );
    case historyActions.TRANSFER_LEAD._TYPE:
      return (
        <>
          <hr />
          <p>
            <span>Bulk: </span>
            {extra.bulk ? 'Tak' : 'Nie'}
          </p>
          <p>
            <span>Transfer z: </span>
            {extra.fromUser?.fullName} - {extra.fromUser?.email}
          </p>
          <p>
            <span>Transfer na: </span>
            {extra.toUser?.fullName} - {extra.toUser?.email}
          </p>
        </>
      );
    case historyActions.REASON_DUPLICATION._TYPE:
      return (
        <>
          {extra.reason && (
            <p>
              <span>Powód duplikacji: </span>
              <span className="capitalize"> {extra.reason.toLowerCase()}</span>
            </p>
          )}
        </>
      );
    case historyActions.PROCESSED_UNSUCCESSFULLY_CRM_PEP_FORM._TYPE:
    case historyActions.RESEND_PROCESSED_UNSUCCESSFULLY_CRM_PEP_FORM._TYPE:
      return (
        <>
          <hr />
          <p>
            <span>Powód błędu </span>
            {extra || 'Brak informacji'}
          </p>
        </>
      );
    default:
      return null;
  }
};

const ClientHistory = ({ historyData, userRole }) => {
  const [openTabs, setOpenTabs] = useState({});
  const [showLimit, setShowLimit] = useState(50);

  // const handleScroll = e => {
  //   const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
  //   if (bottom) {
  //     console.log('bottom');
  //   }
  // };

  const openTab = (id) => {
    const isOpen = !!openTabs[id];
    setOpenTabs((state) => {
      return { ...state, [id]: !isOpen };
    });
  };

  const sortedMonths = useMemo(
    () =>
      historyData.slice(0, showLimit).reduce((obj, next) => {
        const created = moment(next.createdAt).format('YYYY-MM');
        if (Object.hasOwnProperty.call(obj, created)) obj[created].push(next);
        else obj[created] = [next];
        return obj;
      }, {}),
    [historyData, showLimit],
  );

  return (
    <HistoryWrapper
    // onScroll={handleScroll}
    >
      {historyData.length ? (
        Object.keys(sortedMonths).map((month) => (
          <HistoryMonth key={month}>
            <span>{moment(month).format('MMMM, YYYY')}</span>
            {sortedMonths[month].map((log) => {
              const logMoment = moment(log.createdAt).locale('pl');
              return (
                <HistoryItem key={log.id}>
                  <HistoryItemTop>
                    <HistoryItemDate>
                      <span>{logMoment.format('DD MMM')}</span>
                      <span>{logMoment.format('HH:mm')}</span>
                    </HistoryItemDate>
                    <StyledHistoryIcon icon={historyActions[log.actionType]?.icon || 'info-circle'} fixedWidth />
                    <HistoryItemDescription>
                      {getTitle(log)}
                      <p>
                        Akcja wykonana przez:&nbsp;
                        <span className="actionBy">
                          {log.authUser ? log.authUser.fullName : historyTypes[log.authType].display}
                        </span>
                      </p>
                    </HistoryItemDescription>
                    {['admin', 'dsp'].includes(userRole) && (
                      <HistoryItemShowMore onClick={() => openTab(log.id)}>Zobacz szczegóły</HistoryItemShowMore>
                    )}
                  </HistoryItemTop>
                  {['admin', 'dsp'].includes(userRole) && (
                    <HistoryItemBottom isOpen={openTabs[log.id]}>
                      {createAdditionalInfo(log)}
                      {createDetails(log)}
                      {openTabs[log.id] && log.actionType === historyActions.ADD_LEAD._TYPE && console.log(log)}
                    </HistoryItemBottom>
                  )}
                </HistoryItem>
              );
            })}
          </HistoryMonth>
        ))
      ) : (
        <NoData message="Brak historii do wyświetlenia" />
      )}

      {showLimit < historyData.length && (
        <Button small secondary centered onClick={() => setShowLimit(showLimit + 25)}>
          Pokaż więcej
        </Button>
      )}
    </HistoryWrapper>
  );
};

ClientHistory.defaultProps = {
  historyData: [],
};

ClientHistory.propTypes = {
  historyData: PropTypes.instanceOf(Array),
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: state.user.data.role.slug,
});

export default connect(mapStateToProps)(ClientHistory);

const HistoryWrapper = styled.div`
  overflow-y: auto;
  max-height: 75vh;
  margin-right: -10px;
  padding-right: 20px;
`;

const HistoryMonth = styled.div`
  & > span {
    text-transform: capitalize;
    display: block;
    padding: 8px 38px;
    margin-bottom: 15px;
    font-size: ${font.size.xl};
    color: ${colors.darkGray};
    letter-spacing: 1px;
    background: linear-gradient(to right, ${colors.lighterGray}, transparent);
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
const HistoryItemShowMore = styled.div`
  text-align: right;
  color: ${colors.orange};
  font-weight: ${font.weight.semibold};
  cursor: pointer;
  user-select: 'none';
`;

const HistoryItemDate = styled.div`
  flex: 0 0 50px;
  max-width: 50px;
  margin-right: 15px;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }

  span {
    display: block;
  }

  span:last-child {
    font-size: ${font.size.s};
    color: ${colors.mediumGray};
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }
`;

const HistoryItem = styled.div`
  position: relative;
  padding-left: 30px;
  margin-left: 8px;
  border-left: 2px dashed ${colors.lightGray};
  padding-bottom: 10px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -9px;
    top: 5px;
    width: 16px;
    height: 16px;
    border: 2px solid ${colors.orange};
    background-color: #ffffff;
    padding: 2px;
    border-radius: 50%;
    background-clip: content-box;
  }

  &:hover&::before {
    background-color: ${colors.orange};
  }

  &:last-of-type {
    border-left-color: transparent;

    &::before {
      background-color: ${colors.orange};
      background-clip: initial;
    }
  }

  /* &:hover {
    background: linear-gradient(
      to right,
      transparent 0%,
      ${colors.lighterGray} 15%,
      ${colors.lighterGray} 85%,
      transparent
    );
  } */

  &:hover ${HistoryItemDate} span:last-child {
    opacity: 1;
  }
`;

const HistoryItemTop = styled.div`
  display: flex;
  align-items: baseline;
`;

const HistoryItemBottom = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  margin-bottom: 2rem;
  background-color: ${colors.blueGray};
  padding: 8px 10px;
  margin-top: 2rem;

  p {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 5px;

    span:first-child {
      font-weight: ${font.weight.semibold};
    }
  }

  hr {
    border-top: 1px solid ${colors.gray};
  }
`;

const StyledHistoryIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: 2px;
  flex: 0 1 20px;
  margin-right: 15px;
  text-align: center;
  color: ${colors.mediumGray};
`;

const HistoryItemDescription = styled.div`
  flex-grow: 1;

  p {
    margin-top: 0;
  }

  p:first-child {
    color: ${colors.orange};
    font-weight: ${font.weight.semibold};
    font-size: ${font.size.l};
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const HistoryItemDiffTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  th {
    text-align: left;
    width: 40%;

    &.propName {
      width: auto;
    }
  }

  th,
  td {
    border: 1px solid ${colors.gray};
  }

  td,
  th {
    padding: 4px 8px;
    white-space: pre-wrap;
  }
`;
