import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrentCron, clearCurrentCron, getCurrentCronLogs } from 'redux/admin';
import { BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import { removeNullsAndEmpty } from 'utils/helpers';
import { mapCronSlugToOptions } from 'config/constants';
import { routes } from 'routes';
import { breakpoints } from 'theme/styles';
import PageTemplate from 'templates/PageTemplate';
import usePagination from 'hooks/usePagination';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';
import ExtendedTable from 'components/layout/Tables/ExtendedTable';
import Pagination from 'components/UI/Pagination';
import Select from 'components/UI/Select';
import styled from 'styled-components';
import SidePanel from 'components/layout/SidePanel';
import CronSettings from 'components/forms/Admin/CronSettings';

const headers = [
  { displayName: 'Graylog ID', property: 'graylogId', copy: true },
  { displayName: 'Data wykonania', property: 'createdAt', type: 'date-time', class: 'wider' },
  { displayName: 'Status', property: 'isOk', type: 'status', class: 'status' },
];

const AdminCronDetails = ({ match }) => {
  const ID = match.params.id;
  const [optionsPanelVisible, setOptionsPanelVisible] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { pagination, handlePerPage, setPage, setPagination } = usePagination();

  const currentCronLogsObj = useSelector(state => state.admin.currentCron.logs);
  const currentCron = useSelector(state => state.admin.currentCron.data);
  const error = useSelector(state => state.admin.currentCron.error);
  const isLoading = useSelector(state => state.admin.currentCron.isLoading);

  const availableOptions = {
    ...mapCronSlugToOptions.ALL,
    ...mapCronSlugToOptions[currentCron?.slug || ''],
  };

  const toggleOptionsPanel = () => setOptionsPanelVisible(state => !state);

  const fetchLogsCb = useCallback(
    id => {
      const query = removeNullsAndEmpty({
        limit: pagination.perPage,
        page: pagination.page,
      });
      dispatch(getCurrentCronLogs(id, query));
    },
    [pagination.perPage, pagination.page, dispatch],
  );

  useEffect(() => {
    dispatch(getCurrentCron(ID));
    fetchLogsCb(ID);
    return () => dispatch(clearCurrentCron());
  }, [dispatch, ID, fetchLogsCb]);

  const handleError = () => {
    history.replace(routes.adminCron);
  };

  useEffect(() => {
    setPagination(prevPag => ({
      ...prevPag,
      ...currentCronLogsObj.pagination,
      count: currentCronLogsObj.count,
    }));
  }, [currentCronLogsObj.count, currentCronLogsObj.pagination, setPagination]);

  const render = () => {
    if (currentCron && !isLoading) {
      return (
        <>
          {availableOptions && (
            <SidePanel small toggleClose={toggleOptionsPanel} isVisible={optionsPanelVisible}>
              <CronSettings options={availableOptions} cron={currentCron} closePanel={toggleOptionsPanel} />
            </SidePanel>
          )}
          <StyledTopSection>
            <Heading size="xxl">
              <span className="color-orange">CRON: </span>
              {currentCron.name}
            </Heading>
            {availableOptions && (
              <Button onClick={toggleOptionsPanel}>
                <FontAwesomeIcon icon="edit" style={{ marginRight: '6px' }} /> Ustawienia
              </Button>
            )}
          </StyledTopSection>
          <div>
            <p>
              <strong>Stan: </strong>
              {currentCron.cronStatus ? (
                <strong className="color-green">Włączony</strong>
              ) : (
                <strong className="color-red">Wyłączony</strong>
              )}
            </p>
            <p>
              <strong>Slug: </strong>
              {currentCron.slug}
            </p>
            <p>
              <strong>Interwał: </strong>
              {currentCron.interval}
            </p>
            <p>
              <strong>Opis: </strong>
              {currentCron.description ? currentCron.description : 'Brak'}
            </p>
          </div>
          <ExtendedTable
            data={currentCronLogsObj.count ? currentCronLogsObj.rows : []}
            headers={headers}
            offset={(pagination.currentPage - 1) * (pagination.limit || 20)}
          />
          <BottomSection>
            <BottomItem>Elementów: {pagination.count}</BottomItem>
            <Pagination currentPage={pagination.currentPage} maxPages={pagination.totalPages} handlePage={setPage} />
            <BottomItem>
              <span>Wyświetlaj na stronie:</span>
              <Select small value={pagination.perPage} onChange={handlePerPage}>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="80">80</option>
                <option value="100">100</option>
              </Select>
            </BottomItem>
          </BottomSection>
        </>
      );
    }

    if (error && !isLoading) {
      return (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={handleError}>Wróć</Button>
          </div>
        </ErrorPopup>
      );
    }

    return <Loading big />;
  };

  return <PageTemplate>{render()}</PageTemplate>;
};

AdminCronDetails.defaultProps = {};

AdminCronDetails.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default AdminCronDetails;

const StyledTopSection = styled(TopSection)`
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.mobileSmall}) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
