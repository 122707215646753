import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableWrapperCommon, baseTableMixin } from 'components/layout/Tables/TableComponents';
import { formatMoney } from 'utils/helpers';

import NoData from 'components/UI/NoData';

const SimpleTable = ({ headers, data }) => {
  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => (
              <col key={header.property} className={header.property} />
            ))}
            <col className="actions" />
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id || index}>
                <td>{index + 1}</td>
                <td>{item.parametr}</td>
                <td>{item.wartosc.toString().includes('Opłata') ? formatMoney(item.wartosc) : item.wartosc}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData message="Brak danych" />
      )}
    </TableWrapper>
  );
};

SimpleTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

export default SimpleTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  ${baseTableMixin}
`;
