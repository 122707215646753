import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getAllChallengesForPartner,
  getPartnerChallenge,
  clearCurrentChallenge,
  resetChallenges,
} from 'redux/challenge';
import PageTemplate from 'templates/PageTemplate';
import TwoColsTemplate from 'templates/partials/TwoColsTemplate';
import Loading from 'components/UI/Loading';
import ChallengeList from 'components/layout/ChallengeList';
import PartnerChallengeDetail from 'components/layout/PartnerChallengeDetail';
import ErrorsWrapper from 'components/UI/ErrorsWrapper';

const PartnerChallengePage = ({
  getAllChallengesForPartner,
  getPartnerChallenge,
  resetChallenges,
  currentChallengeDetails,
  isLoadingCurrDetails,
  isErrorCurrDetails,
  challenges,
  isLoadingChallenges,
  errorChallenges,
}) => {
  useEffect(() => {
    getAllChallengesForPartner();
    return () => {
      resetChallenges();
    };
  }, [getAllChallengesForPartner, resetChallenges]);

  const onPick = useCallback(
    challenge => {
      getPartnerChallenge(challenge.id);
    },
    [getPartnerChallenge],
  );

  return (
    <PageTemplate>
      <TwoColsTemplate
        leftTitle="Dostępne wyzwania"
        leftSide={
          <>
            {!isLoadingChallenges && errorChallenges && (
              <ErrorsWrapper>{errorChallenges.message}</ErrorsWrapper>
            )}
            {isLoadingChallenges ? (
              <Loading />
            ) : (
              <ChallengeList challenges={challenges} onPick={onPick} />
            )}
          </>
        }
        rightTitle="Szczegóły"
        rightSide={
          <>
            {isLoadingCurrDetails && <Loading />}
            {currentChallengeDetails && !isLoadingCurrDetails && (
              <PartnerChallengeDetail challenge={currentChallengeDetails} />
            )}
            {!currentChallengeDetails && !isLoadingCurrDetails && !isErrorCurrDetails && <p>Wybierz wyzwanie z listy</p>}
            {isErrorCurrDetails && !isLoadingCurrDetails && (
              <ErrorsWrapper>{isErrorCurrDetails.message}</ErrorsWrapper>
            )}
          </>
        }
      />
    </PageTemplate>
  );
};

PartnerChallengePage.defaultProps = {};

PartnerChallengePage.propTypes = {
  challenges: PropTypes.instanceOf(Array).isRequired,
  isLoadingChallenges: PropTypes.bool.isRequired,
  errorChallenges: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  currentChallengeDetails: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)])
    .isRequired,
  isLoadingCurrDetails: PropTypes.bool.isRequired,
  isErrorCurrDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
    .isRequired,
  getAllChallengesForPartner: PropTypes.func.isRequired,
  resetChallenges: PropTypes.func.isRequired,
  getPartnerChallenge: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  challenges: state.challenge.challenges.data,
  currentChallenge: state.challenge.currentChallenge,
  currentChallengeDetails: state.challenge.currentChallengeDetails.data,
  isLoadingCurrDetails: state.challenge.currentChallengeDetails.isLoading,
  isErrorCurrDetails: state.challenge.currentChallengeDetails.error,
  isLoadingChallenges: state.challenge.challenges.isLoading,
  errorChallenges: state.challenge.challenges.error,
});

const mapDispatchToProps = {
  getAllChallengesForPartner,
  getPartnerChallenge,
  clearCurrentChallenge,
  resetChallenges,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerChallengePage);
