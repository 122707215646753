import React from // useEffect, useState
'react';
import PageTemplate from 'templates/PageTemplate';
import LeadMerchant from 'components/forms/LeadMerchant/LeadMerchant';
// import { useParams } from 'react-router-dom';

const LeadOnboardingPage = () => {
  // const { id } = useParams();

  return (
    <PageTemplate>
      <LeadMerchant />
    </PageTemplate>
  );
};

LeadOnboardingPage.propTypes = {};

export default LeadOnboardingPage;
