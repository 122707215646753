import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage, Field, FieldArray } from 'formik';
import { integrationDocumentsSchema } from 'utils/validation';
import { integrationDocsService } from 'services/integrationDocs';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import { commonFilesMimeTypes } from 'config/constants';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import FileInput from 'components/UI/FileInput';
import Tooltip from 'components/UI/Tooltip';

const groupsOptions = [
  {
    propName: 'pepStructures',
    label: 'Pracownicy PeP (bez PH)',
  },
  {
    propName: 'partners',
    label: 'Partnerzy',
  },
  {
    propName: 'partnersTypeInstallTerminal',
    label: 'Partnerzy (Instalacja terminala)',
  },
  {
    propName: 'partnersTypeCanSigningContract',
    label: 'Partnerzy (Podpisywanie umów)',
  },
  {
    propName: 'ambasador',
    label: 'Partnerzy (Ambasadorzy)',
  },
  {
    propName: 'subPartners',
    label: 'Sub-partnerzy',
  },
];

const IntegrationDocumentForm = ({ toggleClose, initialValues, editMode, refetch }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleClose = (resetForm) => {
    setSuccess(false);
    setError('');
    resetForm({});
    toggleClose();
  };

  const handleSubmit = (values) => {
    setError('');
    setLoading(true);

    const thenFn = (data) => {
      console.log(data);
      setSuccess(true);
      setLoading(false);
      refetch();
    };

    const catchFn = (err) => {
      console.log(err);
      setError(err.response.data.error.message);
      setLoading(false);
    };

    const { name, active, integrationAssignmentDocuments } = values;

    const groups = JSON.stringify(values.groups);

    if (initialValues.id) {
      integrationDocsService
        .updateIntegrationDoc(initialValues.id, { name, active, groups })
        .then(thenFn)
        .catch(catchFn);
    } else {
      integrationDocsService
        .addIntegrationDoc({
          name,
          active,
          integrationAssignmentDocuments,
          groups,
        })
        .then(thenFn)
        .catch(catchFn);
    }
  };

  const { name, groups = [] } = initialValues;
  const active = initialValues.active === '1' || initialValues.active === true;
  const integrationAssignmentDocuments = initialValues.fileName || '';

  return (
    <Wrapper>
      {loading && (
        <SuccessPopup>
          <Heading>Trwa wysyłanie dokumentów</Heading>
          <Loading customStyle={{ marginTop: '20px', marginBottom: '20px' }} />
        </SuccessPopup>
      )}
      <Heading size="xl">Dokumenty</Heading>
      <Formik
        initialValues={{
          name,
          integrationAssignmentDocuments,
          active,
          groups,
        }}
        validationSchema={integrationDocumentsSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>{editMode ? 'Zaktualizowano dokument' : 'Dodałeś dokument'}</Heading>
                {/* <p>{success}</p> */}
                <div className="buttons">
                  {!editMode && <Button onClick={() => handleReturn(true, resetForm)}>Dodaj kolejne</Button>}
                  <Button gray={!editMode} onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>{editMode ? 'Błąd podczas aktualizacji' : 'Błąd podczas dodawania dokumentu'}</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                {editMode ? ' Zaktualizuj dokument' : ' Uzupełnij dane'}
              </FieldHeading>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nazwa"
                value={values.name || ''}
                error={touched.name && errors.name}
              />
              <ErrorMessage component={ErrorLabel} name="name" />

              <FieldHeading size="s">Dodaj plik</FieldHeading>
              <Field
                disabled={editMode}
                name="integrationAssignmentDocuments"
                id="integrationAssignmentDocuments"
                accept={commonFilesMimeTypes}
                component={FileInput}
              />
              <ErrorMessage
                style={{ marginTop: '1.4rem' }}
                component={ErrorLabel}
                name="integrationAssignmentDocuments"
              />
              <FieldHeading size="s">
                Dostep do zasobu:
                <Tooltip width={240}>
                  Nie wybranie grupy oznacza <span className="color-orange">dokument ogólnodostępny</span>
                </Tooltip>
              </FieldHeading>
              <FieldArray
                name="groups"
                render={(arrayHelpers) => (
                  <div>
                    {groupsOptions.map((item) => (
                      <Checkbox
                        key={item.propName}
                        name="group"
                        onChange={(e) => {
                          if (e.target.checked) arrayHelpers.push(item.propName);
                          else {
                            const idx = values.groups.indexOf(item.propName);
                            arrayHelpers.remove(idx);
                          }
                        }}
                        onBlur={handleBlur}
                        label={item.label}
                        value={item.propName}
                        checked={values.groups ? values.groups.includes(item.propName) : false}
                      />
                    ))}
                  </div>
                )}
              />
              <ErrorMessage style={{ marginTop: '5px' }} component={ErrorLabel} name="groups" />
              <FieldHeading size="s">Status</FieldHeading>
              <Checkbox
                name="active"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Aktywny"
                value={values.active}
                checked={values.active}
                error={Boolean(touched.active && errors.active)}
              />
              <ErrorMessage component={ErrorLabel} name="active" />
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

IntegrationDocumentForm.defaultProps = {
  initialValues: {
    id: '',
    name: '',
    integrationAssignmentDocuments: '',
    active: true,
    fileName: '',
    groups: [],
  },
  editMode: false,
};

IntegrationDocumentForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    integrationAssignmentDocuments: PropTypes.string,
    fileName: PropTypes.string,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
  editMode: PropTypes.bool,
  toggleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default IntegrationDocumentForm;

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
