/* eslint-disable class-methods-use-this */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatMoney } from 'utils/helpers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class BillingPdf {
  constructor(data) {
    if (!data) throw new Error('Obiekt konfiguracyjny jest wymagany.');

    this.data = data;

    this.pdf = null;
    this.pdfDefinition = null;
    this.style = {
      lineWidth: 0.5,
      lineColor: '#FF0000',
      tableFill: '#dddddd',
    };

    this.mapTypeValue = (value) => {
      const map = {
        D: 'Dochodowość',
        S: 'Sezon',
        L: 'Lead',
      };
      return map[value] || '';
    };

    this.fields = [
      {
        value: 'RoE_WynagrJednWartosc',
        count: 'RoE_WynagrJednLiczbaPOS',
        valueLabel: 'Naliczona wartość jednorazowego wynagrodzenia (CRM)',
        countLabel: 'Liczba terminali POS stanowiąca podstawę jednorazowego wynagrodzenia (CRM)',
      },
      {
        value: 'RoE_WynagrJednPodpUmowyWartosc',
        count: 'RoE_WynagrJednPodpUmowyLiczbaPOS',
        valueLabel: 'Naliczona wartość jednorazowego wynagrodzenia (podpisanie umowy)',
        countLabel: 'Liczba terminali POS stanowiąca podstawę jednorazowego wynagrodzenia za (podpisanie umowy)',
      },
      {
        value: 'RoE_WynagrJednInstalacjaWartosc',
        count: 'RoE_WynagrJednInstalacjaLiczbaPOS',
        valueLabel: 'Naliczona wartość jednorazowego wynagrodzenia (instalacja terminala)',
        countLabel: 'Liczba terminali POS stanowiąca podstawę jednorazowego wynagrodzenia (instalacja terminala)',
      },
      {
        value: 'RoE_WynagrCyklWartosc',
        count: 'RoE_WynagrCyklLiczbaPOS',
        valueLabel: `Naliczona wartość wynagrodzenia cyklicznego za ${this.data.root.RoN_RozliczenieZa}`,
        countLabel: 'Liczba terminali POS stanowiąca podstawę wynagrodzenia cyklicznego (CRM)',
      },
    ];

    this.validFields = [];

    const baseColumns = 7;

    this.layout = {
      baseColumns: baseColumns,
      allColumns: baseColumns + this.fields.length * 2,
      validColumns: baseColumns,
    };

    this.fields.forEach((field) => {
      if (this.data.total[field.value] !== 0) {
        this.validFields.push(field);
        this.layout.validColumns += 2;
      }
    });
  }

  _createRows(rows) {
    return rows.map((billing) => {
      const baseFields = [
        {
          text: billing.RoE_Partner,
          style: ['center'],
        },
        {
          text: billing.RoE_NIP,
          style: ['center'],
        },
        {
          text: billing.RoE_TID,
          style: ['center'],
        },
        {
          text: billing.RoE_NazwaPotencjalnegoAkceptanta,
          style: ['center'],
        },
        {
          text: billing.RoE_NIPPotencjalnegoAkceptanta,
          style: ['center'],
        },
        {
          text: billing.RoE_DataRejestracjiLeada,
          style: ['center'],
        },
        {
          text: this.mapTypeValue(billing.RoE_Typ),
          style: ['center'],
        },
      ];

      const extraFields = this.validFields.map((field) => [
        {
          text: billing[field.count],
          style: ['center'],
        },
        {
          text: formatMoney(billing[field.value]),
          style: ['center'],
        },
      ]);

      return [...baseFields].concat(...extraFields);
    });
  }

  _createSummaryRows(totals) {
    const extraFields = this.validFields.map((field) => [
      {
        text: totals[field.count],
        style: ['center', 'bold', 'tableMedium', 'tablePadding'],
        border: [false, false],
      },
      {
        text: formatMoney(totals[field.value]),
        style: ['center', 'bold', 'tableMedium', 'tablePadding'],
        border: [false, false],
      },
    ]);

    return [
      [
        {
          text: 'Razem',
          style: ['center', 'tableMedium', 'bold', 'tablePadding'],
          border: [false, false],
        },
        {
          colSpan: this.layout.baseColumns - 1,
          text: '',
          border: [false, false],
        },
        ...Array(this.layout.baseColumns - 2).fill({}),
        ...extraFields.flat(),
      ],
    ];
  }

  _createMainTable() {
    if (this.data.data.length === 0)
      return {
        text: 'Brak zgłoszonych leadów',
        style: ['bold', 'spaceBottom', 'tableBig'],
      };

    const extraFields = this.validFields.map((field) => [
      {
        text: field.countLabel,
        style: ['tableHeadCenter'],
      },
      {
        text: field.valueLabel,
        style: ['tableHeadCenter'],
      },
    ]);

    return {
      table: {
        widths: Array(this.layout.validColumns).fill('auto'),
        body: [
          [
            {
              text: 'Partner',
              style: ['tableHeadCenter'],
            },
            {
              text: 'NIP',
              style: ['tableHeadCenter'],
            },
            {
              text: 'TID',
              style: ['tableHeadCenter'],
            },
            {
              text: 'Nazwa Potencjalnego\nAkceptanta',
              style: ['tableHeadCenter'],
            },
            {
              text: 'Nip Potencjalnego\nAkceptanta',
              style: ['tableHeadCenter'],
            },
            {
              text: 'Data rejestracji Leada',
              style: ['tableHeadCenter'],
            },
            {
              text: 'Typ',
              style: ['tableHeadCenter'],
            },
            ...extraFields.flat(),
          ],
          ...this._createRows(this.data.data),
          ...this._createSummaryRows(this.data.total),
        ],
      },
      layout: {
        hLineWidth: function () {
          return 0.5;
        },
        vLineWidth: function () {
          return 0.5;
        },
      },
      style: ['table', 'spaceBottomBig'],
    };
  }

  _createSummaryTable() {
    if (this.data.roz_pods.length === 0) return null;

    return [
      {
        table: {
          widths: ['10%', '65%', '25%'],
          body: [
            [
              {
                text: 'L.P.',
                style: ['bold', 'tablePaddingSmall', 'big'],
              },
              {
                text: 'Nazwa pozycji',
                style: ['bold', 'tablePaddingSmall', 'left', 'big'],
              },
              {
                text: 'Kwota',
                style: ['bold', 'tablePaddingSmall', 'big'],
              },
            ],
            [
              {
                text: '#',
                style: ['bold', 'tablePaddingSmall', 'red', 'big'],
              },
              {
                text: 'Suma na fakturze',
                style: ['bold', 'tablePaddingSmall', 'left', 'red', 'big'],
              },
              {
                text: formatMoney(this.data.total.sumRozPods),
                style: ['bold', 'tablePaddingSmall', 'red', 'big'],
              },
            ],
          ],
        },
        style: ['spaceBottomSmall', 'tableBig', 'center'],
      },
    ];
  }

  _createBottomTable() {
    if (this.data.roz_pods.length === 0) return null;

    const rows = this.data.roz_pods.map((pod, i) => [
      {
        text: i + 1,
        style: ['tablePaddingSmall'],
      },
      {
        text: pod.RoP_TowarNazwa,
        style: ['left', 'tablePaddingSmall'],
      },
      {
        text: formatMoney(pod.RoP_TowarCena),
        style: ['tablePaddingSmall'],
      },
    ]);

    return [
      {
        text: 'UWAGA',
        style: ['bold', 'spaceBottomSmall', 'tableBig'],
      },
      {
        text: 'W celu terminowego rozliczenia wynagrodzenia prosimy o wystawienie faktury do 14-go dnia miesiąca.',
        style: ['spaceBottomSmall', 'tableMedium'],
      },
      {
        table: {
          widths: ['10%', '65%', '25%'],
          body: [
            [
              {
                text: 'L.P.',
                style: ['bold', 'tablePaddingSmall'],
              },
              {
                text: 'Nazwa pozycji',
                style: ['bold', 'tablePaddingSmall', 'left'],
              },
              {
                text: 'Kwota',
                style: ['bold', 'tablePaddingSmall'],
              },
            ],
            ...rows,
          ],
        },
        style: ['spaceBottomSmall', 'tableBig', 'center'],
      },
      {
        text: '* Nie dotyczy Partnerów, którzy korzystają z formy samo fakturowania lub zatwierdzonej propozycji faktury.',
        style: ['tableMedium', 'bold'],
      },
    ];
  }

  _createContent() {
    return [
      {
        text: `Rozliczenie za okres: ${this.data.root.RoN_RozliczenieZa}`,
        style: ['title'],
      },
      {
        text: [
          {
            text: `Partner: `,
            style: 'bold',
          },
          {
            text: this.data.root.RoN_PartnerNazwa,
          },
        ],
      },
      {
        text: [
          {
            text: `NIP: `,
            style: 'bold',
          },
          {
            text: this.data.root.RoN_PartnerNIP,
          },
        ],
      },
      {
        text: [
          {
            text: `Data wygenerowania: `,
            style: 'bold',
          },
          {
            text: this.data.root.RoN_DataGenerowania,
          },
        ],
        style: ['spaceBottom'],
      },
      this._createMainTable(),
      {
        columns: [this._createBottomTable(), this._createSummaryTable()],
        columnGap: 20,
      },
    ];
  }

  _createDefinition() {
    const cols = this.layout.validColumns;
    let tableHeadFontSize = 7;
    if (cols > 11) tableHeadFontSize = 6;
    if (cols > 13) tableHeadFontSize = 5;

    return {
      info: {
        title: `Rozliczenie za okres: ${this.data.root.RoN_RozliczenieZa}`,
        subject: `Rozlcizenie dla ${this.data.root.RoN_PartnerNazwa}`,
        author: 'PeP - Rozliczenia',
      },
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: [30, 30, 30, 30],
      content: this._createContent(),
      defaultStyle: {
        fontSize: 8,
        lineHeight: 1.2,
      },
      styles: {
        header: {
          margin: [40, 10],
        },
        footer: {
          margin: [40, 12],
          alignment: 'right',
          fontSize: 12,
        },
        title: {
          alignment: 'center',
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 12],
        },
        table: {
          fontSize: 7,
          alignment: 'right',
        },
        tableMedium: {
          fontSize: 9,
        },
        tableBig: {
          fontSize: 10,
        },
        tablePadding: {
          margin: [0, 6, 0, 6],
        },
        tablePaddingSmall: {
          margin: [0, 3, 0, 3],
        },
        tableHeadCenter: {
          fontSize: tableHeadFontSize,
          bold: true,
          alignment: 'center',
          margin: [0, 2, 0, 2],
        },
        tableHeadAlign: {
          margin: [0, 18, 0, 0],
        },
        tableSeparator: {
          margin: [0, 1, 0, 2],
        },
        productRow: {
          margin: [0, 5, 0, 5],
        },
        left: {
          alignment: 'left',
        },
        center: {
          alignment: 'center',
        },
        right: {
          alignment: 'right',
        },
        bold: {
          bold: true,
        },
        red: {
          color: '#CC3333',
        },
        big: {
          fontSize: 11,
          bold: true,
        },
        spaceBottomBig: {
          margin: [0, 0, 0, 60],
        },
        spaceBottomMedium: {
          margin: [0, 0, 0, 48],
        },
        spaceBottom: {
          margin: [0, 0, 0, 24],
        },
        spaceBottomSmall: {
          margin: [0, 0, 0, 12],
        },
      },
    };
  }

  getPdf() {
    return this.pdf;
  }

  getDefinition() {
    return this.pdfDefinition;
  }

  createDefinition() {
    const definition = this._createDefinition();
    this.pdfDefinition = definition;
  }

  build() {
    this.createDefinition();
    this.pdf = pdfMake.createPdf(this.pdfDefinition);
    return this.pdf;
  }

  download(filename) {
    if (!this.pdf) throw new Error('Brak wygenerowanego pdf');
    try {
      this.pdf.download(filename || `Rozliczenie za ${this.data.root.RoN_RozliczenieZa}.pdf`);
    } catch (err) {
      throw new Error(err);
    }
  }
}
