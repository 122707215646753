import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from 'services/socket';
import { chatModes } from 'config/constants';
import {
  setConversations,
  addMessage,
  clearChat,
  setConversationsError,
  setConversationsLoading,
  checkUnreadConversations,
  addConversation,
  setIsLoadingLeads,
  setIsLoadingPatrons,
  clearConversations,
  setArchivedConversations,
} from 'redux/chat';

export default function Messages() {
  const dispatch = useDispatch();

  const isSocketConnected = useSelector(state => state.socket.connected);
  const loggedUserId = useSelector(state => state.user.data.id);
  const activeConversation = useSelector(s => s.chat.activeConversation.conversation);
  const allConversations = useSelector(s => s.chat.allConversations.data);
  // const chatMode = useSelector(s => s.chat.chatMode);

  const getConversation = useCallback(
    (conversationId, type) => {
      if (!isSocketConnected) return;
      dispatch(setConversationsLoading(true));
      socket.emit(
        `chat:${type}:conversation`,
        {
          conversationId,
        },
        response => {
          dispatch(setConversationsLoading(false));
          if (response && response.data) {
            dispatch(addConversation(response.data));
            dispatch(checkUnreadConversations(loggedUserId));
          } else dispatch(setConversationsError(response.error.name || 'Wystąpił bląd'));
        },
      );
    },
    [dispatch, isSocketConnected, loggedUserId],
  );

  const getConversations = useCallback(
    (type, mode) => {
      dispatch(setConversationsLoading(true));
      dispatch(type === 'lead' ? setIsLoadingLeads(true) : setIsLoadingPatrons(true));

      let eventName = `chat:${type}:conversations`;
      if (mode === chatModes.archive) eventName = `chat:${type}:archives`;

      socket.emit(eventName, {}, response => {
        dispatch(setConversationsLoading(false));
        dispatch(type === 'lead' ? setIsLoadingLeads(false) : setIsLoadingPatrons(false));
        if (response && response.data) {
          if (mode === chatModes.archive) {
            dispatch(setArchivedConversations(response.data));
          } else {
            dispatch(setConversations(response.data));
            dispatch(checkUnreadConversations(loggedUserId));
          }
        } else dispatch(setConversationsError(response.error.name || 'Wystąpił bląd'));
      });
    },
    [dispatch, loggedUserId],
  );

  useEffect(() => {
    if (!isSocketConnected) return;
    dispatch(clearConversations());
    getConversations('lead');
    getConversations('patron');
    getConversations('lead', chatModes.archive);
    getConversations('patron', chatModes.archive);
  }, [getConversations, isSocketConnected, dispatch]);

  useEffect(() => {
    return () => dispatch(clearChat());
  }, [dispatch]);

  // Handle new messages
  useEffect(() => {
    if (!isSocketConnected) return () => {};

    const handleFn = response => {
      if (!response) return;
      // Jeżeli jest to wiadomość do konwersacji której nie ma na liście to ją pobierz
      if (!allConversations.some(c => c.id === response.wsConversationId)) {
        const type = response.conversation?.type;
        if (type) getConversation(response.wsConversationId, String(type).toLocaleLowerCase());
        else {
          getConversation(response.wsConversationId, 'lead');
          getConversation(response.wsConversationId, 'patron');
        }
      }
      dispatch(addMessage(response, response.wsConversationId));
      dispatch(checkUnreadConversations(loggedUserId));
    };

    socket.on('chat:created-message', handleFn);

    return () => {
      socket.off('chat:created-message', handleFn);
    };
  }, [dispatch, isSocketConnected, activeConversation, loggedUserId, allConversations, getConversation]);

  return null;
}
