/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, font } from 'theme/styles';
import Tooltip from './Tooltip';

const Checkbox = ({
  name,
  placeholder,
  onChange,
  onBlur,
  className,
  value,
  error,
  label,
  checked,
  disabled,
  style,
  tooltip,
}) => {
  return (
    <CheckboxLabel style={style} error={error} disabled={disabled}>
      <StyledCheckbox
        name={name}
        type="checkbox"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={className}
        error={error}
        disabled={disabled}
        checked={checked}
      />
      <span className="label" />
      {Array.isArray(label) ? label.map((el, i) => <span key={i}>{el}</span>) : <span>{label}</span>}
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </CheckboxLabel>
  );
};

Checkbox.defaultProps = {
  className: '',
  placeholder: '',
  error: false,
  checked: false,
  disabled: false,
  style: null,
  tooltip: '',
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  tooltip: PropTypes.string,
};

export default Checkbox;

// Styled components:

const CheckboxLabel = styled.label`
  display: block;
  font-size: ${font.size.xm};
  color: ${colors.black};
  line-height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  user-select: none;

  & > span.label {
    display: block;
    float: left;
    margin-right: 10px;
    position: relative;
    width: 18px;
    height: 18px;
    background: ${colors.white};
    border: 1px solid ${colors.orange};

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      top: 50%;
      left: 50%;
      background-color: ${colors.orange};
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.15s ease-out;
    }
  }

  .new-product {
    color: ${colors.orange};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: initial;
      color: ${colors.lightGray};

      .new-product {
        color: inherit;
      }
    `}

  ${(props) =>
    props.error &&
    css`
      & + * {
        margin-bottom: 0;
      }

      & > span.label {
        border-color: ${colors.red};
      }
    `}
`;

const StyledCheckbox = styled.input`
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  display: none;

  &:disabled + span.label {
    border-color: ${colors.lightGray};
  }
  &:disabled + span.label::after {
    background-color: ${colors.lightGray};
  }
  &:checked + span.label::after {
    transform: translate(-50%, -50%) scale(1);
  }
`;
