import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, colors } from 'theme/styles';

// Komponent zadziała tylko wewnątrz FORMIKa (dostaje propsy field i form)

const ImageInput = ({ field, form, id, accept, disabled }) => {
  const [filename, setFilename] = useState('');

  const handleChange = e => {
    const file = e.currentTarget.files[0];
    form.setFieldValue(field.name, file);
    if (file) {
      setFilename(file.name);
      form.handleSubmit(form.values);
    }
  };

  const handleReset = () => {
    setFilename('');
    form.setFieldValue(field.name, null);
  };

  useEffect(() => {
    if (!field.value) {
      setFilename('');
    }
  }, [field.value]);

  return (
    <StyledImageInput>
      <label htmlFor={id}>Zaktualizuj zdjęcie</label>
      <span>{filename ? <ResetButton onClick={handleReset}>| usuń</ResetButton> : ''}</span>

      <input
        type="file"
        accept={accept}
        id={id}
        disabled={disabled}
        onChange={file => handleChange(file)}
      />
    </StyledImageInput>
  );
};

ImageInput.defaultProps = {
  accept: 'image/png, image/jpeg',
  disabled: false,
};

ImageInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ImageInput;

const StyledImageInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    height: 27px;
    min-width: 128px;
    border-radius: 3px;
    font-size: ${font.size.s};
    font-weight: ${font.weight.default};
    padding: 0px 20px;
    background: ${colors.white};
    color: ${colors.black};
    border: 1px solid ${colors.orange};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: ${colors.orange};
      text-decoration: none;
    }
  }
  span {
    font-size: ${font.size.s};
    font-weight: ${font.weight.default};
    color: ${colors.black};
    margin: 10px 0;
  }
  input {
    width: 1px;
    height: 1px;
    opacity: 0;
  }
`;

const ResetButton = styled.button`
  font-size: ${font.size.s};
  font-weight: ${font.weight.default};
  color: ${colors.red};
  margin: 0;
  padding: 0;
  margin-left: 5px;
  border: none;
  outline: none;
  background: transparent;
  display: inline-block;
  cursor: pointer;
`;
