export default class Terminal {
  constructor(type = 1, connectionType = 1, installationType = 1, notes = '', installationId = '', count = 1) {
    this.type = type;
    this.connectionType = connectionType;
    this.installationType = installationType;
    this.installationId = installationId;
    this.notes = notes;
    this.count = count;
  }
}
