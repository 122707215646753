import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { colors, font } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChallengeList = ({ challenges, onPick }) => {
  const [pickedId, setPickedId] = useState(null);

  const onPickHandler = useCallback(
    el => {
      onPick(el);
      setPickedId(el.id);
    },
    [onPick],
  );

  return (
    <List>
      {challenges.map(el => (
        <Item
          picked={pickedId === el.id}
          key={el.id}
          isActive={el.isActive}
          isSpecial={el.special}
          onClick={() => onPickHandler(el)}
        >
          <div>
            {el.special && <IsSpecialIcon icon="burn" />}
            <ItemTitle>{el.name.charAt(0).toUpperCase() + el.name.slice(1)}</ItemTitle>
          </div>
          <ItemContent>
            {el.description.charAt(0).toUpperCase() + el.description.slice(1)}
          </ItemContent>
          <ItemInfo>
            <span>
              <strong>Data zakończenia:</strong> {moment(el.dateTo).format('DD-MM-YYYY')}
            </span>
            <span>
              <strong> {el.type.charAt(0).toUpperCase() + el.type.slice(1)} </strong>
            </span>
          </ItemInfo>
        </Item>
      ))}
    </List>
  );
};

ChallengeList.propTypes = {
  challenges: PropTypes.instanceOf(Array).isRequired,
  onPick: PropTypes.func.isRequired,
};

export default ChallengeList;

const List = styled.section`
  max-height: 75vh;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  & > *:not(:last-child) {
    margin-bottom: 10px;
    border-bottom: 1px solid ${colors.lighterGray};
  }
`;

const Item = styled.div`
  position: relative;
  padding: 6px 12px 6px 12px;
  /* padding: 6px 30px 6px 12px; */
  border-left: 4px solid;
  border-left-color: ${props => (props.isActive ? colors.greenDark : colors.lightGray)};
  transition: background-color 0.3s;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
    background-color: ${colors.lighterGray};
  }
  ${props =>
    props.picked &&
    css`
      background-color: ${colors.blueGray};
    `};

  ${props =>
    !props.isActive &&
    css`
      opacity: 0.5;
      text-decoration: line-through;
    `};
`;

const ItemTitle = styled.span`
  color: ${colors.orange};
  font-weight: 600;
`;

const ItemContent = styled.p`
  margin: 5px 0;
  color: ${colors.mediumGray};
  font-size: ${font.size.s};
`;

const ItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${font.size.s};
`;

const IsSpecialIcon = styled(FontAwesomeIcon)`
  color: ${colors.orange};
  position: absolute;
  font-size: 1em;
  right: 10px;
  top: 50%;
  top: 10px;
`;

// const TypeIcon = styled(FontAwesomeIcon)`
//   color: ${colors.orange};
//   position: absolute;
//   right: 10px;
//   top: 10px;
// `;
