export const CLEAR_CLEARITY = 'CLEAR_CLEARITY';
export const CLEAR_CLEARITY_CRM_CHECK = 'CLEAR_CLEARITY_CRM_CHECK';
export const SET_CLEARITY_ERROR = 'SET_CLEARITY_ERROR';
export const SET_CLEARITY_LOADING = 'SET_CLEARITY_LOADING';

export const GET_GRAYLOG_LOG_REQUEST = 'GET_GRAYLOG_LOG_REQUEST';
export const GET_GRAYLOG_LOG_SUCCESS = 'GET_GRAYLOG_LOG_SUCCESS';
export const GET_GRAYLOG_LOG_FAILURE = 'GET_GRAYLOG_LOG_FAILURE'; 

export const GET_SP_OPTIONS_REQUEST = 'GET_SP_OPTIONS_REQUEST';
export const GET_SP_OPTIONS_SUCCESS = 'GET_SP_OPTIONS_SUCCESS';
export const GET_SP_OPTIONS_FAILURE = 'GET_SP_OPTIONS_FAILURE'; 

export const GET_CRM_CHECK_REQUEST = 'GET_CRM_CHECK_REQUEST';
export const GET_CRM_CHECK_SUCCESS = 'GET_CRM_CHECK_SUCCESS';
export const GET_CRM_CHECK_FAILURE = 'GET_CRM_CHECK_FAILURE'; 

export const GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_REQUEST = 'GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_REQUEST';
export const GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_SUCCESS = 'GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_SUCCESS';
export const GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_FAILURE = 'GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_FAILURE'; 

export const GET_MERCHANTS_LEADS_ANALYTICS_REQUEST = 'GET_MERCHANTS_LEADS_ANALYTICS_REQUEST';
export const GET_MERCHANTS_LEADS_ANALYTICS_SUCCESS = 'GET_MERCHANTS_LEADS_ANALYTICS_SUCCESS';
export const GET_MERCHANTS_LEADS_ANALYTICS_FAILURE = 'GET_MERCHANTS_LEADS_ANALYTICS_FAILURE'; 

export const GET_MERCHANTS_LEADS_ANALYTICS_PH_REQUEST = 'GET_MERCHANTS_LEADS_ANALYTICS_PH_REQUEST';
export const GET_MERCHANTS_LEADS_ANALYTICS_PH_SUCCESS = 'GET_MERCHANTS_LEADS_ANALYTICS_PH_SUCCESS';
export const GET_MERCHANTS_LEADS_ANALYTICS_PH_FAILURE = 'GET_MERCHANTS_LEADS_ANALYTICS_PH_FAILURE'; 

export const GET_COMPANY_ANALYTICS_REQUEST = 'GET_COMPANY_ANALYTICS_REQUEST';
export const GET_COMPANY_ANALYTICS_SUCCESS = 'GET_COMPANY_ANALYTICS_SUCCESS';
export const GET_COMPANY_ANALYTICS_FAILURE = 'GET_COMPANY_ANALYTICS_FAILURE';   

export const GET_COUNTY_ANALYTICS_REQUEST = 'GET_COUNTY_ANALYTICS_REQUEST';
export const GET_COUNTY_ANALYTICS_SUCCESS = 'GET_COUNTY_ANALYTICS_SUCCESS';
export const GET_COUNTY_ANALYTICS_FAILURE = 'GET_COUNTY_ANALYTICS_FAILURE';

export const GET_EMAIL_TEMPLATES_REQUEST = 'GET_EMAIL_TEMPLATES_REQUEST';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAILURE = 'GET_EMAIL_TEMPLATES_FAILURE';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_ALL_STRUCTURES_REQUEST = 'GET_ALL_STRUCTURES_REQUEST';
export const GET_ALL_STRUCTURES_SUCCESS = 'GET_ALL_STRUCTURES_SUCCESS';
export const GET_ALL_STRUCTURES_FAILURE = 'GET_ALL_STRUCTURES_FAILURE';

export const GET_ALL_PEP_STRUCTURES_REQUEST = 'GET_ALL_PEP_STRUCTURES_REQUEST';
export const GET_ALL_PEP_STRUCTURES_SUCCESS = 'GET_ALL_PEP_STRUCTURES_SUCCESS';
export const GET_ALL_PEP_STRUCTURES_FAILURE = 'GET_ALL_PEP_STRUCTURES_FAILURE';

export const GET_ALL_ROLES_REQUEST = 'GET_ALL_ROLES_REQUEST';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAILURE = 'GET_ALL_ROLES_FAILURE';

export const GET_ALL_COUNTIES_REQUEST = 'GET_ALL_COUNTIES_REQUEST';
export const GET_ALL_COUNTIES_SUCCESS = 'GET_ALL_COUNTIES_SUCCESS';
export const GET_ALL_COUNTIES_FAILURE = 'GET_ALL_COUNTIES_FAILURE';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_ALL_COMPANIES_REQUEST = 'GET_ALL_COMPANIES_REQUEST';
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS';
export const GET_ALL_COMPANIES_FAILURE = 'GET_ALL_COMPANIES_FAILURE';

export const GET_COMPANIES_SUGGESTIONS_REQUEST = 'GET_COMPANIES_SUGGESTIONS_REQUEST';
export const GET_COMPANIES_SUGGESTIONS_SUCCESS = 'GET_COMPANIES_SUGGESTIONS_SUCCESS';
export const GET_COMPANIES_SUGGESTIONS_FAILURE = 'GET_COMPANIES_SUGGESTIONS_FAILURE';

export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const GET_COMPANY_USERS_REQUEST = 'GET_COMPANY_USERS_REQUEST';
export const GET_COMPANY_USERS_SUCCESS = 'GET_COMPANY_USERS_SUCCESS';
export const GET_COMPANY_USERS_FAILURE = 'GET_COMPANY_USERS_FAILURE';

export const GET_ALL_API_KEYS_REQUEST = 'GET_ALL_API_KEYS_REQUEST';
export const GET_ALL_API_KEYS_SUCCESS = 'GET_ALL_API_KEYS_SUCCESS';
export const GET_ALL_API_KEYS_FAILURE = 'GET_ALL_API_KEYS_FAILURE';

export const GET_SINGLE_API_KEY_REQUEST = 'GET_SINGLE_API_KEY_REQUEST';
export const GET_SINGLE_API_KEY_SUCCESS = 'GET_SINGLE_API_KEY_SUCCESS';
export const GET_SINGLE_API_KEY_FAILURE = 'GET_SINGLE_API_KEY_FAILURE';

export const GET_ALL_VACATIONS_REQUEST = 'GET_ALL_VACATIONS_REQUEST';
export const GET_ALL_VACATIONS_SUCCESS = 'GET_ALL_VACATIONS_SUCCESS';
export const GET_ALL_VACATIONS_FAILURE = 'GET_ALL_VACATIONS_FAILURE';

export const GET_VACATION_REQUEST = 'GET_VACATION_REQUEST';
export const GET_VACATION_SUCCESS = 'GET_VACATION_SUCCESS';
export const GET_VACATION_FAILURE = 'GET_VACATION_FAILURE';

export const GET_ALL_CRONS_REQUEST = 'GET_ALL_CRONS_REQUEST';
export const GET_ALL_CRONS_SUCCESS = 'GET_ALL_CRONS_SUCCESS';
export const GET_ALL_CRONS_FAILURE = 'GET_ALL_CRONS_FAILURE';

export const GET_CRON_REQUEST = 'GET_CRON_REQUEST';
export const GET_CRON_SUCCESS = 'GET_CRON_SUCCESS';
export const GET_CRON_FAILURE = 'GET_CRON_FAILURE';

export const GET_CRON_LOGS_REQUEST = 'GET_CRON_LOGS_REQUEST';
export const GET_CRON_LOGS_SUCCESS = 'GET_CRON_LOGS_SUCCESS';
export const GET_CRON_LOGS_FAILURE = 'GET_CRON_LOGS_FAILURE';

export const SET_ALL_CRONS_ERROR = 'SET_CURRESET_ALL_CRONS_ERRORNT_CRON';
export const SET_ALL_CRONS_LOADING = 'SET_ALL_CRONS_LOADING';
export const CLEAR_ALL_CRONS = 'CLEAR_ALL_CRONS';

export const SET_CURRENT_CRON = 'SET_CURRENT_CRON';
export const SET_CURRENT_CRON_ERROR = 'SET_CURRENT_CRON_ERROR';
export const SET_CURRENT_CRON_LOADING = 'SET_CURRENT_CRON_LOADING';
export const CLEAR_CURRENT_CRON = 'CLEAR_CURRENT_CRON';

export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const CLEAR_CURRENT_COMPANY = 'CLEAR_CURRENT_COMPANY';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export const SET_CURRENT_VACATION = 'SET_CURRENT_VACATION';
export const CLEAR_CURRENT_VACATION = 'CLEAR_CURRENT_VACATION';

export const CLEAR_CURRENT_MERCHANT_ANALYTICS = 'CLEAR_CURRENT_MERCHANT_ANALYTICS';

export const CLEAR_ADMIN = 'CLEAR_ADMIN';
