import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { userService } from 'services/user';
import FileInput from 'components/UI/FileInput';
import ErrorLabel from 'components/UI/ErrorLabel';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import { uploadCsv } from 'utils/validation';
import styled from 'styled-components';
import { colors, font } from 'theme/styles';

const UsersBulkImport = ({ onSucces }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = values => {
    const { csv } = values;
    setLoading(true);
    setError('');

    userService
      .usersBulkImport(csv)
      .then(res => {
        console.log(res);
        setLoading(false);
        if (onSucces) onSucces(res);
      })
      .catch(err => {
        console.log(err);
        setError(err.response?.data?.error?.message || 'Coś poszło nie tak.');
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading absolute />}
      <>
        <Heading>Wybierz plik</Heading>
        <p>
          Wybierz plik <span style={{ color: colors.red, fontWeight: 700 }}>csv</span> z listą użytkowników do
          zaimportowania.
        </p>
        <Formik
          initialValues={{
            csv: null,
          }}
          validationSchema={uploadCsv}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values);
            resetForm({});
            setSubmitting(false);
          }}
        >
          {formik => (
            <StyledForm>
              <Field name="csv" id="csv" accept="text/csv" component={FileInput} />
              <ErrorMessage component={ErrorLabel} name="csv" />
              {formik.dirty && formik.isValid && (
                <Button disabled={!formik.dirty || !formik.isValid} type="submit" style={{ margin: '30px auto 0' }}>
                  Importuj
                </Button>
              )}
            </StyledForm>
          )}
        </Formik>
        {error && <p style={{ color: colors.red }}>{error}</p>}
        <div>
          <StyledLink href="/bulk-import-partner-example.csv" download>
            Pobierz przykładowy plik importu
          </StyledLink>
        </div>
      </>
    </>
  );
};

UsersBulkImport.defaultProps = {};

UsersBulkImport.propTypes = {
  onSucces: PropTypes.func.isRequired,
};

export default UsersBulkImport;
const StyledForm = styled(Form)`
  margin-top: 10px;
`;

const StyledLink = styled.a`
  display: inline-block;
  margin-top: 3rem;
  font-size: ${font.size.s};

  &:link,
  &:active {
    color: ${colors.orange};
  }

  &:hover,
  &:focus {
    color: ${colors.red};
  }
`;
