/* eslint-disable no-console */
import React, { useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { setPasswordSchema } from 'utils/validation';
import { userService } from 'services/user';
import { font, colors } from 'theme/styles';
import { routes } from 'routes';
import usePopup from 'hooks/usePopup';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import AuthTemplate from 'templates/AuthTemplate';
import Loading from 'components/UI/Loading';

const SetPasswordPage = () => {
  const { id, token } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [SuccessPopup, toggleSuccessPopup] = usePopup();
  const [ErrorPopup, toggleErrorPopup, setErrorVisible] = usePopup({
    type: 'error'
  });

  const handleSubmit = values => {
    setLoading(true);
    userService
      .setPassword(values.password, id, token)
      .then(() => {
        setLoading(false);
        toggleSuccessPopup();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setError(err.response.data.error.message);
        toggleErrorPopup()
      });
  };

  return (
    <AuthTemplate>
      {isLoading ? <Loading absolute /> : ''}
      <ErrorPopup fixed>
        <Heading centered size="xl">Wystąpił błąd</Heading>
        <p>{error}</p>
        <Button style={{ marginTop: '40px' }} centered onClick={() => {
          setErrorVisible(false);
          setError(false)
        }}>
          Ok
        </Button>
      </ErrorPopup>
      <SuccessPopup fixed>
        <Heading centered size="xl">Hasło zostało ustawione pomyślnie.</Heading>
        <Button style={{ marginTop: '40px' }} centered onClick={() => history.push('/login')}>
          Przejdź do logowania
        </Button>
      </SuccessPopup>
      <Heading centered>Ustaw nowe hasło</Heading>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={setPasswordSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm({});
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => {
          return (
            <StyledForm>
              <Input
                name="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Hasło"
                value={values.password || ''}
                error={touched.password && errors.password}
              />
              <ErrorMessage component={ErrorLabel} name="password" />
              <Input
                name="passwordConfirmation"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Powtórz hasło"
                value={values.passwordConfirmation || ''}
                error={touched.passwordConfirmation && errors.passwordConfirmation}
              />
              <ErrorMessage component={ErrorLabel} name="passwordConfirmation" />
              <Button style={{ marginTop: '20px' }} centered type="submit">
                Prześlij
              </Button>
            </StyledForm>
          );
        }}
      </Formik>
      <StyledLink to={routes.login}>Powrót do logowania</StyledLink>
    </AuthTemplate>
  );
};

export default SetPasswordPage;

// styled components

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
`;

const StyledLink = styled(Link)`
  position: relative;
  bottom: -30px;
  font-size: ${font.size.s};
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  color: ${colors.darkGray};

  &:hover {
    color: ${colors.red};
  }
`;
