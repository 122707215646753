import * as types from './types';

const initialState = {
  allP24Leads: {
    isLoading: false,
    data: [],
    count: 0,
    totalPages: 0,
    currentPage: 0,
    error: null,
  },
};

const leadP24Reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_P24_LEADS_REQUEST:
      return {
        ...state,
        allP24Leads: {
          ...state.allP24Leads,
          isLoading: true,
        },
      };
    case types.GET_P24_LEADS_SUCCESS:
      return {
        ...state,
        allP24Leads: {
          ...state.allP24Leads,
          isLoading: false,
          data: action.payload.data.rows,
          count: action.payload.data.count,
          totalPages: action.payload.data.pagination.totalPages,
          currentPage: action.payload.data.pagination.currentPage,
          error: null,
        },
      };
    case types.GET_P24_LEADS_FAILURE:
      return {
        ...state,
        allP24Leads: {
          ...state.allP24Leads,
          isLoading: false,
          data: [],
          count: 0,
          totalPages: 0,
          currentPage: 0,
          error: action.err.response.data.error,
        },
      };
    case types.CLEAR_LEADS:
      return initialState;
    default:
      return state;
  }
};

export default leadP24Reducer;
