import makeRequest, { requestWithFiles } from 'services/makeRequest';

const TRAINING_BASE_URL = '/study-materials';

const getTrainingMaterials = (params) =>
  makeRequest({
    method: 'get',
    url: TRAINING_BASE_URL,
    params,
  });

const getTrainingMaterial = (id) =>
  makeRequest({
    method: 'get',
    url: `${TRAINING_BASE_URL}/${id}`,
  });

const getTrainingMaterialFile = (resourceId) =>
  makeRequest({
    method: 'get',
    url: `${TRAINING_BASE_URL}/${resourceId}/resource`,
    responseType: 'blob',
  });

const getTrainingMaterialFilesZip = (id) =>
  makeRequest({
    method: 'get',
    url: `${TRAINING_BASE_URL}/${id}/zip`,
    responseType: 'blob',
  });

const addTrainingMaterial = (data) =>
  requestWithFiles({
    method: 'post',
    url: TRAINING_BASE_URL,
    data,
  });

const deleteTrainingMaterial = (id) =>
  makeRequest({
    method: 'post',
    url: `${TRAINING_BASE_URL}/${id}/delete`,
  });

export const trainingService = {
  getTrainingMaterials,
  getTrainingMaterial,
  getTrainingMaterialFile,
  getTrainingMaterialFilesZip,
  addTrainingMaterial,
  deleteTrainingMaterial,
};
