import React, { useState } from 'react';
import CloseButton from 'components/UI/CloseButton';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Heading from 'components/UI/Heading';

export default (props = {}) => {
  const { type, size, hideCloseButton = false } = props;
  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible(!isVisible);

  const PopupComponent = type !== 'error' ? SuccessPopup : ErrorPopup;

  // eslint-disable-next-line react/prop-types
  const Popup = ({ children, title, fixed = false }) =>
    isVisible && (
      <PopupComponent size={size} fixed={fixed}>
        {!hideCloseButton && <CloseButton onClick={toggle} />}
        {title && (
          <Heading size="xl" centered>
            {title}
          </Heading>
        )}
        {children}
      </PopupComponent>
    );

  return [Popup, toggle, setIsVisible, isVisible];
};
