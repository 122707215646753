/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class InvoicePdf {
  constructor(config) {
    if (!config) throw new Error('Obiekt konfiguracyjny jest wymagany.');

    this.config = config;

    this.pdf = null;
    this.pdfDefinition = null;

    this.style = {
      lineWidth: 0.5,
      lineColor: '#999999',
      tableFill: '#dddddd',
    };
  }

  _createLine(size) {
    const options = {
      x1: 0,
      y1: 0,
      x2: 595 - 2 * 40,
      y2: 0,
      extra: {},
    };

    if (size === 'small') {
      options.x1 = 0;
      options.x2 = 40;
      options.extra = { style: 'tableSeparator' };
    } else if (size === 'header' || size === 'footer') {
      options.x1 = 40;
      options.x2 = 595 - 40;
    }

    return {
      canvas: [
        {
          type: 'line',
          lineWidth: this.style.lineWidth,
          lineColor: this.style.lineColor,
          ...options,
        },
      ],
      ...options.extra,
    };
  }

  _createProductsRows(products) {
    return products.map((product, i) => [
      i + 1,
      {
        stack: [
          { text: product.ean ? product.ean : ' ' },
          this._createLine('small'),
          { text: product.sumbol ? product.sumbol : ' ' },
        ],
        style: 'center',
      },
      { text: product.name, alignment: 'left' },
      {
        stack: [{ text: product.quantity }, this._createLine('small'), { text: product.quantityUnit }],
        style: 'center',
      },
      product.unitPrice,
      {
        stack: [
          { text: (product.unitPrice - (product.discountInPerc / 100) * product.unitPrice).toFixed(2) },
          this._createLine('small'),
          { text: `${product.discountInPerc ? product.discountInPerc : 0}%` },
        ],
        style: 'right',
      },
      product.unitPrice,
      {
        stack: [
          { text: ((product.vatRate / 100) * product.unitPrice).toFixed(2) },
          this._createLine('small'),
          { text: `${product.vatRate}%` },
        ],
        style: 'right',
      },
      ((1 + product.vatRate / 100) * product.unitPrice).toFixed(2),
    ]);
  }

  _createFooter() {
    const that = this;
    return (currentPage, pageCount) => {
      return [that._createLine('footer'), { text: currentPage.toString() + '/' + pageCount, style: 'footer' }];
    };
  }

  _createHeader() {
    return { text: `Faktura nr: ${this.config.invoiceNumber}`, style: 'header' };
  }

  _createMainTable() {
    return {
      table: {
        widths: ['2.5%', '*', '30%', '*', '*', '*', '*', '*', '*'],
        body: [
          [
            { text: 'Lp.', style: ['tableHeadCenter', 'center'], fillColor: this.style.tableFill },
            {
              stack: [{ text: 'Symbol' }, this._createLine('small'), { text: 'EAN' }],
              fillColor: this.style.tableFill,
              style: 'center',
            },
            { text: 'Nazwa towaru (PKWiU)', style: ['tableHeadCenter', 'center'], fillColor: this.style.tableFill },
            {
              stack: [{ text: 'Ilość' }, this._createLine('small'), { text: 'Jedn. miary' }],
              fillColor: this.style.tableFill,
              style: 'center',
            },
            { text: 'Cena jedn.', style: ['tableHeadCenter', 'center'], fillColor: this.style.tableFill },
            {
              stack: [{ text: 'C.po upuście' }, this._createLine('small'), { text: 'Upust (%)' }],
              fillColor: this.style.tableFill,
              style: 'center',
            },
            { text: 'Wartość netto', style: ['tableHeadCenter', 'center'], fillColor: this.style.tableFill },
            {
              stack: [{ text: 'Kwota VAT' }, this._createLine('small'), { text: 'St. VAT' }],
              fillColor: this.style.tableFill,
              style: 'center',
            },
            { text: 'Wartość brutto', style: ['tableHeadCenter', 'center'], fillColor: this.style.tableFill },
          ],
          ...this._createProductsRows(this.config.products),
        ],
      },
      layout: {
        hLineWidth: function() {
          return 0.5;
        },
        vLineWidth: function() {
          return 0.5;
        },
      },
      style: ['table', 'spaceBottomSmall'],
    };
  }

  _createSummaryTable() {
    return {
      columns: [
        {
          width: '50%',
          stack: [
            { text: `Do zapłaty: ${this.config.totalMoney} PLN`, style: ['bold', 'spaceBottomSmall'] },
            { text: `Termin płatności: ${this.config.paymentDeadline}` },
            { text: `Sposób zapłaty: ${this.config.paymentMethod}` },
            { text: `Konto: ${this.config.paymentAccountNumber}` },
            { text: `Wystawił: ${this.config.stagedBy}` },
          ],
        },
        {
          width: '50%',
          stack: [
            {
              table: {
                widths: ['*', '*', '*', '*'],
                body: [
                  [
                    { text: 'Stawka VAT', style: 'center', fillColor: this.style.tableFill },
                    { text: 'Netto', style: 'center', fillColor: this.style.tableFill },
                    { text: 'VAT', style: 'center', fillColor: this.style.tableFill },
                    { text: 'Brutto', style: 'center', fillColor: this.style.tableFill },
                  ],
                  [
                    { text: `${this.config.summaryTable.vatRate}%` },
                    { text: `${this.config.summaryTable.netto}` },
                    { text: `${this.config.summaryTable.vat}` },
                    { text: `${this.config.summaryTable.burtto}` },
                  ],
                  [
                    { text: 'Razem', style: 'bold' },
                    { text: `${this.config.summaryTable.netto}` },
                    { text: `${this.config.summaryTable.vat}` },
                    { text: `${this.config.summaryTable.burtto}` },
                  ],
                ],
              },
              layout: {
                hLineWidth: function() {
                  return 0.5;
                },
                vLineWidth: function() {
                  return 0.5;
                },
              },
              style: ['table', 'right', 'spaceBottomSmall'],
            },
          ],
        },
      ],
      style: 'spaceBottom',
    };
  }

  _createContent() {
    return [
      this._createLine(),
      {
        text: `Faktura nr: ${this.config.invoiceNumber} (samofakturowanie)`,
        style: ['title', 'center', 'spaceBottom'],
      },
      { text: `Data wystawienia: ${this.config.createDate}`, style: 'center' },
      { text: `Data sprzedaży/wydania: ${this.config.releaseDate}`, style: ['center', 'spaceBottom'] },
      {
        columns: [
          {
            width: '50%',
            stack: [
              { text: 'Sprzedawca', style: 'bold' },
              { text: this.config.company.name },
              { text: this.config.company.street },
              { text: `${this.config.company.zipCode} ${this.config.company.city}` },
              { text: `NIP: ${this.config.company.nip}` },
            ],
          },
          {
            width: '50%',
            stack: [
              { text: 'Nabywca', style: 'bold' },
              { text: this.config.purchaser.name },
              { text: this.config.purchaser.street },
              { text: `${this.config.purchaser.zipCode} ${this.config.purchaser.city}`, style: 'spaceBottomSmall' },
              { text: `NIP/NIP UE: ${this.config.purchaser.nip}` },
              { text: `CSK: ${this.config.purchaser.csk}` },
            ],
          },
        ],
        style: 'spaceBottom',
      },
      this._createMainTable(),
      this._createSummaryTable(),
    ];
  }

  _createDefinition() {
    return {
      info: {
        title: `Faktura nr: ${this.config.invoiceNumber}`,
        subject: `Faktura dla ${this.config.company.name}`,
        author: 'PeP - Samofakturowanie',
      },
      pageSize: 'A4',
      header: this._createHeader(),
      footer: this._createFooter(),
      content: this._createContent(),
      defaultStyle: {
        fontSize: 8,
        lineHeight: 1.2,
      },
      styles: {
        header: {
          margin: [40, 10],
        },
        footer: {
          margin: [40, 12],
          alignment: 'right',
          fontSize: 12,
        },
        title: {
          fontSize: 14,
          bold: true,
          margin: [0, 24],
        },
        table: {
          fontSize: 6,
          alignment: 'right',
        },
        tableHeadCenter: {
          margin: [0, 7, 0, 0],
        },
        tableSeparator: {
          margin: [0, 1, 0, 2],
        },
        center: {
          alignment: 'center',
        },
        right: {
          alignment: 'right',
        },
        bold: {
          bold: true,
        },
        spaceBottom: {
          margin: [0, 0, 0, 24],
        },
        spaceBottomSmall: {
          margin: [0, 0, 0, 12],
        },
      },
    };
  }

  getPdf() {
    return this.pdf;
  }

  getDefinition() {
    return this.pdfDefinition;
  }

  createDefinition() {
    const definition = this._createDefinition();
    this.pdfDefinition = definition;
  }

  build() {
    this.createDefinition();
    this.pdf = pdfMake.createPdf(this.pdfDefinition);
    return this.pdf;
  }

  download() {
    if (!this.pdf) throw new Error('Brak wygenerowanego pdf');

    try {
      this.pdf.download();
    } catch (err) {
      throw new Error(err);
    }
  }
}
