/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { diff } from 'json-diff';
import { clientService } from 'services/client';
import { sortByCreatedAt } from 'utils/helpers';
import * as types from './types';

const mapSpecialCases = (el, i, arr) => {
  if (el.actionType !== 'AUTO_TAKE_LEAD_BY_SYSTEM') return el;
  const orginalAddLeadLog = arr.find(log => log.actionType === 'ADD_LEAD' && log.frontNumLead === el.frontNumLead);
  if (!orginalAddLeadLog || !orginalAddLeadLog.nextData) return el;
  const newCreatedAt = moment(orginalAddLeadLog.nextData.updatedAt)
    .add(100, 'millisecond')
    .toISOString();
  return { ...el, createdAt: newCreatedAt, __info__: 'Sztucznie zmienione createdAt w celu prawidłowego sortowania' };
};

export const getClientInfo = id => dispatch => {
  dispatch({ type: types.GET_CLIENT_INFO_REQUEST });
  return clientService
    .getClientInfo(id)
    .then(payload => {
      dispatch({ type: types.GET_CLIENT_INFO_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_CLIENT_INFO_FAILURE, err });
    });
};

export const getClientHistory = id => dispatch => {
  dispatch({ type: types.GET_CLIENT_HISTORY_REQUEST });
  return clientService
    .getClientHistory(id)
    .then(payload => {
      payload.data = payload.data
        .map(log => {
          const prevData = JSON.parse(JSON.parse(log.prevData));
          const nextData = JSON.parse(JSON.parse(log.nextData));
          const extra = JSON.parse(JSON.parse(log.extra));

          return {
            ...log,
            prevData,
            nextData,
            extra,
            diff: diff(prevData, nextData),
          };
        })
        .map(mapSpecialCases)
        .sort(sortByCreatedAt);

      dispatch({ type: types.GET_CLIENT_HISTORY_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_CLIENT_HISTORY_FAILURE, err });
    });
};

export const getClientNotes = id => dispatch => {
  dispatch({ type: types.GET_CLIENT_NOTES_REQUEST });
  return clientService
    .getClientNotes(id)
    .then(payload => {
      dispatch({ type: types.GET_CLIENT_NOTES_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_CLIENT_NOTES_FAILURE, err });
    });
};

export const getClientDocs = id => dispatch => {
  dispatch({ type: types.GET_CLIENT_DOCS_REQUEST });
  return clientService
    .getClientDocs(id)
    .then(payload => {
      dispatch({ type: types.GET_CLIENT_DOCS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_CLIENT_DOCS_FAILURE, err });
    });
};
