import Person from './Person';
import Place from './Place';
import Point from './Point';

// Version 3 : added generalPromotion

export default {
  version: 'AML',
  versionNumber: 4,
  addresses: {
    email: '',
    main: new Place({}),
    invoices: new Place({}),
    mailling: new Place({}),
  },
  settlements: {
    bankAccount: '',
    billingSystem: 2,
    invoicingSystem: 1,
    serviceType: 2,
    pbg: null,
    pbgPwob: 0,
    // To jest nowe pole które ma wartości -1 dla nieuzupełnionego, 1 - Tak, 0 - Nie
    // propka pbgPwob2 dodana w celu nie psucia wczeniejszego uzupełnionego już forma
    pbgPwob2: -1,
    pbgPwobOptions: -1, // -1 is no render // 0 Nie // 1 Tak
    generalPromotion: -1, // -1 is no render // 0 Nie // 1 Tak
    period: 2,
    businessLine: 1,
    legalForm: null,
    legalFormAML: -1,
    agreementFile: '',
    agreement: '',
  },
  terms: {
    model: 'commission', // margin
    commission: {
      fee: 0.59,
      individualTermsFee: false,
      fixedFee: 0.05,
      individualTermsFixedFee: false,
    },
    margin: {
      value: 0.21,
      // Tu jest błąd nazewnictwa, od początku, to na dole jest do value, mimo nazwy fixedfee
      individualTermsFixedFee: false,
      marginFixedFee: 0.01,
      individualTermsMarginFixedFee: false,
    },
    individualTermsOthers: false,
    others: '',
    terminalPinPad: 19.0,
    terminalStationary: 39.0,
    terminalMobile: 39.0,
    individualTerminalPinPad: false,
    individualTerminalStationary: false,
    individualTerminalMobile: false,
  },
  contact: {
    signatory: [new Person({ type: 6 })],
    financialMatters: new Person({ type: 1 }),
    operationalMatters: new Person({ type: 2 }),
    businessMatters: new Person({ type: 3 }),
    owners: [new Person({ type: 4 })],
    personInCharge: new Person({ type: 5 }),
    legalFormCopy: null,
    agreementFile: '',
    agreement: '',
    versionNumberCopy: null,
    contractForm: '',
    // From version 4
    allSignatoryAreCitizensOfPoland: null,
    signatoryDrukAml: [],
  },
  points: [new Point({})],
  products: {
    items: [],
    comment: '',
  },
  files: [],
};

export const termsWithoutPbg = {
  model: 'commission',
  commission: {
    fee: 0.65,
    individualTermsFee: false,
    fixedFee: 0.01,
    individualTermsFixedFee: false,
  },
  margin: {
    value: 0.2,
    // Tu jest błąd nazewnictwa, od początku, to na dole jest do value, mimo nazwy fixedfee
    individualTermsFixedFee: false,
    marginFixedFee: 0,
    individualTermsMarginFixedFee: false,
  },
  individualTermsOthers: false,
  others: '',
  terminalPinPad: 19.0,
  terminalStationary: 35.0,
  terminalMobile: 35.0,
  individualTerminalPinPad: false,
  individualTerminalStationary: false,
  individualTerminalMobile: false,
};

export const demoViewOnboardingData = {
  agreement: null,
  createdAt: '2024-08-01T10:00:00.000Z',
  crmStatus: 0,
  data: '"{}"',
  formPwob: 0,
  generalPromotion: 0,
  gus: {
    city: 'Testowe',
    community: 'Testowy',
    county: 'Testowa',
    email: '',
    flaat_no: '2',
    house_no: '1',
    legal_form: 117,
    name: 'Testowa firma',
    nip: '1111111111',
    postal_code: '11-111',
    regon: '',
    regon9: '',
    regon14: '',
    street: 'ul. Testowa',
    teryt: '',
    voidoeship: 'WIELKOPOLSKIE',
  },
  id: '62c4f4b1-10f9-427f-95bf-9c9300474401',
  ivKey: null,
  leadId: '98b0dfa7-e480-42d9-87c8-aa1c01dea93c',
  media: [],
  signatoryAgreement: null,
  stage: 1,
  status: 'DRAFT',
  updatedAt: '2024-08-01T10:00:00.000Z',
};
