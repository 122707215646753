import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { font, colors } from 'theme/styles';
import searchIcon from 'assets/icons/search.svg';

const SearchInput = ({ inactive, handleSearch, value, ...rest }) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (value) {
      setSearchValue(value);
    }
  }, [value]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchValue);
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <StyledInput
        disabled={inactive}
        name="search"
        id="search"
        onChange={handleChange}
        onBlur={handleSubmit}
        value={searchValue}
        {...rest}
      />
      {!inactive && <SearchButton type="submit" />}
    </Wrapper>
  );
};

SearchInput.defaultProps = {
  value: '',
  inactive: false,
};

SearchInput.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  inactive: PropTypes.bool,
};

export default SearchInput;

const Wrapper = styled.form`
  position: relative;
  display: flex;
`;

const StyledInput = styled.input`
  background: transparent;
  border: none;
  width: 150px;
  height: 30px;
  border-bottom: 1px solid ${colors.darkGray};
  font-size: ${font.size.s};
  color: ${colors.black};
  line-height: 1.1;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.orange};
  }

  position: relative;
  padding-right: 30px;
`;

const SearchButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 50% 50%;
  cursor: pointer;

  position: absolute;
  left: 100%;
  bottom: 2px;
  transform: translateX(-100%);
  width: 26px;
  height: 26px;
`;
