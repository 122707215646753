import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getIntegrationDocs,
  getIntegrationDoc,
  setCurrentIntegrationDoc,
  clearCurrentIntegrationDoc,
} from 'redux/integrationDocs';
import { integrationDocsService } from 'services/integrationDocs';
import PageTemplate from 'templates/PageTemplate';
import IntegrationDocumentsTable from 'components/layout/Tables/IntegrationDocumentsTable';
import IntegrationDocumentForm from 'components/forms/IntegrationDocumentForm';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import Pagination from 'components/UI/Pagination';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import SuccessPopup from 'components/layout/SuccessPopup';
import { download } from 'utils/helpers';

import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';

const IntegrationDocsPage = ({
  integrationDocs,
  integrationDocsCount,
  currentIntegrationDoc,
  isLoading,
  error,
  totalPages,
  currentPage,
  getIntegrationDocs,
  getIntegrationDoc,
  setCurrentIntegrationDoc,
  clearCurrentIntegrationDoc,
  userRole,
}) => {
  const [isAddNewVisible, setIsAddNewVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [deletedError, setDeletedError] = useState('');
  const [deleted, setDeleted] = useState('');
  const [page, setPage] = useState('1');
  const [perPage, setPerPage] = useState('20');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('true');

  const handleCanEdit = doc => {
    if (['admin', 'dsp'].includes(userRole)) return true;
    return doc.status === 'NEW';
  };

  const getIntegrationDocumentsData = useCallback(() => {
    const query = {
      limit: perPage,
      page,
      ...(status && { showActive: status }),
      search: search || null,
    };
    getIntegrationDocs(query);
  }, [getIntegrationDocs, perPage, page, status, search]);

  useEffect(() => {
    getIntegrationDocumentsData();
  }, [getIntegrationDocumentsData]);

  const toggleNewPanel = () => setIsAddNewVisible(!isAddNewVisible);

  const closeEditPanel = () => {
    setEditVisible(false);
    clearCurrentIntegrationDoc();
  };

  const getDoc = id => {
    const local = integrationDocs.find(u => u.id === id);
    if (local) {
      setCurrentIntegrationDoc(local);
    } else {
      getIntegrationDoc(id);
    }
  };

  const openEditPanel = id => {
    getDoc(id);
    setEditVisible(true);
  };

  const handlePerPage = e => {
    setPerPage(e.target.value);
    setPage('1');
  };

  const handleSearch = value => {
    setSearch(value);
    setPage(1);
  };

  const handleStatus = e => {
    setStatus(e.target.value);
    setPage(1);
  };

  const headers = [
    { displayName: 'Data modyfikacji', property: 'updatedAt' },
    { displayName: 'Typ', property: 'type' },
    { displayName: 'Nazwa', property: 'name' },
    ['admin', 'dsp'].includes(userRole) && { displayName: 'Aktywny', property: 'active' },
    { displayName: 'Pobierz', property: 'download' },
  ].filter(Boolean);

  const handleDelete = id => {
    setDeletedError('');
    integrationDocsService
      .deleteIntegrationDoc(id)
      .then(() => {
        setDeleted('');
        getIntegrationDocumentsData();
      })
      .catch(err => {
        console.dir(err);
        setDeletedError(err.response.data.error.message);
      });
  };

  const handleDownload = docItem => {
    const { name, fileExtension } = docItem;

    integrationDocsService
      .getIntegrationDocFile(docItem.id)
      .then(res => {
        download(res, `${name}.${fileExtension}`);
      })
      .catch(err => {
        console.dir(err);
      });
  };

  const showDeletePopup = deleteItem => (
    <SuccessPopup fixed>
      <span>
        Czy na pewno chcesz usunąć dokument <span className="color-orange">{deleteItem.name}</span> ?
      </span>
      {deletedError !== '' && <span style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{deletedError}</span>}
      <div className="buttons">
        <Button
          secondary
          onClick={() => {
            setDeletedError('');
            setDeleted('');
          }}
        >
          Anuluj
        </Button>
        <Button onClick={() => handleDelete(deleted.id)}>{deletedError ? 'Spróbuj ponownie' : 'Usuń'}</Button>
      </div>
    </SuccessPopup>
  );

  return (
    <PageTemplate>
      {!isLoading && error && <ErrorPopup fixed>{error.message}</ErrorPopup>}
      <TopSection>
        {['admin', 'dsp'].includes(userRole) ? (
          <Button onClick={toggleNewPanel}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj dokumenty
          </Button>
        ) : (
          <span />
        )}
        <TableSorter>
          {['admin', 'dsp'].includes(userRole) && (
            <SortItem>
              <span>Status</span>
              <Select small value={status} onChange={handleStatus}>
                <option value="true">Aktywne</option>
                <option value="false">Nieaktywne</option>
                <option value="">Wszystkie</option>
              </Select>
            </SortItem>
          )}
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {isLoading ? (
        <Loading />
      ) : (
        <IntegrationDocumentsTable
          data={integrationDocs}
          headers={headers}
          offset={(currentPage - 1) * (perPage || 20)}
          canUserEdit={handleCanEdit}
          handleDelete={['admin', 'dsp'].includes(userRole) && setDeleted}
          handleEdit={['admin', 'dsp'].includes(userRole) && openEditPanel}
          handleDownload={handleDownload}
        />
      )}
      <BottomSection>
        <BottomItem>Wszystkich dokumentów: {integrationDocsCount}</BottomItem>
        <Pagination currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      {deleted !== '' && showDeletePopup(deleted)}
      <SidePanel small toggleClose={closeEditPanel} isVisible={isEditVisible}>
        {currentIntegrationDoc !== null && (
          <IntegrationDocumentForm
            editMode
            adminMode={['admin', 'dsp'].includes(userRole)}
            initialValues={{ ...currentIntegrationDoc }}
            toggleClose={closeEditPanel}
            refetch={getIntegrationDocumentsData}
          />
        )}
      </SidePanel>
      <SidePanel small toggleClose={toggleNewPanel} isVisible={isAddNewVisible}>
        <IntegrationDocumentForm toggleClose={toggleNewPanel} refetch={getIntegrationDocumentsData} />
      </SidePanel>
    </PageTemplate>
  );
};

IntegrationDocsPage.defaultProps = {
  currentIntegrationDoc: null,
  integrationDocsCount: 0,
  error: null,
  totalPages: 1,
  currentPage: 1,
};

IntegrationDocsPage.propTypes = {
  integrationDocs: PropTypes.instanceOf(Array).isRequired,
  currentIntegrationDoc: PropTypes.instanceOf(Object),
  integrationDocsCount: PropTypes.number,

  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Object),

  totalPages: PropTypes.number,
  currentPage: PropTypes.number,

  getIntegrationDocs: PropTypes.func.isRequired,
  getIntegrationDoc: PropTypes.func.isRequired,
  setCurrentIntegrationDoc: PropTypes.func.isRequired,
  clearCurrentIntegrationDoc: PropTypes.func.isRequired,

  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  integrationDocs: state.integrationDocs.integrationDocs.data.rows,
  currentIntegrationDoc: state.integrationDocs.currentIntegrationDoc.data,
  integrationDocsCount: state.integrationDocs.integrationDocs.data.count,

  isLoading: state.integrationDocs.integrationDocs.isLoading,
  error: state.integrationDocs.integrationDocs.error,

  totalPages: state.integrationDocs.integrationDocs.data.pagination.totalPages,
  currentPage: state.integrationDocs.integrationDocs.data.pagination.currentPage,

  userRole: state.user.data.role.slug,
});

const mapDispatchToProps = {
  getIntegrationDocs,
  getIntegrationDoc,
  setCurrentIntegrationDoc,
  clearCurrentIntegrationDoc,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationDocsPage);
