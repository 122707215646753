import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Content = ({ children }) => <Wrapper>{children}</Wrapper>;

Content.defaultProps = {
  children: null,
};

Content.propTypes = {
  children: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
};

export default Content;

const Wrapper = styled.article`
  p {
    margin-bottom: 1em !important;
  }

  ul,
  ol {
    margin-bottom: 1.25em;

    li {
      margin-bottom: 0.5em;
    }
  }
`;
