export const GET_CLIENT_PARTNER_INFO_REQUEST = 'GET_CLIENT_PARTNER_INFO_REQUEST';
export const GET_CLIENT_PARTNER_INFO_SUCCESS = 'GET_CLIENT_PARTNER_INFO_SUCCESS';
export const GET_CLIENT_PARTNER_INFO_FAILURE = 'GET_CLIENT_PARTNER_INFO_FAILURE';

export const GET_CLIENT_PARTNER_HISTORY_REQUEST = 'GET_CLIENT_PARTNER_HISTORY_REQUEST';
export const GET_CLIENT_PARTNER_HISTORY_SUCCESS = 'GET_CLIENT_PARTNER_HISTORY_SUCCESS';
export const GET_CLIENT_PARTNER_HISTORY_FAILURE = 'GET_CLIENT_PARTNER_HISTORY_FAILURE';

export const GET_CLIENT_PARTNER_NOTES_REQUEST = 'GET_CLIENT_PARTNER_NOTES_REQUEST';
export const GET_CLIENT_PARTNER_NOTES_SUCCESS = 'GET_CLIENT_PARTNER_NOTES_SUCCESS';
export const GET_CLIENT_PARTNER_NOTES_FAILURE = 'GET_CLIENT_PARTNER_NOTES_FAILURE';