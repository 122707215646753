import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMerchantsLeadsAnalytics } from 'redux/admin';
import { TopSection, BottomItem, BottomSection, TableSorter, SortItem } from 'components/layout/Tables/TableComponents';
import { routes } from 'routes';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import ExtendedTable from 'components/layout/Tables/ExtendedTable';
import Pagination from 'components/UI/Pagination';
import Select from 'components/UI/Select';
import usePagination from 'hooks/usePagination';
import Heading from 'components/UI/Heading';
import SearchInput from 'components/UI/SearchInput';

export const toHoursAndMinutes = totalMinutes => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours > 0 ? `${hours} h` : ''} ${minutes > 0 ? `${minutes} min` : ''}`;
};

const handleMapValueToMinutes = (fieldName, fieldValue, rowData) => {
  if ((rowData.notTaken48hLeads === 0 && rowData.taken48hLeads === 0) || fieldValue === false) return '-';
  if (fieldValue === 0) return '< 1 min';
  if (fieldValue >= 60) return toHoursAndMinutes(fieldValue);
  return `${fieldValue} min`;
};

const headers = [
  { displayName: 'Przedstawiciel', property: 'ph.fullName' },
  { displayName: 'Średni czas podjęcia', property: 'avgTimeTaken', useToSort: true, mapValue: handleMapValueToMinutes },
  {
    displayName: 'Najkrótszy czas podjęcia ',
    property: 'shortestTimeTaken',
    useToSort: true,
    mapValue: handleMapValueToMinutes,
  },
  {
    displayName: 'Najdłuższy czas podjęcia ',
    property: 'longestTimeTaken',
    useToSort: true,
    mapValue: handleMapValueToMinutes,
  },
  {
    displayName: 'Średni czas rejestracji w CRM',
    property: 'crmTimeRegister',
    useToSort: true,
    mapValue: handleMapValueToMinutes,
  },
  { displayName: 'Niepodjęte w 48h', property: 'notTaken48hLeads', useToSort: true },
  { displayName: 'Podjęte w 48h', property: 'taken48hLeads', useToSort: true },
];

const MerchantsAnalyticsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector(state => state.admin.merchantsLeadsAnalytics.data.rows);
  const isLoading = useSelector(state => state.admin.merchantsLeadsAnalytics.isLoading);
  const error = useSelector(state => state.admin.merchantsLeadsAnalytics.error);
  const count = useSelector(state => state.admin.merchantsLeadsAnalytics.data.count);
  const reduxPagination = useSelector(state => state.admin.merchantsLeadsAnalytics.data.pagination);

  const [period, setPeriod] = useState('30');
  const [order, setOrder] = useState('DESC');
  const [sortBy, setSortBy] = useState('avgTimeTaken');
  const [search, setSearch] = useState('');

  const { pagination, handlePerPage, setPage, setPagination } = usePagination({
    perPage: 20,
    totalPages: 1,
    currentPage: 1,
  });

  const getDataCb = useCallback(() => {
    const query = {
      days: period,
      limit: pagination.perPage,
      page: pagination.page,
      ...(sortBy && { sortBy }),
      ...(order && { order }),
      ...(search && { search }),
    };
    dispatch(getMerchantsLeadsAnalytics(query));
  }, [dispatch, search, order, pagination.page, pagination.perPage, period, sortBy]);

  useEffect(() => {
    getDataCb();
  }, [getDataCb]);

  const handlePeriod = e => {
    setPeriod(e.target.value);
    setPage(1);
  };

  const handleSortBy = e => {
    setSortBy(e.target.value);
    setPage(1);
  };

  const handleOrder = e => {
    setOrder(e.target.value);
    setPage(1);
  };

  const handleSearch = value => {
    setSearch(value);
    setPage(1);
  };

  useEffect(() => {
    setPagination(prevPag => ({
      ...prevPag,
      ...reduxPagination,
      count,
    }));
  }, [count, reduxPagination, setPagination]);

  const showErrorPopup = error => <ErrorPopup fixed>{error.message}</ErrorPopup>;

  return (
    <PageTemplate>
      <TopSection style={{ alignItems: 'flex-start' }}>
        <Heading size="xxl">Analiza działań handlowców</Heading>
        <TableSorter>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={order} onChange={handleOrder}>
              <option value="DESC">Malejąco</option>
              <option value="ASC">Rosnąco</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj po</span>
            <Select small value={sortBy} onChange={handleSortBy}>
              <option value="">&nbsp;</option>
              {headers
                .filter(s => s.useToSort)
                .map(s => (
                  <option key={s.property} value={s.property}>
                    {s.displayName}
                  </option>
                ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Okres</span>
            <Select small value={period} onChange={handlePeriod}>
              <option value="30">30 dni</option>
              <option value="90">90 dni</option>
              <option value="365">365 dni</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {!isLoading && error && showErrorPopup(error)}
      {isLoading ? (
        <Loading />
      ) : (
        <ExtendedTable
          data={data}
          headers={headers}
          offset={(pagination.currentPage - 1) * (pagination.perPage || 20)}
          handleRowClick={item => history.push(routes.merchantsAnalyticsDetails.replace(':id', item.phId), { period })}
        />
      )}
      <BottomSection>
        <BottomItem>Wszystkich: {count}</BottomItem>
        <Pagination currentPage={pagination.currentPage} maxPages={pagination.totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={pagination.perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
    </PageTemplate>
  );
};

MerchantsAnalyticsPage.defaultProps = {};

MerchantsAnalyticsPage.propTypes = {};

export default MerchantsAnalyticsPage;
