import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import { adminService } from 'services/admin';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import { optionsOthersSchema } from 'utils/validation';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import CloseButton from 'components/UI/CloseButton';
import Input from 'components/UI/Input';

const OptionsOthers = ({ refetch }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [askForPassword, setAskForPassword] = useState(false);
  const [masterPassword, setMasterPassword] = useState('');

  const options = useSelector((s) => s.admin.spOptions);
  const isLoadingOptions = useSelector((s) => s.admin.spOptions.isLoading);
  const isErrorOptions = useSelector((s) => s.admin.spOptions.error);

  const handleClose = (resetForm) => {
    setSuccess(false);
    setError('');
    resetForm({});
  };

  const handleSubmit = (values) => {
    setError('');
    setLoading(true);

    const payload = {
      masterPassword,
      collaborationRegulationUrl: values.collaborationRegulationUrl,
    };

    const thenFn = (data) => {
      console.log(data);

      if (data.data?.isOk) setSuccess(true);
      else setError('Coś poszło nie tak.');

      setLoading(false);
    };

    const catchFn = (err) => {
      console.log(err);
      setError(err.response.data.error.message);
      setLoading(false);
    };

    adminService
      .setSpOptions(payload)
      .then(thenFn)
      .catch(catchFn)
      .finally(() => {
        setAskForPassword(false);
        setMasterPassword('');
        refetch();
      });
  };

  const { collaborationRegulationUrl = '' } = options.data;

  return (
    <>
      <FormWrapperCommon>
        {(loading || isLoadingOptions) && <Loading absolute />}
        {isErrorOptions && (
          <ErrorPopup>
            <Heading>Błąd</Heading>
            <p style={{ marginTop: '2rem' }}> {isErrorOptions}</p>
          </ErrorPopup>
        )}
        <Heading size="xl">Ustawienia danych zewnętrznych</Heading>
        {!isLoadingOptions && !isErrorOptions && (
          <Formik
            initialValues={{
              collaborationRegulationUrl,
            }}
            enableReinitialize
            validationSchema={optionsOthersSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleBlur, resetForm, handleChange }) => (
              <>
                {success && (
                  <SuccessPopup>
                    <Heading>Pomyślnie zapisano ustawienia</Heading>
                    <div className="buttons">
                      <Button onClick={() => handleClose(resetForm)}>Zamknij</Button>
                    </div>
                  </SuccessPopup>
                )}
                {error && (
                  <ErrorPopup>
                    <Heading>Wystapił błąd</Heading>
                    <p style={{ marginTop: '2rem' }}>{error}</p>
                    <div className="buttons">
                      <Button gray onClick={() => handleClose(resetForm)}>
                        Zamknij
                      </Button>
                    </div>
                  </ErrorPopup>
                )}
                <StyledForm>
                  <FieldHeading size="m">Program poleceń</FieldHeading>
                  <Input
                    name="collaborationRegulationUrl"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Adres URL regulaminu dla programu poleceń:"
                    value={values.collaborationRegulationUrl || ''}
                    error={touched.collaborationRegulationUrl && errors.collaborationRegulationUrl}
                  />
                  <ErrorMessage component={ErrorLabel} name="collaborationRegulationUrl" />
                  <Button
                    style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }}
                    type="button"
                    onClick={() => setAskForPassword(true)}
                  >
                    Zapisz ustawienia
                  </Button>
                  {askForPassword && (
                    <SuccessPopup fixed>
                      <CloseButton onClick={() => setAskForPassword(false)} />
                      <Heading style={{ marginBottom: '2rem' }}>Akcja wymaga hasła</Heading>
                      <div style={{ width: '25rem' }}>
                        <Input
                          name="masterPassword"
                          type="password"
                          onChange={(e) => setMasterPassword(e.target.value)}
                          onBlur={() => {}}
                          label="Master-password"
                          value={masterPassword}
                          error={false}
                        />
                      </div>
                      <div className="buttons">
                        <Button type="submit">Zapisz ustawienia</Button>
                      </div>
                    </SuccessPopup>
                  )}
                </StyledForm>
              </>
            )}
          </Formik>
        )}
      </FormWrapperCommon>
    </>
  );
};

OptionsOthers.defaultProps = {};

OptionsOthers.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default OptionsOthers;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;

  input {
    max-width: 30rem;
  }
`;
