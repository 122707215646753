/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPartnerTerms } from 'redux/partner';
import { TopSection } from 'components/layout/Tables/TableComponents';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import SimpleTable from 'components/layout/Tables/SimpleTable';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';

const headers = [
  { displayName: 'Pozycja', property: 'parametr' },
  { displayName: 'Wartość', property: 'wartosc' },
];

const CompanyTermsPage = ({ terms, partner, isLoading, error, getPartnerTerms, match, history }) => {
  useEffect(() => {
    getPartnerTerms(match.params.id);
  }, [getPartnerTerms, match.params.id]);

  return (
    <PageTemplate>
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => history.goBack()}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      <TopSection>
        <div>
          <Heading size="xxl">Partner - {partner?.name}</Heading>
          <br />
          <Heading size="xl">Warunki handlowe</Heading>
        </div>
      </TopSection>
      {isLoading ? <Loading /> : <SimpleTable headers={headers} data={terms} />}
    </PageTemplate>
  );
};

CompanyTermsPage.defaultProps = {
  error: null,
  partner: null,
};

CompanyTermsPage.propTypes = {
  terms: PropTypes.instanceOf(Array).isRequired,
  partner: PropTypes.instanceOf(Object),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  getPartnerTerms: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  terms: state.partner.terms.data.externalDb,
  partner: state.partner.terms.data.partner,
  isLoading: state.partner.terms.isLoading,
  error: state.partner.terms.error,
});

const mapDispatchToProps = {
  getPartnerTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTermsPage);
