import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getVacations, getVacation, setCurrentVacation, clearCurrentVacation } from 'redux/admin';
import { adminService } from 'services/admin';
import PageTemplate from 'templates/PageTemplate';
import VacationsTable from 'components/layout/Tables/VacationsTable';
import VacationForm from 'components/forms/Admin/VacationForm';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import Pagination from 'components/UI/Pagination';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import SuccessPopup from 'components/layout/SuccessPopup';

import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import Heading from 'components/UI/Heading';

const headers = [
  { displayName: 'Zgłoszony', property: 'createdAt' },
  { displayName: 'Przedstawiciel', property: 'user' },
  { displayName: 'Zgłosił', property: 'reported' },
  { displayName: 'Data rozpoczęcia', property: 'startDate' },
  { displayName: 'Data zakończenia', property: 'endDate' },
  { displayName: 'Aktywny', property: 'active' },
];

const AdminVacationsPage = ({
  vacations,
  currentVacation,
  vacationsCount,
  isLoading,
  error,
  totalPages,
  currentPage,
  getVacations,
  getVacation,
  setCurrentVacation,
  clearCurrentVacation,
  loggedUserId,
}) => {
  const [isAddNewVisible, setIsAddNewVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [deletedError, setDeletedError] = useState('');
  const [deleted, setDeleted] = useState('');
  const [page, setPage] = useState('1');
  const [perPage, setPerPage] = useState('20');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('false');
  const [sort, setSort] = useState('DESC');

  const getVacationsData = useCallback(() => {
    const query = {
      limit: perPage,
      page,
      sort,
      showDeleted: status,
      search: search || null,
    };
    getVacations(query);
  }, [getVacations, perPage, page, status, sort, search]);

  useEffect(() => {
    getVacationsData();
  }, [getVacationsData]);

  const toggleNewPanel = () => {
    setIsAddNewVisible(!isAddNewVisible);
  };

  const closeEditPanel = () => {
    setEditVisible(false);
    clearCurrentVacation();
  };

  const getSingleVacation = id => {
    const local = vacations.find(u => u.id === id);
    if (local) setCurrentVacation(local);
    else getVacation(id);
  };

  const openEditPanel = id => {
    getSingleVacation(id);
    setEditVisible(true);
  };

  const handlePerPage = e => {
    setPerPage(e.target.value);
    setPage('1');
  };

  const handleSearch = value => {
    setSearch(value);
    setPage(1);
  };

  const handleStatus = e => {
    setStatus(e.target.value);
    setPage(1);
  };

  const handleSort = e => {
    setSort(e.target.value);
    setPage(1);
  };

  const handleDelete = id => {
    setDeletedError('');

    adminService
      .deleteVacation(id)
      .then(() => {
        setDeleted('');
        getVacationsData();
      })
      .catch(err => {
        console.dir(err);
        setDeletedError(err.response.data.error.message);
      });
  };

  const showDeletePopup = deleted => (
    <SuccessPopup fixed>
      <Heading>Czy chcesz usunąć urlop dla:</Heading>
      <p>
        {deleted.user?.name} {deleted.user?.surname}
      </p>
      {deletedError !== '' && <span style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{deletedError}</span>}
      <div className="buttons">
        <Button
          secondary
          onClick={() => {
            setDeletedError('');
            setDeleted('');
          }}
        >
          Anuluj
        </Button>
        <Button onClick={() => handleDelete(deleted.id)}>{deletedError ? 'Spróbuj ponownie' : 'Usuń'}</Button>
      </div>
    </SuccessPopup>
  );

  return (
    <PageTemplate>
      {!isLoading && error && <ErrorPopup fixed>{error.message}</ErrorPopup>}
      <TopSection>
        <Button onClick={toggleNewPanel}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj urlop
        </Button>
        <TableSorter>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={handleStatus}>
              <option value="false">Aktywne</option>
              <option value="true">Wszystkie</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={sort} onChange={handleSort}>
              <option value="DESC">Najnowsze</option>
              <option value="ASC">Najstarsze</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {isLoading ? (
        <Loading />
      ) : (
        <VacationsTable
          data={vacations}
          headers={headers}
          offset={(currentPage - 1) * (perPage || 20)}
          handleEdit={openEditPanel}
          handleDelete={setDeleted}
        />
      )}
      <BottomSection>
        <BottomItem>Wszystkich urlopów: {vacationsCount}</BottomItem>
        <Pagination currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      {deleted !== '' && showDeletePopup(deleted)}
      <SidePanel small toggleClose={closeEditPanel} isVisible={isEditVisible}>
        {currentVacation !== null && (
          <VacationForm
            editMode
            initialValues={{ ...currentVacation }}
            toggleClose={closeEditPanel}
            refetch={getVacationsData}
            reportedBy={loggedUserId}
          />
        )}
      </SidePanel>
      <SidePanel small toggleClose={toggleNewPanel} isVisible={isAddNewVisible}>
        <VacationForm toggleClose={toggleNewPanel} refetch={getVacationsData} reportedBy={loggedUserId} />
      </SidePanel>
    </PageTemplate>
  );
};

AdminVacationsPage.defaultProps = {
  currentVacation: null,
  error: null,
  vacationsCount: 0,
  totalPages: 1,
  currentPage: 1,
};

AdminVacationsPage.propTypes = {
  vacations: PropTypes.instanceOf(Array).isRequired,
  currentVacation: PropTypes.instanceOf(Object),
  vacationsCount: PropTypes.number,

  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Object),

  totalPages: PropTypes.number,
  currentPage: PropTypes.number,

  getVacations: PropTypes.func.isRequired,
  getVacation: PropTypes.func.isRequired,
  setCurrentVacation: PropTypes.func.isRequired,
  clearCurrentVacation: PropTypes.func.isRequired,

  loggedUserId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  vacations: state.admin.vacations.data.rows,
  currentVacation: state.admin.currentVacation.data,
  vacationsCount: state.admin.vacations.data.count,

  isLoading: state.admin.vacations.isLoading,
  error: state.admin.vacations.error,

  totalPages: state.admin.vacations.data.pagination.totalPages,
  currentPage: state.admin.vacations.data.pagination.currentPage,

  loggedUserId: state.user.data.id,
});

const mapDispatchToProps = {
  getVacations,
  getVacation,
  setCurrentVacation,
  clearCurrentVacation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminVacationsPage);
