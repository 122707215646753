import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { routes } from 'routes';
import { colors, breakpoints } from 'theme/styles';
import { ReactComponent as UsersIcon } from 'assets/icons/admin-users.svg';
import { ReactComponent as ProductsIcon } from 'assets/icons/admin-products.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as ApiIcon } from 'assets/icons/admin-api.svg';
import { ReactComponent as ChallengeIcon } from 'assets/icons/admin-challenge.svg';
import { ReactComponent as MessagesIcon } from 'assets/icons/admin-messages.svg';
import { ReactComponent as LeadsArchiveIcon } from 'assets/icons/admin-deleted.svg';
import { ReactComponent as StampIcon } from 'assets/icons/admin-stamp.svg';
import { ReactComponent as CollabIcon } from 'assets/icons/admin-collab.svg';
import { ReactComponent as VacationIcon } from 'assets/icons/admin-vacations.svg';
import { ReactComponent as MarketingIcon } from 'assets/icons/marketing.svg';
import { ReactComponent as ExchangeIcon } from 'assets/icons/admin-exchange.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as EmailsIcon } from 'assets/icons/admin-email.svg';
import { ReactComponent as IntegrationIcon } from 'assets/icons/integrationDoc.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/admin-schedule.svg';
import { ReactComponent as IntegrationOrdersIcon } from 'assets/icons/admin-cash-register.svg';
import { ReactComponent as MerchantsAnalyticsIcon } from 'assets/icons/analysis.svg';
import { ReactComponent as ClarityIcon } from 'assets/icons/clarity.svg';
import { ReactComponent as Przelewy24Logo } from 'assets/icons/przelewy24.svg';
import { ReactComponent as AdminOptionsIcon } from 'assets/icons/admin-gear.svg';
import { ReactComponent as TrainingMaterialsIcon } from 'assets/icons/training-materials.svg';
import { ReactComponent as DemoIcon } from 'assets/icons/demo.svg';

// import { ReactComponent as AnalysisIcon } from 'assets/icons/admin-analysis.svg';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';
// import { adminService } from 'services/admin';
// import Button from 'components/UI/Button';

const AdminPage = ({ userRole }) => {
  // const assignLeadsHandler = () => {
  //   // eslint-disable-next-line no-alert
  //   const confirm = window.confirm('Czy chcesz manualnie uruchomic proces przypisywania leadów?');
  //   if (!confirm) return;
  //   adminService
  //     .assignLeadsManual()
  //     .then(() => console.log('Przypisuje'))
  //     .catch(() => console.log('Błąd przypisywania'));
  // };

  return (
    <PageTemplate>
      <CardsWrapper>
        <Card to={routes.adminCompanies}>
          <CompanyIcon />
          <Heading centered> Zarządzanie partnerami </Heading>
        </Card>
        <Card to={routes.adminUsers}>
          <UsersIcon />
          <Heading centered>Zarządzanie użytkownikami</Heading>
        </Card>
        <Card to={routes.adminProducts}>
          <ProductsIcon />
          <Heading centered>Zarządzanie produktami</Heading>
        </Card>
        <Card to={routes.adminChallenges}>
          <ChallengeIcon />
          <Heading centered>Zarządzanie wyzwaniami</Heading>
        </Card>
        <Card to={routes.collaboration}>
          <CollabIcon />
          <Heading centered>Program poleceń</Heading>
        </Card>
        <Card to={routes.marketing}>
          <MarketingIcon />
          <Heading centered>Materiały marketingowe</Heading>
        </Card>
        {['admin', 'dsp'].includes(userRole) && (
          <Card to={routes.trainingMaterials}>
            <TrainingMaterialsIcon />
            <Heading centered>Materiały szkoleniowe</Heading>
          </Card>
        )}
        <Card to={routes.documents}>
          <IntegrationIcon />
          <Heading centered>Dokumenty</Heading>
        </Card>
        <Card to={routes.integrationOrders}>
          <IntegrationOrdersIcon />
          <Heading centered> Zlecenia</Heading>
        </Card>
        <Card to={routes.adminCountyAnalysis}>
          <StampIcon />
          <Heading centered> Powiaty i kody pocztowe </Heading>
        </Card>
        <Card to={routes.adminNotifications}>
          <ErrorIcon />
          <Heading centered> Informacje o błędach </Heading>
        </Card>
        <Card to={routes.adminVacations}>
          <VacationIcon />
          <Heading centered> Urlopy PH </Heading>
        </Card>
        {['admin'].includes(userRole) && (
          <>
            <Card to={routes.adminApi}>
              <ApiIcon />
              <Heading centered> Zarządzanie kluczami API </Heading>
            </Card>
            <Card to={routes.adminSystemMessages}>
              <MessagesIcon />
              <Heading centered> Powiadomienia systemowe </Heading>
            </Card>
            <Card to={routes.adminLeadsArchive}>
              <LeadsArchiveIcon />
              <Heading centered> Leady zarchiwizowane </Heading>
            </Card>
            {/* <Card to={routes.adminZipcodes}>
              <StampIcon />
              <Heading centered> Kody pocztowe </Heading>
            </Card> */}
            <Card to={routes.adminTransferLeads}>
              <ExchangeIcon />
              <Heading centered> Transfer leadów </Heading>
            </Card>
            <Card to={routes.adminEmailTemplates}>
              <EmailsIcon />
              <Heading centered> Szablony e-mail </Heading>
            </Card>
            <Card to={routes.adminCron}>
              <ScheduleIcon />
              <Heading centered> Lista Cronów </Heading>
            </Card>
            <Card to={routes.przelewy24}>
              <Przelewy24Logo />
              <Heading centered>Leady do Przelewy24</Heading>
            </Card>
            <Card to={routes.adminOptions} style={{ order: 999 }}>
              <AdminOptionsIcon />
              <Heading centered>Ustawienia</Heading>
            </Card>
          </>
        )}
        <Card to={routes.analytics}>
          <MerchantsAnalyticsIcon />
          <Heading centered> Analiza działań handlowców </Heading>
        </Card>
        <Card to={routes.adminClarity}>
          <ClarityIcon />
          <Heading centered> Funkcje weryfikacyjne </Heading>
        </Card>
        {['admin', 'dsp'].includes(userRole) && (
          <Card to={routes.adminDemos}>
            <DemoIcon />
            <Heading centered> Wersje Demo </Heading>
          </Card>
        )}
      </CardsWrapper>
      {/* {process.env.NODE_ENV === 'development' && (
        <AdminActionsSecton>
          <Button onClick={assignLeadsHandler}>Przypisz leady manualnie</Button>
        </AdminActionsSecton>
      )} */}
    </PageTemplate>
  );
};

AdminPage.defaultProps = {
  userRole: '',
};

AdminPage.propTypes = {
  userRole: PropTypes.string,
};

export default AdminPage;

// styled components

export const CardsWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Card = styled(Link)`
  display: block;
  height: 171px;
  width: 260px;
  margin: 10px;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  background: ${colors.white};
  border-radius: 14px;
  box-shadow: 0px 3px 15px #00000010;
  text-decoration: none;

  img {
    height: 54px;
    margin-bottom: 10px;
  }

  svg {
    height: 54px;
    width: auto;
    margin-bottom: 10px;
    fill: ${colors.orange};
    color: ${colors.orange};
    transition: fill 0.3s, color 0.3s;
  }

  &:hover svg {
    fill: ${colors.red};
    color: ${colors.red};
  }

  @media (max-width: ${breakpoints.adminPage}) {
    width: 100%;
  }
`;

// const AdminActionsSecton = styled.div`
//   margin-top: 50px;
//   display: flex;
//   justify-content: center;
// `;
