/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserInfo } from 'redux/user';
import { Redirect, useLocation } from 'react-router-dom';
import Loading from 'components/UI/Loading';

const withAuth = (WrappedComponent, role) => {
  const WithAuth = props => {
    const { isUserLoaded, getUserInfo, authError, userRole } = props;
    const location = useLocation();

    useEffect(() => {
      if (!isUserLoaded) getUserInfo();
    }, [getUserInfo, isUserLoaded]);

    const rolesArray = [];

    if (isUserLoaded) {
      if (role) {
        if (Array.isArray(role)) {
          rolesArray.push(...role);
        }
        if (typeof role === 'string') {
          rolesArray.push(role);
        }
        if (rolesArray.includes(userRole)) {
          return <WrappedComponent {...props} />;
        }
        // eslint-disable-next-line no-alert
        return (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        );
      }
      return <WrappedComponent {...props} />;
    }
    if (authError) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: location,
          }}
        />
      );
    }
    return <Loading big />;
  };

  const mapStateToProps = state => ({
    isUserLoaded: state.user.isUserLoaded,
    authError: state.user.authError,
    userRole: state.user.data.role.slug,
    user: state.user.data,
  });

  WithAuth.defaultProps = {
    authError: null,
    user: null,
    userRole: '',
  };

  WithAuth.propTypes = {
    isUserLoaded: PropTypes.bool.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    authError: PropTypes.instanceOf(Object),
    userRole: PropTypes.string,
    user: PropTypes.instanceOf(Object),
  };

  return connect(mapStateToProps, { getUserInfo })(WithAuth);
};

export default withAuth;
