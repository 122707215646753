export const GET_TRAINING_MATERIALS_REQUEST = 'GET_TRAINING_MATERIALS_REQUEST';
export const GET_TRAINING_MATERIALS_SUCCESS = 'GET_TRAINING_MATERIALS_SUCCESS';
export const GET_TRAINING_MATERIALS_FAILURE = 'GET_TRAINING_MATERIALS_FAILURE';

export const GET_TRAINING_MATERIAL_REQUEST = 'GET_TRAINING_MATERIAL_REQUEST';
export const GET_TRAINING_MATERIAL_SUCCESS = 'GET_TRAINING_MATERIAL_SUCCESS';
export const GET_TRAINING_MATERIAL_FAILURE = 'GET_TRAINING_MATERIAL_FAILURE';

export const SET_CURRENT_TRAINING_MATERIAL = 'SET_CURRENT_TRAINING_MATERIAL';
export const CLEAR_CURRENT_TRAINING_MATERIAL = 'CLEAR_CURRENT_TRAINING_MATERIAL';

export const SET_TRAINING_MATERIAL_UPLOAD_PROGRESS = 'SET_TRAINING_MATERIAL_UPLOAD_PROGRESS';

export const CLEAR_TRAINING = 'CLEAR_TRAINING';

