/* eslint-disable camelcase */
import makeRequest from 'services/makeRequest';
import axios from 'axios';

const { CancelToken } = axios;
let cancelExportToken;

// Endpoints urls
const ADD_LEAD_URL = '/przelewy24-leads';
const GET_LEADS_URL = '/przelewy24-leads';
const GET_LEAD_URL = '/przelewy24-leads';
const EXPORT_LEADS_URL = '/przelewy24-leads/excel';


const addLeadP24 = data => {
  return makeRequest({
    method: 'POST',
    url: ADD_LEAD_URL,
    data,
  });
};

const getP24Lead = id => {
  return makeRequest({
    method: 'GET',
    url: `${GET_LEAD_URL}/${id}`,
  });
};

const getP24Leads = query => {
  return makeRequest({
    method: 'GET',
    url: GET_LEADS_URL,
    params: query,
  });
};

// Tylko admin
// NEW EXPORT
const exportP24LeadsExcel = (params) => {
  return makeRequest({
    method: 'GET',
    url: EXPORT_LEADS_URL,
    params,
    timeout: 0,
    responseType: 'blob',
    cancelToken: new CancelToken(function executor(c) {
      cancelExportToken = c;
    }),
  });
};

export const p24LeadService = {
  addLeadP24,
  getP24Lead,
  getP24Leads,
  exportP24LeadsExcel,
  cancelExport: () => cancelExportToken(),
};
