/* eslint-disable camelcase */
import makeRequest from 'services/makeRequest';
import axios from 'axios';

const { CancelToken } = axios;
let cancelExportToken;

const COLLABORATION_BASE_URL = '/partner-leads';
const EXPORT_COLLABORATION_URL = '/partner-leads/report';

// Export
const exportRecommendedUsers = params => {
  return makeRequest({
    method: 'GET',
    url: EXPORT_COLLABORATION_URL,
    params,
    timeout: 0,
    responseType: 'blob',
    cancelToken: new CancelToken(function executor(c) {
      cancelExportToken = c;
    }),
  });
};

const getRecommendedUsers = queryData => {
  return makeRequest({
    method: 'get',
    url: `${COLLABORATION_BASE_URL}`,
    params: queryData,
  });
};

const getRecommendedUser = id => {
  return makeRequest({
    method: 'get',
    url: `${COLLABORATION_BASE_URL}/${id}`,
  });
};

const createRecommendedUser = data => {
  return makeRequest({
    method: 'post',
    url: `${COLLABORATION_BASE_URL}`,
    data,
  });
};

const updateRecommendedUser = (id, data) => {
  return makeRequest({
    method: 'put',
    url: `${COLLABORATION_BASE_URL}/${id}`,
    data,
  });
};

const deleteRecommendedUser = id => {
  return makeRequest({
    method: 'delete',
    url: `${COLLABORATION_BASE_URL}/${id}`,
  });
};

export const collaborationService = {
  getRecommendedUsers,
  getRecommendedUser,
  createRecommendedUser,
  updateRecommendedUser,
  deleteRecommendedUser,
  exportRecommendedUsers,
  cancelRecommendedUsersExport: () => cancelExportToken(),
};
