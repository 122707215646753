import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCompanyUsers,
  getPepStructures,
  getCounties,
  getRoles,
  getStructures,
  clearCurrentUser,
  getUser,
} from 'redux/admin';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { copyToClipboard } from 'utils/helpers';
import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTemplate from 'templates/PageTemplate';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import AddUser from 'components/forms/Admin/AddUser';
import EditUser from 'components/forms/Admin/EditUser';
import UsersTable from 'components/layout/Tables/UsersTable';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';
import Notification from 'components/UI/Notification';
import { TERMINAL_SPUID_BASE_URL } from 'config/constants';
import LoginAsMaster from 'components/logic/LoginAsMaster';
import Select from 'components/UI/Select';

const headers = [
  { displayName: '', property: 'feSubPartner' },
  { displayName: 'Imię i Nazwisko', property: 'fullName' },
  { displayName: 'Telefon', property: 'phone' },
  { displayName: 'Email', property: 'email' },
  { displayName: 'Rola', property: 'roleName' },
  { displayName: 'Aktywny', property: 'active' },
];

const CompanyUsersPage = ({
  getCompanyUsers,
  usersData,
  isLoading,
  error,
  currentUser,
  getPepStructures,
  getCounties,
  getRoles,
  getStructures,
  structures,
  roles,
  loggedUser,
  clearCurrentUser,
  getUser,
}) => {
  const userRole = loggedUser.role.slug;
  const history = useHistory();
  const companyId = history.location?.state?.company?.id || null;

  const [isNewUserVisible, setNewUserVisible] = useState(false);
  const [isEditUserVisible, setEditUserVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('active');
  const [notification, setNotification] = useState('');

  const [loginAsUserData, setLoginAsUserData] = useState('');

  useEffect(() => {
    if (!companyId) history.replace(routes.adminCompanies);
  }, [history, companyId]);

  const getUsersData = useCallback(() => {
    const query = {
      ...(search && { search }),
      ...(status && { status }),
    };
    if (companyId) getCompanyUsers(companyId, query);
  }, [search, status, companyId, getCompanyUsers]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  useEffect(() => {
    if (companyId) {
      getStructures();
      getRoles();
      getCounties();
      getPepStructures();
    }
  }, [companyId, getStructures, getRoles, getCounties, getPepStructures]);

  const handleSearch = value => setSearch(value);

  const handleTopBarElement = (e, set) => {
    set(e.target.value);
  };

  const handleTermianl = userId => {
    setNotification('Skopiowano link');
    setTimeout(() => setNotification(false), 2500);
    copyToClipboard(TERMINAL_SPUID_BASE_URL + userId);
  };

  const closeEditUserPanel = () => {
    setEditUserVisible(false);
    clearCurrentUser();
  };

  const openEditUserPanel = id => {
    setEditUserVisible(true);
    getUser(id);
  };

  const toggleNewUserPanel = () => {
    setNewUserVisible(!isNewUserVisible);
  };

  return (
    <PageTemplate>
      {loginAsUserData && <LoginAsMaster loginAs={loginAsUserData} />}
      <TopSection>
        <div>
          <Heading size="xxl">Partner {history.location.state && history.location.state.company.name}</Heading>
        </div>
        <TableSorter>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={e => handleTopBarElement(e, setStatus)}>
              <option value="active">Aktywni</option>
              <option value="inactive">Nieaktywni</option>
              {['admin'].includes(userRole) && <option value="deleted">Usunięci</option>}
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => history.push(routes.adminCompanyUsers)}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Heading size="xl">Lista użytkowników</Heading>
          <UsersTable
            companyUsersTable
            handleTerminal={handleTermianl}
            {...(['admin', 'dsp'].includes(userRole) && { handleEdit: openEditUserPanel })}
            {...(['admin'].includes(userRole) && { handleLoginAs: setLoginAsUserData })}
            data={usersData}
            headers={headers}
            offset={0}
          />
        </>
      )}
      <BottomSection>
        <BottomItem>Użytkowników w zespole: {usersData.length}</BottomItem>
        <BottomItem>
          {['admin', 'dsp'].includes(userRole) && (
            <Button onClick={toggleNewUserPanel}>
              <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj użytkownika
            </Button>
          )}
        </BottomItem>
      </BottomSection>
      <Notification show={notification}>{notification}</Notification>
      <SidePanel small toggleClose={closeEditUserPanel} isVisible={isEditUserVisible}>
        {currentUser !== null && (
          <EditUser
            toggleClose={closeEditUserPanel}
            refetchUsers={getUsersData}
            refetchPepStructures={getPepStructures}
            userData={currentUser}
            structures={structures}
            roles={roles}
            loggedUser={loggedUser}
          />
        )}
      </SidePanel>
      <SidePanel small toggleClose={toggleNewUserPanel} isVisible={isNewUserVisible}>
        <AddUser
          refetchUsers={getUsersData}
          refetchPepStructures={getPepStructures}
          structures={structures}
          roles={roles.filter(role => ['partner', 'subPartner'].includes(role.slug))}
          loggedUser={loggedUser}
          initialValues={{
            companyId: companyId || '',
            roleId: roles.length > 0 ? roles.find(role => role.slug === 'partner').id : '',
            roleSlug: roles.length > 0 ? roles.find(role => role.slug === 'partner').slug : '',
          }}
        />
      </SidePanel>
    </PageTemplate>
  );
};

CompanyUsersPage.defaultProps = {
  error: null,
  currentUser: null,
};

CompanyUsersPage.propTypes = {
  usersData: PropTypes.instanceOf(Array).isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.instanceOf(Object),
  structures: PropTypes.instanceOf(Array).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  loggedUser: PropTypes.instanceOf(Object).isRequired,

  getCompanyUsers: PropTypes.func.isRequired,
  getPepStructures: PropTypes.func.isRequired,
  getStructures: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getCounties: PropTypes.func.isRequired,
  clearCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.admin.companyUsers.isLoading,
  usersData: state.admin.companyUsers.data,
  error: state.admin.companyUsers.error,

  currentUser: state.admin.currentUser.data,
  loggedUser: state.user.data,
  roles: state.admin.roles.data,
  structures: state.admin.structures.data,
});

const mapDispatchToProps = {
  getCompanyUsers,
  getPepStructures,
  getStructures,
  getUser,
  getRoles,
  getCounties,
  clearCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsersPage);
