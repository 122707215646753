/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import { mapStatusObjShort, mapStatusToColor } from 'config/constants';

const calcTotal = dataObj => Object.values(dataObj).reduce((acc, value) => value + acc, 0);

const getColor = bar => {
  return mapStatusToColor[bar.id];
};

const LeadsTrendBar = ({ data }) => {
  const total = useMemo(() => calcTotal(data[0]), [data]);
  const translated = useMemo(
    () =>
      data.map(obj => {
        let newObj = {};
        Object.keys(obj).forEach(key => {
          if (key !== 'install_terminal') newObj = { ...newObj, [mapStatusObjShort[key]]: obj[key] };
        });
        return newObj;
      }),
    [data],
  );

  return (
    <Wrapper>
      <ChartWrapper>
        <ResponsiveBar
          data={translated}
          keys={Object.keys(translated[0])}
          margin={{ top: -20, right: 0, bottom: -10, left: 0 }}
          padding={0.3}
          layout="horizontal"
          indexScale={{ type: 'band', round: true }}
          colors={getColor}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          tooltip={input => (
            <Tooltip boxColor={input.color}>
              {input.id} - {input.value} ({Number((input.value / total) * 100).toFixed(2)}%)
            </Tooltip>
          )}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        />
      </ChartWrapper>
      <LegendWrapper>
        {Object.entries(data[0])
          .filter(([_, value]) => value !== 0)
          .map(([key, value]) => (
            <Label key={key} color={mapStatusToColor[key]}>
              <span />
              {mapStatusObjShort[key]} - <strong>{value}</strong>
            </Label>
          ))}
      </LegendWrapper>
    </Wrapper>
  );
};

LeadsTrendBar.defaultProps = {};

LeadsTrendBar.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const ChartWrapper = styled.div`
  height: 60px;
  display: flex;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  user-select: none;
`;

const Tooltip = styled.div`
  font-weight: ${font.weight.semibold};

  &::before {
    content: '';
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.5em;
    background: ${props => props.boxColor};
    border-radius: 2px;
  }
`;

const Label = styled.div`
  display: block;
  font-size: ${font.size.s};
  color: ${colors.black};
  line-height: 20px;
  margin-bottom: 10px;
  padding-right: 20px;

  strong {
    font-weight: ${font.weight.semibold};
  }

  input {
    display: none;
    &:checked + span::after {
      opacity: 1;
    }
  }

  & > span {
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    color: ${props => props.color || '#eee'};
    background: currentColor;
    border: 1px solid currentColor;
    border-radius: 50%;
    overflow: hidden;
  }
`;

export default LeadsTrendBar;
