/* eslint-disable no-case-declarations */
import moment from 'moment';
import { assignMessageToUser } from 'utils/helpers';
import { chatModes } from 'config/constants';
import * as types from './types';

window.moment = moment;

const initialState = {
  chatMode: chatModes.normal,
  isLoadingLeads: false,
  isLoadingPatrons: false,
  isPanelActive: false,
  newMessages: 0,
  unreadConversations: [],
  localLastSeen: {},
  localLastSeenMessages: {},
  allConversations: {
    data: [],
    isLoading: false,
    error: '',
    initialLoaded: false,
  },
  allArchivedConversations: {
    data: [],
    isLoading: false,
    error: '',
    initialLoaded: false,
  },
  activeConversation: {
    conversation: false,
    messages: [],
    lastStatus: false,
    isLoading: false,
    error: '',
    initialLoaded: false,
  },
  systemMessages: {
    data: [],
    isLoading: false,
    error: '',
  },
  systemConversations: {
    data: [],
    isLoading: false,
    error: '',
  },
  currentSystemConversation: {
    data: null,
    isLoading: false,
    error: '',
  },
};

const isConversationUnread = (conv, loggedUserId, localLastSeenConversation, state) => {
  const { details, lastMessageAt, lastMessage } = conv;

  // przypadek kiedy brak dostepu, tylko wtedy lastMessage = false, a nie null czy obiekt
  if (lastMessage === false) return false;

  // Ingnoruj konwersację która jest aktywna
  if (state.activeConversation?.conversation?.id === conv.id) return false;

  // Jeżeli ja pisałem ostatnią wiadomość to zawsze przeczytana
  if (lastMessage && lastMessage.userId === loggedUserId) return false;

  // Jeżeli istnieje JAKAŚ wersja informacji o przeczytanych elementach
  if (localLastSeenConversation || details) {
    let lastVisited;

    // Lokalna wersja ma pierwszeństwo
    if (localLastSeenConversation) {
      lastVisited = localLastSeenConversation;
    } else if (details) {
      // Jeżeli nie znaleziono to zweryfikuj lastVisits na details konwersacji
      const parsedDetails = JSON.parse(details);
      lastVisited = parsedDetails.lastVisits ? parsedDetails.lastVisits.find(el => el.userId === loggedUserId) : false;
    }

    if (!lastVisited || !lastMessageAt) return true;
    return moment(lastVisited.lastSeen || lastVisited).isBefore(moment(lastMessageAt));
  }

  // Jeżeli brakuje danych to nieprzeczytana
  return true;
};

function uniqBy(a, key) {
  const seen = new Set();
  return a.filter(item => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

const sortByLastMessage = (a, b) => new Date(b.lastMessageAt) - new Date(a.lastMessageAt);

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ARCHIVE_CONVERSATION:
      const temp1 = state.allConversations.data.find(conv => conv.id === action.payload);
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          data: state.allConversations.data.filter(conv => conv.id !== action.payload),
        },
        allArchivedConversations: {
          ...state.allArchivedConversations,
          data: uniqBy([...state.allArchivedConversations.data, temp1], conv => conv.id).sort(sortByLastMessage),
        },
        activeConversation: { ...initialState.activeConversation },
      };
    case types.UNARCHIVE_CONVERSATION:
      const temp2 = state.allArchivedConversations.data.find(conv => conv.id === action.payload);
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          data: uniqBy([...state.allConversations.data, temp2], conv => conv.id).sort(sortByLastMessage),
        },
        allArchivedConversations: {
          ...state.allArchivedConversations,
          data: state.allArchivedConversations.data.filter(conv => conv.id !== action.payload),
        },
        activeConversation: { ...initialState.activeConversation },
      };
    case types.SET_ARCHIVED_CONVERSATIONS_ERROR:
      return {
        ...state,
        allArchivedConversations: {
          ...state.allArchivedConversations,
          isLoading: false,
          initialLoaded: true,
          error: action.payload,
        },
      };
    case types.SET_ARCHIVED_CONVERSATIONS_LOADING:
      return {
        ...state,
        allArchivedConversations: {
          ...state.allArchivedConversations,
          isLoading: action.payload,
        },
      };
    case types.SET_ARCHIVED_CONVERSATIONS:
      return {
        ...state,
        allArchivedConversations: {
          data: uniqBy([...state.allArchivedConversations.data, ...action.payload], conv => conv.id).sort(
            sortByLastMessage,
          ),
          initialLoaded: true,
          isLoading: false,
        },
      };
    case types.SET_MODE:
      return {
        ...state,
        chatMode: action.payload,
      };
    case types.SET_LOCAL_LAST_SEEN_MESSAGES:
      return {
        ...state,
        localLastSeenMessages: {
          ...state.localLastSeenMessages,
          [action.payload]: moment().subtract(state.timeDiff),
        },
      };
    case types.IS_LOADING_LEADS:
      return {
        ...state,
        isLoadingLeads: action.payload,
      };
    case types.IS_LOADING_PATRONS:
      return {
        ...state,
        isLoadingPatrons: action.payload,
      };
    case types.SET_TIME: {
      const localTime = moment();
      return {
        ...state,
        serverTime: action.payload,
        localTime,
        timeDiff: moment(localTime).diff(action.payload),
      };
    }
    case types.UPDATE_LAST_SEEN_IN_CONVERSATION_DETAILS:
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          data: state.allConversations.data.map(conv => {
            if (action.payload.conversationId === conv.id) {
              let tempDetails = JSON.parse(conv.details);
              let tempLastVisits = tempDetails?.lastVisits || false;
              if (tempLastVisits) {
                tempLastVisits = tempLastVisits.map(o =>
                  // o.userId === action.payload.loggedUserId ? { ...o, lastSeen: new Date().toISOString() } : o,
                  o.userId === action.payload.loggedUserId
                    ? {
                        ...o,
                        lastSeen: moment()
                          .subtract(state.timeDiff)
                          .toISOString(),
                      }
                    : o,
                );
                tempDetails = JSON.stringify({ ...tempDetails, lastVisits: tempLastVisits });
                return { ...conv, details: tempDetails };
              }
              return conv;
            }
            return conv;
          }),
        },
      };
    case types.CLEAR_PREVIEW_CONVERSATIONS:
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          data: state.allConversations.data.filter(conv => conv.users.some(u => u.id === action.payload)),
        },
      };
    case types.SET_CONVERSATION_USERS:
      const { users, convId } = action.payload;
      const isActiveConv = state.activeConversation.conversation.id === convId;
      const tempState = {
        ...state,
      };

      Object.assign(tempState, {
        allConversations: {
          ...state.allConversations,
          data: state.allConversations.data.map(c =>
            c.id === convId
              ? {
                  ...c,
                  users,
                }
              : c,
          ),
        },
      });

      if (isActiveConv) {
        Object.assign(tempState, {
          activeConversation: {
            ...state.activeConversation,
            conversation: {
              ...state.activeConversation.conversation,
              users,
            },
          },
        });
      }

      return tempState;
    case types.CHECK_UNREAD_CONVERSATIONS:
      const loggedUserId = action.payload;
      const unreadConversations = state.allConversations.data
        .filter(conv => isConversationUnread(conv, loggedUserId, state.localLastSeen[conv.id], state))
        .map(c => c.id);
      return {
        ...state,
        unreadConversations,
      };
    case types.SET_PANEL_ACTIVE:
      return {
        ...state,
        isPanelActive: action.payload,
      };
    case types.SET_CONVERSATIONS_ERROR:
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          isLoading: false,
          initialLoaded: true,
          error: action.payload,
        },
      };
    case types.SET_CONVERSATIONS_LOADING:
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          isLoading: action.payload,
        },
      };
    case types.SET_CONVERSATIONS:
      return {
        ...state,
        allConversations: {
          data: uniqBy([...state.allConversations.data, ...action.payload], conv => conv.id).sort(sortByLastMessage),
          initialLoaded: true,
          isLoading: false,
        },
      };
    case types.SET_ACTIVE_CONVERSATION_MESSAGES_LOADING:
      return {
        ...state,
        activeConversation: {
          ...state.activeConversation,
          isLoading: action.payload,
        },
      };
    case types.ADD_CONVERSATION:
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          data: uniqBy([...state.allConversations.data, action.payload], conv => conv.id).sort(sortByLastMessage),
          isLoading: false,
        },
      };
    case types.ADD_PREVIOUS_CONVERSATIONS:
      return {
        ...state,
        allConversations: {
          ...state.allConversations,
          data: uniqBy([...state.allConversations.data, ...action.payload], conv => conv.id).sort(sortByLastMessage),
          isLoading: false,
        },
      };
      case types.ADD_PREVIOUS_ARCHIVES:
        return {
          ...state,
          allArchivedConversations: {
            ...state.allArchivedConversations,
            data: uniqBy([...state.allArchivedConversations.data, ...action.payload], conv => conv.id).sort(sortByLastMessage),
            isLoading: false,
          },
        };
    case types.SET_ACTIVE_CONVERSATION:
      return {
        ...state,
        localLastSeen: {
          ...state.localLastSeen,
          [action.payload.id]: moment().subtract(state.timeDiff),
        },
        unreadConversations: state.unreadConversations.filter(conv => conv !== action.payload.id),
        activeConversation: {
          ...state.activeConversation,
          initialLoaded: false,
          conversation: action.payload,
          messages: [],
        },
      };
    case types.SET_ACTIVE_CONVERSATION_MESSAGES:
      return {
        ...state,
        activeConversation: {
          ...state.activeConversation,
          messages: action.payload,
          lastStatus: types.STATUSES.INITIAL_LOADED,
          initialLoaded: true,
        },
      };
    case types.ADD_MESSAGE:
      const { message, conversationId } = action.payload;
      const isActiveConversation = state.activeConversation.conversation.id === conversationId;
      const returnObj = {
        ...state,
      };

      // Gdy dodawana jest nowa wiadomość to:
      // - poszukaj do której konwersacji jest przypisana i zaktualizuj lastMessage i lastMessageAt w celu wyświetlenia informaci o nowej wiadomości
      // - sprawdź czy w danej konwersacji lokalnie nie brakuje użytkownika, jeżeli tak to go dodaj (potrzebne do wyświetlenia czyja jest wiadomość przy renderowaniu)
      // - jeżeli nie ponado conversationId to znaczy że tą wiadomość napisał user, więc dodajemy do active
      Object.assign(returnObj, {
        allConversations: {
          ...state.allConversations,
          data: state.allConversations.data
            .map(c =>
              c.id === message.wsConversationId
                ? {
                    ...c,
                    lastMessage: {
                      createdAt: message.createdAt,
                      id: message.id,
                      message: message.message,
                      userId: message.userId,
                    },
                    lastMessageAt: message.createdAt,
                    users:
                      message.user && c.users.some(u => u.id === message.user.id)
                        ? [...c.users]
                        : [...c.users, message.user],
                  }
                : c,
            )
            .sort(sortByLastMessage),
        },
      });

      if (!conversationId || isActiveConversation) {
        const assignedMessage = assignMessageToUser(state.activeConversation.conversation.users, message);
        Object.assign(returnObj, {
          localLastSeen: {
            ...state.localLastSeen,
            [conversationId]: moment().subtract(state.timeDiff),
          },
          activeConversation: {
            ...state.activeConversation,
            conversation: {
              ...state.activeConversation.conversation,
              lastMessage: assignedMessage,
              lastMessageAt: assignedMessage.createdAt,
            },
            messages: [...state.activeConversation.messages, assignedMessage],
            lastStatus: types.STATUSES.NEW,
          },
        });
      }

      return returnObj;
    case types.ADD_PREVIOUS_MESSAGES:
      if (action.payload.isOk === false) return { ...state };
      if (state.activeConversation.conversation.id !== action.conversationId) return { ...state };
      return {
        ...state,
        activeConversation: {
          ...state.activeConversation,
          messages: [...action.payload, ...state.activeConversation.messages],
          lastStatus: types.STATUSES.PREVIOUS,
        },
      };
    case types.CLEAR_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversation: { ...initialState.activeConversation },
      };
    case types.CLEAR_CURRENT_SYSTEM_CONVERSATION:
      return {
        ...state,
        currentSystemConversation: { ...initialState.currentSystemConversation },
      };
    case types.CLEAR_CONVERSATIONS:
      return {
        ...state,
        isLoadingLeads: false,
        isLoadingPatrons: false,
        isPanelActive: false,
        localLastSeen: {},
        localLastSeenMessages: {},
        allConversations: { ...initialState.allConversations },
        activeConversation: { ...initialState.activeConversation },
      };
    case types.CLEAR_CHAT:
      return initialState;
    default:
      return state;
  }
};

export default chatReducer;
