import { p24LeadService } from 'services/leadP24';
import * as types from './types';

export const get24Leads = query => dispatch => {
  dispatch({ type: types.GET_P24_LEADS_REQUEST });

  return p24LeadService
    .getP24Leads(query)
    .then(payload => {
      dispatch({ type: types.GET_P24_LEADS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_P24_LEADS_FAILURE, err });
    });
};

export const clearLeads = () => dispatch => {
  dispatch({ type: types.CLEAR_LEADS });
};
