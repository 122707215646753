import React from 'react';
import Note from 'components/UI/Note';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddNote from 'components/forms/AddNote';
import NoData from 'components/UI/NoData';
import { colors } from 'theme/styles';
import { sortByCreatedAt } from 'utils/helpers';

const ClientNotes = ({ notes, allowAddNote, handleSubmit }) => (
  <>
    <NotesWrapper>
      {notes.length > 0 ? (
        notes.sort(sortByCreatedAt).map(note => <Note key={note.id} data={note} />)
      ) : (
        <NoData message="Brak notatek do wyświetlenia" />
      )}
    </NotesWrapper>
    {allowAddNote && (
      <FormWrapper>
        <AddNote handleSubmit={handleSubmit} />
      </FormWrapper>
    )}
  </>
);

ClientNotes.defaultProps = {
  allowAddNote: false,
};

ClientNotes.propTypes = {
  notes: PropTypes.instanceOf(Array).isRequired,
  allowAddNote: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export default ClientNotes;

const NotesWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(75vh - 150px);
`;

const FormWrapper = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${colors.lightGray};
`;
