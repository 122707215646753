export const GET_INTEGRATION_ORDERS_REQUEST = 'GET_INTEGRATION_ORDERS_REQUEST';
export const GET_INTEGRATION_ORDERS_SUCCESS = 'GET_INTEGRATION_ORDERS_SUCCESS';
export const GET_INTEGRATION_ORDERS_FAILURE = 'GET_INTEGRATION_ORDERS_FAILURE';

export const GET_INTEGRATION_ORDER_DETAILS_REQUEST = 'GET_INTEGRATION_ORDER_DETAILS_REQUEST';
export const GET_INTEGRATION_ORDER_DETAILS_SUCCESS = 'GET_INTEGRATION_ORDER_DETAILS_SUCCESS';
export const GET_INTEGRATION_ORDER_DETAILS_FAILURE = 'GET_INTEGRATION_ORDER_DETAILS_FAILURE';

export const SET_INTEGRATION_ORDERS_ERROR = 'SET_INTEGRATION_ORDERS_ERROR'

export const SET_CURRENT_INTEGRATION_ORDER = 'SET_CURRENT_INTEGRATION_ORDER';
export const SET_CURRENT_INTEGRATION_ORDER_ERROR = 'SET_CURRENT_INTEGRATION_ORDER_ERROR'
export const SET_CURRENT_INTEGRATION_ORDER_LOADING = 'SET_CURRENT_INTEGRATION_ORDER_LOADING';

export const CLEAR_INTEGRATION_ORDERS_ERROR = 'CLEAR_INTEGRATION_ORDERS_ERROR';

export const CLEAR_CURRENT_INTEGRATION_ORDER = 'CLEAR_CURRENT_INTEGRATION_ORDER';
export const CLEAR_CURRENT_INTEGRATION_ORDER_ERROR = 'CLEAR_CURRENT_INTEGRATION_ORDER_ERROR';

export const CLEAR_INTEGRATION_ORDERS_REDUCER = 'CLEAR_INTEGRATION_ORDERS_REDUCER';
