import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Tooltip = ({ children, side, width }) => {
  return (
    <Wrapper>
      <QuestionSign icon="question" />
      <Text side={side} width={width}>
        {children}
      </Text>
    </Wrapper>
  );
};

Tooltip.defaultProps = {
  children: '',
  side: 'right',
  width: 0,
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  width: PropTypes.number,
};

export default Tooltip;

// const Wrapper = styled.span`
//   background: ${colors.orange};
//   display: inline-block;
//   color: ${colors.white};
//   font-size: ${font.size.s};
//   padding: 2px 8px;
//   border-radius: 4px;
// `;

const QuestionSign = styled(FontAwesomeIcon)``;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;

  ${QuestionSign} {
    color: ${colors.orange};
    opacity: 0.75;
    margin-left: 5px;
    font-size: 0.8rem;
    vertical-align: text-top;
  }
`;

const Text = styled.div`
  --w: ${({ width }) => (width ? `${width}px` : '220px')};

  position: absolute;
  visibility: hidden;
  width: var(--w);
  background: ${colors.white};
  color: ${colors.darkGray};
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.4;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #00000020;
  z-index: 1;
  top: -5px;
  left: calc(100% + 1rem);

  ${Wrapper}:hover & {
    visibility: visible;
  }

  ${({ side }) =>
    side === 'top' &&
    css`
      width: var(--w);
      bottom: 100%;
      left: 50%;
      margin-left: calc(var(--w) / -2);
    `}

  ${({ side }) =>
    side === 'bottom' &&
    css`
      width: var(--w);
      top: 100%;
      left: 50%;
      margin-left: calc(var(--w) / -2);
    `}

  ${({ side }) =>
    side === 'left' &&
    css`
      top: -5px;
      right: calc(100% + 1rem);
    `}
`;
