import makeRequest from 'services/makeRequest';

const GET_COUNTIE_CODES_URL = 'region/post-codes';
const UPDATE_CODES_URL = 'region/post-codes/update';

const getCountyCodes = countyId => {
  return makeRequest({
    method: 'get',
    url: `${GET_COUNTIE_CODES_URL}/${countyId}`,
  });
};

const updateCountyCodes = data => {
  return makeRequest({
    method: 'post',
    url: UPDATE_CODES_URL,
    data,
    timeout: 0,
  });
};

export const regionService = {
  getCountyCodes,
  updateCountyCodes,
};
