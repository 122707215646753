import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'theme/styles';
import { displayTimeWithSeconds } from 'utils/helpers';
import { Label, Text } from 'components/layout/Client/ClientInfo';
import Heading from 'components/UI/Heading';

const GraylogLogDisplay = ({ log }) => {
  const memoizedPayload = useMemo(() => {
    try {
      return <pre>{JSON.stringify(JSON.parse(log.payload), null, 4)}</pre>;
    } catch (error) {
      return <p>{log.payload || '-'}</p>;
    }
  }, [log.payload]);

  const memoizedExtra = useMemo(() => {
    try {
      return <pre>{JSON.stringify(JSON.parse(log.extra), null, 4)}</pre>;
    } catch (error) {
      return <p>{log.extra || '-'}</p>;
    }
  }, [log.extra]);

  return (
    <Wrapper>
      <Heading color={colors.orange} style={{ marginBottom: '1rem' }} size="l">
        ReqCodeId - {log.reqCodeId}
      </Heading>
      <ColsWrapper>
        <Col>
          <Label>Czas</Label>
          <Text>{displayTimeWithSeconds(log.timestamp)}</Text>
        </Col>
        <Col>
          <Label>Timestamp</Label>
          <Text>{log.timestamp}</Text>
        </Col>
        <Col>
          <Label>Zródło</Label>
          <Text>{log.source}</Text>
        </Col>
        <Col>
          <Label>Autoryzacja</Label>
          <Text>{log.authType}</Text>
        </Col>
      </ColsWrapper>
      <ColsWrapper>
        <Col>
          <Label>Akcja</Label>
          <Text>{log.full_message}</Text>
        </Col>
        <Col>
          <Label>Użytkownik</Label>
          <Text>{log.user}</Text>
        </Col>
        <Col>
          <Label>Metoda</Label>
          <Text>{log.method}</Text>
        </Col>
        <Col>
          <Label>Url</Label>
          <Text>{log.url}</Text>
        </Col>
      </ColsWrapper>
      <ColsWrapperBig style={{marginTop: '1rem'}}>
        <Col>
          <Label>Payload</Label>
          {memoizedPayload}
        </Col>
        <Col>
          <Label>Extra</Label>
          {memoizedExtra}
        </Col>
      </ColsWrapperBig>
    </Wrapper>
  );
};

GraylogLogDisplay.propTypes = {
  log: PropTypes.instanceOf(Object).isRequired,
};

export default GraylogLogDisplay;

const Wrapper = styled.div`
  margin-top: 2rem;

  & + & {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid ${colors.gray};
  }

  ${Label} {
  }

  ${Text} {
    margin-bottom: 1rem;
  }

  ${Text},
  p,
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
  }
`;

const ColsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  & > * {
    flex: 1 1 32rem;
    max-width: 32rem;
  }
`;

const ColsWrapperBig = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  & > * {
    flex: 1 1 66rem;
    max-width: 66rem;
  }
`;
const Col = styled.div``;
