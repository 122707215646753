import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';
import { RecommendationsStatusObj, provinces } from 'config/constants';
import NoData from 'components/UI/NoData';
import { displayDate } from 'utils/helpers';

const UsersTable = ({ data, headers, offset, handleEdit, canUserEdit, handleDelete }) => (
  <TableWrapper>
    {data.length > 0 ? (
      <table>
        <colgroup>
          <col className="lp" />
          {headers.map(header => {
            switch (header.property) {
              case 'createdAt':
                return <col key={header.property} className={`${header.property}-wide`} />;
              default:
                return <col key={header.property} className={header.property} />;
            }
          })}
          <col className="actions" />
        </colgroup>
        <thead>
          <tr>
            <th>LP</th>
            {headers.map(header => (
              <th key={header.property}>{header.displayName}</th>
            ))}
            <th key="edit">Edycja</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => {
            const canEdit = canUserEdit(user);
            return (
              <tr key={user.id}>
                <td>{offset + index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = user.id + header.property;
                  switch (header.property) {
                    case 'voivodeship':
                      return (
                        <td key={uniqueKey}>
                          {provinces.find(prov => prov.value === user.voivodeship).display}
                        </td>
                      );
                    case 'createdAt':
                      return (
                        <td key={uniqueKey}>
                          {displayDate(user[header.property])}
                        </td>
                      );
                    case 'status':
                      return (
                        <td key={uniqueKey}>
                          {RecommendationsStatusObj[user[header.property]] || user[header.property]}
                        </td>
                      );
                    default:
                      if (header.subProperty) {
                        return (
                          <td key={uniqueKey}>
                            {user[header.property] ? user[header.property][header.subProperty] : ''}
                          </td>
                        );
                      }
                      return <td key={uniqueKey}>{user[header.property]}</td>;
                  }
                })}
                <td key="edit">
                  <span className="icons">
                    {handleEdit && (
                      <FontAwesomeIcon
                        style={!canEdit && { opacity: '0.5', cursor: 'default' }}
                        title={canEdit ? 'Edytuj' : 'Edycja niemożliwa'}
                        icon="edit"
                        onClick={canEdit ? () => handleEdit(user.id) : null}
                      />
                    )}
                    {handleDelete && (
                      <FontAwesomeIcon
                        title="Usuń"
                        icon="trash-alt"
                        onClick={() => handleDelete(user)}
                      />
                    )}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : (
      <NoData />
    )}
  </TableWrapper>
);

UsersTable.defaultProps = {
  handleDelete: false,
};

UsersTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  canUserEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default UsersTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 720px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;

    tr {
      th,
      td {
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 35px;
        &:nth-of-type(even) {
          background-color: ${colors.white};
        }
      }

      td {
        font-size: ${font.size.s};
        text-transform: none;
        color: ${colors.black};

        & > svg {
          color: ${colors.orange};
        }
        & > .icons {
          color: ${colors.orange};
          font-size: ${font.size.m};
          svg {
            cursor: pointer;
            margin: 0 7px;
          }
        }
      }
    }
  }
`;
