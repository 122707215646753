import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, breakpoints } from 'theme/styles';

const Hamburger = ({ active, onClick }) => (
  <StyledHamburger active={active} onClick={onClick}>
    <span />
    <span />
    <span />
  </StyledHamburger>
);

Hamburger.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Hamburger;

// styled components

const StyledHamburger = styled.div`
  display: block;
  width: 28px;
  height: 28px;
  padding: 5px;
  display: none;
  margin: 0;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  
  span {
    display: block;
    position: relative;
    width: 100%;
    height: 2px;
    background-color: ${colors.white};
    transition: transform 0.4s ease, opacity 0.2s;
  }
  
  ${({ active }) =>
    active &&
    css`
      span {
        &:nth-child(1) {
          transform: translateY(6px) rotate(-45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    `}

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
  }
`;
