/* eslint-disable no-dupe-else-if */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray, ErrorMessage, Field } from 'formik';
import { FormWrapperCommon, Columns, FieldHeading, FormColumn } from 'components/forms/FormComponents';
import { updateLeadSchema } from 'utils/validation';
import { useDoubleConfirmPopup } from 'utils/hooks';
import { getAllProducts } from 'redux/product';
import { updateCurrentLead, getLead } from 'redux/lead';
import { clearClearityCrmCheck, crmCheck, setClearityError } from 'redux/admin';
import { suggestionsService } from 'services/suggestions';
import { leadService } from 'services/lead';
import { routes } from 'routes';
import { colors } from 'theme/styles';
import { Label, Text } from 'components/layout/Client/ClientInfo';
import CrmAccountDisplayMinimal from 'components/layout/CrmAccountDisplayMinimal';
import TransferLeadForm from 'components/forms/Admin/TransferLead';
import Input from 'components/UI/Input';
import SuccessPopup, { PopupScroller } from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Textarea from 'components/UI/Textarea';
import NumberInput from 'components/UI/NumberInput';
import DateTimePicker from 'components/UI/DateTimePicker';
import InputAuto from 'components/UI/InputAuto';
import Select from 'components/UI/Select';
import usePopup from 'hooks/usePopup';
import Loading from 'components/UI/Loading';
import moment from 'moment';

// This ID is same on local / test / prod
const isAclasProduct = (prodId) => ['1ba6d72d-9244-4b28-a1b6-404be609e0ef'].includes(prodId);

const formButtonText = (userRole, status, defaultText = 'Podgląd formularza') => {
  let text = defaultText;
  if (userRole === 'partner') text = status === 'DRAFT' ? 'Uzupełnij formularz' : 'Formularz uzupełniony';
  return text;
};

const getRegisterCrmTitle = (lead) => {
  let tmp = 'Zarejestruj lead w PeP CRM';

  if (lead.crmAccount) {
    tmp = 'Lead został już zarejsetrowany w PeP CRM';
  } else if (!lead.queue) {
    tmp = 'Brak możliwości rejestracji - brak przypisanego przedstawiciela handlowego';
  } else if (!lead.takenUser) {
    tmp = 'Brak możliwości rejestracji - lead musi zostać podjęty przez przedstawiciela';
  }
  return tmp;
};

const EditLead = ({ leadData, userRole, userId, getAllProducts, products, updateCurrentLead, getLead }) => {
  const [loading, setLoading] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const [isAclasTouched, setIsAclasTouched] = useState(false);

  const [ForgetPopup, popupTrigger] = useDoubleConfirmPopup({
    firstQuestion: `Czy chcesz zapomnieć leada: ${leadData.companyName} ?`,
    secondQuestion: `Zmiana jest nieodwracalna, lead ${leadData.companyName} zostanie zapomniany.\n Czy jesteś pewien?`,
    submitHandler: async () => {
      return leadService.forgetLead(leadData.id);
    },
    beforeSubmit: () => setLoading(true),
    afterSubmit: () => setLoading(false),
    redirectAfter: '/',
  });
  // const [sidePanelVisible, setSidePanelVisible] = useState(false);
  // const [leadMessages, setLeadMessages] = useState([]);
  const [reassigneMode, setReassigneMode] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [edit, setEdit] = useState(false);
  const [editable, setEditable] = useState(false);
  const [renewLeadSuccess, setRenewLeadSuccess] = useState('');

  const [canEditContactDate, setCanEditContactDate] = useState(false);
  const [editContactDate, setEditContactDate] = useState(false);
  const [takePossible, setTakePossible] = useState(false);
  // const [conversationPossible, setConversationPossible] = useState(false);
  const [rejectable, setRejectable] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [phList, setPhList] = useState([]);
  const [TransferLeadPopup, toggleTransferPopup] = usePopup();
  const [SelectCrmAccountsPopup, toggleSelectCrmAccountsPopup] = usePopup();
  const history = useHistory();

  const userData = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  // Crm specific
  const crmCheckResult = useSelector((s) => s.admin.clarity.crmCheckResult);
  const isLoadingClarity = useSelector((s) => s.admin.clarity.isLoading);
  const errorClarity = useSelector((s) => s.admin.clarity.error);
  const [accounts, setAccounts] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  useEffect(() => {
    if (['admin', 'dsp'].includes(userRole)) setEditable(true);
    if (['merchantPep'].includes(userRole)) {
      setCanEditContactDate(true);
      if (leadData.takenUser === userId && leadData.status === 'taken') setRejectable(true);
      if (leadData.status === 'rejected_ph' && leadData.queue?.user?.id === userId) setIsRejected(true);
    }
    if (leadData.takenUser === null) {
      if (userRole !== 'merchantPep') setEditable(true);
      if (userRole === 'merchantPep' && leadData.status === 'new' && leadData.queue.userId === userId) {
        setTakePossible(true);
      }
    }
  }, [leadData, userRole, userId]);

  const canRenewLead = () => {
    const allowedStatuses = ['new', 'taken', 'in_progress'];

    const leadCreatedAt = moment(leadData.createdAt).startOf('day');
    const daysAgo = moment().startOf('day').diff(leadCreatedAt, 'days');
    const isInRange = daysAgo <= 180 && daysAgo >= 166;

    return allowedStatuses.includes(leadData.status) && isInRange;
  };

  const handleDebugMode = () => {
    if (['admin'].includes(userRole)) setDebugMode((s) => !s);
  };

  const handleReturn = () => {
    setSuccess('');
    setError('');
    setEditContactDate(false);
    setRenewLeadSuccess('');
  };

  const handleClose = () => {
    setSuccess('');
    setError('');
    history.go(-1);
  };

  const createAccountCrm = () => {
    setError('');
    setSuccess('');
    setLoading(true);

    leadService
      .registerAccountCrm(leadData.id)
      .then((data) => {
        console.log(data);
        setSuccess('Konto w CRM zostało utworzone.');
        updateCurrentLead({
          crmAccount: true,
        });
      })
      .catch((error) => {
        console.dir(error);
        let errorMsg =
          'Wystąpił błąd podczas tworzenia konta w CRM, spróbuj ponownie, lub skontaktuj się z administratorem.';
        if (error.response.data.MERCHANT_LEAD_CRM?.LEAD_CRM_RESULT) {
          errorMsg = error.response.data.MERCHANT_LEAD_CRM.LEAD_CRM_RESULT;
        }
        setError(errorMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const visitClientCard = () => {
    history.push(`/client/${leadData.clientId}`);
  };

  const handleConversation = () => {
    if (leadData.wsConversationId) {
      history.push(`/contact?type=lead&conversation=${leadData.wsConversationId}`);
    } else {
      history.push(`/contact?newLeadConversationId=${leadData.id}`);
    }
  };

  const handleRenewLead = () => {
    setLoading(true);

    leadService
      .renewLead(leadData.id)
      .then((response) => {
        setError('');
        setRenewLeadSuccess(response.data);
      })
      .catch((error) => {
        console.dir(error);
        setRenewLeadSuccess('');
        setError(error.response.data.error.message || 'Nie można odnowić leada. Spróbuj ponownie później.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReassigneLead = () => {
    setLoading(true);

    leadService
      .reassigneLead(leadData.id)
      .then(() => {
        setError('');
        setSuccess('Lead skierowano do ponownego przypisania!');
      })
      .catch((error) => {
        setSuccess('');
        setError(error.response.data.error.message || 'Nie można przypisać leada ponownie. Spróbuj ponownie później.');
      })
      .finally(() => {
        setReassigneMode(false);
        setLoading(false);
      });
  };

  const takeLead = async () => {
    setLoading(true);

    await leadService
      .takeLead(leadData.id)
      .then(() => {
        setSuccess('Lead podjęty!');
        setTakePossible(false);
        // setConversationPossible(true);
        setEditable(false);
        setIsRejected(false);
      })
      .catch((err) => {
        setError(err.response.data.error.message);
      })
      .finally(() => {
        setLoading(false);
      });

    await getLead(leadData.id);
  };

  const formatValues = (values) => {
    const products = JSON.stringify(
      values.products.map((product) => {
        return { id: product };
      }),
    );
    const newValues = { ...values, products };

    if (['admin', 'dsp'].includes(userRole)) {
      newValues.queueAssignedUserId = values.queue;
    } else {
      delete newValues.status;
    }
    delete newValues.queue;

    const removeNulls = (obj) =>
      Object.entries(obj).forEach(([key, val]) => {
        if (val && typeof val === 'object') removeNulls(val);
        else if (key === 'comment' && val === '') return;
        else if (val === null || val === '') delete obj[key];
      });
    removeNulls(newValues);
    return newValues;
  };

  const fetchPhs = (inputValue) => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue, 'merchantPep')
      .then((payload) => setPhList(payload.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const rejectLead = () => {
    setLoading(true);

    leadService
      .rejectLeadByPh(leadData.id)
      .then((payload) => {
        console.log(payload);
        history.go(-1);
      })
      .catch((err) => {
        console.log(err);
        setError('Wystąpił nieoczekiwany problem');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeCrmAccountBinding = () => {
    setLoading(true);

    leadService
      .removeCrmAccountBinding(leadData.id)
      .then(async (payload) => {
        console.log(payload);
        await getLead(leadData.id);
      })
      .catch((err) => {
        console.log(err);
        setError('Wystąpił nieoczekiwany problem');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    let newValues = formatValues(values);

    if (editContactDate && newValues.preferredContactDate) {
      newValues = { preferredContactDate: newValues.preferredContactDate };
    }

    leadService
      .updateLead(leadData.id, newValues)
      .then(async (data) => {
        console.log(data);
        setSuccess('Lead zaktualizowany');
        setEdit(false);
        await getLead(leadData.id);
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!crmCheckResult) return;
    const parser = new DOMParser();
    const doc = parser.parseFromString(crmCheckResult, 'application/xml');
    const errorNode = doc.querySelector('parsererror');
    if (errorNode) {
      console.log('Błąd podczas parsowania XML');
      dispatch(setClearityError('Błąd podczas parsowania XML'));
    } else {
      const accounts = Array.from(doc.querySelectorAll('Accounts Account'));
      accounts.forEach((acc) => (acc.id = acc.querySelector('Id').textContent));
      // const errorMessage = doc.querySelector('status error_message');
      // const errorCode = doc.querySelector('status error_code');
      // const error = doc.querySelector('status error');
      if (accounts.length > 0) setAccounts(accounts);
      // if (error)
      //   setAccountsError((s) => ({
      //     ...s,
      //     error: error.textContent === 'True',
      //     message: errorMessage.textContent,
      //     code: errorCode.textContent,
      //   }));
    }
  }, [crmCheckResult, dispatch]);

  const handleCrmAccountManualPopup = () => {
    toggleSelectCrmAccountsPopup();
    setAccounts(null);
    setSelectedAccountId(null);
    // setAccountsError({ code: null, message: null, error: false });
    dispatch(clearClearityCrmCheck());
    dispatch(crmCheck(leadData.nip));
  };

  const cancelCrmAccountManual = () => {
    toggleSelectCrmAccountsPopup();
    setAccounts(null);
    dispatch(clearClearityCrmCheck());
    // setAccountsError({ code: null, message: null, error: false });
  };

  const handleCrmAccountManual = () => {
    leadService
      .updateCrmAccountById(leadData.id, selectedAccountId)
      .then(async (res) => {
        console.log(res);
        setSuccess('Zaktualizowano CRM account');
        await getLead(leadData.id);
      })
      .catch((err) => {
        setError(err.response.data.error.message);
      })
      .finally(() => {
        handleCrmAccountManualPopup();
      });
  };

  const {
    frontNumLead,
    // id,
    companyName,
    nip,
    phone,
    postCode,
    potentialPOS,
    contactPerson,
    comment,
    preferredContactDate = null,
    products: initialProducts,
    status = null,
  } = leadData;

  const queue = ['admin', 'dsp'].includes(userRole)
    ? (leadData.queue && leadData.queue.user) || ''
    : (leadData.queue && leadData.queue.user.id) || '';

  const selectedProducts = initialProducts.map((o) => o.id);

  const displayProducts = useMemo(() => {
    let temp = products.filter((p) => {
      // Hide inactives
      if (p.active === '0') return false;
      // If product is hidden for users - show it ONLY if it's selected
      if (p.isVisible === '0' && !selectedProducts.includes(p.id)) return false;
      // Else its OK
      return true;
    });

    // If admin or DSP dont filter other cases
    if (['admin', 'dsp'].includes(userRole)) return temp;

    // Partner Program handler
    if (userData?.company?.partnerProgram) {
      const companyProgram = JSON.parse(userData.company.partnerProgram);
      temp = temp.filter((p) => {
        if (p.partnerProgram.length === 0) return true;
        if (p.partnerProgram.some((el) => companyProgram.includes(el))) return true;
      });
    } else temp = temp.filter((p) => p.partnerProgram.length === 0);

    return temp;
  }, [products, selectedProducts, userRole, userData?.company?.partnerProgram]);

  useEffect(() => {
    if (!selectedAccountId) return;
    const accountDOM = document.getElementById(selectedAccountId);
    if (accountDOM) accountDOM.scrollIntoView({ behavior: 'instant', block: 'nearest' });
  }, [selectedAccountId]);

  return (
    <>
      {loading && <Loading fixed />}
      <ForgetPopup />
      <SelectCrmAccountsPopup fixed={true}>
        {isLoadingClarity && <Loading />}
        {!isLoadingClarity && errorClarity && (
          <>
            <Heading style={{ marginBottom: '2rem' }}>Wystąpił błąd</Heading>
            <p>{errorClarity.message || errorClarity}</p>
            <div className="buttons">
              <Button onClick={() => handleCrmAccountManualPopup()}>Wróć</Button>
            </div>
          </>
        )}
        {!isLoadingClarity && !errorClarity && !accounts && <Heading>Brak danych dla wskazanego NIPu</Heading>}
        {!isLoadingClarity && !errorClarity && accounts && (
          <>
            <Heading>Wybierz CRM Account</Heading>
            <PopupScroller>
              {accounts.length > 0 &&
                accounts.map((acc) => (
                  <CrmAccountDisplayMinimal
                    id={acc.id}
                    current={leadData.crmAccountId === acc.id}
                    selected={selectedAccountId === acc.id}
                    onClick={() => setSelectedAccountId(acc.id)}
                    leadData={leadData}
                    key={acc.id}
                    acc={acc}
                  />
                ))}
            </PopupScroller>
            <div className="buttons">
              <Button onClick={handleCrmAccountManual} disabled={!selectedAccountId}>
                Zaktualizuj
              </Button>
              <Button gray onClick={cancelCrmAccountManual}>
                Zamknij
              </Button>
            </div>
          </>
        )}
      </SelectCrmAccountsPopup>
      <TransferLeadPopup>
        <TransferLeadForm leadId={leadData.id} />
      </TransferLeadPopup>
      {reassigneMode && (
        <SuccessPopup>
          <Heading centered>Czy na pewno chcesz ponownie przypisać tego leada?</Heading>
          <div className="buttons">
            <Button onClick={handleReassigneLead}>Ponów przypisanie</Button>
            <Button gray onClick={() => setReassigneMode(false)}>
              Zamknij
            </Button>
          </div>
        </SuccessPopup>
      )}
      {success && (
        <SuccessPopup>
          <Heading centered>{success}</Heading>
          <div className="buttons">
            <Button onClick={handleReturn}>Powrót do leada</Button>
            <Button gray onClick={handleClose}>
              Zamknij
            </Button>
          </div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup>
          <Heading centered>Wystąpił błąd</Heading>
          <p>{error}</p>
          <div className="buttons">
            <Button onClick={handleReturn}>Powrót do leada</Button>
            <Button gray onClick={handleClose}>
              Zamknij
            </Button>
          </div>
        </ErrorPopup>
      )}
      {renewLeadSuccess && (
        <SuccessPopup>
          <Heading style={{ marginBottom: '2rem' }} centered>
            Lead został ponowiony
          </Heading>
          <p>
            Numer odnowionego leada to: <strong>{renewLeadSuccess.frontNumLead}</strong>
          </p>
          <div className="buttons">
            <Button secondary onClick={handleReturn}>
              Powrót do leada
            </Button>
            <Button onClick={() => history.push(`/lead/details/${renewLeadSuccess.id}`)}>Zobacz odnowiony</Button>
          </div>
        </SuccessPopup>
      )}
      <Wrapper>
        <Heading size="xl" onDoubleClick={handleDebugMode}>
          Lead {frontNumLead}
        </Heading>
        <Formik
          initialValues={{
            companyName,
            nip,
            contactPerson,
            phone,
            postCode,
            products: selectedProducts,
            potentialPOS,
            comment,
            // agreement: null,
            preferredContactDate,
            queue,
            status,
          }}
          validationSchema={updateLeadSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, initialValues }) => (
            <StyledForm>
              <Columns>
                <StyledFormColumn>
                  <FieldHeading big size="m">
                    Dane zgłoszonego leada
                  </FieldHeading>
                  <Input
                    name="companyName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!edit}
                    label="Nazwa Firmy"
                    value={values.companyName || ''}
                    error={touched.companyName && errors.companyName}
                  />
                  <ErrorMessage component={ErrorLabel} name="companyName" />
                  <Input
                    name="nip"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    label="Numer NIP"
                    value={values.nip || ''}
                    error={touched.nip && errors.nip}
                  />
                  <ErrorMessage component={ErrorLabel} name="nip" />
                  <Input
                    name="contactPerson"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!edit}
                    label="Osoba Kontaktowa"
                    value={values.contactPerson || ''}
                    error={touched.contactPerson && errors.contactPerson}
                  />
                  <ErrorMessage component={ErrorLabel} name="contactPerson" />
                  <Input
                    name="phone"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!edit}
                    label="Numer Telefonu"
                    value={values.phone || ''}
                    error={touched.phone && errors.phone}
                  />
                  <ErrorMessage component={ErrorLabel} name="phone" />
                  <Input
                    name="postCode"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!edit}
                    label="Kod Pocztowy"
                    value={values.postCode || ''}
                    error={touched.postCode && errors.postCode}
                  />
                  <ErrorMessage component={ErrorLabel} name="postCode" />
                  {['admin', 'dsp'].includes(userRole) && (
                    <>
                      <InputAuto
                        suggestions={phList}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        onlyFromList
                        pickProp="id"
                        name="queue"
                        type="text"
                        display={['name', 'surname', 'email']}
                        onBlur={handleBlur}
                        onChange={(e) => fetchPhs(e.target.value)}
                        label="Przedstawiciel handlowy"
                        value={values.queue || ''}
                        disabled={!edit}
                        error={touched.queue && errors.queue}
                      />
                      <ErrorMessage component={ErrorLabel} name="queue" />
                    </>
                  )}
                  <FieldHeading size="s">Potencjał docelowy (liczba terminali)</FieldHeading>
                  <Field name="potentialPOS" component={NumberInput} disabled={!edit} />
                  <ErrorMessage component={ErrorLabel} name="potentialPOS" />
                  {['admin', 'dsp'].includes(userRole) && (
                    <>
                      <Select
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Status leada"
                        disabled={!edit}
                      >
                        <option value="new">Nowy</option>
                        <option value="taken">Podjęty</option>
                        <option value="duplicate">Zduplikowany</option>
                        <option value="rejected_pep">Odrzucony - PeP</option>
                        <option value="rejected_client">Odrzucony - Klient</option>
                        <option value="rejected_ph">Odrzucony - PH</option>
                        <option value="success">Terminal zainstalowano</option>
                        <option value="import">Zaimportowany</option>
                        <option value="in_progress">W trakcie</option>
                        <option value="inactive">Nieaktywny</option>
                      </Select>
                      <ErrorMessage component={ErrorLabel} name="status" />
                    </>
                  )}
                  {/* {edit ? (
                    <>
                      <FieldHeading size="s">Zmień oświadczenie</FieldHeading>
                      <Field
                        name="agreement"
                        id="agreement"
                        disabled={!edit}
                        accept={
                          isMobile
                            ? 'image/png, image/jpg'
                            : 'image/png, image/jpeg, application/pdf'
                        }
                        component={FileInput}
                      />
                      <ErrorMessage component={ErrorLabel} name="agreement" />
                    </>
                  ) : (
                    <div style={{ marginTop: '20px' }}>
                      <a
                        target="blank"
                        style={{ color: 'black', textDecoration: 'underline' }}
                        href={`${BASE_URL}/http/lead/download-agreement/${id}`}
                      >
                        Pobierz oświadczenie
                      </a>
                    </div>
                  )}
                  <Preview url={`${BASE_URL}/http/lead/download-agreement/${id}`} /> */}
                </StyledFormColumn>
                <StyledFormColumn>
                  <FieldHeading big size="m">
                    Wybierz produkt z oferty PeP, którym zainteresowany jest Twój kontakt
                  </FieldHeading>
                  <FieldArray
                    name="products"
                    render={(arrayHelpers) => (
                      <div>
                        {displayProducts.map((item) => {
                          const isNewString =
                            item.isNew === '1' ? <strong className="new-product"> nowość</strong> : null;

                          let label = [item.name, isNewString];

                          const isAclas = isAclasProduct(item.id);
                          if (
                            isAclas &&
                            isAclasTouched &&
                            values.products &&
                            values.products.includes(item.id) &&
                            ['admin', 'dsp'].includes(userRole)
                          )
                            label = [
                              item.name,
                              <span style={{ color: edit ? colors.red : 'inherit' }} key="aclas">
                                &nbsp; - Dane zostaną wysłane do firmy Aclas
                              </span>,
                            ];

                          return (
                            <Checkbox
                              key={item.id}
                              name="products"
                              disabled={!edit}
                              onChange={(e) => {
                                if (isAclasProduct(item.id)) setIsAclasTouched(true);
                                if (e.target.checked) {
                                  arrayHelpers.push(item.id);
                                } else {
                                  const idx = values.products.indexOf(item.id);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                              onBlur={handleBlur}
                              label={label}
                              value={item.id}
                              checked={values.products ? values.products.includes(item.id) : false}
                            />
                          );
                        })}
                      </div>
                    )}
                  />
                  <ErrorMessage component={ErrorLabel} name="products" />
                  <FieldHeading size="s">Data preferowanego kontaktu</FieldHeading>
                  <DateTimePicker
                    handleDate={(date) => setFieldValue('preferredContactDate', date)}
                    initialDate={preferredContactDate || null}
                    isMinDate={false}
                    disabled={!edit && !editContactDate}
                  />
                  <FieldHeading size="s">Komentarz od Partnera</FieldHeading>
                  <Textarea
                    name="comment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!edit}
                    width="100%"
                    locked
                    height="150px"
                    value={values.comment || ''}
                  />
                  <ErrorMessage component={ErrorLabel} name="comment" />
                </StyledFormColumn>
              </Columns>
              <Columns style={{ marginTop: '2rem', gap: '2rem 0' }}>
                {!['guest'].includes(userRole) && (
                  <StyledFormColumnBtns>
                    <div>
                      {rejectable && (
                        <Button gray type="button" onClick={rejectLead} disabled={loading}>
                          Odrzuć
                        </Button>
                      )}
                    </div>
                    <div>
                      {isRejected && (
                        <Button gray type="button" onClick={takeLead} disabled={loading}>
                          Podejmij ponownie
                        </Button>
                      )}
                      {['admin'].includes(userRole) && (
                        <Button gray type="button" onClick={popupTrigger} disabled={loading}>
                          Zapomnij leada
                        </Button>
                      )}
                      {['admin'].includes(userRole) && (
                        <Button gray type="button" onClick={toggleTransferPopup} disabled={loading}>
                          Transferuj
                        </Button>
                      )}
                    </div>
                    <div>
                      {/* {(['admin'].includes(userRole) || (['dsp'].includes(userRole) && !leadData.contract)) && <span>Aktualizuj CRM Account</span>} */}
                      {['admin'].includes(userRole) && (
                        <Button
                          gray
                          type="button"
                          onClick={removeCrmAccountBinding}
                          disabled={!leadData.crmAccount || Boolean(leadData.contract) || loading}
                        >
                          Odepnij CRM Account
                        </Button>
                      )}
                      {['admin', 'dsp'].includes(userRole) && (
                        <Button gray type="button" onClick={handleCrmAccountManualPopup} disabled={loading}>
                          Aktualizuj CRM Account
                        </Button>
                      )}
                    </div>
                  </StyledFormColumnBtns>
                )}
                <StyledFormColumnBtns>
                  {!['guest'].includes(userRole) && (
                    <div>
                      {canEditContactDate && !editable && !isRejected && (
                        <Button
                          type="button"
                          onClick={() => setEditContactDate(!editContactDate)}
                          gray
                          disabled={loading}
                        >
                          {editContactDate ? 'Anuluj' : 'Data kolejnego kontaktu'}
                        </Button>
                      )}
                      {editable && (
                        <Button type="button" onClick={() => setEdit(!edit)} gray disabled={loading}>
                          {edit ? 'Anuluj' : 'Edycja leada'}
                        </Button>
                      )}
                      {(edit || editContactDate) && (
                        <Button type="submit" secondary disabled={loading}>
                          Zapisz zmiany
                        </Button>
                      )}
                      {takePossible && (
                        <Button type="button" onClick={takeLead} disabled={loading}>
                          Podejmij leada
                        </Button>
                      )}
                    </div>
                  )}
                  <div>
                    {['admin', 'dsp', 'merchantPep', 'guest'].includes(userRole) && (
                      <Button type="button" onClick={visitClientCard}>
                        Karta klienta
                      </Button>
                    )}
                    {!['guest'].includes(userRole) && (
                      <Button type="button" onClick={handleConversation}>
                        Konwersacja
                      </Button>
                    )}
                  </div>
                  {!['guest'].includes(userRole) && (
                    <div>
                      {((['admin', 'dsp'].includes(userRole) && leadData.contract) ||
                        (['partner'].includes(userRole) &&
                          leadData.userId === userId &&
                          leadData.contract &&
                          leadData.contract.id)) && (
                        <Button
                          type="button"
                          green={leadData.contract.status === 'SUCCESS'}
                          gray={leadData.contract.status !== 'SUCCESS'}
                          disabled={loading}
                          onClick={() =>
                            history.push(routes.leadOnboarding.replace(':id', leadData.contract.id), {
                              lead: leadData,
                            })
                          }
                        >
                          {formButtonText(userRole, leadData.contract.status)}
                        </Button>
                      )}
                      {['admin', 'dsp'].includes(userRole) && initialValues.status === 'new' && (
                        <Button
                          type="button"
                          title="Funkcja tymczasowo niedostępna"
                          disabled
                          onClick={() => setReassigneMode(true)}
                        >
                          Ponów przypisanie
                        </Button>
                      )}
                      {['admin', 'dsp', 'dr', 'merchantPep'].includes(userRole) && leadData.takenUser && (
                        <>
                          {userRole === 'merchantPep' && leadData.takenUser !== userId ? null : (
                            <Button
                              disabled={
                                leadData.crmAccount || !leadData.queue || leadData.status === 'in_progress' || loading
                              }
                              type="button"
                              title={getRegisterCrmTitle(leadData)}
                              onClick={createAccountCrm}
                              green={leadData.crmAccount}
                              gray={!leadData.crmAccount}
                            >
                              {leadData.crmAccount ? 'Zarejestrowany w CRM' : 'Zarejestruj w CRM'}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {['admin', 'dsp'].includes(userRole) && canRenewLead() && (
                    <div>
                      <Button type="button" onClick={handleRenewLead}>
                        Ponów leada
                      </Button>
                    </div>
                  )}
                </StyledFormColumnBtns>
              </Columns>
            </StyledForm>
          )}
        </Formik>
        {debugMode && (
          <div style={{ marginBottom: '4rem' }}>
            <Heading style={{ marginBottom: '2rem' }} size="xl">
              Informacje dodatkowe:
            </Heading>
            <InfoWrapper>
              <div>
                <Label>Formidable - Nazwa:</Label>
                <Text>{leadData.formidableFormName || '-'}</Text>
              </div>
              <div>
                <Label>Formidable - ID:</Label>
                <Text>{leadData.formidableId || '-'}</Text>
              </div>
              <div>
                <Label>Formidable - Refferer:</Label>
                <Text>{leadData.formidableRefferer || '-'}</Text>
              </div>
            </InfoWrapper>
          </div>
        )}
      </Wrapper>
    </>
  );
};

EditLead.propTypes = {
  products: PropTypes.instanceOf(Array).isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getLead: PropTypes.func.isRequired,
  updateCurrentLead: PropTypes.func.isRequired,
  leadData: PropTypes.instanceOf(Object).isRequired,
  userRole: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products.data,
  userRole: state.user.data.role.slug,
  userId: state.user.data.id,
});

const mapDispatchToProps = {
  getAllProducts,
  updateCurrentLead,
  getLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLead);

// styled components

const Wrapper = styled(FormWrapperCommon)``;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const StyledFormColumn = styled(FormColumn)`
  max-width: 500px;
`;

const StyledFormColumnBtns = styled(FormColumn)`
  max-width: 500px;

  & {
    order: 2;
  }

  & + & {
    order: 1;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0 2rem;

    button {
      flex: 0 1 calc(50% - 1rem);
      max-width: 20rem;
      min-width: 15rem;
      margin-top: 2rem;
    }
  }

  @media (min-width: 769px) {
    & {
      order: 1;
    }

    & + & {
      order: 2;
    }

    & + & > div {
      justify-content: flex-end;
    }
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 2rem;

  & > * {
    flex-basis: 100%;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;
