import * as types from './types';

const initialState = {
  data: {},
  isLoading: false,
  error: '',
};

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PUBLIC_OPTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_PUBLIC_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {...action.payload.data},
        error: '',
      };
    case types.GET_PUBLIC_OPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.err.response.data.error,
      };
    case types.CLEAR_PUBLIC_OPTIONS:
      return initialState;
    default:
      return state;
  }
};

export default optionsReducer;
