import makeRequest from 'services/makeRequest';

const GET_LEAD_STATISTICS_URL = '/dashboard/statistics';
const GET_SETTLEMENT_STATISTICS_URL = '/dashboard/settlement';

const getLeadsStatistics = (dateFrom, dateTo, products, structure) => {
  return makeRequest({
    method: 'post',
    url: `${GET_LEAD_STATISTICS_URL}`,
    data: {
      dateFrom,
      dateTo,
      products,
      structure
    },
  });
};

const getSettlementStatistics = (dateFrom, dateTo, patronId) => {

  return makeRequest({
    method: 'post',
    url: `${GET_SETTLEMENT_STATISTICS_URL}`,
    data: {
      dateFrom,
      dateTo,
      patronId,
    },
  });
};

export const statisticService = {
  getLeadsStatistics,
  getSettlementStatistics,
};
