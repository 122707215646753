/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { routes } from 'routes';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { colors, font } from 'theme/styles';
import { getClientPartnerInfo, getClientPartnerHistory, getClientPartnerNotes } from 'redux/clientPartner';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import ErrorsWrapper from 'components/UI/ErrorsWrapper';
import ClientNotes from 'components/layout/Client/ClientNotes';
import ClientPartnerInfo from 'components/layout/ClientPartner/ClientPartnerInfo';
import ClientPartnerHistory from 'components/layout/ClientPartner/ClientPartnerHistory';
import { clientPartnerService } from 'services/clientPartner';
import { partnerService } from 'services/partner';
// import ClientDocs from 'components/layout/Client/ClientDocs';

const ClientPartnerPage = ({
  match,
  location,
  loggedUser,
  isUserFromPep,
  getClientPartnerInfo,
  getClientPartnerHistory,
  getClientPartnerNotes,
  clientInfo,
  clientHistory,
  clientNotes,
  isLoadingClientInfo,
  isLoadingClientHistory,
  isLoadingClientNotes,
  errorClientInfo,
  errorClientHistory,
  errorClientNotes,
}) => {
  const userRole = useSelector(state => state.user.data.role.slug);
  
  const [posInfo, setPosInfo] = useState(false);

  const addNote = values => {
    clientPartnerService
      .addClientPartnerNote(match.params.id, values.text)
      .then(() => {
        getClientPartnerNotes(match.params.id);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getClientPartnerInfo(match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    if (!['admin', 'dsp'].includes(loggedUser.role.slug) || !clientInfo?.nip) return;
    partnerService
      .getPosInfo(clientInfo.nip)
      .then(response => {
        setPosInfo(response.data);
      })
      .catch(err => console.log(err));
  }, [clientInfo]);

  useEffect(() => {
    const subPage = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
    switch (subPage) {
      case 'history':
        getClientPartnerHistory(match.params.id);
        break;
      case 'notes':
        getClientPartnerNotes(match.params.id);
        break;
      case 'docs':
      default:
        break;
    }
  }, [location.pathname]);

  const infoMarkup = () => {
    if (!clientInfo) return <ErrorsWrapper>Brak danych</ErrorsWrapper>;
    if (isLoadingClientInfo) return <Loading />;
    if (errorClientInfo) return <ErrorsWrapper>{errorClientInfo.message}</ErrorsWrapper>;
    return <ClientPartnerInfo data={clientInfo} posInfo={posInfo} />;
  };

  const historyMarkup = () => {
    if (isLoadingClientHistory) return <Loading />;
    if (errorClientHistory) return <ErrorsWrapper>{errorClientHistory.message}</ErrorsWrapper>;
    return <ClientPartnerHistory historyData={clientHistory} />;
  };

  const notesMarkup = () => {
    if (isLoadingClientNotes) return <Loading />;
    if (errorClientNotes) return <ErrorsWrapper>{errorClientNotes.message}</ErrorsWrapper>;
    return (
      <ClientNotes
        match={match}
        notes={clientNotes}
        allowAddNote={isUserFromPep && !['guest'].includes(userRole)}
        handleSubmit={addNote}
        fetchNotes={() => getClientPartnerNotes(match.params.id)}
      />
    );
  };

  return (
    <PageTemplate>
      <Wrapper>
        <ColLeft>
          <Block>{infoMarkup()}</Block>
        </ColLeft>
        <ColRight>
          <InnerHeader>
            <InnerNvButton to="history">Historia</InnerNvButton>
            <InnerNvButton to="notes">Notatki</InnerNvButton>
          </InnerHeader>
          <Block>
            <Switch>
              <Route exact path={routes.clientPartnerHistory} render={historyMarkup} />
              <Route path={routes.clientPartnerNotes} render={notesMarkup} />
              <Redirect to={routes.clientPartnerHistory} />
            </Switch>
          </Block>
        </ColRight>
      </Wrapper>
    </PageTemplate>
  );
};

ClientPartnerPage.defaultProps = {};

ClientPartnerPage.propTypes = {
  isUserFromPep: PropTypes.bool.isRequired,
  loggedUser: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  getClientPartnerInfo: PropTypes.func.isRequired,
  getClientPartnerHistory: PropTypes.func.isRequired,
  getClientPartnerNotes: PropTypes.func.isRequired,
  clientInfo: PropTypes.instanceOf(Object).isRequired,
  clientHistory: PropTypes.instanceOf(Object).isRequired,
  clientNotes: PropTypes.instanceOf(Object).isRequired,
  isLoadingClientInfo: PropTypes.bool.isRequired,
  isLoadingClientHistory: PropTypes.bool.isRequired,
  isLoadingClientNotes: PropTypes.bool.isRequired,
  errorClientInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  errorClientHistory: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  errorClientNotes: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
};

const mapStateToProps = state => ({
  isUserFromPep: Boolean(state.user.data.role.pep_structure),
  loggedUser: state.user.data,
  clientInfo: state.clientPartner.info.data,
  clientHistory: state.clientPartner.history.data,
  clientNotes: state.clientPartner.notes.data,
  isLoadingClientInfo: state.clientPartner.info.isLoading,
  isLoadingClientHistory: state.clientPartner.history.isLoading,
  isLoadingClientNotes: state.clientPartner.notes.isLoading,
  errorClientInfo: state.clientPartner.info.error,
  errorClientHistory: state.clientPartner.history.error,
  errorClientNotes: state.clientPartner.notes.error,
});
const mapDispatchToProps = {
  getClientPartnerInfo,
  getClientPartnerHistory,
  getClientPartnerNotes,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientPartnerPage);

// styled components

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 940px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ColLeft = styled.section`
  margin-bottom: 3rem;

  @media (min-width: 940px) {
    margin-bottom: 0;
    flex: 0 1 calc(33.3333% - 15px);
    max-width: calc(33.3333% - 15px);
  }
`;

const ColRight = styled.section`
  @media (min-width: 940px) {
    flex: 0 1 calc(66.6666% - 15px);
    max-width: calc(66.6666% - 15px);
  }
`;

const Block = styled.div`
  width: 100%;
  box-shadow: 0px 3px 15px #00000010;
  /* padding: 30px 20px 30px 30px; */
  padding: 30px;
  border-radius: 5px;
  background: ${colors.white};
`;

const InnerHeader = styled.div`
  position: relative;
  background-color: ${colors.lighterGray};
  padding: 10px 20px;
`;

const InnerNvButton = styled(NavLink)`
  color: ${colors.darkGray};
  font-weight: ${font.weight.semibold};
  letter-spacing: 0.5px;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  font-size: ${font.size.m};
  text-decoration: none;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 0;
    background-color: ${colors.orange};
    bottom: -10px;
    left: 10px;
    transition: width 0.15s ease-in;
  }

  &.active,
  &:hover {
    color: ${colors.orange};

    &::before {
      width: calc(100% - 20px);
    }
  }
`;
