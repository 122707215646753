import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { routes } from 'routes';
import { getNotifications } from 'redux/admin';
import { TopSection } from 'components/layout/Tables/TableComponents';
import { Block } from 'components/UI/Structure';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import NotificationsTable from 'components/layout/Tables/NotificationsTable';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';

const countiesHeaders = [{ displayName: 'Nazwa powiatu', property: 'name' }];
const usersHeaders = [
  { displayName: 'Imię i nazwisko', property: 'fullName' },
  { displayName: 'Email', property: 'email' },
  { displayName: 'Partner', property: 'company.name' },
  { displayName: 'Leadmaster', property: 'dedicatedLeadMaster.fullName' },
  { displayName: 'Nieaktywny przedstawiciel', property: 'ph.0.email' },
];

const companiesHeaders = [
  { displayName: 'Nazwa partnera', property: 'name' },
  { displayName: 'Leadmaster', property: 'leadMasterAssoc.fullName' },
  { displayName: 'Nieaktywny przedstawiciel', property: 'ph.0.email' },
];

const companiesWithMultiplePartnerProgramsHeaders = [
  { displayName: 'Nazwa partnera', property: 'name' },
  { displayName: 'Programy partnerskie', property: 'partnerProgram' },
  { displayName: 'Liczba kanalów partnerskich', property: 'partnerProgramLength' },
];

const subPartnerListWithInactivePatronHeaders = [
  { displayName: 'Imię i nazwisko', property: 'fullName' },
  { displayName: 'Nieaktywny patron (Partner)', property: 'patron.fullName' },
];

const countyHaveNonPHDedicatedRoleHeaders = [
  { displayName: 'Powiat', property: 'name' },
  { displayName: 'Niepoprawny przedstawiciel', property: 'user.fullName' },
  { displayName: 'Niepoprawna rola', property: 'user.role.name' },
];

const companyHaveNonPHDedicatedRoleHeaders = [
  { displayName: 'Nazwa partnera', property: 'name' },
  { displayName: 'Niepoprawny przedstawiciel', property: 'dedicatedPhAssoc.fullName' },
  { displayName: 'Niepoprawna rola', property: 'dedicatedPhAssoc.role.name' },
];

const userHaveNonPHDedicatedRoleHeaders = [
  { displayName: 'Imię i nazwisko', property: 'fullName' },
  { displayName: 'Niepoprawny przedstawiciel', property: 'dedicatedUser.fullName' },
  { displayName: 'Niepoprawna rola', property: 'dedicatedUser.role.name' },

];

const AdminNotifications = ({
  notUsedCounties,
  usersWithoutPhs,
  companiesWithoutPhs,
  subPartnerListWithInactivePatron,
  companiesWithMultiplePartnerPrograms,
  countyHaveNonPHDedicatedRole,
  companyHaveNonPHDedicatedRole,
  userHaveNonPHDedicatedRole,
  getNotifications,
  error,
  isLoading,
  history,
}) => {

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const countErrors = [
    notUsedCounties,
    usersWithoutPhs,
    companiesWithoutPhs,
    subPartnerListWithInactivePatron,
    companiesWithMultiplePartnerPrograms,
    countyHaveNonPHDedicatedRole,
    companyHaveNonPHDedicatedRole,
    userHaveNonPHDedicatedRole,
  ].map(el => el.length > 0);

  const editUser = user => history.push(routes.adminUsers, { showUser: user });
  const editCompany = company => history.push(routes.adminCompanies, { showCompany: company });

  const reducedCountyHaveNonPHDedicatedRole = countyHaveNonPHDedicatedRole.reduce((arr, item) => {
    return [...arr, ...item.counties.map(c => ({ ...c, user: item }))];
  }, []);

  return (
    <PageTemplate>
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => history.goBack()}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      <TopSection style={{ marginBottom: '60px' }}>
        <div>
          <Heading size="xxl">
            Informacje o błędach -{' '}
            <span className="color-red">
              {countErrors.filter(Boolean).length} / {countErrors.length}
            </span>
          </Heading>
        </div>
      </TopSection>
      {isLoading && <Loading />}
      {!isLoading && !error && (
        <ListWrapper>

          {notUsedCounties && (
            <Block>
              {notUsedCounties.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak niepokrytych powiatów
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto niepokryte powiaty - <strong className="color-red">{notUsedCounties.length}</strong>
                  </Heading>
                  <NotificationsTable headers={countiesHeaders} data={notUsedCounties} />
                </>
              )}
            </Block>
          )}

          {usersWithoutPhs && (
            <Block>
              {usersWithoutPhs.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak użytkowników z przypisanym nieaktywnym przedstawicielem
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto użytkowników z przypisanym nieaktywnym przedstawicielem -{' '}
                    <strong className="color-red">{usersWithoutPhs.length}</strong>
                  </Heading>
                  <NotificationsTable
                    headers={usersHeaders}
                    data={usersWithoutPhs}
                    actions={[{ title: 'Edytuj', icon: 'edit', handler: editUser }]}
                  />
                </>
              )}
            </Block>
          )}

          {companiesWithoutPhs && (
            <Block>
              {companiesWithoutPhs.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak partnerów z przypisanym nieaktywnym przedstawicielem
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto partnerów z przypisanym nieaktywnym przedstawicielem -{' '}
                    <strong className="color-red">{companiesWithoutPhs.length}</strong>
                  </Heading>
                  <NotificationsTable
                    headers={companiesHeaders}
                    data={companiesWithoutPhs}
                    actions={[{ title: 'Edytuj', icon: 'edit', handler: editCompany }]}
                  />
                </>
              )}
            </Block>
          )}

          {subPartnerListWithInactivePatron && (
            <Block>
              {subPartnerListWithInactivePatron.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak użytkowników o roli sub-partner z nieaktywnym użytkownikiem o roli Partner
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto użytkowników o roli sub-partner z przypisanym nieaktywnym użytkownikiem o roli Partner -{' '}
                    <strong className="color-red">{subPartnerListWithInactivePatron.length}</strong>
                  </Heading>
                  <NotificationsTable
                    headers={subPartnerListWithInactivePatronHeaders}
                    data={subPartnerListWithInactivePatron}
                    actions={[{ title: 'Edytuj', icon: 'edit', handler: editUser }]}
                  />
                </>
              )}
            </Block>
          )}

          {companiesWithMultiplePartnerPrograms && (
            <Block>
              {companiesWithMultiplePartnerPrograms.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak partnerów z przypisanym więcej niż jednym kanałem partnerskim
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto partnerów z przypisanymi kilkoma kanałami partnerskimi -{' '}
                    <strong className="color-red">{companiesWithMultiplePartnerPrograms.length}</strong>
                  </Heading>
                  <NotificationsTable
                    headers={companiesWithMultiplePartnerProgramsHeaders}
                    data={companiesWithMultiplePartnerPrograms}
                    actions={[{ title: 'Edytuj', icon: 'edit', handler: editCompany }]}
                  />
                </>
              )}
            </Block>
          )}

          {countyHaveNonPHDedicatedRole && (
            <Block>
              {countyHaveNonPHDedicatedRole.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak powiatów z przypisanym użytkownikiem o roli innej niż Przedstawiciel Handlowy
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto powiaty z przypisanymi użytkownikami o roli innej niż Przedstawiciel Handlowy -{' '}
                    <strong className="color-red">{reducedCountyHaveNonPHDedicatedRole.length}</strong>
                  </Heading>
                  <NotificationsTable
                    headers={countyHaveNonPHDedicatedRoleHeaders}
                    data={reducedCountyHaveNonPHDedicatedRole}
                    actions={[{
                      title: 'Edytuj', icon: 'edit', handler: (item) => {
                        history.push(routes.adminUsers, { showUser: item.user })
                      }
                    }]}
                  />
                </>
              )}
            </Block>
          )}

          {companyHaveNonPHDedicatedRole && (
            <Block>
              {companyHaveNonPHDedicatedRole.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak partnerów z przypisanym przedstawicielem o roli innej niż Przedstawiciel Handlowy
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto partnerów z przypisanym przedstawicielem o roli innej niż Przedstawiciel Handlowy -{' '}
                    <strong className="color-red">{companyHaveNonPHDedicatedRole.length}</strong>
                  </Heading>
                  <NotificationsTable
                    headers={companyHaveNonPHDedicatedRoleHeaders}
                    data={companyHaveNonPHDedicatedRole}
                    actions={[{ title: 'Edytuj', icon: 'edit', handler: editCompany }]}
                  />
                </>
              )}
            </Block>
          )}

          {userHaveNonPHDedicatedRole && (
            <Block>
              {userHaveNonPHDedicatedRole.length === 0 ? (
                <Heading size="l">
                  <span className="color-green">
                    <FontAwesomeIcon style={{ marginRight: '1em' }} title="Aktywny" icon="check" />
                    Brak powiatów z przypisanym użytkownikiem o roli inne niż Przedstawiciel Handlowy
                  </span>
                </Heading>
              ) : (
                <>
                  <Heading size="l">
                    Wykryto powiaty z przypisanymi użytkownikami o roli innej niż Przedstawiciel Handlowy -{' '}
                    <strong className="color-red">{userHaveNonPHDedicatedRole.length}</strong>
                  </Heading>
                  <NotificationsTable
                    headers={userHaveNonPHDedicatedRoleHeaders}
                    data={userHaveNonPHDedicatedRole}
                    actions={[{ title: 'Edytuj', icon: 'edit', handler: editCompany }]}
                  />
                </>
              )}
            </Block>
          )}


        </ListWrapper>
      )}
    </PageTemplate>
  );
};

AdminNotifications.defaultProps = {
  error: null,
};

AdminNotifications.propTypes = {
  notUsedCounties: PropTypes.instanceOf(Array).isRequired,
  usersWithoutPhs: PropTypes.instanceOf(Array).isRequired,
  companiesWithoutPhs: PropTypes.instanceOf(Array).isRequired,
  subPartnerListWithInactivePatron: PropTypes.instanceOf(Array).isRequired,
  companiesWithMultiplePartnerPrograms: PropTypes.instanceOf(Array).isRequired,
  countyHaveNonPHDedicatedRole: PropTypes.instanceOf(Array).isRequired,
  companyHaveNonPHDedicatedRole: PropTypes.instanceOf(Array).isRequired,
  userHaveNonPHDedicatedRole: PropTypes.instanceOf(Array).isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  // Utils
  history: PropTypes.instanceOf(Object).isRequired,
  // Actions
  getNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notUsedCounties: state.admin.notifications.data.notUsedCounties,
  usersWithoutPhs: state.admin.notifications.data.usersWithoutPhs,
  companiesWithoutPhs: state.admin.notifications.data.companiesWithoutPhs,
  subPartnerListWithInactivePatron: state.admin.notifications.data.subPartnerListWithInactivePatron,
  companiesWithMultiplePartnerPrograms: state.admin.notifications.data.companiesWithMultiplePartnerPrograms,
  countyHaveNonPHDedicatedRole: state.admin.notifications.data.countyHaveNonPHDedicatedRole,
  companyHaveNonPHDedicatedRole: state.admin.notifications.data.companyHaveNonPHDedicatedRole,
  userHaveNonPHDedicatedRole: state.admin.notifications.data.userHaveNonPHDedicatedRole,
  isLoading: state.admin.notifications.isLoading,
  error: state.admin.notifications.error,
});

const mapDispatchToProps = {
  getNotifications,
};

const ListWrapper = styled.div`
  margin-bottom: 4rem;

  & > * + * {
    margin-top: 4rem;
  }
`;
export default connect(mapStateToProps, mapDispatchToProps)(AdminNotifications);
