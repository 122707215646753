import { createGlobalStyle } from 'styled-components';
import { colors, font } from './styles';
import 'antd/es/grid/style';

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
    box-sizing: border-box;
    font-family: "Source Sans Pro", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    
    /* font-size: 56.25%; */
    /* @media (min-width: 767px) {
      font-size: 62.5%;
    } */
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-family: "Source Sans Pro", sans-serif;
    overflow-x: hidden;
  }

  *::-webkit-scrollbar, textarea::-webkit-scrollbar, iframe::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track, iframe::-webkit-scrollbar {
    background-color: #D8DFE8;
    border-radius: 4px;
  }

  *::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb, iframe::-webkit-scrollbar {
    background-color: #F18819;
    border-radius: 4px;
  }

  /* p {
    font-size: 1.5rem;
  } */

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

  .capitalize {
    text-transform: capitalize;
  }
  
  .uppercase {
    text-transform: uppercase;
  }

  .color-green {
    color: ${colors.greenDark};
  }

  .color-red {
    color: ${colors.red};
  }

  .color-orange {
    color: ${colors.orange};
  }

  .semi {
    font-weight: ${font.weight.semibold};
  }

  .bold {
    font-weight: ${font.weight.bold};
  }
  
  table {

    @media (max-width: 767px) {
      max-height: 50vh;
      min-width: unset;
      td {
        padding: 4px !important;
      }
    }

    @media (min-width: 940px) {
      col.lp,
      col.narrow {
        width: 40px;
      }

      col.type {
        width: 60px;
      }

      col.frontNumLead,
      col.createdAt,
      col.phone,
      col.nip,
      col.regon,
      col.active,
      col.download,
      col.actions {
        width: 100px;
      }
      
      col.startDate,
      col.endDate,
      col.wide,
      col.status,
      col.isActive,
      col.createdAt-wide,
      col.date {
        width: 140px;
      }

      col.wider  {
        width: 150px
      }

      col.oneTimeFee,
      col.cyclicFee,
      col.roleName,
      col.ospObj,
      col.status-wider { 
        width: 200px;
      }
    }
  }
`;

export default GlobalStyle;
