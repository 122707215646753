/* eslint-disable no-param-reassign */
import { invoicingService } from 'services/invoicing';
import { sortBillingsDetails, sortInvoices } from 'utils/helpers';
import * as types from './types';

export const getAllInvoices = query => dispatch => {
  dispatch({ type: types.GET_ALL_INVOICES_REQUEST });
  return invoicingService
    .getInvoices(query)
    .then(payload => {
      payload.data.sort(sortInvoices);
      dispatch({ type: types.GET_ALL_INVOICES_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_ALL_INVOICES_FAILURE, err });
    });
};

export const getAllBillings = query => dispatch => {
  dispatch({ type: types.GET_BILLINGS_REQUEST });
  return invoicingService
    .getBillings(query)
    .then(payload => {
      dispatch({ type: types.GET_BILLINGS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_BILLINGS_FAILURE, err });
    });
};

export const getBillingDetails = id => dispatch => {
  dispatch({ type: types.GET_BILLING_REQUEST });
  return invoicingService
    .getBillingDetails(id)
    .then(payload => {
      payload.data.data.sort(sortBillingsDetails);
      dispatch({ type: types.GET_BILLING_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_BILLING_FAILURE, err });
    });
};

export const getInvoice = id => dispatch => {
  dispatch({ type: types.GET_INVOICE_REQUEST });
  return invoicingService
    .getInvoice(id)
    .then(payload => {
      dispatch({ type: types.GET_INVOICE_SUCCESS, payload: payload.data });
    })
    .catch(err => {
      dispatch({ type: types.GET_INVOICE_FAILURE, err });
    });
};

export const getInvoiceTemplate = id => dispatch => {
  dispatch({ type: types.GET_INVOICE_TEMPLATE_REQUEST });
  return invoicingService
    .getInvoiceTemplate(id)
    .then(payload => {
      dispatch({ type: types.GET_INVOICE_TEMPLATE_SUCCESS, payload: payload.data });
    })
    .catch(err => {
      dispatch({ type: types.GET_INVOICE_TEMPLATE_FAILURE, err });
    });
};

export const setCurrentInvoice = invoice => dispatch => {
  dispatch({ type: types.SET_CURRENT_INVOICE, payload: invoice });
};

export const clearCurrentInvoice = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_INVOICE });
};

export const setCurrentBilling = billing => dispatch => {
  dispatch({ type: types.SET_CURRENT_BILLING, payload: billing });
};

export const clearCurrentBilling = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_BILLING });
};

export const setCurrentInvoiceTemplate = invoiceTemplate => dispatch => {
  dispatch({ type: types.SET_CURRENT_INVOICE_TEMPLATE, payload: invoiceTemplate });
};

export const clearCurrentInvoiceTemplate = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_INVOICE_TEMPLATE });
};

export const clearInvocing = () => dispatch => {
  dispatch({ type: types.CLEAR_INVOICING });
};
