import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { adminUserSchema } from 'utils/validation';
import { adminService } from 'services/admin';
import { removeNulls, validateValuesByRole } from 'utils/helpers';
import { suggestionsService } from 'services/suggestions';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Select from 'components/UI/Select';
import Loading from 'components/UI/Loading';
import InputAuto from 'components/UI/InputAuto';
import Chip from 'components/UI/Chip';
import CountiesList from 'components/layout/CountiesList';
import InputWithIcon from 'components/layout/Forms/InputWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import Checkbox from 'components/UI/Checkbox';
// import Tip from 'components/UI/Tip';

const AddUser = ({
  loggedUser,
  structures,
  roles,
  countiesSuggestions,
  pepStructureSuggestions,
  refetchUsers,
  refetchPepStructures,
  initialValues,
}) => {
  const [countieBoxVisible, setCountieBoxVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [companiesSug, setCompaniesSug] = useState([]);
  const [error, setError] = useState('');
  const [phsSugg, setPhsSugg] = useState([]);
  const [usersSugg, setUsersSugg] = useState([]);
  const toggleCountieBoxVisibility = () => setCountieBoxVisible(state => !state);

  const fetchCompanies = inputValue => {
    if (inputValue.length <= 2) return;
    adminService
      .getAllCompanies({
        limit: 10,
        page: 1,
        showDeleted: false,
        search: inputValue,
      })
      .then(payload => setCompaniesSug(payload.data.rows))
      .catch(err => {
        console.log(err);
      });
  };

  const fetchUsers = (inputValue, role, setList) => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue, role, 20)
      .then(payload => setList(payload.data))
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    const excludeForDsp = ['admin', 'dsp', 'skf'];
    switch (loggedUser.role.slug) {
      case 'dsp':
        setFilteredRoles(roles.filter(role => !excludeForDsp.includes(role.slug)));
        break;
      default:
        setFilteredRoles(roles);
    }
  }, [roles, loggedUser.role.slug]);

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleSubmit = values => {
    const selectedRole = roles.find(r => r.id === values.roleId);
    let newValues = removeNulls(values);
    
    newValues = validateValuesByRole(values, selectedRole);

    setError('');
    setLoading(true);
    adminService
      .addUser(newValues)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchUsers();
        refetchPepStructures();
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Dodaj użytkownika</Heading>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
          surname: '',
          structureId: '',
          roleId: '',
          roleSlug: '',
          phone: '',
          email: '',
          patron: '',
          counties: [],
          countieSearch: '',
          companyId: '',
          leadMaster: '',
          // ph: '',
          dedicatedPhSearch: '',
          dedicatedPhs: [],
          mainPartner: '0',
          coordinator: '0',
          ...initialValues,
        }}
        validationSchema={adminUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          initialValues,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>Dodałeś użytkownika</Heading>
                <div className="buttons">
                  <Button onClick={() => handleReturn(true, resetForm)}>Wróć</Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Błąd dodawania użytkownika</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                Wprowadź dane:
              </FieldHeading>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Imię"
                value={values.name || ''}
                error={touched.name && errors.name}
              />
              <ErrorMessage component={ErrorLabel} name="name" />
              <Input
                name="surname"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nazwisko"
                value={values.surname || ''}
                error={touched.surname && errors.surname}
              />
              <ErrorMessage component={ErrorLabel} name="surname" />
              <Input
                name="phone"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Numer Telefonu"
                value={values.phone || ''}
                error={touched.phone && errors.phone}
              />
              <ErrorMessage component={ErrorLabel} name="phone" />
              <Input
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Adres email"
                value={values.email || ''}
                error={touched.email && errors.email}
              />
              <ErrorMessage component={ErrorLabel} name="email" />
              <InputAuto
                suggestions={usersSugg}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onlyFromList
                pickProp="email"
                name="patron"
                type="text"
                display={['name', 'surname', 'email']}
                onBlur={handleBlur}
                onChange={e => fetchUsers(e.target.value, null, setUsersSugg)}
                label="Opiekun"
                value={values.patron || ''}
                error={touched.patron && errors.patron}
              />
              <ErrorMessage component={ErrorLabel} name="patron" />
              <Select
                name="roleId"
                value={values.roleId || ''}
                onChange={e => {
                  setFieldValue('roleSlug', e.target[e.target.selectedIndex].getAttribute('data-slug'));
                  setFieldValue('companyId', initialValues.companyId);
                  setFieldValue('leadMaster', initialValues.leadMaster);
                  // setFieldValue('dedicatedPhs', initialValues.dedicatedPhs);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                label="Rola użytkownika"
              >
                <option key="default" value="">
                  &nbsp;
                </option>
                {filteredRoles.map(role => (
                  <option key={role.id} value={role.id} data-slug={role.slug}>
                    {role.name}
                  </option>
                ))}
              </Select>
              <ErrorMessage component={ErrorLabel} name="roleId" />
              {values.roleSlug && values.roleSlug === 'merchantPep' && (
                <>
                  <InputWithIcon>
                    <InputAuto
                      suggestions={countiesSuggestions}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      onlyFromList
                      pickProp="id"
                      name="countieSearch"
                      type="text"
                      display={['name']}
                      onBlur={handleBlur}
                      onSelect={(val, item) => {
                        if (!values.counties.some(c => c.id === item.id)) {
                          setFieldValue('counties', [...values.counties, item]);
                        }
                        setFieldValue('countieSearch', '');
                      }}
                      label="Dodaj powiat"
                      value={values.countieSearch || ''}
                      error={touched.countieSearch && errors.countieSearch}
                    />
                    <FontAwesomeIcon icon="list" onClick={toggleCountieBoxVisibility} />
                  </InputWithIcon>
                  <div style={{ marginBottom: '10px' }}>
                    {countieBoxVisible && (
                      <CountiesList
                        initialPicked={values.counties}
                        counties={countiesSuggestions}
                        closeHandler={() => setCountieBoxVisible(false)}
                        saveHandler={pickedCounties => {
                          toggleCountieBoxVisibility();
                          const uniquePicks = pickedCounties.filter(el => !values.counties.some(c => c.id === el.id));
                          setFieldValue('counties', [...values.counties, ...uniquePicks]);
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {values.counties &&
                      values.counties.map(c => (
                        <Chip
                          key={`${c.id}`}
                          onClick={() => {
                            const newValue = values.counties.filter(el => el.id !== c.id);
                            setFieldValue('counties', newValue);
                          }}
                        >
                          {c.name}
                        </Chip>
                      ))}
                  </div>
                </>
              )}
              {values.roleSlug && (values.roleSlug === 'partner' || values.roleSlug === 'subPartner') && (
                <>
                  {!initialValues.companyId && (
                    <>
                      <InputAuto
                        suggestions={companiesSug}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        onlyFromList
                        pickProp="id"
                        name="companyId"
                        type="text"
                        display={['name']}
                        onBlur={handleBlur}
                        onChange={e => fetchCompanies(e.target.value)}
                        label="Firma"
                        value={values.companyId || ''}
                        error={touched.companyId && errors.companyId}
                      />
                      <ErrorMessage component={ErrorLabel} name="companyId" />
                    </>
                  )}
                  {values.roleSlug === 'partner' && (
                    <>
                      <Checkbox
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                        name="mainPartner"
                        onChange={e => setFieldValue('mainPartner', e.target.checked ? '1' : '0')}
                        onBlur={handleBlur}
                        label="Partner główny"
                        value={values.mainPartner}
                        checked={values.mainPartner === '1'}
                        error={touched.mainPartner && errors.mainPartner}
                      />
                      <Checkbox
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                        name="coordinator"
                        onChange={e => setFieldValue('coordinator', e.target.checked ? '1' : '0')}
                        onBlur={handleBlur}
                        label="Faktury"
                        value={values.coordinator}
                        checked={values.coordinator === '1'}
                        error={touched.coordinator && errors.coordinator}
                      />
                    </>
                  )}
                  <InputAuto
                    suggestions={pepStructureSuggestions}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="leadMaster"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    label="Leadmaster"
                    value={values.leadMaster || ''}
                    error={touched.leadMaster && errors.leadMaster}
                  />
                  <ErrorMessage component={ErrorLabel} name="leadMaster" />
                  <InputAuto
                    suggestions={phsSugg}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="dedicatedPhSearch"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    onChange={e => fetchUsers(e.target.value, 'merchantPep', setPhsSugg)}
                    onSelect={(val, item) => {
                      if (!values.dedicatedPhs.some(dph => dph.id === item.id)) {
                        const { id, name, surname } = item;
                        setFieldValue('dedicatedPhs', [...values.dedicatedPhs, { id, name, surname }]);
                      }
                      setFieldValue('dedicatedPhSearch', '');
                    }}
                    label="Przedstawiciele dedykowani"
                    value={values.dedicatedPhSearch || ''}
                    error={touched.dedicatedPhSearch && errors.dedicatedPhSearch}
                  />
                  <ErrorMessage component={ErrorLabel} name="dedicatedPhs" />
                  <div>
                    {values.dedicatedPhs &&
                      values.dedicatedPhs.map(dph => (
                        <Chip
                          key={`${dph.id}`}
                          onClick={() => {
                            const newValue = values.dedicatedPhs.filter(el => el.id !== dph.id);
                            setFieldValue('dedicatedPhs', newValue);
                          }}
                        >
                          {`${dph.name} ${dph.surname}`}
                        </Chip>
                      ))}
                  </div>
                </>
              )}
              <Select
                name="structureId"
                value={values.structureId || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Struktura"
              >
                <option key="default" value="">
                  &nbsp;
                </option>
                {structures.map(structure => (
                  <option key={structure.id} value={structure.id}>
                    {structure.region}
                  </option>
                ))}
              </Select>
              <ErrorMessage component={ErrorLabel} name="structureId" />
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

AddUser.defaultProps = {
  initialValues: {},
};

AddUser.propTypes = {
  loggedUser: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  countiesSuggestions: PropTypes.instanceOf(Array).isRequired,
  pepStructureSuggestions: PropTypes.instanceOf(Array).isRequired,
  structures: PropTypes.instanceOf(Array).isRequired,
  refetchUsers: PropTypes.func.isRequired,
  refetchPepStructures: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  countiesSuggestions: state.admin.counties.data,
  pepStructureSuggestions: state.admin.pepStructure.data,
  structures: state.admin.structures.data,
});

export default connect(mapStateToProps)(AddUser);

// styled components

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
