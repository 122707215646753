import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors, font } from 'theme/styles';

const NavButton = styled(NavLink)`
  color: ${colors.white};
  position: relative;
  font-size: ${font.size.s};
  text-decoration: none;
  margin: 0 9px;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 0;
    background-color: #fff;
    bottom: -2px;
    left: 0;
    transition: width 0.15s ease-in;
  }

  &.active,
  &:hover {
    color: #fff;
    text-decoration: none;
    &::before {
      width: 100%;
    }
  }

  ${({ badge }) =>
    badge &&
    css`
      &::after {
        content: '${badge}';
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 100%;
        font-size: 1.2rem;
        padding: 2px 5px;
        height: 16px;
        background-color: ${colors.white};
        color: ${colors.orange};
        font-weight: ${font.weight.bold};
        border-radius: 10px;
        transform: translate(25%, -25%);
      }
    `}
`;

export default NavButton;
