import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import NoData from 'components/UI/NoData';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';

const ProductsTable = ({ headers, data, handleEdit, handleDelete, editOrderMode, setOrder, onlyActive, feeFormik }) => {
  const tableBody = useRef(null);
  const [grabbedRow, setGrabbedRow] = useState(null);
  const [justInserted, setJustInserted] = useState(false);

  const onDragEnd = (e) => {
    e.preventDefault();
    const products = Array.from(tableBody.current.children);
    setOrder(products.map((el) => el.dataset.id));
  };

  const onDragOver = (e) => {
    e.preventDefault();
    if (!justInserted) {
      setJustInserted(true);
      setTimeout(() => setJustInserted(false), 250);
      const rowEl = e.target.closest('tr');
      if (rowEl && rowEl !== grabbedRow && rowEl.nodeName === 'TR') {
        tableBody.current.insertBefore(grabbedRow, rowEl);
      }
    }
  };

  const onDragStart = (e) => {
    if (!editOrderMode) e.preventDefault();
    const draggedRow = e.target;
    setGrabbedRow(draggedRow);
    e.dataTransfer.setData('Text', draggedRow.textContent);
  };

  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map((header) => (
              <col key={header.property} className={header.property} />
            ))}
            <col className="actions" />
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map((header) => (
                <th key={header.property}>{header.displayName}</th>
              ))}
              {(handleDelete || handleEdit) && <th key="edit">Edycja</th>}
            </tr>
          </thead>
          <tbody ref={tableBody}>
            {data
              .filter((e) => (onlyActive ? e.active === '1' : true))
              .map((product, index) => (
                <tr
                  key={product.id}
                  draggable={editOrderMode ? true : null}
                  onDragEnd={editOrderMode ? onDragEnd : null}
                  onDragOver={editOrderMode ? onDragOver : null}
                  onDragStart={editOrderMode ? onDragStart : null}
                  data-id={product.id}
                >
                  <td>{index + 1}</td>
                  {headers.map((header) => {
                    let fieldName;
                    switch (header.property) {
                      case 'name':
                        return (
                          <td key={product.id + header.property}>
                            {product[header.property]} {product.isNew === '1' && <i style={{color: colors.orange}}>nowy</i>}
                          </td>
                        );
                      case 'isVisible':
                        return (
                          <td key={product.id + header.property}>
                            {product[header.property] === '1' ? (
                              <FontAwesomeIcon title="Widoczny na formularzu" icon="check" />
                            ) : (
                              ''
                            )}
                          </td>
                        );
                      case 'ambassadorVisible':
                      case 'softProducentVisible':
                      case 'casherVisible':
                        return (
                          <td key={product.id + header.property}>
                            {product[header.property] === '1' ? (
                              <FontAwesomeIcon title="Przypisany" icon="check" />
                            ) : (
                              ''
                            )}
                          </td>
                        );
                      case 'active':
                        return (
                          <td key={product.id + header.property}>
                            {product.active === '1' ? <FontAwesomeIcon title="Aktywny" icon="check" /> : ''}
                          </td>
                        );
                      case 'ospObj':
                        return (
                          <td key={product.id + header.property}>
                            {product.ospObj ? `${product.ospObj.name} ${product.ospObj.surname}` : ''}
                          </td>
                        );
                      case 'oneTimeFee':
                      case 'cyclicFee':
                        fieldName = `${product.id}.${header.property}`;
                        return feeFormik ? (
                          <td key={product.id + header.property}>
                            <InputWrapper>
                              <StyledInput
                                type="number"
                                placeholder="stawka"
                                name={fieldName}
                                id={fieldName}
                                onChange={(e) => {
                                  feeFormik.handleChange(e);
                                }}
                                value={feeFormik.values[product.id][header.property]}
                              />
                              <span>zł</span>
                              <div>{feeFormik.errors[product.id] && feeFormik.errors[product.id][header.property]}</div>
                            </InputWrapper>
                          </td>
                        ) : (
                          <td key={product.id + header.property}>{product[header.property]} zł</td>
                        );
                      default:
                        return <td key={product.id + header.property}>{product[header.property]}</td>;
                    }
                  })}
                  {(handleDelete || handleEdit) && (
                    <td key="edit">
                      <span className="icons">
                        <FontAwesomeIcon title="Edytuj" icon="edit" onClick={() => handleEdit(product.id)} />
                        <FontAwesomeIcon title="Usuń" icon="trash-alt" onClick={() => handleDelete(product.id)} />
                      </span>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <NoData />
      )}
    </TableWrapper>
  );
};
ProductsTable.defaultProps = {
  handleEdit: false,
  setOrder: false,
  editOrderMode: false,
  handleDelete: false,
  onlyActive: false,
  feeFormik: false,
};

ProductsTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  setOrder: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  editOrderMode: PropTypes.bool,
  onlyActive: PropTypes.bool,
  feeFormik: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
};

export default ProductsTable;

// styled components
const StyledInput = styled.input`
  border: 1px solid ${(props) => (props.error ? 'red' : 'inherit')} !important;
`;

const InputWrapper = styled.div`
  position: relative;

  input {
    display: inline-block;
    text-align: right;
    padding-right: 25px;
    border: 1px solid ${colors.lightGray};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  span {
    position: absolute;
    right: 5px;
    display: inline-block;
    width: 20px;
  }

  div {
    position: absolute;
    font-size: ${font.size.xs};
    color: ${colors.red};
    line-height: 1;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: left;
  }

  input:placeholder-shown + span {
    color: ${colors.mediumGray};
  }
`;

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 450px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;
    text-align: center;

    tr {
      th:nth-child(2),
      td:nth-child(2) {
        text-align: left;
        padding-left: 20px;
      }

      th,
      td {
        padding-left: 8px;
        padding-right: 8px;
        white-space: pre-line;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 40px;

        &[draggable='true'] {
          cursor: grab;

          &:not(:last-of-type) {
            border-bottom: 2px dashed ${colors.lightGray};
          }
        }

        &:nth-of-type(even) {
          background-color: ${colors.white};
        }

        td {
          font-size: ${font.size.m};
          text-transform: none;
          color: ${colors.black};

          & > svg {
            color: ${colors.orange};
          }

          & > .icons {
            display: inline-block;
            min-width: 60px;
            color: ${colors.orange};
            svg {
              cursor: pointer;
              margin: 0 7px;
            }
          }
        }
      }
    }
  }
`;
