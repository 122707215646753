/* eslint-disable no-console */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import { resetPasswordSchema } from 'utils/validation';
import { userService } from 'services/user';
import { font, colors } from 'theme/styles';
import { routes } from 'routes';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import AuthTemplate from 'templates/AuthTemplate';
import Loading from 'components/UI/Loading';
import ErrorsWrapper from 'components/UI/ErrorsWrapper';

const ResetPasswordPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [succes, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const handleSubmit = values => {
    setLoading(true);
    setError(false);

    userService
      .resetPassword(values.email.trim())
      .then(data => {
        console.log(data);
        setLoading(false);
        setSucces(true);
        setTimeout(() => {
          history.push('/login');
        }, 5000);
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  return (
    <AuthTemplate>
      {isLoading && <Loading absolute />}
      {succes ? (
        <div style={{ textAlign: 'center' }}>
          <Heading centered>Link do zmiany hasła został wysłany na Twój email</Heading>
          <p>Za chwilę nastąpi przekierowanie na stronę logowania</p>
        </div>
      ) : (
        <>
          <Heading centered>Resetuj hasło</Heading>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={resetPasswordSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm({});
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => {
              return (
                <StyledForm>
                  <Input
                    name="email"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Adres email"
                    value={values.email || ''}
                    error={touched.email && errors.email}
                  />
                  <ErrorMessage component={ErrorLabel} name="email" />
                  {error && <ErrorsWrapper>{error}</ErrorsWrapper>}
                  <Button style={{ marginTop: '20px' }} centered type="submit">
                    Resetuj
                  </Button>
                </StyledForm>
              );
            }}
          </Formik>
          <StyledLink to={routes.login}>Powrót do logowania</StyledLink>
        </>
      )}
    </AuthTemplate>
  );
};

export default ResetPasswordPage;

// styled components

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
`;

const StyledLink = styled(Link)`
  position: relative;
  bottom: -30px;
  font-size: ${font.size.s};
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  color: ${colors.darkGray};

  &:hover {
    color: ${colors.red};
  }
`;
