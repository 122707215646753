import { optionsService } from 'services/options';
import * as types from './types';

export const getPublicOptions = () => (dispatch) => {
  dispatch({ type: types.GET_PUBLIC_OPTIONS_REQUEST });
  return optionsService
    .getPublicOptions()
    .then((payload) => {
      dispatch({ type: types.GET_PUBLIC_OPTIONS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_PUBLIC_OPTIONS_FAILURE, err });
    });
};

export const clearPublicOptions = () => (dispatch) => {
  dispatch({ type: types.CLEAR_PUBLIC_OPTIONS });
};
