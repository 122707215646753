import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import { addProductSchema } from 'utils/validation';
import { productService } from 'services/product';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
// import { TwoColumns } from 'components/UI/Structure';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Checkbox from 'components/UI/Checkbox';
import Loading from 'components/UI/Loading';
import InputAuto from 'components/UI/InputAuto';

const EditProduct = ({ productData, refetchProducts, handleClose, pepStructureSuggestions }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = () => {
    setSuccess(false);
    setError('');
  };

  const handleSubmit = (values) => {
    setError('');
    setLoading(true);
    productService
      .editProduct(productData.id, values)
      .then((data) => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchProducts();
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  const {
    name,
    active,
    // oneTimeFee,
    // cyclicFee,
    isVisible,
    isNew,
    ambassadorVisible,
    softProducentVisible,
    casherVisible
  } = productData;
  const osp = productData.ospObj || '';

  return (
    <Wrapper>
      {success ? (
        <SuccessPopup>
          <Heading>Produkt zaktualizowany</Heading>
          <div className="buttons">
            <Button onClick={handleClose}>Zamknij</Button>
          </div>
        </SuccessPopup>
      ) : (
        ''
      )}
      {error ? (
        <ErrorPopup>
          <Heading>Błąd aktualizacji produktu</Heading>
          <p>Upewnij się czy dany produkt nie istnieje</p>
          {/* <p>{error}</p> */}
          <div className="buttons">
            <Button onClick={handleReturn}>Spróbuj ponownie</Button>
          </div>
        </ErrorPopup>
      ) : (
        ''
      )}
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Zarządzanie produktem</Heading>
      <Formik
        initialValues={{
          name,
          active,
          isVisible,
          isNew,
          ambassadorVisible,
          softProducentVisible,
          casherVisible,
          osp,
          // oneTimeFee,
          // cyclicFee,
        }}
        validationSchema={addProductSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, isValid }) => (
          <StyledForm>
            <FieldHeading big size="m">
              Edytuj dane:
            </FieldHeading>
            <Input
              name="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Nazwa produktu"
              value={values.name}
              error={touched.name && errors.name}
            />
            <ErrorMessage component={ErrorLabel} name="name" />
            <InputAuto
              suggestions={pepStructureSuggestions}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onlyFromList
              pickProp="id"
              name="osp"
              type="text"
              display={['name', 'surname', 'email']}
              onBlur={handleBlur}
              label="Opiekun sprzedażowy produktu"
              value={values.osp || ''}
              error={touched.osp && errors.osp}
            />
            <ErrorMessage component={ErrorLabel} name="osp" />
            {/* <TwoColumns>
              <div>
                <Input
                  currency
                  name="oneTimeFee"
                  type="number"
                  onChange={(e) => setFieldValue('oneTimeFee', e.target.value, true)}
                  onBlur={handleBlur}
                  label="Stawka jednorazowa"
                  value={values.oneTimeFee || values.oneTimeFee === 0 ? values.oneTimeFee : ''}
                  error={touched.oneTimeFee && errors.oneTimeFee}
                />
                <ErrorMessage component={ErrorLabel} name="oneTimeFee" />
              </div>
              <div>
                <Input
                  currency
                  name="cyclicFee"
                  type="number"
                  onChange={(e) => setFieldValue('cyclicFee', e.target.value, true)}
                  onBlur={handleBlur}
                  label="Stawka cykliczna"
                  value={values.cyclicFee || values.cyclicFee === 0 ? values.cyclicFee : ''}
                  error={touched.cyclicFee && errors.cyclicFee}
                />
                <ErrorMessage component={ErrorLabel} name="cyclicFee" />
              </div>
            </TwoColumns> */}
            <br style={{ marginTop: '10px' }} />
            <Checkbox
              name="active"
              onChange={(e) => setFieldValue('active', e.target.checked ? '1' : '0')}
              onBlur={handleBlur}
              label="Aktywny"
              value={values.active}
              checked={values.active === '1'}
              error={touched.active && errors.active}
            />
            <Checkbox
              name="isVisible"
              onChange={(e) => setFieldValue('isVisible', e.target.checked ? '1' : '0')}
              onBlur={handleBlur}
              label="Widoczny na formularzu"
              value={values.isVisible}
              checked={values.isVisible === '1'}
              error={touched.isVisible && errors.isVisible}
            />
            <Checkbox
              name="isNew"
              onChange={(e) => setFieldValue('isNew', e.target.checked ? '1' : '0')}
              onBlur={handleBlur}
              label="Nowy"
              value={values.isNew}
              checked={values.isNew === '1'}
              error={touched.isNew && errors.isNew}
            />
            <FieldHeading medium size="m">
              Ogranicz do:
            </FieldHeading>
            <Checkbox
              name="ambassadorVisible"
              onChange={(e) => setFieldValue('ambassadorVisible', e.target.checked ? '1' : '0')}
              onBlur={handleBlur}
              label="Ambasador"
              value={values.ambassadorVisible}
              checked={values.ambassadorVisible === '1'}
              error={touched.ambassadorVisible && errors.ambassadorVisible}
            />
            <Checkbox
              name="softProducentVisible"
              onChange={(e) => setFieldValue('softProducentVisible', e.target.checked ? '1' : '0')}
              onBlur={handleBlur}
              label="Producent Soft"
              value={values.softProducentVisible}
              checked={values.softProducentVisible === '1'}
              error={touched.softProducentVisible && errors.softProducentVisible}
            />
            <Checkbox
                name="casherVisible"
                onChange={(e) => setFieldValue('casherVisible', e.target.checked ? '1' : '0')}
                onBlur={handleBlur}
                label="Kasiarze"
                value={values.casherVisible}
                checked={values.casherVisible === '1'}
                error={touched.casherVisible && errors.casherVisible}
              />
            <Button disabled={!isValid} style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
              Prześlij
            </Button>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};

EditProduct.propTypes = {
  refetchProducts: PropTypes.func.isRequired,
  productData: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
  pepStructureSuggestions: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = (state) => ({
  pepStructureSuggestions: state.admin.pepStructure.data,
});

export default connect(mapStateToProps)(EditProduct);

// styled components

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
