import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { displayDate, displayHour, removeNullsAndEmpty } from 'utils/helpers';
import { mapStatusObjShort } from 'config/constants';
import { TableCommon, TableWrapperCommon, TdWithTitle } from 'components/layout/Tables/TableComponents';
import { useUrl } from 'utils/hooks';
import NoData from 'components/UI/NoData';
import Loading from 'components/UI/Loading';

const LeadsTable = ({ data, headers, offset, userRole, isLoading }) => {
  const history = useHistory();
  const [advancedSearch, setAdvancedSearch] = useUrl('advancedSearch');

  const getInitialValues = () => {
    const initialObject = headers.reduce((o, prop) => {
      if (prop.advancedSearch) {
        // eslint-disable-next-line no-param-reassign
        o[`${prop.advancedSearch}-search`] = '';
      }
      return o;
    }, {});

    if (!advancedSearch) return initialObject;
    const tempArr = advancedSearch.split('|').map((el) => el.split(':'));
    tempArr.forEach((el) => {
      const [propName, value] = el;
      // eslint-disable-next-line no-param-reassign
      initialObject[`${propName}-search`] = value;
    });
    return initialObject;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    onSubmit: (values) => {
      let outputStr = '';
      const filtered = removeNullsAndEmpty({ ...values });
      Object.entries(filtered).forEach(([key, val]) => {
        outputStr += `${key.replace('-search', '')}:${val}|`;
      });
      outputStr = outputStr.slice(0, -1);
      setAdvancedSearch(outputStr);
    },
  });

  const mapStatus = (status) =>
    Object.hasOwnProperty.call(mapStatusObjShort, status) ? mapStatusObjShort[status] : status;

  const checkLeadMaster = (lead) => {
    let leadMaster = '';
    if (lead.user && lead.user.dedicatedLeadMaster) leadMaster = lead.user.dedicatedLeadMaster.fullName;
    else if (lead.user && lead.user.company && lead.user.company.leadMasterAssoc)
      leadMaster = lead.user.company.leadMasterAssoc.fullName;
    return leadMaster;
  };

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col className="lp" />
          {headers.map((header) => (
            <col key={header.property} className={header.property} />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className="noSearch">LP</th>
            {headers.map((header) => (
              <th key={header.property} className={header.advancedSearch ? null : 'noSearch'}>
                {header.displayName}
                {header.advancedSearch ? (
                  <StyledSearch
                    type="text"
                    placeholder="Szukaj..."
                    name={`${header.advancedSearch}-search`}
                    id={`${header.advancedSearch}-search`}
                    onChange={formik.handleChange}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.target.blur();
                      }
                    }}
                    onBlur={() => {
                      if (formik.dirty) formik.handleSubmit();
                    }}
                    value={formik.values[`${header.advancedSearch}-search`]}
                  />
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            data.map((lead, index) => (
              <tr
                key={lead.id}
                onClick={() => history.push(`/lead/details/${lead.id}`)}
                style={{
                  fontWeight:
                    userRole === 'merchantPep' && lead.taken === null && lead.status !== 'rejected_ph' ? 'bold' : null,
                }}
              >
                <td>{offset + index + 1}</td>
                {headers.map((header) => {
                  const uniqueKey = lead.id + header.property;
                  switch (header.property) {
                    case 'createdAt':
                      return (
                        <TdWithTitle key={uniqueKey}>
                          {displayDate(lead[header.property])}
                          <span>{displayHour(lead[header.property])}</span>
                        </TdWithTitle>
                      );
                    case 'frontNumLead':
                      return <td key={uniqueKey}>{lead[header.property]}</td>;
                    case 'status':
                      return (
                        <TdWithTitle key={uniqueKey}>
                          {mapStatus(lead[header.property])}
                          <span>Ost.&nbsp;aktualizacja {displayDate(lead.updatedAt)}</span>
                        </TdWithTitle>
                      );
                    case 'user':
                      return <td key={uniqueKey}>{lead.user && `${lead.user.name} ${lead.user.surname}`}</td>;
                    case 'userCompanyName':
                      return <td key={uniqueKey}>{lead.user && lead.user.company ? lead.user.company.name : ''}</td>;
                    case 'leadMaster':
                      return <td key={uniqueKey}>{checkLeadMaster(lead)}</td>;
                    case 'taken':
                      return <td key={uniqueKey}>{lead.taken ? `${lead.taken.name} ${lead.taken.surname}` : ''}</td>;
                    case 'queue':
                      return (
                        <td key={uniqueKey}>
                          {lead.queue && lead.queue.user ? `${lead.queue.user.name} ${lead.queue.user.surname}` : ''}
                        </td>
                      );
                    default:
                      return <td key={uniqueKey}>{lead[header.property]}</td>;
                  }
                })}
              </tr>
            ))}
        </tbody>
      </Table>
      {isLoading && <Loading />}
      {!isLoading && data.length === 0 && <NoData />}
    </TableWrapper>
  );
};

LeadsTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LeadsTable;

// styled components

const StyledSearch = styled.input`
  display: block;
  padding: 0 4px;
  width: 100%;
  margin-top: 5px;
`;

const TableWrapper = styled(TableWrapperCommon)``;

const Table = styled(TableCommon)``;
