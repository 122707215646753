import makeRequest from 'services/makeRequest';
import axios from 'axios';

// eslint-disable-next-line prefer-destructuring
const CancelToken = axios.CancelToken;
let cancelPartnerLeadsExport;

const GET_USERS_URL = '/dashboard/patron/sub-partner/list';
const GET_USER_URL = '/partner/users';
const ADD_USER_URL = '/dashboard/patron/create-sub-partner';
const EDIT_USER_URL = '/partner/users/edit';
const GET_PARTNER_LEADS_URL = '/partner-leads';
const GET_TERMS_URL = '/companies/terms';
const GET_POS_URL = '/companies/pos';

const getPosInfo = nip => {
  return makeRequest({
    method: 'get',
    url: `${GET_POS_URL}/${nip}`,
  });
};

const getTerms = id => {
  return makeRequest({
    method: 'get',
    url: `${GET_TERMS_URL}/${id}`,
  });
};

const getAllUsers = queryData => {
  return makeRequest({
    method: 'get',
    url: GET_USERS_URL,
    params: queryData,
  });
};

const getUser = id => {
  return makeRequest({
    method: 'get',
    url: `${GET_USER_URL}/${id}`,
  });
};

const addUser = data => {
  return makeRequest({
    method: 'post',
    url: ADD_USER_URL,
    data,
  });
};

const editUser = (id, data) => {
  return makeRequest({
    method: 'post',
    url: `${EDIT_USER_URL}/${id}`,
    data,
  });
};

const getPartnerLeads = id => {
  return makeRequest({
    method: 'get',
    url: `${GET_PARTNER_LEADS_URL}/${id}`,
    timeout: 0,
    responseType: 'blob',
    cancelToken: new CancelToken(function executor(c) {
      cancelPartnerLeadsExport = c;
    }),
  });
};

export const partnerService = {
  getAllUsers,
  getUser,
  addUser,
  editUser,
  getPartnerLeads,
  getTerms,
  getPosInfo,
  cancelPartnerLeadsExport,
};
