import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getMarketingMaterials,
  getMarketingMaterial,
  setCurrentMarketingMaterial,
  clearCurrentMarketingMaterial,
} from 'redux/marketing';
import { marketingService } from 'services/marketing';
import PageTemplate from 'templates/PageTemplate';
import MarketingMaterialsTable from 'components/layout/Tables/MarketingMaterialsTable';
import MarketingForm from 'components/forms/MarketingForm';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import Pagination from 'components/UI/Pagination';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import SuccessPopup from 'components/layout/SuccessPopup';
import { download } from 'utils/helpers';

import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';

const MarketingPage = ({
  marketingMaterials,
  marketingMaterialsCount,
  currentMarketingMaterial,
  isLoading,
  error,
  totalPages,
  currentPage,
  getMarketingMaterials,
  getMarketingMaterial,
  setCurrentMarketingMaterial,
  clearCurrentMarketingMaterial,
  userRole,
}) => {
  const [isAddNewVisible, setIsAddNewVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [deletedError, setDeletedError] = useState('');
  const [deleted, setDeleted] = useState('');
  const [page, setPage] = useState('1');
  const [perPage, setPerPage] = useState('20');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('true');

  const handleCanEdit = recomendation => {
    if (['admin', 'dsp'].includes(userRole)) return true;
    return recomendation.status === 'NEW';
  };

  const getMarketingMaterialsData = useCallback(() => {
    const query = {
      limit: perPage,
      page,
      // showActive: status,
      ...(status && { showActive: status }),
      search: search || null,
    };
    getMarketingMaterials(query);
  }, [getMarketingMaterials, perPage, page, status, search]);

  useEffect(() => {
    getMarketingMaterialsData();
  }, [getMarketingMaterialsData]);

  const toggleNewPanel = () => {
    setIsAddNewVisible(!isAddNewVisible);
  };

  const closeEditPanel = () => {
    setEditVisible(false);
    clearCurrentMarketingMaterial();
  };

  const getMaterial = id => {
    const local = marketingMaterials.find(u => u.id === id);
    if (local) {
      setCurrentMarketingMaterial(local);
    } else {
      getMarketingMaterial(id);
    }
  };

  const openEditPanel = id => {
    getMaterial(id);
    setEditVisible(true);
  };

  const handlePerPage = e => {
    setPerPage(e.target.value);
    setPage('1');
  };

  const handleSearch = value => {
    setSearch(value);
    setPage(1);
  };

  const handleStatus = e => {
    setStatus(e.target.value);
    setPage(1);
  };

  const headers = [
    { displayName: 'Data modyfikacji', property: 'updatedAt' },
    { displayName: 'Typ', property: 'type' },
    { displayName: 'Nazwa', property: 'name' },
    ['admin', 'dsp'].includes(userRole) && { displayName: 'Aktywny', property: 'active' },
    { displayName: 'Pobierz', property: 'download' },
  ].filter(Boolean);

  const handleDelete = id => {
    setDeletedError('');

    marketingService
      .deleteMarketingMaterial(id)
      .then(() => {
        setDeleted('');
        getMarketingMaterialsData();
      })
      .catch(err => {
        console.dir(err);
        setDeletedError(err.response.data.error.message);
      });
  };

  const handleDownload = materialItem => {
    let { fileName } = materialItem;
    if (materialItem.fileExtension.split('###').length > 1) {
      fileName = 'banners.zip';
    }
    marketingService
      .getMarketingMaterialFile(materialItem.id)
      .then(res => {
        download(res, fileName);
      })
      .catch(err => {
        console.dir(err);
      });
  };

  const showDeletePopup = deleted => (
    <SuccessPopup fixed>
      <span>Czy na pewno chcesz usunąć {deleted.companyName} ?</span>
      {deletedError !== '' && <span style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{deletedError}</span>}
      <div className="buttons">
        <Button
          secondary
          onClick={() => {
            setDeletedError('');
            setDeleted('');
          }}
        >
          Anuluj
        </Button>
        <Button onClick={() => handleDelete(deleted.id)}>{deletedError ? 'Spróbuj ponownie' : 'Usuń'}</Button>
      </div>
    </SuccessPopup>
  );

  return (
    <PageTemplate>
      {!isLoading && error && <ErrorPopup fixed>{error.message}</ErrorPopup>}
      <TopSection>
        {['admin', 'dsp'].includes(userRole) ? (
          <Button onClick={toggleNewPanel}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj materiały
          </Button>
        ) : (
          <span />
        )}
        <TableSorter>
          {['admin', 'dsp'].includes(userRole) && (
            <SortItem>
              <span>Status</span>
              <Select small value={status} onChange={handleStatus}>
                <option value="true">Aktywne</option>
                <option value="false">Nieaktywne</option>
                <option value="">Wszystkie</option>
              </Select>
            </SortItem>
          )}
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {isLoading ? (
        <Loading />
      ) : (
        <MarketingMaterialsTable
          data={marketingMaterials}
          headers={headers}
          offset={(currentPage - 1) * (perPage || 20)}
          canUserEdit={handleCanEdit}
          handleDelete={['admin', 'dsp'].includes(userRole) && setDeleted}
          handleEdit={['admin', 'dsp'].includes(userRole) && openEditPanel}
          handleDownload={handleDownload}
        />
      )}
      <BottomSection>
        <BottomItem>Wszystkich materiałów: {marketingMaterialsCount}</BottomItem>
        <Pagination currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      {deleted !== '' && showDeletePopup(deleted)}
      <SidePanel small toggleClose={closeEditPanel} isVisible={isEditVisible}>
        {currentMarketingMaterial !== null && (
          <MarketingForm
            editMode
            adminMode={['admin', 'dsp'].includes(userRole)}
            initialValues={{ ...currentMarketingMaterial }}
            toggleClose={closeEditPanel}
            refetch={getMarketingMaterialsData}
          />
        )}
      </SidePanel>
      <SidePanel small toggleClose={toggleNewPanel} isVisible={isAddNewVisible}>
        <MarketingForm toggleClose={toggleNewPanel} refetch={getMarketingMaterialsData} />
      </SidePanel>
    </PageTemplate>
  );
};

MarketingPage.defaultProps = {
  currentMarketingMaterial: null,
  marketingMaterialsCount: 0,
  error: null,
  totalPages: 1,
  currentPage: 1,
};

MarketingPage.propTypes = {
  marketingMaterials: PropTypes.instanceOf(Array).isRequired,
  currentMarketingMaterial: PropTypes.instanceOf(Object),
  marketingMaterialsCount: PropTypes.number,

  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Object),

  totalPages: PropTypes.number,
  currentPage: PropTypes.number,

  getMarketingMaterials: PropTypes.func.isRequired,
  getMarketingMaterial: PropTypes.func.isRequired,
  setCurrentMarketingMaterial: PropTypes.func.isRequired,
  clearCurrentMarketingMaterial: PropTypes.func.isRequired,

  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  marketingMaterials: state.marketing.materials.data.rows,
  currentMarketingMaterial: state.marketing.currentMaterial.data,
  marketingMaterialsCount: state.marketing.materials.data.count,

  isLoading: state.marketing.materials.isLoading,
  error: state.marketing.materials.error,

  totalPages: state.marketing.materials.data.pagination.totalPages,
  currentPage: state.marketing.materials.data.pagination.currentPage,

  userRole: state.user.data.role.slug,
});

const mapDispatchToProps = {
  getMarketingMaterials,
  getMarketingMaterial,
  setCurrentMarketingMaterial,
  clearCurrentMarketingMaterial,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPage);
