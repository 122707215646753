/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams, Link } from 'react-router-dom';
import { userService } from 'services/user';
import { font, colors } from 'theme/styles';
import { routes } from 'routes';
import Heading from 'components/UI/Heading';
import AuthTemplate from 'templates/AuthTemplate';
import Loading from 'components/UI/Loading';
import ErrorsWrapper from 'components/UI/ErrorsWrapper';

const ResetPasswordConfirmPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [succes, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { id, token } = useParams();

  useEffect(() => {
    setLoading(true);
    setError(false);

    userService
      .resetPasswordConfirm(id, token)
      .then(data => {
        console.log(data);
        setLoading(false);
        setSucces(true);
        setTimeout(() => {
          history.push(routes.login);
        }, 5000);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setError(err.response.data.error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthTemplate>
      {isLoading && <Loading absolute />}
      {succes ? (
        <div style={{ textAlign: 'center' }}>
          <Heading centered>Nowe hasło zostało wysłane</Heading>
          <p>Za chwilę nastąpi przekierowanie na stronę logowania</p>
        </div>
      ) : (
        <>{error && <ErrorsWrapper>{error}</ErrorsWrapper>}</>
      )}
      <StyledLink to={routes.login}>Powrót do logowania</StyledLink>
    </AuthTemplate>
  );
};

export default ResetPasswordConfirmPage;

const StyledLink = styled(Link)`
  position: relative;
  bottom: -30px;
  font-size: ${font.size.s};
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  color: ${colors.darkGray};

  &:hover {
    color: ${colors.red};
  }
`;
