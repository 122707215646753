import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllApiKeys } from 'redux/admin';
import { adminService } from 'services/admin';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import SidePanel from 'components/layout/SidePanel';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import { TopSection, BottomItem, BottomSection, TableSorter, SortItem } from 'components/layout/Tables/TableComponents';
import SuccessPopup from 'components/layout/SuccessPopup';
import ApiKeysTable from 'components/layout/Tables/ApiKeysTable';
import Pagination from 'components/UI/Pagination';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import AddKey from 'components/forms/Admin/AddKey';
import EditKey from 'components/forms/Admin/EditKey';

const headers = [
  { displayName: 'Nazwa klucza', property: 'name' },
  { displayName: 'Klucz', property: 'key' },
  { displayName: 'Przypisany użytkownik', property: 'user' },
  { displayName: 'Data', property: 'date' },
  { displayName: 'Aktywny', property: 'active' },
];

const AdminApiPage = ({
  getAllApiKeys,
  isLoadingKeys,
  keysData,
  keysError,
  keysCount,
  currentPage,
  totalPages,
}) => {
  const [addKeyPanelVisible, setAddKeyPanelVisible] = useState(false);
  const [editKey, setEditKey] = useState(null);
  const [deletedKeyId, setDeletedKeyId] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [page, setPage] = useState('1');
  const [perPage, setPerPage] = useState('20');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  const getKeys = useCallback(() => {
    const query = {
      name: search,
    };
    if (status !== '') query.active = status;
    getAllApiKeys(perPage, page, query);
  }, [getAllApiKeys, perPage, page, search, status]);

  useEffect(() => {
    getKeys();
  }, [getKeys]);

  const handlePerPage = e => {
    setPerPage(e.target.value);
    setPage('1');
  };

  const handleSearch = value => {
    setSearch(value);
    setPage(1);
  };

  const handleStatus = e => {
    setStatus(e.target.value);
    setPage(1);
  };

  const toggleAddKeyPanel = () => {
    setAddKeyPanelVisible(!addKeyPanelVisible);
  };

  const openEditProductPanel = id => {
    const selectedKey = keysData.find(key => key.id === id);
    setEditKey(selectedKey);
  };

  const closeEditKeyPanel = () => {
    setEditKey(null);
  };

  const handleDelete = id => {
    setDeleteError('');

    adminService
      .deleteApiKey(id)
      .then(() => {
        setDeletedKeyId('');
        getKeys();
      })
      .catch(err => {
        console.dir(err);
        setDeleteError(err.response.data.error.message);
      });
  };

  const showDeletePopup = id => {
    const keyName = keysData.find(key => key.id === id).name;
    return (
      <SuccessPopup fixed>
        <span>Czy na pewno chcesz usunąć klucz {keyName}?</span>
        {deleteError !== '' && <span style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{deleteError}</span>}
        <div className="buttons">
          <Button
            secondary
            onClick={() => {
              setDeleteError('');
              setDeletedKeyId('');
            }}
          >
            Anuluj
          </Button>
          <Button onClick={() => handleDelete(id)}>{deleteError ? 'Spróbuj ponownie' : 'Usuń'}</Button>
        </div>
      </SuccessPopup>
    );
  };

  const showErrorPopup = error => <ErrorPopup fixed>{error.message}</ErrorPopup>;

  return (
    <PageTemplate>
      <TopSection style={{ alignItems: 'flex-start' }}>
        <Button onClick={toggleAddKeyPanel}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Nowy klucz
        </Button>
        <TableSorter>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={handleStatus}>
              <option value="">&nbsp;</option>
              <option value="1">Aktywne</option>
              <option value="0">Nieaktywne</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {!isLoadingKeys && keysError && showErrorPopup(keysError)}
      {isLoadingKeys ? (
        <Loading />
      ) : (
        <ApiKeysTable
          handleEdit={openEditProductPanel}
          handleDelete={setDeletedKeyId}
          data={keysData}
          headers={headers}
          offset={(currentPage - 1) * (perPage || 20)}
        />
      )}
      <BottomSection>
        <BottomItem>Wszystkich kluczy: {keysCount}</BottomItem>
        <Pagination currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      {deletedKeyId !== '' && showDeletePopup(deletedKeyId)}
      <SidePanel small toggleClose={closeEditKeyPanel} isVisible={editKey !== null}>
        {editKey !== null && <EditKey refetchKeys={getAllApiKeys} handleClose={closeEditKeyPanel} data={editKey} />}
      </SidePanel>
      <SidePanel small toggleClose={toggleAddKeyPanel} isVisible={addKeyPanelVisible}>
        <AddKey refetchKeys={getAllApiKeys} />
      </SidePanel>
    </PageTemplate>
  );
};

AdminApiPage.defaultProps = {
  keysError: null,
  // keyError: null,
};

AdminApiPage.propTypes = {
  getAllApiKeys: PropTypes.func.isRequired,
  isLoadingKeys: PropTypes.bool.isRequired,
  keysData: PropTypes.instanceOf(Array).isRequired,
  keysError: PropTypes.instanceOf(Object),
  // isLoadingKey: PropTypes.bool.isRequired,
  // keyData: PropTypes.instanceOf(Array).isRequired,
  // keyError: PropTypes.instanceOf(Object),
  keysCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  isLoadingKeys: state.admin.apiKeys.isLoading,
  keysData: state.admin.apiKeys.data.rows,
  keysError: state.admin.apiKeys.error,
  // isLoadingKey: state.admin.currentApiKey.isLoading,
  // keyData: state.admin.currentApiKey.data,
  // keyError: state.admin.currentApiKey.error,
  keysCount: state.admin.apiKeys.data.count,
  currentPage: state.admin.apiKeys.data.pagination.currentPage,
  totalPages: state.admin.apiKeys.data.pagination.totalPages,
});

const mapDispatchToProps = {
  getAllApiKeys,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminApiPage);
