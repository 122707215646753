import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { colors } from 'theme/styles';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';
import { ReactComponent as UsersIcon } from 'assets/icons/admin-users.svg';
import { ReactComponent as CollabIcon } from 'assets/icons/admin-collab.svg';
import { ReactComponent as MarketingIcon } from 'assets/icons/marketing.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/invoice.svg';
import { ReactComponent as IntegrationIcon } from 'assets/icons/integrationDoc.svg';
import { ReactComponent as IntegrationOrdersIcon } from 'assets/icons/admin-cash-register.svg';
import { ReactComponent as TrainingMaterialsIcon } from 'assets/icons/training-materials.svg';
// import myBenefitLogo from 'assets/mybenefit.jpg';
// import { ReactComponent as TermsIcon } from 'assets/icons/price.svg';
// import challengeIcon from 'assets/icons/admin-challenge.svg';

const PartnerPage = ({ userRole, user }) => {
  return (
    <PageTemplate>
      <CardsWrapper>
        {userRole === 'partner' && user.company?.teamManagement === '1' && (
          <Card to={routes.partnerUsers}>
            <UsersIcon />
            <Heading centered>
              Zarządzanie
              <br />
              zespołem
            </Heading>
          </Card>
        )}
        {userRole === 'partner' && user.coordinator === '1' && user.company?.invoiceAccess === '1' && (
          <Card to={routes.partnerInvoicing}>
            <InvoiceIcon />
            <Heading centered>Fakturowanie</Heading>
          </Card>
        )}
        <Card to={routes.collaboration}>
          <CollabIcon />
          <Heading centered>
            Program
            <br />
            poleceń
          </Heading>
        </Card>
        <Card to={routes.marketing}>
          <MarketingIcon />
          <Heading centered>Materiały marketingowe</Heading>
        </Card>
        <Card to={routes.trainingMaterials}>
          <TrainingMaterialsIcon />
          <Heading centered>Materiały szkoleniowe</Heading>
        </Card>
        <Card to={routes.documents}>
          <IntegrationIcon />
          <Heading centered>Dokumenty</Heading>
        </Card>
        {user.canInstallTerminal && (
          <Card to={routes.integrationOrders}>
            <IntegrationOrdersIcon />
            <Heading centered> Zlecenia</Heading>
          </Card>
        )}
        {/* <Card to={{ pathname: '//system.mybenefit.pl/mybenefit/login.html' }} target="_blank">
          <img src={myBenefitLogo} alt="Program MyBenefit" />
          <Heading centered>Program MyBenefit</Heading>
        </Card> */}
        {/* <Card to={routes.partnerTerms.replace(':id', user.companyId)}>
          <TermsIcon />
          <Heading centered>
            Warunki
            <br />
            handlowe
          </Heading>
        </Card> */}
        {/* <Card to={routes.partnerChallenges}>
          <img src={challengeIcon} alt="Wyzwania" />
          <Heading centered>Wyzwania<br />zespołowe</Heading>
        </Card> */}
      </CardsWrapper>
    </PageTemplate>
  );
};

PartnerPage.defaultProps = {
  userRole: '',
  user: null,
};

PartnerPage.propTypes = {
  userRole: PropTypes.string,
  user: PropTypes.instanceOf(Object),
};

export default PartnerPage;

// styled components
const CardsWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const CardCss = css`
  display: block;
  height: 171px;
  width: 260px;
  margin: 10px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  background: ${colors.white};
  border-radius: 14px;
  box-shadow: 0px 3px 15px #00000010;
  text-decoration: none;

  img,
  svg {
    max-height: 54px;
    max-width: 160px;
  }
  img {
    height: auto;
    margin-bottom: 10px;
  }

  svg {
    height: auto;
    width: auto;
    margin-bottom: 10px;
    fill: ${colors.orange};
    transition: fill 0.3s;
  }

  &:hover svg {
    fill: ${colors.red};
  }
`;

const Card = styled(Link)`
  ${CardCss}
`;
