/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/UI/Checkbox';
import { Block } from 'components/UI/Structure';
import { AlignCheckbox, FieldHeading, FlexFields } from 'components/forms/FormComponents';
import { placeFieldsRender } from 'components/forms/LeadMerchant/others/helpers';
import { ErrorMessage } from 'formik';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import Place from './others/Place';

const LeadMerchantStep1 = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  config,
  setConfig,
  validateForm,
  viewMode,
  debugMode,
}) => {
  const { addresses: stepValues } = values;
  const { addresses: stepTouched = {} } = touched;
  const { addresses: stepErrors = {} } = errors;

  const bag = { values: stepValues, touched: stepTouched, errors: stepErrors, handleChange, handleBlur, setFieldValue };

  const [isInvoicesCheck, setIsInvoicesCheck] = useState(stepValues.invoices.sameAs);
  const [isMaillingCheck, setIsMaillingCheck] = useState(stepValues.mailling.sameAs);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  // -------------------- invoices --------------------

  useEffect(() => {
    if (config.invoicesAddress) setIsInvoicesCheck(!config.invoicesAddress);
    if (config.maillingAddress) setIsMaillingCheck(!config.maillingAddress);
  }, [config.invoicesAddress, config.maillingAddress]);

  useEffect(() => {
    let place = new Place({ sameAs: false });
    if (isInvoicesCheck) place = { ...stepValues.main, sameAs: true };
    else if (config.invoicesAddress) place = { ...stepValues.invoices, sameAs: false };
    setFieldValue('addresses.invoices', place);
  }, [isInvoicesCheck, setFieldValue, stepValues.main]);

  useEffect(() => {
    setConfig((c) => ({ ...c, invoicesAddress: !isInvoicesCheck }));
  }, [isInvoicesCheck, setConfig]);

  // -------------------- mailling --------------------

  useEffect(() => {
    let place = new Place({ sameAs: false });
    if (isMaillingCheck) place = { ...stepValues.main, sameAs: true };
    else if (config.maillingAddress) place = { ...stepValues.mailling, sameAs: false };
    setFieldValue('addresses.mailling', place);
  }, [isMaillingCheck, setFieldValue, stepValues.main]);

  useEffect(() => {
    setConfig((c) => ({ ...c, maillingAddress: !isMaillingCheck }));
  }, [isMaillingCheck, setConfig]);

  return (
    <>
      <Block noInteract={viewMode}>
        <FieldHeading size="l" mb style={{ marginBottom: '-20px' }}>
          Adres email <span className="sub-heading"> (na wskazany adres zostanie wysłana umowa do podpisu)</span>
        </FieldHeading>
        <FlexFields cols={3}>
          <div>
            <Input
              disabled={viewMode}
              name="addresses.email"
              type="text"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue('addresses.email', values.addresses.email.trim());
              }}
              label=""
              value={values.addresses?.email || ''}
              error={touched.addresses?.email && errors.addresses?.email}
            />
            <ErrorMessage component={ErrorLabel} name="addresses.email" />
          </div>
        </FlexFields>
      </Block>
      <Block noInteract={viewMode}>
        <FieldHeading mb size="l">
          Adres siedziby
        </FieldHeading>
        {placeFieldsRender('addresses', 'main', bag, true)}
      </Block>
      <Block noInteract={viewMode}>
        <AlignCheckbox>
          <FieldHeading mb size="l">
            Adres do faktur
          </FieldHeading>
          <Checkbox
            disabled={viewMode || config.isGusEmpty}
            style={{ marginBottom: '20px' }}
            name="isInvoicesCheck"
            onChange={(e) => setIsInvoicesCheck(e.target.checked)}
            onBlur={handleBlur}
            label="Taki sam jak siedziby"
            value={isInvoicesCheck}
            checked={isInvoicesCheck}
          />
        </AlignCheckbox>
        {!isInvoicesCheck && placeFieldsRender('addresses', 'invoices', bag, viewMode)}
      </Block>
      <Block noInteract={viewMode}>
        <AlignCheckbox>
          <FieldHeading mb size="l">
            Adres do korespondencji
          </FieldHeading>
          <Checkbox
            disabled={viewMode || config.isGusEmpty}
            style={{ marginBottom: '20px' }}
            name="isMaillingCheck"
            onChange={(e) => setIsMaillingCheck(e.target.checked)}
            onBlur={handleBlur}
            label="Taki sam jak siedziby"
            value={isMaillingCheck}
            checked={isMaillingCheck}
          />
        </AlignCheckbox>
        {!isMaillingCheck && placeFieldsRender('addresses', 'mailling', bag, viewMode)}
      </Block>
      {debugMode && (
        <>
          <Block>
            <div>
              <pre>Version: {JSON.stringify(values.version || '???', null, 4)}</pre>
              <pre>Version Number: {JSON.stringify(values.versionNumber || '???', null, 4)}</pre>
            </div>
          </Block>
          <Block>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <pre>Values: {JSON.stringify(stepValues, null, 4)}</pre>
              <pre>Errors: {JSON.stringify(errors, null, 4)}</pre>
              <pre>Touched: {JSON.stringify(touched, null, 4)}</pre>
            </div>
          </Block>
        </>
      )}
    </>
  );
};

LeadMerchantStep1.defaultProps = {
  config: {},
  values: {},
  errors: {},
  touched: {},
  validateForm: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  setFieldValue: () => {},
  setConfig: () => {},
  debugMode: false,
};

LeadMerchantStep1.propTypes = {
  config: PropTypes.instanceOf(Object),
  values: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  validateForm: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setConfig: PropTypes.func,
  viewMode: PropTypes.bool.isRequired,
  debugMode: PropTypes.bool,
};

export default LeadMerchantStep1;
