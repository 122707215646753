import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { Block } from 'components/UI/Structure';
import { FieldHeading } from 'components/forms/FormComponents';
import ErrorLabel from 'components/UI/ErrorLabel';
import Radio from 'components/UI/Radio';
import styled from 'styled-components';
import { breakpoints, colors, font } from 'theme/styles';
import Checkbox from 'components/UI/Checkbox';
import Textarea from 'components/UI/Textarea';
import Input from 'components/UI/Input';
import initialValues, { termsWithoutPbg } from './others/initialValues';

const displayValue = (val) => parseFloat(val).toFixed(2).replace('.', ',');

const decimals = (val) => Number(val).toFixed(2);

const LeadMerchantStep3 = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  validateForm,
  viewMode,
  debugMode,
  config,
  setConfig,
}) => {
  const { terms: stepValues } = values;
  const { terms: stepTouched = {} } = touched;
  const { terms: stepErrors = {} } = errors;
  // const [disablePWOBOption, setDisablePWOBOption] = useState(false);
  // const disableIndividuals = values.settlements.pbgPwob2 > 0;
  // const disableIndividuals = values.settlements.pbg === 1;
  const promotionPicked = values.settlements.pbgPwobOptions === 1 || values.settlements.generalPromotion === 1;
  const disableIndividuals = promotionPicked;

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    // Jeżeli jakiekolwiek warunki indywidualne były ustawione to niczego nie zmieniamy
    if (
      values.terms.commission.individualTermsFee ||
      values.terms.commission.individualTermsFixedFee ||
      values.terms.margin.individualTermsMargin ||
      values.terms.individualTermsOthers ||
      values.terms.individualTerminalPinPad ||
      values.terms.individualTerminalStationary ||
      values.terms.individualTerminalMobile
    )
      setConfig((c) => ({ ...c, blockTerms: true }));
    else {
      setConfig((c) => ({ ...c, blockTerms: false }));
    }
  }, [values.terms, setConfig]);

  // Nadpisuje wartości do domyslnych jeżeli ustawiono pwob (zapamiętuje jedynie model rozliczenia)
  useEffect(() => {
    // Jeżeli confgi z jakiegoś powodu ma propke to ignorujemy wszelkie wymyslne kombinacje z cenami i warunkami do nich
    if (config.dontMessWithValues) return;

    // Jeżeli pbg === NIE (value = 2) to nic nie robimy
    // Idź dalej tylko jeżeli PBG jest TAK oraz Promocja jest TAK
    // if (values.settlements.pbg !== 1 || values.settlements.pbgPwob2 !== 1) return;

    const pickedModel = values.terms.model;
    const pickedPbg = values.settlements.pbg === 1;

    // setFieldValue('terms', {
    //   ...initialValues.terms,
    //   // DOSTEPNA TYLKO PROWIZJA
    //   model: pickedModel,
    //   commission: {
    //     ...(pickedPbg ? initialValues.terms.commission : termsWithoutPbg.commission),
    //     fixedFee: 0,
    //   },
    //   margin: {
    //     ...(pickedPbg ? initialValues.terms.margin : termsWithoutPbg.margin),
    //   },
    //   terminalPinPad: 29.0,
    //   terminalStationary: 29.0,
    //   terminalMobile: 29.0,
    // });

    // Nie aktualizuj jeżeli zapisano indywidualne stawki oraz czekaj na render w którym config.blockTerms ma określoną wartość
    if ((config.blockTerms === undefined || config.blockTerms) && !promotionPicked) {
      return;
    } else if (values.settlements.pbg === 1 && values.settlements.pbgPwob2 === 1) {
      setFieldValue('terms', {
        ...initialValues.terms,
        // DOSTEPNA TYLKO PROWIZJA
        model: 'commission',
        commission: {
          ...initialValues.terms.commission,
          fixedFee: 0,
        },
        margin: {
          ...(pickedPbg ? initialValues.terms.margin : termsWithoutPbg.margin),
        },
        // terminalPinPad: 29.0,
        terminalStationary: 29.0,
        terminalMobile: 29.0,
      });
    } else {
      setFieldValue('terms', {
        ...initialValues.terms,
        model: pickedModel,
        commission: {
          ...(pickedPbg ? initialValues.terms.commission : termsWithoutPbg.commission),
        },
        margin: {
          ...(pickedPbg ? initialValues.terms.margin : termsWithoutPbg.margin),
        },
        terminalPinPad: pickedPbg ? initialValues.terms.terminalPinPad : termsWithoutPbg.terminalPinPad,
        terminalStationary: pickedPbg ? initialValues.terms.terminalStationary : termsWithoutPbg.terminalStationary,
        terminalMobile: pickedPbg ? initialValues.terms.terminalMobile : termsWithoutPbg.terminalMobile,
      });
    }
  }, [
    setFieldValue,
    values.terms.model,
    values.settlements.pbg,
    values.settlements.pbgPwob2,
    config.dontMessWithValues,
    config.blockTerms,
    promotionPicked,
  ]);

  const showHint = () => {
    if (
      stepValues.individualTermsOthers ||
      stepValues.individualTerminalPinPad ||
      stepValues.individualTerminalStationary ||
      stepValues.individualTerminalMobile
    )
      return true;
    if (
      stepValues.model === 'commission' &&
      (stepValues.commission.individualTermsFee || stepValues.commission.individualTermsFixedFee)
    )
      return true;
    if (
      stepValues.model === 'margin' &&
      (stepValues.margin.individualTermsFixedFee || stepValues.margin.individualTermsMarginFixedFee)
    )
      return true;
    return false;
  };

  return (
    <>
      <Block noInteract={viewMode}>
        <Row style={{ marginBottom: '2rem' }}>
          <FieldHeading mb size="m">
            Model rozliczenia:
          </FieldHeading>
          <Radio
            disabled={viewMode}
            name="terms.model"
            value={stepValues.model}
            onChange={() => setFieldValue('terms.model', 'commission')}
            label="Prowizja"
            checked={stepValues.model === 'commission'}
          />
          <Radio
            disabled={viewMode}
            name="terms.model"
            value={stepValues.model}
            onChange={() => setFieldValue('terms.model', 'margin')}
            label="Marża stała"
            checked={stepValues.model === 'margin'}
          />
        </Row>
        {stepValues.model === 'commission' && (
          <>
            <Row>
              <FieldHeading mb size="m">
                Wartość prowizji:
              </FieldHeading>
              <Value>{displayValue(stepValues.commission?.fee)} %</Value>
              <Checkbox
                disabled={viewMode || disableIndividuals}
                name="individualTermsFee"
                onChange={() => {
                  setFieldValue('terms.commission.individualTermsFee', !stepValues.commission.individualTermsFee);
                  setFieldValue(
                    'terms.commission.fee',
                    values.settlements.pbg === 1 ? initialValues.terms.commission.fee : termsWithoutPbg.commission.fee,
                  );
                }}
                onBlur={handleBlur}
                label="Warunki indywidualne"
                value={stepValues.commission.individualTermsFee || false}
                checked={stepValues.commission.individualTermsFee}
              />
              <Input
                disabled={viewMode || !stepValues.commission.individualTermsFee}
                step=".01"
                min={0}
                name="terms.commission.fee"
                type="number"
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  setFieldValue('terms.commission.fee', Number(e.target.value).toFixed(2));
                }}
                label=""
                value={decimals(stepValues.commission?.fee) || 0}
                error={stepTouched.commission?.fee && stepErrors.commission?.fee}
              />
              {/* <ErrorMessage component={ErrorLabel} name="terms.commission.fee" /> */}
            </Row>
            <Row style={{ marginBottom: '2rem' }}>
              <FieldHeading noMargin size="m">
                Opłata stała:
              </FieldHeading>
              <Value>{displayValue(stepValues.commission?.fixedFee)} zł</Value>
              <Checkbox
                disabled={viewMode || disableIndividuals}
                name="individualTermsFixedFee"
                onChange={() => {
                  setFieldValue(
                    'terms.commission.individualTermsFixedFee',
                    !stepValues.commission.individualTermsFixedFee,
                  );
                  setFieldValue(
                    'terms.commission.fixedFee',
                    values.settlements.pbg === 1
                      ? initialValues.terms.commission.fixedFee
                      : termsWithoutPbg.commission.fixedFee,
                  );
                }}
                onBlur={handleBlur}
                label="Warunki indywidualne"
                value={stepValues.commission.individualTermsFixedFee || false}
                checked={stepValues.commission.individualTermsFixedFee}
              />
              <Input
                disabled={viewMode || !stepValues.commission.individualTermsFixedFee}
                step=".01"
                min={0}
                name="terms.commission.fixedFee"
                type="number"
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  setFieldValue('terms.commission.fixedFee', Number(e.target.value).toFixed(2));
                }}
                label=""
                value={decimals(stepValues.commission?.fixedFee) || 0}
                error={stepTouched.commission?.fixedFee && stepErrors.commission?.fixedFee}
              />
            </Row>
          </>
        )}
        {stepValues.model === 'margin' && (
          <>
            <Row style={{ marginBottom: values.settlements.pbg === 1 ? '0' : '2rem' }}>
              <FieldHeading noMargin size="m">
                Marża stała:
              </FieldHeading>
              <Value>{displayValue(stepValues.margin?.value)} %</Value>
              <Checkbox
                disabled={viewMode || disableIndividuals}
                name="individualTermsFixedFee"
                onChange={() => {
                  setFieldValue('terms.margin.individualTermsFixedFee', !stepValues.margin.individualTermsFixedFee);
                  setFieldValue(
                    'terms.margin.value',
                    values.settlements.pbg === 1 ? initialValues.terms.margin.value : termsWithoutPbg.margin.value,
                  );
                }}
                onBlur={handleBlur}
                label="Warunki indywidualne"
                value={stepValues.margin.individualTermsFixedFee || false}
                checked={stepValues.margin.individualTermsFixedFee}
              />
              <Input
                disabled={viewMode || !stepValues.margin.individualTermsFixedFee}
                step=".01"
                min={0}
                name="terms.margin.value"
                type="number"
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  setFieldValue('terms.margin.value', Number(e.target.value).toFixed(2));
                }}
                label=""
                value={decimals(stepValues.margin?.value) || 0}
                error={stepTouched.margin?.value && stepErrors.margin?.value}
              />
            </Row>
            {values.settlements.pbg === 1 && (
              <Row style={{ marginBottom: '2rem' }}>
                <FieldHeading noMargin size="m">
                  Opłata stała:
                </FieldHeading>
                <Value>{displayValue(stepValues.margin?.marginFixedFee)} zł</Value>
                <Checkbox
                  disabled={viewMode || disableIndividuals}
                  name="individualTermsMarginFixedFee"
                  onChange={() => {
                    setFieldValue(
                      'terms.margin.individualTermsMarginFixedFee',
                      !stepValues.margin.individualTermsMarginFixedFee,
                    );
                    setFieldValue(
                      'terms.margin.marginFixedFee',
                      values.settlements.pbg === 1
                        ? initialValues.terms.margin.marginFixedFee
                        : termsWithoutPbg.margin.marginFixedFee,
                    );
                  }}
                  onBlur={handleBlur}
                  label="Warunki indywidualne"
                  value={stepValues.margin.individualTermsMarginFixedFee || false}
                  checked={stepValues.margin.individualTermsMarginFixedFee}
                />
                <Input
                  disabled={viewMode || !stepValues.margin.individualTermsMarginFixedFee}
                  step=".01"
                  min={0}
                  name="terms.margin.marginFixedFee"
                  type="number"
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    setFieldValue('terms.margin.marginFixedFee', Number(e.target.value).toFixed(2));
                  }}
                  label=""
                  value={decimals(stepValues.margin?.marginFixedFee) || 0}
                  error={stepTouched.margin?.marginFixedFee && stepErrors.margin?.marginFixedFee}
                />
              </Row>
            )}
          </>
        )}
        {stepValues.terminalPinPad !== 0 && (
          <Row>
            <FieldHeading mb size="m">
              Najem PinPad:
            </FieldHeading>
            <Value>{displayValue(stepValues.terminalPinPad)} zł</Value>
            <Checkbox
              disabled={viewMode || disableIndividuals}
              name="individualTerminalPinPad"
              onChange={() => {
                setFieldValue('terms.individualTerminalPinPad', !stepValues.individualTerminalPinPad);
                setFieldValue(
                  'terms.terminalPinPad',
                  values.settlements.pbg === 1 ? initialValues.terms.terminalPinPad : termsWithoutPbg.terminalPinPad,
                );
              }}
              onBlur={handleBlur}
              label="Warunki indywidualne"
              value={stepValues.individualTerminalPinPad || false}
              checked={stepValues.individualTerminalPinPad}
            />
            <Input
              disabled={viewMode || !stepValues.individualTerminalPinPad}
              step=".01"
              min={0}
              name="terms.terminalPinPad"
              type="number"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue('terms.terminalPinPad', Number(e.target.value).toFixed(2));
              }}
              label=""
              value={decimals(stepValues.terminalPinPad) || 0}
              error={stepTouched.terminalPinPad && stepErrors.terminalPinPad}
            />
            {/* <ErrorMessage component={ErrorLabel} name="terms.terminalPinPad" /> */}
          </Row>
        )}
        <Row>
          <FieldHeading mb size="m">
            Najem terminal stacjonarny:
          </FieldHeading>
          <Value>{displayValue(stepValues.terminalStationary)} zł</Value>
          <Checkbox
            disabled={viewMode || disableIndividuals}
            name="individualTerminalStationary"
            onChange={() => {
              setFieldValue('terms.individualTerminalStationary', !stepValues.individualTerminalStationary);
              setFieldValue(
                'terms.terminalStationary',
                values.settlements.pbg === 1
                  ? initialValues.terms.terminalStationary
                  : termsWithoutPbg.terminalStationary,
              );
            }}
            onBlur={handleBlur}
            label="Warunki indywidualne"
            value={stepValues.individualTerminalStationary || false}
            checked={stepValues.individualTerminalStationary}
          />
          <Input
            disabled={viewMode || !stepValues.individualTerminalStationary}
            step=".01"
            min={0}
            name="terms.terminalStationary"
            type="number"
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              setFieldValue('terms.terminalStationary', Number(e.target.value).toFixed(2));
            }}
            label=""
            value={decimals(stepValues.terminalStationary) || 0}
            error={stepTouched.terminalStationary && stepErrors.terminalStationary}
          />
          {/* <ErrorMessage component={ErrorLabel} name="terms.terminalStationary" /> */}
        </Row>
        <Row>
          <FieldHeading mb size="m">
            Najem terminal przenośny:
          </FieldHeading>
          <Value>{displayValue(stepValues.terminalMobile)} zł</Value>
          <Checkbox
            disabled={viewMode || disableIndividuals}
            name="individualTerminalMobile"
            onChange={() => {
              setFieldValue('terms.individualTerminalMobile', !stepValues.individualTerminalMobile);
              setFieldValue(
                'terms.terminalMobile',
                values.settlements.pbg === 1 ? initialValues.terms.terminalMobile : termsWithoutPbg.terminalMobile,
              );
            }}
            onBlur={handleBlur}
            label="Warunki indywidualne"
            value={stepValues.individualTerminalMobile || false}
            checked={stepValues.individualTerminalMobile}
          />
          <Input
            disabled={viewMode || !stepValues.individualTerminalMobile}
            step=".01"
            min={0}
            name="terms.terminalMobile"
            type="number"
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              setFieldValue('terms.terminalMobile', Number(e.target.value).toFixed(2));
            }}
            label=""
            value={decimals(stepValues.terminalMobile) || 0}
            error={stepTouched.terminalMobile && stepErrors.terminalMobile}
          />
          {/* <ErrorMessage component={ErrorLabel} name="terms.terminalMobile" /> */}
        </Row>
        <OtherTerms style={{ marginTop: '3rem' }}>
          <Checkbox
            disabled={viewMode || disableIndividuals}
            style={{ marginBottom: '20px' }}
            name="individualTermsOthers"
            onChange={() => setFieldValue('terms.individualTermsOthers', !stepValues.individualTermsOthers)}
            onBlur={handleBlur}
            label="Pozostałe warunki indywidualne"
            value={stepValues.individualTermsOthers || false}
            checked={stepValues.individualTermsOthers}
          />
        </OtherTerms>
        {stepValues.individualTermsOthers && (
          <div>
            <Textarea
              disabled={viewMode}
              name="terms.others"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue('terms.others', stepValues.others.trim());
              }}
              width="100%"
              locked
              height="92px"
              value={stepValues.others || ''}
              error={stepTouched.others && stepErrors.othersu}
            />
            <ErrorMessage component={ErrorLabel} name="terms.others" />
          </div>
        )}
        {promotionPicked && (
          <FieldHeading size="s">
            <span className="color-red">Warunki handlowe zgodne z wybraną promocją</span>
          </FieldHeading>
        )}
        {showHint() && (
          <FieldHeading size="s">
            <span className="color-red">
              Formularz zawierający indywidualne warunki handlowe zostanie przekazany do akceptacji opiekuna.
            </span>
          </FieldHeading>
        )}
      </Block>
      {debugMode && (
        <>
          <Block>
            <div>
              <pre>Version: {JSON.stringify(values.version || '???', null, 4)}</pre>
              <pre>Version Number: {JSON.stringify(values.versionNumber || '???', null, 4)}</pre>
            </div>
          </Block>
          <Block>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <pre>Values: {JSON.stringify(stepValues, null, 4)}</pre>
              <pre>Errors: {JSON.stringify(errors, null, 4)}</pre>
              <pre>Touched: {JSON.stringify(touched, null, 4)}</pre>
            </div>
          </Block>
        </>
      )}
    </>
  );
};

LeadMerchantStep3.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  config: {},
  handleChange: () => {},
  handleBlur: () => {},
  setFieldValue: () => {},
  validateForm: () => {},
  setConfig: () => {},
  debugMode: false,
};

LeadMerchantStep3.propTypes = {
  values: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  validateForm: PropTypes.func,
  setConfig: PropTypes.func,
  viewMode: PropTypes.bool.isRequired,
  debugMode: PropTypes.bool,
};

export default LeadMerchantStep3;

const Value = styled.div`
  display: inline-block;
  border: 1.5px solid ${colors.orange};
  padding: 4px 2px;
  border-radius: 2px;
  min-width: 8rem;
  text-align: center;
`;

export const Row = styled.div`
  margin-bottom: 2rem;

  & > * {
    margin-bottom: 1rem;
  }

  @media (min-width: ${breakpoints.mobileSmall}) {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 0;
    gap: 1rem 2.5rem;

    input {
      width: 60px;
      text-align: center;
    }

    & > * {
      margin: 0;
      width: auto;
    }

    ${FieldHeading} {
      min-width: 19rem;
    }
  }
`;

const OtherTerms = styled.div`
  label {
    font-weight: ${font.weight.semibold};
  }
`;
