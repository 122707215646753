import * as types from './types';

const initialState = {
  info: {
    data: {},
    isLoading: false,
    error: '',
  },
  history: {
    data: [],
    isLoading: false,
    error: '',
  },
  notes: {
    data: [],
    isLoading: false,
    error: '',
  },
  docs: {
    data: [],
    isLoading: false,
    error: '',
  },
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLIENT_INFO_REQUEST:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: true,
        },
      };
    case types.GET_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_CLIENT_INFO_FAILURE:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_CLIENT_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: true,
        },
      };
    case types.GET_CLIENT_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_CLIENT_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_CLIENT_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: true,
        },
      };
    case types.GET_CLIENT_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_CLIENT_NOTES_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_CLIENT_DOCS_REQUEST:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: true,
        },
      };
    case types.GET_CLIENT_DOCS_SUCCESS:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_CLIENT_DOCS_FAILURE:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    default:
      return state;
  }
};

export default clientReducer;
