import * as types from './types';

const initialState = {
  data: [],
  count: 0,
  dataWidget: [],
  isLoading: false,
  error: null,
  isOpen: false,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case types.SHOW:
      return {
        ...state,
        isOpen: true,
      };
    case types.HIDE:
      return {
        ...state,
        isOpen: false,
      };
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        data: action.payload.notifications,
        count: action.payload.count,
      };
    case types.SET_NOTIFICATIONS_WIDGET:
      return {
        ...state,
        dataWidget: action.payload,
      };
    case types.LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    case types.ADD_NOTIFICATION:
      return {
        ...state,
        data: [action.payload, ...state.data],
        count: state.count + 1,
      };
    case types.ADD_NOTIFICATION_WIDGET:
      return {
        ...state,
        dataWidget: [action.payload, ...state.dataWidget],
      };
    case types.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        data: [],
        count: 0,
      };
      case types.CLEAR_NOTIFICATIONS_WIDGET:
        return {
          ...state,
          dataWidget: [],
        };
    case types.MARK_AS_READED_ALL:
      return {
        ...state,
        data: state.data.map(n => ({
          ...n,
          isSeen: new Date().toISOString(),
        })),
      };
    case types.MARK_AS_READED_ALL_WIDGET:
      return {
        ...state,
        // dataWidget: state.dataWidget.map(n => ({
        //   ...n,
        //   isSeen: new Date().toISOString(),
        // })),
        dataWidget: [],
      };
    case types.MARK_AS_READED_ONE:
      return {
        ...state,
        data: state.data.map(n => {
          return n.id === action.payload ? { ...n, isSeen: new Date().toISOString() } : n;
        }),
        dataWidget: state.dataWidget.map(n => {
          return n.id === action.payload ? { ...n, isSeen: new Date().toISOString() } : n;
        }),
      };
    case types.CLEAR_NOTIFICATIONS_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default notificationsReducer;
