import * as types from './types';

const initialState = {
  materials: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  currentMaterial: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadProgress: null,
};

const marketingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MARKETING_MATERIAL_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
      };
    case types.GET_MARKETING_MATERIALS_REQUEST:
      return {
        ...state,
        materials: {
          ...state.materials,
          isLoading: true,
        },
      };
    case types.GET_MARKETING_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: {
          ...state.materials,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_MARKETING_MATERIALS_FAILURE:
      return {
        ...state,
        materials: {
          ...state.materials,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_MARKETING_MATERIAL_REQUEST:
      return {
        ...state,
        currentMaterial: {
          ...state.currentMaterial,
          isLoading: true,
        },
      };
    case types.GET_MARKETING_MATERIAL_SUCCESS:
      return {
        ...state,
        currentMaterial: {
          ...state.currentMaterial,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_MARKETING_MATERIAL_FAILURE:
      return {
        ...state,
        currentMaterial: {
          ...state.currentMaterial,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };
    case types.SET_CURRENT_MARKETING_MATERIAL:
      return {
        ...state,
        currentMaterial: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case types.CLEAR_CURRENT_MARKETING_MATERIAL:
      return {
        ...state,
        currentMaterial: {
          data: null,
          isLoading: false,
          error: null,
        },
      };
    case types.CLEAR_MARKETING:
      return initialState;
    default:
      return state;
  }
};

export default marketingReducer;
