/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import { displayDate } from 'utils/helpers';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';
import NoData from 'components/UI/NoData';
import SimpleButton from 'components/UI/SimpleButton';

const TrainingMaterialsTable = ({ data, headers, offset, handleEdit, handleDelete, handleDownload, handleVimeo }) => (
  <TableWrapper>
    {data.length > 0 ? (
      <table>
        <colgroup>
          <col className="lp" />
          {headers.map((header) => {
            switch (header.property) {
              case 'createdAt':
              case 'updatedAt':
                return <col key={header.property} className={`${header.property} wide`} />;
              default:
                return <col key={header.property} className={header.property} />;
            }
          })}
          {(handleEdit || handleDelete) && <col className="actions" />}
        </colgroup>
        <thead>
          <tr>
            <th>LP</th>
            {headers.map((header) => (
              <th key={header.property}>{header.displayName}</th>
            ))}
            {(handleEdit || handleDelete) && <th key="edit">Akcje</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const { resources = [] } = item;
            const isVimeo = resources.some((r) => r.resourceType === 'VIDEO_URL');
            const isFile = resources.filter((r) => r.resourceType !== 'VIDEO_URL').length > 0;
            return (
              <tr key={item.id + index}>
                <td>{offset + index + 1}</td>
                {headers.map((header) => {
                  const uniqueKey = item.id + header.property;
                  switch (header.property) {
                    case 'createdAt':
                    case 'updatedAt':
                      return (
                        <td key={uniqueKey}>
                          {item[header.property] ? displayDate(item[header.property]) : displayDate(item.createdAt)}
                        </td>
                      );
                    case 'active':
                      return (
                        <td key={uniqueKey}>
                          {item.active === '1' ? <FontAwesomeIcon title="Aktywny" icon="check" /> : ''}
                        </td>
                      );
                    case 'download':
                      if (!handleDownload || !isFile) return <td key={uniqueKey} />;
                      return (
                        <td key={uniqueKey}>
                          <SimpleButton
                            disabled={item.active === '0'}
                            type="button"
                            onClick={() => handleDownload(item)}
                          >
                            Pobierz
                          </SimpleButton>
                        </td>
                      );
                    case 'vimeo':
                      if (!handleVimeo || !isVimeo) return <td key={uniqueKey} />;
                      return (
                        <td key={uniqueKey}>
                          <SimpleButton disabled={item.active === '0'} type="button" onClick={() => handleVimeo(item)}>
                            Obejrzyj
                          </SimpleButton>
                        </td>
                      );
                    default:
                      if (header.subProperty) {
                        return (
                          <td key={uniqueKey}>
                            {item[header.property] ? item[header.property][header.subProperty] : ''}
                          </td>
                        );
                      }
                      return <td key={uniqueKey}>{item[header.property]}</td>;
                  }
                })}
                {(handleEdit || handleDelete) && (
                  <td key="edit" style={{ width: '110px' }}>
                    <span className="icons">
                      {handleEdit && <FontAwesomeIcon title="Edytuj" icon="edit" onClick={() => handleEdit(item.id)} />}
                      {handleDelete && (
                        <FontAwesomeIcon title="Usuń" icon="trash-alt" onClick={() => handleDelete(item)} />
                      )}
                    </span>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : (
      <NoData />
    )}
  </TableWrapper>
);

TrainingMaterialsTable.defaultProps = {
  handleEdit: false,
  handleDelete: false,
  handleDownload: false,
  handleVimeo: false,
};

TrainingMaterialsTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleDownload: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleVimeo: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default TrainingMaterialsTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 720px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;

    @media (min-width: 940px) {
      col.download,
      col.vimeo {
        width: 100px;
      }
    }

    tr {
      th,
      td {
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 35px;
        &:nth-of-type(even) {
          background-color: ${colors.white};
        }
      }

      td {
        font-size: ${font.size.s};
        text-transform: none;
        color: ${colors.black};

        & > svg {
          color: ${colors.orange};
        }
        & > .icons {
          color: ${colors.orange};
          font-size: ${font.size.m};
          svg {
            cursor: pointer;
            margin: 0 7px;
          }
        }
      }
    }
  }
`;
