import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSystemMessages } from 'redux/chat';
import { breakpoints } from 'theme/styles';
import { TopSection } from 'components/layout/Tables/TableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import SidePanel from 'components/layout/SidePanel';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import ErrorPopup from 'components/layout/ErrorPopup';
import AddSystemMessage from 'components/forms/Admin/AddSystemMessage';
import SystemMEssagesTable from 'components/layout/Tables/SystemMessagesTable';

const headers = [
  { displayName: 'Tytuł', property: 'subject' },
  { displayName: 'Nadawca', property: 'fromUser' },
  { displayName: 'Grupa docelowa', property: 'group' },
  { displayName: 'Wysłana', property: 'createdAt' },
];

const AdminChallengesPage = ({ getSystemMessages, systemMessages, isLoadingMessages, errorMessages }) => {
  const [isNewMessageVisible, setNewMessageVisible] = useState(false);

  useEffect(() => {
    getSystemMessages();
  }, [getSystemMessages]);

  const toggleNewMessagePanel = () => setNewMessageVisible(state => !state);

  return (
    <PageTemplate>
      <StyledTopSection>
        <Heading size="xxl">Powiadomienia systemowe</Heading>
        <Button onClick={toggleNewMessagePanel}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Wyślij
        </Button>
      </StyledTopSection>
      {!isLoadingMessages && errorMessages && <ErrorPopup fixed>{errorMessages.message}</ErrorPopup>}
      {isLoadingMessages ? <Loading /> : <SystemMEssagesTable data={systemMessages} headers={headers} offset={0} />}
      <SidePanel toggleClose={toggleNewMessagePanel} isVisible={isNewMessageVisible}>
        <AddSystemMessage refetchMessages={getSystemMessages} closePanel={toggleNewMessagePanel} />
      </SidePanel>
    </PageTemplate>
  );
};

AdminChallengesPage.defaultProps = {};

AdminChallengesPage.propTypes = {
  getSystemMessages: PropTypes.func.isRequired,
  systemMessages: PropTypes.instanceOf(Array).isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
};

const mapStateToProps = state => ({
  systemMessages: state.chat.systemMessages.data,
  isLoadingMessages: state.chat.systemMessages.isLoading,
  errorMessages: state.chat.systemMessages.error,
});

const mapDispatchToProps = {
  getSystemMessages,
};

const StyledTopSection = styled(TopSection)`
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.mobileSmall}) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdminChallengesPage);
