/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { assignMessageToUser, sortByCreatedAt } from 'utils/helpers';
import { breakpoints, colors } from 'theme/styles';
import {
  setActiveConversationMessages,
  setActiveConversation,
  setConversationsError,
  setConversationsLoading,
  addConversation,
  setPanelActive,
  setMessagesLoading,
  clearActiveConversation,
  clearPreviewConversations,
  setChatMode,
} from 'redux/chat';
import { routes } from 'routes';
import { socket } from 'services/socket';
import { MessagesListTypes, chatModes } from 'config/constants';
import { Label, Text } from 'components/layout/Client/ClientInfo';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import Chat from 'components/UI/chat/Chat';
import Heading from 'components/UI/Heading';
import ErrorPopup from 'components/layout/ErrorPopup';

const createNoAccesMessage = message => [
  {
    type: 'no_access',
    id: '1',
    userId: '1',
    message: message || 'Brak dostepu.',
    createdAt: moment().toString(),
  },
];

const getConversationName = (conv, myId = false, history) => {
  switch (conv.type) {
    case 'LEAD':
      // return <div>Lead {conv.lead.frontNumLead} - {conv.lead.companyName} / {conv.lead.nip}</div>;
      return (
        <InfoTable>
          <div>
            <Label>Lead</Label>
            <TextLink onClick={() => history.push(routes.leadDetails.replace(':id', conv.lead.id))}>
              {conv.lead.frontNumLead}
            </TextLink>
          </div>
          <div>
            <Label>Nazwa firmy</Label>
            <Text>{conv.lead.companyName}</Text>
          </div>
          <div>
            <Label>NIP</Label>
            <Text>{conv.lead.nip}</Text>
          </div>
          <div>
            <Label>Partner</Label>
            <Text>{conv.lead?.user?.fullName || '-'}</Text>
          </div>
          <div>
            <Label>Przedstawiciel handlowy</Label>
            <Text>{conv.lead?.queue?.user?.fullName || '-'}</Text>
          </div>
        </InfoTable>
      );
    case 'PATRON':
      let output = 'Rozmowa z opiekunem';
      const otherGuy = conv.users?.find(u => u.id !== myId) || null;
      const me = conv.users?.find(u => u.id === myId) || null;

      if (otherGuy) output = `Rozmowa z opiekunem - ${otherGuy.fullName}`;
      if (
        me &&
        otherGuy &&
        ['admin', 'dsp', 'dr', 'merchantPep'].includes(me.role?.slug) &&
        ['partner'].includes(otherGuy.role.slug)
      )
        output = (
          <InfoTable>
            <div>
              <Label>Rozmowa z opiekunem</Label>
              <Text>{otherGuy.fullName}</Text>
            </div>
            <div>
              <Label>Partner</Label>
              <Text>{otherGuy.company?.name || '-'}</Text>
            </div>
          </InfoTable>
        );
      return output;

    default:
      return 'Rozmowa z ...';
  }
};

const MessagesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const prevConversationRef = useRef();

  const [error, setError] = useState('');

  // Query
  const queryObj = queryString.parse(history.location.search);
  const { type, conversation: urlId, newLeadConversationId } = queryObj;
  const newPatronConversation = queryObj.newPatronConversation === null;

  // Redux
  const isSocketConnected = useSelector(s => s.socket.connected);
  const myId = useSelector(s => s.user.data.id);
  const isPanelActive = useSelector(s => s.chat.isPanelActive);
  const conversations = useSelector(s => s.chat.allConversations.data);
  const archivedConversations = useSelector(s => s.chat.allArchivedConversations.data);
  const chatMode = useSelector(s => s.chat.chatMode);
  const conversationsInitialLoaded = useSelector(s => s.chat.allConversations.initialLoaded);
  const activeConversation = useSelector(s => s.chat.activeConversation.conversation);
  const activeConversationMessages = useSelector(s => s.chat.activeConversation.messages);

  const getConversation = useCallback(
    conversationId => {
      if (!isSocketConnected || !type) return;

      dispatch(setConversationsLoading(true));
      socket.emit(
        `chat:${type}:conversation`,
        {
          conversationId,
        },
        response => {
          dispatch(setConversationsLoading(false));
          if (response && response.data) {
            dispatch(addConversation(response.data));
            dispatch(setActiveConversation(response.data));
          } else dispatch(setConversationsError(response.error.name || 'Wystąpił bląd'));
        },
      );
    },
    [dispatch, isSocketConnected, type],
  );

  useEffect(() => {
    dispatch(setChatMode(chatModes.normal))

    return () => {
      dispatch(clearActiveConversation());
      dispatch(clearPreviewConversations(myId));
      dispatch(setPanelActive(false));
    };
  }, [dispatch, myId]);

  // Get messages when activeConversation change
  useEffect(() => {
    if (!isSocketConnected || !myId || !activeConversation.id) return;

    // Leciały 2 requesty, to jest dodatkowy check
    if (activeConversation.id !== urlId) return;

    dispatch(setMessagesLoading(true));
    socket.emit(`chat:${type}:messages`, { conversationId: activeConversation.id }, response => {
      dispatch(setMessagesLoading(false));

      // Jak jest git to response to  tablica wiadomości, a jak nie to jest to obiekt z błędem
      if (!Array.isArray(response) && !response.isOk)
        dispatch(setActiveConversationMessages(createNoAccesMessage(response?.error?.name)));
      else dispatch(setActiveConversationMessages(assignMessageToUser(activeConversation.users, response)));

      // dispatch(setActiveConversationMessages(assignMessageToUser(activeConversation.users, response)));
    });
  }, [activeConversation.id, activeConversation.users, type, isSocketConnected, dispatch, myId, urlId]);

  const updateLastSeen = useCallback(() => {
    if (prevConversationRef.current && prevConversationRef.current.urlId && prevConversationRef.current.type)
      socket.emit(
        `chat:${prevConversationRef.current.type}:update-last-seen-message`,
        { conversationId: prevConversationRef.current.urlId },
        () => {},
      );
  }, []);

  // W teorii to odpala się zawsze po zmianie active conv i wysyła do reducera info aby zaktualizował jego lokalne lastVisited dla podanego usera,
  // ma to na celu prawidłowe śledzenie przeczytanych wiadomości
  // useEffect(() => {
  //   return () => {
  //     if (activeConversation.id && myId) dispatch(updateLastSeendInDetails(myId, activeConversation.id));
  //   };
  // }, [dispatch, activeConversation.id, myId]);

  // Zaktualizuj lastSeen konwersacji która zostaje "opuszczona"
  useEffect(() => {
    prevConversationRef.current = {
      urlId,
      type,
    };
    window.addEventListener('beforeunload', updateLastSeen);
    return () => {
      window.removeEventListener('beforeunload', updateLastSeen);
      updateLastSeen();
    };
  }, [type, urlId, updateLastSeen]);

  // Sledzi urlId i jeżeli trzeba pobiera brakująca konwersacje / ustawia aktywną konwersacje
  // Tu jest coś nie do końca dobrze, czasami dodaje sie duplikat, ale zabezpieczono w reducerze
  useEffect(() => {
    if (!updateLastSeen || !urlId || !conversationsInitialLoaded) return;

    const conversation = conversations.find(c => c.id === urlId);

    if (!conversation) getConversation(urlId);
    else if (activeConversation.id !== conversation.id) dispatch(setActiveConversation(conversation));
  }, [
    urlId,
    conversations,
    activeConversation.id,
    dispatch,
    updateLastSeen,
    getConversation,
    conversationsInitialLoaded,
  ]);

  return (
    <PageTemplate>
      <Wrapper>
        {error && (
          <ErrorPopup>
            <Heading style={{ marginBottom: '1em' }}>Wystąpił błąd</Heading>
            <p>{error}</p>
            <div className="buttons">
              <Button
                onClick={() => {
                  setError('');
                }}
              >
                Ok
              </Button>
            </div>
          </ErrorPopup>
        )}
        <Chat>
          <ColLeft isActive={isPanelActive}>
            <Chat.ConversationsList
              conversations={chatMode === chatModes.archive ? archivedConversations : conversations}
            />
          </ColLeft>
          <ColRight isActive={isPanelActive}>
            {!newPatronConversation && !newLeadConversationId && activeConversation && (
              <Chat.MessagesList
                messages={activeConversationMessages.sort(sortByCreatedAt).reverse()}
                conversationName={getConversationName(activeConversation, myId, history)}
                conversationId={activeConversation.id}
                type={MessagesListTypes.active}
              />
            )}
            {!newPatronConversation && newLeadConversationId && (
              <Chat.MessagesList
                messages={[]}
                conversationName="Rozpocznij konwersacje"
                conversationId={newLeadConversationId}
                newConversationLeadId={newLeadConversationId}
                type={MessagesListTypes.newLead}
              />
            )}
            {!newLeadConversationId && newPatronConversation && (
              <Chat.MessagesList
                messages={[]}
                conversationName="Rozmowa z opiekunem"
                conversationId="bez znaczenia"
                newConversationLeadId="bez znaczenia"
                type={MessagesListTypes.newPatron}
              />
            )}
            {!newPatronConversation && !newLeadConversationId && !activeConversation && (
              <CenteredText onClick={() => dispatch(setPanelActive(true))}>
                <Heading size="xl">
                  <FontAwesomeIcon icon="arrow-left" />
                  <span>Wybierz konwersację</span>
                </Heading>
              </CenteredText>
            )}
          </ColRight>
        </Chat>
      </Wrapper>
    </PageTemplate>
  );
};

export default MessagesPage;

// styled components

const Wrapper = styled.div`
  height: 100%;
`;

const ColLeft = styled.div`
  width: 35%;
  /* flex: 0 1 30%;
  max-width: 30%; */
  padding: 2rem 2rem 0 2rem;
  border-right: 1px solid ${colors.blueLighter};
  backface-visibility: hidden;

  @media (max-width: ${breakpoints.header}) {
    width: 100%;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 1040;
    background: white;
    height: calc(100% - 80px);
    padding: 0 1.5rem 2rem;
    transform-style: preserve-3d;
    transform: ${({ isActive }) => (isActive ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 0.3s ease-out;
  }
`;

const ColRight = styled.div`
  flex: 1;
  /* flex: 0 1 70%;
  max-width: 70%; */
  padding: 2rem 2rem 0 2rem;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CenteredText = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    margin-right: 10px;
    color: ${colors.orange};
  }
`;

const InfoTable = styled.div`
  // TODO, mobile i overflow
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  grid-gap: 1rem;
`;

const TextLink = styled(Text)`
  cursor: pointer;
  color: ${colors.orange};

  &:hover {
    color: ${colors.red};
  }
`;

// let lastVisit = false;
// let newMessages = messages;

// if (activeConversation.details) {
//   const lastVisitsArr = JSON.parse(activeConversation.details).lastVisits || [];
//   const userLastVisit = lastVisitsArr.find(o => o.userId === myId);
//   if (userLastVisit) lastVisit = userLastVisit.lastSeen;
// }

// console.log(messages.length);
// console.log(initialMessagesLoaded);

// if (lastVisit) {
//   // Find first message that is writtern after my last visit on this conversation, ommit my own messages
//   const firstUnreaded = messages.findIndex(msg =>
//     msg.user.id !== myId ? new Date(msg.createdAt) > new Date('2022-06-03T11:08:34.467Z') : false,
//   );
//   if (firstUnreaded !== -1) {
//     newMessages = insertInArray(messages, firstUnreaded, {
//       type: 'NEW_MESSAGES',
//       user: {
//         id: 'NONE',
//       },
//     });
//   }
// }
