export const GET_LEAD_ONBOARDING_REQUEST = 'GET_LEAD_ONBOARDING_REQUEST';
export const GET_LEAD_ONBOARDING_SUCCESS = 'GET_LEAD_ONBOARDING_SUCCESS';
export const GET_LEAD_ONBOARDING_FAILURE = 'GET_LEAD_ONBOARDING_FAILURE';

export const SET_LEAD_ONBOARDING_DATA = 'SET_LEAD_ONBOARDING_DATA';

export const GET_LEAD_REQUEST = 'GET_LEAD_REQUEST';
export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';
export const GET_LEAD_FAILURE = 'GET_LEAD_FAILURE';

export const GET_SORTED_LEAD_REQUEST = 'GET_SORTED_LEAD_REQUEST';
export const GET_SORTED_LEAD_SUCCESS = 'GET_SORTED_LEAD_SUCCESS';
export const GET_SORTED_LEAD_FAILURE = 'GET_SORTED_LEAD_FAILURE';
export const GET_SORTED_LEAD_CANCEL = 'GET_SORTED_LEAD_CANCEL';

export const UPDATE_CURRENT_LEAD = 'UPDATE_CURRENT_LEAD';

export const CLEAR_CURRENT_ONBOARDING = 'CLEAR_CURRENT_ONBOARDING';
export const CLEAR_LEADS = 'CLEAR_LEADS';