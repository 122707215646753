import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { colors, font } from 'theme/styles';
import { getEmailTemplates } from 'redux/admin';
import { adminService } from 'services/admin';
import { SortItem, TableSorter, TopSection } from 'components/layout/Tables/TableComponents';
import { displayTime } from 'utils/helpers';
import { FieldHeading } from 'components/forms/FormComponents';
import PageTemplate from 'templates/PageTemplate';
import ErrorPopup from 'components/layout/ErrorPopup';
import SuccessPopup from 'components/layout/SuccessPopup';
import useTopSection from 'hooks/useTopSection';
import Select from 'components/UI/Select';
import Button from 'components/UI/Button';
import NoData from 'components/UI/NoData';
import Heading from 'components/UI/Heading';
import styled from 'styled-components';
import Block from 'components/UI/Block';
import Textarea from 'components/UI/Textarea';
import usePopup from 'hooks/usePopup';
import Input from 'components/UI/Input';
import CloseButton from 'components/UI/CloseButton';

const AdminEmailTemplatesPage = ({ templatesData, getEmailTemplates, error, isLoading }) => {
  const { topSectionState, handleChange, handleSpecial } = useTopSection([{ name: 'template' }]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [editTemplate, setEditTemplate] = useState();

  const [iframeData, setIframeData] = useState(null);
  const [saveError, setSaveError] = useState('');
  const [saveSuccess, setSaveSuccess] = useState('');

  const [testEmail, setTestEmail] = useState('');
  const [showTestEmailPopup, setShowTestEmailPopup] = useState(false);
  const [testError, setTestError] = useState('');
  const [testSuccess, setTestSuccess] = useState('');

  const [SavePopup, toggleSavePopup] = usePopup();
  const [SaveErrorPopup, toggleSaveErrorPopup] = usePopup({ type: 'error' });

  // const [saveError, setSaveError] = useState('');
  const [TestErrorPopup, toggleTestErrorPopup] = usePopup({ type: 'error' });

  useEffect(() => {
    const temp = templatesData.find((t) => t.id === topSectionState.template);
    setSelectedTemplate(temp);
    setEditTemplate(temp);
  }, [topSectionState.template, templatesData]);

  useEffect(() => {
    getEmailTemplates();
  }, [getEmailTemplates]);

  const handleEdit = (e) => {
    setEditTemplate({ ...editTemplate, [e.target.name]: e.target.value });
  };

  const onEmailChange = (e) => setTestEmail(e.target.value);

  const openTestPopup = () => setShowTestEmailPopup(true);

  const closeTestEmailPopup = () => {
    setShowTestEmailPopup(false);
    setTestEmail('');
  };

  const closeTestSuccesPopup = () => {
    setTestError('');
    setTestSuccess('');
    setIframeData(null);
  };

  const handleTest = () => {
    setTestError('');
    setTestSuccess('');
    setShowTestEmailPopup(false);

    const data = {
      id: editTemplate.id,
      ejs: editTemplate.content,
      ...(testEmail && { to: testEmail }),
    };

    adminService
      .testEmailTemplate(data)
      .then((res) => {
        console.log(res);
        setTestSuccess(testEmail ? 'Email został wysłany' : 'Podgląd szablonu');
        setIframeData(`data:text/html,${encodeURIComponent(res.data)}`);
      })
      .catch((err) => {
        setTestError(err.response.data.error.message);
        toggleTestErrorPopup();
      })
      .finally(() => {
        setTestEmail('');
      });
  };

  const handleSave = () => {
    setSaveError('');
    setSaveSuccess('');

    adminService
      .saveEmailTemplate(editTemplate)
      .then((res) => {
        console.log(res);
        setSaveSuccess('Zapisano');
        toggleSavePopup();
      })
      .catch((err) => {
        console.log(err);
        setSaveError(err.response.data.error.message);
        toggleSaveErrorPopup();
      })
      .finally(async () => {
        handleSpecial('', 'template');
        await getEmailTemplates();
      });
  };

  return (
    <PageTemplate>
      {!isLoading && error && <ErrorPopup fixed>{error.message}</ErrorPopup>}
      {showTestEmailPopup && (
        <SuccessPopup fixed>
          <CloseButton onClick={closeTestEmailPopup} />
          <Heading centered size="xl">
            Podaj testowy email
          </Heading>
          <InputWrapper>
            <Input
              name="testEmail"
              label="Testowy e-mail:"
              onChange={onEmailChange}
              onBlur={() => {}}
              value={testEmail}
            />
          </InputWrapper>
          <Button centered disabled={!selectedTemplate} onClick={handleTest}>
            Wyślij
          </Button>
        </SuccessPopup>
      )}
      <SavePopup fixed>{saveSuccess}</SavePopup>
      <SaveErrorPopup fixed>{saveError}</SaveErrorPopup>
      {testSuccess && (
        <StyledTestSuccessPopup fixed>
          <CloseButton onClick={closeTestSuccesPopup} />
          <Heading centered size="l">
            {testSuccess}
          </Heading>
          {iframeData && <iframe type="text/html" title="Podgląd" src={iframeData} />}
        </StyledTestSuccessPopup>
      )}
      <TestErrorPopup fixed>{testError}</TestErrorPopup>
      <TopSection>
        <Button disabled={!selectedTemplate} onClick={handleSave}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Zapisz zmiany
        </Button>
        <TableSorter>
          <SortItem>
            <span>Szablon e-mail</span>
            <Select small name="template" value={topSectionState.template || ''} onChange={handleChange}>
              <option value=""> </option>
              {templatesData &&
                templatesData.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
            </Select>
          </SortItem>
        </TableSorter>
      </TopSection>
      {!selectedTemplate && <NoData message="Nie wybrano szablonu email" />}
      {selectedTemplate && (
        <Wrapper>
          {console.log(selectedTemplate)}
          <StyledBlock>
            <TemplateHeader>
              <Heading centered size="xl">
                Edycja szablonu: {selectedTemplate.name}
              </Heading>
              <Info>
                <span>
                  <strong>Slug:</strong> {selectedTemplate.slug}
                </span>
                <span>
                  <strong>Utworzony:</strong> {displayTime(selectedTemplate.createdAt)}
                </span>
                <span>
                  <strong>Edytowany:</strong> {displayTime(selectedTemplate.updatedAt)}
                </span>
              </Info>
            </TemplateHeader>
            <TemplateContent>
              <Input
                name="name"
                label="Tytuł powiadomienia:"
                onChange={handleEdit}
                onBlur={() => {}}
                value={editTemplate.name}
              />
              <FieldHeading size="s">Treść powiadomienia:</FieldHeading>
              <Textarea name="content" onChange={handleEdit} onBlur={() => {}} value={editTemplate.content} />
            </TemplateContent>
            <TemplateVariables>
              <Heading>Dostępne zmienne:</Heading>
              {selectedTemplate.available_vars
                .filter((v) => Boolean(v))
                .map((variable) => (
                  <p key={variable.name}>
                    <span>
                      {'{{'} {variable.name} {'}}'}
                    </span>
                    &nbsp;&nbsp;—&nbsp;&nbsp;{variable.description}
                  </p>
                ))}
            </TemplateVariables>
            <Button right disabled={!selectedTemplate} onClick={openTestPopup}>
              Testuj
            </Button>
          </StyledBlock>
        </Wrapper>
      )}
    </PageTemplate>
  );
};
AdminEmailTemplatesPage.defaultProps = {
  error: null,
};

AdminEmailTemplatesPage.propTypes = {
  templatesData: PropTypes.instanceOf(Array).isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  templatesData: state.admin.emailTemplates.data,
  isLoading: state.admin.emailTemplates.isLoading,
  error: state.admin.emailTemplates.error,
});

const mapDispatchToProps = {
  getEmailTemplates,
};

const Wrapper = styled.div`
  & > * + * {
    margin-top: 4rem;
  }
`;

const StyledBlock = styled(Block)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`;

const TemplateContent = styled.div`
  margin-top: 3rem;

  textarea {
    width: 100%;
    min-height: 25em;
    margin-bottom: 3rem;
    resize: none;
  }
`;

const TemplateVariables = styled.div`
  & > * {
    margin-bottom: 0.25em;
  }
  
  span {
    color: ${colors.orange};
    font-family: monospace;
    font-size: 0.9em;
  }
`;

const TemplateHeader = styled.div`
  strong {
    font-weight: ${font.weight.semibold};
  }
`;

const Info = styled.div`
  margin-top: 2em;

  span {
    display: inline-block;
  }
  span + span {
    margin-left: 2em;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 30px auto;
`;

const StyledTestSuccessPopup = styled(SuccessPopup)`
  max-width: 700px;
  width: 100%;
  max-height: 600px;
  height: 100%;

  iframe {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmailTemplatesPage);
