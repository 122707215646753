import * as types from './types';

const initialState = {
  connected: false,
  error: false,
  connectError: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SOCKET_CONNECT:
      return {
        ...state,
        connected: action.payload,
      };
    case types.SET_SOCKET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.SET_SOCKET_CONNECT_ERROR:
      return {
        ...state,
        connectError: action.payload,
      };

    case types.CLEAR_SOCKET_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default chatReducer;
