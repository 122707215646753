import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, ErrorMessage, FieldArray } from 'formik';
import { trainingMaterialsSchema } from 'utils/validation';
import { FormWrapperCommon, FieldHeading, Columns, FormColumn } from 'components/forms/FormComponents';
import { partnerProgramOptions, trainingMaterialTypesOptions, trainingsMimeTypes } from 'config/constants';
import { humanFileSize, removeNullsAndEmpty } from 'utils/helpers';
import { trainingService } from 'services/training';
import { colors, font } from 'theme/styles';
import useFetchSuggestions from 'hooks/useFetchSuggestions';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import Select from 'components/UI/Select';
import InputAuto from 'components/UI/InputAuto';
import Textarea from 'components/UI/Textarea';
import Chip from 'components/UI/Chip';
import FileDropZone from 'components/UI/FileDropzone';
import ResetButton from 'components/UI/ResetButton';

const TrainingForm = ({ toggleClose, initialValues, editMode, refetch }) => {
  const [addFileError, setAddFileError] = useState('');

  const [partnersList, fetchPartners] = useFetchSuggestions({ type: 'partners' });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleClose = (resetForm) => {
    setSuccess(false);
    setError('');
    resetForm({});
    toggleClose();
  };

  const handleSubmit = (values) => {
    const newValues = removeNullsAndEmpty(values);

    newValues.partnerProgram = JSON.stringify(values.partnerProgram);
    newValues.companyIds = JSON.stringify(values.companyIds.map((p) => p.id));
    newValues.active = values.active ? '1' : '0';

    delete newValues.type;
    delete newValues.partnerSearch;

    setError('');
    setLoading(true);

    const thenFn = (data) => {
      console.log(data);
      setLoading(false);
      setSuccess(true);
      refetch();
    };

    const catchFn = (err) => {
      console.log(err);
      setLoading(false);
      setError(err.response?.data?.error?.message || 'Wystąpił błąd.');
    };

    trainingService.addTrainingMaterial(newValues).then(thenFn).catch(catchFn);
  };

  let { title, type, videoUrl, files, partnerProgram, description, companyIds, resources = [] } = initialValues;
  const active = initialValues.active === '1';
  if (editMode) {
    const isVimeo = resources.some((r) => r.resourceType === 'VIDEO_URL');
    type = isVimeo ? 'video' : 'file';
    companyIds = initialValues.companies;
    if (isVimeo) videoUrl = resources[0].url;
  }

  return (
    <Wrapper>
      {loading && (
        <SuccessPopup>
          <Heading>Trwa wysyłanie</Heading>
          <Loading customStyle={{ marginTop: '20px', marginBottom: '20px' }} />
        </SuccessPopup>
      )}
      <Heading size="xl">Materiały szkoleniowe</Heading>
      <Formik
        initialValues={{
          type,
          title,
          description,
          videoUrl,
          files,
          active,
          partnerProgram,
          companyIds,
        }}
        validationSchema={trainingMaterialsSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm, setFieldValue, setFieldTouched }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>{editMode ? 'Zaktualizowano' : 'Dodałeś materiał'}</Heading>
                <div className="buttons">
                  {!editMode && <Button onClick={() => handleReturn(true, resetForm)}>Dodaj kolejny</Button>}
                  <Button gray={!editMode} onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Wystąpił błąd</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <Columns>
                <FormColumn>
                  <FieldHeading big size="m">
                    Uzupełnij dane:
                  </FieldHeading>
                  <Input
                    disabled={editMode}
                    name="title"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nazwa"
                    value={values.title || ''}
                    error={touched.title && errors.title}
                  />
                  <ErrorMessage component={ErrorLabel} name="title" />
                  <Select
                    disabled={editMode}
                    maxWidth="unset"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Typ"
                    preFilled
                  >
                    {trainingMaterialTypesOptions.map((t) => (
                      <option key={t.value} value={t.value}>
                        {t.display}
                      </option>
                    ))}
                  </Select>
                  <FieldHeading size="s">Opis</FieldHeading>
                  <Textarea
                    disabled={editMode}
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    locked
                    height="120px"
                    placeholder="Dodaj notatkę"
                    value={values.description || ''}
                  />
                  {['video'].includes(values.type) && (
                    <>
                      <FieldHeading size="s">Dodaj link z portalu vimeo</FieldHeading>
                      <Input
                        disabled={editMode}
                        name="videoUrl"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Link"
                        value={values.videoUrl || ''}
                        error={touched.videoUrl && errors.videoUrl}
                      />
                      <ErrorMessage component={ErrorLabel} name="videoUrl" />
                    </>
                  )}
                  {['file'].includes(values.type) && (
                    <>
                      <FieldHeading size="s">Dodaj pliki z materiałami</FieldHeading>
                      <FileDropZone
                        disabled={editMode}
                        maxSize={20000000}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        onDrop={(acceptedFiles, fileRejections) => {
                          fileRejections.forEach((file) => {
                            file.errors.forEach((err) => {
                              if (err.code === 'file-too-large')
                                setAddFileError(`Plik jest za duży, maksymalnie ${humanFileSize(20000000)}`);
                              if (err.code === 'file-invalid-type')
                                setAddFileError('Plik ma niepoprawny format, tylko pliki PDF');
                            });
                          });

                          if (acceptedFiles.length > 0) {
                            const prevFiles = values.files || [];
                            const newFiles = acceptedFiles.filter((nf) => !prevFiles.some((lf) => lf.path === nf.path));
                            setAddFileError('');
                            setFieldValue('files', [...prevFiles, ...newFiles]);
                          }
                        }}
                        multiple
                        name="files"
                        accept={trainingsMimeTypes}
                        label="Dodaj pliki"
                      />
                      {addFileError && <ErrorLabel style={{ marginTop: '2rem' }}>{addFileError}</ErrorLabel>}
                      {errors.files && touched.files && (
                        <ErrorLabel style={{ marginTop: '2rem' }}>{errors.files}</ErrorLabel>
                      )}
                      {resources && (
                        <FilesWrapper>
                          {resources
                            .filter((r) => !r.url)
                            .map((file) => (
                              <FileCard key={file.fileName}>
                                <File download={false}>
                                  <FontAwesomeIcon fixedWidth icon="file" />
                                  <span>{file.fileName}</span>
                                </File>
                              </FileCard>
                            ))}
                        </FilesWrapper>
                      )}
                      {values.files && (
                        <FilesWrapper>
                          {values.files.map((file) => (
                            <FileCard key={file.name}>
                              <File download={false}>
                                <FontAwesomeIcon fixedWidth icon="file" />
                                <span>{file.name}</span>
                              </File>
                              <ResetButton
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    'files',
                                    values.files.filter((f) => f.path !== file.path),
                                  )
                                }
                              >
                                Usuń
                              </ResetButton>
                            </FileCard>
                          ))}
                        </FilesWrapper>
                      )}
                    </>
                  )}
                  <FieldHeading size="s">Status</FieldHeading>
                  <Checkbox
                    disabled={editMode}
                    name="active"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Aktywny"
                    value={values.active}
                    checked={values.active}
                    error={Boolean(touched.active && errors.active)}
                  />
                  <ErrorMessage component={ErrorLabel} name="active" />
                </FormColumn>
                <FormColumn>
                  <FieldHeading big size="m">
                    Ogranicz do:
                  </FieldHeading>
                  <FieldHeading medium size="m">
                    Kanał partnerski
                  </FieldHeading>
                  <FieldArray
                    name="partnerProgram"
                    render={(arrayHelpers) => (
                      <div>
                        {partnerProgramOptions.map((item) => (
                          <Checkbox
                            disabled={editMode}
                            key={item.value}
                            name="partnerProgram"
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayHelpers.push(item.value);
                              } else {
                                const idx = values.partnerProgram.indexOf(item.value);
                                arrayHelpers.remove(idx);
                              }
                            }}
                            onBlur={handleBlur}
                            label={item.display}
                            value={item.value}
                            checked={values.partnerProgram ? values.partnerProgram.includes(item.value) : false}
                          />
                        ))}
                      </div>
                    )}
                  />
                  <FieldHeading medium size="m">
                    Wybrani partnerzy
                  </FieldHeading>
                  <InputAuto
                    disabled={editMode}
                    suggestions={partnersList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="partnerSearch"
                    type="text"
                    display={['name']}
                    onBlur={handleBlur}
                    onChange={(e) => fetchPartners(e.target.value)}
                    onSelect={(val, item) => {
                      if (!values.companyIds.some((p) => p.id === item.id)) {
                        const { id, name } = item;
                        setFieldValue('companyIds', [...values.companyIds, { id, name }]);
                      }
                      setFieldValue('partnerSearch', '');
                    }}
                    label="Szukaj partnera"
                    value={values.partnerSearch || ''}
                    error={touched.partnerSearch && errors.partnerSearch}
                  />
                  <ErrorMessage component={ErrorLabel} name="partnerSearch" />
                  <div>
                    {values.companyIds &&
                      values.companyIds.map((partner) => (
                        <Chip
                          key={partner.id}
                          onClick={
                            editMode
                              ? false
                              : () => {
                                  const newValue = values.companyIds.filter((el) => el.id !== partner.id);
                                  setFieldValue('companyIds', newValue);
                                }
                          }
                        >
                          {partner.name}
                        </Chip>
                      ))}
                  </div>
                  <ErrorMessage component={ErrorLabel} name="partnerProgram" />
                </FormColumn>
              </Columns>
              {!editMode && (
                <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                  Prześlij
                </Button>
              )}
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

TrainingForm.defaultProps = {
  initialValues: {
    id: '',
    type: 'file',
    title: '',
    description: '',
    files: '',
    videoUrl: '',
    active: '1',
    partnerProgram: [],
    companyIds: [],
    resources: [],
    companies: [],
  },
  editMode: false,
};

TrainingForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    files: PropTypes.string,
    videoUrl: PropTypes.string,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    partnerProgram: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    companyIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    resources: PropTypes.array,
    companies: PropTypes.array,
  }),
  editMode: PropTypes.bool,
  toggleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default TrainingForm;

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;

const FilesWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FileCard = styled.div`
  background-color: ${({ saved }) => (saved ? colors.blueGray : colors.orangeSubtle)};
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  justify-content: space-between;
  font-size: ${font.size.s};
`;

const File = styled.div`
  &[download] {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    color: ${colors.orange};
    margin-right: 0.5rem;
  }
`;
