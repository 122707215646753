import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, font, breakpoints } from 'theme/styles';

const Input = React.forwardRef(
  (
    { name, type, placeholder, onChange, onBlur, className, value, error, label, width, disabled, currency, ...rest },
    ref,
  ) => {
    return (
      <Field width={width}>
        <StyledInput
          ref={ref}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          className={className}
          error={error}
          disabled={disabled}
          currency={currency}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
        {label && (
          <StyledLabel disabled={disabled} error={error} htmlFor={name}>
            {label}
          </StyledLabel>
        )}
        {currency && <span>zł</span>}
      </Field>
    );
  },
);

Input.displayName = 'Input';

Input.defaultProps = {
  type: 'text',
  className: '',
  value: '',
  placeholder: '',
  error: false,
  width: '',
  disabled: false,
  autocomplete: '',
  currency: false,
  label: false,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'date', 'number']),
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  width: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  autocomplete: PropTypes.string,
  currency: PropTypes.bool,
};

export default Input;

// Styled components:

const Field = styled.div`
  width: '100%';
  height: 40px;
  position: relative;
  padding: 0px;
  padding-top: 8px;
  margin: 10px 0;

  @media (min-width: ${breakpoints.mobileSmall}) {
    width: ${({ width }) => width || '100%'};
  }
`;

const StyledInput = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${colors.darkGray};
  font-size: ${font.size.s};
  color: ${colors.black};
  line-height: 1.1;
  letter-spacing: 0.5px;
  padding: 0;
  margin: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.darkGray};
      opacity: 0.5;
      & + label {
        opacity: 0.5;
      }
    `};

  & + label {
    transform: ${({ value }) => (value ? 'scale(0.8) translateY(-6px)' : 'none')};
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.orange};
  }

  &:focus + label {
    transform: scale(0.8) translateY(-6px);
  }

  ${({ error }) =>
    error &&
    css`
      border-bottom: 1px solid ${colors.red};
    `}

  ${({ currency }) =>
    currency &&
    css`
      text-align: right;
      position: relative;
      padding-right: 25px;

      & ~ span {
        position: absolute;
        right: 0px;
        display: inline-block;
        height: 30px;
        width: 20px;
        line-height: 30px;
        font-size: ${font.size.s};
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }
    `}

  &[type='date'] {
    width: 100px;
    color: ${({ value }) => (value ? colors.black : colors.lightGray)};
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
    &::-webkit-calendar-picker-indicator {
      color: ${colors.black};
      &:hover {
        background: transparent;
        color: ${colors.black};
      }
    }
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 0px;
  left: 0;
  transform-origin: top left;
  transition: transform 0.3s ease;
  font-size: ${font.size.s};
  color: ${colors.black};
`;
