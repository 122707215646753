/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { font, colors } from 'theme/styles';
import ResetButton from './ResetButton';

// Komponent zadziała tylko wewnątrz FORMIKa (dostaje propsy field i form)

const FileInput = props => {
  const { field, form, id, accept, disabled } = props;
  const [filename, setFilename] = useState(field.value?.name || '');

  const handleChange = e => {
    const file = e.currentTarget.files[0];
    form.setFieldValue(field.name, file);
    if (file) setFilename(file.name);
  };

  const handleReset = () => {
    if (disabled) return;
    setFilename('');
    form.setFieldValue(field.name, null);
  };

  useEffect(() => {
    if (!field.value) setFilename('');
    if (typeof field.value === 'string') setFilename(field.value);
  }, [field.value]);

  return (
    <StyledFileInput disabled={disabled}>
      {!filename && <label htmlFor={id}>Wybierz plik</label>}
      {filename && <FontAwesomeIcon icon="file" color={colors.orange} />}
      {filename && <span>{filename}</span>}
      {filename && !disabled && (
        <StyledResetButton type="button" onClick={handleReset}>
          &nbsp;usuń
        </StyledResetButton>
      )}
      <input
        type="file"
        accept={accept}
        id={id}
        disabled={disabled}
        onChange={file => handleChange(file)}
        onClick={e => {
          e.currentTarget.value = null;
        }}
      />
    </StyledFileInput>
  );
};

FileInput.defaultProps = {
  accept: 'image/png, image/jpeg',
  disabled: false,
};

FileInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FileInput;

const StyledResetButton = styled(ResetButton)`
  margin-left: auto;
`;

const StyledFileInput = styled.div`
  display: flex;
  align-items: center;
  label {
    height: 27px;
    min-width: 128px;
    border-radius: 3px;
    font-size: ${font.size.s};
    font-weight: ${font.weight.default};
    padding: 0 20px;
    background: ${colors.white};
    color: ${colors.black};
    border: 1px solid ${colors.orange};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: ${colors.orange};
      text-decoration: none;
    }
  }
  span {
    font-size: ${font.size.s};
    font-weight: ${font.weight.default};
    color: ${colors.black};
    margin-left: 10px;
  }
  input {
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      label:hover {
        color: inherit;
        cursor: default;
      }
    `}
`;
