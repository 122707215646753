import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { breakpoints, colors, font } from 'theme/styles';
import arrow from 'assets/icons/dropdown.svg';

const Select = ({
  small,
  name,
  error,
  onChange,
  onBlur,
  className,
  value,
  label,
  children,
  disabled,
  preFilled,
  multiple,
  maxWidth,
  ...rest
}) => {
  return (
    <Field
      small={small}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <StyledSelect
        disabled={disabled}
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        small={small}
        className={className}
        error={error}
        preFilled={preFilled}
        multiple={multiple}
        maxWidth={maxWidth}
      >
        {children}
      </StyledSelect>
      {label && (
        <StyledLabel error={error} htmlFor={name}>
          {label}
        </StyledLabel>
      )}
    </Field>
  );
};

Select.defaultProps = {
  disabled: false,
  className: '',
  value: '',
  error: false,
  label: '',
  small: false,
  preFilled: false,
  multiple: false,
  name: '',
  maxWidth: '',
  onBlur: () => {},
};

Select.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Array)]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  preFilled: PropTypes.bool,
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  small: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  maxWidth: PropTypes.string,
};

const Field = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  padding: 0px;
  padding-top: 8px;
  margin: 10px 0;

  ${({ disabled }) =>
    disabled &&
    css`
      select {
        color: ${({ disabled }) => (disabled ? colors.darkGray : colors.black)};
      }
    `}

  ${({ small }) =>
    small &&
    css`
      margin: 0;
      padding: 0;
      height: auto;
      width: auto;
    `}
`;

const StyledSelect = styled.select`
  font-family: 'Source Sans Pro', sans-serif;
  border: none;
  outline: none;
  background: transparent;
  min-width: 60px;
  width: ${({ small }) => (small ? 'auto' : '100%')};
  height: 30px;
  border-radius: 0;
  border-bottom: 1px solid ${colors.darkGray};
  font-size: ${font.size.s};
  color: ${({ disabled }) => (disabled ? colors.darkGray : colors.black)};
  line-height: 1.1;
  padding: 0 25px 0 10px;
  margin: 0;
  appearance: none;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: right 0 top 50%, 0 0;
  background-size: 20px auto;

  @media (min-width: ${breakpoints.mobile}) {
    max-width: ${({ maxWidth }) => maxWidth || '140px'};
  }

  & + label {
    transform: ${({ value, preFilled }) => (value || preFilled ? 'scale(0.8) translateY(-6px)' : 'none')};
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.orange};
  }

  &:focus + label {
    transform: scale(0.8) translateY(-6px);
  }

  ${({ error }) =>
    error &&
    css`
      border-bottom: 1px solid ${colors.red};
    `}

  &::-ms-expand {
    display: none;
  }

  > option {
    color: ${colors.black};
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 0px;
  left: 0;
  transform-origin: top left;
  transition: transform 0.3s ease;
  font-size: ${font.size.s};
  color: ${colors.black};
`;

export default Select;
