import React, { useState } from 'react';
import { leadService } from 'services/lead';
import PropTypes from 'prop-types';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import moment from 'moment';

const LeadsExport = ({ example }) => {
  const [downloadInProgress, setDownloadInProgres] = useState(false);

  const clickHandler = () => {
    setDownloadInProgres(true);

    const createLink = res => {
      setDownloadInProgres(false);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `leads__${moment().format('DD_MM_YY')}.csv`);
      document.body.appendChild(link);
      link.click();
    };

    if (example) {
      leadService
        .getExampleCsv()
        .then(res => {
          createLink(res);
        })
        .catch(err => {
          setDownloadInProgres(false);
          console.log(err);
        });
    } else {
      leadService
        .exportLeads()
        .then(res => {
          createLink(res);
        })
        .catch(err => {
          setDownloadInProgres(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Button type="button" onClick={clickHandler} disabled={downloadInProgress}>
        {example ? 'Pobierz przykładowy plik' : 'Exportuj leady' }
      </Button>
      {downloadInProgress && (
        <>
          <Heading centered size="l">
            Pobieranie...
          </Heading>
          <Loading />
        </>
      )}
    </>
  );
};

LeadsExport.defaultProps = {
  example: false,
};

LeadsExport.propTypes = {
  example: PropTypes.bool,
};

export default LeadsExport;
