import React from 'react';
import { routes } from 'routes';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';
import { ReactComponent as MerchantsAnalyticsIcon } from 'assets/icons/analysis.svg';
import { ReactComponent as MerchantsLeadsAnalyticsIcon } from 'assets/icons/analysis-leads.svg';
import { Card, CardsWrapper } from './Admin/AdminPage';

const AnalyticsPage = () => {
  return (
    <PageTemplate>
      <CardsWrapper>
        <Card to={routes.merchantsAnalytics}>
          <MerchantsAnalyticsIcon />
          <Heading centered> Analiza działań handlowców </Heading>
        </Card>
        <Card to={routes.merchantsLeadsAnalytics}>
          <MerchantsLeadsAnalyticsIcon />
          <Heading centered>Analiza nowych leadów</Heading>
        </Card>
      </CardsWrapper>
    </PageTemplate>
  );
};

AnalyticsPage.defaultProps = {};

AnalyticsPage.propTypes = {};

export default AnalyticsPage;
