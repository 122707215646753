import { partnerService } from 'services/partner';
import * as types from './types';

export const getPartnerTerms = id => dispatch => {
  dispatch({ type: types.GET_PARTNER_TERMS_REQUEST });
  return partnerService
    .getTerms(id)
    .then(payload => {
      dispatch({ type: types.GET_PARTNER_TERMS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_PARTNER_TERMS_FAILURE, err });
    });
};

export const getAllUsers = query => dispatch => {
  dispatch({ type: types.GET_ALL_USERS_REQUEST });
  return partnerService
    .getAllUsers(query)
    .then(payload => {
      dispatch({ type: types.GET_ALL_USERS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_ALL_USERS_FAILURE, err });
    });
};

export const getUser = id => dispatch => {
  dispatch({ type: types.GET_USER_REQUEST });
  return partnerService
    .getUser(id)
    .then(payload => {
      dispatch({ type: types.GET_USER_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_USER_FAILURE, err });
    });
};

export const setCurrentUser = user => dispatch => {
  dispatch({ type: types.SET_CURRENT_USER, payload: user });
};

export const clearCurrentUser = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_USER });
};

export const clearPartner = () => dispatch => {
  dispatch({ type: types.CLEAR_PARTNER });
};
