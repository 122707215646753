import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css, keyframes } from 'styled-components';
import { breakpoints, colors, font } from 'theme/styles';
import Notification from './Notification';

const bellshake = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0); }
  15% { transform: translate(-50%, -50%) rotate(5deg); }
  30% { transform: translate(-50%, -50%) rotate(-5deg); }
  45% { transform: translate(-50%, -50%) rotate(4deg); }
  60% { transform: translate(-50%, -50%) rotate(-4deg); }
  75% { transform: translate(-50%, -50%) rotate(2deg); }
  85% { transform: translate(-50%, -50%) rotate(-2deg); }
  92% { transform: translate(-50%, -50%) rotate(1deg); }
  100% { transform: translate(-50%, -50%) rotate(0); }
`;

const Notifications = styled.div`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  text-align: right;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  width: calc(100% - 3rem);
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 1000;
    `}

  @media (min-width: ${breakpoints.notificationsDesktop}) {
    top: 8rem;
    bottom: unset;
    flex-direction: column;
    width: auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  color: ${colors.mediumGray};
  font-size: 1.4rem;

  & > * {
    margin-left: 0.5em;
  }

  & > :first-child {
    margin-right: auto;
    margin-left: 0;
  }
`;

const HeaderCloseIcon = styled(FontAwesomeIcon).attrs((props) => ({
  disabled: true,
  icon: 'times',
  fixedWidth: true,
  size: 'lg',
  ...props,
}))`
  position: relative;
  top: -1px;
  padding: 0 0.5rem;
  color: ${colors.mediumGray};
  font-size: 1.1em;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${colors.orange};
  }
`;

const Footer = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  overflow: hidden;
`;

const StyledBellWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 1.5rem;
  width: 3.4rem;
  height: 3.4rem;
  background-color: ${colors.lighterGray};
  border-radius: 50%;
  color: ${colors.orange};
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, color 0.3s;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: ${colors.orange};
    color: ${colors.white};
  }

  ${({ shake }) =>
    shake &&
    css`
      svg {
        animation: ${bellshake} 3s linear infinite;
      }
    `}

  @media (min-width: ${breakpoints.tablet}) {
    position: relative;
    display: inline-block;
    margin-right: 1.4rem;
    bottom: unset;
    right: unset;
  }
`;

const Badge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 2rem;
  height: 2rem;
  color: ${colors.white};
  font-size: ${font.size.xxs};
  font-weight: ${font.weight.semibold};
  z-index: 2;
  background-color: ${colors.red};
  border-radius: 50%;
  border: 2px solid ${colors.white};
`;

const Wrapper = styled.div`
  position: relative;
  visibility: hidden;
  display: block;
  padding: 1rem 0.5rem 1rem 1rem;
  background-color: ${colors.white};
  text-align: left;
  border: 1px solid ${colors.lightGray};
  box-shadow: 0px 3px 15px #00000010;
  border-radius: 4px;
  opacity: 0;
  height: 0;
  width: 0;
  transform: translateY(2rem);
  transition: transform 0.3s, opacity 0.3s;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      overflow: visible;
      opacity: 1;
      height: auto;
      width: 100%;
      visibility: visible;
      transform: translateY(0);

      @media (min-width: ${breakpoints.mobileSmall}) {
        width: 40rem;
      }
    `}
`;

const NotificationList = styled.div`
  padding-right: 0.5rem;
  max-height: calc(50vh - 18rem);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

Notifications.Wrapper = Wrapper;

Notifications.Header = Header;
Notifications.HeaderCloseIcon = HeaderCloseIcon;
Notifications.Footer = Footer;

Notifications.Badge = Badge;
Notifications.BellWrapper = StyledBellWrapper;

Notifications.Item = Notification;
Notifications.List = NotificationList;

export default Notifications;
