export const GET_MARKETING_MATERIALS_REQUEST = 'GET_MARKETING_MATERIALS_REQUEST';
export const GET_MARKETING_MATERIALS_SUCCESS = 'GET_MARKETING_MATERIALS_SUCCESS';
export const GET_MARKETING_MATERIALS_FAILURE = 'GET_MARKETING_MATERIALS_FAILURE';

export const GET_MARKETING_MATERIAL_REQUEST = 'GET_MARKETING_MATERIAL_REQUEST';
export const GET_MARKETING_MATERIAL_SUCCESS = 'GET_MARKETING_MATERIAL_SUCCESS';
export const GET_MARKETING_MATERIAL_FAILURE = 'GET_MARKETING_MATERIAL_FAILURE';

export const SET_CURRENT_MARKETING_MATERIAL = 'SET_CURRENT_MARKETING_MATERIAL';
export const CLEAR_CURRENT_MARKETING_MATERIAL = 'CLEAR_CURRENT_MARKETING_MATERIAL';

export const SET_MARKETING_MATERIAL_UPLOAD_PROGRESS = 'SET_MARKETING_MATERIAL_UPLOAD_PROGRESS';

export const CLEAR_MARKETING = 'CLEAR_MARKETING';

