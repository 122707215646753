import React from 'react';
import { routes } from 'routes';
import { ReactComponent as FormIcon } from 'assets/icons/form.svg';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';
import { CardsWrapper, Card } from './AdminPage';

const AdminDemosPage = () => {
  return (
    <PageTemplate>
      <CardsWrapper>
        <Card to={routes.adminDemoCrmForm.replace(':id', 'demo')}>
          <FormIcon />
          <Heading centered> Formularz CRM </Heading>
        </Card>
      </CardsWrapper>
    </PageTemplate>
  );
};

AdminDemosPage.defaultProps = {};

AdminDemosPage.propTypes = {};

export default AdminDemosPage;
