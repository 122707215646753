/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { TableWrapperCommon, TableCommon } from 'components/layout/Tables/TableComponents';
import { breakpoints, colors, font } from 'theme/styles';
import { formatMoney, resolveObjProp } from 'utils/helpers';
import NoData from 'components/UI/NoData';
import { TwoColumnsRwd } from 'components/UI/Structure';

const BillingsDetailsTable = ({ headers, data, offset, total, pods }) => {
  const span = headers.filter((i) => !i.sumHeader).length - 1;
  const spanSum = headers.filter((i) => i.sumHeader).length;

  return (
    <>
      <TableWrapper>
        <Table columns={headers.length}>
          <colgroup>
            {headers.map((header) => (
              <col key={header.property} />
            ))}
          </colgroup>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.property}>{header.displayName}</th>
              ))}
            </tr>
          </thead>
          {data.length > 0 && (
            <>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.id || index}>
                      {headers.map((header) => {
                        const uniqueKey = item.id + header.property;
                        let propValue = resolveObjProp(header.property, item);

                        if (header.type === 'date' && propValue) propValue = moment(propValue).format('YYYY-MM-DD');
                        if (header.type === 'currency') propValue = formatMoney(propValue);
                        if (header.mapValue) propValue = header.mapValue(propValue);

                        return (
                          <td className={header.class} key={uniqueKey}>
                            {propValue}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {spanSum > 0 && (
                <tfoot>
                  <tr className="summary">
                    <td>Razem</td>
                    <td colSpan={span} />
                    {headers
                      .filter((i) => i.sumHeader)
                      .map((header) => {
                        let val = total[header.property];
                        if (header.type === 'currency') val = formatMoney(val);
                        return <td key={header.property}>{val}</td>;
                      })}
                  </tr>
                  {/* <tr className="total">
                    <td>Suma</td>
                    <td colSpan={span} />
                    <td colSpan={spanSum}>{formatMoney(total.sum)}</td>
                  </tr> */}
                </tfoot>
              )}
            </>
          )}
        </Table>
        {data.length === 0 && <NoData />}
      </TableWrapper>
      <DetailsTableWrapper>
        <TwoColumnsRwd breakpoint={breakpoints.mobile}>
          <div>
            {pods.length > 0 && (
              <>
                <p>
                  <strong>UWAGA</strong>
                </p>
                <p>
                  W celu terminowego rozliczenia wynagrodzenia prosimy o wystawienie faktury do 14-go dnia miesiąca*.
                </p>
                <DetailsTable>
                  <thead>
                    <tr>
                      <th>L.P.</th>
                      <th>Nazwa pozycji</th>
                      <th>Kwota</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pods.map((pod, i) => (
                      <tr key={pod.RoP_ID}>
                        <td>{i + 1}</td>
                        <td>{pod.RoP_TowarNazwa}</td>
                        <td>{formatMoney(pod.RoP_TowarCena)}</td>
                      </tr>
                    ))}
                  </tbody>
                </DetailsTable>
                <p>
                  <strong>
                    *Nie dotyczy Partnerów, którzy korzystają z formy samo fakturowania lub zatwierdzonej propozycji
                    faktury.
                  </strong>
                </p>
              </>
            )}
          </div>
          <div>
            <DetailsTable style={{ fontSize: '1.2em'}} >
              <thead>
                <tr>
                  <th>L.P.</th>
                  <th>Podsumowanie rozliczenia</th>
                  <th>Kwota</th>
                </tr>
              </thead>
              <tfoot >
                <tr>
                  <td style={{ fontSize: '1.1em'}}>#</td>
                  <td style={{ fontSize: '1.1em'}}>Suma na fakturze</td>
                  <td style={{ fontSize: '1.1em'}}>{formatMoney(total.sumRozPods)}</td>
                </tr>
              </tfoot>
            </DetailsTable>
          </div>
        </TwoColumnsRwd>
      </DetailsTableWrapper>
    </>
  );
};

BillingsDetailsTable.defaultProps = {
  offset: 0,
  pods: [],
};

BillingsDetailsTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  pods: PropTypes.instanceOf(Array),
  total: PropTypes.instanceOf(Object).isRequired,
  offset: PropTypes.number,
};

export default BillingsDetailsTable;

const DetailsTableWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;

  strong {
    margin-top: 2em;
    font-weight: ${font.weight.semibold};
  }
`;

const TableWrapper = styled(TableWrapperCommon)`
  flex-grow: 0;
`;

const DetailsTable = styled(TableCommon)`
  min-width: 360px;
  margin-bottom: 2rem;

  table {
    width: 100%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
    padding-left: 16px;
  }

  tfoot {
    td {
      font-size: ${font.size.m};
      font-weight: ${font.weight.bold};
      color: ${colors.red};
    }
  }
`;

const Table = styled(TableCommon)`
  td {
    width: ${({ columns }) => `${Math.floor(1400 / columns)}px`};
    min-width: ${({ columns }) => `${Math.floor(1400 / columns)}px`};
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  thead {
    font-size: ${font.size.xs};

    tr {
      height: 50px;
      border-top: 1px solid ${colors.gray};
      border-bottom: 1px solid ${colors.gray};

      th {
        top: -1px;
      }
    }
  }

  tbody {
    tr {
      cursor: default;
    }

    td {
      text-align: center;
      font-size: ${font.size.xs};

      &.right {
        text-align: right;
      }

      &.left {
        text-align: left;
      }
    }
  }

  tfoot {
    tr {
      td {
        background-color: ${colors.white};
      }

      &.summary,
      &.total {
        font-weight: ${font.weight.semibold};
      }

      &.summary td {
        border-top: 2px solid ${colors.gray};
      }

      &.total td:last-child {
        background-color: ${colors.blueGray};
      }
    }
  }
`;
