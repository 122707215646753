/* eslint-disable func-names */
import * as Yup from 'yup';
import { NIP, PESEL, IdentityCardNumber } from 'id-pl';
import IBAN from 'iban';
import moment from 'moment';
import matrixArr from 'components/forms/LeadMerchant/others/configAML';
import ids from 'config/ids';

const lettersRegex = /^[a-zA-Z]+$/;
const placeNameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ&-\s\d]+$/;
const gusPlaceNameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ&()-\s\d]+$/;
const nameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/;
const surnameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/;
const zipCodeRegex = /^\d{2}-\d{3}$/;
const phoneRegex = /^\d{9}$/;
const polishLettersRegex = /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+/;

const NIP_EXCLUDE = [
  '0000000000',
  '1111111111',
  '2222222222',
  '3333333333',
  '4444444444',
  '5555555555',
  '6666666666',
  '7777777777',
  '8888888888',
  '9999999999',
];

const validateNip = (nip) => {
  if (NIP_EXCLUDE.includes(nip)) return false;
  return NIP.isValid(nip);
};

const validatePesel = (pesel) => PESEL.isValid(pesel);
const validateIdentityCardNumber = (pesIdentityCardNumberel) => IdentityCardNumber.isValid(pesIdentityCardNumberel);

Yup.setLocale({
  mixed: {
    required: 'To pole jest wymagane',
  },
});

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

export const optionsOthersSchema = Yup.object().shape({
  collaborationRegulationUrl: Yup.string().url('Niepoprawny format').nullable(),
});

export const userPreferencesSchema = Yup.object().shape({
  newMessagesEmailNotification: Yup.boolean().required(),
});

export const crmCheckSchema = Yup.object().shape({
  nip: Yup.string()
    .required()
    .test('length', 'Niepoprawny format NIP', (value) => {
      if (value) return value.toString().length === 10;
      return true;
    })
    .test('format', 'Niepoprawny format NIP', (value) => validateNip(String(value))),
});

export const graylogCheckSchema = Yup.object().shape({
  graylogId: Yup.string().required(),
});

export const leacCrmStatusCheckSchema = Yup.object().shape({
  leadId: Yup.string().required(),
});

export const lead24FormSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  nip: Yup.string()
    .required()
    .test('length', 'Niepoprawny format NIP', (value) => {
      if (value) return value.toString().length === 10;
      return true;
    })
    .test('format', 'Niepoprawny format NIP', (value) => validateNip(String(value))),
  email: Yup.string().email('Błędny format adresu email').required('Podaj email'),
  phone: Yup.string()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu')
    .required('Telefon jest wymagany')
    .nullable(),
  www: Yup.string().url('Podaj prawdiłowy adres url').required('Podaj adres www').nullable(),
});

const gusPlaceSchema = Yup.object().shape({
  streetName: Yup.string(),
  buildingNumber: Yup.string(),
  apartmentNumber: Yup.string(),
  city: Yup.string(),
  zipCode: Yup.string()
    .matches(zipCodeRegex, {
      message: 'Niepoprawny format kodu pocztowego',
      excludeEmptyString: true,
    })
    .typeError('Niepoprawny format kodu pocztowego'),
  country: Yup.string().matches(lettersRegex, {
    message: 'Dozwolone tylko litery',
    excludeEmptyString: true,
  }),
  county: Yup.string().matches(placeNameRegex, { message: 'Niedozwolone znaki', excludeEmptyString: true }),
  community: Yup.string().matches(gusPlaceNameRegex, { message: 'Niedozwolone znaki', excludeEmptyString: true }),
  voivodeship: Yup.string(),
});

const placeSchema = Yup.object().shape({
  streetName: Yup.string(),
  buildingNumber: Yup.string(),
  apartmentNumber: Yup.string().max(9, 'Maksymalnie 9 znaków'),
  city: Yup.string().required(),
  zipCode: Yup.string()
    .required()
    .matches(zipCodeRegex, {
      message: 'Niepoprawny format kodu pocztowego',
      excludeEmptyString: true,
    })
    .typeError('Niepoprawny format kodu pocztowego'),
  country: Yup.string().required().matches(lettersRegex, {
    message: 'Dozwolone tylko litery',
    excludeEmptyString: true,
  }),
  county: Yup.string().required().matches(placeNameRegex, 'Niedozwolone znaki'),
  community: Yup.string().required().matches(gusPlaceNameRegex, 'Niedozwolone znaki'),
  voivodeship: Yup.string().required(),
});

const personSchema = Yup.object().shape({
  name: Yup.string().required().matches(nameRegex, 'Niedozwolone znaki'),
  lastname: Yup.string().required().matches(surnameRegex, 'Niedozwolone znaki'),
  email: Yup.string()
    .email('Błędny format adresu email')
    .test('Polskie znaki są niedozwolone', 'Polskie znaki są niedozwolone', (value) => !polishLettersRegex.test(value))
    .max(30, 'Maksymalnie 30 znaków'),
  phone: Yup.string()
    .required()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu'),
  type: Yup.number().required(),
  function: Yup.number().required().positive('To pole jest wymagane'),
});

const contactPersonSchema = Yup.object().shape({
  name: Yup.string().required().matches(nameRegex, 'Niedozwolone znaki'),
  lastname: Yup.string().required().matches(surnameRegex, 'Niedozwolone znaki'),
  phone: Yup.string()
    .required()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu'),
});

const terminalSchema = Yup.object().shape({
  type: Yup.number().required(),
  connectionType: Yup.number().required(),
  installationType: Yup.number().required(),
  installationId: Yup.string().nullable(),
  notes: Yup.string().max(450, 'Maksymalnie 450 znaków').nullable(),
  count: Yup.number().min(1).required(),
});

const timeSchedule = Yup.object().shape(
  {
    from: Yup.string()
      .matches(/\d{2}:\d{2}/, {
        message: 'Format: HH:MM',
        excludeEmptyString: true,
      })
      .when('to', {
        is: (to) => Boolean(to),
        then: () => Yup.string().nullable().required(),
        otherwise: () => Yup.string().nullable(),
      }),
    to: Yup.string()
      .matches(/\d{2}:\d{2}/, {
        message: 'Format: HH:MM',
        excludeEmptyString: true,
      })
      .when('from', {
        is: (from) => Boolean(from),
        then: () => Yup.string().nullable().required(),
        otherwise: () => Yup.string().nullable(),
      }),
  },
  [['from', 'to']],
);

const pointSchema = Yup.object().shape({
  billingSystemCopy: Yup.number().nullable(),
  name: Yup.string().required().matches(placeNameRegex, 'Dozwolone znaki: litery, cyfry, spacje oraz: - &'),
  address: placeSchema.required(),
  contactPerson: contactPersonSchema.required(),
  industry: Yup.number().positive('To pole jest wymagane').required(),
  workSchedule: Yup.object()
    .shape({
      ponPt: timeSchedule,
      sob: timeSchedule,
      niedz: timeSchedule,
    })
    .required(),
  terminals: Yup.array().of(terminalSchema).required(),
  pointBankAccount: Yup.string().when('billingSystemCopy', {
    is: (val) => val === 2,
    then: () =>
      Yup.string()
        .test('is-iban', 'Niepoprawny numer IBAN', function (value) {
          return Boolean(value && (IBAN.isValid(value) || IBAN.isValid(`PL${value}`)));
        })
        .required(),
    otherwise: () => Yup.string().nullable(),
  }),
});

const LeadMerchantStep1 = Yup.object().shape({
  addresses: Yup.object().shape({
    email: Yup.string()
      .email('Błędny format adresu email')
      .test(
        'Polskie znaki są niedozwolone',
        'Polskie znaki są niedozwolone',
        (value) => !polishLettersRegex.test(value),
      )
      .required(),
    main: gusPlaceSchema.required(),
    invoices: placeSchema.required(),
    mailling: placeSchema.required(),
  }),
});

const LeadMerchantStep2 = Yup.object().shape({
  settlements: Yup.object().shape({
    bankAccount: Yup.string()
      .test('is-iban', 'Niepoprawny numer IBAN', function (value) {
        return Boolean(value && (IBAN.isValid(value) || IBAN.isValid(`PL${value}`)));
      })
      .required(),
    billingSystem: Yup.number().required(),
    invoicingSystem: Yup.number().required(),
    serviceType: Yup.number().required(),
    pbg: Yup.number().typeError('To pole jest wymagane').required(),
    period: Yup.number().required(),
    businessLine: Yup.number().required(),
    legalForm: Yup.mixed().nullable(),
    legalFormAML: Yup.number().positive('To pole jest wymagane').typeError('To pole jest wymagane').required(),
    agreement: Yup.mixed().when('legalFormAML', {
      is: (val) => {
        const legalFormobj = matrixArr.find((lf) => lf.value === val); // spóła cywilna
        return legalFormobj ? legalFormobj.agreement : false;
      },
      then: () => Yup.mixed().required(),
      otherwise: () => Yup.mixed().nullable(),
    }),

    // agreement: Yup.string()
    //   .when('legalForm', {
    //     is: val => val === 1, // spóła cywilna
    //     then: Yup.string().required(),
    //     otherwise: Yup.string().nullable(),
    //   })
  }),
});

const LeadMerchantStep3 = Yup.object().shape({
  terms: Yup.object().shape({
    others: Yup.string().max(200, 'Maksymalnie 100 znaków').nullable(),
  }),
});

const nationality = Yup.object().shape({
  id: Yup.string().required(),
  display: Yup.string().required(),
});

const merchantSchema = Yup.object().shape({
  peselExist: Yup.boolean().required(),
  pesel: Yup.mixed().when('peselExist', {
    is: (peselExist) => peselExist === true,
    then: () =>
      Yup.string()
        .required()
        .test('format', 'Niepoprawny PESEL', (value) => validatePesel(String(value))),
    otherwise: () => Yup.string().nullable(),
  }),
  birthdayDate: Yup.mixed().when('peselExist', {
    is: (peselExist) => peselExist === false,
    then: () => Yup.string().required(),
    otherwise: () => Yup.string().nullable(),
  }),
  identityCardExpirationDateExist: Yup.boolean().required(),
  identityCardNumber: Yup.string()
    .required()
    .test('format', 'Niepoprawny numer dowodu', (value) => validateIdentityCardNumber(String(value))),
  identityCardReleaseDate: Yup.string().required(),
  // identityCardExpirationDate: Yup.string().required(),
  identityCardExpirationDate: Yup.mixed().when('identityCardExpirationDateExist', {
    is: (identityCardExpirationDateExist) => identityCardExpirationDateExist === true,
    then: () => Yup.string().required(),
    otherwise: () => Yup.string().nullable(),
  }),
  countryOfBirth: Yup.string().required(),
  nationalities: Yup.array().of(nationality).min(1).required(),
});

const pepSchema = Yup.object().shape({
  position: Yup.string().required(),
  positionReleaseDate: Yup.string().required(),
});

const pepAssocSchema = Yup.object().shape({
  PEPFullname: Yup.string().required(),
  position: Yup.string().required(),
  positionReleaseDate: Yup.string().required(),
});

const signatoryDrukAmlSchema = Yup.object().shape({
  isAuthorizedMerchant: Yup.string().required(),
  merchant: Yup.mixed().when('isAuthorizedMerchant', {
    is: (isAuthorizedMerchant) => isAuthorizedMerchant === '1',
    then: () => merchantSchema.required(),
    otherwise: () => Yup.mixed().nullable(),
  }),
  isBeneficialOwner: Yup.string().required(),
  isPEP: Yup.string().required(),
  pep: Yup.mixed().when('isPEP', {
    is: (isPEP) => isPEP == '1',
    then: () => pepSchema.required(),
    otherwise: () => Yup.mixed().nullable(),
  }),
  isPEPFamily: Yup.string().required(),
  pepFamily: Yup.mixed().when('isPEPFamily', {
    is: (isPEPFamily) => isPEPFamily == '1',
    then: () => pepAssocSchema.required(),
    otherwise: () => Yup.mixed().nullable(),
  }),
  isPEPAssociate: Yup.string().required(),
  pepAssociate: Yup.mixed().when('isPEPAssociate', {
    is: (isPEPAssociate) => isPEPAssociate == '1',
    then: () => pepAssocSchema.required(),
    otherwise: () => Yup.mixed().nullable(),
  }),
  dataValidationConfirmation: Yup.boolean().oneOf([true], 'Oświadczenie jest wymagane').required(),
});

const LeadMerchantStep4 = Yup.object().shape({
  contact: Yup.object().shape({
    legalFormCopy: Yup.object().nullable(),
    financialMatters: personSchema.required(),
    operationalMatters: personSchema.required(),
    businessMatters: personSchema.required(),
    owners: Yup.array().of(personSchema),
    personInCharge: personSchema.required(),
    signatory: Yup.array().of(personSchema).min(1).required(),
    agreement: Yup.mixed().when(['signatory', 'legalFormCopy'], {
      is: (valSignatory, valform) => {
        // Pełnomocnik
        const isProxy = valSignatory && valSignatory[0] && valSignatory.some((p) => p && p.function === 8);
        // Forma prawna zwolniona z pliku dla pełnomocnika - na ten moment jest jedna, ale bez pełnomocnika w dostepnych funkcjach
        const doLegalFormAllowAgreement = valform && valform.isSignatoryAgreementRequired;
        return isProxy && doLegalFormAllowAgreement;
      },
      then: () => Yup.mixed().required(),
      otherwise: () => Yup.mixed().nullable(),
    }),
    versionNumberCopy: Yup.number().nullable(),
    contractForm: Yup.mixed().when('versionNumberCopy', {
      is: (versionVal) => versionVal >= 2,
      then: () => Yup.string().required(),
      otherwise: () => Yup.mixed().nullable(),
    }),

    // Version >= 4
    allSignatoryAreCitizensOfPoland: Yup.mixed().when('versionNumberCopy', {
      is: (versionVal) => versionVal >= 4,
      then: () => Yup.string().required(),
      otherwise: () => Yup.mixed().nullable(),
    }),
    signatoryDrukAml: Yup.mixed().when(['versionNumberCopy', 'allSignatoryAreCitizensOfPoland'], {
      is: (versionVal, allSignatoryAreCitizensOfPoland) => versionVal >= 4 && allSignatoryAreCitizensOfPoland === '1',
      then: () => Yup.array().of(signatoryDrukAmlSchema).min(1).required(),
      otherwise: () => Yup.mixed().nullable(),
    }),
  }),
});

const LeadMerchantStep5 = Yup.object().shape({
  billingSystemCopy: Yup.number(),
  points: Yup.array().of(pointSchema).min(1).required(),
});

const LeadMerchantStep6 = Yup.object().shape({
  products: Yup.object().shape({
    items: Yup.array().min(1, 'Proszę wybrać przynajmniej 1 produkt').required(),
    comment: Yup.string().max(200, 'Maksymalnie 200 znaków').nullable(),
  }),
});

export const LeadMerchantStepSchemas = {
  step1: LeadMerchantStep1,
  step2: LeadMerchantStep2,
  step3: LeadMerchantStep3,
  step4: LeadMerchantStep4,
  step5: LeadMerchantStep5,
  step6: LeadMerchantStep6,
};

export const InvoiceNumberFormSchema = Yup.object().shape({
  invoiceNumber: Yup.string()
    .required('Proszę wprowadzić numer faktury')
    .matches(/^(?!\s+$).*/, {
      message: 'Proszę wprowadzić prawidłowy numer faktury',
      excludeEmptyString: true,
    }),
  issueDate: Yup.string().required('Data wystawienia faktury jest wymagana.'),
});

export const vacationSchema = Yup.object().shape({
  user: Yup.string().required(),
  deputyPh: Yup.string().nullable(),
  startDate: Yup.string().required(),
  endDate: Yup.string()
    .required()
    .test('later', 'Data zakończenia jest nieprawidłowa', function (end) {
      const start = this.parent.startDate;
      const tempEnd = moment(end).add(1, 'hour');
      if (end && start) return tempEnd.isAfter(moment(start));
      return true;
    }),
  active: Yup.boolean().required(),
});

export const transferLeadsBulkSchema = Yup.object().shape({
  transferFrom: Yup.array().min(1, 'Proszę podać przynajmniej jednego użytkownika').required(),
  transferTo: Yup.string().required('Proszę podać ID użytkownika docelowego'),
});

export const transferLeadSchema = Yup.object().shape({
  leadId: Yup.string().required('Lead ID jest wymagane'),
  transferUserId: Yup.string().required(),
});

export const marketingMaterialsSchema = Yup.object().shape({
  name: Yup.string().required(),
  marketingMaterial: Yup.mixed().required('Plik jest wymagany'),
  type: Yup.string().required(),
  marketingMaterial2: Yup.mixed()
    .when('type', {
      is: (val) => val === 'banner',
      then: (fieldSchema) => fieldSchema.required(),
    })
    .nullable(),
  link: Yup.string().url('Podaj prawdiłowy adres url').nullable(),
  active: Yup.boolean().required(),
});

export const trainingMaterialsSchema = Yup.object().shape({
  title: Yup.string().required(),
  type: Yup.string().required(),
  files: Yup.mixed()
    .when('type', {
      is: (val) => val === 'file',
      then: (fieldSchema) => fieldSchema.required('Plik jest wymagany'),
    })
    .nullable(),
  videoUrl: Yup.string()
    .when('type', {
      is: (val) => val === 'video',
      then: (fieldSchema) => fieldSchema.url('Podaj prawdiłowy adres url').required('Plik jest wymagany'),
    })
    .nullable(),
  active: Yup.boolean().required(),
});

export const integrationDocumentsSchema = Yup.object().shape({
  name: Yup.string().required(),
  integrationAssignmentDocuments: Yup.mixed().required('Plik jest wymagany'),
  active: Yup.boolean().required(),
  groups: Yup.array().of(Yup.string()).min(1).required('Grupa jest wymagana'),
});

export const zipcodeSchema = Yup.object().shape({
  county: Yup.string().required('Pole wymagane'),
  postCode: Yup.string()
    .matches(zipCodeRegex, {
      message: 'Niepoprawny format kodu pocztowego',
      excludeEmptyString: true,
    })
    .typeError('Niepoprawny format kodu pocztowego')
    .required(),
});

// Import / export
export const uploadCsv = Yup.object().shape({
  csv: Yup.mixed().required('Wymagany plik .csv'),
});

// auth
export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Błędny format adresu email').required('Podaj email'),
  password: Yup.string().required('Podaj hasło'),
});

export const setPasswordSchema = Yup.object({
  password: Yup.string().min(12, 'Minimum 12 znaków').max(24, 'Maksymalnie 24 znaki').required('Hasło jest wymagane'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Hasła muszą się zgadzać'),
});

export const resetPasswordSchema = Yup.object({
  email: Yup.string().email('Błędny format adresu email').required('Podaj email'),
});

// product
export const addProductSchema = Yup.object().shape({
  name: Yup.string().required(),
  active: Yup.mixed().oneOf(['0', '1']),
  // isVisible: Yup.mixed().when(['ambassadorVisible', 'softProducentVisible'], {
  //   is: (ambassador, soft) => {
  //     return ambassador === '1' || soft === '1' ? true : false;
  //   },
  //   then: () =>
  //     Yup.mixed().oneOf(
  //       ['0'],
  //       'Brak możliwości wyboru, jeżeli wybrano równocześnie: Widoczny dla Ambasadora lub Widoczny dla Producenta Soft',
  //     ),
  //   otherwise: () => Yup.mixed().oneOf(['0', '1']),
  // }),
  isVisible: Yup.mixed().oneOf(['0', '1']),
  isNew: Yup.mixed().oneOf(['0', '1']),
  ambassadorVisible: Yup.mixed().oneOf(['0', '1']),
  softProducentVisible: Yup.mixed().oneOf(['0', '1']),
  casherVisible: Yup.mixed().oneOf(['0', '1']),
  // oneTimeFee: Yup.number().min(0, 'Stawka nie może być ujemna').required(),
  // cyclicFee: Yup.number().min(0, 'Stawka nie może być ujemna').required(),
});

// challenge
export const challengeSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  special: Yup.boolean(),
  type: Yup.mixed().oneOf(['lead', 'terminal']).required('Proszę wybrać typ'),
  productsIds: Yup.array().min(1, 'Proszę wybrać przynajmniej 1 produkt').required(),
  dateFrom: Yup.string().required(),
  dateTo: Yup.string().required(),
  goal: Yup.number().positive('Tylko wartości dodatnie').required(),
  award: Yup.number().positive('Stawka nie może być ujemna').required().nullable(),
});

// user
export const usersBulkImportSchema = Yup.object().shape({});

export const editUserSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email('Błędny format adresu email').required('Podaj email'),
  phone: Yup.string()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu')
    .required('Telefon jest wymagany')
    .nullable(),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().min(8, 'Minimum 8 znaków').max(24, 'Maksymalnie 24 znaki').required(),
  newPassword: Yup.string().min(12, 'Minimum 12 znaków').max(24, 'Maksymalnie 24 znaki').required(),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Hasła muszą się zgadzać')
    .required(),
});

// admin
export const systemMessageSchema = Yup.object().shape({
  title: Yup.string().min(4, 'Minimum 4 znaki').max(48, 'Maksymalnie 48 znaków').required(),
  message: Yup.string().required(),
  group: Yup.mixed().oneOf(
    [
      'admin',
      'dsp',
      'dr',
      'merchantPep',
      'partner',
      'subPartner',
      '',
      'custom',
      'partnerAndSubPartner',
      'all',
      'partnerTypeLead',
      'partnerTypeInstallTerminal',
      'partnerTypeCanSigningContract',
      'partnerAmbasador',
    ],
    'Wybrano nieprawidłową grupę',
  ),
  userIds: Yup.array().when('group', {
    is: (val) => val === 'custom',
    then: (fieldSchema) => fieldSchema.required(),
  }),
  attachment: Yup.mixed()
    .test('fileSize', 'Plik jest za duży (do 10 MB)', (value) => {
      if (!value) return true;
      // 10MB
      return value && value.size <= 10000000;
    })
    .nullable(),
});

export const addApiKeySchema = Yup.object().shape({
  name: Yup.string().min(4, 'Minimum 4 znaki').max(48, 'Maksymalnie 48 znaków').required(),
  userId: Yup.string().required(),
});

export const editApiKeySchema = Yup.object().shape({
  name: Yup.string().min(4, 'Minimum 4 znaki').max(48, 'Maksymalnie 48 znaków').required(),
  active: Yup.mixed().oneOf(['0', '1']),
});

export const adminUserSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email('Błędny format adresu email').required('Podaj email'),
  phone: Yup.string()
    .required()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu')
    .nullable(),
  structureId: Yup.string().required(),
  patron: Yup.string().email('Błędny format adresu email').required('Podaj email'),
  roleId: Yup.string().required(),
  companyId: Yup.mixed()
    .when('roleSlug', {
      is: (val) => val === 'partner' || val === 'subPartner',
      then: (fieldSchema) => fieldSchema.required(),
    })
    .nullable(),
  leadMaster: Yup.mixed().nullable(),
  ph: Yup.string().nullable(),
  // leadMaster: Yup.string()
  //   .when('roleSlug', {
  //     is: val => val === 'partner' || val === 'subPartner',
  //     then: fieldSchema => fieldSchema.required(),
  //   })
  //   .nullable(),
  // ph: Yup.string()
  //   .when('roleSlug', {
  //     is: val => val === 'partner' || val === 'subPartner',
  //     then: fieldSchema => fieldSchema.required(),
  //   })
  //   .nullable(),
});

export const adminCompanySchema = Yup.object().shape({
  name: Yup.string().required(),
  nip: Yup.number()
    .typeError('Niepoprawny format NIP')
    .positive('Niepoprawny format NIP')
    .test('length', 'Niepoprawny format NIP', (value) => {
      if (value) {
        return value.toString().length === 10;
      }
      return true;
    })
    .test('format', 'Niepoprawny format NIP', (value) => validateNip(String(value)))
    .required(),
  // iban: Yup.string()
  //   .matches(/^[a-zA-Z]{0,2}\d{26}$/, {
  //     message: 'Niepoprawny format numeru iban',
  //     excludeEmptyString: true,
  //   })
  //   .nullable(),
  // regon: Yup.number()
  //   .typeError('Niepoprawny format REGON')
  //   .positive('Niepoprawny format REGON')
  //   .test('length', 'Niepoprawny format numeru REGON', value => {
  //     if (value) {
  //       return value.toString().length === 9;
  //     }
  //     return true;
  //   })
  //   .test('format', 'Niepoprawny format REGON', value => {
  //     if (value) {
  //       return REGON.isValid(String(value));
  //     }
  //     return true;
  //   })
  //   .nullable(),
  krs: Yup.string()
    .typeError('Niepoprawny format KRS')
    .test('length', 'Niepoprawny format numeru KRS', (value) => {
      if (value) return value.length === 10;
      return true;
    })
    .nullable(),
  postCode: Yup.string()
    .matches(zipCodeRegex, {
      message: 'Niepoprawny format kodu pocztowego',
      excludeEmptyString: true,
    })
    .typeError('Niepoprawny format kodu pocztowego')
    .nullable(),
  city: Yup.string().nullable(),
  street: Yup.string().nullable(),
  leadMaster: Yup.string().required().nullable(),
  dedicatedPhs: Yup.array().nullable(),
  harvesting: Yup.string().nullable(),
  numBuilding: Yup.string().nullable(),
  numLocal: Yup.string().nullable(),
  partnerProgram: Yup.array().max(1, 'Proszę wybrać tylko 1 typ').nullable(),
});

// Recommendation
export const recommendationSchema = Yup.object().shape({
  companyName: Yup.string().required(),
  nip: Yup.number()
    .typeError('Niepoprawny format NIP')
    .positive('Niepoprawny format NIP')
    .test('length', 'Niepoprawny format NIP', (value) => {
      if (value) {
        return value.toString().length === 10;
      }
      return true;
    })
    .test('format', 'Niepoprawny format NIP', (value) => validateNip(String(value)))
    .required(),
  city: Yup.string().required(),
  voivodeship: Yup.string().required(),
  contactPerson: Yup.string().required(),
  phone: Yup.string()
    .required()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu'),
  regulations: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
  consent: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
});

// lead
export const newLeadSchema = Yup.object().shape({
  companyName: Yup.string().required(),
  nip: Yup.string()
    .required()
    .test('length', 'Niepoprawny format NIP', (value) => {
      if (value) {
        return value.toString().length === 10;
      }
      return true;
    })
    .test('format', 'Niepoprawny format NIP', (value) => validateNip(String(value))),
  contactPerson: Yup.string().required(),
  phone: Yup.string()
    .required()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu'),
  postCode: Yup.string()
    .matches(zipCodeRegex, {
      message: 'Niepoprawny format kodu pocztowego',
      excludeEmptyString: true,
    })
    .typeError('Niepoprawny format kodu pocztowego')
    .required(),
  products: Yup.array().min(1, 'Proszę wybrać przynajmniej 1 produkt').required(),
  // agreement: Yup.mixed().required('Oświadczenie jest wymagane'),
  potentialPOS: Yup.number()
    .required('Potencjał jest wymagany')
    .typeError('Niepoprawny format')
    .positive('Liczba powinna być większa niż 0'),
  comment: Yup.string().nullable(),

  wenetEmail: Yup.string().when('products', {
    is: (products) => products.includes(ids.wenetProdId),
    then: () => Yup.string().email('Błędny format adresu email').required(),
    otherwise: () => Yup.string().nullable(),
  }),
});

export const updateLeadSchema = Yup.object().shape({
  companyName: Yup.string().required(),
  nip: Yup.number()
    .typeError('Niepoprawny format NIP')
    .positive('Niepoprawny format NIP')
    .test('length', 'Niepoprawny format NIP', (value) => {
      if (value) {
        return value.toString().length === 10;
      }
      return true;
    })
    .test('format', 'Niepoprawny format NIP', (value) => validateNip(String(value)))
    .required(),
  contactPerson: Yup.string().required(),
  phone: Yup.string()
    .required()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu'),
  postCode: Yup.string()
    .matches(zipCodeRegex, {
      message: 'Niepoprawny format kodu pocztowego',
      excludeEmptyString: true,
    })
    .typeError('Niepoprawny format kodu pocztowego')
    .required(),
  products: Yup.array().min(1, 'Proszę wybrać przynajmniej 1 produkt').required(),
  potentialPOS: Yup.number()
    .required('Potencjał jest wymagany')
    .typeError('Niepoprawny format')
    .positive('Liczba powinna być większa niż 0'),
  comment: Yup.string().nullable(),
  queue: Yup.string().nullable(),
});

// Partner
export const partnerUserSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  phone: Yup.string()
    .required()
    .test('length', 'Niepoprawny numeru telefonu', (value) => {
      if (value) return value.toString().length === 9;
      return true;
    })
    .matches(phoneRegex, 'Niepoprawny numeru telefonu'),
  email: Yup.string().email('Błędny format adresu email').required(),
});

// Chat
export const messageSchema = Yup.object().shape({
  text: Yup.string().required(),
  attachment: Yup.mixed().nullable(),
});

// Notes
export const noteSchema = Yup.object().shape({
  text: Yup.string().required(),
});

// Files form
export const addFilesSchema = Yup.object().shape({
  files: Yup.mixed().required(),
});
