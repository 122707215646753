import { default as PepIdLogo } from 'assets/pep_id_logo.png';
import { default as MojeIdLogo } from 'assets/moje_id_logo.png';

// Rozliczenia - krok 2
const billingSystemOptions = [
  // {
  //   display: 'Merchant',
  //   value: 1,
  //   // tooltip: 'Brakujący tooltip...'
  // },
  {
    display: 'Punkt',
    value: 2,
    // tooltip: 'Brakujący tooltip...'
  },
];
const invoicingSystemOptions = [
  {
    display: 'Merchant',
    value: 1,
    // tooltip: 'Brakujący tooltip...'
  },
  // {
  //   display: 'Punkt',
  //   value: 2,
  //   // tooltip: 'Brakujący tooltip...'
  // },
];
const serviceTypeOptions = [
  {
    display: 'Economic D+2',
    value: 1,
    // tooltip: 'Brakujący tooltip...'
  },
  {
    display: 'Silver D+1',
    value: 2,
    // tooltip: 'Brakujący tooltip...'
  },
  {
    display: 'Gold 24H',
    value: 3,
    // tooltip: 'Brakujący tooltip...'
  },
];
const pbgOptions = [
  { display: 'Tak', value: 1 },
  { display: 'Nie', value: 2 },
];

const pbgPromoPwobOptions = [
  { display: 'Nie dotyczy', value: 0 },
  { display: 'Terminal za 0 zł na 12 miesięcy', value: 1 },
  { display: 'Prowizja 0 % na 12 miesięcy', value: 2 },
];

const pbgPromoPwobTakNieOptions = [
  { display: 'Nie', value: 0 },
  { display: 'Tak', value: 1 },
];

const generalPromotionOptions = [
  { display: 'Nie', value: 0 },
  { display: 'Tak', value: 1 },
];

const periodOptions = [
  { display: '12 MSC', value: 1 },
  { display: '24 MSC', value: 2 },
  { display: '36 MSC', value: 3 },
];
const periodOptionsNo12 = [
  // { display: '12 MSC', value: 1 },
  { display: '24 MSC', value: 2 },
  { display: '36 MSC', value: 3 },
];

const businessLineOptions = [
  { display: 'Handel', value: 1 },
  { display: 'Usługi', value: 2 },
  { display: 'Inna', value: 3 },
];
const legalFormOptions = [
  {
    display: 'Działalność gospodarcza',
    value: 6,
    relations: { minOwners: 1, maxOwners: 1, minSignatory: 1, maxSignatory: 1 },
  },
  {
    display: 'Spółka cywilna',
    value: 1,
    relations: { minOwners: 2, maxOwners: 99999, minSignatory: 2, maxSignatory: 99999 },
    agreement: true,
  },
  {
    display: 'Spółka jawna',
    value: 2,
    relations: { minOwners: 0, maxOwners: 0, minSignatory: 1, maxSignatory: 99999 },
  },
  {
    display: 'Spółka z o.o.',
    value: 3,
    relations: { minOwners: 0, maxOwners: 0, minSignatory: 1, maxSignatory: 99999 },
  },
  {
    display: 'Spółka akcyjna',
    value: 4,
    relations: { minOwners: 0, maxOwners: 0, minSignatory: 1, maxSignatory: 99999 },
  },
  {
    display: 'Spółdzielnia',
    value: 7,
    relations: { minOwners: 0, maxOwners: 0, minSignatory: 1, maxSignatory: 99999 },
  },
  { display: 'Inna', value: 5, relations: { minOwners: 0, maxOwners: 0, minSignatory: 1, maxSignatory: 99999 } },
];

// Warunki handlowe - krok 3
const individualFeeOptions = [
  0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65,
];
const individualFixedFeeOptions = [0, 0.01, 0.02, 0.03, 0.04, 0.05];
const individualMarginOptions = [0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2];

// Contact - krok 4
const personFunctionOptions = [
  { display: 'Właściciel', value: 1 },
  { display: 'Współwłaściciel', value: 2 },
  { display: 'Prezes', value: 3 },
  { display: 'Wiceprezes', value: 4 },
  { display: 'Członek zarządu', value: 5 },
  { display: 'Prokurent', value: 6 },
  { display: 'Dyrektor generalny', value: 7 },
  { display: 'Pełnomocnik', value: 8 },
  { display: 'Dyrektor finansowy', value: 9 },
  { display: 'Główny księgowy', value: 10 },
  { display: 'Inna', value: 11 },
];
const personTypeOptions = [
  { display: 'Kontakt finansowy', value: 1 },
  { display: 'Kontakt operacyjny', value: 2 },
  { display: 'Kontakt biznesowy', value: 3 },
  { display: 'Właściciel', value: 4 },
  { display: 'Osoba zarządzająca', value: 5 },
  { display: 'Osoba podpisująca umowę / reprezentująca', value: 6 },
  { display: 'Rejestracja SIM ??', value: 7 },
];

// Points - krok 4
const terminalTypeOptions = [
  { display: 'Przenośny', value: 1 },
  { display: 'Stacjonarny', value: 2 },
  { display: 'Pinpad', value: 3 },
];
const terminalConnectionOptions = [
  { display: 'LAN', value: 1 },
  { display: 'GPRS', value: 2 },
];
const terminalInstallationOptions = [
  { display: 'PEP', value: 1 },
  { display: 'Partner', value: 2 },
];

const allSignatoryAreCitizensOfPolandOptions = [
  { display: 'Nie', value: 0 },
  { display: 'Tak', value: 1 },
];

const contractFormValues = {
  aplikacjaPepId: 'aplikacja-pep-id',
  mojeId: 'moje-id',
  weryfikacjaPapierowaKurier: 'weryfikacja-papierowa-kurier',
  weryfikachaTozsamosci: 'weryfikacja-tozsamosci',
};

const contractFormOptions = [
  { display: 'Aplikacja - PEP ID', value: contractFormValues.aplikacjaPepId, image: PepIdLogo },
  { display: 'Moje ID', value: contractFormValues.mojeId, image: MojeIdLogo },
  { display: 'Weryfikacja papierowa - kurier', value: contractFormValues.weryfikacjaPapierowaKurier, image: null },
  { display: 'Weryfikacja tożsamości', value: contractFormValues.weryfikachaTozsamosci, image: null },
];

const genericYesNoOptions = [
  { display: 'Nie', value: 0 },
  { display: 'Tak', value: 1 },
];

// Products - krok 5
// const productsAndServicesOptions = [
//   { display: 'Cashback', value: 1, tooltip: 'Usługa polega na...' },
//   { display: 'Moja paczka', value: 2, tooltip: 'Usługa polega na...' },
//   { display: 'Zwrot na hasło', value: 3, tooltip: 'Usługa polega na...' },
//   { display: 'Integracja z systemem kasowym', value: 4, tooltip: 'Usługa polega na...' },
//   { display: 'Doładowania elektroniczne', value: 5, tooltip: 'Usługa polega na...' },
//   { display: 'Wydawanie kuponów', value: 6, tooltip: 'Usługa polega na...' },
//   { display: 'PSC', value: 7, tooltip: 'Usługa polega na...' },
//   { display: 'Logo', value: 8, tooltip: 'Usługa polega na...' },
//   { display: 'Napiwki', value: 9, tooltip: 'Usługa polega na...' },
//   { display: 'Preautoryzacja', value: 10, exclusiveMcc: [1], tooltip: 'Usługa polega na...' },
// ];

const productsAndServicesOptions = [
  {
    display: 'Cashback',
    tooltip: 'Usługa Cash Back umożliwia Klientowi wypłatę gotówki w placówce naszego Akceptanta.',
    value: 1,
    nameValue: 'cashback',
  },
  {
    display: 'DCC - płatność w walucie karty',
    tooltip: 'Usługa DCC umożliwia klientom dokonanie zapłaty w walucie karty płatniczej na terminalu.',
    value: 2,
    nameValue: 'dcc_platnosc_w_walucie_karty',
  },
  {
    display: 'PrePaid - doładowania',
    tooltip:
      'Usługa ta pozwala na wygenerowania dodatkowego dochodu dzięki możliwości sprzedaży doładowań telefonicznych.',
    value: 3,
    nameValue: 'prepaid_doladowania',
  },
  {
    display: 'Moja paczka',
    tooltip:
      'To usługa na terminalu która umożliwia kompleksową obsługę przesyłek kurierskich nadawanych i odbieranych przez Klientów.',
    value: 4,
    nameValue: 'moja_paczka',
  },
  {
    display: 'Integracja - system kasowy',
    tooltip:
      'Usługa ta polega na połączeniu kasy fiskalnej lub systemu sprzedażowego z terminalem co powoduje przyspieszenie wykonywania transakcji – akceptant nie musi wprowadzać kwoty na terminalu.',
    value: 5,
    nameValue: 'integracja_system_kasowy',
  },
  {
    display: 'Płatność kartami',
    tooltip: 'To podstawowa usługa na naszych terminalach. Pozwala na przyjmowanie płatności bezgotówkowych.',
    value: 6,
    nameValue: 'platnosc_kartami',
  },
  {
    display: 'Diners',
    tooltip: 'Usługa umożliwia akceptację płatności za pomocą kart DINERS.',
    value: 7,
    nameValue: 'diners',
  },
  {
    display: 'Blik',
    tooltip:
      'To prosty, szybki i bezpieczny system płatności mobilnych. Umożliwia dokonanie płatności na terminalu bez konieczności użycia karty płatniczej.',
    value: 8,
    nameValue: 'blik',
  },
  {
    display: 'PaySafeCard',
    tooltip:
      'PaySafeCard to usługa polegająca na możliwości dokonywania płatności internetowych bez podawania szczegółów Twoich kart płatniczych (generowanie kodu).',
    value: 9,
    nameValue: 'paysafecard',
  },
  {
    display: 'Lojalność',
    tooltip:
      'Usługa o charakterze marketingowym i promocyjnym w celu budowania i podtrzymywania trwałych relacji z klientami.',
    value: 10,
    nameValue: 'lojalnosc',
  },
  {
    display: 'Logo',
    tooltip:
      'Usługa pozwala na zmianę logo na wydruku oraz treści marketingowych umieszczanych przez Akceptanta na Strefie Klienta.',
    value: 11,
    nameValue: 'logo',
  },
  {
    display: 'Zwrot na hasło',
    tooltip: 'Usługa ta pozwala na dokonanie zwrotu w przypadku reklamacji lub oddania towaru.',
    value: 12,
    nameValue: 'zwrot_na_haslo',
  },
  {
    display: 'Napiwki',
    tooltip: 'Usługa ta umożliwia pobranie płatności z doliczeniem napiwku.',
    value: 13,
    nameValue: 'napiwki',
  },
];

export default {
  billingSystemOptions,
  invoicingSystemOptions,
  serviceTypeOptions,
  pbgOptions,
  periodOptions,
  periodOptionsNo12,
  businessLineOptions,
  legalFormOptions,
  personFunctionOptions,
  personTypeOptions,
  terminalTypeOptions,
  terminalConnectionOptions,
  terminalInstallationOptions,
  productsAndServicesOptions,
  individualFeeOptions,
  individualFixedFeeOptions,
  individualMarginOptions,
  pbgPromoPwobOptions,
  pbgPromoPwobTakNieOptions,
  contractFormOptions,
  generalPromotionOptions,
  allSignatoryAreCitizensOfPolandOptions,
  contractFormValues,
  genericYesNoOptions,
};
