import React from 'react';
import PropTypes from 'prop-types';

const VimeoPlayer = ({ vimeoLink }) => {
  const idRegex = /[0-9]+/g;
  const vidId = vimeoLink.match(idRegex)?.[0] || false;

  if (!vidId) return null;
  return (
    <div>
      <div style={{ padding: '64.07% 0 0 0', position: 'relative' }}>
        <iframe
          src={`https://player.vimeo.com/video/${vidId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          title=""
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
};

VimeoPlayer.propTypes = {
  vimeoLink: PropTypes.string.isRequired,
};
export default VimeoPlayer;
