import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage, FieldArray, Field } from 'formik';
import { challengeService } from 'services/challenge';
import { challengeSchema } from 'utils/validation';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Checkbox from 'components/UI/Checkbox';
import Radio from 'components/UI/Radio';
import Loading from 'components/UI/Loading';
import Textarea from 'components/UI/Textarea';
import DateFromTo from 'components/UI/DateFromTo';
import NumberInput from 'components/UI/NumberInput';
import {
  FormWrapperCommon,
  Columns,
  FieldHeading,
  FormColumn,
} from 'components/forms/FormComponents';

const AddChallenge = ({ products, refetchChallenges, closePanel }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReset = resetForm => {
    setSuccess(false);
    setError('');
    resetForm({});
  };

  const handleSubmit = values => {
    const newValues = { ...values, productsIds: JSON.stringify(values.productsIds) };
    setError('');
    setLoading(true);
    challengeService
      .addChallenge(newValues)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchChallenges();
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {loading && <Loading absolute />}
      <Heading size="xl">Dodaj nowe wyzwanie</Heading>
      <Formik
        initialValues={{
          name: '',
          award: 0,
          description: '',
          type: 'lead',
          dateFrom: '',
          dateTo: '',
          special: false,
          productsIds: [],
          goal: 10,
        }}
        validationSchema={challengeSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>Dodałeś nowe wyzwanie</Heading>
                {typeof success !== 'boolean' && success}
                <div className="buttons">
                  <Button
                    onClick={() => {
                      handleReset(resetForm);
                      closePanel();
                    }}
                  >
                    Wróć
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Błąd, nie udało się dodać wyzwania</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReset(resetForm)}>Spróbuj ponownie</Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <Columns>
                <FormColumn>
                  <FieldHeading big size="m">
                    Wprowadź dane:
                  </FieldHeading>
                  <Input
                    name="name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nazwa"
                    value={values.name}
                    error={touched.name && errors.name}
                  />
                  <ErrorMessage component={ErrorLabel} name="name" />
                  <FieldHeading medium size="m">
                    Typ wyzwania
                  </FieldHeading>
                  <Radio
                    name="type"
                    value="lead"
                    onChange={() => setFieldValue('type', 'lead')}
                    label="Zgłoszone leady"
                    checked={values.type === 'lead'}
                  />
                  <Radio
                    name="type"
                    value="terminal"
                    onChange={() => setFieldValue('type', 'terminal')}
                    label="Zainstalowane terminale"
                    checked={values.type === 'terminal'}
                  />
                  <ErrorMessage style={{ marginTop: '5px' }} component={ErrorLabel} name="type" />
                  <FieldHeading size="m">Czas trwania</FieldHeading>
                  <DateFromTo
                    maxDate={false}
                    minDate={false}
                    handleDate={date => {
                      setFieldValue('dateFrom', date[0]);
                      setFieldValue('dateTo', date[1]);
                    }}
                  />
                  <ErrorMessage
                    style={{ marginTop: '15px' }}
                    component={ErrorLabel}
                    name="dateFrom"
                  />
                  <FieldHeading size="m">Ilość docelowa</FieldHeading>
                  <Field name="goal" component={NumberInput} />
                  <ErrorMessage component={ErrorLabel} name="goal" />
                  <Input
                    width="50%"
                    currency
                    name="award"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nagroda"
                    value={values.award || ''}
                    error={touched.award && errors.award}
                  />
                  <ErrorMessage component={ErrorLabel} name="award" />
                  <FieldHeading medium size="m">
                    Rodzaj
                  </FieldHeading>
                  <Checkbox
                    name="special"
                    type="checkbox"
                    onChange={e => {
                      if (e.target.checked) {
                        setFieldValue('special', true);
                      } else {
                        setFieldValue('special', false);
                      }
                    }}
                    onBlur={handleBlur}
                    label="Wyzwanie specjalne"
                    value={values.special}
                    checked={values.special}
                    error={touched.special && errors.special}
                  />
                  <ErrorMessage
                    style={{ marginTop: '5px' }}
                    component={ErrorLabel}
                    name="special"
                  />
                </FormColumn>
                <FormColumn>
                  <FieldHeading big size="m">
                    Wybierz produkty których dotyczyć ma wyzwanie
                  </FieldHeading>
                  <FieldArray
                    name="productsIds"
                    render={arrayHelpers => (
                      <div>
                        {products.map(item => (
                          <Checkbox
                            key={item.id}
                            name="productsIds"
                            onChange={e => {
                              if (e.target.checked) {
                                arrayHelpers.push(item.id);
                              } else {
                                const idx = values.productsIds.indexOf(item.id);
                                arrayHelpers.remove(idx);
                              }
                            }}
                            onBlur={handleBlur}
                            label={item.name}
                            value={item.id}
                            checked={
                              values.productsIds ? values.productsIds.includes(item.id) : false
                            }
                          />
                        ))}
                      </div>
                    )}
                  />
                  <ErrorMessage
                    style={{ marginTop: '5px' }}
                    component={ErrorLabel}
                    name="productsIds"
                  />
                  <FieldHeading size="m">Krótki opis wyzwania</FieldHeading>
                  <Textarea
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    locked
                    height="120px"
                    value={values.description}
                  />
                  <ErrorMessage component={ErrorLabel} name="description" />
                </FormColumn>
              </Columns>
              <Button
                style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }}
                type="submit"
              >
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

AddChallenge.defaultProps = {
  products: [],
};

AddChallenge.propTypes = {
  products: PropTypes.instanceOf(Array),
  refetchChallenges: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
};

export default AddChallenge;

// styled components

const Wrapper = styled(FormWrapperCommon)``;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
