import { combineReducers } from 'redux';
import adminReducer from './admin';
import chatReducer from './chat';
import leadReducer from './lead';
import partnerReducer from './partner';
import productReducer from './product';
import raportReducer from './raports';
import userReducer from './user';
import clientReducer from './client';
import challengeReducer from './challenge';
import collaborationReducer from './collaboration';
import marketingReducer from './marketing';
import integrationDocsReducer from './integrationDocs';
import invoicingReducer from './invoicing';
import socketReducer from './socket';
import notificationsReducer from './notifications';
import integrationOrdersReducer from './integrationOrders';
import clientPartnerReducer from './clientPartner';
import p24LeadReducer from './leadP24';
import optionsReducer from './options';
import trainingReducer from './training';

export default combineReducers({
  admin: adminReducer,
  chat: chatReducer,
  leads: leadReducer,
  partner: partnerReducer,
  products: productReducer,
  user: userReducer,
  raport: raportReducer,
  client: clientReducer,
  clientPartner: clientPartnerReducer,
  challenge: challengeReducer,
  collaboration: collaborationReducer,
  marketing: marketingReducer,
  socket: socketReducer,
  invoicing: invoicingReducer,
  notifications: notificationsReducer,
  integrationDocs: integrationDocsReducer,
  orders: integrationOrdersReducer,
  p24Leads: p24LeadReducer,
  options: optionsReducer,
  training: trainingReducer,
});
