import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Formik, Form, FieldArray, ErrorMessage, Field } from 'formik';
import { routes } from 'routes';
import { newLeadSchema } from 'utils/validation';
import { getAllProducts } from 'redux/product';
import { leadService } from 'services/lead';
import { FormWrapperCommon, Columns, FieldHeading, FormColumn } from 'components/forms/FormComponents';
import { jsConfetti } from 'components/logic/Confetti';
import { transformNipForAlior } from 'utils/helpers';
import { colors, font } from 'theme/styles';
import { utilityService } from 'services/utility';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Textarea from 'components/UI/Textarea';
import NumberInput from 'components/UI/NumberInput';
import Loading from 'components/UI/Loading';
import DateTimePicker from 'components/UI/DateTimePicker';
import HorizontalLine from 'components/UI/HorizontalLine';
import usePopup from 'hooks/usePopup';
import CopyString from 'components/UI/CopyString';
import ids from 'config/ids';

const generateAliorUrl = (user) => {
  if (!user || !user.company || !user.company.nip) return '';
  return `https://wniosek-rachunek-firmowy.aliorbank.pl/contact-data?partnerId=PEP_P_IKB_NEW&applicationId=${transformNipForAlior(
    user.company.nip,
  )}&pCode=BONUSPEP`;
};

const formatValues = (values) => {
  const products = JSON.stringify(
    values.products.map((product) => {
      return { id: product };
    }),
  );

  const newValues = { ...values, products };

  if (!values.products.includes(ids.wenetProdId)) delete newValues.wenetEmail;

  return newValues;
};

const validateProdcuts = (products, userData) => {
  let prods = products.filter((p) => p.active === '1' && p.isVisible === '1');

  if (['admin', 'dsp'].includes(userData.role.slug)) return prods;

  if (userData?.company?.partnerProgram) {
    const companyProgram = JSON.parse(userData.company.partnerProgram);
    prods = prods.filter((p) => {
      if (p.partnerProgram.length === 0) return true;
      if (p.partnerProgram.some((el) => companyProgram.includes(el))) return true;
    });
  } else prods = prods.filter((p) => p.partnerProgram.length === 0);

  return prods;
};

const AddLead = ({ getAllProducts, products, toggleClose, refetchLeads }) => {
  const formRef = useRef();
  const history = useHistory();

  const [AliorPopup, toggleAliorPopup, setIsVisibleAliorPopUp] = usePopup({ hideCloseButton: true });

  const user = useSelector((state) => state.user.data);
  const canUserSignContract = useSelector((state) => state.user.data.canSigningContract);

  const [askForForm, setAskForForm] = useState(false);
  const [sendWithForm, setSendWithForm] = useState(false);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [gusEmailFetched, setGusEmailFetched] = useState(false);
  const [wenetEmailFetchedWithValues, setWenetEmailFetchedWithValues] = useState(false);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const handleReturn = (doReset, resetForm) => {
    setSendWithForm(false);
    setSuccess(false);
    setError('');
    setAskForForm(false);
    setSendWithForm(false);
    if (doReset) resetForm({});
  };

  const handleClose = (resetForm) => {
    setTimeout(() => {
      setSuccess(false);
      setError('');
    }, 700);
    resetForm({});
    toggleClose();
  };

  const onSubmitClick = (values) => {
    let hasLeadTerminalProduct = false;
    const newValues = formatValues(values);
    const isAmbasador = user?.company?.partnerProgram?.includes('AMBASADOR');

    values.products.forEach((prodId) => {
      if ([ids.terminalPlatniczy, ids.terminalPlatniczyPolskaBezgotowkowa].includes(prodId)) hasLeadTerminalProduct = true;
    });

    if (newValues.products.includes(ids.aliorProductId) && isAmbasador) setIsVisibleAliorPopUp(true);

    if (canUserSignContract && hasLeadTerminalProduct) setAskForForm(true);
    else if (formRef.current) formRef.current.handleSubmit();
  };

  const handleSubmit = (values) => {
    const newValues = formatValues(values);
    if (sendWithForm) newValues.onboarding = 1;

    setError('');
    setLoading(true);
    setAskForForm(false);

    leadService
      .addLead(newValues)
      .then((data) => {
        console.log(data);

        const isAmbasador = user?.company?.partnerProgram?.includes('AMBASADOR');

        // Confetti logic
        const runValentinesConfetti = moment().isSame(moment('02/14/2025'), 'date');
        if (runValentinesConfetti) {
          jsConfetti.show();
          jsConfetti
            .addConfetti({
              emojis: ['❤️', '💖', '💘'],
              emojiSize: 25,
              confettiNumber: 50,
            })
            .finally(() => jsConfetti.hide());
        } else if (isAmbasador) {
          jsConfetti.show();
          jsConfetti.addConfetti().finally(() => jsConfetti.hide());
        }
        // END

        if (setAskForForm && data.data.signingContractId) {
          history.push(routes.leadOnboarding.replace(':id', data.data.signingContractId), {
            lead: data.data,
          });
        } else {
          setSuccess(
            data.data.status === 'duplicate'
              ? 'NIP znajduje się w systemach PeP. Skontaktuj się z opiekunem'
              : 'Zobaczysz go w liście swoich leadów',
          );
          refetchLeads();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
        setSendWithForm(false);
      });
  };

  const wenetLogic = useCallback(
    (values, setFieldValue) => {
      if (gusEmailFetched) return;

      if (values.nip)
        utilityService
          .getGusEmail(values.nip)
          .then((res) => {
            if (res.data.email) {
              setFieldValue('wenetEmail', res.data.email);
              setWenetEmailFetchedWithValues(true);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setGusEmailFetched(true);
          });
    },
    [gusEmailFetched],
  );

  return (
    <Wrapper>
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Dodaj potencjalnego klienta</Heading>
      <Formik
        innerRef={formRef}
        initialValues={{
          companyName: '',
          nip: '',
          contactPerson: '',
          phone: '',
          postCode: '',
          products: [],
          potentialPOS: 1,
          comment: '',
          // agreement: null,
          preferredContactDate: '',
          wenetEmail: '',
        }}
        validationSchema={newLeadSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, resetForm, setFieldValue }) => (
          <>
            {askForForm && (
              <SuccessPopup>
                <Heading style={{ marginBottom: '1rem' }}>Co chciałbyś zrobić?</Heading>
                <HorizontalLine style={{ marginTop: '10px' }} />
                <div className="buttons">
                  <Button
                    style={{ fontWeight: '600' }}
                    greenFilled
                    onClick={() => {
                      setSendWithForm(true);
                      formRef.current.handleSubmit();
                    }}
                  >
                    Uzupełnij dane do umowy
                  </Button>
                </div>
                <p>
                  Twoje uprawnienia pozwalają na samodzielne uzupełnienie danych do umowy. <br />
                  <small className="color-red">
                    Akcja jest opcjonalna, po przypisaniu do Przedstawiciela Handlowego nie będziesz mieć możliwości
                    uzupełnienia danych do umowy.
                  </small>
                </p>
                <HorizontalLine style={{ marginBottom: '10px' }} />
                <div className="buttons">
                  <Button style={{ fontWeight: '600' }} onClick={() => formRef.current.handleSubmit()}>
                    Przekaż Lead do <br /> Przedstawiciela PEP
                  </Button>
                  <Button style={{ fontWeight: '600' }} gray onClick={() => handleReturn(false, resetForm)}>
                    Anuluj wprowadzanie <br />
                    danych
                  </Button>
                </div>
              </SuccessPopup>
            )}

            {success && (
              <SuccessPopup>
                <Heading>Dodałeś Lead</Heading>
                <p>{success}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(true, resetForm)}>Dodaj kolejny</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </SuccessPopup>
            )}

            <AliorPopup title="Oferta Alior Bank">
              <div style={{ marginTop: '2rem', textAlign: 'center' }}>
                <p>Dodajesz lead z produktem Alior Bank, skopiuj swój indywidualny link i przekaż klientowi</p>
                <CopyString staticPosition text={generateAliorUrl(user)}>
                  {generateAliorUrl(user)}
                </CopyString>
              </div>
              <div className="buttons" style={{ marginTop: '4rem' }}>
                <Button onClick={toggleAliorPopup}>Potwierdzam przekazanie oferty klientowi</Button>
              </div>
            </AliorPopup>

            {error && (
              <ErrorPopup>
                <Heading>Błąd dodawania leada</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </ErrorPopup>
            )}

            <StyledForm>
              <Columns>
                <FormColumn>
                  <FieldHeading big size="m">
                    Wypełnij dane kontaktowe Firmy, którą chcesz zgłosić
                  </FieldHeading>
                  <Input
                    name="companyName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nazwa Firmy"
                    value={values.companyName || ''}
                    error={touched.companyName && errors.companyName}
                  />
                  <ErrorMessage component={ErrorLabel} name="companyName" />
                  <Input
                    name="nip"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer NIP"
                    value={values.nip || ''}
                    error={touched.nip && errors.nip}
                  />
                  <ErrorMessage component={ErrorLabel} name="nip" />
                  <Input
                    name="contactPerson"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Osoba Kontaktowa"
                    value={values.contactPerson || ''}
                    error={touched.contactPerson && errors.contactPerson}
                  />
                  <ErrorMessage component={ErrorLabel} name="contactPerson" />
                  <Input
                    name="phone"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer Telefonu"
                    value={values.phone || ''}
                    error={touched.phone && errors.phone}
                  />
                  <ErrorMessage component={ErrorLabel} name="phone" />
                  <Input
                    name="postCode"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Kod Pocztowy"
                    value={values.postCode || ''}
                    error={touched.postCode && errors.postCode}
                  />
                  <ErrorMessage component={ErrorLabel} name="postCode" />

                  {values.products.includes(ids.wenetProdId) && (
                    <>
                      {wenetLogic(values, setFieldValue)}
                      <Input
                        name="wenetEmail"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Adres e-mail klienta"
                        value={values.wenetEmail || ''}
                        error={touched.wenetEmail && errors.wenetEmail}
                      />
                      {wenetEmailFetchedWithValues && <InputInfo>Zaktualizuj adres jeśli jest nieprawidłowy</InputInfo>}
                      <ErrorMessage component={ErrorLabel} name="wenetEmail" />
                    </>
                  )}

                  <FieldHeading size="s">Potencjał docelowy (liczba terminali)</FieldHeading>
                  <Field name="potentialPOS" component={NumberInput} />
                  <ErrorMessage component={ErrorLabel} name="potentialPOS" />
                  <FieldHeading size="s">
                    W przypadku dodania leada, osoba kontaktowa otrzyma SMS z informacją dotyczącą przetwarzania danych
                    osobowych.
                  </FieldHeading>
                  {/* <FieldHeading size="s">Dodaj oświadczenie</FieldHeading>
                  <Field
                    name="agreement"
                    id="agreement"
                    accept={
                      isMobile ? 'image/png, image/jpg' : 'image/png, image/jpeg, application/pdf'
                    }
                    component={FileInput}
                  />
                  <ErrorMessage component={ErrorLabel} name="agreement" /> */}
                </FormColumn>
                <FormColumn>
                  <FieldHeading big size="m">
                    Wybierz produkt z oferty PeP, którym zainteresowany jest Twój kontakt
                  </FieldHeading>
                  <FieldArray
                    name="products"
                    render={(arrayHelpers) => (
                      <div>
                        {validateProdcuts(products, user).map((item) => {
                          const isNewString =
                            item.isNew === '1' ? <strong className="new-product"> nowość</strong> : null;

                          return (
                            <Checkbox
                              key={item.id}
                              name="products"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayHelpers.push(item.id);
                                } else {
                                  const idx = values.products.indexOf(item.id);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                              onBlur={handleBlur}
                              label={[item.name, isNewString]}
                              value={item.id}
                              checked={values.products ? values.products.includes(item.id) : false}
                            />
                          );
                        })}
                      </div>
                    )}
                  />
                  <ErrorMessage component={ErrorLabel} name="products" />
                  <FieldHeading size="s">Data preferowanego kontaktu</FieldHeading>
                  <DateTimePicker
                    handleDate={(date) => setFieldValue('preferredContactDate', date)}
                    initialDate={values.preferredContactDate || null}
                    resetOnInitialChange
                  />
                  <FieldHeading size="s">Komentarz do leada</FieldHeading>
                  <Textarea
                    name="comment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    locked
                    height="120px"
                    value={values.comment || ''}
                  />
                </FormColumn>
              </Columns>
              <Button
                disabled={!(isValid && dirty)}
                style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }}
                type="button"
                onClick={() => onSubmitClick(values)}
              >
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

AddLead.propTypes = {
  products: PropTypes.instanceOf(Array).isRequired,
  getAllProducts: PropTypes.func.isRequired,
  toggleClose: PropTypes.func.isRequired,
  refetchLeads: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products.data,
});

const mapDispatchToProps = {
  getAllProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLead);

const Wrapper = styled(FormWrapperCommon)``;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;

const InputInfo = styled.p`
  font-size: ${font.size.xs};
  color: ${colors.red};
  margin-bottom: 1.4rem;
`;
