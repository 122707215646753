import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { TableWrapperCommon, baseTableMixin } from 'components/layout/Tables/TableComponents';
import { formatMoney, resolveObjProp, displayDate, displayTime, displayTimeWithSeconds } from 'utils/helpers';
import NoData from 'components/UI/NoData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'theme/styles';
import CopyString from 'components/UI/CopyString';

const BaseTable = ({ headers, data, offset, handleRowClick, actions }) => {
  return (
    <TableWrapper>
      {data.length > 0 ? (
        <>
          <table>
            <colgroup>
              <col className="lp" />
              {headers.map((header) => (
                <col key={header.property} className={header.class ? header.class : header.property} />
              ))}
              {actions && <col className="actions" />}
            </colgroup>
            <thead>
              <tr>
                <th>LP</th>
                {headers.map((header) => (
                  <th className={header.headerClass} key={header.property}>
                    {header.displayName}
                  </th>
                ))}
                {actions && <th key="edit">Akcje</th>}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <StyledRow key={item.id || index} onClick={handleRowClick ? () => handleRowClick(item) : undefined}>
                  <td>{offset + index + 1}</td>
                  {headers.map((header) => {
                    const uniqueKey = item.id + header.property;
                    let propValue = resolveObjProp(header.property, item);
                    if (!propValue && header.or) propValue = resolveObjProp(header.or, item);
                    if (header.type === 'date' && propValue) propValue = displayDate(propValue);
                    if (header.type === 'date-time' && propValue) propValue = displayTime(propValue);
                    if (header.type === 'date-time-seconds' && propValue) propValue = displayTimeWithSeconds(propValue);
                    if (header.type === 'currency' && propValue) propValue = formatMoney(propValue);
                    if (header.type === 'on/off')
                      propValue =
                        propValue === true ? (
                          <strong className="color-green">Włączony</strong>
                        ) : (
                          <strong className="color-red">Wyłączony</strong>
                        );

                    if (header.type === 'status' && propValue)
                      propValue =
                        propValue === '1' || propValue === true ? (
                          <FontAwesomeIcon title="Aktywny" icon="check" />
                        ) : null;

                    if (header.mapValue) propValue = header.mapValue(header.property, propValue, item);

                    return (
                      <td
                        className={header.cellClass}
                        style={header.style || null}
                        key={uniqueKey}
                        title={header.titleProp ? resolveObjProp(header.titleProp, item) : null}
                      >
                        {propValue === null ? '-' : propValue}
                        {header.copy && <CopyString text={propValue} />}
                      </td>
                    );
                  })}
                  {actions && (
                    <td key="edit">
                      <span className="icons">
                        {actions.map((action) => (
                          <FontAwesomeIcon
                            key={item.id + action.icon}
                            title={action.title}
                            icon={action.icon}
                            color={action.color}
                            onClick={() => action.handler(item)}
                          />
                        ))}
                      </span>
                    </td>
                  )}
                </StyledRow>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <NoData message="Brak danych" />
      )}
    </TableWrapper>
  );
};
BaseTable.defaultProps = {
  handleRowClick: undefined,
  actions: false,
  offset: 0,
};

BaseTable.propTypes = {
  handleRowClick: PropTypes.func,
  headers: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number,
  data: PropTypes.instanceOf(Array).isRequired,
  actions: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool]),
};

export default BaseTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  ${baseTableMixin}
`;

const StyledRow = styled.tr`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${colors.green}10 !important;
      }
    `}
`;
