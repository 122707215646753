import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import spinner from 'assets/icons/spinner-solid.svg';

const Loading = ({ big, fixed, absolute, customStyle }) => (
  <Wrapper big={big} fixed={fixed} absolute={absolute} style={customStyle}>
    <Spinner big={big} src={spinner} alt="loading..." />
  </Wrapper>
);

Loading.defaultProps = {
  big: false,
  absolute: false,
  fixed: false,
  customStyle: {},
};

Loading.propTypes = {
  big: PropTypes.bool,
  absolute: PropTypes.bool,
  fixed: PropTypes.bool,
  customStyle: PropTypes.instanceOf(Object),
};

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  width: ${({ big }) => (big ? '100vw' : '100%')};
  height: ${({ big }) => (big ? '100vh' : '100%')};
  position: ${({ big }) => (big ? 'fixed' : 'static')};
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #ffffff50;
  /* backdrop-filter: blur(2px); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 20px 0;

  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
    `}

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
    `}
`;

const Spinner = styled.img`
  animation: ${rotate} 1s linear infinite;
  width: ${({ big }) => (big ? '80px' : '50px')};
  height: ${({ big }) => (big ? '80px' : '50px')};
  margin: 0;
  padding: 0;
`;

export default Loading;
