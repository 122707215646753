/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { connect } from 'react-redux';
import { getBillingDetails } from 'redux/invoicing';
import { TopSection } from 'components/layout/Tables/TableComponents';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import BilligsDetailsTable from 'components/layout/Tables/BilligsFixedTable';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';
import InvoiceFactory from 'utils/pdf';
import { displayTime, download } from 'utils/helpers';
import styled from 'styled-components';
import { invoicingService } from 'services/invoicing';

const mapTypValue = value => {
  const map = {
    D: 'Dochodowość',
    S: 'Sezon',
    L: 'Lead',
  };
  return map[value] || '';
};

const getHeaders = (billingFor, total) => {
  const showGroupJedn = total.RoE_WynagrJednWartosc > 0;
  const showGroupContract = total.RoE_WynagrJednPodpUmowyWartosc > 0;
  const showGroupInstalation = total.RoE_WynagrJednInstalacjaWartosc > 0;
  const showGroupCykl = total.RoE_WynagrCyklWartosc > 0;

  return [
    { displayName: 'Partner', property: 'RoE_Partner' },
    { displayName: 'NIP', property: 'RoE_NIP' },
    { displayName: 'TID', property: 'RoE_TID' },
    // { displayName: 'Data zawarcia Umowy', property: 'RoE_DataUmowy', type: 'date' },
    { displayName: 'Nazwa Potencjalnego Akceptanta', property: 'RoE_NazwaPotencjalnegoAkceptanta' },
    { displayName: 'Nip Potencjalnego Akceptanta ', property: 'RoE_NIPPotencjalnegoAkceptanta' },
    { displayName: 'Data rejestracji Leada', property: 'RoE_DataRejestracjiLeada' },
    { displayName: 'Typ', property: 'RoE_Typ', mapValue: mapTypValue },
    showGroupJedn && {
      displayName: 'Liczba terminali POS stanowiąca podstawę jednorazowego wynagrodzenia (CRM)',
      property: 'RoE_WynagrJednLiczbaPOS',
      sumHeader: true,
    },
    showGroupJedn && {
      displayName: 'Naliczona wartość jednorazowego wynagrodzenia (CRM)',
      property: 'RoE_WynagrJednWartosc',
      sumHeader: true,
      type: 'currency',
    },
    showGroupContract && {
      displayName: 'Liczba terminali POS stanowiąca podstawę jednorazowego wynagrodzenia za (podpisanie umowy)',
      property: 'RoE_WynagrJednPodpUmowyLiczbaPOS',
      sumHeader: true,
    },
    showGroupContract && {
      displayName: 'Naliczona wartość jednorazowego wynagrodzenia (podpisanie umowy)',
      property: 'RoE_WynagrJednPodpUmowyWartosc',
      sumHeader: true,
      type: 'currency',
    },
    showGroupInstalation && {
      displayName: 'Liczba terminali POS stanowiąca podstawę jednorazowego wynagrodzenia (instalacja terminala)',
      property: 'RoE_WynagrJednInstalacjaLiczbaPOS',
      sumHeader: true,
    },
    showGroupInstalation && {
      displayName: 'Naliczona wartość jednorazowego wynagrodzenia (instalacja terminala)',
      property: 'RoE_WynagrJednInstalacjaWartosc',
      sumHeader: true,
      type: 'currency',
    },
    showGroupCykl && {
      displayName: 'Liczba terminali POS stanowiąca podstawę wynagrodzenia cyklicznego (CRM)',
      property: 'RoE_WynagrCyklLiczbaPOS',
      sumHeader: true,
    },
    showGroupCykl && {
      displayName: `Naliczona wartość wynagrodzenia cyklicznego za ${billingFor}`,
      property: 'RoE_WynagrCyklWartosc',
      sumHeader: true,
      type: 'currency',
    },
  ].filter(Boolean);
};

const PartnerBillingsDetailsPage = ({ billings, isLoading, error, getBillingDetails, match, history, userRole }) => {
  useEffect(() => {
    getBillingDetails(match.params.id);
  }, [getBillingDetails, match.params.id]);

  const handleDownload = () => {
    const billing = new InvoiceFactory.Billings(billings);
    billing.build();
    billing.download();
  };

  const handleDownloadExcel = () => {
    invoicingService
      .getBillingXML(match.params.id)
      .then(res => {
        const fileName = `${slugify(billings.root.RoN_PartnerNazwa)}__${billings.root.RoN_PartnerNIP}__${
          billings.root.RoN_RozliczenieZa
        }.xlsx`;
        download(res, fileName);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <PageTemplate>
      {isLoading && <Loading />}
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => history.goBack()}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      {!isLoading && billings && !billings.root && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>Wystąpił nieoczekiwany błąd</p>
          <div className="buttons">
            <Button onClick={() => history.goBack()}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      {!isLoading && billings && billings.root && (
        <TopSection>
          <div>
            <Heading size="xxl">Partner - {billings.root.RoN_PartnerNazwa}</Heading>
            <br />
            <Heading size="xl">Rozliczenie za okres: {billings.root.RoN_RozliczenieZa} </Heading>
            {['admin', 'dsp', 'dr'].includes(userRole) && billings.root.RoN_DataPobraniaSP && (
              <p>Data otwarcia przez partnera: {displayTime(billings.root.RoN_DataPobraniaSP)}</p>
            )}
          </div>
          <ActionsWrapper>
            <Button onClick={handleDownloadExcel}>Pobierz plik Excel</Button>
            <Button onClick={handleDownload}>Pobierz PDF</Button>
          </ActionsWrapper>
        </TopSection>
      )}
      {!isLoading && billings && billings.data && billings.root && (
        <BilligsDetailsTable
          headers={getHeaders(billings.root.RoN_RozliczenieZa, billings.total)}
          data={billings.data}
          total={billings.total}
          root={billings.root}
          pods={billings.roz_pods}
        />
      )}
    </PageTemplate>
  );
};

PartnerBillingsDetailsPage.defaultProps = {
  error: null,
  billings: null,
};

PartnerBillingsDetailsPage.propTypes = {
  billings: PropTypes.instanceOf(Object),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  getBillingDetails: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  billings: state.invoicing.currentBilling.data,
  isLoading: state.invoicing.currentBilling.isLoading,
  error: state.invoicing.currentBilling.error,
  userRole: state.user.data.role.slug,
});

const mapDispatchToProps = {
  getBillingDetails,
};

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export default connect(mapStateToProps, mapDispatchToProps)(PartnerBillingsDetailsPage);
