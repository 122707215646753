import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConnect, setConnectError, setError } from 'redux/socket';
import { socket } from 'services/socket';
import ErrorPopup from 'components/layout/ErrorPopup';
import Heading from 'components/UI/Heading';
import Portal from 'components/logic/Portal';
import Button from 'components/UI/Button';

const SocketConnect = () => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.socket.error);
  const connectError = useSelector(state => state.socket.connectError);

  const errorMsg = typeof error === 'string' ? error : error.message || 'Wystapił błąd.';
  const connectErrorMsg =
    typeof connectError === 'string' ? connectError : connectError.message || 'Wystąpił błąd podczas próby połączenia.';

  // Przeniosełem logikę dla isUserLoaded do templatki (komponent nie zostanie wyrenderowany gdy !isUserLoaded)
  useEffect(() => {
    const onConnect = () => dispatch(setConnect(true));
    const onDisconnect = () => dispatch(setConnect(false));
    const onConnectError = err => dispatch(setConnectError(err));
    const onError = err => dispatch(setError(err));

    socket.connect();
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onConnectError);
    socket.on('error', onError);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onConnectError);
      socket.off('error', onError);
      socket.disconnect();
      socket.removeAllListeners();
    };
  }, [dispatch]);

  useEffect(() => {
    socket.connect();
    return () => socket.disconnect();
  }, []);

  // Tak te popupy sa chwilowo zakomentowane celowo
  return (
    <Portal>
      {false && error && (
        <ErrorPopup fixed>
          <Heading>Wystapił błąd</Heading>
          <p>{errorMsg}</p>
          <div className="buttons">
            <Button onClick={() => dispatch(setError(false))}>Zamknij</Button>
          </div>
        </ErrorPopup>
      )}
      {false && connectError && (
        <ErrorPopup fixed>
          <Heading>Wystapił problem z połączeniem</Heading>
          <p>{connectErrorMsg}</p>
          <div className="buttons">
            <Button onClick={() => dispatch(setConnectError(false))}>Zamknij</Button>
          </div>
        </ErrorPopup>
      )}
    </Portal>
  );
};

export default SocketConnect;
