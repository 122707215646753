import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsTypes } from 'config/constants';
import { socket } from 'services/socket';
import {
  clearReducer,
  clearNotifications,
  markAsReadedAll,
  markAsReadedOne,
  setNotifications,
  addNotification,
} from 'redux/notifications';
import { BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import { colors } from 'theme/styles';
import { setError } from 'redux/socket';
import usePagination from 'hooks/usePagination';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';
import Pagination from 'components/UI/Pagination';
import Select from 'components/UI/Select';
import Menu from 'components/UI/Menu';
import N from 'components/UI/Notifications';

const getIcon = type => notificationsTypes.find(el => el.name === type).icon || null;

const NotificationsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [pickedNotification, setPickedNotification] = useState(location.state?.showNotification || null);
  const dispatch = useDispatch();

  const [type, setType] = useState('');

  const isSocketConnected = useSelector(state => state.socket.connected);
  const notifications = useSelector(s => s.notifications.data);
  const notificationsCount = useSelector(s => s.notifications.count);
  const { pagination, handlePerPage, setPage, setPagination } = usePagination({
    perPage: 20,
    totalPages: 1,
  });

  useEffect(() => {
    if (location.state?.showNotification) setPickedNotification(location.state.showNotification);
  }, [location.state]);

  useEffect(() => {
    history.replace({ state: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSocketConnected) return () => {};

    const handleFn = response => {
      if (!response) return;
      if (response.data && response.data.length) dispatch(setNotifications(response.data, response.count));
      else if (response.id && response.type === type) dispatch(addNotification(response));
    };

    socket.on('notifications:list', handleFn);

    return () => {
      socket.off('notifications:list', handleFn);
      dispatch(clearReducer());
    };
    // Tu ma nie byc typu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSocketConnected]);

  useEffect(() => {
    if (!isSocketConnected) return () => {};
    dispatch(clearNotifications());
    socket.emit(
      'notifications:list',
      {
        offset: (pagination.page - 1) * (pagination.perPage || 20) || 0,
        limit: pagination.perPage,
        ...(type ? { type } : {}),
      },
      response => {
        if (!response) return;
        if (response.data && response.data.length) dispatch(setNotifications(response.data, response.count));
      },
    );
    return () => {};
  }, [dispatch, type, isSocketConnected, pagination.perPage, pagination.page]);

  useEffect(() => {
    if (!notificationsCount) return;
    setPagination(s => ({
      ...s,
      totalPages: Math.ceil(notificationsCount / Number(pagination.perPage)),
    }));
  }, [notificationsCount, pagination.perPage, setPagination]);

  const onReadHandler = (mode, id) => {
    if (!isSocketConnected) return;
    socket.emit(
      'notifications:mark-as-read',
      {
        ...(mode === 'all' && { markAllAsRead: true }),
        ...(mode === 'allLocal' && {
          notifications: JSON.stringify(notifications.filter(n => !n.isSeen).map(n => n.id)),
        }),
        ...(mode === 'single' && { notifications: JSON.stringify([id]) }),
      },
      response => {
        if (response.isOk) {
          if (mode === 'single') dispatch(markAsReadedOne(id));
          else dispatch(markAsReadedAll());
        } else {
          const error = response.error?.name || 'Wystąpił błąd.';
          dispatch(setError(error));
        }
      },
    );
  };

  const menuHandler = type => {
    setType(type);
    setPickedNotification(null);
  };

  return (
    <PageTemplate>
      <TopSection>
        <Heading style={{ marginBottom: '2rem' }} size="xxl">
          Powiadomienia
        </Heading>
      </TopSection>
      <Row gutter={20} style={{ flex: 1 }}>
        <Col lg={5}>
          <Menu>
            <Menu.Item isActive={type === ''} onClick={() => setType('')} icon="list">
              Wszystkie
            </Menu.Item>
            {notificationsTypes.map(t => (
              <Menu.Item
                icon={getIcon(t.name)}
                isActive={type === t.name}
                key={t.name}
                onClick={() => menuHandler(t.name)}
              >
                {t.display}
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <StyledCol lg={19}>
          {pickedNotification && (
            <>
              <Heading size="s">Pogląd powiadomienia</Heading>
              <div style={{ marginBottom: '2rem' }}>
                <N.Item
                  key={pickedNotification.id}
                  id={pickedNotification.id}
                  type={pickedNotification.type || ''}
                  title={pickedNotification.subject || ''}
                  text={pickedNotification.content || ''}
                  date={pickedNotification.createdAt}
                  attachment={pickedNotification.fileName}
                  advanced
                  marked
                  // onClick={() => onReadHandler('single', n.id)}
                />
              </div>
            </>
          )}
          <StyledList>
            {notifications.length > 0 ? (
              notifications
                .filter(n => n.id !== pickedNotification?.id)
                .map(n => (
                  <N.Item
                    key={n.id}
                    id={n.id}
                    type={n.type || ''}
                    title={n.subject || ''}
                    text={n.content || ''}
                    isSeen={Boolean(n.isSeen)}
                    date={n.createdAt}
                    advanced
                    attachment={n.fileName}
                    onClick={() => onReadHandler('single', n.id)}
                  />
                ))
            ) : (
              <Heading color={colors.mediumGray} centered size="xm">
                Brak powiadomień
              </Heading>
            )}
          </StyledList>
        </StyledCol>
      </Row>
      <BottomSection>
        <BottomItem>Wszystkich powiadomień: {notificationsCount}</BottomItem>
        <Pagination currentPage={Number(pagination.page)} maxPages={pagination.totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={pagination.perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
    </PageTemplate>
  );
};

NotificationsPage.propTypes = {};

export default NotificationsPage;

const StyledCol = styled(Col)`
  margin: 2rem 0;

  @media (min-width: 992px) {
    border-left: 1px solid ${colors.blueGray};
    margin: 0;
  }
`;

const StyledList = styled(N.List)`
  max-height: unset;

  @media (min-width: 992px) {
    max-height: 80vh;
    min-height: 80vh;
  }
`;
