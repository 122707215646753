import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TimePickerNpm from 'react-time-picker';
import { colors, font } from 'theme/styles';
import clearIcon from 'assets/icons/clear.svg';

const TimePicker = ({ setValue, value, disabled }) => {
  return (
    <Styles>
      <TimePickerNpm
        onChange={setValue}
        value={value}
        disabled={disabled}
        clearIcon={<ClearButton />}
        disableClock
        format="HH:mm"
        hourPlaceholder="GG"
        minutePlaceholder="MM"
      />
    </Styles>
  );
};

TimePicker.defaultProps = {
  value: '',
  disabled: false,
};

TimePicker.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TimePicker;

const Styles = styled.div`
  .react-time-picker__wrapper {
    border: none;
  }

  .react-time-picker__inputGroup {
    min-width: 5rem;
    text-align: center;
  }

  .react-time-picker__inputGroup {
    border-bottom: 1px solid ${colors.darkGray};
    font-size: ${font.size.s};
  }

  .react-time-picker__button {
    padding: 0;
  }
  .react-time-picker__inputGroup__divider {
    padding: 0 2px;
  }
  .react-time-picker__inputGroup__input {
  }

  input:focus {
    outline: none;
    border-bottom: 1px solid ${colors.orange};
    background-color: ${colors.orange}20;
  }
`;

const ClearButton = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${clearIcon});
  width: 1.8em;
  height: 1.8em;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 60% 60%;
  cursor: pointer;
`;
