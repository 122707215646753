import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllUsers, getUser, setCurrentUser, clearCurrentUser } from 'redux/partner';
import { breakpoints } from 'theme/styles';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import AddUser from 'components/forms/Partner/AddPartnerUser';
import EditUser from 'components/forms/Partner/EditPartnerUser';
import UsersTable from 'components/layout/Tables/UsersTable';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import { SortItem, BottomItem, BottomSection, TopSection, TableSorter } from 'components/layout/Tables/TableComponents';
import { mapUsersToCorespondingPartner } from 'utils/helpers';
import Select from 'components/UI/Select';

const headers = [
  { displayName: 'Sub-partner', property: 'fullName' },
  { displayName: 'Telefon', property: 'phone' },
  { displayName: 'Email', property: 'email' },
  { displayName: 'Aktywny', property: 'active' },
];

const headersMainPartner = [
  { displayName: '', property: 'feSubPartner' },
  { displayName: 'Imię i Nazwisko', property: 'fullName' },
  { displayName: 'Telefon', property: 'phone' },
  { displayName: 'Email', property: 'email' },
  { displayName: 'Rola', property: 'roleName' },
  { displayName: 'Aktywny', property: 'active' },
];

const PartnerUsersPage = ({
  getUser,
  getAllUsers,
  setCurrentUser,
  usersData,
  isLoading,
  error,
  currentUser,
  clearCurrentUser,
}) => {
  const me = useSelector(s => s.user.data);
  const history = useHistory();

  const [isNewUserVisible, setNewUserVisible] = useState(false);
  const [isEditUserVisible, setEditUserVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('active');

  // Redirect if no access
  if (me.company?.teamManagement !== '1') history.go(-1);

  const finalUsersData = useMemo(() => (me.mainPartner ? mapUsersToCorespondingPartner(usersData) : usersData), [
    usersData,
    me,
  ]);

  const getUsersData = useCallback(() => {
    const query = {
      ...(search && { search }),
      ...(status && { status }),
    };
    getAllUsers(query);
  }, [search, status, getAllUsers]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  const toggleNewUserPanel = () => {
    setNewUserVisible(!isNewUserVisible);
  };

  const closeEditUserPanel = () => {
    setEditUserVisible(false);
    clearCurrentUser();
  };

  const openEditUserPanel = id => {
    const localCurrentUser = usersData.find(user => user.id === id);
    if (localCurrentUser) {
      setCurrentUser(localCurrentUser);
      setEditUserVisible(true);
    } else {
      getUser(id);
    }
  };

  const handleSearch = value => setSearch(value);

  const handleTopBarElement = (e, set) => {
    set(e.target.value);
  };

  const showErrorPopup = error => {
    if (error.status === 401) return null;
    return <ErrorPopup fixed>{error.message}</ErrorPopup>;
  };

  return (
    <PageTemplate>
      <StyledTopSection>
        <Button onClick={toggleNewUserPanel}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj subpartnera
        </Button>
        <TableSorter>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={e => handleTopBarElement(e, setStatus)}>
              <option value="active">Aktywni</option>
              <option value="inactive">Nieaktywni</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </StyledTopSection>
      {!isLoading && error && showErrorPopup(error)}
      {isLoading ? (
        <Loading />
      ) : (
        <UsersTable
          handleEdit={openEditUserPanel}
          data={finalUsersData}
          headers={me.mainPartner ? headersMainPartner : headers}
          offset={0}
        />
      )}
      <BottomSection>
        <BottomItem>Użytkowników w zespole: {finalUsersData.length}</BottomItem>
      </BottomSection>
      <SidePanel small toggleClose={closeEditUserPanel} isVisible={isEditUserVisible}>
        {currentUser !== null && (
          <EditUser toggleClose={closeEditUserPanel} refetchUsers={getUsersData} userData={currentUser} />
        )}
      </SidePanel>
      <SidePanel small toggleClose={toggleNewUserPanel} isVisible={isNewUserVisible}>
        <AddUser refetchUsers={getUsersData} toggleClose={toggleNewUserPanel} />
      </SidePanel>
    </PageTemplate>
  );
};

PartnerUsersPage.defaultProps = {
  currentUser: null,
  error: null,
};

PartnerUsersPage.propTypes = {
  usersData: PropTypes.instanceOf(Array).isRequired,
  currentUser: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  clearCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.partner.users.isLoading,
  usersData: state.partner.users.data,
  currentUser: state.partner.currentUser.data,
  error: state.partner.users.error,
});

const mapDispatchToProps = {
  getAllUsers,
  getUser,
  setCurrentUser,
  clearCurrentUser,
};

const StyledTopSection = styled(TopSection)`
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.mobileTiny}) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(PartnerUsersPage);
