import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { routes } from 'routes';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { colors, font } from 'theme/styles';
import { getClientInfo, getClientHistory, getClientNotes } from 'redux/client';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import ErrorsWrapper from 'components/UI/ErrorsWrapper';
import ClientInfo from 'components/layout/Client/ClientInfo';
import ClientHistory from 'components/layout/Client/ClientHistory';
import ClientNotes from 'components/layout/Client/ClientNotes';
import { clientService } from 'services/client';
import SearchInput from 'components/UI/SearchInput';
// import ClientDocs from 'components/layout/Client/ClientDocs';

const ClientPage = ({
  match,
  location,
  userRole,
  isUserFromPep,
  getClientInfo,
  getClientHistory,
  getClientNotes,
  clientInfo,
  clientHistory,
  clientNotes,
  isLoadingClientInfo,
  isLoadingClientHistory,
  isLoadingClientNotes,
  errorClientInfo,
  errorClientHistory,
  errorClientNotes,
}) => {
  const [search, setSearch] = useState('');

  const filteredHistory = useMemo(() => {
    if (!search) return clientHistory;
    const temp = clientHistory;
    return temp.filter((log) => log.frontNumLead && log.frontNumLead.includes(search));
  }, [clientHistory, search]);

  const addNote = (values) => {
    clientService
      .addClientNote(match.params.id, values.text)
      .then(() => {
        getClientNotes(match.params.id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getClientInfo(match.params.id);
  }, [match.params.id, getClientInfo]);

  useEffect(() => {
    const subPage = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
    switch (subPage) {
      case 'history':
        getClientHistory(match.params.id);
        break;
      case 'notes':
        getClientNotes(match.params.id);
        break;
      case 'docs':
      default:
        break;
    }
  }, [getClientHistory, getClientNotes, location.pathname, match.params.id]);

  const infoMarkup = () => {
    if (isLoadingClientInfo) return <Loading />;
    if (errorClientInfo) return <ErrorsWrapper>{errorClientInfo.message}</ErrorsWrapper>;
    return <ClientInfo clientData={clientInfo} userRole={userRole} />;
  };

  const historyMarkup = () => {
    if (['merchantPep'].includes(userRole)) return null;
    if (isLoadingClientHistory) return <Loading />;
    if (errorClientHistory) return <ErrorsWrapper>{errorClientHistory.message}</ErrorsWrapper>;
    return <ClientHistory historyData={filteredHistory} />;
  };

  const notesMarkup = () => {
    if (isLoadingClientNotes) return <Loading />;
    if (errorClientNotes) return <ErrorsWrapper>{errorClientNotes.message}</ErrorsWrapper>;
    return (
      <ClientNotes
        notes={clientNotes}
        allowAddNote={isUserFromPep && !['guest'].includes(userRole)}
        handleSubmit={addNote}
      />
    );
  };

  return (
    <PageTemplate>
      <Wrapper>
        <ColLeft>
          <Block>{infoMarkup()}</Block>
        </ColLeft>
        <ColRight>
          <InnerHeader>
            {!['merchantPep'].includes(userRole) && <InnerNvButton to="history">Historia</InnerNvButton>}
            <InnerNvButton to="notes">Notatki</InnerNvButton>
            {['admin'].includes(userRole) && (
              <Route
                exact
                path={routes.clientHistory}
                render={() => (
                  <StyledSearchWrapper>
                    <SearchInput handleSearch={setSearch} value={search} />
                  </StyledSearchWrapper>
                )}
              />
            )}
          </InnerHeader>
          <Block>
            <Switch>
              <Route exact path={routes.clientHistory} render={historyMarkup} />
              <Route path={routes.clientNotes} render={notesMarkup} />
              {!['merchantPep'].includes(userRole) ? (
                <Redirect to={routes.clientHistory} />
              ) : (
                <Redirect to={routes.clientNotes} />
              )}
            </Switch>
          </Block>
        </ColRight>
      </Wrapper>
    </PageTemplate>
  );
};

ClientPage.defaultProps = {};

ClientPage.propTypes = {
  isUserFromPep: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  getClientInfo: PropTypes.func.isRequired,
  getClientHistory: PropTypes.func.isRequired,
  getClientNotes: PropTypes.func.isRequired,
  clientInfo: PropTypes.instanceOf(Object).isRequired,
  clientHistory: PropTypes.instanceOf(Object).isRequired,
  clientNotes: PropTypes.instanceOf(Object).isRequired,
  isLoadingClientInfo: PropTypes.bool.isRequired,
  isLoadingClientHistory: PropTypes.bool.isRequired,
  isLoadingClientNotes: PropTypes.bool.isRequired,
  errorClientInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  errorClientHistory: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  errorClientNotes: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
};

const mapStateToProps = (state) => ({
  isUserFromPep: Boolean(state.user.data.role.pep_structure),
  userRole: state.user.data.role.slug,
  clientInfo: state.client.info.data,
  clientHistory: state.client.history.data,
  clientNotes: state.client.notes.data,
  isLoadingClientInfo: state.client.info.isLoading,
  isLoadingClientHistory: state.client.history.isLoading,
  isLoadingClientNotes: state.client.notes.isLoading,
  errorClientInfo: state.client.info.error,
  errorClientHistory: state.client.history.error,
  errorClientNotes: state.client.notes.error,
});
const mapDispatchToProps = {
  getClientInfo,
  getClientHistory,
  getClientNotes,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientPage);

// styled components

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 940px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ColLeft = styled.section`
  margin-bottom: 3rem;

  @media (min-width: 940px) {
    margin-bottom: 0;
    flex: 0 1 calc(33.3333% - 15px);
    max-width: calc(33.3333% - 15px);
  }
`;

const ColRight = styled.section`
  @media (min-width: 940px) {
    flex: 0 1 calc(66.6666% - 15px);
    max-width: calc(66.6666% - 15px);
  }
`;

const Block = styled.div`
  width: 100%;
  box-shadow: 0px 3px 15px #00000010;
  /* padding: 30px 20px 30px 30px; */
  padding: 30px;
  border-radius: 5px;
  background: ${colors.white};
`;

const InnerHeader = styled.div`
  position: relative;
  background-color: ${colors.lighterGray};
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;

const InnerNvButton = styled(NavLink)`
  color: ${colors.darkGray};
  font-weight: ${font.weight.semibold};
  letter-spacing: 0.5px;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  font-size: ${font.size.m};
  text-decoration: none;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 0;
    background-color: ${colors.orange};
    bottom: -10px;
    left: 10px;
    transition: width 0.15s ease-in;
  }

  &.active,
  &:hover {
    color: ${colors.orange};

    &::before {
      width: calc(100% - 20px);
    }
  }
`;

const StyledSearchWrapper = styled.div`
  color: ${colors.darkGray};
  font-weight: ${font.weight.semibold};
  letter-spacing: 0.5px;
  display: inline-block;
  position: relative;
  font-size: ${font.size.m};
  text-decoration: none;
  user-select: none;
  margin-left: auto;
`;
