export const GET_INTEGRATION_DOCS_REQUEST = 'GET_INTEGRATION_DOCS_REQUEST';
export const GET_INTEGRATION_DOCS_SUCCESS = 'GET_INTEGRATION_DOCS_SUCCESS';
export const GET_INTEGRATION_DOCS_FAILURE = 'GET_INTEGRATION_DOCS_FAILURE';

export const GET_INTEGRATION_DOC_REQUEST = 'GET_INTEGRATION_DOC_REQUEST';
export const GET_INTEGRATION_DOC_SUCCESS = 'GET_INTEGRATION_DOC_SUCCESS';
export const GET_INTEGRATION_DOC_FAILURE = 'GET_INTEGRATION_DOC_FAILURE';

export const SET_CURRENT_INTEGRATION_DOC = 'SET_CURRENT_INTEGRATION_DOC';
export const CLEAR_CURRENT_INTEGRATION_DOC = 'CLEAR_CURRENT_INTEGRATION_DOC';

export const SET_INTEGRATION_DOC_UPLOAD_PROGRESS = 'SET_INTEGRATION_DOC_UPLOAD_PROGRESS';

export const CLEAR_INTEGRATION_DOC = 'CLEAR_INTEGRATION_DOC';

