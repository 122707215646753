import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getCronsList, setCronsListError } from 'redux/admin';
import { TopSection } from 'components/layout/Tables/TableComponents';
import { colors } from 'theme/styles';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';
import ExtendedTable from 'components/layout/Tables/ExtendedTable';
import usePopup from 'hooks/usePopup';
import { adminService } from 'services/admin';

const headers = [
  { displayName: 'Nazwa', property: 'name', titleProp: 'description' },
  { displayName: 'Interwał', property: 'interval' },
  { displayName: 'Status wykonania', class: 'wider', property: 'lastLaunchStatus', type: 'status' },
  { displayName: 'Stan', property: 'cronStatus', type: 'on/off' },
];

const AdminCron = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const crons = useSelector(state => state.admin.crons.data);
  const error = useSelector(state => state.admin.crons.error);
  const isLoading = useSelector(state => state.admin.crons.isLoading);

  const [ConfirmRunPopup, toggleConfirmRunPopup] = usePopup();
  const [ConfirmStatusPopup, toggleConfirmStatusPopup] = usePopup();
  const [runCron, setRunCron] = useState(null);
  const [statusCron, setStatusCron] = useState(null);

  useEffect(() => {
    dispatch(getCronsList());
  }, [dispatch]);

  const handleDetails = cron => {
    history.push(routes.adminCronDetails.replace(':id', cron.id), {
      cron,
    });
  };

  const handleAskRun = cron => {
    setRunCron(cron);
    toggleConfirmRunPopup();
  };

  const handleAskStatus = cron => {
    setStatusCron(cron);
    toggleConfirmStatusPopup();
  };

  const handleCancel = () => {
    setRunCron(null);
    toggleConfirmRunPopup();
  };

  const handleCancelStatus = () => {
    setStatusCron(null);
    toggleConfirmStatusPopup();
  };

  const handleRunManuallny = () => {
    adminService
      .executeCron(runCron.id)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.error(err);
        let error = 'Coś poszło nie tak. Skontaktuj się z administratorem.';
        if (err.response) error = err.response.data.error.message;
        dispatch(setCronsListError(error));
      })
      .finally(() => handleCancel());
  };

  const handleStatusChange = () => {
    const newStatusPropValue = statusCron.cronStatus ? 0 : 1;

    adminService
      .editCronStatus(statusCron.id, newStatusPropValue)
      .then(res => {
        console.log(res);
        dispatch(getCronsList());
      })
      .catch(err => {
        console.error(err);
        let error = 'Coś poszło nie tak. Skontaktuj się z administratorem.';
        if (err.response) error = err.response.data.error.message;
        dispatch(setCronsListError(error));
      })
      .finally(() => handleCancelStatus());
  };

  return (
    <PageTemplate>
      <ConfirmRunPopup>
        <Heading centered size="xl" style={{ marginBottom: '2rem' }}>
          Czy jesteś pewien, że chcesz ręcznie uruchomic CRON:
        </Heading>
        <p>{runCron ? runCron.name : ''}</p>
        <div className="buttons" style={{ marginTop: '3rem' }}>
          <Button onClick={handleRunManuallny} greenFilled>
            Tak
          </Button>
          <Button onClick={handleCancel}>Anuluj</Button>
        </div>
      </ConfirmRunPopup>

      <ConfirmStatusPopup>
        <Heading centered size="xl" style={{ marginBottom: '2rem' }}>
          Czy jesteś pewien, że chcesz
          {statusCron && statusCron.cronStatus ? (
            <strong className="color-red"> Wyłączyć </strong>
          ) : (
            <strong className="color-green"> Włączyć </strong>
          )}
          CRON:
        </Heading>
        <p>{statusCron ? statusCron.name : ''}</p>
        <div className="buttons" style={{ marginTop: '3rem' }}>
          <Button onClick={handleStatusChange} greenFilled>
            Tak
          </Button>
          <Button onClick={handleCancelStatus}>Anuluj</Button>
        </div>
      </ConfirmStatusPopup>

      {isLoading && <Loading big />}
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => dispatch(setCronsListError(null))}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      <TopSection>
        <Heading size="xxl">Crony systemowe</Heading>
      </TopSection>
      <ExtendedTable
        data={crons}
        headers={headers}
        actions={[
          { title: 'Wł/Wył', icon: 'bolt', handler: handleAskStatus, color: colors.red },
          { title: 'Szczegóły', icon: 'sign-in-alt', handler: handleDetails },
          { title: 'Aktywuj ręcznie', icon: 'play-circle', handler: handleAskRun, color: colors.greenText },
        ]}
      />
    </PageTemplate>
  );
};

export default AdminCron;
