/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableWrapperCommon, TableCommon } from 'components/layout/Tables/TableComponents';
import { colors } from 'theme/styles';
// import { invoiceStatus } from 'config/constants';
import { formatMoney, resolveObjProp, displayTime, displayDate } from 'utils/helpers';
import SimpleButton from 'components/UI/SimpleButton';
import NoData from 'components/UI/NoData';
import Loading from 'components/UI/Loading';

// const mapStatus = status => invoiceStatus.find(s => s.value === status)?.display || status.toLowerCase();
// const mapStatus = (status) => 'status';
// Propozycja - FvN_ID
// Samofaktura - DBF_Id

const InvoiceTable = ({
  userRole,
  headers,
  data,
  offset,
  handleTemplateDownload,
  handleTemplateReject,
  handleTemplateNumber,
  handleDownload,
  isLoading,
}) => (
  <TableWrapperCommon>
    <Table>
      <colgroup>
        <col className="narrow" />
        {headers.map(header => (
          <col key={header.property} />
        ))}
        <col className="wider" />
      </colgroup>
      <thead>
        <tr>
          <th>LP</th>
          {headers.map(header => (
            <th key={header.property}>{header.displayName}</th>
          ))}
          <th>Akcja</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 &&
          !isLoading &&
          data.map((item, index) => {
            const type = item.FvN_ID ? 'template' : 'invoice';

            return (
              <tr key={item.id || index}>
                <td className="center">{offset + index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = item.id + header.property;

                  let propValue = resolveObjProp(header.property, item);
                  if (!propValue && header.or) propValue = resolveObjProp(header.or, item);

                  if (header.type === 'date' && propValue) propValue = displayDate(propValue);
                  if (header.type === 'time' && propValue) propValue = displayTime(propValue);
                  if (header.type === 'currency' && propValue) propValue = formatMoney(propValue);

                  if (header.property === 'status.FvN_StatusOpis' && type === 'invoice')
                    propValue = item.DBF_DataPobraniaSP ? 'Wystawiona - pobrana' : 'Wystawiona';

                  if (header.property === 'type') propValue = type === 'invoice' ? 'Samofaktura' : 'Propozycja faktury';

                  return (
                    <td className={header.class} key={uniqueKey}>
                      {propValue || '—'}
                    </td>
                  );
                })}
                <td>
                  {type === 'invoice' && (
                    <SimpleButton gray={item.DBF_DataPobraniaSP} type="button" onClick={() => handleDownload(item)}>
                      Pobierz
                    </SimpleButton>
                  )}
                  {type === 'template' && (
                    <div style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                      {['admin', 'dsp', 'partner'].includes(userRole) && (
                        <SimpleButton gray type="button" onClick={() => handleTemplateDownload(item)}>
                          {[1, 2, 4].includes(item.FvN_StatusId) ? 'Podgląd' : 'Pobierz fakturę'}
                        </SimpleButton>
                      )}
                      {['admin', 'partner'].includes(userRole) && [1, 2].includes(item.FvN_StatusId) && (
                        <>
                          <SimpleButton gray type="button" onClick={() => handleTemplateReject(item)}>
                            Odrzuć
                          </SimpleButton>
                          <SimpleButton type="button" onClick={() => handleTemplateNumber(item)}>
                            Uzupełnij dane
                          </SimpleButton>
                        </>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
    {isLoading && <Loading />}
    {!isLoading && data.length === 0 && <NoData />}
  </TableWrapperCommon>
);

InvoiceTable.defaultProps = {
  offset: 0,
};

InvoiceTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  handleTemplateDownload: PropTypes.func.isRequired,
  handleTemplateNumber: PropTypes.func.isRequired,
  handleTemplateReject: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default InvoiceTable;

const Table = styled(TableCommon)`
  thead {
    tr {
      height: 50px;
      border-top: 1px solid ${colors.gray};
      border-bottom: 1px solid ${colors.gray};
    }
  }

  tbody {
    tr {
      cursor: default;
    }

    td {
      text-align: center;

      &.right {
        text-align: right;
      }

      &.left {
        text-align: left;
      }
    }
  }
`;
