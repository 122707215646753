export const GET_CHALLENGES_REQUEST = 'GET_CHALLENGE_REQUEST';
export const GET_CHALLENGES_SUCCESS = 'GET_CHALLENGE_SUCCESS';
export const GET_CHALLENGES_FAILURE = 'GET_CHALLENGE_FAILURE';

export const GET_PARTNER_CHALLENGE_REQUEST = 'GET_PARTNER_CHALLENGE_REQUEST';
export const GET_PARTNER_CHALLENGE_SUCCESS = 'GET_PARTNER_CHALLENGE_SUCCESS';
export const GET_PARTNER_CHALLENGE_FAILURE = 'GET_PARTNER_CHALLENGE_FAILURE';

export const SET_CURRENT_CHALLENGE = 'SET_CURRENT_CHALLENGE';
export const CLEAR_CURRENT_CHALLENGE = 'CLEAR_CURRENT_CHALLENGE';

export const RESET_CHALLENGES = 'RESET_CHALLENGES';
