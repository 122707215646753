import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import { editUserSchema } from 'utils/validation';
import { userService } from 'services/user';
import { getUserInfo,  } from 'redux/user';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import ErrorLabel from 'components/UI/ErrorLabel';
import Heading from 'components/UI/Heading';
import SuccessPopup from 'components/layout/SuccessPopup';

const EditProfile = ({ userData, getUserInfo }) => {
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleReturn = () => {
    setSuccess(false);
  };

  const handleSubmit = values => {
    userService
      .update(values)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setEdit(false);
        getUserInfo();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const { name, surname, email, phone } = userData;
  
  return (
    <Wrapper>
      {success && (
        <SuccessPopup fixed>
          <Heading>Profil zaktualizowany</Heading>
          <div className="buttons">
            <Button onClick={handleReturn}>Zamknij</Button>
          </div>
        </SuccessPopup>
      )}
      <Formik
        initialValues={{
          name,
          surname,
          email,
          phone,
        }}
        validationSchema={editUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Input
              name="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Imię"
              disabled={!edit}
              value={values.name || ''}
              error={touched.name && errors.name}
            />
            <ErrorMessage component={ErrorLabel} name="name" />
            <Input
              name="surname"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Nazwisko"
              disabled={!edit}
              value={values.surname || ''}
              error={touched.surname && errors.surname}
            />
            <ErrorMessage component={ErrorLabel} name="surname" />
            <Input
              name="phone"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Numer Telefonu"
              disabled={!edit}
              value={values.phone || ''}
              error={touched.phone && errors.phone}
            />
            <ErrorMessage component={ErrorLabel} name="phone" />
            <Input
              name="email"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Adres email"
              disabled={!edit}
              value={values.email || ''}
              error={touched.email && errors.email}
            />
            <ErrorMessage component={ErrorLabel} name="email" />
            <ButtonWrapper>
              <Button small gray={!edit} secondary={edit} type="button" onClick={toggleEdit}>
                {edit ? 'Anuluj' : 'Edytuj profil'}
              </Button>
              {edit ? <Button small type="submit">Zapisz</Button> : ''}
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

EditProfile.defaultProps = {
  userData: {
    name: '',
    surname: '',
    email: '',
    phone: '',
  },
};

EditProfile.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  getUserInfo: PropTypes.func.isRequired,
};

export default connect(null, { getUserInfo })(EditProfile);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & > ${Button} {
    min-width: 140px;
    margin-right: 20px;
  }
`;
