import axios from 'axios';
import { leadService } from 'services/lead';
import * as types from './types';

export const setOnboarding = (data) => (dispatch) => {
  dispatch({ type: types.SET_LEAD_ONBOARDING_DATA, data });
};

export const getOnboarding = (onboardingId) => (dispatch) => {
  dispatch({ type: types.GET_LEAD_ONBOARDING_REQUEST });
  return leadService
    .getOnboarding(onboardingId)
    .then((payload) => {
      dispatch({ type: types.GET_LEAD_ONBOARDING_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_LEAD_ONBOARDING_FAILURE, err });
    });
};

export const getLead = (id) => (dispatch) => {
  dispatch({ type: types.GET_LEAD_REQUEST });
  return leadService
    .getLead(id)
    .then((payload) => {
      dispatch({ type: types.GET_LEAD_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_LEAD_FAILURE, err });
    });
};

export const getSortedLeads = (query, signal) => (dispatch) => {
  dispatch({ type: types.GET_SORTED_LEAD_REQUEST });

  return leadService
    .getSortedLeads(query, signal)
    .then((payload) => {
      dispatch({ type: types.GET_SORTED_LEAD_SUCCESS, payload });
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        // dispatch({ type: types.GET_SORTED_LEAD_CANCEL });
      } else {
        dispatch({ type: types.GET_SORTED_LEAD_FAILURE, err });
      }
    });
};

export const updateCurrentLead = (updatedLead) => (dispatch) => {
  dispatch({ type: types.UPDATE_CURRENT_LEAD, payload: updatedLead });
};

export const clearCurrentOnboarding = () => (dispatch) => {
  dispatch({ type: types.CLEAR_CURRENT_ONBOARDING });
};

export const clearLeads = () => (dispatch) => {
  dispatch({ type: types.CLEAR_LEADS });
};
