/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect, useLocation, Link } from 'react-router-dom';
import { routes } from 'routes';
import { Formik, Form, ErrorMessage } from 'formik';
import { loginSchema } from 'utils/validation';
import { authenticate } from 'redux/user';
import { font, colors } from 'theme/styles';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import AuthTemplate from 'templates/AuthTemplate';
import ErrorsWrapper from 'components/UI/ErrorsWrapper';
import Loading from 'components/UI/Loading';

const LoginPage = ({ authenticate, isUserLoaded, loginError, isLoading }) => {
  const handleSubmit = values => {
    authenticate(values.email, values.password);
  };
  const location = useLocation();

  const targetUrl = () => {
    if (location.state) {
      if (location.state.pathname !== '/contact' && location.state.pathname !== '/admin') {
        return location.state.pathname;
      }
    }
    return routes.home;
  };

  return (
    <AuthTemplate>
      {isLoading ? <Loading absolute /> : ''}
      <Heading centered>Witaj w Strefie Partnera Polskich ePłatności</Heading>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm({});
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => {
          if (isUserLoaded) {
            return <Redirect to={targetUrl()} />;
          }
          return (
            <StyledForm>
              <Input
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Email"
                value={values.email || ''}
                error={touched.email && errors.email}
              />
              <ErrorMessage component={ErrorLabel} name="email" />
              <Input
                name="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Hasło"
                value={values.password || ''}
                error={touched.password && errors.password}
              />
              <ErrorMessage component={ErrorLabel} name="password" />
              {loginError && <ErrorsWrapper>{loginError.message}</ErrorsWrapper>}
              <Button style={{ marginTop: '20px' }} centered type="submit">
                Zaloguj się
              </Button>
            </StyledForm>
          );
        }}
      </Formik>
      <StyledLink to={routes.resetPassword}>Zapomniałeś hasła?</StyledLink>
    </AuthTemplate>
  );
};

const mapStateToProps = state => ({
  isUserLoaded: state.user.isUserLoaded,
  isLoading: state.user.isLoading,
  loginError: state.user.loginError,
});

const mapDispatchToProps = {
  authenticate,
};

LoginPage.defaultProps = {
  loginError: null,
};

LoginPage.propTypes = {
  authenticate: PropTypes.func.isRequired,
  isUserLoaded: PropTypes.bool.isRequired,
  loginError: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

// styled components

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
`;

const StyledLink = styled(Link)`
  position: relative;
  bottom: -30px;
  font-size: ${font.size.s};
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  color: ${colors.darkGray};

  &:hover {
    color: ${colors.red};
  }
`;
