import styled, { css } from 'styled-components';
import { colors } from 'theme/styles';

const Button = styled.button`
  background: transparent;
  border: 1px solid transparent;
  color: ${colors.red};
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 2px 8px;
  border-radius: 4px;
  transition: 0.5s ease;

  &:not(:disabled):hover {
    cursor: pointer;
    border-color: ${colors.red};
  }

  &:disabled {
    cursor: default;
    color: ${colors.gray};
  }

  ${({ underline }) =>
    underline &&
    css`
      position: relative;

      &::after {
        position: absolute;
        content: '';
        display: block;
        bottom: 0;
        left: 8px;
        width: calc(100% - 16px);
        height: 1px;
        background-color: currentColor;
        opacity: 0;
        transition: opacity 0.3s;
      }
      &:not(:disabled):hover {
        border-color: transparent;
      }
      &:not(:disabled):hover::after {
        opacity: 1;
      }
    `}

  ${({ noPadding, underline }) =>
    noPadding &&
    underline &&
    css`
      padding: 2px 0;

      &::after {
        width: 100%;
        left: 0;
      }
    `}

  ${({ gray }) =>
    gray &&
    css`
      color: ${colors.mediumGray};

      &:hover {
        color: ${colors.red};
      }
    `}

  ${({ blue }) =>
    blue &&
    css`
      color: ${colors.notiBlueAccent};
    `}
`;

export default Button;
