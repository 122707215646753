import * as types from './types';

const initialState = {
  statistics: {
    isLoading: false,
    data: [],
    error: null,
  },
  settlement: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LEADS_STATISTICS_REQUEST:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isLoading: true,
        },
      };
    case types.GET_LEADS_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_LEADS_STATISTICS_FAILURE:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_SETTLEMENT_STATISTICS_REQUEST:
      return {
        ...state,
        settlement: {
          ...state.settlement,
          isLoading: true,
        },
      };
    case types.GET_SETTLEMENT_STATISTICS_SUCCESS:
      return {
        ...state,
        settlement: {
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_SETTLEMENT_STATISTICS_FAILURE:
      return {
        ...state,
        settlement: {
          ...state.settlement,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.CLEAR_STATISTICS:
      return initialState;
    default:
      return state;
  }
};

export default statisticReducer;
