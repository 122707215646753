import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileDropZone from 'components/UI/FileDropzone';
import { Form, Formik } from 'formik';
import { humanFileSize } from 'utils/helpers';
import { addFilesSchema } from 'utils/validation';
import { FieldHeading } from 'components/forms/FormComponents';
import ErrorLabel from 'components/UI/ErrorLabel';
import Button from 'components/UI/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapTypeToIcon } from 'config/constants';
import { colors } from 'theme/styles';

const AddAttachments = ({ label, boxLabel, handleSubmit, maxSize = 5000000, accept }) => {
  const [addFileError, setAddFileError] = useState('');

  return (
    <Formik
      onSubmit={({ files }, resetForm) => {
        handleSubmit({ files });
        resetForm();
      }}
      initialValues={{
        files: null,
      }}
      validationSchema={addFilesSchema}
    >
      {({ values, errors, handleBlur, setFieldValue, handleSubmit }) => (
        <Form>
          {label && (
            <FieldHeading mb size="m">
              {label}
            </FieldHeading>
          )}
          <FileDropZone
            maxSize={maxSize}
            handleBlur={handleBlur}
            onDrop={(acceptedFiles, fileRejections) => {
              fileRejections.forEach(file => {
                file.errors.forEach(err => {
                  if (err.code === 'file-too-large')
                    setAddFileError(`Plik jest za duży, maksymalnie ${humanFileSize(maxSize)}`);
                  if (err.code === 'file-invalid-type') setAddFileError('Plik ma niepoprawny format, tylko pliki PDF');
                });
              });

              if (acceptedFiles.length > 0) {
                setAddFileError('');
                setFieldValue('files', acceptedFiles);
              }
            }}
            multiple
            name="files"
            accept={accept}
            label={boxLabel}
          />
          {addFileError && <ErrorLabel style={{ marginTop: '2rem' }}>{addFileError}</ErrorLabel>}
          {errors.files && <ErrorLabel style={{ marginTop: '2rem' }}>{errors.files}</ErrorLabel>}
          {values.files?.length > 0 && (
            <AddingFilesWrapper>
              {values.files.map(f => (
                <File key={f.name}>
                  <FontAwesomeIcon size="lg" title={`Typ pliku - ${f.type}`} icon={mapTypeToIcon[f.type] || 'file-upload'} />
                  <small>{f.name}</small>
                  <small className="color-orange">({humanFileSize(f.size)})</small>
                </File>
              ))}
              <Button  green style={{ marginTop: '2rem' }} onClick={handleSubmit}>
                Wyślij pliki
              </Button>
            </AddingFilesWrapper>
          )}
        </Form>
      )}
    </Formik>
  );
};

AddAttachments.defaultProps = {
  maxSize: 5000000,
  accept: 'image/*',
  label: 'Dodaj załącznik',
  boxLabel: 'Dodaj',
};

AddAttachments.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  accept: PropTypes.string,
  label: PropTypes.string,
  boxLabel: PropTypes.string,
};

const AddingFilesWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;

  svg {
    color: ${colors.orange};
    margin-right: 0.5em;
  }
`;

const File = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  flex-wrap: wrap;
`;

export default AddAttachments;
