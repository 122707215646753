import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getTrainingMaterials,
  getTrainingMaterial,
  clearCurrentTrainingMaterial,
  setCurrentTrainingMaterial,
  clearTraining,
} from 'redux/training';
import { trainingService } from 'services/training';
import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import { download } from 'utils/helpers';
import { routes } from 'routes';
import usePagination from 'hooks/usePagination';
import PageTemplate from 'templates/PageTemplate';
import TrainingMaterialsTable from 'components/layout/Tables/TrainingMaterialsTable';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import Pagination from 'components/UI/Pagination';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import SuccessPopup from 'components/layout/SuccessPopup';
import TrainingForm from 'components/forms/TrainingForm';

const getHeaders = (userRole) =>
  [
    { displayName: 'Data modyfikacji', property: 'updatedAt' },
    { displayName: 'Nazwa', property: 'title' },
    { displayName: 'Pliki', property: 'download' },
    { displayName: 'Wideo', property: 'vimeo' },
    ['admin', 'dsp'].includes(userRole) && { displayName: 'Aktywny', property: 'active' },
  ].filter(Boolean);

const TrainingMaterialsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pagination, handlePerPage, setPage, setPagination } = usePagination();

  const user = useSelector((s) => s.user.data);
  const userRole = user?.role?.slug;

  const trainingData = useSelector((s) => s.training.trainings.data.rows);
  const currentTrainingData = useSelector((s) => s.training.currentTraining.data);
  const trainingPagination = useSelector((s) => s.training.trainings.data.pagination);
  const count = useSelector((s) => s.training.trainings.data.count);
  const isLoading = useSelector((s) => s.training.isLoading);
  const error = useSelector((s) => s.training.error);

  const [isAddNewVisible, setIsAddNewVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);

  const [deletedError, setDeletedError] = useState('');
  const [deleted, setDeleted] = useState('');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('true');

  const handleCanEdit = () => {
    if (['admin'].includes(userRole)) return true;
    return false;
  };

  const getTrainingMaterialsData = useCallback(() => {
    const query = {
      limit: pagination.perPage,
      page: pagination.page,
      ...(status && { showActive: status }),
      ...(search && { search: search }),
    };
    dispatch(getTrainingMaterials(query));
  }, [dispatch, pagination.perPage, pagination.page, status, search]);

  useEffect(() => {
    getTrainingMaterialsData();
    return () => dispatch(clearTraining());
  }, [dispatch, getTrainingMaterialsData]);

  // Update paginations state based on redux
  useEffect(() => {
    setPagination((prevPag) => ({
      ...prevPag,
      ...trainingPagination,
      count,
    }));
  }, [count, trainingPagination, setPagination]);

  const toggleNewPanel = () => setIsAddNewVisible(!isAddNewVisible);

  const closeEditPanel = () => {
    setEditVisible(false);
    dispatch(clearCurrentTrainingMaterial());
  };

  const getMaterial = (id) => {
    const local = trainingData.find((u) => u.id === id);
    if (local) dispatch(setCurrentTrainingMaterial(local));
    else getTrainingMaterial(id);
  };

  const openEditPanel = (id) => {
    getMaterial(id);
    setEditVisible(true);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setPage(1);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setPage(1);
  };

  const handleDelete = (id) => {
    setDeletedError('');
    trainingService
      .deleteTrainingMaterial(id)
      .then(() => {
        setDeleted('');
        getTrainingMaterialsData();
      })
      .catch((err) => {
        console.dir(err);
        setDeletedError(err.response.data.error.message);
      });
  };

  const handleDownload = (trainingItem) => {
    let { resources = [] } = trainingItem;
    const filesResources = resources.filter((r) => r.resourceType !== 'VIDEO_URL');

    const catchHandler = (err) => console.dir(err);

    if (filesResources.length === 1) {
      trainingService
        .getTrainingMaterialFile(filesResources[0].id)
        .then((res) => download(res, filesResources[0].fileName))
        .catch(catchHandler);
    } else {
      trainingService
        .getTrainingMaterialFilesZip(trainingItem.id)
        .then((res) => download(res, `${trainingItem.title}.zip`))
        .catch(catchHandler);
    }
  };

  const handleVimeo = (trainingItem) => {
    let { resources = [] } = trainingItem;
    const videoResources = resources.filter((r) => r.resourceType === 'VIDEO_URL');
    const videoResource = videoResources[0] || false;
    if (!videoResource) return false;
    history.push(routes.player, {
      videoLink: videoResource.url,
      title: trainingItem.title,
      description: trainingItem.description,
    });
  };

  const showDeletePopup = (deleted) => (
    <SuccessPopup fixed>
      <p>Czy na pewno chcesz usunąć</p>
      <p>
        <strong>{deleted.title} </strong>
      </p>
      {deletedError && <p style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{deletedError}</p>}
      <div className="buttons">
        <Button
          secondary
          onClick={() => {
            setDeletedError('');
            setDeleted('');
          }}
        >
          Anuluj
        </Button>
        <Button onClick={() => handleDelete(deleted.id)}>{deletedError ? 'Spróbuj ponownie' : 'Usuń'}</Button>
      </div>
    </SuccessPopup>
  );

  return (
    <PageTemplate>
      {deleted !== '' && showDeletePopup(deleted)}
      {!isLoading && error && <ErrorPopup fixed>{error.message}</ErrorPopup>}
      <TopSection>
        {['admin'].includes(userRole) ? (
          <Button onClick={toggleNewPanel}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj materiał
          </Button>
        ) : (
          <span />
        )}
        <TableSorter>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={handleStatus}>
              <option value="true">Aktywne</option>
              <option value="false">Nieaktywne</option>
              <option value="">Wszystkie</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {isLoading ? (
        <Loading />
      ) : (
        <TrainingMaterialsTable
          data={trainingData}
          headers={getHeaders(userRole)}
          offset={(pagination.currentPage - 1) * (pagination.perPage || 20)}
          canUserEdit={handleCanEdit}
          handleEdit={['admin'].includes(userRole) && openEditPanel}
          handleDelete={['admin'].includes(userRole) && setDeleted}
          handleDownload={handleDownload}
          handleVimeo={handleVimeo}
        />
      )}
      <BottomSection>
        <BottomItem>Elementów: {count}</BottomItem>
        <Pagination
          allowPageSet
          currentPage={pagination.currentPage}
          maxPages={pagination.totalPages}
          handlePage={setPage}
        />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={pagination.perPage} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      <SidePanel toggleClose={closeEditPanel} isVisible={isEditVisible}>
        {currentTrainingData ? (
          <TrainingForm
            editMode
            initialValues={{ ...currentTrainingData }}
            toggleClose={closeEditPanel}
            refetch={getTrainingMaterialsData}
          />
        ) : (
          ''
        )}
      </SidePanel>
      <SidePanel toggleClose={toggleNewPanel} isVisible={isAddNewVisible}>
        <TrainingForm toggleClose={toggleNewPanel} refetch={getTrainingMaterialsData} />
      </SidePanel>
    </PageTemplate>
  );
};

TrainingMaterialsPage.defaultProps = {};
TrainingMaterialsPage.propTypes = {};

export default TrainingMaterialsPage;
