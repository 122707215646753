import * as types from './types';

export const setNotifications = (notifications, count) => dispatch =>
  dispatch({ type: types.SET_NOTIFICATIONS, payload: { notifications, count } });
export const setNotificationsWidget = notifications => dispatch =>
  dispatch({ type: types.SET_NOTIFICATIONS_WIDGET, payload: notifications });

export const loadMoreNotifications = notifications => dispatch =>
  dispatch({ type: types.LOAD_MORE_NOTIFICATIONS, payload: notifications });

export const addNotification = notification => dispatch =>
  dispatch({ type: types.ADD_NOTIFICATION, payload: notification });
export const addNotificationWidget = notification => dispatch =>
  dispatch({ type: types.ADD_NOTIFICATION_WIDGET, payload: notification });

export const clearNotifications = () => dispatch => dispatch({ type: types.CLEAR_NOTIFICATIONS });
export const clearNotificationsWidget = () => dispatch => dispatch({ type: types.CLEAR_NOTIFICATIONS_WIDGET });

export const markAsReadedAll = () => dispatch => dispatch({ type: types.MARK_AS_READED_ALL });
export const markAsReadedAllWidget = () => dispatch => dispatch({ type: types.MARK_AS_READED_ALL_WIDGET });

export const markAsReadedOne = notificationId => dispatch =>  dispatch({ type: types.MARK_AS_READED_ONE, payload: notificationId });

export const open = () => dispatch => dispatch({ type: types.SHOW });
export const close = () => dispatch => dispatch({ type: types.HIDE });
export const toggle = () => dispatch => dispatch({ type: types.TOGGLE });

export const clearReducer = () => dispatch => dispatch({ type: types.CLEAR_NOTIFICATIONS_REDUCER });
