import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import placeholder from 'assets/avatar-placeholder.png';
import { font, colors } from 'theme/styles';
import { displayDate } from 'utils/helpers';

const Note = ({ data }) => (
  <Wrapper>
    <NoteAvatar>
      <img src={data.user.avatar || placeholder} alt="Avatar" />
    </NoteAvatar>
    <NoteBody>
      <NoteBodyHeader>
        <h3>
          {data.user.name} {data.user.surname}
        </h3>
        <p>{displayDate(data.createdAt)}</p>
      </NoteBodyHeader>
      <Text>{data.text}</Text>
    </NoteBody>
  </Wrapper>
);

Note.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default Note;

const Wrapper = styled.article`
  display: flex;
  padding-bottom: 10px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
    border-bottom: 1px solid ${colors.lightGray};
  }
`;

const NoteAvatar = styled.figure`
  flex: 0 1 10%;
  max-width: 10%;
  margin: 0 20px 0 10px;
  overflow: hidden;
  align-self: flex-start;

  img {
    max-width: 100%;
    border-radius: 5px;
  }
`;

const NoteBody = styled.div`
  flex: 0 1 90%;
  max-width: 90%;
`;

const NoteBodyHeader = styled.div`
  padding: 4px 20px;
  background: linear-gradient(to right, ${colors.lighterGray} 75%, transparent);

  & > * {
    display: inline-block;
    margin: 0;
  }
  font-weight: ${font.weight.semibold};

  h3 {
    color: ${colors.orange};
    font-size: ${font.size.xl};
  }

  p {
    color: ${colors.mediumGray};
    font-size: ${font.size.m};
    letter-spacing: 0.5px;

    &::before {
      content: "\\00B7";
      display: inline;
      font-weight: ${font.weight.bold};
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

const Text = styled.p`
  padding: 0 10px;
  font-size: ${font.size.s};
`;
