import { useReducer } from 'react';

const initialState = {
  loading: false,
  success: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case 'success':
      return {
        ...state,
        loading: false,
        success: action.payload || true,
      };
    case 'error':
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload || true,
      };
    default:
      return state;
  }
};

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onRequest = (type, payload) => {
    dispatch({ type, payload });
  };

  return [state, onRequest];
};
