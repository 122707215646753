/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ErrorMessage, FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { leadService } from 'services/lead';
import { download } from 'utils/helpers';
import matrixArr, { functionToIdMap, functionsArr } from './others/configAML';
import { AlignCheckbox, FieldHeading, FlexFields } from 'components/forms/FormComponents';
import { contactPersonFieldsRender, drukAmlFieldsRender } from 'components/forms/LeadMerchant/others/helpers';
import { Block } from 'components/UI/Structure';
import { colors } from 'theme/styles';
import { Row } from './LeadMerchantStep3';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import options from 'components/forms/LeadMerchant/others/config';
import Person from 'components/forms/LeadMerchant/others/Person';
import FileDropZone from 'components/UI/FileDropzone';
import ResetButton from 'components/UI/ResetButton';
import ErrorLabel from 'components/UI/ErrorLabel';
import Radio from 'components/UI/Radio';
import DrukAML from './others/DrukAML';

const mapPersonFunctionToLegacy = (person) => {
  const personFunctionObj = functionsArr.find((f) => f.value === person.function);
  const tempPerson = { ...person };
  if (personFunctionObj && personFunctionObj.legacyValue) tempPerson.function = personFunctionObj.legacyValue;
  return tempPerson;
};

const getLegalFormRelations = (values) => {
  let legalForm;
  let formRelations;

  if (values.version === 'AML') {
    legalForm = values.settlements.legalFormAML;
    formRelations = matrixArr.find((opt) => opt.value === legalForm)?.relations;
  } else {
    legalForm = values.settlements.legalForm;
    formRelations = options.legalFormOptions.find((opt) => opt.value === legalForm)?.relations;
  }

  return formRelations
    ? {
        minOwners: formRelations.minOwners,
        maxOwners: formRelations.maxOwners,
        minSignatory: formRelations.minSignatory,
        maxSignatory: formRelations.maxSignatory,
      }
    : false;
};

const LeadMerchantStep4 = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  config,
  setConfig,
  validateForm,
  setFieldTouched,
  viewMode,
  debugMode,
  initialValues,
  // setTouched,
}) => {
  const { id: contractId } = useParams();
  const { contact: stepValues } = values;
  const { contact: stepTouched = {} } = touched;
  const { contact: stepErrors = {} } = errors;

  const [addFileError, setAddFileError] = useState('');
  const [pickedLegalForm, setPickedLegalForm] = useState(null);
  const [disableContractFormOptions, setDisableContractFormOptions] = useState(false);

  const isOldVersion = values.version === 'OLD';
  const bag = {
    values: stepValues,
    touched: stepTouched,
    errors: stepErrors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  };

  const [isFinancialCheck, setIsFinancialCheck] = useState(stepValues.financialMatters.sameAs);
  const [isOperationalCheck, setIsOperationalCheck] = useState(stepValues.operationalMatters.sameAs);
  const [isBusinessCheck, setIsBusinessCheck] = useState(stepValues.businessMatters.sameAs);
  const [isOwnerCheck, setIsOwnerCheck] = useState(stepValues.owners.some((el) => el.sameAs) || true);
  const [isPersonInChargeCheck, setIsPersonInChargeCheck] = useState(stepValues.personInCharge.sameAs);
  // Tylko do wyświetlania dla zapisanych starych wersji
  const [isSignatoryCheck, setIsSignatoryCheck] = useState(stepValues.signatory.some((el) => el.sameAs));

  const { minOwners, maxOwners, minSignatory, maxSignatory } = getLegalFormRelations(values);

  const getSameAsCheckboxText = () => {
    if (isOldVersion) return 'Taki sam jak w sprawach finansowych';

    return [
      'Taki sam jak osoba podpisująca umowę',
      stepValues.signatory.length > 1 && (
        <>
          {' '}
          <span className="color-orange">
            ({stepValues.signatory[0].name} {stepValues.signatory[0].lastname})
          </span>
        </>
      ),
    ].filter(Boolean);
  };

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    setFieldValue('contact.versionNumberCopy', values.versionNumber);
  }, [values.versionNumber]);

  useEffect(() => {
    if (isOldVersion) return;

    const tempPickedLegalForm = matrixArr.find((form) => form.value === values.settlements.legalFormAML);
    if (!tempPickedLegalForm) return;
    setPickedLegalForm(tempPickedLegalForm);
    const { value, isSignatoryAgreementRequired } = tempPickedLegalForm;

    setFieldValue('contact.legalFormCopy', { value, isSignatoryAgreementRequired });
  }, [values.settlements.legalFormAML]);

  const resetPersonInChargeFile = useCallback(() => {
    setFieldValue('contact.agreementFile', initialValues.contact.agreementFile || '');
    setFieldValue('contact.agreement', initialValues.contact.agreement || '');
  }, [setFieldValue, initialValues.contact.agreementFile, initialValues.contact.agreement]);

  const handlePersonInChargeAgreementDownload = useCallback(
    (fileName) => {
      leadService
        .getOnboardingPersonInChargeAgreementFile(contractId)
        .then((res) => {
          download(res, fileName);
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    [contractId],
  );

  const onAddSingatoryHandler = () => {
    if (values.versionNumber >= 4)
      setFieldValue('contact.signatoryDrukAml', [...stepValues.signatoryDrukAml, new DrukAML()]);
  };

  const onRemoveSingatoryHandler = (removeIndex) => {
    if (values.versionNumber >= 4)
      setFieldValue(
        'contact.signatoryDrukAml',
        stepValues.signatoryDrukAml.filter((_, i) => i !== removeIndex),
      );
  };

  // -------------------- NEW START --------------------
  // -------------------- financial --------------------

  useEffect(() => {
    // if (viewMode) return;
    let person = new Person({ type: 1, sameAs: false });
    if (isFinancialCheck) person = mapPersonFunctionToLegacy({ ...stepValues.signatory[0], type: 1, sameAs: true });
    else if (config.financial) person = { ...stepValues.financialMatters };
    setFieldValue('contact.financialMatters', person);
  }, [
    isFinancialCheck,
    setFieldValue,
    stepValues.signatory[0].name,
    stepValues.signatory[0].lastname,
    stepValues.signatory[0].email,
    stepValues.signatory[0].phone,
    stepValues.signatory[0].function,
  ]);

  useEffect(() => {
    // if (viewMode) return;

    setConfig((c) => ({ ...c, financial: !isFinancialCheck }));
  }, [isFinancialCheck, setConfig]);
  // --------------------  NEW END  --------------------

  // -------------------- operational --------------------

  useEffect(() => {
    // if (viewMode) return;

    let person = new Person({ type: 2, sameAs: false });
    if (isOperationalCheck) person = mapPersonFunctionToLegacy({ ...stepValues.signatory[0], type: 2, sameAs: true });
    else if (config.operational) person = { ...stepValues.operationalMatters };
    setFieldValue('contact.operationalMatters', person);
  }, [
    isOperationalCheck,
    setFieldValue,
    stepValues.signatory[0].name,
    stepValues.signatory[0].lastname,
    stepValues.signatory[0].email,
    stepValues.signatory[0].phone,
    stepValues.signatory[0].function,
  ]);

  useEffect(() => {
    // if (viewMode) return;

    setConfig((c) => ({ ...c, operational: !isOperationalCheck }));
  }, [isOperationalCheck, setConfig]);

  // -------------------- business --------------------

  useEffect(() => {
    // if (viewMode) return;

    let person = new Person({ type: 3, sameAs: false });
    if (isBusinessCheck) person = mapPersonFunctionToLegacy({ ...stepValues.signatory[0], type: 3, sameAs: true });
    else if (config.business) person = { ...stepValues.businessMatters };
    setFieldValue('contact.businessMatters', person);
  }, [
    isBusinessCheck,
    setFieldValue,
    stepValues.signatory[0].name,
    stepValues.signatory[0].lastname,
    stepValues.signatory[0].email,
    stepValues.signatory[0].phone,
    stepValues.signatory[0].function,
  ]);

  useEffect(() => {
    // if (viewMode) return;

    setConfig((c) => ({ ...c, business: !isBusinessCheck }));
  }, [isBusinessCheck, setConfig]);

  // -------------------- owners --------------------

  useEffect(() => {
    // if (viewMode) return;

    const newPerson = new Person({ type: 4, sameAs: false });
    let value;
    if (isOwnerCheck)
      value =
        minOwners > 0
          ? new Array(minOwners).fill(mapPersonFunctionToLegacy({ ...stepValues.signatory[0], type: 4 }))
          : [];
    else if (minOwners === 0) value = [];
    else if (config.owners) {
      value = [...stepValues.owners];
      if (stepValues.owners.length > maxOwners) value = [...stepValues.owners.slice(0, maxOwners)];
      if (stepValues.owners.length < minOwners)
        value = [...stepValues.owners, new Array(minOwners - stepValues.owners.length).fill(newPerson)];
    } else {
      value = new Array(minOwners).fill(newPerson);
    }
    setFieldValue('contact.owners', value, false);
  }, [
    isOwnerCheck,
    setFieldValue,
    stepValues.signatory[0].name,
    stepValues.signatory[0].lastname,
    stepValues.signatory[0].email,
    stepValues.signatory[0].phone,
    stepValues.signatory[0].function,
    minOwners,
    config,
  ]);

  useEffect(() => {
    // if (viewMode) return;

    setConfig((c) => ({ ...c, owners: !isOwnerCheck }));
  }, [isOwnerCheck, setConfig]);

  // -------------------- personInCharge --------------------

  useEffect(() => {
    // if (viewMode) return;

    let person = new Person({ type: 5, sameAs: false });
    if (isPersonInChargeCheck)
      person = mapPersonFunctionToLegacy({ ...stepValues.signatory[0], type: 5, sameAs: true });
    else if (config.preson) person = { ...stepValues.personInCharge };
    setFieldValue('contact.personInCharge', person);
  }, [
    isPersonInChargeCheck,
    setFieldValue,
    stepValues.signatory[0].name,
    stepValues.signatory[0].lastname,
    stepValues.signatory[0].email,
    stepValues.signatory[0].phone,
    stepValues.signatory[0].function,
  ]);

  useEffect(() => {
    // if (viewMode) return;

    setConfig((c) => ({ ...c, preson: !isPersonInChargeCheck }));
  }, [isPersonInChargeCheck, setConfig]);

  // -------------------- signatory --------------------

  useEffect(() => {
    // if (viewMode) return;

    const newPerson = new Person({ type: 6, sameAs: false });
    let value = [...stepValues.signatory];

    if (minSignatory === 0) value = [];
    else if (stepValues.signatory.length > maxSignatory) value = [...stepValues.signatory.slice(0, maxSignatory)];
    else if (stepValues.signatory.length < minSignatory)
      value = [...stepValues.signatory, ...new Array(minSignatory - stepValues.signatory.length).fill(newPerson)];
    else if (stepValues.signatory.length >= minSignatory || stepValues.signatory.length <= maxSignatory)
      value = [...stepValues.signatory];
    else value = new Array(minSignatory).fill(newPerson);

    setFieldValue('contact.signatory', value);
  }, [setFieldValue, minSignatory, config]);

  useEffect(() => {
    // if (viewMode) return;

    if (!pickedLegalForm) return;
    const validatedSignatory = stepValues.signatory.map((s) =>
      pickedLegalForm.functionsIds.includes(s.function) ? s : { ...s, function: pickedLegalForm.functionId },
    );
    setFieldValue('contact.signatory', validatedSignatory);
  }, [setFieldValue, stepValues.signatory.length, pickedLegalForm]);

  useEffect(() => {
    // Logic only for form version 4 and above
    if (values.versionNumber < 4) return;
    setDisableContractFormOptions(true);

    if (stepValues.allSignatoryAreCitizensOfPoland === null) return;
    setFieldValue(
      'contact.contractForm',
      stepValues.allSignatoryAreCitizensOfPoland
        ? options.contractFormValues.weryfikachaTozsamosci
        : options.contractFormValues.weryfikacjaPapierowaKurier,
    );
  }, [stepValues.allSignatoryAreCitizensOfPoland]);

  return (
    <OrderWrapper>
      <Block noInteract={viewMode} style={{ order: isOldVersion ? 50 : 1 }}>
        <AlignCheckbox>
          <FieldHeading mb size="l">
            Osoba podpisująca umowę
          </FieldHeading>
          {isOldVersion && (
            <Checkbox
              disabled={viewMode}
              style={{ marginBottom: '20px' }}
              name="isSignatoryCheck"
              onChange={(e) => setIsSignatoryCheck(e.target.checked)}
              onBlur={handleBlur}
              label="Taki sam jak w sprawach finansowych"
              value={isSignatoryCheck}
              checked={isSignatoryCheck}
            />
          )}
        </AlignCheckbox>
        {isOldVersion && isSignatoryCheck ? null : (
          <>
            <FieldArray name="contact.signatory" validateOnChange={false}>
              {(arrayHelpers) => (
                <div>
                  {stepValues.signatory.map((signatory, index) =>
                    contactPersonFieldsRender(
                      'contact',
                      'signatory',
                      bag,
                      viewMode,
                      index,
                      arrayHelpers,
                      minSignatory,
                      pickedLegalForm
                        ? pickedLegalForm.functionsIds.map((fId) => functionToIdMap.get(fId))
                        : options.personFunctionOptions,
                      onRemoveSingatoryHandler,
                    ),
                  )}
                  {maxSignatory > stepValues.signatory.length && !viewMode && (
                    <Button
                      right
                      small
                      green
                      type="button"
                      onClick={() => {
                        arrayHelpers.push(new Person({ type: 6, sameAs: false }));
                        onAddSingatoryHandler();
                      }}
                    >
                      Dodaj
                    </Button>
                  )}
                </div>
              )}
            </FieldArray>
            {/* Pełnomocnik */}
            {pickedLegalForm &&
              pickedLegalForm.isSignatoryAgreementRequired &&
              stepValues.signatory.some((p) => p && p.function === 8) && (
                <div>
                  <FieldHeading medium size="m">
                    Pełnomocnictwo
                  </FieldHeading>
                  <FileDropZone
                    handleBlur={handleBlur}
                    onDrop={(acceptedFiles, fileRejections) => {
                      fileRejections.forEach((file) => {
                        file.errors.forEach((err) => {
                          if (err.code === 'file-too-large') setAddFileError('Plik jest za duży, maksymalnie 10mb');
                          if (err.code === 'file-invalid-type')
                            setAddFileError('Plik ma niepoprawny format, tylko pliki PDF');
                        });
                      });

                      if (acceptedFiles.length > 0) {
                        const file = acceptedFiles[0];
                        setAddFileError('');
                        setFieldValue('contact.agreementFile', file);
                        setFieldValue('contact.agreement', {
                          name: file.name,
                          download: false,
                        });
                      }
                    }}
                    disabled={viewMode}
                    name="contact.agreementFile"
                    accept="application/pdf"
                    label="Dodaj pełnomocnictwo"
                    hide={Boolean(values.contact.agreement)}
                  />
                  {values.contact.agreement && values.contact.agreement.name && (
                    <>
                      <File
                        download={values.contact.agreement.download}
                        onClick={() =>
                          values.contact.agreement.download
                            ? handlePersonInChargeAgreementDownload(values.contact.agreement.name)
                            : undefined
                        }
                      >
                        <FontAwesomeIcon fixedWidth icon="file-download" />
                        <span>{values.contact.agreement.name}</span>
                      </File>
                      {!values.contact.agreement.download && (
                        <ResetButton type="button" onClick={resetPersonInChargeFile}>
                          Usuń
                        </ResetButton>
                      )}
                    </>
                  )}
                  {addFileError && <ErrorLabel style={{ marginTop: '2rem' }}>{addFileError}</ErrorLabel>}
                  {stepErrors.agreement && (
                    <ErrorLabel style={{ marginTop: '2rem' }}>{stepErrors.agreement}</ErrorLabel>
                  )}
                </div>
              )}
          </>
        )}

        {/* NEW v 4 */}
        {values.versionNumber >= 4 && (
          <div>
            <FieldHeading mbSmall size="m">
              Czy wszystkie osoby podpisujące umowę są obywatelami Polski?
            </FieldHeading>
            <FlexFields autoFit>
              {options.allSignatoryAreCitizensOfPolandOptions.map((opt) => (
                <Radio
                  disabled={viewMode}
                  key={opt.value}
                  name="contact.allSignatoryAreCitizensOfPoland"
                  value={opt.value}
                  onChange={(e) => {
                    setFieldValue('contact.allSignatoryAreCitizensOfPoland', opt.value);
                    // Reset if off
                    if (e.target.value == 0) setFieldValue('contact.signatoryDrukAml', []);
                    else
                      setFieldValue(
                        'contact.signatoryDrukAml',
                        stepValues.signatory.map(() => new DrukAML()),
                      );
                  }}
                  label={opt.display}
                  checked={stepValues.allSignatoryAreCitizensOfPoland === opt.value}
                />
              ))}
            </FlexFields>
          </div>
        )}
        {values.versionNumber >= 2 && (
          <div style={{ marginTop: '2rem' }}>
            <FieldHeading mbSmall size="m">
              Weryfikacja osoby podpisującej umowę
            </FieldHeading>
            <CustomRow>
              {options.contractFormOptions
                .filter((opt) => {
                  if (values.versionNumber >= 4)
                    return [
                      options.contractFormValues.weryfikachaTozsamosci,
                      options.contractFormValues.weryfikacjaPapierowaKurier,
                    ].includes(opt.value);
                  else
                    return [
                      options.contractFormValues.aplikacjaPepId,
                      options.contractFormValues.mojeId,
                      options.contractFormValues.weryfikacjaPapierowaKurier,
                    ].includes(opt.value);
                })
                .map((opt) => {
                  return (
                    <RadioLogoWrapper key={opt.value}>
                      <Radio
                        disabled={viewMode || disableContractFormOptions}
                        name="contact.contractForm"
                        value={stepValues.contractForm}
                        onChange={() => setFieldValue('contact.contractForm', opt.value)}
                        label={opt.display}
                        checked={stepValues.contractForm === opt.value}
                      />
                      {opt.image && <img src={opt.image} alt={opt.display} />}
                    </RadioLogoWrapper>
                  );
                })}
            </CustomRow>
            <ErrorMessage style={{ marginTop: '4px' }} component={ErrorLabel} name="contact.contractForm" />
          </div>
        )}

        {/* Jeżeli weryfikacja tożsamości */}
        {stepValues.contractForm == options.contractFormValues.weryfikachaTozsamosci && (
          <div style={{ marginTop: '2rem' }}>
            <FieldArray name="contact.signatoryDrukAml">
              {() => (
                <div style={{ marginTop: '1rem' }}>
                  {stepValues.signatoryDrukAml.map((_, index) =>
                    drukAmlFieldsRender('contact', 'signatoryDrukAml', bag, viewMode, index),
                  )}
                </div>
              )}
            </FieldArray>
          </div>
        )}
      </Block>

      <Block noInteract={viewMode}>
        <AlignCheckbox>
          <FieldHeading mb size="l">
            Kontakt w sprawach finansowych
          </FieldHeading>
          {!isOldVersion && (
            <Checkbox
              disabled={viewMode}
              style={{ marginBottom: '20px' }}
              name="isFinancialCheck"
              onChange={(e) => setIsFinancialCheck(e.target.checked)}
              onBlur={handleBlur}
              label={getSameAsCheckboxText()}
              value={isFinancialCheck}
              checked={isFinancialCheck}
            />
          )}
        </AlignCheckbox>
        {isOldVersion && contactPersonFieldsRender('contact', 'financialMatters', bag, viewMode)}
        {!isOldVersion && !isFinancialCheck && contactPersonFieldsRender('contact', 'financialMatters', bag, viewMode)}
      </Block>

      <Block noInteract={viewMode}>
        <AlignCheckbox>
          <FieldHeading mb size="l">
            Kontakt w sprawach operacyjnych
          </FieldHeading>
          <Checkbox
            disabled={viewMode}
            style={{ marginBottom: '20px' }}
            name="isOperationalCheck"
            onChange={(e) => setIsOperationalCheck(e.target.checked)}
            onBlur={handleBlur}
            label={getSameAsCheckboxText()}
            value={isOperationalCheck}
            checked={isOperationalCheck}
          />
        </AlignCheckbox>
        {!isOperationalCheck && contactPersonFieldsRender('contact', 'operationalMatters', bag, viewMode)}
      </Block>

      <Block noInteract={viewMode}>
        <AlignCheckbox>
          <FieldHeading mb size="l">
            Kontakt w sprawach biznesowych
          </FieldHeading>
          <Checkbox
            disabled={viewMode}
            style={{ marginBottom: '20px' }}
            name="isBusinessCheck"
            onChange={(e) => setIsBusinessCheck(e.target.checked)}
            onBlur={handleBlur}
            label={getSameAsCheckboxText()}
            value={isBusinessCheck}
            checked={isBusinessCheck}
          />
        </AlignCheckbox>
        {!isBusinessCheck && contactPersonFieldsRender('contact', 'businessMatters', bag, viewMode)}
      </Block>

      {minOwners > 0 && (
        <Block noInteract={viewMode}>
          <AlignCheckbox>
            <FieldHeading mb size="l">
              Właściciel
            </FieldHeading>
            <Checkbox
              disabled={viewMode}
              style={{ marginBottom: '20px' }}
              name="isOwnerCheck"
              onChange={(e) => setIsOwnerCheck(e.target.checked)}
              onBlur={handleBlur}
              label={getSameAsCheckboxText()}
              value={isOwnerCheck}
              checked={isOwnerCheck}
            />
          </AlignCheckbox>
          {!isOwnerCheck && (
            <FieldArray name="contact.owners" validateOnChange={false}>
              {(arrayHelpers) => (
                <div>
                  {stepValues.owners.map((owner, index) =>
                    contactPersonFieldsRender('contact', 'owners', bag, viewMode, index, arrayHelpers, minOwners),
                  )}
                  {maxOwners > stepValues.owners.length && !viewMode && (
                    <Button
                      disable={viewMode}
                      right
                      small
                      green
                      type="button"
                      onClick={() => {
                        arrayHelpers.push(new Person({ type: 4, sameAs: false }));
                      }}
                    >
                      Dodaj
                    </Button>
                  )}
                </div>
              )}
            </FieldArray>
          )}
        </Block>
      )}

      <Block noInteract={viewMode}>
        <AlignCheckbox>
          <FieldHeading mb size="l">
            Osoba zarządzająca
          </FieldHeading>
          <Checkbox
            disabled={viewMode}
            style={{ marginBottom: '20px' }}
            name="isPersonInChargeCheck"
            onChange={(e) => setIsPersonInChargeCheck(e.target.checked)}
            onBlur={handleBlur}
            label={getSameAsCheckboxText()}
            value={isPersonInChargeCheck}
            checked={isPersonInChargeCheck}
          />
        </AlignCheckbox>
        {!isPersonInChargeCheck && contactPersonFieldsRender('contact', 'personInCharge', bag, viewMode)}
      </Block>

      {debugMode && (
        <>
          <Block>
            <div>
              <pre>Version: {JSON.stringify(values.version || '???', null, 4)}</pre>
              <pre>Version Number: {JSON.stringify(values.versionNumber || '???', null, 4)}</pre>
            </div>
          </Block>
          <Block style={{ order: 999 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <pre>Values: {JSON.stringify(stepValues, null, 4)}</pre>
              <pre>Errors: {JSON.stringify(errors, null, 4)}</pre>
              <pre>Touched: {JSON.stringify(touched, null, 4)}</pre>
            </div>
          </Block>
        </>
      )}
    </OrderWrapper>
  );
};

LeadMerchantStep4.defaultProps = {
  config: {},
  values: {},
  errors: {},
  touched: {},
  validateForm: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  setFieldValue: () => {},
  setFieldTouched: () => {},
  // setTouched: () => {},
  setConfig: () => {},
  debugMode: false,
  initialValues: {},
};

LeadMerchantStep4.propTypes = {
  config: PropTypes.instanceOf(Object),
  values: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  initialValues: PropTypes.instanceOf(Object),
  validateForm: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  // setTouched: PropTypes.func,
  setConfig: PropTypes.func,
  viewMode: PropTypes.bool.isRequired,
  debugMode: PropTypes.bool,
};

export default LeadMerchantStep4;

const CustomRow = styled(Row)`
  align-items: initial;
`;

const RadioLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.4rem;
  border: 1px solid ${colors.blueLighter};

  img {
    max-height: 2.5rem;
    max-width: 6rem;
    margin-left: 2rem;
  }

  label {
    margin-bottom: 0;
  }
`;

export const File = styled.div`
  &[download] {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    color: ${colors.orange};
    margin-right: 0.5rem;
  }
`;

const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  & > * + * {
    margin-top: 0 !important;
  }

  ${Block} {
    order: 1;
  }
`;
