import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { colors, font, breakpoints } from 'theme/styles';
import ReactDateTimePicker from 'react-datetime-picker';
import calendarIcon from 'assets/icons/calendar.svg';
import clearIcon from 'assets/icons/clear.svg';

const DateTimePicker = ({
  handleDate,
  initialDate,
  isMinDate,
  disabled,
  onlyDays,
  resetOnInitialChange,
  minDate,
  maxDate,
  ...rest
}) => {
  const [date, setDate] = useState(null);

  const handleChange = (input) => {
    setDate(input);
    if (input === null) {
      handleDate('');
    } else {
      if (onlyDays) handleDate(moment(input).format('YYYY-MM-DD'));
      else handleDate(moment(input).format('YYYY-MM-DD HH:mm'));
    }
  };

  useEffect(() => {
    if (!initialDate) return;
    const date = initialDate.charAt(initialDate.length - 1) === 'Z' ? initialDate.slice(0, -1) : initialDate;
    setDate(moment(date).toDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetOnInitialChange && !initialDate) setDate(null);
  }, [resetOnInitialChange, initialDate]);

  return (
    <Styles {...rest}>
      <ReactDateTimePicker
        calendarIcon={<CalendarButton />}
        clearIcon={<ClearButton />}
        onChange={handleChange}
        value={date}
        format={onlyDays ? 'y-MM-dd' : 'y-MM-dd  -  HH:mm'}
        hourPlaceholder="GG"
        minutePlaceholder="MM"
        yearPlaceholder="RRRR"
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        disableClock
        disabled={disabled}
        minDate={isMinDate ? minDate || new Date() : null}
        maxDate={maxDate}
      />
    </Styles>
  );
};
DateTimePicker.defaultProps = {
  initialDate: null,
  isMinDate: true,
  disabled: false,
  onlyDays: false,
  resetOnInitialChange: false,
  minDate: null,
  maxDate: null,
  wider: false,
};

DateTimePicker.propTypes = {
  handleDate: PropTypes.func.isRequired,
  isMinDate: PropTypes.bool,
  disabled: PropTypes.bool,
  initialDate: PropTypes.string,
  onlyDays: PropTypes.bool,
  resetOnInitialChange: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  wider: PropTypes.bool,
};

export default DateTimePicker;

const Styles = styled.div`
  ${({ wider }) =>
    wider &&
    css`
      .react-datetime-picker {
        width: 100%;
        max-width: 200px;
      }
    `}

  & + span {
    transform: translateY(3px);
  }
  .react-datetime-picker__wrapper {
    height: 30px;
    border: none;
  }
  .react-datetime-picker__inputGroup {
    border-bottom: 1px solid ${colors.darkGray};
    min-width: 60px;
    margin: 0;
    padding: 0 5px;
    font-size: ${font.size.s};
  }

  .react-datetime-picker__button {
    padding: 0;
  }

  input:focus {
    outline: none;
    border-bottom: 3px solid ${colors.orange};
    background-color: ${colors.orange}20;
  }

  @media (max-width: ${breakpoints.filters}) {
    .react-datetime-picker,
    .react-datetime-picker__wrapper {
      width: 100%;
    }

    .react-datetime-picker__inputGroup {
      justify-content: space-evenly;
    }

    .react-datetime-picker__button {
      position: absolute;
      top: 4px;
      right: 0;
      transform: translateY(-100%);

      & > div {
        width: 26px;
        height: 26px;
      }

      &:nth-last-of-type(1) {
        transform: translate(-100%, -100%);
      }
    }
  }
`;

const ClearButton = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${clearIcon});
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 50% 50%;
  cursor: pointer;
`;

const CalendarButton = styled(ClearButton)`
  background-image: url(${calendarIcon});
`;
