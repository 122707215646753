import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'theme/styles';

const SimpleCheckbox = ({ item, onChange }) => (
  <Checkbox>
    <label htmlFor={item.id}>
      <input id={item.id} type="checkbox" onChange={onChange} defaultChecked={item.isChecked} />
      <span />
      {item.name}
    </label>
  </Checkbox>
);

SimpleCheckbox.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimpleCheckbox;

// styled components
const Checkbox = styled.div`
  text-transform: capitalize;
  margin-bottom: 5px;

  label {
    cursor: pointer;
  }

  input {
    display: none;
  }

  span {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: ${colors.white};
    border: 1px solid ${colors.orange};

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      background-color: ${colors.orange};
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.15s ease-out;
    }
  }

  input:checked + span::after {
    transform: translate(-50%, -50%) scale(1);
  }
`;
