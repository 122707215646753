import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, font } from 'theme/styles';

const Progress = ({ value, maxValue, label }) => (
  <Wrapper>
    <Label>
      {label} - {value}/{maxValue}
      <ProgressBar max={maxValue} value={value} />
    </Label>
  </Wrapper>
);

Progress.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default Progress;

const Wrapper = styled.div``;

const Label = styled.label`
  display: block;
  font-size: ${font.size.s};
  letter-spacing: 1px;
`;

const ProgressBar = styled.progress`
  display: block;
  background-color: ${colors.lighterGray};
  border: 0;
  padding: 0;
  height: 6px;
  border-radius: 10px;
  width: 100%;
  margin-top: 3px;
  -webkit-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    background-color: ${colors.lighterGray};
    border-radius: 10px;
  }

  &::-webkit-progress-value {
    background-color: ${colors.orange};
    border-radius: 10px;
  }

  &::-moz-progress-bar {
    background-color: ${colors.orange};
    border-radius: 10px;
  }
`;
