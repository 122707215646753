import { challengeService } from 'services/challenge';
import { sortDateToDateFrom, sortDateFromDateTo, sortChallengesForPartner } from 'utils/helpers';
import * as types from './types';

export const getAllChallenges = () => dispatch => {
  dispatch({ type: types.GET_CHALLENGES_REQUEST });
  challengeService
    .getAllChallenges()
    .then(payload => {
      payload.data.sort(sortDateFromDateTo);
      dispatch({ type: types.GET_CHALLENGES_SUCCESS, payload });
    })
    .catch(err => dispatch({ type: types.GET_CHALLENGES_FAILURE, err }));
};

export const getAllChallengesForPartner = () => dispatch => {
  dispatch({ type: types.GET_CHALLENGES_REQUEST });
  challengeService
    .getAllChallenges()
    .then(payload => {
      payload.data.sort(sortDateToDateFrom);
      payload.data.sort(sortChallengesForPartner);
      dispatch({ type: types.GET_CHALLENGES_SUCCESS, payload });
    })
    .catch(err => dispatch({ type: types.GET_CHALLENGES_FAILURE, err }));
};

export const getPartnerChallenge = id => dispatch => {
  dispatch({ type: types.GET_PARTNER_CHALLENGE_REQUEST });
  challengeService
    .getChallengePartner(id)
    .then(payload => {
      dispatch({ type: types.GET_PARTNER_CHALLENGE_SUCCESS, payload });
    })
    .catch(err => dispatch({ type: types.GET_PARTNER_CHALLENGE_FAILURE, err }));
};

export const getActiveChallenges = () => dispatch => {
  dispatch({ type: types.GET_CHALLENGES_REQUEST });
  challengeService
    .getActiveChallenges()
    .then(payload => {
      payload.data.sort(sortDateFromDateTo);
      dispatch({ type: types.GET_CHALLENGES_SUCCESS, payload });
    })
    .catch(err => dispatch({ type: types.GET_CHALLENGES_FAILURE, err }));
};

export const setCurrentChallenge = challenge => dispatch => {
  dispatch({ type: types.SET_CURRENT_CHALLENGE, payload: challenge });
};

export const clearCurrentChallenge = () => dispatch =>
  dispatch({ type: types.CLEAR_CURRENT_CHALLENGE });

export const resetChallenges = () => dispatch => dispatch({ type: types.RESET_CHALLENGES });
