import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageTemplate from 'templates/PageTemplate';
import UserProfile from 'components/layout/UserProfile';

const UserPage = ({ userData }) => {
  return (
    <PageTemplate>
      <UserProfile user={userData} />
    </PageTemplate>
  );
};

const mapStateToProps = state => {
  const { name, surname, email, phone, avatar, role, id } = state.user.data;

  return {
    userData: {
      id,
      name,
      surname,
      email,
      phone,
      avatar,
      role: role.name,
      roleSlug: role.slug
    },
  };
};

UserPage.propTypes = {
  userData: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps)(UserPage);
