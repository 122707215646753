import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLead } from 'redux/lead';
import PageTemplate from 'templates/PageTemplate';
import EditLead from 'components/forms/EditLead';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';

// TODO:
// * error handling

const LeadDetailsPage = ({ isLoading, data, error, getLead }) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getLead(id);
  }, [getLead, id]);

  useEffect(() => {
    if (error && error.status === 401) history.replace('/leads');
  }, [error, history]);

  const render = () => {
    if (data && !isLoading) {
      return <EditLead leadData={data} />;
    }
    if (error && !isLoading) {
      return <ErrorPopup fixed>{error.message}</ErrorPopup>;
    }
    return <Loading />;
  };

  return <PageTemplate>{render()}</PageTemplate>;
};

LeadDetailsPage.defaultProps = {
  data: null,
  error: null,
};

LeadDetailsPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  getLead: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.leads.currentLead.isLoading,
  data: state.leads.currentLead.data,
  error: state.leads.currentLead.error,
});

const mapDispatchToProps = {
  getLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadDetailsPage);
