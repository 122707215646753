/* eslint-disable camelcase */
import makeRequest from 'services/makeRequest';

const ALL_PRODUCTS_URL = '/lead-products/all';
const DELETE_PRODUCT_URL = '/lead-products/delete';
const EDIT_PRODUCT_URL = 'lead-products/edit';
const UPDATE_PRODUCTS_ORDER_URL = 'lead-products/order';
const GET_PRODUCT_URL = 'lead-products/get';
const CREATE_PRODUCT_URL = 'lead-products/create';

const getAll = () => {
  return makeRequest({
    method: 'get',
    url: ALL_PRODUCTS_URL,
  });
};

const deleteProduct = id => {
  return makeRequest({
    method: 'post',
    url: DELETE_PRODUCT_URL,
    data: {
      id,
    },
  });
};

const editProduct = (id, data) => {
  return makeRequest({
    method: 'post',
    url: `${EDIT_PRODUCT_URL}/${id}`,
    data,
  });
};

const createProduct = data => {
  return makeRequest({
    method: 'post',
    url: CREATE_PRODUCT_URL,
    data,
  });
};

const getProduct = id => {
  return makeRequest({
    method: 'get',
    url: `${GET_PRODUCT_URL}/${id}`,
  });
};

const updateOrder = productsOrder => {
  return makeRequest({
    method: 'post',
    url: `${UPDATE_PRODUCTS_ORDER_URL}`,
    data: {
      productsOrder,
    },
  });
};

export const productService = {
  getAll,
  deleteProduct,
  editProduct,
  createProduct,
  getProduct,
  updateOrder,
};
