import styled from 'styled-components';
import { colors, font } from 'theme/styles';

const ErrorsWrapper = styled.div`
  width: 100%;
  padding: 5px 10px;
  margin: 10px auto;
  border: 1px solid ${colors.red};
  background-color: ${colors.red}20;
  color: ${colors.red};
  font-size: ${font.size.s};
  line-height: 25px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ErrorsWrapper;
