import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import LeadMerchant from 'components/forms/LeadMerchant/LeadMerchant';

const CrmFormDemoPage = () => {
  return (
    <PageTemplate>
      <LeadMerchant isDemoMode />
    </PageTemplate>
  );
};

CrmFormDemoPage.propTypes = {};

export default CrmFormDemoPage;
