import { sortAlphabetically } from 'utils/helpers';
import * as types from './types';

const initialState = {
  spOptions: {
    data: {},
    isLoading: false,
    error: null,
  },
  clarity: {
    graylogResult: null,
    crmCheckResult: null,
    isLoading: false,
    error: null,
  },
  companyAnalitics: {
    data: {
      calculations: null,
      trend: null,
    },
    isLoading: false,
    error: null,
  },
  countyAnalitics: {
    data: [],
    isLoading: false,
    error: null,
  },
  merchantsLeadsAnalyticsDetails: {
    ph: false,
    data: {
      '30_days': {
        leads: [],
        days: 30,
      },
      '90_days': {
        leads: [],
        days: 90,
      },
      '365_days': {
        leads: [],
        days: 365,
      },
    },
    isLoading: false,
    error: null,
  },
  merchantsLeadsAnalytics: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  merchantsLeadsAnalyticsPh: {
    data: {
      averageLeadsByMonth: {},
      leadsByMonthAndUser: {},
      totalLeadsByMonth: {},
      totalUsers: {},
      rows: [],
      headers: [],
    },
    isLoading: false,
    error: null,
  },
  emailTemplates: {
    data: [],
    isLoading: false,
    error: null,
  },
  notifications: {
    data: {
      notUsedCounties: [],
      usersWithoutPhs: [],
      companiesWithoutPhs: [],
      subPartnerListWithInactivePatron: [],
      companiesWithMultiplePartnerPrograms: [],
      countyHaveNonPHDedicatedRole: [],
      companyHaveNonPHDedicatedRole: [],
      userHaveNonPHDedicatedRole: [],
    },
    isLoading: false,
    error: '',
  },
  structures: {
    data: [],
    isLoading: false,
    error: '',
  },
  roles: {
    data: [],
    isLoading: false,
    error: '',
  },
  counties: {
    data: [],
    isLoading: false,
    error: '',
  },
  pepStructure: {
    data: [],
    isLoading: false,
    error: '',
  },
  companiesSuggestions: {
    data: [],
    isLoading: false,
    error: '',
  },
  currentUser: {
    data: null,
    isLoading: false,
    error: '',
  },
  currentCompany: {
    data: null,
    isLoading: false,
    error: '',
  },
  currentVacation: {
    data: null,
    isLoading: false,
    error: '',
  },
  companyUsers: {
    data: [],
    isLoading: false,
    error: null,
  },
  currentApiKey: {
    data: [],
    isLoading: false,
    error: null,
  },
  users: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  companies: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  apiKeys: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  vacations: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  crons: {
    data: [],
    isLoading: false,
    error: null,
  },
  currentCron: {
    data: null,
    logs: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CLEARITY_LOADING:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: action.payload },
      };
    case types.SET_CLEARITY_ERROR:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: false, error: action.payload },
      };
    case types.CLEAR_CLEARITY_CRM_CHECK:
      return {
        ...state,
        clarity: { ...state.clarity, crmCheckResult: null, isLoading: false, error: null },
      };
    case types.CLEAR_CLEARITY:
      return {
        ...state,
        clarity: { ...initialState.clarity },
      };
    case types.GET_CRM_CHECK_REQUEST:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: true, error: null, crmCheckResult: null },
      };
    case types.GET_CRM_CHECK_SUCCESS:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: false, error: null, crmCheckResult: action.payload.data },
      };
    case types.GET_CRM_CHECK_FAILURE:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: false, error: action.err.response.data.error },
      };
    case types.GET_GRAYLOG_LOG_REQUEST:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: true, error: null, graylogResult: null },
      };
    case types.GET_GRAYLOG_LOG_SUCCESS:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: false, error: null, graylogResult: action.payload.data },
      };
    case types.GET_GRAYLOG_LOG_FAILURE:
      return {
        ...state,
        clarity: { ...state.clarity, isLoading: false, error: action.err.response.data.error },
      };
    case types.GET_SP_OPTIONS_REQUEST:
      return {
        ...state,
        spOptions: {
          ...state.spOptions,
          isLoading: true,
          error: null,
        },
      };
    case types.GET_SP_OPTIONS_SUCCESS:
      return {
        ...state,
        spOptions: {
          ...state.spOptions,
          data: action.payload.data,
          isLoading: false,
          error: null,
        },
      };
    case types.GET_SP_OPTIONS_FAILURE:
      return {
        ...state,
        spOptions: {
          ...state.spOptions,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.CLEAR_CURRENT_MERCHANT_ANALYTICS:
      return {
        ...state,
        merchantsLeadsAnalyticsDetails: { ...initialState.merchantsLeadsAnalyticsDetails },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_REQUEST:
      return {
        ...state,
        merchantsLeadsAnalyticsDetails: {
          ...state.merchantsLeadsAnalyticsDetails,
          isLoading: true,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_SUCCESS:
      return {
        ...state,
        merchantsLeadsAnalyticsDetails: {
          ...state.merchantsLeadsAnalyticsDetails,
          ph: action.payload.data.ph,
          data: { ...action.payload.data.stats },
          isLoading: false,
          error: null,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_FAILURE:
      return {
        ...state,
        merchantsLeadsAnalyticsDetails: {
          ...state.merchantsLeadsAnalyticsDetails,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_REQUEST:
      return {
        ...state,
        merchantsLeadsAnalytics: {
          ...state.merchantsLeadsAnalytics,
          isLoading: true,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_SUCCESS:
      return {
        ...state,
        merchantsLeadsAnalytics: {
          ...state.merchantsLeadsAnalytics,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_FAILURE:
      return {
        ...state,
        merchantsLeadsAnalytics: {
          ...state.merchantsLeadsAnalytics,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_PH_REQUEST:
      return {
        ...state,
        merchantsLeadsAnalyticsPh: {
          ...state.merchantsLeadsAnalyticsPh,
          isLoading: true,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_PH_SUCCESS:
      return {
        ...state,
        merchantsLeadsAnalyticsPh: {
          ...state.merchantsLeadsAnalyticsPh,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_MERCHANTS_LEADS_ANALYTICS_PH_FAILURE:
      return {
        ...state,
        merchantsLeadsAnalyticsPh: {
          ...state.merchantsLeadsAnalyticsPh,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.SET_CURRENT_CRON_LOADING:
      return {
        ...state,
        currentCron: { ...state.currentCron, isLoading: action.payload },
      };
    case types.SET_CURRENT_CRON_ERROR:
      return {
        ...state,
        currentCron: { ...state.currentCron, error: action.payload },
      };
    case types.CLEAR_CURRENT_CRON:
      return {
        ...state,
        currentCron: { ...initialState.currentCron },
      };
    case types.GET_CRON_LOGS_SUCCESS:
      return {
        ...state,
        currentCron: {
          ...state.currentCron,
          isLoading: false,
          error: null,
          logs: action.payload,
        },
      };
    case types.GET_CRON_LOGS_REQUEST:
    case types.GET_CRON_REQUEST:
      return {
        ...state,
        currentCron: {
          ...state.currentCron,
          isLoading: true,
        },
      };
    case types.SET_CURRENT_CRON:
    case types.GET_CRON_SUCCESS:
      return {
        ...state,
        currentCron: {
          ...state.currentCron,
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    case types.GET_CRON_LOGS_FAILURE:
    case types.GET_CRON_FAILURE:
      return {
        ...state,
        currentCron: {
          ...state.currentCron,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.SET_ALL_CRONS_LOADING:
      return {
        ...state,
        crons: { ...state.crons, isLoading: action.payload },
      };
    case types.SET_ALL_CRONS_ERROR:
      return {
        ...state,
        crons: { ...state.crons, error: action.payload },
      };
    case types.CLEAR_ALL_CRONS:
      return {
        ...state,
        crons: { ...initialState.crons },
      };
    case types.GET_ALL_CRONS_REQUEST:
      return {
        ...state,
        crons: {
          ...state.crons,
          isLoading: true,
        },
      };
    case types.GET_ALL_CRONS_SUCCESS:
      return {
        ...state,
        crons: {
          ...state.crons,
          isLoading: false,
          error: null,
          data: [...action.payload],
        },
      };
    case types.GET_ALL_CRONS_FAILURE:
      return {
        ...state,
        crons: {
          ...state.crons,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_COMPANY_ANALYTICS_REQUEST:
      return {
        ...state,
        companyAnalitics: {
          ...state.companyAnalitics,
          isLoading: true,
        },
      };
    case types.GET_COMPANY_ANALYTICS_SUCCESS:
      return {
        ...state,
        companyAnalitics: {
          ...state.companyAnalitics,
          isLoading: false,
          data: { ...action.payload.data },
          error: null,
        },
      };
    case types.GET_COMPANY_ANALYTICS_FAILURE:
      return {
        ...state,
        companyAnalitics: {
          ...state.companyAnalitics,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_COUNTY_ANALYTICS_REQUEST:
      return {
        ...state,
        countyAnalitics: {
          ...state.countyAnalitics,
          isLoading: true,
        },
      };
    case types.GET_COUNTY_ANALYTICS_SUCCESS:
      return {
        ...state,
        countyAnalitics: {
          ...state.countyAnalitics,
          isLoading: false,
          data: [...action.payload.data],
          error: null,
        },
      };
    case types.GET_COUNTY_ANALYTICS_FAILURE:
      return {
        ...state,
        countyAnalitics: {
          ...state.countyAnalitics,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          isLoading: true,
        },
      };
    case types.GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          isLoading: false,
          data: [...action.payload.data],
          error: null,
        },
      };
    case types.GET_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
      };
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          data: {
            ...state.notifications.data,
            notUsedCounties: action.payload.data.notUsedCounties || [],
            usersWithoutPhs: action.payload.data.usersContainInactivePh || [],
            companiesWithoutPhs: action.payload.data.companiesContainsInactivePh || [],
            subPartnerListWithInactivePatron: action.payload.data.subPartnerListWithInactivePatron || [],
            companiesWithMultiplePartnerPrograms: action.payload.data.companiesWithMultiplePartnerPrograms || [],
            countyHaveNonPHDedicatedRole: action.payload.data.countyHaveNonPHDedicatedRole || [],
            companyHaveNonPHDedicatedRole: action.payload.data.companyHaveNonPHDedicatedRole || [],
            userHaveNonPHDedicatedRole: action.payload.data.userHaveNonPHDedicatedRole || [],
          },
          error: null,
        },
      };
    case types.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_ALL_VACATIONS_REQUEST:
      return {
        ...state,
        vacations: {
          ...state.vacations,
          isLoading: true,
        },
      };
    case types.GET_ALL_VACATIONS_SUCCESS:
      return {
        ...state,
        vacations: {
          ...state.vacations,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_ALL_VACATIONS_FAILURE:
      return {
        ...state,
        vacations: {
          ...state.vacations,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_VACATION_REQUEST:
      return {
        ...state,
        currentVacation: {
          ...state.currentVacation,
          isLoading: true,
        },
      };
    case types.GET_VACATION_SUCCESS:
      return {
        ...state,
        currentVacation: {
          ...state.currentVacation,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_VACATION_FAILURE:
      return {
        ...state,
        currentVacation: {
          ...state.currentVacation,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };
    case types.SET_CURRENT_VACATION:
      return {
        ...state,
        currentVacation: {
          data: action.payload,
        },
      };
    case types.CLEAR_CURRENT_VACATION:
      return {
        ...state,
        currentVacation: { ...initialState.currentVacation },
      };
    case types.GET_ALL_API_KEYS_REQUEST:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          isLoading: true,
        },
      };
    case types.GET_ALL_API_KEYS_SUCCESS:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_ALL_API_KEYS_FAILURE:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_SINGLE_API_KEY_REQUEST:
      return {
        ...state,
        currentApiKey: {
          ...state.currentApiKey,
          isLoading: true,
        },
      };
    case types.GET_SINGLE_API_KEY_SUCCESS:
      return {
        ...state,
        currentApiKey: {
          ...state.currentApiKey,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_SINGLE_API_KEY_FAILURE:
      return {
        ...state,
        currentApiKey: {
          ...state.currentApiKey,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };
    case types.GET_ALL_STRUCTURES_REQUEST:
      return {
        ...state,
        structures: {
          ...state.structures,
          isLoading: true,
        },
      };
    case types.GET_ALL_STRUCTURES_SUCCESS:
      return {
        ...state,
        structures: {
          ...state.structures,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_ALL_STRUCTURES_FAILURE:
      return {
        ...state,
        structures: {
          ...state.structures,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_ALL_PEP_STRUCTURES_REQUEST:
      return {
        ...state,
        pepStructure: {
          ...state.pepStructure,
          isLoading: true,
        },
      };
    case types.GET_ALL_PEP_STRUCTURES_SUCCESS:
      return {
        ...state,
        pepStructure: {
          ...state.pepStructure,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_ALL_PEP_STRUCTURES_FAILURE:
      return {
        ...state,
        pepStructure: {
          ...state.pepStructure,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: true,
        },
      };
    case types.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_ALL_COUNTIES_REQUEST:
      return {
        ...state,
        counties: {
          ...state.counties,
          isLoading: true,
        },
      };
    case types.GET_ALL_COUNTIES_SUCCESS:
      return {
        ...state,
        counties: {
          ...state.counties,
          isLoading: false,
          data: sortAlphabetically(action.payload.data, 'name'),
          error: '',
        },
      };
    case types.GET_ALL_COUNTIES_FAILURE:
      return {
        ...state,
        counties: {
          ...state.counties,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_USER_REQUEST:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: true,
        },
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };
    case types.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
      };
    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: {
          data: action.payload,
        },
      };
    case types.GET_COMPANY_REQUEST:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          isLoading: true,
        },
      };
    case types.GET_COMPANY_SUCCESS:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_COMPANY_FAILURE:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };
    case types.GET_COMPANY_USERS_REQUEST:
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          isLoading: true,
        },
      };
    case types.GET_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          isLoading: false,
          data: action.payload,
          error: '',
        },
      };
    case types.GET_COMPANY_USERS_FAILURE:
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };
    case types.GET_ALL_COMPANIES_REQUEST:
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: true,
        },
      };
    case types.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_COMPANIES_SUGGESTIONS_REQUEST:
      return {
        ...state,
        companiesSuggestions: {
          ...state.companiesSuggestions,
          isLoading: true,
        },
      };
    case types.GET_COMPANIES_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        companiesSuggestions: {
          ...state.companiesSuggestions,
          isLoading: false,
          data: action.payload.data.rows,
          error: '',
        },
      };
    case types.GET_COMPANIES_SUGGESTIONS_FAILURE:
      return {
        ...state,
        companiesSuggestions: {
          ...state.companiesSuggestions,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...initialState.currentUser,
        },
      };
    case types.CLEAR_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: {
          ...initialState.currentCompany,
        },
      };
    case types.CLEAR_ADMIN:
      return initialState;
    default:
      return state;
  }
};

export default adminReducer;
