import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, font } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Menu = ({ children }) => {
  return <MenuWrapper>{children}</MenuWrapper>;
};

const MenuItem = ({ isActive, children, icon, onClick }) => {
  return (
    <MenuItemWrapper isActive={isActive} onClick={onClick}>
      {icon && <FontAwesomeIcon fixedWidth icon={icon} style={{ marginRight: '0.75em' }} size="xs" />}
      <span>{children}</span>
    </MenuItemWrapper>
  );
};

Menu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

MenuItem.defaultProps = {
  icon: null,
  isActive: false,
  onClick: () => {},
};

MenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Menu;

const MenuWrapper = styled.div`
  background-color: ${colors.white};
`;

const MenuItemWrapper = styled.div`
  cursor: pointer;
  padding: 8px 2rem;
  font-size: ${font.size.l};
  align-items: center;
  display: flex;

  & + & {
    margin-top: 5px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.selectBlue};
      background-color: ${colors.lighterGray};
      font-weight: ${font.weight.semibold};
    `}

  &:hover {
    background-color: ${colors.lighterGray};
  }
`;

Menu.Item = MenuItem;
