import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'routes';
import { colors } from 'theme/styles';
import { getCompanyAnalytics } from 'redux/admin';
import { getTrendIcon } from 'utils/helpers';
import { TableSorter, SortItem, TopSection } from 'components/layout/Tables/TableComponents';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';
import LeadsTrendBar from 'components/UI/LeadsTrendBar';
import DateFromTo from 'components/UI/DateFromTo';
import NoData from 'components/UI/NoData';
import styled from 'styled-components';

const calcTotal = (dataObj) => Object.values(dataObj).reduce((acc, value) => value + acc, 0);

const AdminCompanyAnalysisPage = ({ analiticsData, isLoading, error, getCompanyAnalytics }) => {
  const history = useHistory();
  const companyId = history.location?.state?.company?.id || null;
  const [dateOne, setDateOne] = useState('');
  const [dateTwo, setDateTwo] = useState('');

  useEffect(() => {
    if (companyId && !dateOne && !dateTwo) {
      getCompanyAnalytics(companyId);
    } else if (companyId && dateOne && dateTwo) {
      getCompanyAnalytics(companyId, {
        fromDate_1: dateOne[0],
        toDate_1: dateOne[1],
        fromDate_2: dateTwo[0],
        toDate_2: dateTwo[1],
      });
    }
  }, [companyId, dateOne, dateTwo, getCompanyAnalytics]);

  const chartSnippet = (data, title, trend = null) => {
    const total = calcTotal(data);
    const totalNoDuplications = total - data.duplicate;
    console.log(trend)
    const [icon] = getTrendIcon(trend);

    return (
      <>
        <Heading size="xl">
          {title}{' '}
          {icon && (
            <small>
              ( trend: <StyledIcon title={trend} className={trend} icon={icon} /> )
            </small>
          )}
        </Heading>
        {calcTotal(data) > 0 ? (
          <>
            <p>
              Leadów: <strong>{total}</strong> (bez duplikatów{' '}
              <strong className="color-orange">{totalNoDuplications}</strong>)
            </p>
            <LeadsTrendBar data={[data]} />
          </>
        ) : (
          <NoData message="Brak danych" />
        )}
      </>
    );
  };

  return (
    <PageTemplate>
      {!companyId && <Redirect to={routes.adminCompanies} />}
      <TopSection>
        <div>
          <Heading size="xxl">Partner {history.location.state && history.location.state.company.name}</Heading>
        </div>
        <TableSorter>
          <SortItem>
            <span>Przedział analityczny #1</span>
            <DateFromTo handleDate={setDateOne} date={dateOne} />
          </SortItem>
          <SortItem>
            <span>Przedział analityczny #2</span>
            <DateFromTo handleDate={setDateTwo} date={dateTwo} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {isLoading && <Loading />}
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => history.replace(routes.adminCompanyUsers)}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      {!isLoading &&
        analiticsData.calculations &&
        analiticsData.calculations.now &&
        chartSnippet(
          analiticsData.calculations.now,
          `Analityka ${analiticsData.calculations.past ? '- przedział #1' : 'ogólna'}`,
          analiticsData.calculations.trend,
        )}
      {!isLoading &&
        analiticsData.calculations &&
        analiticsData.calculations.past &&
        chartSnippet(analiticsData.calculations.past, 'Analityka - przedział #2')}
    </PageTemplate>
  );
};

AdminCompanyAnalysisPage.defaultProps = {
  error: null,
};

AdminCompanyAnalysisPage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  analiticsData: PropTypes.instanceOf(Object).isRequired,
  getCompanyAnalytics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.admin.companyAnalitics.isLoading,
  analiticsData: state.admin.companyAnalitics.data,
  error: state.admin.companyUsers.error,
});

const mapDispatchToProps = {
  getCompanyAnalytics,
};

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.mediumGray};
  font-size: 0.8em;
  margin-left: 0.5em;

  &.UP {
    color: ${colors.orange};
  }

  &.DOWN {
    color: ${colors.red};
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdminCompanyAnalysisPage);
