import * as types from './types';

const initialState = {
  integrationDocs: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  currentIntegrationDoc: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadProgress: null,
};

const integrationDocs = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INTEGRATION_DOC_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
      };
    case types.GET_INTEGRATION_DOCS_REQUEST:
      return {
        ...state,
        integrationDocs: {
          ...state.integrationDocs,
          isLoading: true,
        },
      };
    case types.GET_INTEGRATION_DOCS_SUCCESS:
      return {
        ...state,
        integrationDocs: {
          ...state.integrationDocs,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_INTEGRATION_DOCS_FAILURE:
      return {
        ...state,
        integrationDocs: {
          ...state.integrationDocs,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_INTEGRATION_DOC_REQUEST:
      return {
        ...state,
        currentIntegrationDoc: {
          ...state.currentIntegrationDoc,
          isLoading: true,
        },
      };
    case types.GET_INTEGRATION_DOC_SUCCESS:
      return {
        ...state,
        currentIntegrationDoc: {
          ...state.currentIntegrationDoc,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_INTEGRATION_DOC_FAILURE:
      return {
        ...state,
        currentIntegrationDoc: {
          ...state.currentIntegrationDoc,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };
    case types.SET_CURRENT_INTEGRATION_DOC:
      return {
        ...state,
        currentIntegrationDoc: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case types.CLEAR_CURRENT_INTEGRATION_DOC:
      return {
        ...state,
        currentIntegrationDoc: {
          data: null,
          isLoading: false,
          error: null,
        },
      };
    case types.CLEAR_INTEGRATION_DOC:
      return initialState;
    default:
      return state;
  }
};

export default integrationDocs;
