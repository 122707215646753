import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL } from 'config';
import { colors, font } from 'theme/styles';
import { download, hslBaseColorFromString, humanFileSize } from 'utils/helpers';
import { mapTypeToIcon } from 'config/constants';
import { chatService } from 'services/chat';

const Message = ({ avatar, name, surname, date, text, fromMe, bulk, attachment, messageId }) => {
  const AVATAR_URL = `${BASE_URL}/storage/users/avatar/`;

  const downloadAttachment = (id, fileName) => {
    chatService
      .downloadAttachment(id)
      .then(res => download(res, fileName))
      .catch(err => console.log(err));
  };

  return (
    <MessageWrapper fromMe={fromMe} bulk={bulk} from={name + surname}>
      {!fromMe && !bulk && (
        <AvatarWrapper>
          {avatar && <Avatar src={AVATAR_URL + avatar} />}
          <AvatarInicials baseColor={hslBaseColorFromString(name + surname)}>
            {name.charAt(0) + surname.charAt(0)}
          </AvatarInicials>
        </AvatarWrapper>
      )}
      <Content>
        <ContentHeader>
          {!fromMe && (
            <Name>
              {name} {surname}
            </Name>
          )}
          <Date>{moment(date).format('HH:mm - DD.MM YYYY')}</Date>
        </ContentHeader>
        <Text>{text}</Text>
        {attachment.name && (
          <AttachmentWrapper onClick={() => downloadAttachment(messageId, attachment.displayName)}>
            <FontAwesomeIcon size="lg" icon={mapTypeToIcon[attachment.name.split('.')[1]] || 'file'} />
            <p>{attachment.displayName}</p>
            <span>{humanFileSize(Number(attachment.size))}</span>
          </AttachmentWrapper>
        )}
      </Content>
    </MessageWrapper>
  );
};

export default Message;

Message.defaultProps = {
  avatar: '',
  fromMe: false,
  bulk: false,
  attachment: false,
};

Message.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  fromMe: PropTypes.bool,
  bulk: PropTypes.bool,
  attachment: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  messageId: PropTypes.string.isRequired,
};

const AvatarWrapper = styled.div`
  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
  min-height: 3.2rem;
  margin-top: 0.6rem;
  margin-right: 1rem;
  border-radius: 50%;
  overflow: hidden;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const AvatarInicials = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: hsl(${({ baseColor }) => baseColor}, 80%, 85%);
  color: hsl(${({ baseColor }) => baseColor}, 80%, 15%);
  line-height: 1;
  font-size: ${font.size.s};
  font-weight: ${font.weight.semibold};
  letter-spacing: 0.2px;
`;

const Content = styled.div`
  padding: 0.8rem 1.6rem;
  border: 1px solid ${colors.blueLighter};
  background: ${colors.white};
  border-radius: 1rem;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: baseline;
`;

const Name = styled.span`
  font-size: ${font.size.xs};
  font-weight: ${font.weight.semibold};
  margin-right: 1rem;
`;

const Date = styled.p`
  font-size: ${font.size.xxs};
`;

const Text = styled.p``;

const MessageWrapper = styled.li`
  display: flex;
  max-width: 80%;

  ${({ bulk }) =>
    bulk &&
    css`
      margin-left: 4.2rem;
    `};

  ${({ fromMe }) =>
    fromMe &&
    css`
      margin-left: auto;
      ${Content} {
        background-color: ${colors.blueLighter};
      }
    `}

  &:not(:first-child) {
    margin-top: ${({ bulk }) => (bulk ? '4px' : '2rem')};
  }
`;

const AttachmentWrapper = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${colors.lightGray};
  padding: 1rem 0;
  font-size: ${font.size.xm};
  word-wrap: break-word;
  font-weight: ${font.weight.semibold};

  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    color: ${colors.orange};
  }

  svg {
    color: ${colors.orange};
  }

  &:hover,
  &:active {
    cursor: pointer;

    p {
      text-decoration: underline;
    }
  }
`;
