import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from 'routes';
import withAuth from 'HOC/withAuth';

// Auth
import LoginPage from 'views/Auth/LoginPage';
import SetPasswordPage from 'views/Auth/SetPasswordPage';
import ResetPasswordPage from 'views/Auth/ResetPasswordPage';
import ResetPasswordConfirmPage from 'views/Auth/ResetPasswordConfirmPage';

// Leads
import LeadsPage from 'views/Leads/LeadsPage';
import LeadDetailsPage from 'views/Leads/LeadDetailsPage';
import LeadOnboardingPage from 'views/Leads/LeadOnboardingPage';
import LeadCardPage from 'views/ClientPage';

// Admin
import AdminPage from 'views/Admin/AdminPage';
import AdminUsersPage from 'views/Admin/AdminUsersPage';
import AdminProductsPage from 'views/Admin/AdminProductsPage';
import AdminApiPage from 'views/Admin/AdminApiPage';
import AdminchallengesPage from 'views/Admin/AdminChallengesPage';
import AdminSystemMessagesPage from 'views/Admin/AdminSystemMessagesPage';
import AdminLeadsArchivePage from 'views/Admin/AdminLeadsArchivePage';
import AdminCompanyAnalysisPage from 'views/Admin/AdminCompanyAnalysisPage';
import AdminVacationsPage from 'views/Admin/AdminVacationsPage';
import AdminTransferLeadsPage from 'views/Admin/AdminTransferLeadsPage';
import AdminNotificationsPage from 'views/Admin/AdminNotificationsPage';
import AdminEmailTemplatesPage from 'views/Admin/AdminEmailTemplatesPage';
import AdminCompaniesPage from 'views/Admin/AdminCompaniesPage';
import AdminCompanyUsersPage from 'views/Admin/AdminCompanyUsersPage';
// import AdminCompanyProductsPage from 'views/Admin/AdminCompanyProductsPage';
import AdminCountyAnalysis from 'views/Admin/AdminCountyAnalysisPage';
import AdminCronPage from 'views/Admin/AdminCronPage';
import AdminCronDetailsPage from 'views/Admin/AdminCronDetailsPage';
import MerchantsAnalyticsPage from 'views/MerchantsAnalyticsPage';
import MerchantsAnalyticsDetailsPage from 'views/MerchantsAnalyticsDetailsPage';
import AdminOptionsPage from 'views/Admin/AdminOptionsPage';
import AdminDemosPage from 'views/Admin/AdminDemosPage';

// Partner
import PartnerCardPage from 'views/ClientPartnerPage';
import PartnerPage from 'views/Partner/PartnerPage';
import PartnerUsersPage from 'views/Partner/PartnerUsersPage';
import PartnerChallengesPage from 'views/Partner/PartnerChallengesPage';
import PartnerInvoicingPage from 'views/Partner/PartnerInvoicingPage';
import PartnerTermsPage from 'views/Partner/PartnerTermsPage';
import PartnerBillingsDetailsPage from 'views/Partner/PartnerBillingsDetailsPage';
// import PartnerInvoicingDetailsPage from 'views/Partner/PartnerInvoicingDetailsPage';

// Dr
import DrPanelPage from 'views/DrPanelPage';

// Merchant
import MerchantPanelPage from 'views/Merchant/MerchantPanelPage';

// Rest
import MessagesPage from 'views/MessagesPage';
import UserPage from 'views/UserPage';
import CollaborationPage from 'views/CollaborationPage';
import MarketingPage from 'views/MarketingPage';
import TrainingMaterialsPage from 'views/TrainingMaterialsPage';
import IntegrationDocsPage from 'views/IntegrationDocsPage';
import IntegrationOrdersPage from 'views/IntegrationOrdersPage';
import IntegrationOrderDetailsPage from 'views/IntegrationOrderDetailsPage';
import RaportsPage from 'views/RaportsPage';
import NotificationsPage from 'views/NotificationsPage';

// import TestPage  from 'views/TestPage';
// import NoPage from 'views/404';
import AdminClarityPage from './Admin/AdminClarityPage';
import leadsP24Page from './Merchant/leadsP24Page';
import AdminGuestPage from './Admin/AdminGuestPage';
import AnalyticsPage from './Analytics';
import MerchantsLeadsAnalyticsPage from './MerchantsLeadsAnalyticsPage';
import VimeoPlayerPage from './VimeoPlayerPage';
import CrmFormDemoPage from './Demos/CrmFormDemoPage';
// import TestPage from './TestPage';

const allStandardRoles = ['admin', 'dsp', 'dr', 'partner', 'subPartner', 'merchantPep', 'guest'];
const allRoles = ['admin', 'dsp', 'dr', 'partner', 'subPartner', 'merchantPep', 'skf', 'guest'];

const RootRouter = () => {
  const userRole = useSelector((s) => s.user.data.role?.slug);
  return (
    <Switch>
      <Route exact path={routes.login} component={LoginPage} />

      {userRole === 'skf' ? (
        <Route exact path={routes.home} render={() => <Redirect to={routes.integrationOrders} />} />
      ) : (
        <Route exact path={routes.home} render={() => <Redirect to={routes.leads} />} />
      )}

      <Route path={routes.contact} component={withAuth(MessagesPage, allRoles)} />
      <Route
        exact
        path={routes.raports}
        component={withAuth(RaportsPage, ['admin', 'dsp', 'dr', 'partner', 'subPartner', 'guest'])}
      />
      <Route exact path={routes.user} component={withAuth(UserPage, allRoles)} />
      <Route path={routes.leads} component={withAuth(LeadsPage, allStandardRoles)} />
      <Route path={routes.leadDetails} component={withAuth(LeadDetailsPage, allStandardRoles)} />
      <Route path={routes.notifications} component={withAuth(NotificationsPage, allRoles)} />
      <Route path={routes.leadOnboarding} component={withAuth(LeadOnboardingPage, ['admin', 'dsp', 'partner'])} />
      <Route path={routes.marketing} component={withAuth(MarketingPage, allStandardRoles)} />
      <Route
        path={routes.trainingMaterials}
        component={withAuth(TrainingMaterialsPage, ['admin', 'dsp', 'partner', 'subPartner'])}
      />
      <Route path={routes.player} component={withAuth(VimeoPlayerPage, ['admin', 'dsp', 'partner', 'subPartner'])} />
      <Route
        path={routes.documents}
        component={withAuth(IntegrationDocsPage, ['admin', 'dsp', 'dr', 'partner', 'subPartner'])}
      />
      <Route
        exact
        path={routes.integrationOrders}
        component={withAuth(IntegrationOrdersPage, ['admin', 'dsp', 'dr', 'partner', 'subPartner', 'skf'])}
      />
      <Route
        path={routes.integrationOrderDetails}
        component={withAuth(IntegrationOrderDetailsPage, ['admin', 'dsp', 'dr', 'partner', 'subPartner', 'skf'])}
      />

      <Route path={routes.setPassword} component={SetPasswordPage} />
      <Route exact path={routes.resetPassword} component={ResetPasswordPage} />
      <Route path={routes.resetPasswordConfirm} component={ResetPasswordConfirmPage} />

      <Route path={routes.client} component={withAuth(LeadCardPage, ['admin', 'dsp', 'merchantPep', 'guest'])} />
      <Route
        exact
        path={routes.collaboration}
        component={withAuth(CollaborationPage, ['admin', 'dsp', 'partner', 'subPartner'])}
      />

      <Route exact path={routes.drPanel} component={withAuth(DrPanelPage, ['dr'])} />

      <Route exact path={routes.merchantPanel} component={withAuth(MerchantPanelPage, ['admin', 'merchantPep'])} />
      <Route exact path={routes.przelewy24} component={withAuth(leadsP24Page, ['admin', 'merchantPep', 'guest'])} />

      <Route path={routes.clientPartner} component={withAuth(PartnerCardPage, ['admin', 'dsp', 'guest'])} />
      <Route exact path={routes.partner} component={withAuth(PartnerPage, ['partner', 'subPartner'])} />
      <Route path={routes.partnerUsers} component={withAuth(PartnerUsersPage, 'partner')} />
      <Route path={routes.partnerChallenges} component={withAuth(PartnerChallengesPage, 'partner')} />
      <Route
        exact
        path={routes.partnerInvoicing}
        component={withAuth(PartnerInvoicingPage, ['admin', 'dsp', 'partner', 'guest'])}
      />
      {/* <Route path={routes.partnerInvoicingDetails} component={withAuth(PartnerInvoicingDetailsPage, ['admin', 'dsp', 'partner'])} /> */}
      <Route path={routes.partnerTerms} component={withAuth(PartnerTermsPage, ['admin', 'dsp', 'guest'])} />
      <Route
        path={routes.partnerBillings}
        component={withAuth(PartnerBillingsDetailsPage, ['partner', 'admin', 'dsp'])}
      />

      <Route exact path={routes.adminGuest} component={withAuth(AdminGuestPage, ['guest'])} />
      <Route exact path={routes.admin} component={withAuth(AdminPage, ['admin', 'dsp'])} />
      <Route path={routes.adminUsers} component={withAuth(AdminUsersPage, ['admin', 'dsp', 'guest'])} />
      <Route path={routes.adminProducts} component={withAuth(AdminProductsPage, ['admin', 'dsp'])} />
      <Route exact path={routes.adminCompanies} component={withAuth(AdminCompaniesPage, ['admin', 'dsp', 'guest'])} />
      <Route
        exact
        path={routes.adminCompanyUsers}
        component={withAuth(AdminCompanyUsersPage, ['admin', 'dsp', 'guest'])}
      />
      <Route
        exact
        path={routes.adminCompanyAnalysis}
        component={withAuth(AdminCompanyAnalysisPage, ['admin', 'dsp', 'guest'])}
      />
      {/* <Route
        path={routes.adminCompanyProducts}
        component={withAuth(AdminCompanyProductsPage, ['admin', 'dsp', 'guest'])}
      /> */}
      <Route exact path={routes.adminChallenges} component={withAuth(AdminchallengesPage, ['admin', 'dsp'])} />
      <Route path={routes.adminApi} component={withAuth(AdminApiPage, 'admin')} />
      {/* <Route path={routes.adminZipcodes} component={withAuth(AdminZipcodesPage, 'admin')} /> */}
      <Route path={routes.adminTransferLeads} component={withAuth(AdminTransferLeadsPage, 'admin')} />
      <Route path={routes.adminSystemMessages} component={withAuth(AdminSystemMessagesPage, 'admin')} />
      <Route path={routes.adminLeadsArchive} component={withAuth(AdminLeadsArchivePage, 'admin')} />
      <Route path={routes.adminVacations} component={withAuth(AdminVacationsPage, ['admin', 'dsp'])} />
      <Route path={routes.adminNotifications} component={withAuth(AdminNotificationsPage, ['admin', 'dsp'])} />
      <Route path={routes.adminEmailTemplates} component={withAuth(AdminEmailTemplatesPage, 'admin')} />
      <Route path={routes.adminCountyAnalysis} component={withAuth(AdminCountyAnalysis, ['admin', 'dsp'])} />
      <Route path={routes.adminCron} exact component={withAuth(AdminCronPage, ['admin', 'dsp'])} />
      <Route path={routes.adminCronDetails} component={withAuth(AdminCronDetailsPage, ['admin', 'dsp'])} />

      <Route path={routes.analytics} exact component={withAuth(AnalyticsPage, ['admin', 'dsp'])} />
      <Route
        path={routes.merchantsLeadsAnalytics}
        exact
        component={withAuth(MerchantsLeadsAnalyticsPage, ['admin', 'dsp'])}
      />
      <Route path={routes.merchantsAnalytics} exact component={withAuth(MerchantsAnalyticsPage, ['admin', 'dsp'])} />
      <Route
        path={routes.merchantsAnalyticsDetails}
        component={withAuth(MerchantsAnalyticsDetailsPage, ['admin', 'dsp'])}
      />
      <Route path={routes.adminClarity} component={withAuth(AdminClarityPage, ['admin', 'dsp'])} />
      <Route path={routes.adminOptions} component={withAuth(AdminOptionsPage, ['admin'])} />
      <Route path={routes.adminDemos} exact component={withAuth(AdminDemosPage, ['admin', 'dsp'])} />

      {/* Demos */}
      <Route path={routes.adminDemoCrmForm} component={withAuth(CrmFormDemoPage, ['admin', 'dsp'])} />

      {/* <Route path={routes.test} component={TestPage} /> */}
      {/* <Route exact path={routes.test} render={() => <Redirect to={routes.client} />} /> */}

      <Route path="*" render={() => <Redirect to={routes.login} />} />
    </Switch>
  );
};

export default RootRouter;
