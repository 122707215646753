import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCountyAnalytics } from 'redux/admin';
import { SortItem, TableSorter, TopSection } from 'components/layout/Tables/TableComponents';
import { regionService } from 'services/region';
import { colors, font } from 'theme/styles';
import { displayTime } from 'utils/helpers';
import { NUMBER_OF_DISPLAYED_ZIPCODES } from 'config/constants';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import Heading from 'components/UI/Heading';
import BaseTable from 'components/layout/Tables/BaseTable';
import Button from 'components/UI/Button';
import ErrorPopup from 'components/layout/ErrorPopup';
import Select from 'components/UI/Select';
import Block from 'components/UI/Block';
import styled from 'styled-components';
import NoData from 'components/UI/NoData';
import SidePanel from 'components/layout/SidePanel';
import AddZipcode from 'components/forms/Admin/AddZipcode';
import Chip from 'components/UI/Chip';

const headers = [
  { displayName: 'Przedstawiciel', property: 'fullName' },
  { displayName: 'Email', property: 'email' },
  { displayName: 'Aktywny', property: 'active', type: 'status' },
];

const AdminCountyAnalysis = ({ analyticsData, getCountyAnalytics, error, isLoading }) => {
  const history = useHistory();
  const allCounties = useRef([]);

  const [newCodeMode, setNewCodeMode] = useState(false);
  const [countyRegionDetails, setCountyRegionDetails] = useState(null);

  const [filteredCounties, setFilteredCounties] = useState([]);
  const [currentCounty, setCurrentCounty] = useState('');
  const [county, setCounty] = useState(null);
  const [phLimit, setPhLimit] = useState('');

  useEffect(() => {
    getCountyAnalytics();
  }, [getCountyAnalytics]);

  useEffect(() => {
    if (!allCounties.current.length) {
      allCounties.current = analyticsData;
      setFilteredCounties(analyticsData);
    }
  }, [analyticsData, allCounties]);

  const handleCountySelect = e => {
    const countyId = e.target.value;
    setCounty(allCounties.current.find(c => c.id === countyId));
    setCurrentCounty(countyId);

    regionService.getCountyCodes(countyId).then(res => {
      setCountyRegionDetails(res.data);
    });
  };

  const updateZipCodesAfterSubmit = updatedCountyId => {
    if (currentCounty && currentCounty === updatedCountyId) {
      regionService.getCountyCodes(updatedCountyId).then(res => {
        setCountyRegionDetails(res.data);
      });
    }
  };

  const handlePhClick = ph => history.push(routes.adminUsers, { showUser: ph });

  const handleLimitSelect = e => {
    const limit = e.target.value;
    setPhLimit(limit);
    setCurrentCounty('');
    if (limit === '') setFilteredCounties(allCounties.current);
    else if (limit === '4+') setFilteredCounties(allCounties.current.filter(c => c.users.length >= 4));
    else setFilteredCounties(allCounties.current.filter(c => c.users.length === parseInt(limit, 10)));
  };

  return (
    <PageTemplate>
      {isLoading && <Loading big />}
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => {}}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      <TopSection>
        <Button onClick={() => setNewCodeMode(true)}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj kod pocztowy
        </Button>
        <TableSorter>
          <StyledSortItem>
            <span>Filtruj po ilość przedstawicieli</span>
            <Select small value={phLimit} onChange={handleLimitSelect}>
              <option value="">&nbsp;</option>
              <option value="0">0 przedstawiciel / powiat </option>
              <option value="1">1 przedstawiciel / powiat </option>
              <option value="2">2 przedstawicieli / powiat</option>
              <option value="3">3 przedstawicieli / powiat</option>
              <option value="4+">4+ przedstawicieli / powiat</option>
            </Select>
          </StyledSortItem>
          <StyledSortItem>
            <span>Wybierz powiat</span>
            <Select small value={currentCounty} onChange={handleCountySelect}>
              <option value="">&nbsp;</option>
              {filteredCounties.map(c => (
                <option key={c.id} value={c.id} style={{ background: !c.users.length && colors.red }}>
                  {c.name} — {c.users.length}
                </option>
              ))}
            </Select>
          </StyledSortItem>
        </TableSorter>
      </TopSection>
      {county ? (
        <StyledBlock>
          <Heading size="xl">
            Powiat: <span className="name">{county.name}</span>
          </Heading>
          {county.lastUpdateAssignToUser && (
            <p>
              Ostatnio aktualizowany: <strong className="orange">{displayTime(county.lastUpdateAssignToUser)}</strong>
            </p>
          )}
          {countyRegionDetails && (
            <div style={{ marginBottom: '20px' }}>
              <p style={{ marginBottom: '20px' }}>
                Przypisanych kodów: <strong className="orange">{countyRegionDetails.PostCodes.length}</strong>
              </p>
              {countyRegionDetails.PostCodes.slice(0, NUMBER_OF_DISPLAYED_ZIPCODES).map(c => (
                <Chip key={c.id}>{c.postCode}</Chip>
              ))}
              {countyRegionDetails.PostCodes.length - NUMBER_OF_DISPLAYED_ZIPCODES > 0 && (
                <span> + {countyRegionDetails.PostCodes.length - NUMBER_OF_DISPLAYED_ZIPCODES}</span>
              )}
            </div>
          )}
          <Heading size="l">Przypisani przedstawiciele: </Heading>
          <BaseTable data={county.users} headers={headers} handleRowClick={handlePhClick} />
        </StyledBlock>
      ) : (
        <NoData message="Wybierz powiat z listy" />
      )}
      {allCounties.current.length > 0 && (
        <SidePanel small toggleClose={() => setNewCodeMode(false)} isVisible={newCodeMode}>
          <AddZipcode onSuccess={updateZipCodesAfterSubmit} countiesSuggestions={allCounties.current} />
        </SidePanel>
      )}
    </PageTemplate>
  );
};

AdminCountyAnalysis.defaultProps = {
  error: null,
};

AdminCountyAnalysis.propTypes = {
  analyticsData: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  // Actions
  getCountyAnalytics: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  analyticsData: state.admin.countyAnalitics.data,
  isLoading: state.admin.countyAnalitics.isLoading,
  error: state.admin.countyAnalitics.error,
});

const mapDispatchToProps = {
  getCountyAnalytics,
};

const StyledSortItem = styled(SortItem)`
  flex: 0 1 200px;
  select {
    width: 100%;
  }
`;

const StyledBlock = styled(Block)`
  .orange {
    color: ${colors.orange};
  }
  .name {
    color: ${colors.orange};
    font-weight: ${font.weight.semibold};
    text-transform: capitalize;
  }
`;
export default connect(mapStateToProps, mapDispatchToProps)(AdminCountyAnalysis);
