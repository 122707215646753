import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Button from 'components/UI/Button';
import { Block } from 'components/UI/Structure';
import Heading from 'components/UI/Heading';
import { breakpoints } from 'theme/styles';

const FormikStepper = ({
  children,
  onStepChange,
  title,
  additionalButtons,
  viewMode,
  additionalTitle,
  loading,
  isDemoMode,
  ...props
}) => {
  const childrenArray = React.Children.toArray(children);
  const allSteps = childrenArray.length;
  const [step, setStep] = useState(0);
  const currentStep = childrenArray[step];

  const goBack = () => setStep((s) => s - 1);
  const goNext = () => setStep((s) => s + 1);

  return (
    <Formik
      {...props}
      validationSchema={viewMode ? null : currentStep.props.validationSchema}
      onSubmit={(values, helpers) => {
        if (step === allSteps - 1) {
          props.onSubmit(values, helpers);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {(formik) => (
        <Form>
          {(additionalTitle || additionalButtons) && (
            <Header>
              {additionalTitle}
              {additionalButtons && <ButtonsWrapper> {additionalButtons(formik)} </ButtonsWrapper>}
            </Header>
          )}
          <Block>
            <StepperHeader>
              {title && (
                <Heading size="xl">
                  {title} <br />
                  <span className="orange"># {currentStep.props.title}</span>
                </Heading>
              )}
              <Heading size="xxxl">
                <span className="orange">
                  {step + 1}&nbsp;/&nbsp;{allSteps}
                </span>
              </Heading>
            </StepperHeader>
          </Block>
          {React.cloneElement(currentStep, { ...formik })}
          <ButtonsBlock>
            {step > 0 && (
              <Button
                small
                secondary
                type="button"
                onClick={() => {
                  if (onStepChange) onStepChange(step, formik.values);
                  goBack();
                }}
              >
                Poprzedni etap
              </Button>
            )}

            {step < allSteps - 1 && (
              <Button
                small
                greenFilled={formik.isValid}
                secondary={!formik.isValid}
                right
                type="button"
                onClick={() => {
                  if (!formik.isValid) {
                    formik.submitForm();
                  } else {
                    if (onStepChange) onStepChange(step, formik.values);
                    formik.setTouched({});
                    goNext();
                  }
                }}
              >
                {formik.isValid ? 'Dalej' : 'Sprawdź pola'}
              </Button>
            )}
            {step === allSteps - 1 && !viewMode && (
              <Button small greenFilled disabled={!formik.isValid || loading || isDemoMode} right type="submit">
                Wyślij
              </Button>
            )}
          </ButtonsBlock>
        </Form>
      )}
    </Formik>
  );
};

FormikStepper.defaultProps = {
  title: '',
  onStepChange: false,
  additionalButtons: false,
  additionalTitle: false,
  loading: false,
  isDemoMode: false,
};

FormikStepper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStepChange: PropTypes.func,
  additionalButtons: PropTypes.func,
  viewMode: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  additionalTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
  isDemoMode: PropTypes.bool,
};

const Header = styled.div`
  margin-bottom: 20px;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  @media (min-width: ${breakpoints.mobileMinimum}) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

const ButtonsBlock = styled.div`
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
`;

const StepperHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:first-child {
    margin-right: 20px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 5px;
  }

  @media (min-width: ${breakpoints.mobileMinimum}) {
    justify-content: flex-end;
  }
`;

export default FormikStepper;
