import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, font } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const Chip = ({ onClick, children, hideDeleteBtn }) => {
  return (
    <Wrapper>
      <span>{children}</span>
      {onClick && !hideDeleteBtn && <StyledFontAwesomeIcon icon={faTimesCircle} onClick={onClick} />}
    </Wrapper>
  );
};

Chip.defaultProps = {
  children: '',
  onClick: undefined,
  hideDeleteBtn: false,
};

Chip.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string,
  hideDeleteBtn: PropTypes.bool,
};

export default Chip;

const Wrapper = styled.span`
  background: ${colors.orange};
  display: inline-flex;
  align-items: center;
  color: ${colors.white};
  font-size: ${font.size.s};
  padding: 2px 8px;
  margin-bottom: 6px;
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 6px;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;

  &:hover {
    cursor: pointer;
    color: ${colors.blueGray};
  }
`;
