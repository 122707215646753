import React, { useState } from 'react';
import Styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyToClipboard } from 'utils/helpers';
import { colors } from 'theme/styles';

const CopyString = ({ children, text, withNotification, staticPosition = false }) => {
  const [clicked, setClicked] = useState(false);

  const clickHandler = () => {
    copyToClipboard(text);
    setClicked(true);
    setTimeout(() => setClicked(false), 1500);
  };

  return (
    <Wrapper onClick={clickHandler} staticPosition={staticPosition}>
      {children && <strong>{children}</strong>}
      <FontAwesomeIcon title="Kopiuj" icon={clicked ? 'clipboard-check' : 'clipboard'} />
      {staticPosition && !clicked && withNotification && <span>Kliknij aby skopiować</span>}
      {clicked && withNotification && <span>Skopiowano</span>}
    </Wrapper>
  );
};

CopyString.defaultProps = {
  withNotification: true,
};

CopyString.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  text: PropTypes.string.isRequired,
  withNotification: PropTypes.bool,
  staticPosition: PropTypes.bool,
};

export default CopyString;

const Wrapper = Styled.div`
    position: relative;
    display: inline-block;
    margin-left: 0.8em;
    color: ${colors.orange};
    cursor: pointer;
    
    strong {
      font-weight: 600;
      margin-right: 1rem;
    }

    span {
        position: absolute;
        top: 50%;
        left: calc(100% + 0.5em);
        transform: translateY(-50%);
        font-size: 0.8em;
        line-height: 1;
    }

${({ staticPosition }) =>
  staticPosition &&
  css`
    strong {
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    span {
      position: static;
      transform: none;
      margin-left: 1rem;
    }
  `}
`;
