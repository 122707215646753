import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'theme/styles';
import placeholder from 'assets/avatar-placeholder.png';
import UploadAvatar from 'components/forms/UploadAvatar';

const Avatar = ({ url }) => {
  return (
    <Wrapper url={url}>
      <InputContainer>
        <UploadAvatar />
      </InputContainer>
    </Wrapper>
  );
};

Avatar.defaultProps = {
  url: '',
};

Avatar.propTypes = {
  url: PropTypes.string,
};

export default Avatar;

const Wrapper = styled.div`
  width: 100%;
  max-width: 250px;
  height: 250px;
  background-image: ${({ url }) => (url ? `url(${url})` : `url(${placeholder})`)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.lighterGray}60;
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 1;
  }
`;
