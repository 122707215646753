import { useState } from 'react';
import { suggestionsService } from 'services/suggestions';
import { adminService } from 'services/admin';

export default props => {
  const { type } = props || {};
  const [suggestions, setSuggestions] = useState([]);
  let fetchSuggestions;

  switch (type) {
    case 'partners':
      fetchSuggestions = inputValue => {
        if (inputValue.length <= 2) return;
        adminService
          .getAllCompanies({
            limit: 10,
            page: 1,
            showDeleted: false,
            search: inputValue,
          })
          .then(payload => setSuggestions(payload.data.rows))
          .catch(err => {
            console.log(err);
          });
      };
      break;
    default:
      fetchSuggestions = (inputValue, role) => {
        if (inputValue.length <= 2) return;
        suggestionsService
          .getUsersSuggestions(inputValue, role, 20)
          .then(payload => setSuggestions(payload.data))
          .catch(err => {
            console.log(err);
          });
      };
  }

  return [suggestions, fetchSuggestions];
};
