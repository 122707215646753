import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, font } from 'theme/styles';

const Textarea = React.forwardRef(
  ({ name, placeholder, onChange, onBlur, className, value, error, width, height, locked, disabled, label }, ref) => (
    <Field width={width}>
      {label && (
        <StyledLabel disabled={disabled} error={error} htmlFor={name}>
          {label}
        </StyledLabel>
      )}
      <StyledTextarea
        ref={ref}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        className={className}
        error={error}
        locked={locked}
        height={height}
      />
    </Field>
  ),
);

Textarea.displayName = 'Textarea';

Textarea.defaultProps = {
  className: '',
  value: '',
  placeholder: '',
  error: false,
  width: '',
  height: '',
  locked: false,
  disabled: false,
  label: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  ref: null,
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Textarea;

// Styled components:

const Field = styled.div`
  width: ${({ width }) => width || '100%'};
  /* min-height: ${({ height }) => height || '150px'}; */
  padding: 0px;
`;

const StyledTextarea = styled.textarea`
  font-family: 'Source Sans Pro', sans-serif;
  background: transparent;
  border: none;
  width: 100%;
  height: ${({ height }) => (height ? `calc(${height} - 10px)` : '125px')};
  border: 1px solid ${colors.lightGray};
  font-size: ${font.size.s};
  color: ${({ disabled }) => (disabled ? colors.darkGray : colors.black)};
  line-height: 1.5;
  padding: 10px;
  margin: 0;
  resize: ${({ locked }) => (locked ? 'none' : 'vertical')};

  &:focus {
    outline: none;
    border: 1px solid ${colors.orange};
  }
`;

const StyledLabel = styled.label`
  font-size: ${font.size.s};
  color: ${colors.mediumGray};
  line-height: 20px;
`;
