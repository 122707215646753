// All invoices
export const GET_ALL_INVOICES_REQUEST = 'GET_ALL_INVOICES_REQUEST';
export const GET_ALL_INVOICES_SUCCESS = 'GET_ALL_INVOICES_SUCCESS';
export const GET_ALL_INVOICES_FAILURE = 'GET_ALL_INVOICES_FAILURE';

// One invoice
export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';

// All billings
export const GET_BILLINGS_REQUEST = 'GET_BILLINGS_REQUEST';
export const GET_BILLINGS_SUCCESS = 'GET_BILLINGS_SUCCESS';
export const GET_BILLINGS_FAILURE = 'GET_BILLINGS_FAILURE';

// One billing
export const GET_BILLING_REQUEST = 'GET_BILLING_REQUEST';
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS';
export const GET_BILLING_FAILURE = 'GET_BILLING_FAILURE';

// All invoice templates
export const GET_ALL_INVOICES_TEMPLATES_REQUEST = 'GET_ALL_INVOICES_TEMPLATES_REQUEST';
export const GET_ALL_INVOICES_TEMPLATES_SUCCESS = 'GET_ALL_INVOICES_TEMPLATES_SUCCESS';
export const GET_ALL_INVOICES_TEMPLATES_FAILURE = 'GET_ALL_INVOICES_TEMPLATES_FAILURE';

// One invoice template
export const GET_INVOICE_TEMPLATE_REQUEST = 'GET_INVOICE_TEMPLATE_REQUEST';
export const GET_INVOICE_TEMPLATE_SUCCESS = 'GET_INVOICE_TEMPLATE_SUCCESS';
export const GET_INVOICE_TEMPLATE_FAILURE = 'GET_INVOICE_TEMPLATE_FAILURE';

// Invoice utils
export const SET_CURRENT_INVOICE = 'SET_CURRENT_INVOICE';
export const CLEAR_CURRENT_INVOICE = 'CLEAR_CURRENT_INVOICE';

// Invoice template utils
export const SET_CURRENT_INVOICE_TEMPLATE = 'SET_CURRENT_INVOICE_TEMPLATE';
export const CLEAR_CURRENT_INVOICE_TEMPLATE = 'CLEAR_CURRENT_INVOICE_TEMPLATE';

// Billing utils
export const SET_CURRENT_BILLING = 'SET_CURRENT_BILLING';
export const CLEAR_CURRENT_BILLING = 'CLEAR_CURRENT_BILLING';

// RESET
export const CLEAR_INVOICING = 'CLEAR_INVOICING';
