import * as types from './types';

const initialState = {
  isLoading: false,
  data: [],
  error: '',
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...action.payload.data],
        error: '',
      };
    case types.GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.err.response.data.error,
      };
    case types.CLEAR_PRODUCTS:
      return initialState;
    default:
      return state;
  }
};

export default productReducer;
