import JSConfetti from 'js-confetti';

JSConfetti.prototype.hide = function () {
  if (this.canvas) this.canvas.style.display = 'none';
};

JSConfetti.prototype.show = function () {
  if (this.canvas) this.canvas.style.display = 'block';
};

const jsConfetti = new JSConfetti();
jsConfetti.hide();

const Confetti = () => {
  return null;
};

export default Confetti;
export { jsConfetti };
