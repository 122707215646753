import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { logout } from 'redux/user';
import { Formik, Form, ErrorMessage } from 'formik';
import { changePasswordSchema } from 'utils/validation';
import { userService } from 'services/user';
import { breakpoints } from 'theme/styles';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import ErrorLabel from 'components/UI/ErrorLabel';
import Heading from 'components/UI/Heading';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';

const ChangePassword = ({ userId, logout }) => {
  const [changeMode, setChangeMode] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleReturn = () => {
    setChangeMode(false);
    setSuccess(false);
    setError(false);
  };

  const handleSubmit = values => {
    setError(false);
    setSuccess(false);

    const { oldPassword, newPassword } = values;
    userService
      .changePassword(userId, { oldPassword, newPassword })
      .then(data => {
        console.log(data);
        setChangeMode(false);
        setSuccess(true);
      })
      .catch(err => {
        setChangeMode(false);
        setError(err.response.data.error.message);
        console.dir(err);
      });
  };

  const toggleChange = () => setChangeMode(!changeMode);

  return (
    <>
      <Button type="button" onClick={toggleChange}>
        Zmień hasło
      </Button>
      {success && (
        <SuccessPopup fixed>
          <Heading>Hasło zostało zmienione</Heading>
          <div className="buttons">
            <Button onClick={() => logout()}>Zaloguj</Button>
          </div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error}</p>
          <div className="buttons">
            <Button onClick={handleReturn}>Zamknij</Button>
          </div>
        </ErrorPopup>
      )}
      {changeMode && (
        <SuccessPopup fixed blur={false}>
          <PopupInnerWrapper>
            <Heading centered>Zmień hasło</Heading>
            <Formik
              initialValues={{
                oldPassword: '',
                newPassword: '',
                newPasswordConfirmation: '',
              }}
              validationSchema={changePasswordSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <StyledForm>
                  <Input
                    autoComplete="off"
                    name="oldPassword"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Stare hasło"
                    value={values.oldPassword || ''}
                    error={touched.oldPassword && errors.oldPassword}
                  />
                  <ErrorMessage component={ErrorLabel} name="oldPassword" />
                  <Input
                    autoComplete="off"
                    name="newPassword"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nowe hasło"
                    value={values.newPassword || ''}
                    error={touched.newPassword && errors.newPassword}
                  />
                  <ErrorMessage component={ErrorLabel} name="newPassword" />
                  <Input
                    autoComplete="off"
                    name="newPasswordConfirmation"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Powtórz nowe hasło"
                    value={values.newPasswordConfirmation || ''}
                    error={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
                  />
                  <ErrorMessage component={ErrorLabel} name="newPasswordConfirmation" />
                  <ButtonWrapper>
                    <Button type="submit">Zmień</Button>
                    <Button type="button" secondary onClick={handleReturn}>
                      Anuluj
                    </Button>
                  </ButtonWrapper>
                </StyledForm>
              )}
            </Formik>
          </PopupInnerWrapper>
        </SuccessPopup>
      )}
    </>
  );
};

ChangePassword.propTypes = {
  userId: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  logout,
};

export default connect(null, mapDispatchToProps)(ChangePassword);

// styled components

const PopupInnerWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px 0;

  @media (min-width: ${breakpoints.mobile}) {
    padding: 40px 20px;
  }
`;

const StyledForm = styled(Form)`
  position: 'absolute';
  margin-top: 30px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  & > ${Button} {
    margin-bottom: 20px;
    width: 100%;
    min-width: unset;
    max-width: 140px;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;
