export default [
  { value: '0742', display: 'Lekarz weterynarz' },
  { value: '0763', display: 'Spółdzielnie rolnicze, gospodarstwa rolne' },
  {
    value: '1520',
    display: 'Generalni wykonawcy - obiekty mieszkalne i komercyjne (usługi ogólnobudowlane i remontowe)',
  },
  { value: '1711', display: 'Wykonawcy instalacji grzewczych, hydraulicznych i klimatyzacyjnych' },
  { value: '1731', display: 'Wykonawcy instalacji elektrycznych' },
  { value: '1740', display: 'Zakłady kamieniarskie' },
  { value: '1750', display: 'Usługi stolarskie' },
  { value: '1761', display: 'Usługi dekarskie' },
  { value: '1771', display: 'Usługi brukarskie' },
  { value: '2741', display: 'Usługi wydawnicze i drukarskie' },
  { value: '2791', display: 'Skład tekstu, wytwarzanie matryc, usługi powiązane' },
  { value: '4011', display: 'Transport kolejowy towarów' },
  { value: '4111', display: 'Komunikacja lokalna i podmiejska' },
  { value: '4112', display: 'Koleje pasażerskie - PKP' },
  { value: '4121', display: 'Taksówki i limuzyny' },
  { value: '4131', display: 'Linie autobusowe' },
  { value: '4214', display: 'Przewóz towarów samochodami ciężarowymi' },
  { value: '4215', display: 'Usługi kurierskie' },
  { value: '4225', display: 'Magazyny, Usługi przechowywania' },
  { value: '4411', display: 'Rejsy pasażerskie i parowcowe' },
  { value: '4457', display: 'Wynajem i dzierżawa łodzi' },
  { value: '4511', display: 'Linie lotnicze i transport lotniczy' },
  { value: '4582', display: 'Lotniska, terminale lotnicze' },
  { value: '4722', display: 'Biura podróży' },
  { value: '4784', display: 'Opłaty za korzystanie z dróg i mostów' },
  { value: '4789', display: 'Usługi transportowe, nigdzie indziej nie sklasyfikowane' },
  { value: '4812', display: 'Sklep z telefonami i sprzętem telekomunikacyjnym' },
  { value: '4814', display: 'Usługi telekomunikacyjne' },
  { value: '4816', display: 'Dostawcy usług i serwisów on-line' },
  { value: '4899', display: 'Usługi telewizji kablowej i innych płatnych telewizji' },
  { value: '4900', display: 'Zakłady użyteczności publicznej: elektryczność, gaz woda i kanalizacja' },
  { value: '5021', display: 'Wyposażenie biur (meble biurowe/reklamowe) ' },
  { value: '5039', display: 'Materiały budowlane, nigdzie indziej niesklasyfikowane' },
  { value: '5044', display: 'Sklep ze sprzętem fotograficznym, kserograficznym' },
  { value: '5046', display: 'Wyposażenie handlowe, gdzie indziej niesklasyfikowane' },
  { value: '5047', display: 'Sprzęt szpitalny i zaopatrzenie medyczne, stomatologiczne i okulistyczne' },
  { value: '5051', display: 'Centra Serwisowe i biurowe' },
  { value: '5065', display: 'Sklep elektryczny' },
  { value: '5072', display: 'Sklep z narzędziami' },
  { value: '5074', display: 'Sklep hydrauliczny' },
  { value: '5085', display: 'Dostawy przemysłowe, nigdzie indziej niesklasyfikowane' },
  { value: '5111', display: 'Sklep z artykułami biurowymi i  papierniczymi ' },
  { value: '5169', display: 'Chemia przemysłowa i gospodarcza' },
  { value: '5200', display: 'Wyposażenie wnętrz' },
  { value: '5211', display: 'Sklep z materiałami budowlanymi i wyposażeniem wnętrz' },
  { value: '5231', display: 'Sklep ze szkłem, farbą i tapetą' },
  { value: '5251', display: 'Sklep z artykułami metalowymi' },
  { value: '5261', display: 'Sklep ogrodniczy' },
  { value: '5271', display: 'Sprzedaż domów mobilnych' },
  { value: '5300', display: 'Hurtownie (art. spożywcze, art. elektroniczne, meble, art. elektroniczne)' },
  { value: '5309', display: 'Sklep wolnocłowy' },
  { value: '5310', display: 'Sklep dyskontowy' },
  { value: '5311', display: 'Dom towarowy, handlowy' },
  { value: '5331', display: 'Sklep wielobranżowy' },
  { value: '5399', display: 'Różnorodne gadżety' },
  { value: '5411', display: 'Supermaket i hipermarket spożywczy' },
  { value: '5422', display: 'Wyroby mięsne' },
  { value: '5441', display: 'Cukiernie' },
  { value: '5451', display: 'Sklep nabiałowy' },
  { value: '5462', display: 'Piekarnia' },
  {
    value: '5499',
    display: 'Sklepy: spożywcze, z ekologiczną żywnością, z herbatą i kawą, z warzywami i owocami, lodziarnia',
  },
  { value: '5511', display: 'Salon lub sklep samochodowy' },
  { value: '5521', display: 'Komis samochodowy' },
  { value: '5532', display: 'Sklepy z oponami do samochodów' },
  { value: '5533', display: 'Sklep z częściami i akcesoriami samochodowymi' },
  { value: '5541', display: 'Stacje benzynowe' },
  { value: '5542', display: 'Bezobsługowe stacje benzynowe' },
  { value: '5551', display: 'Sprzedaż łodzi' },
  { value: '5561', display: 'Sprzedaż przyczep campingowych' },
  { value: '5571', display: 'Sklep z motorami' },
  { value: '5599', display: 'Sprzedaż gokartów, skuterów śnieżnych, traktorów i innych maszyn rolniczych' },
  { value: '5611', display: 'Sklep z odzieżą męską i chłopięcą' },
  { value: '5621', display: 'sklep z odzieżą damską (również salony mody ślubnej)' },
  {
    value: '5631',
    display: 'Akcesoria odzieżowe dla kobiet,sprzedaż torebek,czapek, szali, bielizny, pończoch, ozdób do włosów',
  },
  { value: '5641', display: 'Sklep z odzieżą dla dzieci i niemowląt' },
  { value: '5651', display: 'Sklepy odzieżowe rodzinne' },
  { value: '5655', display: 'Stroje i akcesoria sportowe' },
  { value: '5661', display: 'Sklep obuwniczy' },
  { value: '5681', display: 'Sklep futrzarski' },
  { value: '5691', display: 'Sklep z odzieżą damsko-męską' },
  { value: '5697', display: 'Usługi krawieckie' },
  { value: '5699', display: 'Sklepy z innymi akcesoriami i odzieżą' },
  { value: '5712', display: 'Sklep meblowy' },
  { value: '5713', display: 'Sklep z materiałami na podłogi (wykładziny, dywany, panele)' },
  { value: '5714', display: 'Sklep z firanami, zasłonami materiałami i obiciami' },
  { value: '5719', display: 'Sklepy z akcesoriami meblowymi' },
  { value: '5722', display: 'Sklep AGD' },
  { value: '5732', display: 'Sklep RTV' },
  { value: '5733', display: 'Sklep z art. muzycznymi' },
  { value: '5734', display: 'Sprzedaż sprzętu komputerowego i oprogramowania' },
  { value: '5735', display: 'Sklep z płytami' },
  { value: '5811', display: 'Catering' },
  { value: '5812', display: 'Restauracje i jadalnie' },
  { value: '5813', display: 'Puby, bary, tawerny, kluby nocne, dyskoteki, koktajlbary, kawiarnie' },
  { value: '5814', display: 'Fast food' },
  { value: '5912', display: 'Apteki, sklepy z lekami' },
  { value: '5921', display: 'Sklep monopolowy' },
  { value: '5931', display: 'Secon-handy' },
  { value: '5932', display: 'Sklep z antykami, sprzedaż, usługi naprawcze i renowacyjne' },
  { value: '5933', display: 'Lombardy' },
  { value: '5940', display: 'Sklep rowerowy – sprzedaż i serwis' },
  { value: '5941', display: 'Sklep sportowo - turystyczny' },
  { value: '5942', display: 'Księgarnie' },
  { value: '5943', display: 'Sklep papierniczy, zaopatrzenie szkoły i biura' },
  { value: '5944', display: 'Jubiler, sklepy z biżuterią, zegarkami i srebrną zastawą' },
  { value: '5945', display: 'Sklep z grami i zabawkami oraz hobbistyczne' },
  { value: '5946', display: 'Sklep fotograficzny' },
  { value: '5947', display: 'Sklep z upominkami' },
  { value: '5948', display: 'Sklep z wyrobami skórzanymi' },
  { value: '5949', display: 'Pasmanteria, art. krawieckie' },
  { value: '5950', display: 'Sklep ze szkłem i kryształami' },
  { value: '5970', display: 'Sklep z artykułami dla plastyków' },
  { value: '5971', display: 'Galerie sztuki' },
  { value: '5972', display: 'Sklep filatelistyczny' },
  { value: '5973', display: 'Sklep z dewocjonaliami' },
  { value: '5975', display: 'Sklepy z aparatami słuchowymi - sprzedaż, serwis' },
  { value: '5976', display: 'Sklep ortopedyczny' },
  { value: '5977', display: 'Sklep z kosmetykami' },
  { value: '5983', display: 'Stacje benzynowe – olej opałowy, drewno, węgiel, benzyna' },
  { value: '5992', display: 'Kwiaciarnie' },
  { value: '5993', display: 'Sklep tytoniowy' },
  { value: '5994', display: 'Sprzedaż prasy - kioski' },
  { value: '5995', display: 'Sklep zoologiczny i z jedzeniem dla zwierząt' },
  { value: '5999', display: 'Rozmaite i specjalistyczne sklepy detaliczne nie sklasyfikowane w innym MCC' },
  { value: '6012', display: 'Instytucje finansowe (oddziały bankowe)' },
  { value: '6051', display: 'Quasi-cash- zakup czeków podróżnych, obcej waluty (kantory)' },
  { value: '6300', display: 'Towarzystwa ubezpieczeniowe' },
  { value: '6513', display: 'Agencje nieruchomości' },
  { value: '7011', display: 'Hotel - Hotele, motele, zajazdy, pensjonaty, uzdrowiska, spa' },
  { value: '7012', display: 'Biuro pośrednictwa wynajmu nieruchomości' },
  { value: '7032', display: 'Ośrodki sportowe i rekreacyjne' },
  { value: '7033', display: 'Pola campingowe' },
  { value: '7211', display: 'Pralnie samoobsługowe' },
  { value: '7216', display: 'Pralnie ' },
  { value: '7217', display: 'Usługi czyszczenia dywanów' },
  { value: '7221', display: 'Usługi fotograficzne (lub studia fotograficzne) – zdjęcia ślubne, komunijne, szkolne' },
  { value: '7230', display: 'Salony fryzjerskie i kosmetyczne' },
  { value: '7251', display: 'Usługi szewskie' },
  { value: '7261', display: 'Usługi pogrzebowe' },
  { value: '7276', display: 'Doradca podatkowy' },
  { value: '7277', display: 'Poradnie psychologiczne i małżeńskie' },
  { value: '7296', display: 'Wynajem odzieży – kostiumy, odzież wizytowa, mundury' },
  { value: '7297', display: 'Salon masażu' },
  { value: '7298', display: 'Salony piękności (day-spa)' },
  {
    value: '7299',
    display: 'Usługi nie sklasyfikowane nigdzie indziej-pielęgnacja, trening, hodowla, kremacja zwierząt, tatuaż',
  },
  { value: '7311', display: 'Usługi reklamowe, agencje' },
  { value: '7321', display: 'Agencje raportowania zdolności kredytowej klientów' },
  { value: '7338', display: 'Usługi ksero, kopiowanie' },
  { value: '7342', display: 'Biura pośrednictwa pracy' },
  { value: '7349', display: 'Usługi porządkowe i sprzątające' },
  { value: '7361', display: 'Biura pośrednictwa pracy' },
  { value: '7372', display: 'Programowanie komputerowe; przetwarzanie danych; projektowanie systemów zintegrowanych' },
  { value: '7379', display: 'Naprawa sprzętu komputerowego' },
  { value: '7392', display: 'Usługi doradcze dla firm' },
  { value: '7393', display: 'Agencje detektywistyczne, agencje ochrony' },
  { value: '7394', display: 'Wypożyczalnie sprzętu, narzędzi, mebli i AGD' },
  { value: '7395', display: 'Wywoływanie i obróbka zdjęć' },
  {
    value: '7399',
    display: 'Usługi dla biznesu tj. organizacja konferencji, usługi ślusarskie, niszczenie dokumentów',
  },
  { value: '7512', display: 'Wypożyczalnie samochodów' },
  { value: '7513', display: 'Wypożyczanie ciężarówek i przyczep użytkowych' },
  { value: '7523', display: 'Parkingi i garaże' },
  { value: '7531', display: 'Sklepy motoryzacyjne – blacharstwo' },
  { value: '7534', display: 'Wulkanizacja' },
  { value: '7535', display: 'Sklepy motoryzacyjne – lakiernictwo' },
  { value: '7538', display: 'Serwis samochodowy' },
  { value: '7542', display: 'Myjnie samochodowe' },
  { value: '7549', display: 'Pomoc drogowa' },
  { value: '7622', display: 'Naprawa RTV' },
  { value: '7623', display: 'Serwis i naprawa instalacji klimatyzacyjnych' },
  { value: '7629', display: 'Naprawa AGD' },
  { value: '7631', display: 'Zegarmistrz, naprawa biżuterii' },
  { value: '7641', display: 'Usługi tapicerskie' },
  { value: '7692', display: 'Usługi spawalnicze' },
  { value: '7699', display: 'Różne usługi naprawcze i usługi pokrewne nie sklasyfikowane w innym MCC' },
  { value: '7832', display: 'Kina' },
  { value: '7841', display: 'Wypożyczalnie kaset video' },
  { value: '7911', display: 'Szkoły tańca' },
  { value: '7922', display: 'Teatry i imprezy rozrywkowe' },
  { value: '7932', display: 'Kluby bilardowe' },
  { value: '7933', display: 'Kręgielnie' },
  { value: '7941', display: 'Kluby sportowe, stadiony' },
  { value: '7991', display: 'Muzea, ogrody botaniczne i inne ekspozycje skierowane do turystów' },
  { value: '7992', display: 'Pola golfowe' },
  { value: '7994', display: 'Gry Video' },
  { value: '7995', display: 'Salony gier, kasyna i loterie' },
  { value: '7996', display: 'Parki rozrywki, wesołe miasteczka' },
  { value: '7997', display: 'Kluby sportowe, siłownie' },
  { value: '7998', display: 'Zoo, oceanarium, zwierzyńce' },
  {
    value: '7999',
    display: 'Usł. Rekr.:tory gokartowe,ścianki wspinaczkowe,wyciągi narciarskie,lodowiska,ośrodki jazdy konnej',
  },
  { value: '8011', display: 'Lekarze interniści i specjaliści' },
  { value: '8021', display: 'Lekarz - dentysta i ortodonta' },
  { value: '8031', display: 'Specjalista ortopeda' },
  { value: '8041', display: 'Kręgarz' },
  { value: '8042', display: 'Lekarze okuliści' },
  { value: '8043', display: 'Usługi okulistyczne, zakłady optyczne' },
  { value: '8050', display: 'Opieka pielęgniarska' },
  { value: '8062', display: 'Szpitale' },
  { value: '8071', display: 'Laboratoria medyczne i stomatologiczne' },
  { value: '8099', display: 'Usługi medyczne, lecznice, prywatne kliniki nie sklasyfikowane w innym MCC' },
  { value: '8111', display: 'Usługi prawne, adwokaci' },
  { value: '8211', display: 'Szkoły podstawowe i średnie' },
  { value: '8220', display: 'Koledże, uniwersytety, szkoły specjalistyczne' },
  {
    value: '8299',
    display: 'Poz szkoły i usł eduk.:  wokalna, aktorska, sztuk walki, ośrodek szkol. kierowców, szkolenia. Komp.',
  },
  { value: '8351', display: 'Przedszkola i opieka nad dziećmi' },
  { value: '8398', display: 'organizacje charytatywne' },
  { value: '8699', display: 'Organizacje i stowarzyszenia' },
  { value: '8911', display: 'Usługi architektoniczne, inżynieryjne i inspekcyjne' },
  { value: '8931', display: 'Usługi rachunkowe, audytoryjne I księgowości' },
  {
    value: '8999',
    display: 'Usł.Spec.nie ujęte w innymMCC:Pośrednicy finan,badanie rynku, rzeczoznawcy majątkowi, domy aukcyjne',
  },
  { value: '9211', display: 'Opłaty sądowe' },
  { value: '9311', display: 'Urzędy skarbowe' },
  { value: '9399', display: 'Urzędy Państwowe' },
  { value: '9406', display: 'Loteria Państwowa (Lotto, Totalizator Sportowy)' },
];
