import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { colors, font } from 'theme/styles';
import Progress from 'components/UI/Progress';

// Trwa
// Zaliczone
// Niezaliczone

const PartnerChallengeDetail = ({ challenge }) => {
  const dateFrom = moment(challenge.dateFrom);
  const dateTo = moment(challenge.dateTo);
  const today = moment().startOf('day');

  const getStatus = () => {
    const isFulfilled = challenge.teamProgressValue >= challenge.goal;
    let status = <span className="semibold">Niezaliczone</span>;
    if (isFulfilled) status = <span className="green semibold">Zaliczone</span>;
    if (challenge.isActive && !isFulfilled)
      status = <span className="semibold orange">W trakcie</span>;
    return status;
  };

  return (
    <Wrapper>
      {challenge.isActive ? (
        <span className="active">Wyzwanie aktywne</span>
      ) : (
        <span className="inactive">Wyzwanie nieaktywne</span>
      )}
      {challenge.special && <span className="special">Wyzwanie specjalne</span>}
      <h3>Wyzwanie: {challenge.name}</h3>
      <p>
        <span className="semibold">Opis:</span>
        {challenge.description}
      </p>
      <p>
        <span className="semibold">Cel:</span>
        {challenge.type === 'lead'
          ? `Zgłoszenie ${challenge.goal} nowych leadów`
          : `Instalacja ${challenge.goal} terminali`}
      </p>
      <p>
        <span className="semibold">Data rozpoczęcia:</span>
        {dateFrom.format('DD-MM-YYYY')}
      </p>
      <p>
        <span className="semibold">Data zakończenia:</span> {dateTo.format('DD-MM-YYYY')}
      </p>
      {challenge.isActive && (
        <p>
          <span className="semibold">Pozostało dni:</span>{' '}
          <span className="orange">{dateTo.diff(today, 'days')}</span>
        </p>
      )}
      <p>
        <span className="semibold">Postęp:</span>
        {challenge.teamProgressValue} / {challenge.goal}
      </p>
      <p>
        <span className="semibold">Status:</span>
        {getStatus()}
      </p>
      {challenge.teamProgress.length > 0 && (
        <ProgressWrapper>
          <p>
            <span className="semibold">Postęp członków zespołu:</span>
            <br />
          </p>
          {challenge.teamProgress.map(person => (
            <Progress
              key={person.name}
              value={person.value}
              maxValue={challenge.goal}
              label={person.name}
            />
          ))}
        </ProgressWrapper>
      )}
    </Wrapper>
  );
};

PartnerChallengeDetail.propTypes = {
  challenge: PropTypes.instanceOf(Object).isRequired,
};

const Wrapper = styled.section`
  max-height: 75vh;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .orange {
    color: ${colors.orange};
  }

  .green {
    color: ${colors.greenDark};
  }

  .semibold {
    font-weight: ${font.weight.semibold};
  }

  .active,
  .inactive,
  .special {
    font-size: ${font.size.s};
    font-weight: ${font.weight.semibold};
    display: inline-block;
    padding: 4px 8px;
    letter-spacing: 1px;
    margin-right: 15px;
    border-radius: 4px;
  }

  .active {
    background-color: ${colors.greenDark};
    color: ${colors.white};
  }

  .inactive {
    background-color: ${colors.lightGray};
    color: ${colors.darkGray};
  }

  .special {
    background-color: ${colors.orange};
    color: ${colors.white};
  }

  p,
  h3 {
    margin: 0;
  }

  p {
    color: ${colors.darkGray};
    span {
      color: ${colors.black};
      padding-right: 5px;
    }
  }

  h3 {
    font-size: ${font.size.xl};
    color: ${colors.orange};
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
`;

const ProgressWrapper = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${colors.lightGray};

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export default PartnerChallengeDetail;
