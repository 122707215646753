/* eslint-disable camelcase */
import makeRequest from 'services/makeRequest';

const CLIENT_BASE_URL = '/client';

const GET_HISTORY_ENDPOINT = 'history';
const GET_NOTES_ENDPOINT = 'notes';
const GET_DOCS_ENDPOINT = 'docs';
const ADD_NOTE_ENDPOINT = 'note';
const FORGET_ENDPOINT = 'forget';


const forgetClient = clientId => {
  return makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${FORGET_ENDPOINT}/${clientId}`,
  });
};

const getClientInfo = clientId => {
  return makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${clientId}`,
  });
};

const getClientHistory = clientId => {
  return makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${clientId}/${GET_HISTORY_ENDPOINT}`,
  });
};

const getClientNotes = clientId => {
  return makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${clientId}/${GET_NOTES_ENDPOINT}`,
  });
};

const addClientNote = (clientId, text) => {
  return makeRequest({
    method: 'post',
    url: `${CLIENT_BASE_URL}/${clientId}/${ADD_NOTE_ENDPOINT}`,
    data: {
      text,
    },
  });
};

const getClientDocs = clientId => {
  return makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${clientId}/${GET_DOCS_ENDPOINT}`,
  });
};

export const clientService = {
  getClientInfo,
  getClientHistory,
  getClientNotes,
  getClientDocs,
  addClientNote,
  forgetClient
};
