import React from 'react';
import styled from 'styled-components';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';
import TransferLeadsForm from 'components/forms/Admin/TransferLeads';

const TransferLeadsPage = () => {
  return (
    <PageTemplate>
      <Wrapper>
        <Heading size="xl" style={{ paddingBottom: '30px' }}>
          Transferuj leady do innego użytkownika
        </Heading>
        <TransferLeadsForm />
      </Wrapper>
    </PageTemplate>
  );
};

export default TransferLeadsPage;

const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
`;
