/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, colors } from 'theme/styles';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileDropZone = ({ onDrop, accept, label, disabled, multiple, maxSize, hide, handleBlur }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept, multiple, disabled, maxSize, onDrop });

  return (
    <Wrapper disabled={disabled}>
      {!hide && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} onBlur={handleBlur} />
          <FontAwesomeIcon fixedWidth size="lg" icon={isDragActive ? 'plus' : 'file-upload'} />
          <span>{label}</span>
        </div>
      )}
    </Wrapper>
  );
};

export default FileDropZone;

FileDropZone.defaultProps = {
  maxSize: 10000000, // 10MB
  accept: 'image/*',
  disabled: false,
  label: 'Dodaj plik',
  acceptAll: false,
  multiple: false,
  hide: false,
};

FileDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  label: PropTypes.string,
  acceptAll: PropTypes.bool,
  hide: PropTypes.bool,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
};

const Wrapper = styled.div`
  display: flex;

  .dropzone {
    padding: 1.4rem 3rem 1.4rem 2rem;
    border-radius: 3px;
    font-size: ${font.size.s};
    font-weight: ${font.weight.default};
    color: ${colors.black};
    border: 1px dashed ${colors.orange};
    cursor: pointer;

    &:hover {
      color: ${colors.orange};
    }
  }
  svg {
    margin-right: 1.4rem;
    color: ${colors.orange};
  }

  &[disabled] {
    .dropzone {
      cursor: initial;
      color: ${colors.darkGray};
      border-color: ${colors.darkGray};

      &:hover {
        color: ${colors.darkGray};
      }
    }

    svg {
      color: ${colors.darkGray};
    }
  }
`;
