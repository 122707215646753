import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { archiveConversation, setPanelActive, unarchiveConversation } from 'redux/chat';
import { colors, font } from 'theme/styles';
import { chatService } from 'services/chat';
import { chatModes } from 'config/constants';
import moment from 'moment';
import Dropdown from '../Dropdown';

const Conversation = ({ id, name, users, lastMessage, lead, isActive, lastMessageAt, onClick, unread }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const leadName = lead ? `\nNazwa firmy: ${lead.companyName || 'Brak danych'}\nNIP: ${lead.nip || 'Brak danych'}` : '';
  const convatedUsers = `W rozmowie uczestniczą: ${users.map(u => u.fullName).join(', ')}`;

  const lastMessageUser = users.find(u => u.id === lastMessage?.userId);

  const loggerdUserId = useSelector(state => state.user.data.id);
  const timeDiff = useSelector(state => state.chat.timeDiff);
  const chatMode = useSelector(state => state.chat.chatMode);
  const activeConversationId = useSelector(s => s.chat.activeConversation.conversation.id);

  // Odejmuję sekundkę dla pewności żeby nie podawało "za kilka sekund" lub podobnych
  const fixedLocal = moment().diff(timeDiff - 1000);

  const isItMe = () => (lastMessageUser ? loggerdUserId === lastMessageUser.id : false);

  const renderWho = () => {
    if (isItMe()) return <span>Ja:</span>;
    return <span>{lastMessageUser ? lastMessageUser.name : 'Ostatnia wiadomość'}:</span>;
  };

  const clearActiveConversation = () => {
    history.replace();
    dispatch(setPanelActive(false));
  };

  const archiveHandler = e => {
    e.stopPropagation();
    if (activeConversationId === id) clearActiveConversation();
    chatService.archiveWsConversation(id).then(() => dispatch(archiveConversation(id)));
  };

  const unArchiveHandler = e => {
    e.stopPropagation();
    if (activeConversationId === id) clearActiveConversation();
    chatService.unarchiveWsConversation(id).then(() => dispatch(unarchiveConversation(id)));
  };

  return (
    <Wrapper
      onClick={e => !e.target.closest('svg') && onClick()}
      title={`${convatedUsers}${leadName}`}
      isActive={isActive}
      unread={unread}
    >
      <Row>
        <h3>{name}</h3>
        <Dropdown width={140}>
          {chatMode === chatModes.archive && <Dropdown.Item onClick={unArchiveHandler}>Przywróć</Dropdown.Item>}
          {chatMode === chatModes.normal && <Dropdown.Item onClick={archiveHandler}>Archiwizuj</Dropdown.Item>}
        </Dropdown>
      </Row>
      <Row>
        <LastMessage>
          {lastMessage ? renderWho() : 'Brak dostępu do ostatniej wiadomości.'}
          {lastMessage?.message}
        </LastMessage>
        <Date>{lastMessageAt ? moment(lastMessageAt).from(fixedLocal, true) : ''}</Date>
      </Row>
    </Wrapper>
  );
};

Conversation.defaultProps = {
  lead: false,
  lastMessageAt: false,
  lastMessage: false,
};

Conversation.propTypes = {
  id: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  lastMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  lastMessageAt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  users: PropTypes.instanceOf(Array).isRequired,
  lead: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const LastMessage = styled.span`
  font-size: ${font.s};
  color: ${colors.darkGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  span {
    display: inline-block;
    margin-right: 0.5em;
    font-weight: 600;
  }
`;

const Date = styled.span`
  /* position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%); */
  white-space: nowrap;
`;

const Wrapper = styled.li`
  font-size: ${font.size.s};
  position: relative;
  /* padding: 0.6rem 5rem 0.6rem 1rem; */
  /* padding: 0.6rem 8rem 0.6rem 1rem; */
  padding: 0.6rem 1rem 0.6rem 1rem;
  cursor: pointer;
  transition: color 0.3s;
  border-radius: 4px;

  h3 {
    font-weight: ${font.weight.default};
  }

  &:hover,
  &:active {
    background-color: ${colors.blueGrayLight};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.orange};
      background-color: ${colors.blueGray};
    `}

  ${({ unread }) =>
    unread &&
    css`
      border-left: 2px solid ${colors.orange};
      ${LastMessage},
      ${Date} {
        font-weight: ${font.weight.semibold};
        color: ${colors.textBlack};
      }

      h3 {
        font-weight: ${font.weight.semibold};
      }
    `}

  & + & {
    border-top: 1px solid ${colors.blueLighter};
  }
`;

const Row = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
`;

export default Conversation;
