import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { lead24FormSchema } from 'utils/validation';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import { p24LeadService } from 'services/leadP24';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import Input from 'components/UI/Input';
import Radio from 'components/UI/Radio';
import { p24OffersOptions } from 'config/constants';

const P24LeadForm = ({ toggleClose, initialValues, editMode, refetch }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleClose = (resetForm) => {
    setSuccess(false);
    setError('');
    resetForm({});
    toggleClose();
  };

  const handleSubmit = (values) => {
    setError('');
    setLoading(true);

    const thenFn = (data) => {
      console.log(data);
      setSuccess(true);
      setLoading(false);
      refetch();
    };

    const catchFn = (err) => {
      console.log(err);
      setError(err.response.data.error.message);
      setLoading(false);
    };

    if (initialValues.id) {
      console.log('Edycja do implementacji');
    } else {
      p24LeadService.addLeadP24(values).then(thenFn).catch(catchFn);
    }
  };

  const { name, surname, nip, email, phone, offer = '', www = ''} = initialValues;

  return (
    <FormWrapperCommon>
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Dodaj leada do przelewy24</Heading>
      <Formik
        initialValues={{
          name,
          surname,
          nip,
          email,
          phone,
          offer,
          www,
        }}
        enableReinitialize
        validationSchema={lead24FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm, setFieldValue }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>{editMode ? 'Lead został zaktualizowany' : 'Dodano leada do przelewy24'}</Heading>
                {/* {success} */}
                <div className="buttons">
                  {!editMode && <Button onClick={() => handleReturn(true, resetForm)}>Dodaj kolejny</Button>}
                  <Button gray={!editMode} onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>{editMode ? 'Błąd podczas aktualizacji' : 'Błąd podczas dodawania leada'}</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                {editMode ? ' Zaktualizuj' : ' Wypełnij dane'}
              </FieldHeading>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Imię"
                value={values.name || ''}
                error={touched.name && errors.name}
              />
              <ErrorMessage component={ErrorLabel} name="name" />
              <Input
                name="surname"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nazwisko"
                value={values.surname || ''}
                error={touched.surname && errors.surname}
              />
              <ErrorMessage component={ErrorLabel} name="surname" />
              <Input
                name="nip"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Numer NIP"
                value={values.nip || ''}
                error={touched.nip && errors.nip}
              />
              <ErrorMessage component={ErrorLabel} name="nip" />
              <Input
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Adres email"
                value={values.email || ''}
                error={touched.email && errors.email}
              />
              <ErrorMessage component={ErrorLabel} name="email" />
              <Input
                name="phone"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Numer Telefonu"
                value={values.phone || ''}
                error={touched.phone && errors.phone}
              />
              <ErrorMessage component={ErrorLabel} name="phone" />
              <Input
                name="www"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Adres www"
                value={values.www || ''}
                error={touched.www && errors.www}
              />
              <ErrorMessage component={ErrorLabel} name="www" />
              <FieldHeading style={{ marginTop: '2rem' }} medium size="m">
                Wybierz ofertę
              </FieldHeading>
              {p24OffersOptions.map((option) => (
                <Radio
                  key={option.value}
                  name="offer"
                  value={option.value}
                  onChange={() => setFieldValue('offer', option.value)}
                  label={`Oferta: ${option.display}`}
                  checked={values.offer === option.value}
                />
              ))}
              <ErrorMessage component={ErrorLabel} name="offer" />
              <Button style={{ marginTop: '50px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </FormWrapperCommon>
  );
};

P24LeadForm.defaultProps = {
  initialValues: {
    id: '',
    name: '',
    surname: '',
    nip: '',
    email: '',
    phone: '',
    offer: '',
    www: ''
  },
  editMode: false,
};

P24LeadForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    nip: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    offer: PropTypes.string,
    www: PropTypes.string,
  }),
  editMode: PropTypes.bool,
  toggleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

// const mapStateToProps = state => ({});
// const mapDispatchToProps = {};
// export default connect(mapStateToProps, mapDispatchToProps)(P24LeadForm);

export default P24LeadForm;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
