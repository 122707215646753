/* eslint-disable camelcase */
import axios from 'axios';
import makeRequest from 'services/makeRequest';

const { CancelToken } = axios;
let cancelPartnerExportToken;
let cancelexportMerchantAnalyticsPhsToken;

const ALL_STRUCTURES_URL = '/structure/all';
const ALL_ROLES_URL = '/role/all';
const ALL_COUNTIES_URL = '/region/county';
const ALL_PEP_STRUCTURE = '/user/all-pep-structure';

const GET_ALL_USERS_URL = '/admin/user/list';
const GET_USER_URL = '/admin/user/get';
const ADD_USER_URL = '/admin/user/create';
const EDIT_USER_URL = '/admin/user/edit';
const DELETE_USER_URL = '/admin/user/delete';
const RESTORE_USER_URL = '/admin/user/restore';

const GET_ALL_COMPANIES_URL = '/companies';
const GET_COMPANY_URL = '/companies';
const ADD_COMPANY_URL = '/companies';
const EDIT_COMPANY_URL = '/companies/edit';
const EDIT_COMPANY_FEES_URL = '/companies/fees';
const REMOVE_COMPANY_URL = '/companies/remove';
const RESTORE_COMPANY_URL = '/companies/restore';
const EXPORT_COMPANY_URL = '/companies/export';
const GET_COMPANY_ANALYSIS_URL = '/companies/analysis';

const GET_ALL_API_KEYS_URL = '/api-manager';
const GET_API_KEY_URL = '/api-manager';
const ADD_API_KEY_URL = '/api-manager';
const UPDATE_API_KEY_URL = '/api-manager';
const DELETE_API_KEY_URL = '/api-manager/delete';

const GET_COMPANY_USERS_URL = '/user/company';

const ASSIGN_LEADS_MANUAL_URL = '/admin/leads/go';

const VACATION_BASE_URL = '/vacations';

const NOTIFICATIONS_BASE_URL = '/admin/notifications';
const EMAIL_TEMPLATES_BASE_URL = '/email';

const COUNTY_ANALYSIS_BASE_URL = '/dashboard/analysis/counties';
const MERCHANTS_LEADS_ANALYSIS_BASE_URL = '/user/analysis-lead';
const MERCHANT_LEADS_ANALYSIS_BASE_URL = '/user/analysis-lead-details';
const MERCHANTS_LEADS_ANALYSIS_PH_BASE_URL = '/user/analysis-ph';

const LOGIN_AS_BASE_URL = '/admin/log-as-user';
const CRONS_BASE_URL = '/cron';

const CRM_CHECK_URL = '/admin/crm-accounts';
const GRAYLOG_CHECK_URL = '/graylog';

// TODO - temp
const SP_OPTIONS_URL = '/app-settings';
const SP_UPDATE_OPTIONS_URL = '/app-settings/update';

const CRM_LEAD_STATUS_UPDATE_URL = '/admin/crm-update/';

const crmLeadStatusUpdateById = (leadId) =>
  makeRequest({
    method: 'get',
    url: `${CRM_LEAD_STATUS_UPDATE_URL}/${leadId}`,
  });

const restoreUser = (userId) =>
  makeRequest({
    method: 'get',
    url: `${RESTORE_USER_URL}/${userId}`,
  });

const deleteUser = (userId) =>
  makeRequest({
    method: 'get',
    url: `${DELETE_USER_URL}/${userId}`,
  });

// Graylog
const getGraylog = (logId) =>
  makeRequest({
    method: 'get',
    url: `${GRAYLOG_CHECK_URL}/${logId}`,
  });

// PepOptions
const getSpOptions = () =>
  makeRequest({
    method: 'get',
    url: SP_OPTIONS_URL,
  });

const setSpOptions = (data) =>
  makeRequest({
    method: 'post',
    url: SP_UPDATE_OPTIONS_URL,
    data,
  });

// CRM Check
const crmCheck = (nip) =>
  makeRequest({
    method: 'get',
    url: `${CRM_CHECK_URL}/${nip}`,
  });

// Merchants analytics
const getMerchantAnalyticsPhs = (params) =>
  makeRequest({
    method: 'get',
    url: MERCHANTS_LEADS_ANALYSIS_PH_BASE_URL,
    params,
  });

const exportMerchantAnalyticsPhs = (data) =>
  makeRequest({
    method: 'post',
    url: `${MERCHANTS_LEADS_ANALYSIS_PH_BASE_URL}/export`,
    data: {
      rows: JSON.stringify(data),
    },
    timeout: 0,
    responseType: 'blob',
    cancelToken: new CancelToken(function executor(c) {
      cancelexportMerchantAnalyticsPhsToken = c;
    }),
  });

const getMerchantsAnalytics = (params) =>
  makeRequest({
    method: 'get',
    url: MERCHANTS_LEADS_ANALYSIS_BASE_URL,
    params,
  });
const getMerchantAnalytics = (phId, params) =>
  makeRequest({
    method: 'get',
    url: `${MERCHANT_LEADS_ANALYSIS_BASE_URL}/${phId}`,
    params,
  });

// Crons
const editCronStatus = (id, status) =>
  makeRequest({
    method: 'post',
    url: `${CRONS_BASE_URL}/${id}/status`,
    data: {
      status,
    },
  });

const editCronSettings = (id, settings) =>
  makeRequest({
    method: 'post',
    url: `${CRONS_BASE_URL}/${id}/edit`,
    data: {
      options: JSON.stringify(settings),
    },
  });

const executeCron = (id) =>
  makeRequest({
    method: 'post',
    url: `${CRONS_BASE_URL}/${id}/exec`,
  });

const getCronLogs = (id, params) =>
  makeRequest({
    method: 'get',
    url: `${CRONS_BASE_URL}/${id}/logs`,
    params,
  });

const getCron = (id) =>
  makeRequest({
    method: 'get',
    url: `${CRONS_BASE_URL}/${id}`,
  });

const getCronsList = () =>
  makeRequest({
    method: 'get',
    url: CRONS_BASE_URL,
  });

const loginAs = (data) =>
  makeRequest({
    method: 'post',
    url: LOGIN_AS_BASE_URL,
    data,
  });

const getCountyAnalytics = (params) =>
  makeRequest({
    method: 'get',
    url: COUNTY_ANALYSIS_BASE_URL,
    params,
  });

// Email templates
const testEmailTemplate = (data) =>
  makeRequest({
    method: 'post',
    url: `${EMAIL_TEMPLATES_BASE_URL}/${data.id}/preview`,
    data,
  });

const saveEmailTemplate = (data) =>
  makeRequest({
    method: 'post',
    url: `${EMAIL_TEMPLATES_BASE_URL}/${data.id}/update`,
    data,
  });

const getEmailTemplates = (params) =>
  makeRequest({
    method: 'get',
    url: EMAIL_TEMPLATES_BASE_URL,
    params,
  });

// Notifications
const getNotifications = (params) =>
  makeRequest({
    method: 'get',
    url: NOTIFICATIONS_BASE_URL,
    params,
    timeout: 0,
  });

// Vacations
const getVacations = (params) =>
  makeRequest({
    method: 'get',
    url: VACATION_BASE_URL,
    params,
  });

const getVacation = (id) =>
  makeRequest({
    method: 'get',
    url: `${VACATION_BASE_URL}/${id}`,
  });

const addVacation = (data) =>
  makeRequest({
    method: 'post',
    url: VACATION_BASE_URL,
    data,
  });

const updateVacation = (id, data) =>
  makeRequest({
    method: 'put',
    url: `${VACATION_BASE_URL}/${id}`,
    data,
  });

const deleteVacation = (id) =>
  makeRequest({
    method: 'delete',
    url: `${VACATION_BASE_URL}/${id}`,
  });

// Export companies/partners
const exportPartnersExcel = (
  users = false,
  search = null,
  showDeleted = null,
  leadmaster = null,
  sortTrend = null,
  sort = null,
) =>
  makeRequest({
    method: 'GET',
    url: EXPORT_COMPANY_URL,
    params: {
      users,
      search,
      leadmaster,
      sortTrend,
      showDeleted,
      sort,
    },
    timeout: 0,
    responseType: 'blob',
    cancelToken: new CancelToken(function executor(c) {
      cancelPartnerExportToken = c;
    }),
  });

// ApiKeys
const getAllApiKeys = (perPage, page, queryData) =>
  makeRequest({
    method: 'get',
    url: `${GET_ALL_API_KEYS_URL}/${page}/${perPage}`,
    params: queryData,
  });

const getApiKey = (id) =>
  makeRequest({
    method: 'get',
    url: `${GET_API_KEY_URL}/${id}`,
  });

const addApiKey = (data) =>
  makeRequest({
    method: 'post',
    url: ADD_API_KEY_URL,
    data,
  });

const updateApiKey = (id, data) =>
  makeRequest({
    method: 'post',
    url: `${UPDATE_API_KEY_URL}/${id}`,
    data,
  });

const deleteApiKey = (id) =>
  makeRequest({
    method: 'get',
    url: `${DELETE_API_KEY_URL}/${id}`,
  });

// Leads
const assignLeadsManual = () =>
  makeRequest({
    method: 'get',
    url: ASSIGN_LEADS_MANUAL_URL,
  });

// Companies
const getCompanyAnalysis = (id, query) =>
  makeRequest({
    method: 'get',
    url: `${GET_COMPANY_ANALYSIS_URL}/${id}`,
    params: query,
  });

const getAllCompanies = (queryData) =>
  makeRequest({
    method: 'get',
    url: GET_ALL_COMPANIES_URL,
    params: queryData,
  });

const getCompany = (id) =>
  makeRequest({
    method: 'get',
    url: `${GET_COMPANY_URL}/${id}`,
  });

const getCompanyUsers = (id, data) =>
  makeRequest({
    method: 'get',
    url: `${GET_COMPANY_USERS_URL}/${id}`,
    params: data,
  });

const addCompany = (data) =>
  makeRequest({
    method: 'post',
    url: `${ADD_COMPANY_URL}`,
    data,
  });

const editCompany = (id, data) =>
  makeRequest({
    method: 'post',
    url: `${EDIT_COMPANY_URL}/${id}`,
    data,
  });

const editCompanyFees = (id, productsFees) =>
  makeRequest({
    method: 'post',
    url: `${EDIT_COMPANY_FEES_URL}/${id}`,
    data: {
      productsFees,
    },
  });

const removeCompany = (id) =>
  makeRequest({
    method: 'get',
    url: `${REMOVE_COMPANY_URL}/${id}`,
  });

const restoreCompany = (id, withUsers = null) =>
  makeRequest({
    method: 'get',
    url: `${RESTORE_COMPANY_URL}/${id}`,
    params: {
      withUsers,
    },
  });

// Utils
const getAllStructures = () =>
  makeRequest({
    method: 'get',
    url: ALL_STRUCTURES_URL,
  });

const getAllPepStructures = () =>
  makeRequest({
    method: 'get',
    url: ALL_PEP_STRUCTURE,
  });

const getAllRoles = () =>
  makeRequest({
    method: 'get',
    url: ALL_ROLES_URL,
  });

const getAllCounties = () =>
  makeRequest({
    method: 'get',
    url: ALL_COUNTIES_URL,
  });

// Users
const getAllUsers = (perPage, page, queryData) =>
  makeRequest({
    method: 'get',
    url: `${GET_ALL_USERS_URL}/${perPage}/${page}`,
    params: queryData,
  });

const getUser = (id) =>
  makeRequest({
    method: 'get',
    url: `${GET_USER_URL}/${id}`,
  });

const addUser = (data) =>
  makeRequest({
    method: 'post',
    url: ADD_USER_URL,
    data,
  });

const editUser = (id, data) =>
  makeRequest({
    method: 'post',
    url: `${EDIT_USER_URL}/${id}`,
    data,
  });

export const adminService = {
  getAllRoles,
  getAllCounties,
  getAllStructures,
  getAllPepStructures,
  getAllUsers,
  getUser,
  addUser,
  editUser,
  getAllCompanies,
  getCompany,
  getCompanyUsers,
  addCompany,
  editCompany,
  editCompanyFees,
  removeCompany,
  restoreCompany,
  assignLeadsManual,
  getAllApiKeys,
  getApiKey,
  addApiKey,
  updateApiKey,
  deleteApiKey,
  getVacations,
  getVacation,
  addVacation,
  updateVacation,
  deleteVacation,
  exportPartnersExcel,
  getNotifications,
  getEmailTemplates,
  saveEmailTemplate,
  testEmailTemplate,
  getCountyAnalytics,
  getCompanyAnalysis,
  cancelPartnerExport: () => cancelPartnerExportToken(),
  loginAs,
  editCronStatus,
  getCron,
  getCronsList,
  executeCron,
  getCronLogs,
  getMerchantsAnalytics,
  getMerchantAnalytics,
  crmCheck,
  editCronSettings,
  getSpOptions,
  setSpOptions,
  getGraylog,
  deleteUser,
  restoreUser,
  getMerchantAnalyticsPhs,
  exportMerchantAnalyticsPhs,
  cancelExportMerchantAnalyticsPhs: () => cancelexportMerchantAnalyticsPhsToken(),
  crmLeadStatusUpdateById,
};
