import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints, colors, font } from 'theme/styles';
import { integratinOrdersAttachmentsMimeTypes, integrationOrdersStatuses, mapTypeToIcon } from 'config/constants';
import { integrationOrdersService } from 'services/integrationOrders';
import {
  clearCurrentIntegrationOrder,
  clearCurrentIntegrationOrderError,
  getIntegrationOrderDetails,
  setCurrentIntegrationOrderError,
  setCurrentIntegrationOrderLoading,
} from 'redux/integrationOrders';
import { Block, TwoColumns } from 'components/UI/Structure';
import { displayTime, displayTimeUtc, download, humanFileSize } from 'utils/helpers';
import { routes } from 'routes';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import SimpleButton from 'components/UI/SimpleButton';
import SuccessPopup from 'components/layout/SuccessPopup';
import Content from 'components/UI/Content';
import AddAttachments from 'components/forms/AddAttachments';

const getTitle = type => {
  switch (type) {
    case 'Integracja':
      return 'Zlecenie integracji';
    case 'Instalacja':
    case 'Instalacje':
      return 'Zlecenie instalacji';
    default:
      return 'Zlecenie';
  }
};

const IntegrationOrderDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [askForAction, setAskForAction] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const user = useSelector(s => s.user.data);
  const userRole = user?.role?.slug;
  const order = useSelector(s => s.orders.currentIntegrationOrder.data);
  const isLoading = useSelector(s => s.orders.currentIntegrationOrder.isLoading);
  const error = useSelector(s => s.orders.currentIntegrationOrder.error);

  const statusColor = (order && integrationOrdersStatuses.find(s => s.id === order.status_id)?.color) || colors.orange;

  useEffect(() => {
    if (!user) return;
    if (['admin', 'dsp', 'dr', 'skf'].includes(userRole)) return;
    if (!user.canInstallTerminal) history.replace(routes.leads);
  });

  useEffect(() => {
    dispatch(getIntegrationOrderDetails(id));
    return () => dispatch(clearCurrentIntegrationOrder());
  }, [dispatch, id]);

  useEffect(() => {
    if (error && [401, 404].includes(error.status)) history.replace(routes.integrationOrders);
  }, [error, history]);

  const handleAction = () => {
    let statusId = false;

    if (askForAction === 'accept') statusId = 3;
    else if (askForAction === 'reject') statusId = 6;

    if (!statusId) return;

    integrationOrdersService
      .updateIntegrationOrderStatus(id, statusId)
      .then(res => {
        console.log(res);
        dispatch(getIntegrationOrderDetails(id));
      })
      .catch(err => {
        console.log(err);
        dispatch(setCurrentIntegrationOrderError(err.message || 'Coś poszło nie tak'));
        setAskForAction(false);
      })
      .finally(() => setAskForAction(false));
  };

  const handleUploadAttachments = files => {
    dispatch(setCurrentIntegrationOrderLoading(true));
    integrationOrdersService
      .addIntegrationOrderAttachment(order.zlecenie_id, files)
      .then(response => {
        console.log(response);
        dispatch(getIntegrationOrderDetails(id));
      })
      .catch(err => {
        console.log(err);
        dispatch(setCurrentIntegrationOrderError(err.message || 'Coś poszło nie tak'));
      })
      .finally(() => dispatch(setCurrentIntegrationOrderLoading(false)));
  };

  const handleDownloadAttachments = (id, name) => {
    integrationOrdersService
      .getIntegrationOrderAttachment(id)
      .then(res => download(res, name))
      .catch(err => console.log(err));
  };

  const handleDeleteAttachment = () => {
    dispatch(setCurrentIntegrationOrderLoading(true));
    integrationOrdersService
      .deleteIntegrationOrderAttachment(deleteId)
      .then(response => {
        console.log(response);
        dispatch(getIntegrationOrderDetails(id));
      })
      .catch(err => {
        dispatch(setCurrentIntegrationOrderError(err.message || 'Coś poszło nie tak'));
      })
      .finally(() => {
        dispatch(setCurrentIntegrationOrderLoading(false));
        setDeleteId();
      });
  };

  const handleCanSeeAction = item => {
    if (!['partner', 'subPartner'].includes(userRole)) return false;
    return item.status_id === 2 ? true : false || false;
  };

  const canAddAttachments = order => {
    if (order.typ_zlecenia === 'Integracja') return false;
    if (['skf'].includes(userRole)) return false;
    // 2 - Podjęte, 3 - Zrealizowane
    // return [2, 3].includes(order.status_id) ? true : false || false;
    return true;
  };

  return (
    <PageTemplate>
      {askForAction && (
        <SuccessPopup fixed style={{ textAlign: 'center' }}>
          <Heading centered style={{ marginBottom: '20px' }}>
            Czy jesteś pewien, że chcesz {askForAction === 'accept' ? 'zaakceptować' : 'odrzucić'} zgłoszenie
          </Heading>
          <div className="buttons">
            <Button secondary onClick={handleAction}>
              Potwierdzam
            </Button>
            <Button onClick={() => setAskForAction(false)}>Anuluj</Button>
          </div>
        </SuccessPopup>
      )}
      {deleteId && (
        <SuccessPopup fixed style={{ textAlign: 'center' }}>
          <Heading centered style={{ marginBottom: '20px' }}>
            Czy jesteś pewien, że chcesz usunąc załącznik?
          </Heading>
          <div className="buttons">
            <Button secondary onClick={handleDeleteAttachment}>
              Potwierdzam
            </Button>
            <Button onClick={() => setDeleteId('')}>Anuluj</Button>
          </div>
        </SuccessPopup>
      )}
      {isLoading && <Loading />}
      {error && !isLoading && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error?.message || error || 'Coś poszło nie tak'}</p>
          <div className="buttons">
            <Button
              onClick={() => {
                dispatch(clearCurrentIntegrationOrderError());
              }}
            >
              Zamknij
            </Button>
          </div>
        </ErrorPopup>
      )}
      {order && !isLoading && (
        <Wrapper>
          <Header>
            <Heading size="xl">
              {getTitle(order.typ_zlecenia)} nr {order.numer_zlecenia}
            </Heading>
            {handleCanSeeAction(order) && (
              <ButtonsWrapper>
                <Button small greenFilled type="button" onClick={() => setAskForAction('accept')}>
                  Akceptuj
                </Button>
                <Button small type="button" onClick={() => setAskForAction('reject')}>
                  Odrzuć
                </Button>
              </ButtonsWrapper>
            )}
          </Header>
          <TwoColumns style={{ marginBottom: '40px' }}>
            <StyledCol>
              <Block>
                <Heading centered color={colors.orange} style={{ marginBottom: '2rem' }}>
                  Dane zgłoszenia
                </Heading>

                <Item>
                  <Label>ID zlecenia:</Label>
                  <Text>{order.zlecenie_id}</Text>
                </Item>

                <Item>
                  <Label>Numer zlecenia:</Label>
                  <Text>{order.numer_zlecenia}</Text>
                </Item>

                {order.nr_zlecenia_wymiany && (
                  <Item>
                    <Label>Numer zlecenia wymiany:</Label>
                    <Text>{order.nr_zlecenia_wymiany}</Text>
                  </Item>
                )}

                <Item>
                  <Label>Data zlecenia</Label>
                  <Text>{displayTimeUtc(order.data_zlecenia)}</Text>
                </Item>

                <Item>
                  <Label>Typ zlecenia:</Label>
                  <Text>{order.typ_zlecenia}</Text>
                </Item>

                <Item>
                  <Label>Terminal:</Label>
                  <Text>{order.terminal}</Text>
                  <Text>POS ID/TID: {order.numer_terminala}</Text>
                </Item>

                {order.typ_zlecenia === 'Integracja' && (
                  <Item>
                    <Label>Kasa:</Label>
                    <Text>{order.kasa}</Text>
                    <Text>Model: {order.kasa_model}</Text>
                    <Text>Łączność z terminalem: {order.lacznosc_z_terminalem}</Text>
                    <Text>Łączność z internetem: {order.lacznosc_z_internetem}</Text>
                  </Item>
                )}

                <Item>
                  <Label>Opis:</Label>
                  <Text>{order.opis || 'Brak dodatkowych informacji'}</Text>
                </Item>

                <Item>
                  <Label>Status:</Label>
                  <Text style={{ color: statusColor }}>{order.status_zlecenia}</Text>
                </Item>
              </Block>
            </StyledCol>

            <StyledCol>
              <Block>
                <Heading centered color={colors.orange} style={{ marginBottom: '2rem' }}>
                  Dane Klienta
                </Heading>

                <Item>
                  <Label>NIP:</Label>
                  <Text>{order.nip}</Text>
                </Item>

                <Item>
                  <Label>Osoba kontaktowa:</Label>
                  <Text>{order.osoba_kontaktowa}</Text>
                </Item>

                <Item>
                  <Label>Adres:</Label>
                  <Text>{order.adres}</Text>
                  <Text>{order.kod_pocztowy}</Text>
                </Item>

                {(order.godzina_otwarcia_punktu_od || order.godzina_otwarcia_punktu_do) && (
                  <Item>
                    <Label>Godziny otwarcia punktu:</Label>
                    <Text>
                      {order.godzina_otwarcia_punktu_od && <span>od {order.godzina_otwarcia_punktu_od}</span>}
                      {order.godzina_otwarcia_punktu_do && <span> do {order.godzina_otwarcia_punktu_do}</span>}
                    </Text>
                  </Item>
                )}
              </Block>

              <Block>
                <Heading centered color={colors.orange} style={{ marginBottom: '2rem' }}>
                  Dane Partnera
                </Heading>

                <Item>
                  <Label>NIP Partnera:</Label>
                  <Text>{order.nip_partnera}</Text>
                </Item>

                <Item>
                  <Label>Nazwa Partnera:</Label>
                  <Text>{order.nazwa_partnera}</Text>
                </Item>

                <Item>
                  <Label>Email Partnera:</Label>
                  <Text>{order.email_partnera}</Text>
                </Item>
              </Block>
            </StyledCol>
          </TwoColumns>

          {(canAddAttachments(order) || order.attachments.length > 0) && (
            <Block style={{ marginBottom: '40px' }}>
              <Heading size="m" style={{ marginBottom: '2rem' }}>
                Załączniki
              </Heading>
              {order.attachments.length > 0 && (
                <AttachmentsWrapper>
                  {order.attachments.map(f => (
                    <File key={`${f.attachmentOriginalName}-${f.createdAt}`}>
                      <p>
                        <FontAwesomeIcon
                          size="lg"
                          title={`Typ pliku - ${f.attachmentExtension}`}
                          icon={mapTypeToIcon[f.attachmentExtension] || 'file'}
                        />
                        <span style={{ fontSize: '0.8em' }}>{f.attachmentOriginalName}</span>
                      </p>
                      <div style={{ marginTop: '0.5rem' }}>
                        <span style={{ fontSize: '0.7em' }}>{displayTime(f.createdAt)}</span>
                        <span style={{ fontSize: '0.7em' }} className="color-orange">
                          ({humanFileSize(f.attachmentSize)})
                        </span>
                        <div style={{ marginLeft: 'auto' }}>
                          <SimpleButton green onClick={() => handleDownloadAttachments(f.id, f.attachmentOriginalName)}>
                            Pobierz
                          </SimpleButton>
                          {['admin'].includes(userRole) && (
                            <SimpleButton onClick={() => setDeleteId(f.id)}>Usuń</SimpleButton>
                          )}
                        </div>
                      </div>
                    </File>
                  ))}
                </AttachmentsWrapper>
              )}
              {canAddAttachments(order) && (
                <>
                  <AddAttachments
                    handleSubmit={handleUploadAttachments}
                    accept={integratinOrdersAttachmentsMimeTypes}
                    maxSize={5_000_000}
                    boxLabel="Wybierz lub upuść pliki"
                    label={null}
                  />
                </>
              )}
            </Block>
          )}

          {order.typ_zlecenia === 'Integracja' && (
            <TwoColumns style={{ marginBottom: '40px' }}>
              <StyledCol>
                <Block>
                  <Heading centered color={colors.notiBlue} style={{ marginBottom: '2rem' }}>
                    Potwierdzenie realizacji zlecenia przez IVR
                  </Heading>
                  <Content>
                    <p>
                      W celu przyjęcia lub odrzucenia nadanych zleceń, a także potwierdzenia ich skutecznej realizacji
                      zadzwoń pod numer 17 859 69 59 i wybierz IVR 4.
                    </p>
                    <p>Następnie dokonaj jednego z poniższych wyborów:</p>
                    <ol>
                      <li>Potwierdzenie podjęcia zgłoszenia integracji.</li>
                      <li>Odrzucenie zlecenia integracji</li>
                      <li>Potwierdzenie skutecznej realizacji zlecenia integracji</li>
                      <li>Odrzucenie już podjętego zlecenia integracji.</li>
                    </ol>
                    <p>
                      Po dokonaniu wyboru wprowadź numer TID terminala i zatwierdź klawiszem „#”. Następnie wprowadź 3
                      ostatnie cyfry numeru NIP twojej firmy i ponownie zatwierdź „#”. Jeżeli wszystko wykonałeś
                      poprawnie, usłyszysz komunikat potwierdzający dyspozycję zgodnie z dokonanym wyborem
                    </p>
                  </Content>
                </Block>
              </StyledCol>
            </TwoColumns>
          )}
        </Wrapper>
      )}
    </PageTemplate>
  );
};

IntegrationOrderDetailsPage.defaultProps = {};

export default IntegrationOrderDetailsPage;

const Wrapper = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  margin-bottom: 40px;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  @media (min-width: ${breakpoints.mobileMinimum}) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    flex: 1;
  }
  p {
    margin: 0;
  }
`;

const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const Label = styled.p`
  letter-spacing: 0.5px;
  color: ${colors.mediumGray};
  font-size: ${font.size.s};
`;

const Text = styled.p`
  font-weight: ${font.weight.semibold};
  color: ${colors.darkBlue};
  font-size: ${font.size.m};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 5px;
  }

  @media (min-width: ${breakpoints.mobileMinimum}) {
    justify-content: flex-end;
  }
`;

const AttachmentsWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  svg {
    color: ${colors.orange};
    margin-right: 0.5em;
  }
`;

const File = styled.div`
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 1.6rem;
  border: 1px solid ${colors.blueGray};
  border-radius: 5px;
  flex: 1 1 100%;

  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  @media (min-width: ${breakpoints.mobileSmall}) {
    flex: 1 1 calc((100% - 1rem) / 2);
    max-width: calc((100% - 1rem) / 2);
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex: 1 1 calc((100% - 2rem) / 3);
    max-width: calc((100% - 2rem) / 3);
  }

  @media (min-width: ${breakpoints.notificationsDesktop}) {
    flex: 1 1 calc((100% - 3rem) / 4);
    max-width: calc((100% - 3rem) / 4);
  }
`;
