import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getRecommendedUsers,
  getRecommendedUser,
  setCurrentRecommendedUser,
  clearCurrentRecommendedUser,
} from 'redux/collaboration';
import { getPublicOptions } from 'redux/options';
import { provinces } from 'config/constants';
import { collaborationService } from 'services/collaboration';
import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import PageTemplate from 'templates/PageTemplate';
import CollaborationTable from 'components/layout/Tables/CollaborationTable';
import RecommendationForm from 'components/forms/RecommendationForm';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import Pagination from 'components/UI/Pagination';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import SuccessPopup from 'components/layout/SuccessPopup';
import useExport from 'hooks/useExport';

const headers = [
  { displayName: 'Data zgłoszenia', property: 'createdAt' },
  { displayName: 'LEAD ID', property: 'number' },
  { displayName: 'NIP', property: 'nip' },
  { displayName: 'Nazwa firmy', property: 'companyName' },
  { displayName: 'Partner', property: 'Partner', subProperty: 'name' },
  { displayName: 'Leadmaster', property: 'LeadMaster', subProperty: 'fullName' },
  { displayName: 'Zgłosił', property: 'ReportedUser', subProperty: 'fullName' },
  { displayName: 'Województwo', property: 'voivodeship' },
  { displayName: 'Status PeP', property: 'status' },
];

const CollaborationPage = ({
  recommendedUsers,
  currentRecommendedUser,
  recommendedUsersCount,
  isLoading,
  error,
  totalPages,
  currentPage,
  getRecommendedUsers,
  getRecommendedUser,
  setCurrentRecommendedUser,
  clearCurrentRecommendedUser,
  userRole,
}) => {
  const dispatch = useDispatch();

  const [isAddNewVisible, setIsAddNewVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [deletedError, setDeletedError] = useState('');
  const [deleted, setDeleted] = useState('');
  const [page, setPage] = useState('1');
  const [perPage, setPerPage] = useState('20');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('false');
  const [sort, setSort] = useState('DESC');
  const [vivodeship, setVivodeship] = useState('');

  const [ExportPopup, handleExport] = useExport({
    handleCancel: collaborationService.cancelRecommendedUsersExport,
    handleExport: collaborationService.exportRecommendedUsers,
  });

  const handleCanEdit = (recomendation) => {
    if (['admin', 'dsp'].includes(userRole)) return true;
    return recomendation.status === 'NEW';
  };

  useEffect(() => {
    dispatch(getPublicOptions());
  }, [dispatch]);

  const queryMemo = useMemo(
    () => ({
      limit: perPage,
      page,
      sort,
      showDeleted: status,
      search: search || null,
      vivodeship: vivodeship || null,
    }),
    [vivodeship, perPage, page, status, sort, search],
  );

  const handleExportCb = useCallback(() => {
    handleExport(queryMemo);
  }, [handleExport, queryMemo]);

  const getRecommendationsData = useCallback(() => {
    getRecommendedUsers(queryMemo);
  }, [getRecommendedUsers, queryMemo]);

  useEffect(() => {
    getRecommendationsData();
  }, [getRecommendationsData]);

  const toggleNewPanel = () => {
    setIsAddNewVisible(!isAddNewVisible);
  };

  const closeEditPanel = () => {
    setEditVisible(false);
    clearCurrentRecommendedUser();
  };

  const getPartner = (id) => {
    const local = recommendedUsers.find((u) => u.id === id);
    if (local) {
      setCurrentRecommendedUser(local);
    } else {
      getRecommendedUser(id);
    }
  };

  const openEditPanel = (id) => {
    getPartner(id);
    setEditVisible(true);
  };

  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setPage('1');
  };

  const handleSearch = (value) => {
    setSearch(value);
    setPage(1);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setPage(1);
  };

  const handleSort = (e) => {
    setSort(e.target.value);
    setPage(1);
  };

  const handleVivodeship = (e) => {
    setVivodeship(e.target.value);
    setPage(1);
  };

  const handleDelete = (id) => {
    setDeletedError('');

    collaborationService
      .deleteRecommendedUser(id)
      .then(() => {
        setDeleted('');
        getRecommendationsData();
      })
      .catch((err) => {
        console.dir(err);
        setDeletedError(err.response.data.error.message);
      });
  };

  const showDeletePopup = (deleted) => (
    <SuccessPopup fixed>
      <span>Czy na pewno chcesz usunąć {deleted.companyName} ?</span>
      {deletedError !== '' && <span style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{deletedError}</span>}
      <div className="buttons">
        <Button
          secondary
          onClick={() => {
            setDeletedError('');
            setDeleted('');
          }}
        >
          Anuluj
        </Button>
        <Button onClick={() => handleDelete(deleted.id)}>{deletedError ? 'Spróbuj ponownie' : 'Usuń'}</Button>
      </div>
    </SuccessPopup>
  );

  return (
    <PageTemplate>
      {!isLoading && error && <ErrorPopup fixed>{error.message}</ErrorPopup>}
      <TopSection>
        {['partner', 'subPartner'].includes(userRole) ? (
          <Button onClick={toggleNewPanel}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Poleć partnera
          </Button>
        ) : (
          <span />
        )}
        <TableSorter>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={handleStatus}>
              <option value="false">Aktywne</option>
              <option value="true">Wszystkie</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={sort} onChange={handleSort}>
              <option value="DESC">Najnowsze</option>
              <option value="ASC">Najstarsze</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Województwo</span>
            <Select small value={vivodeship} onChange={handleVivodeship}>
              <option value="">&nbsp;</option>
              {provinces.map((province) => (
                <option key={province.value} value={province.value}>
                  {province.display}
                </option>
              ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {isLoading ? (
        <Loading />
      ) : (
        <CollaborationTable
          handleEdit={openEditPanel}
          data={recommendedUsers}
          headers={headers}
          offset={(currentPage - 1) * (perPage || 20)}
          canUserEdit={handleCanEdit}
          handleDelete={['admin', 'dsp'].includes(userRole) && setDeleted}
        />
      )}
      <ExportButtonsWrapper>
        <Button small secondary onClick={handleExportCb}>
          Eksportuj
        </Button>
      </ExportButtonsWrapper>
      <BottomSection>
        <BottomItem>Poleconych partnerów: {recommendedUsersCount}</BottomItem>
        <Pagination allowPageSet currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      {deleted !== '' && showDeletePopup(deleted)}
      <SidePanel toggleClose={closeEditPanel} isVisible={isEditVisible}>
        {currentRecommendedUser !== null && (
          <RecommendationForm
            editMode
            adminMode={['admin', 'dsp'].includes(userRole)}
            initialValues={{ ...currentRecommendedUser }}
            toggleClose={closeEditPanel}
            refetch={getRecommendationsData}
          />
        )}
      </SidePanel>
      <SidePanel toggleClose={toggleNewPanel} isVisible={isAddNewVisible}>
        <RecommendationForm toggleClose={toggleNewPanel} refetch={getRecommendationsData} />
      </SidePanel>
      <ExportPopup />
    </PageTemplate>
  );
};

CollaborationPage.defaultProps = {
  currentRecommendedUser: null,
  error: null,
  recommendedUsersCount: 0,

  totalPages: 1,
  currentPage: 1,
};

CollaborationPage.propTypes = {
  recommendedUsers: PropTypes.instanceOf(Array).isRequired,
  currentRecommendedUser: PropTypes.instanceOf(Object),
  recommendedUsersCount: PropTypes.number,

  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Object),

  totalPages: PropTypes.number,
  currentPage: PropTypes.number,

  getRecommendedUsers: PropTypes.func.isRequired,
  getRecommendedUser: PropTypes.func.isRequired,
  setCurrentRecommendedUser: PropTypes.func.isRequired,
  clearCurrentRecommendedUser: PropTypes.func.isRequired,

  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  recommendedUsers: state.collaboration.recommendedUsers.data.rows,
  currentRecommendedUser: state.collaboration.currentRecommendedUser.data,
  recommendedUsersCount: state.collaboration.recommendedUsers.data.count,

  isLoading: state.collaboration.recommendedUsers.isLoading,
  error: state.collaboration.recommendedUsers.error,

  totalPages: state.collaboration.recommendedUsers.data.pagination.totalPages,
  currentPage: state.collaboration.recommendedUsers.data.pagination.currentPage,

  userRole: state.user.data.role.slug,
});

const mapDispatchToProps = {
  getRecommendedUsers,
  getRecommendedUser,
  setCurrentRecommendedUser,
  clearCurrentRecommendedUser,
};

const ExportButtonsWrapper = styled.div`
  button {
    display: inline-block;
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationPage);
