import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableWrapperCommon, baseTableMixin } from 'components/layout/Tables/TableComponents';
import { partnerProgramOptions } from 'config/constants';
import { resolveObjProp } from 'utils/helpers';
import NoData from 'components/UI/NoData';

const NotificationsTable = ({ headers, data, actions }) => {
  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => (
              <col key={header.property} className={header.property} />
            ))}
            {actions && <col className="actions" />}
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id || index}>
                <td>{index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = item.id + header.property;
                  let propValue = resolveObjProp(header.property, item);

                  if (header.property === 'fullName' && !propValue)
                    propValue = `${item.name || ''} ${item.surname || ''}`;

                  if (header.property === 'partnerProgram') {
                    const arr = JSON.parse(item.partnerProgram);
                    propValue = arr.map(v => partnerProgramOptions.find(p => p.value === v)?.display || 'BRAK').join(' - ');
                  }
                  return (
                    <td className="name" key={uniqueKey}>
                      {propValue}
                    </td>
                  );
                })}
                {actions && (
                  <td key="edit">
                    <span className="icons">
                      {actions.map(action => (
                        <FontAwesomeIcon
                          key={item.id}
                          title={action.title}
                          icon={action.icon}
                          onClick={() => action.handler(item)}
                        />
                      ))}
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData message="Brak danych" />
      )}
    </TableWrapper>
  );
};

NotificationsTable.defaultProps = {
  actions: false,
};

NotificationsTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  actions: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool]),
};

export default NotificationsTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  ${baseTableMixin}
  width: 100%;
  height: auto;
  flex-grow: 0;

  td {
    font-size: 0.9em;
  }
  table {
    /* table-layout: fixed; */
  }
`;
