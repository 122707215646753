import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pl';
import GlobalStyle from 'theme/GlobalStyle';
import AuthErrorPopup from 'components/layout/AuthErrorPopup';
import SocketConnect from 'components/logic/SocketConnect';
import Notifications from 'components/logic/Notifications';
import Messages from 'components/logic/Messages';
import Confetti from 'components/logic/Confetti';
import Markerio from 'components/logic/Markerio';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBolt,
  faPowerOff,
  faEnvelopeOpenText,
  faEdit,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faClipboard,
  faClipboardCheck,
  faComment,
  faStickyNote,
  faList,
  faThList,
  faSort,
  faAddressBook,
  faIdBadge,
  faWallet,
  faInfoCircle,
  faInfo,
  faQuestionCircle,
  faQuestion,
  faExclamation,
  faExclamationTriangle,
  faCalendarPlus,
  faCalendarCheck,
  faGlassCheers,
  faPlus,
  faBurn,
  faBookmark,
  faArchive,
  faLevelUpAlt,
  faUserTie,
  faChevronUp,
  faChevronDown,
  faFileExport,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faFileImage,
  faFileDownload,
  faFile,
  faFileInvoice,
  faFileCsv,
  faFileCode,
  faFileWord,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faBalanceScale,
  faChartLine,
  faBullhorn,
  faTimes,
  faSignInAlt,
  faFileUpload,
  faUpload,
  faBell,
  faEllipsisH,
  faPlayCircle,
  faArrowLeft,
  faChartBar,
  faPaperPlane,
  faSearch,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faSearch,
  faBolt,
  faPowerOff,
  faEnvelopeOpenText,
  faEdit,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faClipboard,
  faClipboardCheck,
  faComment,
  faStickyNote,
  faList,
  faThList,
  faSort,
  faAddressBook,
  faIdBadge,
  faWallet,
  faInfoCircle,
  faInfo,
  faQuestionCircle,
  faQuestion,
  faExclamation,
  faExclamationTriangle,
  faCalendarPlus,
  faCalendarCheck,
  faGlassCheers,
  faPlus,
  faBurn,
  faBookmark,
  faArchive,
  faLevelUpAlt,
  faUserTie,
  faChevronUp,
  faChevronDown,
  faFileExport,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faFileImage,
  faFileDownload,
  faFile,
  faFileInvoice,
  faFileCsv,
  faFileCode,
  faFileWord,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faBalanceScale,
  faChartLine,
  faBullhorn,
  faTimes,
  faSignInAlt,
  faFileUpload,
  faUpload,
  faBell,
  faEllipsisH,
  faPlayCircle,
  faArrowLeft,
  faChartBar,
  faPaperPlane,
  faPaperclip,
);

moment.updateLocale('pl', {
  relativeTime: {
    s: 'poniżej minuty',
  },
});

const MainTemplate = ({ children }) => {
  const isUserLoaded = useSelector((state) => state.user.isUserLoaded);

  return (
    <>
      <GlobalStyle />
      <AuthErrorPopup />
      {isUserLoaded && (
        <>
          <SocketConnect />
          <Notifications />
          <Messages />
        </>
      )}
      {children}
      <Confetti />
      <Markerio />
    </>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default MainTemplate;
