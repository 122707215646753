import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import { userPreferencesSchema } from 'utils/validation';
import { FieldHeading } from 'components/forms/FormComponents';
import { userService } from 'services/user';
import Button from 'components/UI/Button';
import ErrorLabel from 'components/UI/ErrorLabel';
import Heading from 'components/UI/Heading';
import SuccessPopup from 'components/layout/SuccessPopup';
import Radio from 'components/UI/Radio';
import ErrorPopup from 'components/layout/ErrorPopup';

const defaultSettings = {
  shared: {
    newMessagesEmailNotification: '0',
  },
  admin: {},
  dsp: {},
  dr: {},
  partner: {},
  merchantPep: {},
  subPartner: {},
};

const UserPreferences = () => {
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const userData = useSelector(s => s.user.data);
  const settings = { ...defaultSettings.shared, ...defaultSettings[userData.role.slug], ...userData.settings };

  const handleReturn = () => {
    setSuccess(false);
    setError(false);
  };

  const handleSubmit = values => {
    handleReturn();

    userService
      .updateSettings({ settings: JSON.stringify(values) })
      .then(data => {
        console.log(data);
        setSuccess(true);
        setEdit(false);
      })
      .catch(err => {
        console.log(err);
        setError(err.response?.data?.error?.message || 'Wystąpił nieznany błąd');
      });
  };

  const toggleEdit = () => setEdit(s => !s);

  return (
    <Wrapper>
      {success && (
        <SuccessPopup fixed>
          <Heading>Preferencje zostały zaktualizowane</Heading>
          <div className="buttons">
            <Button onClick={handleReturn}>Zamknij</Button>
          </div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup>
          <Heading centered>Wystąpił błąd</Heading>
          <p>{error}</p>
          <div className="buttons">
            <Button gray onClick={handleReturn}>
              Zamknij
            </Button>
          </div>
        </ErrorPopup>
      )}
      <Heading style={{ marginBottom: '2rem' }} size="xl">
        Moje preferencje
      </Heading>
      <Formik
        initialValues={{ ...settings }}
        validationSchema={userPreferencesSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <FieldHeading mbSmall size="m">
              Powiadomienie e-mail dla nowych wiadomości
            </FieldHeading>
            <RadioWrapper>
              <Radio
                disabled={!edit}
                name="newMessagesEmailNotification"
                value="1"
                onChange={handleChange}
                label="Tak"
                checked={values.newMessagesEmailNotification === '1'}
              />
              <Radio
                disabled={!edit}
                name="newMessagesEmailNotification"
                value="0"
                onChange={handleChange}
                label="Nie"
                checked={values.newMessagesEmailNotification === '0'}
              />
            </RadioWrapper>
            <ErrorMessage style={{ marginTop: '5px' }} component={ErrorLabel} name="newMessagesEmailNotification" />
            <ButtonWrapper>
              <Button small gray={!edit} secondary={edit} type="button" onClick={toggleEdit}>
                {edit ? 'Anuluj' : 'Edytuj preferencje'}
              </Button>
              {edit && (
                <Button small type="submit">
                  Zapisz
                </Button>
              )}
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default UserPreferences;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & > ${Button} {
    min-width: 140px;
    margin-right: 20px;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 4rem 2rem;
`;
