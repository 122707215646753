import styled, { css } from 'styled-components';
import { colors, breakpoints } from 'theme/styles';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const Col = styled.div`
  width: 100%;
  min-width: 0;
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  & > *:not(:last-child) {
    margin-bottom: 3rem;
  }

  @media (min-width: 769px) {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

export const ColLeft = styled.section`
  @media (min-width: 769px) {
    padding-right: 15px;
    flex: 0 1 ${(props) => props.width || '40%'};
    max-width: ${(props) => props.width || '40%'};
  }
`;

export const ColRight = styled.section`
  @media (min-width: 769px) {
    padding-left: 15px;
    flex: 0 1 ${(props) => props.width || '60%'};
    max-width: ${(props) => props.width || '60%'};
  }
`;

export const Block = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0px 3px 15px #00000010;
  padding: 15px 20px;
  background: ${colors.white};
  border-radius: 4px;

  & + & {
    margin-top: 15px;
  }

  &:only-child {
    margin-bottom: 15px;
  }

  @media (min-width: ${breakpoints.mobile}) {
    padding: 20px 25px;

    &:only-child {
      margin-bottom: 0;
    }

    & + & {
      margin-top: 20px;
    }
  }

  ${({ noInteract }) =>
    noInteract &&
    css`
      background: ${colors.blueGray};

      * {
        cursor: default;
      }
    `}

  ${({ inner }) =>
    inner &&
    css`
      box-shadow: none;
      border: 1px solid ${colors.lightGray};
      padding: 15px 20px 20px;

      @media (min-width: ${breakpoints.mobile}) {
        padding: 15px 20px 20px;
      }
    `}
`;

export const TwoColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.mobileSmall}) {
    flex-direction: row;

    & > * {
      max-width: calc(50% - 10px);
      flex: 0 1 calc(50% - 10px);
    }
  }
`;

export const TwoColumnsRwd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${(props) => props.breakpoint || breakpoints.mobileSmall}) {
    flex-direction: row;

    & > * {
      max-width: calc(50% - 10px);
      flex: 0 1 calc(50% - 10px);
    }
  }
`;
