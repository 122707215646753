import React from 'react';
import styled from 'styled-components';
import Heading from 'components/UI/Heading';
import PropTypes from 'prop-types';
import { colors } from 'theme/styles';

const NoData = ({ message }) => (
  <Wrapper>
    <Heading centered size="m">
      {message}
    </Heading>
  </Wrapper>
);

NoData.defaultProps = {
  message: 'Brak danych',
};

NoData.propTypes = {
  message: PropTypes.string,
};

export default NoData;

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${colors.darkBlue};
  padding: 20px 30px;
  background-color: ${colors.lighterGray};
`;
