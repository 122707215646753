/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ErrorMessage, FieldArray } from 'formik';
import { Block } from 'components/UI/Structure';
import { AlignCheckbox, FieldHeading, FlexFields, TimeFields } from 'components/forms/FormComponents';
import { placeFieldsRender, terminalFieldsRender } from 'components/forms/LeadMerchant/others/helpers';
import CloseBtn from 'components/UI/CloseBtn';
import Button from 'components/UI/Button';
import Point from 'components/forms/LeadMerchant/others/Point';
// import Terminal from 'components/forms/LeadMerchant/others/Terminal';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import Select from 'components/UI/Select';
import Heading from 'components/UI/Heading';
import TimePicker from 'components/UI/TimePicker';
import mcc from 'components/forms/LeadMerchant/others/mcc';
import InputAuto from 'components/UI/InputAuto';
import Textarea from 'components/UI/Textarea';
import { colors, font } from 'theme/styles';
import FileDropZone from 'components/UI/FileDropzone';
import { leadMerchantFormStep5MimeTypes } from 'config/constants';
import { File } from './LeadMerchantStep4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResetButton from 'components/UI/ResetButton';
import { leadService } from 'services/lead';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import { uuidV4Regex } from './LeadMerchant';
import { download } from 'utils/helpers';

const LeadMerchantStep5 = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  config,
  validateForm,
  viewMode,
  setFieldTouched,
  debugMode,
}) => {
  // const didMount = useRef(null);
  const [deleteError, setDeleteError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [addFileError, setAddFileError] = useState('');
  const isBillingPoint = useMemo(() => values.settlements.billingSystem === 2, [values.settlements.billingSystem]);
  const canUserInstallTerminal = useSelector((state) => state.user.data.canInstallTerminal);

  const { points: stepValues } = values;
  const { points: stepTouched = {} } = touched;
  const { points: stepErrors = {} } = errors;

  const bag = { values: stepValues, touched: stepTouched, errors: stepErrors, handleChange, handleBlur, setFieldValue };

  // Wywalone - domyślnie zawsze do wspisania
  // const [sameAsMainAddress, setSameAsMainAddress] = useState(stepValues.map((a) => Boolean(a.address.sameAs)));
  const [sameAsMainAddress] = useState(stepValues.map((a) => Boolean(a.address.sameAs)));

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    if (config.dontMessWithValues) return;

    if (values.settlements.pbg === 2 || values.settlements.pbgPwob === 0) return;
    values.points.forEach((point, pI) => {
      point.terminals.forEach((terminal, tI) => {
        if (terminal.type === 3) {
          setFieldValue(`points.${pI}.terminals.${tI}.type`, 1);
        }
      });
    });
  }, [setFieldValue, values.settlements.pbg, values.settlements.pbgPwob, config.dontMessWithValues]);

  useEffect(() => {
    sameAsMainAddress.forEach((pointCheck, pointIndex) => {
      setFieldValue(`points.${pointIndex}.billingSystemCopy`, values.settlements.billingSystem);
    });
  }, []);

  // useEffect(() => {
  //   sameAsMainAddress.forEach((pointCheck, pointIndex) => {
  //     if (!stepValues[pointIndex]) return;

  //     let address = new Place({ sameAs: false });

  //     // if (pointCheck) address = { ...values.addresses.main, sameAs: true };
  //     if (pointCheck) address = { ...values.addresses.main, sameAs: false };
  //     else if (config.pointsAddresses && config.pointsAddresses[pointIndex])
  //       address = { ...stepValues[pointIndex].address, sameAs: false };

  //     setFieldValue(`points.${pointIndex}.address`, address);
  //   });
  // }, [sameAsMainAddress, setFieldValue]);

  // Wywalone - domyślnie zawsze do wspisania
  // useEffect(() => {
  //   if (didMount.current) setSameAsMainAddress(stepValues.map((p) => p.address.sameAs));
  //   else didMount.current = true;
  // }, [stepValues.length]);

  // useEffect(() => {
  //   setConfig((c) => ({ ...c, pointsAddresses: sameAsMainAddress.map((el) => !el) }));
  // }, [sameAsMainAddress, setConfig]);

  // Wywalone - domyślnie zawsze do wspisania
  // useEffect(() => {
  //   if (config.isGusEmpty) {
  //     // Wymusza cykl sameAsMainAddress bo nowa referncja
  //     setSameAsMainAddress((prev) => prev.map(() => false));
  //   }
  // }, []);

  const deleteFile = (onboardingId, mediaId, cb) => {
    setDeleteLoading(true);
    setDeleteError('');
    leadService
      .deleteOnbordingPointFile(onboardingId, mediaId)
      .then(() => {
        cb();
      })
      .catch((err) => {
        console.log(err);
        const error = err.response.data.error.message || 'Wystąpił błąd';
        const code = error.match(uuidV4Regex)?.[0] || null;
        if (code) setDeleteError('Wystąpił błąd podczas usuwania pliku. ID błędu: ' + code);
        setDeleteError(error);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const downloadFile = (file) => {
    setDeleteLoading(true);
    setDeleteError('');
    leadService
      .getOnboardingPointFile(file.contractId, file.id)
      .then((res) => {
        download(res, file.fileName);
      })
      .catch((err) => {
        console.log(err);
        const error = err.response.data.error.message || 'Wystąpił błąd';
        const code = error.match(uuidV4Regex)?.[0] || null;
        if (code) setDeleteError('Wystąpił błąd podczas usuwania pliku. ID błędu: ' + code);
        setDeleteError(error);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <>
      {deleteError && !deleteLoading && <ErrorPopup>{deleteError.message}</ErrorPopup>}
      {deleteLoading && <Loading fixed />}
      <FieldArray style={{ marginTop: '20px' }} name="points">
        {(arrayHelpers) => (
          <>
            {stepValues.map((point, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Block noInteract={viewMode} key={index}>
                {index > 0 && !viewMode && <CloseBtn disabled={viewMode} onClick={() => arrayHelpers.remove(index)} />}
                <PointNumber size="xxl">
                  <span className="orange">P{index + 1}</span>
                  <br />
                </PointNumber>
                <FlexFields cols={3}>
                  <div>
                    <FieldHeading mb size="m" style={{ marginBottom: '-20px' }}>
                      Nazwa punktu
                    </FieldHeading>
                    <Input
                      style={{ maxWidth: '295px' }}
                      disabled={viewMode}
                      name={`points.${index}.name`}
                      type="text"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue(`points.${index}.name`, stepValues[index].name.trim() || '');
                      }}
                      label=""
                      value={stepValues[index].name || ''}
                      error={stepTouched[index]?.name && stepErrors[index]?.name}
                    />
                    <ErrorMessage component={ErrorLabel} name={`points.${index}.name`} />
                  </div>
                  {isBillingPoint && (
                    <div>
                      <FieldHeading mb size="m" style={{ marginBottom: '-20px' }}>
                        Rachunek bankowy punktu
                      </FieldHeading>
                      <Input
                        style={{ maxWidth: '295px' }}
                        disabled={viewMode}
                        name={`points.${index}.pointBankAccount`}
                        type="text"
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFieldValue(
                            `points.${index}.pointBankAccount`,
                            stepValues[index].pointBankAccount.trim() || '',
                          );
                        }}
                        label=""
                        value={stepValues[index].pointBankAccount || ''}
                        error={stepTouched[index]?.pointBankAccount && stepErrors[index]?.pointBankAccount}
                      />
                      <ErrorMessage component={ErrorLabel} name={`points.${index}.pointBankAccount`} />
                    </div>
                  )}
                </FlexFields>
                <AlignCheckbox noMargin style={{ marginTop: '10px' }}>
                  <FieldHeading mb size="m">
                    Adres punktu - miejsce montażu terminala
                  </FieldHeading>
                  {/* <Checkbox
                    disabled={viewMode || config.isGusEmpty}
                    style={{ marginBottom: '20px' }}
                    name="isSignatoryCheck"
                    onChange={(e) => {
                      e.persist();
                      setSameAsMainAddress((prev) => prev.map((show, i) => (i === index ? e.target.checked : show)));
                    }}
                    onBlur={handleBlur}
                    label="Taki sam jak siedziby"
                    value={sameAsMainAddress[index] || false}
                    checked={sameAsMainAddress[index]}
                  /> */}
                </AlignCheckbox>
                {/* {!sameAsMainAddress[index] && placeFieldsRender('points', 'address', bag, viewMode, index)} */}
                {placeFieldsRender('points', 'address', bag, viewMode, index)}
                <FieldHeading size="m">Osoba kontaktowa</FieldHeading>
                <FlexFields cols={3}>
                  <div>
                    <Input
                      disabled={viewMode}
                      name={`points.${index}.contactPerson.name`}
                      type="text"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue(
                          `points.${index}.contactPerson.name`,
                          stepValues[index]?.contactPerson?.name.trim() || '',
                        );
                      }}
                      label="Imię"
                      value={stepValues[index]?.contactPerson?.name || ''}
                      error={stepTouched[index]?.contactPerson?.name && stepErrors[index]?.contactPerson?.name}
                    />
                    <ErrorMessage component={ErrorLabel} name={`points.${index}.contactPerson.name`} />
                  </div>
                  <div>
                    <Input
                      disabled={viewMode}
                      name={`points.${index}.contactPerson.lastname`}
                      type="text"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue(
                          `points.${index}.contactPerson.lastname`,
                          stepValues[index]?.contactPerson?.lastname.trim() || '',
                        );
                      }}
                      label="Nazwisko"
                      value={stepValues[index]?.contactPerson?.lastname || ''}
                      error={stepTouched[index]?.contactPerson?.lastname && stepErrors[index]?.contactPerson?.lastname}
                    />
                    <ErrorMessage component={ErrorLabel} name={`points.${index}.contactPerson.lastname`} />
                  </div>
                  <div>
                    <Input
                      disabled={viewMode}
                      name={`points.${index}.contactPerson.phone`}
                      type="text"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue(
                          `points.${index}.contactPerson.phone`,
                          stepValues[index]?.contactPerson?.phone.trim() || '',
                        );
                      }}
                      label="Numer telefonu"
                      value={stepValues[index]?.contactPerson?.phone || ''}
                      error={stepTouched[index]?.contactPerson?.phone && stepErrors[index]?.contactPerson?.phone}
                    />
                    <ErrorMessage component={ErrorLabel} name={`points.${index}.contactPerson.phone`} />
                  </div>
                </FlexFields>
                <FieldHeading size="m" style={{ marginBottom: '-20px', marginTop: '10px' }}>
                  Branża
                </FieldHeading>
                <FlexFields cols={3}>
                  <div>
                    <Select
                      maxWidth="295px"
                      disabled={viewMode}
                      name={`points.${index}.industry`}
                      value={stepValues[index]?.industry || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                    >
                      <option value="">&nbsp;</option>
                      {mcc.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.display}
                        </option>
                      ))}
                    </Select>
                    <ErrorMessage component={ErrorLabel} name={`points.${index}.industry`} />
                  </div>
                  <div>
                    <InputAuto
                      suggestions={mcc}
                      setFieldValue={() => {}}
                      setFieldTouched={() => {}}
                      name="industrySearch"
                      onlyFromList
                      pickProp="display"
                      value=""
                      type="text"
                      display={['display']}
                      onBlur={() => {}}
                      onSelect={(v, item) => setFieldValue(`points.${index}.industry`, item.value)}
                      label="Szukaj branżę"
                    />
                  </div>
                </FlexFields>
                <FieldHeading mb size="m" style={{ marginTop: '20px', marginBottom: '-10px' }}>
                  Godziny otwarcia
                </FieldHeading>
                <FlexFields>
                  <div>
                    <FieldHeading size="xs" style={{ marginBottom: '-5px' }}>
                      Pon - Pt
                    </FieldHeading>
                    <TimeFields>
                      <span>od:</span>
                      <div onBlur={() => setFieldTouched(`points.${index}.workSchedule.ponPt.from`, true)}>
                        <TimePicker
                          disabled={viewMode}
                          value={stepValues[index]?.workSchedule?.ponPt?.from || ''}
                          setValue={(date) => setFieldValue(`points.${index}.workSchedule.ponPt.from`, date)}
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <ErrorLabel style={{ marginBottom: '-2.2rem', marginTop: '1.2rem' }}>{msg}</ErrorLabel>
                          )}
                          name={`points.${index}.workSchedule.ponPt.from`}
                        />
                      </div>
                      <span>do:</span>
                      <div onBlur={() => setFieldTouched(`points.${index}.workSchedule.ponPt.to`, true)}>
                        <TimePicker
                          disabled={viewMode}
                          value={stepValues[index]?.workSchedule?.ponPt?.to || ''}
                          setValue={(date) => {
                            setFieldValue(`points.${index}.workSchedule.ponPt.to`, date);
                          }}
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <ErrorLabel style={{ marginBottom: '-2.2rem', marginTop: '1.2rem' }}>{msg}</ErrorLabel>
                          )}
                          name={`points.${index}.workSchedule.ponPt.to`}
                        />
                      </div>
                    </TimeFields>
                  </div>
                  <div>
                    <FieldHeading size="xs" style={{ marginBottom: '-5px' }}>
                      Sobota
                    </FieldHeading>
                    <TimeFields>
                      <span>od:</span>
                      <div onBlur={() => setFieldTouched(`points.${index}.workSchedule.sob.from`, true)}>
                        <TimePicker
                          disabled={viewMode}
                          value={stepValues[index]?.workSchedule?.sob?.from || ''}
                          setValue={(date) => setFieldValue(`points.${index}.workSchedule.sob.from`, date)}
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <ErrorLabel style={{ marginBottom: '-2.2rem', marginTop: '1.2rem' }}>{msg}</ErrorLabel>
                          )}
                          name={`points.${index}.workSchedule.sob.from`}
                        />
                      </div>
                      <span>do:</span>
                      <div onBlur={() => setFieldTouched(`points.${index}.workSchedule.sob.to`, true)}>
                        <TimePicker
                          disabled={viewMode}
                          value={stepValues[index]?.workSchedule?.sob?.to || ''}
                          setValue={(date) => setFieldValue(`points.${index}.workSchedule.sob.to`, date)}
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <ErrorLabel style={{ marginBottom: '-2.2rem', marginTop: '1.2rem' }}>{msg}</ErrorLabel>
                          )}
                          name={`points.${index}.workSchedule.sob.to`}
                        />
                      </div>
                    </TimeFields>
                  </div>
                  <div>
                    <FieldHeading size="xs" style={{ marginBottom: '-5px' }}>
                      Niedziela
                    </FieldHeading>
                    <TimeFields>
                      <span>od:</span>
                      <div onBlur={() => setFieldTouched(`points.${index}.workSchedule.niedz.from`, true)}>
                        <TimePicker
                          disabled={viewMode}
                          value={stepValues[index]?.workSchedule?.niedz?.from || ''}
                          setValue={(date) => setFieldValue(`points.${index}.workSchedule.niedz.from`, date)}
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <ErrorLabel style={{ marginBottom: '-2.2rem', marginTop: '1.2rem' }}>{msg}</ErrorLabel>
                          )}
                          name={`points.${index}.workSchedule.niedz.from`}
                        />
                      </div>
                      <span>do:</span>
                      <div onBlur={() => setFieldTouched(`points.${index}.workSchedule.niedz.to`, true)}>
                        <TimePicker
                          disabled={viewMode}
                          value={stepValues[index]?.workSchedule?.niedz?.to || ''}
                          setValue={(date) => setFieldValue(`points.${index}.workSchedule.niedz.to`, date)}
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <ErrorLabel style={{ marginBottom: '-2.2rem', marginTop: '1.2rem' }}>{msg}</ErrorLabel>
                          )}
                          name={`points.${index}.workSchedule.niedz.to`}
                        />
                      </div>
                    </TimeFields>
                  </div>
                </FlexFields>

                <FlexFields cols={2} style={{ marginTop: '40px' }}>
                  <div style={{ marginBottom: '5px' }}>
                    <FieldHeading mbSmall size="m">
                      Uwagi i informacje dodatkowe
                    </FieldHeading>
                    <p style={{ fontSize: font.size.s, marginBottom: '1rem' }}>
                      Prosimy o przekazanie strony www, facebook, instagram, strony gdzie ogłasza się klient.
                    </p>
                    <Textarea
                      disabled={viewMode}
                      name={`points.${index}.notes`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width="100%"
                      locked
                      height="92px"
                      value={stepValues[index]?.notes || ''}
                      error={stepTouched[index]?.notes && stepErrors[index]?.notes}
                    />
                    <ErrorMessage component={ErrorLabel} name={`points.${index}.notes`} />
                  </div>
                  <div>
                    <FieldHeading mbSmall size="m">
                      Zdjęcia
                    </FieldHeading>
                    <p style={{ fontSize: font.size.s, marginBottom: '1rem' }}>
                      Prosimy o przesłanie następujących plików: wydruk z fiskalizacji kasy fiskalnej, zdjęcie lokalu,
                      zdjęcie szyldu, ulotka
                    </p>
                    {addFileError && <ErrorLabel style={{ marginTop: '2rem' }}>{addFileError}</ErrorLabel>}
                    <FileDropZone
                      handleBlur={handleBlur}
                      onDrop={(acceptedFiles, fileRejections) => {
                        fileRejections.forEach((file) => {
                          file.errors.forEach((err) => {
                            if (err.code === 'file-too-large') setAddFileError('Plik jest za duży, maksymalnie 10mb');
                            if (err.code === 'file-invalid-type') setAddFileError('Plik ma niepoprawny format');
                          });
                        });

                        if (acceptedFiles.length > 0) {
                          const prevFiles = values.files || [];
                          setAddFileError('');
                          setFieldValue('files', [
                            ...prevFiles,
                            ...acceptedFiles.map((file) =>
                              Object.assign(file, {
                                pointId: index,
                                local: true,
                              }),
                            ),
                          ]);
                        }
                      }}
                      multiple
                      disabled={viewMode}
                      name="contact.files"
                      accept={leadMerchantFormStep5MimeTypes}
                      label="Dodaj zdjęcia"
                    />
                    <FilesWrapper>
                      {/* To są zapisane na BE */}
                      {values.media &&
                        values.media
                          .filter((f) => f.pointId === index)
                          .map((file) => (
                            <FileCard key={file.fileName} saved>
                              <File download={true} onClick={() => downloadFile(file)}>
                                <FontAwesomeIcon fixedWidth icon="file-image" />
                                <span>{file.fileName}</span>
                              </File>
                              <ResetButton
                                type="button"
                                onClick={() =>
                                  deleteFile(file.contractId, file.id, () =>
                                    setFieldValue(
                                      'media',
                                      values.media.filter((f) => f.id !== file.id),
                                    ),
                                  )
                                }
                              >
                                Usuń
                              </ResetButton>
                            </FileCard>
                          ))}

                      {/* To są wybrane lokalnie, ale nie przesłane*/}
                      {values.files &&
                        values.files
                          .filter((f) => f.pointId === index)
                          .map((file) => (
                            <FileCard key={file.name}>
                              <File download={false}>
                                <FontAwesomeIcon fixedWidth icon="file-image" />
                                <span>{file.name}</span>
                              </File>
                              <ResetButton
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    'files',
                                    values.files.filter((f) => f.path !== file.path),
                                  )
                                }
                              >
                                Usuń
                              </ResetButton>
                            </FileCard>
                          ))}
                    </FilesWrapper>
                  </div>
                </FlexFields>
                {/* Nowe END */}

                <FieldHeading size="l" style={{ marginBottom: '20px', marginTop: '20px' }}>
                  Terminal:
                </FieldHeading>
                <div style={{ marginBottom: '20px' }}>
                  <FieldArray name={`points[${index}].terminals`}>
                    {(terminalsArrHelpers) => (
                      <>
                        {stepValues[index].terminals.map((terminal, tIndex) =>
                          terminalFieldsRender(
                            'points',
                            'terminals',
                            bag,
                            viewMode,
                            index,
                            tIndex,
                            terminalsArrHelpers,
                            canUserInstallTerminal,
                            values,
                          ),
                        )}
                        {/* {!viewMode && (
                          <Button
                            disabled={viewMode}
                            style={{ marginTop: '20px' }}
                            right
                            small
                            green
                            type="button"
                            onClick={() => terminalsArrHelpers.push(new Terminal())}
                          >
                            Dodaj terminal
                          </Button>
                        )} */}
                      </>
                    )}
                  </FieldArray>
                </div>
              </Block>
            ))}
            {!viewMode && (
              <Button
                disabled={viewMode}
                right
                small
                green
                type="button"
                style={{ marginTop: '20px' }}
                onClick={() => {
                  const p = new Point({ isAddressSameAsMain: false });
                  p.billingSystemCopy = values.settlements.billingSystem;
                  arrayHelpers.push(p);
                }}
              >
                Dodaj kolejny punkt
              </Button>
            )}
          </>
        )}
      </FieldArray>
      {debugMode && (
        <>
          <Block>
            <div>
              <pre>Version: {JSON.stringify(values.version || '???', null, 4)}</pre>
              <pre>Version Number: {JSON.stringify(values.versionNumber || '???', null, 4)}</pre>
            </div>
          </Block>
          <Block>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <pre>Values: {JSON.stringify(stepValues, null, 4)}</pre>
              <pre>Errors: {JSON.stringify(errors, null, 4)}</pre>
              <pre>Touched: {JSON.stringify(touched, null, 4)}</pre>
            </div>
          </Block>
        </>
      )}
    </>
  );
};

LeadMerchantStep5.defaultProps = {
  config: {},
  values: {},
  errors: {},
  touched: {},
  handleChange: () => {},
  handleBlur: () => {},
  setFieldValue: () => {},
  setConfig: () => {},
  validateForm: () => {},
  setFieldTouched: () => {},
  debugMode: false,
};

LeadMerchantStep5.propTypes = {
  config: PropTypes.instanceOf(Object),
  values: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setConfig: PropTypes.func,
  validateForm: PropTypes.func,
  setFieldTouched: PropTypes.func,
  viewMode: PropTypes.bool.isRequired,
  debugMode: PropTypes.bool,
};

export default LeadMerchantStep5;

const PointNumber = styled(Heading)`
  position: absolute;
  right: 2rem;
`;

const FilesWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FileCard = styled.div`
  background-color: ${({ saved }) => (saved ? colors.blueGray : colors.orangeSubtle)};
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  justify-content: space-between;
  font-size: ${font.size.s};
`;
