import * as types from './types';

const initialState = {
  challenges: {
    data: [],
    isLoading: false,
    error: '',
  },
  currentChallenge: false,
  currentChallengeDetails: {
    data: false,
    isLoading: false,
    error: '',
  },
};

const challengeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHALLENGES_REQUEST:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          isLoading: true,
          error: '',
        },
      };
    case types.GET_CHALLENGES_SUCCESS:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case types.GET_CHALLENGES_FAILURE:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_PARTNER_CHALLENGE_REQUEST:
      return {
        ...state,
        currentChallengeDetails: {
          ...state.currentChallengeDetails,
          isLoading: true,
          error: '',
        },
      };
    case types.GET_PARTNER_CHALLENGE_SUCCESS:
      return {
        ...state,
        currentChallengeDetails: {
          ...state.currentChallengeDetails,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case types.GET_PARTNER_CHALLENGE_FAILURE:
      return {
        ...state,
        currentChallengeDetails: {
          ...state.currentChallengeDetails,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.SET_CURRENT_CHALLENGE:
      return {
        ...state,
        currentChallenge: action.payload,
      };
    case types.CLEAR_CURRENT_CHALLENGE:
      return {
        ...state,
        currentChallenge: false,
      };
    case types.RESET_CHALLENGES:
      return initialState;
    default:
      return state;
  }
};

export default challengeReducer;
