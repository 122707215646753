export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_WIDGET = 'SET_NOTIFICATIONS_WIDGET';

export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_NOTIFICATION_WIDGET = 'ADD_NOTIFICATION_WIDGET';

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS_WIDGET = 'CLEAR_NOTIFICATIONS_WIDGET';

export const MARK_AS_READED_ALL = 'MARK_AS_READED_ALL';
export const MARK_AS_READED_ALL_WIDGET = 'MARK_AS_READED_ALL_WIDGET';

export const MARK_AS_READED_ONE = 'MARK_AS_READED_ONE';

export const SHOW = 'SHOW';
export const HIDE = 'HIDE';
export const TOGGLE = 'TOGGLE';

export const CLEAR_NOTIFICATIONS_REDUCER = 'CLEAR_NOTIFICATIONS_REDUCER';
