export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_PARTNER_TERMS_REQUEST = 'GET_PARTNER_TERMS_REQUEST';
export const GET_PARTNER_TERMS_SUCCESS = 'GET_PARTNER_TERMS_SUCCESS';
export const GET_PARTNER_TERMS_FAILURE = 'GET_PARTNER_TERMS_FAILURE';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const CLEAR_PARTNER = 'CLEAR_PARTNER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
