import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { addApiKeySchema } from 'utils/validation';
import { adminService } from 'services/admin';
import { suggestionsService } from 'services/suggestions';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import { duplicateTypes } from 'config/constants';
import Input from 'components/UI/Input';
import InputAuto from 'components/UI/InputAuto';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import Select from 'components/UI/Select';

const AddKey = ({ refetchKeys }) => {
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const fetchUsers = inputValue => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue)
      .then(payload => setFetchedUsers(payload.data))
      .catch(err => {
        console.log(err);
      });
  };

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleSubmit = values => {
    setError('');
    setLoading(true);
    adminService
      .addApiKey(values)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchKeys();
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {loading && <Loading absolute />}
      <Heading size="xl">Dodaj klucz</Heading>
      <Formik
        initialValues={{
          name: '',
          userId: '',
          duplicateType: duplicateTypes[0].value,
        }}
        validationSchema={addApiKeySchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>Dodałeś nowy klucz</Heading>
                {typeof success !== 'boolean' && success}
                <div className="buttons">
                  <Button onClick={() => handleReturn(true, resetForm)}>Wróć</Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Błąd dodawania klucza</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                Wprowadź dane:
              </FieldHeading>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nazwa"
                value={values.name || ''}
                error={touched.name && errors.name}
              />
              <ErrorMessage component={ErrorLabel} name="name" />
              <InputAuto
                suggestions={fetchedUsers}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onlyFromList
                pickProp="id"
                name="userId"
                type="text"
                display={['name', 'surname', 'email']}
                onChange={e => fetchUsers(e.target.value)}
                onBlur={handleBlur}
                label="Użytkownik"
                value={values.userId || ''}
                error={touched.userId && errors.userId}
              />
              <ErrorMessage component={ErrorLabel} name="userId" />
              <Select
                name="duplicateType"
                value={values.duplicateType}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Typ duplikacji"
                preFilled
              >
                {duplicateTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.display}
                  </option>
                ))}
              </Select>
              <ErrorMessage component={ErrorLabel} name="duplicateType" />
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

AddKey.propTypes = {
  refetchKeys: PropTypes.func.isRequired,
};

export default AddKey;

// styled components

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
