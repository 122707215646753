import { useState } from 'react';

export default configArr => {
  const [state, setState] = useState(
    configArr.reduce((o, key) => {
      let value = key.initialValue || '';
      if (key.type === 'date') value = key.initialValue || ['', ''];
      return { ...o, [key.name]: value };
    }, {}),
  );

  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSpecial = (value, name) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return { topSectionState: state, handleChange, handleSpecial};
};
