import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Label, Text } from 'components/layout/Client/ClientInfo';
import Heading from 'components/UI/Heading';
import { breakpoints, colors } from 'theme/styles';
import { useSelector } from 'react-redux';
// import moment from 'moment';

const CrmAccountDisplayMinimal = ({ id, acc, leadData, current, selected, onClick }) => {
  const userRole = useSelector((state) => state.user.data.role.slug);

  const render = (nodeName) => acc.querySelector(nodeName)?.textContent || '-';
  const status = render('Status');

  const canUserInteract = () => {
    let notAllowedStatusList = ['Odrzucony'];
    let canInteract = !notAllowedStatusList.includes(status);

    if (['dsp'].includes(userRole)) {
      // If acc status other then this
      notAllowedStatusList = ['Nieaktywny__finalny', 'Odrzucony'];

      // If ph email same as phOpBiz email
      const localPhEmail = leadData?.queue?.user?.email || '';
      const phOpBizEmail = render('PhOpBiz Email');
      const isPhOpBizSameAsLeadPh = localPhEmail.toLowerCase() == phOpBizEmail.toLowerCase();

      // If acc is no more then 7 days older then lead
      // const leadCreatedDate = leadData?.createdAt || false;
      // const accountCreatedDate = render('DataUtworzenia');
      // const noMoreThenWeekApart = moment(leadCreatedDate).diff(accountCreatedDate, 'days') <= 7;

      // All above combined
      // canInteract = !notAllowedStatusList.includes(status) && isPhOpBizSameAsLeadPh && noMoreThenWeekApart;
      canInteract = !notAllowedStatusList.includes(status) && isPhOpBizSameAsLeadPh;
    }

    return canInteract;
  };

  const getNameColor = () => {
    if (current) return colors.greenText;
    return canUserInteract() ? colors.orange : 'inherit';
  };

  const getStatusText = () => {
    if (current) return ' - aktualnie przypisany';
    if (selected) return ' - wybrano';
  };

  return (
    <Wrapper
      id={id}
      selected={selected}
      onClick={canUserInteract() ? onClick : null}
      current={current}
      disabled={!canUserInteract()}
    >
      <div style={{ marginBottom: '1rem' }}>
        <Heading color={getNameColor()} style={{ marginBottom: '1rem' }} size="l">
          {render('Nazwa')}
          {getStatusText()}
        </Heading>
      </div>
      <ColsWrapper>
        <div className="span-2">
          <Label>Status</Label>
          <Text>{status}</Text>
        </div>
        <div>
          <Label>Status Value</Label>
          <Text>{render('StatusValue')}</Text>
        </div>
        <div>
          <Label>Data utworzenia</Label>
          <Text>{render('DataUtworzenia')}</Text>
        </div>
        <div>
          <Label>Data modyfikacji</Label>
          <Text>{render('DataModyfikacji')}</Text>
        </div>
        <div>
          <Label>PH Opieki Biz</Label>
          <Text>{render('PhOpBiz Name')}</Text>
        </div>
      </ColsWrapper>
    </Wrapper>
  );
};

CrmAccountDisplayMinimal.defaultProps = {
  selected: false,
  current: false,
  onClick: () => null,
};

CrmAccountDisplayMinimal.propTypes = {
  id: PropTypes.string.isRequired,
  acc: PropTypes.instanceOf(Object).isRequired,
  leadData: PropTypes.instanceOf(Object).isRequired,
  current: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CrmAccountDisplayMinimal;

const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 1rem;

  &:not(:first-child)::before {
    content: '';
    display: block;
    position: absolute;
    top: -1rem;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${colors.gray};
  }

  & + & {
    position: relative;
    margin-top: 2rem;
    padding-top: 1rem;
  }

  ${Label} {
    margin-bottom: 0;
    text-align: left;
  }
  ${Text} {
    text-align: left;
    word-wrap: break-word;
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${colors.lighterGray};
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: ${colors.lighterGray};
      border: 1px solid ${colors.orange};
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: default;
      pointer-events: none;
    `}

  ${(props) =>
    props.current &&
    css`
      opacity: 1;
      border: 1px solid ${colors.green};
      cursor: default;
      pointer-events: none;
    `}
`;

const ColsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem 2rem;

  @media (min-width: ${breakpoints.mobileSmall}) {
    .span-2 {
      grid-column: span 2;
    }
  }
`;
