import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { suggestionsService } from 'services/suggestions';
// import { systemMessageSchema } from 'utils/validation';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import InputAuto from 'components/UI/InputAuto';
import Input from 'components/UI/Input';
import UsersList from 'components/layout/UsersList';
import { mapCronSlugToOptions } from 'config/constants';
import { adminService } from 'services/admin';

const parseValObjToArrayIds = options => {
  const tmp = {};
  Object.entries(options).forEach(([key, val]) => {
    if (Array.isArray(val)) tmp[key] = val.map(obj => obj.id);
    else tmp[key] = val;
  });
  return tmp;
};

const prepareInitialValues = (options, savedOptions) => {
  const tmp = {};
  const parsed = parseValObjToArrayIds(savedOptions);
  Object.entries(options).forEach(([key, value]) => {
    let tmpVal = '';
    if (value.type === 'array') tmpVal = [];
    if (value.type === 'boolean') tmpVal = value.initialValue || false;
    tmp[key] = tmpVal;
  });
  return { ...tmp, ...parsed };
};

const CronSettings = ({ cron, options, closePanel }) => {
  const initialValues = prepareInitialValues(options || {}, cron.options || {});
  const [suggestions, setSuggestions] = useState({});
  const [pickedUsers, setPickedUsers] = useState({ ...cron.options });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReset = resetForm => {
    setSuccess(false);
    setError('');
    resetForm({});
    setPickedUsers({});
  };

  const handleSubmit = values => {
    setError('');
    setLoading(true);

    const newValues = { ...values };

    Object.keys(values)
      .filter(name => name.match(/_DELETE_/gi))
      .forEach(key => delete newValues[key]);

    // Object.entries(newValues).forEach(([key, val]) => {
    //     console.log(key, val)
    //   if (Array.isArray(val)) newValues[key] = JSON.stringify(val);
    // });

    adminService
      .editCronSettings(cron.id, newValues)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  const removeUserFromPicked = (id, fieldName) =>
    setPickedUsers(state => ({ ...state, [fieldName]: state[fieldName].filter(user => user.id !== id) }));

  const fetchUsers = (inputValue, slug, fieldName) => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue, slug)
      .then(payload => setSuggestions(s => ({ ...s, [fieldName]: payload.data })))
      .catch(err => {
        console.log(err);
      });
  };

  const renderField = (slug, fieldName, utils) => {
    let field = (mapCronSlugToOptions.ALL && mapCronSlugToOptions.ALL[fieldName]) || false;
    if (!field) field = (mapCronSlugToOptions[slug] && mapCronSlugToOptions[slug][fieldName]) || false;

    let fieldJsx = null;

    if (['text', 'number'].includes(field.type)) {
      fieldJsx = (
        <>
          <Input
            name={fieldName}
            type="text"
            onChange={utils.handleChange}
            onBlur={utils.handleBlur}
            label={field.label || ''}
            value={utils.values[fieldName]}
            error={utils.touched[fieldName] && utils.errors[fieldName]}
          />
          <ErrorMessage component={ErrorLabel} name={fieldName} />
        </>
      );
    }

    if (field.type === 'array' && field.fetchUsers) {
      fieldJsx = (
        <>
          <InputAuto
            suggestions={suggestions[fieldName] || []}
            setFieldValue={utils.setFieldValue}
            setFieldTouched={utils.setFieldTouched}
            onlyFromList
            pickProp="id"
            name={`${fieldName}_DELETE_Search`}
            type="text"
            display={['name', 'surname', 'email']}
            onBlur={utils.handleBlur}
            onChange={e => fetchUsers(e.target.value, field.fetchUsers, fieldName)}
            onSelect={(val, item) => {
              if (!utils.values[fieldName].some(id => id === item.id)) {
                utils.setFieldValue(fieldName, [...utils.values[fieldName], item.id]);
                setPickedUsers(state => ({ ...state, [fieldName]: [...(state[fieldName] || []), item] }));
              }
              utils.setFieldValue(`${fieldName}_DELETE_Search`, '');
            }}
            label={field.label || 'Szukaj użytkownika'}
            value={utils.values[`${fieldName}_DELETE_Search`] || ''}
            error={utils.touched[`${fieldName}_DELETE_Search`] && utils.errors[`${fieldName}_DELETE_Search`]}
          />
          <ErrorMessage component={ErrorLabel} name={fieldName} />
          <UsersList
            users={pickedUsers[fieldName] || []}
            onRemove={id => {
              utils.setFieldValue(
                fieldName,
                utils.values[fieldName].filter(el => el !== id),
              );
              removeUserFromPicked(id, fieldName);
            }}
          />
        </>
      );
    }

    return (
      <div key={fieldName}>
        {field.heading && (
          <FieldHeading big size="m">
            {field.heading}
          </FieldHeading>
        )}
        {fieldJsx}
      </div>
    );
  };

  return (
    <Wrapper>
      {loading && <Loading absolute />}
      <Heading size="xl">Ustawienia</Heading>
      <Formik
        initialValues={initialValues}
        // validationSchema={systemMessageSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>Ustawienia zostały zapisane</Heading>
                {typeof success !== 'boolean' && success}
                <div className="buttons">
                  <Button
                    onClick={() => {
                      handleReset(resetForm);
                      closePanel();
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Błąd, coś poszło nie tak</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReset(resetForm)}>Spróbuj ponownie</Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              {Object.keys(initialValues).map(name =>
                renderField(cron.slug, name, {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  resetForm,
                }),
              )}
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Wyślij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

CronSettings.defaultProps = {};

CronSettings.propTypes = {
  closePanel: PropTypes.func.isRequired,
  cron: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
};

export default CronSettings;

// styled components

const Wrapper = styled(FormWrapperCommon)``;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
