import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Portal from 'components/logic/Portal';
import SuccessPopup from 'components/layout/SuccessPopup';
import Button from './Button';
import Heading from './Heading';

const TooltipModal = ({ children, title }) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <>
      <MarkWrapper onClick={() => setVisible(true)}>
        <QuestionSign icon="question" />
      </MarkWrapper>
      {isVisible && (
        <Portal>
          <StyledSuccessPopup fixed>
            {title && <Heading style={{ marginBottom: '2rem' }}>{title}</Heading>}
            {children}
            <div className="buttons" style={{ marginTop: '4rem' }}>
              <Button small secondary onClick={() => setVisible(false)}>
                Zamknij
              </Button>
            </div>
          </StyledSuccessPopup>
        </Portal>
      )}
    </>
  );
};

TooltipModal.defaultProps = {
  children: '',
  title: false,
};

TooltipModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
};

export default TooltipModal;

const MarkWrapper = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -60%);
    width: 2em;
    height: 2em;
  }

  &:hover {
    opacity: 1;
  }
`;
const QuestionSign = styled(FontAwesomeIcon)`
  position: relative;
  color: ${colors.orange};
  opacity: 0.75;
  margin-left: 5px;
  font-size: 1rem;
  vertical-align: text-top;
  transition: opacity 0.3s;
`;

const StyledSuccessPopup = styled(SuccessPopup)`
  div.content {
    max-height: 600px;
    overflow: auto;
    padding-right: 2rem;

    p {
      text-align: left;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }

    ul,
    ol {
      margin-left: 1em;

      li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
    }
  }
`;
