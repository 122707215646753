/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableWrapperCommon, TableCommon } from 'components/layout/Tables/TableComponents';
import { colors } from 'theme/styles';
import { displayDate, displayTime, formatMoney, resolveObjProp } from 'utils/helpers';
import SimpleButton from 'components/UI/SimpleButton';
import NoData from 'components/UI/NoData';
import Loading from 'components/UI/Loading';

const BillingsTable = ({ headers, data, offset, handleDetails, isLoading }) => (
  <TableWrapperCommon>
    <Table>
      <colgroup>
        <col className="narrow" />
        {headers.map(header => (
          <col key={header.property} />
        ))}
        <col className="wide" />
      </colgroup>
      <thead>
        <tr>
          <th>LP</th>
          {headers.map(header => (
            <th key={header.property}>{header.displayName}</th>
          ))}
          <th key="edit">Akcja</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 &&
          !isLoading &&
          data.map((item, index) => {
            const type = item.FvN_ID ? 'template' : 'invoice';
            return (
              <tr key={item.id || index}>
                <td className="center">{offset + index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = item.id + header.property;
                  let propValue = resolveObjProp(header.property, item);

                  if (header.type === 'date' && propValue) propValue = displayDate(propValue);
                  if (header.type === 'time' && propValue) propValue = displayTime(propValue);
                  if (header.type === 'currency') propValue = formatMoney(propValue);

                  if (header.property === 'RoN_RodzajRozliczenia' && propValue) {
                    const str = String(propValue).trim();
                    propValue = str.charAt(0).toUpperCase() + str.slice(1);
                  }

                  return (
                    <td className={header.class} key={uniqueKey}>
                      {propValue || '—'}
                    </td>
                  );
                })}
                <td key="edit" className="center">
                  <SimpleButton type="button" onClick={() => handleDetails(item)}>
                    Szczegóły
                  </SimpleButton>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
    {isLoading && <Loading />}
    {!isLoading && data.length === 0 && <NoData />}
  </TableWrapperCommon>
);

BillingsTable.defaultProps = {
  offset: 0,
};

BillingsTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  handleDetails: PropTypes.func.isRequired,
};

export default BillingsTable;

const Table = styled(TableCommon)`
  thead {
    tr {
      height: 50px;
      border-top: 1px solid ${colors.gray};
      border-bottom: 1px solid ${colors.gray};
    }
  }

  tbody {
    tr {
      cursor: default;
    }

    td {
      text-align: center;

      &.right {
        text-align: right;
      }

      &.left {
        text-align: left;
      }
    }
  }
`;
