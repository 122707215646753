import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, font } from 'theme/styles';

const HorizontalLine = ({ title, ...rest }) => {
  if (title) return <Hr {...rest}>{title && <span>{title}</span>}</Hr>;
  return <SoloHr {...rest} />;
};

HorizontalLine.defaultProps = {
  title: null,
};

HorizontalLine.propTypes = {
  title: PropTypes.string,
};

const SoloHr = styled.div`
  width: 100%;
  height: 1px;
  margin: 0.5em 0;
  overflow: hidden;
  background-color: ${colors.gray};
`;
const Hr = styled.div`
  width: 100%;
  margin: 0.5em 0;
  overflow: hidden;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    position: relative;
    display: inline-block;
    font-size: ${font.size.small};
    padding: 0 0.5em;
    letter-spacing: 1px;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 50vw;
      background-color: ${colors.gray};
      position: absolute;
      top: 50%;
      height: 1px;
    }

    &::before {
      right: 100%;
    }
    &::after {
      left: 100%;
    }
  }
`;

export default HorizontalLine;
