import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { adminService } from 'services/admin';
import SuccessPopup from 'components/layout/SuccessPopup';
import CloseButton from 'components/UI/CloseButton';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';

const LoginAsMaster = ({ loginAs }) => {
  const [loginAsData, setLoginAsData] = useState(loginAs);
  const [masterPassword, setMasterPassword] = useState('');

  const loginAsCb = useCallback(() => {
    if (!loginAsData) return;

    const data = {
      userId: loginAsData.id,
      masterPassword,
    };

    adminService
      .loginAs(data)
      .then(res => {
        console.log(res);
        window.location.pathname = '/';
      })
      .catch(err => {
        console.log(err);
        setLoginAsData('');
        setMasterPassword('');
      });
  }, [loginAsData, masterPassword]);

  return (
    <>
      {loginAsData && (
        <SuccessPopup fixed>
          <CloseButton
            onClick={() => {
              setLoginAsData('');
              setMasterPassword('');
            }}
          />
          <Heading>Zaloguj jako </Heading>
          <p>
            {loginAsData.fullName || loginAsData.name} - {loginAsData.email}
          </p>
          <div style={{ width: '25rem' }}>
            <Input
              name="masterPassword"
              type="password"
              onChange={e => setMasterPassword(e.target.value)}
              onBlur={() => {}}
              label="Master-password"
              value={masterPassword}
              error={false}
            />
          </div>
          <div className="buttons">
            <Button onClick={() => loginAsCb()}>Zaloguj</Button>
          </div>
        </SuccessPopup>
      )}
    </>
  );
};

LoginAsMaster.defaultProps = {
  loginAs: '',
};

LoginAsMaster.propTypes = {
  loginAs: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default LoginAsMaster;
