import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { removeNulls } from 'utils/helpers';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import InputAuto from 'components/UI/InputAuto';
import { zipcodeSchema } from 'utils/validation';
import { regionService } from 'services/region';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';

const AddZipcode = ({ countiesSuggestions, onSuccess }) => {
  const [codes, setCodes] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const checkDuplicates = (code, codes) => codes.some(el => el === code);

  const handleSubmit = values => {
    setError('');
    setLoading(true);

    if (checkDuplicates(values.postCode, codes)) {
      setLoading(false);
      setError('Wykryto duplikat');
    } else {
      const newValues = removeNulls(values);
      regionService
        .updateCountyCodes({
          countyId: values.county,
          postCodes: JSON.stringify([...codes, newValues.postCode]),
        })
        .then(() => {
          setLoading(false);
          setSuccess(true);
          setCodes([]);
          onSuccess(values.county);
        })
        .catch(err => {
          setLoading(false);
          setError(err.response.data.error.message);
          console.dir(err);
        });
    }
  };

  const handleAfterSelect = countyId => {
    regionService
      .getCountyCodes(countyId)
      .then(res => {
        setCodes(res.data.PostCodes.map(code => code.postCode));
      })
      .catch(err => {
        setError(err.response.data.error.message);
        console.dir(err);
      });
  };

  return (
    <Wrapper>
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Dodaj kod pocztowy</Heading>
      <Formik
        initialValues={{
          county: '',
          postCode: '',
        }}
        validationSchema={zipcodeSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values);
          setSubmitting(false);
          resetForm({});
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>Dodałeś kod pocztowy</Heading>
                <div className="buttons">
                  <Button onClick={() => handleReturn(true, resetForm)}>Wróć</Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Błąd dodawania kodu pocztowego</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                Wprowadź dane:
              </FieldHeading>
              <InputAuto
                suggestions={countiesSuggestions}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onlyFromList
                pickProp="id"
                name="county"
                type="text"
                display={['name']}
                onBlur={handleBlur}
                afterSelect={handleAfterSelect}
                label="Wybierz powiat"
                value={values.county || ''}
                error={touched.county && errors.county}
              />
              <ErrorMessage component={ErrorLabel} name="county" />
              <Input
                name="postCode"
                type="text"
                onChange={handleChange}
                disabled={!values.county}
                onBlur={handleBlur}
                label="Nowy kod pocztowy"
                value={values.postCode || ''}
                error={touched.postCode && errors.postCode}
              />
              <ErrorMessage component={ErrorLabel} name="postCode" />
              {codes.length > 0 && (
                <p>
                  Ilość kodów pocztowych: <strong>{codes.length}</strong>
                </p>
              )}
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Aktualizuj
              </Button>
            </StyledForm>
            <p>
              <strong>UWAGA</strong> - dodawanie kodu pocztowego jest operacją czasochłonną, może ona zająć nawet kilka
              minut.
            </p>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

AddZipcode.defaultProps = {
  onSuccess: () => {}
}

AddZipcode.propTypes = {
  countiesSuggestions: PropTypes.instanceOf(Array).isRequired,
  onSuccess: PropTypes.func,
};

export default AddZipcode;

// styled components

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
