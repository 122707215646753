import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, font } from 'theme/styles';
import nextIcon from 'assets/icons/caret-right-solid.svg';
import prevIcon from 'assets/icons/caret-left-solid.svg';
import lastIcon from 'assets/icons/step-forward-solid.svg';
import firstIcon from 'assets/icons/step-backward-solid.svg';

const Pagination = ({ maxPages, currentPage, handlePage, allowPageSet }) => {
  const [selectPage, setSelectPage] = useState(currentPage);

  useEffect(() => {
    setSelectPage(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < maxPages) {
      handlePage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    if (currentPage < maxPages) {
      handlePage(maxPages);
    }
  };
  const handleFirstPage = () => {
    if (currentPage > 1) {
      handlePage(1);
    }
  };
  const handlePageChange = e => {
    setSelectPage(e.target.value);
  };

  const handlePageChangeSubmit = e => {
    if ((e.type === 'keyup' && e.keyCode === 13) || e.type === 'blur') {
      const page = parseInt(selectPage, 10);

      if (page > maxPages) {
        setSelectPage(maxPages);
        handlePage(maxPages);
      } else if (page < 1) {
        setSelectPage(1);
        handlePage(1);
      } else {
        setSelectPage(page);
        handlePage(page);
      }
    }
  };

  return (
    <Wrapper>
      <ButtonIcon smaller src={firstIcon} onClick={handleFirstPage} />
      <ButtonIcon src={prevIcon} onClick={handlePrevPage} />
      {allowPageSet ? (
        <PagePicker
          value={selectPage}
          type="number"
          onChange={handlePageChange}
          onKeyUp={handlePageChangeSubmit}
          onBlur={handlePageChangeSubmit}
          className="page-number"
        />
      ) : (
        <span className="page-number">{currentPage}</span>
      )}
      <span>z</span>
      <span className="page-number">{maxPages}</span>
      <ButtonIcon src={nextIcon} onClick={handleNextPage} />
      <ButtonIcon smaller src={lastIcon} onClick={handleLastPage} />
    </Wrapper>
  );
};

Pagination.defaultProps = {
  allowPageSet: false,
};

Pagination.propTypes = {
  maxPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
  allowPageSet: PropTypes.bool,
};

export default Pagination;

// styled components

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: ${font.size.s};
    &.page-number {
      text-decoration: underline;
      margin: 0 5px;
    }
  }
  @media (max-width: 500px) {
    margin: 10px 0 7px 0;
  }
`;

const ButtonIcon = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  width: 18px;
  height: 18px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: ${({ smaller }) => (smaller ? '70% 70%' : '100% 100%')};
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const PagePicker = styled.input`
  display: inline-block;
  margin-right: 5px;
  border: none;
  border-bottom: 1px solid ${colors.darkGray};
  width: 3rem;
  text-align: center;
  margin-bottom: -2px;
  background-color: white;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
