import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearClearity,
  clearClearityCrmCheck,
  crmCheck,
  graylogCheck,
  setClearityError,
  setClearityLoading,
} from 'redux/admin';
import { ErrorMessage, Form, Formik } from 'formik';
import { colors, font } from 'theme/styles';
import { crmCheckSchema, graylogCheckSchema, leacCrmStatusCheckSchema } from 'utils/validation';
import { TopSection } from 'components/layout/Tables/TableComponents';
import CrmAccountDisplay from 'components/layout/CrmAccountDisplay';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import Heading from 'components/UI/Heading';
import Block from 'components/UI/Block';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import GraylogLogDisplay from 'components/layout/GraylogLogDisplay';
import { adminService } from 'services/admin';

// eslint-disable-next-line no-undef
const parser = new DOMParser();

const tabs = {
  crmAccount: 'crmAccount',
  graylog: 'graylog',
  crmLeadStatusUpdate: 'crmLeadStatusUpdate',
};

const AdminClarityPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [openTabs, setOpenTabs] = useState({ crmAccount: true, graylog: true, crmLeadStatusUpdate: true });
  const [accounts, setAccounts] = useState(null);
  const [accountsError, setAccountsError] = useState({ code: null, message: null, error: false });

  const [crmLeadStatusResponse, setCrmLeadStatusResponse] = useState(null);

  const userRole = useSelector((state) => state.user.data.role.slug);
  const isLoading = useSelector((s) => s.admin.clarity.isLoading);
  const error = useSelector((s) => s.admin.clarity.error);
  const crmCheckResult = useSelector((s) => s.admin.clarity.crmCheckResult);
  const graylogResult = useSelector((s) => s.admin.clarity.graylogResult);

  const nip = location.state?.nip;
  const graylogId = location.state?.graylogId;

  useEffect(() => {
    if (!crmCheckResult) return;
    const doc = parser.parseFromString(crmCheckResult, 'application/xml');
    const errorNode = doc.querySelector('parsererror');
    if (errorNode) {
      console.log('Błąd podczas parsowania XML');
      dispatch(setClearityError('Błąd podczas parsowania XML'));
    } else {
      const accounts = Array.from(doc.querySelectorAll('Accounts Account'));
      accounts.forEach((acc) => (acc.id = acc.querySelector('Id').textContent));
      const errorMessage = doc.querySelector('status error_message');
      const errorCode = doc.querySelector('status error_code');
      const error = doc.querySelector('status error');
      if (accounts.length > 0) setAccounts(accounts);
      if (error)
        setAccountsError((s) => ({
          ...s,
          error: error.textContent === 'True',
          message: errorMessage.textContent,
          code: errorCode.textContent,
        }));
    }
  }, [crmCheckResult, dispatch]);

  const handleCrmSearch = useCallback(
    ({ nip }) => {
      setAccounts(null);
      setAccountsError({ code: null, message: null, error: false });
      dispatch(crmCheck(nip));
    },
    [dispatch],
  );

  const handleGraylogSearch = useCallback(
    ({ graylogId }) => {
      dispatch(graylogCheck(graylogId));
    },
    [dispatch],
  );

  const handleManualUpdateLeadCrmStatus = useCallback(
    (leadId) => {
      dispatch(setClearityLoading(true));

      adminService
        .crmLeadStatusUpdateById(leadId)
        .then(setCrmLeadStatusResponse)
        .catch(setCrmLeadStatusResponse)
        .finally(() => dispatch(setClearityLoading(false)));
    },
    [dispatch],
  );

  const openTab = (id) => {
    const isOpen = !!openTabs[id];
    setOpenTabs((state) => {
      return { ...state, [id]: !isOpen };
    });
  };

  useEffect(() => {
    if (nip) handleCrmSearch({ nip });
    window.history.replaceState({}, document.title);
  }, [nip, handleCrmSearch]);

  useEffect(() => {
    return () => dispatch(clearClearity());
  }, [dispatch]);

  // const renderNode = nodeEl => {
  //   const childNodes = Array.from(nodeEl.children);
  //   return (
  //     <Account key={nodeEl.textContent}>
  //       {childNodes &&
  //         childNodes.length > 0 &&
  //         childNodes.map(n => (
  //           <AccoutNode key={n.tagName}>
  //             <span>{n.tagName}:</span> {n.children.length > 0 ? renderNode(n) : n.textContent || '-'}
  //           </AccoutNode>
  //         ))}
  //     </Account>
  //   );
  // };

  return (
    <PageTemplate>
      {isLoading && <Loading absolute />}
      {!isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => dispatch(clearClearityCrmCheck())}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}
      <TopSection style={{ marginBottom: '60px' }}>
        <div>
          <Heading size="xxl">Funkcje weryfikacyjne</Heading>
        </div>
      </TopSection>
      <Wrapper>
        <Block>
          <StyledHeading size="l">
            <span>Zweryfikuj konto w CRM</span>
            <ShowMore onClick={() => openTab(tabs.crmAccount)}>
              {openTabs[tabs.crmAccount] ? 'Zwiń' : 'Rozwiń'}
            </ShowMore>
          </StyledHeading>
          <Tab isOpen={openTabs[tabs.crmAccount]}>
            <Formik
              validationSchema={crmCheckSchema}
              initialValues={{
                nip: nip || '',
              }}
              onSubmit={(values, { resetForm }) => {
                handleCrmSearch(values);
                resetForm({});
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <StyledForm>
                  <FieldWrapper>
                    <div>
                      <Input
                        name="nip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Szukaj w CRM"
                        value={values.nip || ''}
                        error={touched.nip && errors.nip}
                      />
                      <ErrorMessage component={ErrorLabel} name="nip" />
                    </div>
                    <Button small type="submit">
                      Szukaj
                    </Button>
                  </FieldWrapper>
                </StyledForm>
              )}
            </Formik>
            {accountsError.error && (
              <Account>
                <AccoutNode>
                  <span>Kod błędu:</span> {accountsError.code || '-'}
                </AccoutNode>
                <AccoutNode>
                  <span>Błąd:</span> {accountsError.message || '-'}
                </AccoutNode>
              </Account>
            )}
            {accounts && accounts.length > 0 && accounts.map((acc) => <CrmAccountDisplay key={acc.id} acc={acc} />)}
          </Tab>
        </Block>
        {['admin'].includes(userRole) && (
          <>
            <Block>
              <StyledHeading size="l">
                <span>Zweryfikuj kod błędu</span>
                <ShowMore onClick={() => openTab(tabs.graylog)}>{openTabs[tabs.graylog] ? 'Zwiń' : 'Rozwiń'}</ShowMore>
              </StyledHeading>
              <Tab isOpen={openTabs[tabs.graylog]}>
                <Formik
                  validationSchema={graylogCheckSchema}
                  initialValues={{
                    graylogId: graylogId || '',
                  }}
                  onSubmit={(values, { resetForm }) => {
                    handleGraylogSearch(values);
                    resetForm({});
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <StyledForm>
                      <FieldWrapper>
                        <div>
                          <Input
                            name="graylogId"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Szukaj w graylogu"
                            value={values.graylogId || ''}
                            error={touched.graylogId && errors.graylogId}
                          />
                          <ErrorMessage component={ErrorLabel} name="graylogId" />
                        </div>
                        <Button small type="submit">
                          Szukaj
                        </Button>
                      </FieldWrapper>
                    </StyledForm>
                  )}
                </Formik>
                {graylogResult &&
                  graylogResult.length > 0 &&
                  graylogResult.map((log) => <GraylogLogDisplay key={log.timestamp} log={log} />)}
              </Tab>
            </Block>
            <Block>
              <StyledHeading size="l">
                <span>Weryfikacja statusu leada w CRM</span>
                <ShowMore onClick={() => openTab(tabs.crmLeadStatusUpdate)}>
                  {openTabs[tabs.crmLeadStatusUpdate] ? 'Zwiń' : 'Rozwiń'}
                </ShowMore>
              </StyledHeading>
              <Tab isOpen={openTabs[tabs.crmLeadStatusUpdate]}>
                <Formik
                  validationSchema={leacCrmStatusCheckSchema}
                  initialValues={{
                    leadId: '',
                  }}
                  onSubmit={(values, { resetForm }) => {
                    handleManualUpdateLeadCrmStatus(values.leadId);
                    resetForm({});
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <StyledForm>
                      <FieldWrapper>
                        <div>
                          <Input
                            name="leadId"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Podaj ID leada"
                            value={values.leadId || ''}
                            error={touched.leadId && errors.leadId}
                          />
                          <ErrorMessage component={ErrorLabel} name="leadId" />
                        </div>
                        <Button small type="submit">
                          Szukaj
                        </Button>
                      </FieldWrapper>
                    </StyledForm>
                  )}
                </Formik>
                {crmLeadStatusResponse ? (
                  <div style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(crmLeadStatusResponse, null, 4)}</div>
                ) : null}
              </Tab>
            </Block>
          </>
        )}
      </Wrapper>
    </PageTemplate>
  );
};

AdminClarityPage.defaultProps = {};

AdminClarityPage.propTypes = {};

export default AdminClarityPage;

const Wrapper = styled.div`
  margin-bottom: 8rem;

  & > * + * {
    margin-top: 4rem;
  }
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem 4rem;
`;

const Tab = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  margin-top: 2rem;
`;

const ShowMore = styled.span`
  margin-left: 4rem;
  color: ${colors.orange};
  font-weight: ${font.weight.semibold};
  cursor: pointer;
  user-select: 'none';
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 50rem;
  gap: 2rem 4rem;
`;

const Account = styled.div`
  margin-top: 2rem;

  & div {
    margin-top: 0;
  }

  & + & {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid ${colors.blueGray};
  }
`;

const AccoutNode = styled.div`
  letter-spacing: 0.4px;
  font-weight: ${font.weight.semibold};
  font-size: ${font.size.m};

  span {
    display: inline-block;
    font-weight: ${font.weight.bold};
    color: ${colors.orange};
    margin-right: 0.5rem;
  }

  div > div {
    margin-left: 4rem;

    &:first-child {
      margin-top: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }
`;
