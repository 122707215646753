import React, { useReducer } from 'react';
import SuccessPopup from 'components/layout/SuccessPopup';
import Portal from 'components/logic/Portal';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import Loading from 'components/UI/Loading';
import { createLink } from 'utils/helpers';

const initialState = {
  exporting: false,
  success: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        exporting: true,
        success: null,
        error: null,
      };
    case 'error':
      return {
        ...state,
        exporting: false,
        success: false,
        error: action.payload || true,
      };
    case 'reset':
      return {
        ...state,
        exporting: false,
        success: null,
        error: null,
      };
    default:
      return state;
  }
};

export default ({ handleCancel, handleExport }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const confirm = () => {
    dispatch({ type: 'reset' });
  };

  const exportFn = params => {
    dispatch({ type: 'start' });

    handleExport(params)
      .then(res => {
        createLink(res, 'program-polecen');
        confirm();
      })
      .catch(err => {
        console.error(err);
        if (err.response && err.response.status === 404) dispatch({ type: 'error', payload: 'Brak danych do exportu' });
        else dispatch({ type: 'error', payload: 'Coś poszło nie tak. Skontaktuj się z administratorem.' });
      });
  };

  const cancel = () => {
    confirm();
    handleCancel();
  };

  const Popup = () => (
    <Portal>
      {state.error && (
        <SuccessPopup>
          <Heading>{state.error}</Heading>
          <div className="buttons">
            <Button onClick={confirm}>Ok</Button>
          </div>
        </SuccessPopup>
      )}
      {state.exporting && (
        <SuccessPopup>
          <Heading>Eksportuje...</Heading>
          <p>
            Ta operacja może potrwać nawet kilka minut.
            <br />
            Nie zamykaj okna / karty przeglądarki.
          </p>
          <Loading />
          <div className="buttons">
            <Button onClick={cancel}>Anuluj</Button>
          </div>
        </SuccessPopup>
      )}
    </Portal>
  );

  return [Popup, exportFn, state];
};
