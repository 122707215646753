/* eslint-disable no-param-reassign */
import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { nationalities, provinces } from 'config/constants';
import { FieldHeading, FlexFields } from 'components/forms/FormComponents';
import { Block } from 'components/UI/Structure';
import { replaceAccents } from 'utils/helpers';
import { PEP, PEPAssoc } from './DrukAML';
import options from 'components/forms/LeadMerchant/others/config';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import Select from 'components/UI/Select';
import Textarea from 'components/UI/Textarea';
import Radio from 'components/UI/Radio';
import NumberInput from 'components/UI/NumberInput';
import CloseBtn from 'components/UI/CloseBtn';
import Tooltip from 'components/UI/Tooltip';
import Checkbox from 'components/UI/Checkbox';
import DateTimePicker from 'components/UI/DateTimePicker';
import InputAuto from 'components/UI/InputAuto';
import Chip from 'components/UI/Chip';
import TooltipModal from 'components/UI/TooltipModal';
// import Heading from 'components/UI/Heading';
// import CloseBtn from 'components/UI/CloseBtn';

export const formatMerchantValues = (values) => {
  // eslint-disable-next-line no-unused-vars
  const points = values.points.map(({ billingSystemCopy, ...otherProps }) => otherProps);
  const newValues = { ...values, points };
  newValues.settlements = { ...values.settlements };
  newValues.contact = { ...values.contact };
  delete newValues.settlements.agreementFile;
  delete newValues.settlements.agreement;
  delete newValues.contact.legalFormCopy;
  delete newValues.contact.versionNumberCopy;
  delete newValues.contact.agreementFile;
  delete newValues.contact.agreement;
  delete newValues.files;
  return newValues;
};

export const canSave = (touched, errors) => {
  let result = false;
  const mapObj = {};

  // Loop over touched (we consider only fields that user visited),
  // then check if error obj contain same prop, if so mark this in mapObj
  const loopOverObj = (tObj, eObj, tmpObj) => {
    Object.entries(tObj).forEach(([key, val]) => {
      if (val && typeof val === 'object' && eObj[key]) {
        tmpObj[key] = {};
        loopOverObj(tObj[key], eObj[key], tmpObj[key]);
      } else {
        tmpObj[key] = !!eObj[key];
      }
    });
  };

  // Loop over mapObj and check if any prop is marked as true, if so
  // change default result to true
  const mapObjToFlag = (obj) => {
    Object.entries(obj).forEach(([, val]) => {
      if (val && typeof val === 'object') mapObjToFlag(val);
      else if (!result) result = val;
    });
  };

  loopOverObj(touched, errors, mapObj);
  mapObjToFlag(mapObj);
  return !result;
};

export const placeFieldsRender = (
  name,
  subName,
  { values, touched, errors, handleChange, handleBlur, setFieldValue },
  isDisabled = false,
  arrayIndex,
) => {
  const isArrayField = arrayIndex !== undefined;

  return (
    <FlexFields cols={3}>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.streetName` : `${name}.${subName}.streetName`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.streetName` : `${name}.${subName}.streetName`,
              isArrayField
                ? values[arrayIndex][subName].streetName.trim() || ''
                : values[subName].streetName.trim() || '',
            );
          }}
          label="Nazwa ulicy"
          value={isArrayField ? values[arrayIndex][subName].streetName || '' : values[subName].streetName || ''}
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.streetName &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.streetName
              : touched[subName]?.streetName && errors[subName]?.streetName
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.streetName` : `${name}.${subName}.streetName`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.buildingNumber` : `${name}.${subName}.buildingNumber`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.buildingNumber` : `${name}.${subName}.buildingNumber`,
              isArrayField
                ? values[arrayIndex][subName].buildingNumber.trim() || ''
                : values[subName].buildingNumber.trim() || '',
            );
          }}
          label="Numer budynku"
          value={isArrayField ? values[arrayIndex][subName].buildingNumber || '' : values[subName].buildingNumber || ''}
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.buildingNumber &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.buildingNumber
              : touched[subName]?.buildingNumber && errors[subName]?.buildingNumber
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.buildingNumber` : `${name}.${subName}.buildingNumber`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={
            isArrayField ? `${name}.${arrayIndex}.${subName}.apartmentNumber` : `${name}.${subName}.apartmentNumber`
          }
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.apartmentNumber` : `${name}.${subName}.apartmentNumber`,
              isArrayField
                ? values[arrayIndex][subName].apartmentNumber.trim() || ''
                : values[subName].apartmentNumber.trim() || '',
            );
          }}
          label="Numer lokalu"
          value={
            isArrayField ? values[arrayIndex][subName].apartmentNumber || '' : values[subName].apartmentNumber || ''
          }
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.apartmentNumber &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.apartmentNumber
              : touched[subName]?.apartmentNumber && errors[subName]?.apartmentNumber
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={
            isArrayField ? `${name}.${arrayIndex}.${subName}.apartmentNumber` : `${name}.${subName}.apartmentNumber`
          }
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.city` : `${name}.${subName}.city`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.city` : `${name}.${subName}.city`,
              isArrayField ? values[arrayIndex][subName].city.trim() || '' : values[subName].city.trim() || '',
            );
          }}
          label="Miasto"
          value={isArrayField ? values[arrayIndex][subName].city || '' : values[subName].city || ''}
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.city &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.city
              : touched[subName]?.city && errors[subName]?.city
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.city` : `${name}.${subName}.city`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.zipCode` : `${name}.${subName}.zipCode`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.zipCode` : `${name}.${subName}.zipCode`,
              isArrayField ? values[arrayIndex][subName].zipCode.trim() || '' : values[subName].zipCode.trim() || '',
            );
          }}
          label="Kod pocztowy"
          value={isArrayField ? values[arrayIndex][subName].zipCode || '' : values[subName].zipCode || ''}
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.zipCode &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.zipCode
              : touched[subName]?.zipCode && errors[subName]?.zipCode
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.zipCode` : `${name}.${subName}.zipCode`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.country` : `${name}.${subName}.country`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.country` : `${name}.${subName}.country`,
              isArrayField ? values[arrayIndex][subName].country.trim() || '' : values[subName].country.trim() || '',
            );
          }}
          label="Kraj"
          value={isArrayField ? values[arrayIndex][subName].country || '' : values[subName].country || ''}
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.country &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.country
              : touched[subName]?.country && errors[subName]?.country
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.country` : `${name}.${subName}.country`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.county` : `${name}.${subName}.county`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.county` : `${name}.${subName}.county`,
              isArrayField ? values[arrayIndex][subName].county.trim() || '' : values[subName].county.trim() || '',
            );
          }}
          label="Powiat"
          value={isArrayField ? values[arrayIndex][subName].county || '' : values[subName].county || ''}
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.county &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.county
              : touched[subName]?.county && errors[subName]?.county
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.county` : `${name}.${subName}.county`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.community` : `${name}.${subName}.community`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.community` : `${name}.${subName}.community`,
              isArrayField
                ? values[arrayIndex][subName].community.trim() || ''
                : values[subName].community.trim() || '',
            );
          }}
          label="Gmina"
          value={isArrayField ? values[arrayIndex][subName].community || '' : values[subName].community || ''}
          error={
            isArrayField
              ? touched[arrayIndex] &&
                touched[arrayIndex][subName]?.community &&
                errors[arrayIndex] &&
                errors[arrayIndex][subName]?.community
              : touched[subName]?.community && errors[subName]?.community
          }
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.community` : `${name}.${subName}.community`}
        />
      </div>
      <div>
        <Select
          maxWidth="300px"
          disabled={isDisabled}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.voivodeship` : `${name}.${subName}.voivodeship`}
          value={
            isArrayField
              ? replaceAccents(values[arrayIndex][subName].voivodeship || '')
              : replaceAccents(values[subName].voivodeship || '')
          }
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${arrayIndex}.${subName}.voivodeship` : `${name}.${subName}.voivodeship`,
              isArrayField
                ? values[arrayIndex][subName].voivodeship.trim() || ''
                : values[subName].voivodeship.trim() || '',
            );
          }}
          label="Województwo"
        >
          <option value="">&nbsp;</option>
          {provinces.map((province) => (
            <option key={province.value} value={province.value}>
              {province.display}
            </option>
          ))}
        </Select>
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${arrayIndex}.${subName}.voivodeship` : `${name}.${subName}.voivodeship`}
        />
      </div>
    </FlexFields>
  );
};

export const contactPersonFieldsRender = (
  name,
  subName,
  { values, touched, errors, handleChange, handleBlur, setFieldValue },
  isDisabled = false,
  arrayIndex,
  arrayHelpers,
  minPersons,
  personFunctionList = options.personFunctionOptions,
  onRemoveSingatoryHandler,
) => {
  const isArrayField = arrayIndex !== undefined;

  return (
    <FlexFields cols={5} key={arrayIndex} separator={isArrayField}>
      {arrayIndex >= minPersons && !isDisabled && (
        <CloseBtn
          personPosition
          disabled={isDisabled}
          onClick={() => {
            arrayHelpers.remove(arrayIndex);
            if (onRemoveSingatoryHandler) onRemoveSingatoryHandler(arrayIndex);
          }}
        >
          Usuń
        </CloseBtn>
      )}
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.name` : `${name}.${subName}.name`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${subName}.${arrayIndex}.name` : `${name}.${subName}.name`,
              isArrayField ? values[subName][arrayIndex].name.trim() || '' : values[subName].name.trim() || '',
            );
          }}
          label="Imię"
          value={isArrayField ? values[subName][arrayIndex].name || '' : values[subName].name || ''}
          error={isArrayField ? false : touched[subName]?.name && errors[subName]?.name}
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.name` : `${name}.${subName}.name`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.lastname` : `${name}.${subName}.lastname`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${subName}.${arrayIndex}.lastname` : `${name}.${subName}.lastname`,
              isArrayField ? values[subName][arrayIndex].lastname.trim() || '' : values[subName].lastname.trim() || '',
            );
          }}
          label="Nazwisko"
          value={isArrayField ? values[subName][arrayIndex].lastname || '' : values[subName].lastname || ''}
          error={isArrayField ? false : touched[subName]?.lastname && errors[subName]?.lastname}
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.lastname` : `${name}.${subName}.lastname`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.email` : `${name}.${subName}.email`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${subName}.${arrayIndex}.email` : `${name}.${subName}.email`,
              isArrayField ? values[subName][arrayIndex].email.trim() || '' : values[subName].email.trim() || '',
            );
          }}
          label="Adres email"
          value={isArrayField ? values[subName][arrayIndex].email || '' : values[subName].email || ''}
          error={isArrayField ? false : touched[subName]?.email && errors[subName]?.email}
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.email` : `${name}.${subName}.email`}
        />
      </div>
      <div>
        <Input
          disabled={isDisabled}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.phone` : `${name}.${subName}.phone`}
          type="text"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(
              isArrayField ? `${name}.${subName}.${arrayIndex}.phone` : `${name}.${subName}.phone`,
              isArrayField ? values[subName][arrayIndex].phone.trim() || '' : values[subName].phone.trim() || '',
            );
          }}
          label="Telefon"
          value={isArrayField ? values[subName][arrayIndex].phone || '' : values[subName].phone || ''}
          error={isArrayField ? false : touched[subName]?.phone && errors[subName]?.phone}
        />
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.phone` : `${name}.${subName}.phone`}
        />
      </div>
      <div>
        <Select
          disabled={isDisabled}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.function` : `${name}.${subName}.function`}
          value={isArrayField ? values[subName][arrayIndex].function || '' : values[subName].function || ''}
          onChange={(e) => {
            setFieldValue(
              isArrayField ? `${name}.${subName}.${arrayIndex}.function` : `${name}.${subName}.function`,
              Number(e.target.value),
            );
          }}
          onBlur={handleBlur}
          label="Funkcja"
        >
          {personFunctionList.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.display}
            </option>
          ))}
        </Select>
        <ErrorMessage
          component={ErrorLabel}
          name={isArrayField ? `${name}.${subName}.${arrayIndex}.function` : `${name}.${subName}.function`}
        />
      </div>
    </FlexFields>
  );
};

export const terminalFieldsRender = (
  name,
  subName,
  { values, touched, errors, handleChange, handleBlur, setFieldValue },
  isDisabled = false,
  pointIndex,
  arrayIndex,
  terminalsArrHelpers,
  canUserInstallTerminal = false,
  allValues,
) => {
  return (
    <Block noInteract={isDisabled} inner key={arrayIndex} style={{ paddingBottom: '10px' }}>
      {/* {arrayIndex > 0 && !isDisabled && (
        <CloseBtn disabled={isDisabled} onClick={() => terminalsArrHelpers.remove(arrayIndex)} />
      )} */}
      {/* <Heading size="xl" style={{ position: 'absolute', right: '2rem' }}>
        <span className="orange">T{arrayIndex + 1}</span>
      </Heading> */}
      <FlexFields cols={2}>
        <div style={{ marginBottom: '5px' }}>
          <FieldHeading mbSmall size="s">
            Typ terminala
            <Tooltip width={240}>Należy wskazać preferowany typ terminala do dostarczenia</Tooltip>
          </FieldHeading>
          <FlexFields autoFit>
            {options.terminalTypeOptions.map((opt) => {
              const disableField = allValues.settlements.pbgPwob > 0 && opt.value === 3;
              return (
                <Radio
                  disabled={isDisabled || disableField}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name={`${name}.${pointIndex}.${subName}.${arrayIndex}.type`}
                  value={opt.value}
                  onChange={() => setFieldValue(`${name}.${pointIndex}.${subName}.${arrayIndex}.type`, opt.value)}
                  label={opt.display}
                  checked={values[pointIndex][subName][arrayIndex].type === opt.value}
                />
              );
            })}
          </FlexFields>
        </div>
        <div style={{ marginBottom: '5px' }}>
          <FieldHeading mbSmall size="s">
            Rodzaj łączności
            <Tooltip width={240}>
              Należy wskazać możliwy typ połączenia terminala z Internetem. Wybór GPRS jeśli w punkcie nie ma sieci WiFi
              lub Ethernet
            </Tooltip>
          </FieldHeading>
          <FlexFields autoFit>
            {options.terminalConnectionOptions.map((opt) => (
              <Radio
                disabled={isDisabled}
                tooltip={opt.tooltip}
                key={opt.value}
                name={`${name}.${pointIndex}.${subName}.${arrayIndex}.connectionType`}
                value={opt.value}
                onChange={() =>
                  setFieldValue(`${name}.${pointIndex}.${subName}.${arrayIndex}.connectionType`, opt.value)
                }
                label={opt.display}
                checked={values[pointIndex][subName][arrayIndex].connectionType === opt.value}
              />
            ))}
          </FlexFields>
        </div>
        {(canUserInstallTerminal || isDisabled) && (
          <div style={{ marginBottom: '5px' }}>
            <FieldHeading mbSmall size="s">
              Montaż terminala
            </FieldHeading>
            <FlexFields autoFit>
              {options.terminalInstallationOptions.map((opt) => (
                <Radio
                  tooltip={opt.tooltip}
                  disabled={isDisabled}
                  key={opt.value}
                  name={`${name}.${pointIndex}.${subName}.${arrayIndex}.installationType`}
                  value={opt.value}
                  onChange={() =>
                    setFieldValue(`${name}.${pointIndex}.${subName}.${arrayIndex}.installationType`, opt.value)
                  }
                  label={opt.display}
                  checked={values[pointIndex][subName][arrayIndex].installationType === opt.value}
                />
              ))}
            </FlexFields>
            <FieldHeading mbSmall size="s">
              Liczba terminali
            </FieldHeading>
            <Field
              disabled={isDisabled}
              name={`${name}.${pointIndex}.${subName}.${arrayIndex}.count`}
              component={NumberInput}
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${pointIndex}.${subName}.${arrayIndex}.count`} />
          </div>
        )}
        <div style={{ marginBottom: '5px' }}>
          <FieldHeading mbSmall size="s">
            Uwagi
          </FieldHeading>
          <Textarea
            disabled={isDisabled}
            name={`${name}.${pointIndex}.${subName}.${arrayIndex}.notes`}
            onChange={handleChange}
            onBlur={handleBlur}
            width="100%"
            locked
            height="92px"
            value={values[pointIndex][subName][arrayIndex].notes || ''}
            error={
              touched[pointIndex] &&
              touched[pointIndex][subName] &&
              touched[pointIndex][subName][arrayIndex]?.notes &&
              errors[pointIndex] &&
              errors[pointIndex][subName] &&
              errors[pointIndex][subName][arrayIndex]?.notes
            }
          />
          <ErrorMessage component={ErrorLabel} name={`${name}.${pointIndex}.${subName}.${arrayIndex}.notes`} />
        </div>
      </FlexFields>
    </Block>
  );
};

export const drukAmlFieldsRender = (
  name,
  subName,
  { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched },
  isDisabled = false,
  index,
) => {
  const errorChecker = (prop, nested) =>
    touched[subName] &&
    touched[subName][index] &&
    touched[subName][index][prop] &&
    touched[subName][index][prop][nested] &&
    errors[subName] &&
    errors[subName][index] &&
    errors[subName][index][prop] &&
    errors[subName][index][prop][nested];

  // Prevent undefined errors on some renders
  if (!values.signatory[index]) return null;

  return (
    <Block noInteract={isDisabled} inner key={index} style={{ paddingBottom: '10px' }}>
      <FieldHeading mbSmall size="m">
        Weryfikacja tożsamości dla:{' '}
        <span className="orange">
          {values.signatory[index].name} {values.signatory[index].lastname}
        </span>
      </FieldHeading>
      {/* 1 */}
      <FieldHeading noMargin size="s">
        Dane osoby podpisującej umowę
        {/* Czy osoba podpisująca umowę jest osobą upoważnioną do działania w imieniu Akceptanta?
        <TooltipModal title="Informacja">
          Osoba upoważniona do działania w imieniu Akceptanta – reprezentant; osoba umocowana do podpisania umowy.
        </TooltipModal> */}
      </FieldHeading>
      {/* <FlexFields autoFit>
        {options.genericYesNoOptions.map((opt) => (
          <Radio
            disabled={isDisabled}
            key={opt.value}
            name={`${name}.${subName}.${index}.isAuthorizedMerchant`}
            value={opt.value}
            onChange={(e) => {
              setFieldValue(`${name}.${subName}.${index}.isAuthorizedMerchant`, opt.value);
              // Clean if off
              if (e.target.value == 0) setFieldValue(`${name}.${subName}.${index}.merchant`, new AmlPerson());
            }}
            label={opt.display}
            checked={values[subName][index].isAuthorizedMerchant === opt.value}
          />
        ))}
      </FlexFields>
      <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.isAuthorizedMerchant`} /> */}
      {/* Jeżeli 1 = tak */}
      {values[subName][index].isAuthorizedMerchant === 1 && (
        <>
          <FlexFields cols={3}>
            <div>
              <Input
                disabled={isDisabled || !values[subName][index].merchant.peselExist}
                name={`${name}.${subName}.${index}.merchant.pesel`}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="PESEL"
                value={values[subName][index].merchant.pesel || ''}
                error={errorChecker('merchant', 'pesel')}
              />
              <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.merchant.pesel`} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                disabled={isDisabled}
                name={`${name}.${subName}.${index}.merchant.peselExist`}
                onChange={(e) => {
                  setFieldValue(
                    `${name}.${subName}.${index}.merchant.peselExist`,
                    !values[subName][index].merchant.peselExist,
                  );
                  if (e.target.value) setFieldValue(`${name}.${subName}.${index}.merchant.pesel`, '');
                }}
                onBlur={handleBlur}
                label="Nie posiadam nr PESEL"
                value={values[subName][index].merchant.peselExist}
                checked={!values[subName][index].merchant.peselExist}
              />
            </div>
            {!values[subName][index].merchant.peselExist && (
              <div>
                <FieldHeading noMargin style={{ lineHeight: '18px' }} size="s">
                  Data urodzenia
                </FieldHeading>
                <DateTimePicker
                  disabled={isDisabled}
                  onlyDays
                  wider
                  minDate={new Date('1900-01-01')}
                  handleDate={(date) => setFieldValue(`${name}.${subName}.${index}.merchant.birthdayDate`, date)}
                  initialDate={values[subName][index].merchant.birthdayDate || null}
                  resetOnInitialChange
                />
                <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.merchant.birthdayDate`} />
              </div>
            )}
          </FlexFields>
          <FlexFields cols={3} style={{marginBottom: '5px'}}>
            <div>
              <Input
                disabled={isDisabled}
                name={`${name}.${subName}.${index}.merchant.identityCardNumber`}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Seria i numer dokumentu tożsamości"
                value={values[subName][index].merchant.identityCardNumber || ''}
                error={errorChecker('merchant', 'identityCardNumber')}
              />
              <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.merchant.identityCardNumber`} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                disabled={isDisabled}
                name={`${name}.${subName}.${index}.merchant.identityCardExpirationDateExist`}
                onChange={(e) => {
                  setFieldValue(
                    `${name}.${subName}.${index}.merchant.identityCardExpirationDateExist`,
                    !values[subName][index].merchant.identityCardExpirationDateExist,
                  );
                  if (e.target.value)
                    setFieldValue(`${name}.${subName}.${index}.merchant.identityCardExpirationDate`, '');
                }}
                onBlur={handleBlur}
                label="Dokument bezterminowy"
                value={values[subName][index].merchant.identityCardExpirationDateExist}
                checked={!values[subName][index].merchant.identityCardExpirationDateExist}
              />
            </div>
            <div />
            <div>
              <FieldHeading noMargin style={{ lineHeight: '18px' }} size="s">
                Data wydania dokumentu tożsamości
              </FieldHeading>
              <DateTimePicker
                disabled={isDisabled}
                onlyDays
                wider
                minDate={new Date('1900-01-01')}
                handleDate={(date) =>
                  setFieldValue(`${name}.${subName}.${index}.merchant.identityCardReleaseDate`, date)
                }
                initialDate={values[subName][index].merchant.identityCardReleaseDate || null}
                resetOnInitialChange
              />
              <ErrorMessage
                component={ErrorLabel}
                name={`${name}.${subName}.${index}.merchant.identityCardReleaseDate`}
              />
            </div>
            <div>
              <FieldHeading noMargin style={{ lineHeight: '18px' }} size="s">
                Data ważności dokumentu tożsamości
              </FieldHeading>
              <DateTimePicker
                disabled={isDisabled || !values[subName][index].merchant.identityCardExpirationDateExist}
                onlyDays
                wider
                minDate={new Date('1900-01-01')}
                handleDate={(date) =>
                  setFieldValue(`${name}.${subName}.${index}.merchant.identityCardExpirationDate`, date)
                }
                initialDate={values[subName][index].merchant.identityCardExpirationDate || null}
                resetOnInitialChange
              />
              <ErrorMessage
                component={ErrorLabel}
                name={`${name}.${subName}.${index}.merchant.identityCardExpirationDate`}
              />
            </div>
          </FlexFields>
          <FlexFields cols={3}>
            <div>
              <Input
                disabled={isDisabled}
                name={`${name}.${subName}.${index}.merchant.countryOfBirth`}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Państwo urodzenia"
                value={values[subName][index].merchant.countryOfBirth || ''}
                error={errorChecker('merchant', 'countryOfBirth')}
              />
              <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.merchant.countryOfBirth`} />
            </div>
            <div>
              <InputAuto
                suggestions={nationalities}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onlyFromList
                pickProp="id"
                name="nationalitySearch"
                type="text"
                display={['display']}
                onBlur={handleBlur}
                label="Wybór obywatelstwa"
                value={''}
                error={false}
                onSelect={(val, item) => {
                  if (!values[subName][index].merchant.nationalities.some((n) => n.id === item.id)) {
                    setFieldValue(`${name}.${subName}.${index}.merchant.nationalities`, [
                      ...values[subName][index].merchant.nationalities,
                      item,
                    ]);
                  }
                  setFieldValue('nationalitySearch', '');
                }}
              />
            </div>
            <div>
              <FieldHeading noMargin size="s">
                Obywatelstwa
              </FieldHeading>
              {values[subName] &&
                values[subName][index] &&
                values[subName][index].merchant &&
                values[subName][index].merchant.nationalities &&
                values[subName][index].merchant.nationalities.map((nat) => (
                  <Chip
                    key={`${nat.id}`}
                    onClick={() => {
                      const newValue = values[subName][index].merchant.nationalities.filter((el) => el.id !== nat.id);
                      setFieldValue(`${name}.${subName}.${index}.merchant.nationalities`, newValue);
                    }}
                    hideDeleteBtn={nat.id === 'PL'}
                  >
                    {nat.id === 'PL' ? 'Polskie' : nat.display}
                  </Chip>
                ))}
            </div>
          </FlexFields>
        </>
      )}

      {/* 2 */}
      <FieldHeading mbSmall size="s">
        Czy osoba podpisująca umowę jest beneficjentem rzeczywistym?
        <TooltipModal title="Informacja">
          <div className="content">
            <p>
              Beneficjent rzeczywisty/końcowy – rozumie się każdą osobę fizyczną sprawującą bezpośrednio lub pośrednio
              kontrolę nad klientem poprzez posiadane uprawnienia, które wynikają z okoliczności prawnych lub
              faktycznych, umożliwiające wywieranie decydującego wpływu na czynności lub działania podejmowane przez
              klienta, lub każdą osobę fizyczną, w imieniu której są nawiązywane stosunki gospodarcze lub przeprowadzana
              jest transakcja okazjonalna, w tym:
            </p>
            <ol type="a">
              <li>
                w przypadku osoby prawnej innej niż spółka, której papiery wartościowe są dopuszczone do obrotu na rynku
                regulowanym podlegającym wymogom ujawniania informacji wynikającym z przepisów prawa Unii Europejskiej
                lub odpowiadających im przepisów prawa państwa trzeciego:
                <ul>
                  <li>
                    osobę fizyczną będącą udziałowcem lub akcjonariuszem, której przysługuje prawo własności więcej niż
                    25% ogólnej liczby udziałów lub akcji tej osoby prawnej
                  </li>
                  <li>
                    osobę fizyczną dysponującą więcej niż 25% ogólnej liczby głosów w organie stanowiącym klienta, także
                    jako zastawnik albo użytkownik, lub na podstawie porozumień z innymi uprawnionymi do głosu
                  </li>
                  <li>
                    osobę fizyczną sprawującą kontrolę nad osobą prawną lub osobami prawnymi, którym łącznie przysługuje
                    prawo własności więcej niż 25% ogólnej liczby udziałów lub akcji, lub które łącznie dysponują więcej
                    niż 25% ogólnej liczby głosów w organie stanowiącym tej osoby prawnej, także jako zastawnik albo
                    użytkownik, lub na podstawie porozumień z innymi uprawnionymi do głosu
                  </li>
                  <li>
                    osobę fizyczną sprawującą kontrolę nad osobą prawną poprzez posiadanie uprawnień, o których mowa w
                    art. 3 ust. 1 pkt 37 ustawy z dnia 29 września 1994 r. o rachunkowości (Dz. U. z 2021 r . poz. 217)
                  </li>
                  <li>
                    osobę fizyczną zajmującą wyższe stanowisko kierownicze w przypadku udokumentowanego braku możliwości
                    ustalenia lub wątpliwości co do tożsamości osób fizycznych określonych w tiret pierwszym, drugim,
                    trzecim i czwartym oraz w przypadku niestwierdzenia podejrzeń prania pieniędzy lub finansowania
                    terroryzmu
                  </li>
                </ul>
              </li>
              <li>
                w przypadku trustu:
                <ul>
                  <li>założyciela</li>
                  <li>powiernika</li>
                  <li>nadzorcę, jeżeli został ustanowiony</li>
                  <li>
                    beneficjenta lub – w przypadku gdy osoby fizyczne czerpiące korzyści z danego trustu nie zostały
                    jeszcze określone
                  </li>
                  <li>grupę osób, w których głównym interesie powstał lub działa trust</li>
                  <li>inną osobę sprawującą kontrolę nad trustem</li>
                  <li>
                    inną osobę fizyczną posiadającą uprawnienia lub wykonującą obowiązki równoważne z określonymi w
                    tiret pierwszym–piątym- beneficjenta
                  </li>
                  <li>inną osobę sprawująca kontrolę nad trustem</li>
                </ul>
              </li>
              <li>
                w przypadku osoby fizycznej prowadzącej działalność gospodarczą, wobec której nie stwierdzono przesłanek
                lub okoliczności mogących wskazywać na fakt sprawowania kontroli nad nią przez inną osobę fizyczną lub
                osoby fizyczne, przyjmuje się, że taka osoba fizyczna jest jednocześnie beneficjentem rzeczywistym
              </li>
            </ol>
          </div>
        </TooltipModal>
      </FieldHeading>
      <FlexFields autoFit>
        {options.genericYesNoOptions.map((opt) => (
          <Radio
            disabled={isDisabled}
            key={opt.value}
            name={`${name}.${subName}.${index}.isBeneficialOwner`}
            value={opt.value}
            onChange={() => setFieldValue(`${name}.${subName}.${index}.isBeneficialOwner`, opt.value)}
            label={opt.display}
            checked={values[subName][index].isBeneficialOwner === opt.value}
          />
        ))}
      </FlexFields>
      <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.isBeneficialOwner`} />

      {/* 3 */}
      <FieldHeading mbSmall size="s">
        Czy osoba podpisująca umowę jest osobą zajmującą eksponowane stanowisko polityczne, tzw. PEP (politically
        exposed person) ?
        <TooltipModal title="Informacja">
          <div className="content">
            <p>
              Osoba zajmująca eksponowane stanowiska polityczne – rozumie się przez to, z wyłączeniem grup stanowisk
              średniego i niższego szczebla, osoby zajmujące znaczące stanowiska publiczne lub pełniące znaczące funkcje
              publiczne, w tym:
            </p>
            <ul>
              <li>szefów państw, szefów rządów, ministrów, wiceministrów oraz sekretarzy stanu</li>
              <li>członków parlamentu lub podobnych organów ustawodawczych</li>
              <li>członków organów zarządzających partii politycznych</li>
              <li>
                członków sądów najwyższych, trybunałów konstytucyjnych oraz innych organów sądowych wysokiego szczebla,
                których decyzje nie podlegają zaskarżeniu, z wyjątkiem trybów nadzwyczajnych,
              </li>
              <li>członków trybunałów obrachunkowych lub zarządów banków centralnych</li>
              <li>ambasadorów, chargés d&apos;affaires oraz wyższych oficerów sił zbrojnych</li>
              <li>
                członków organów administracyjnych, zarządczych lub nadzorczych przedsiębiorstw państwowych, spółek z
                udziałem Skarbu Państwa, w których ponad połowa akcji albo udziałów należy do Skarbu Państwa lub innych
                państwowych osób prawnych,
              </li>
              <li>
                dyrektorów, zastępców dyrektorów oraz członków organów organizacji międzynarodowych lub osoby pełniące
                równoważne funkcje w tych organizacjach,
              </li>
              <li>
                dyrektorów generalnych w urzędach naczelnych i centralnych organów państwowych oraz dyrektorów
                generalnych urzędów wojewódzkich,
              </li>
              <li>
                inne osoby zajmujące stanowiska publiczne lub pełniące funkcje publiczne w organach państwa lub
                centralnych organach administracji rządowej;
              </li>
            </ul>
          </div>
        </TooltipModal>
      </FieldHeading>
      <FlexFields autoFit>
        {options.genericYesNoOptions.map((opt) => (
          <Radio
            disabled={isDisabled}
            key={opt.value}
            name={`${name}.${subName}.${index}.isPEP`}
            value={opt.value}
            onChange={(e) => {
              setFieldValue(`${name}.${subName}.${index}.isPEP`, opt.value);
              // Clean if off
              if (e.target.value == 0) setFieldValue(`${name}.${subName}.${index}.pep`, new PEP());
            }}
            label={opt.display}
            checked={values[subName][index].isPEP === opt.value}
          />
        ))}
      </FlexFields>
      <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.isPEP`} />
      {/* Jeżeli 3 = tak */}
      {values[subName][index].isPEP === 1 && (
        <FlexFields cols={3}>
          <div>
            <Input
              disabled={isDisabled}
              name={`${name}.${subName}.${index}.pep.position`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Zajmowane stanowisko"
              value={values[subName][index].pep.position || ''}
              error={errorChecker('pep', 'position')}
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.pep.position`} />
          </div>

          <div>
            <FieldHeading noMargin style={{ lineHeight: '18px' }} size="s">
              Data zwolnienia stanowiska
            </FieldHeading>
            <DateTimePicker
              disabled={isDisabled}
              onlyDays
              wider
              minDate={new Date('1900-01-01')}
              handleDate={(date) => setFieldValue(`${name}.${subName}.${index}.pep.positionReleaseDate`, date)}
              initialDate={values[subName][index].pep.positionReleaseDate || null}
              resetOnInitialChange
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.pep.positionReleaseDate`} />
          </div>
        </FlexFields>
      )}

      {/* 4 */}
      <FieldHeading mbSmall size="s">
        Czy osoba podpisująca umowę jest członkiem rodziny osoby zajmującej eksponowane stanowisko polityczne?
        <TooltipModal title="Informacja">
          <div className="content">
            <p>
              Członek rodziny osoby zajmującej eksponowane stanowisko polityczne – o polityczne – rozumie się przez to:
            </p>
            <ul>
              <li>
                małżonka lub osobę pozostającą we wspólnym pożyciu z osobą zajmującą eksponowane stanowisko polityczne
              </li>
              <li>
                dziecko osoby zajmującej eksponowane stanowisko polityczne i jego małżonka lub osoby pozostającej we
                wspólnym pożyciu
              </li>
              <li>rodziców osoby zajmującej eksponowane stanowisko polityczne</li>
            </ul>
          </div>
        </TooltipModal>
      </FieldHeading>
      <FlexFields autoFit>
        {options.genericYesNoOptions.map((opt) => (
          <Radio
            disabled={isDisabled}
            key={opt.value}
            name={`${name}.${subName}.${index}.isPEPFamily`}
            value={opt.value}
            onChange={(e) => {
              setFieldValue(`${name}.${subName}.${index}.isPEPFamily`, opt.value);
              // Clean if off
              if (e.target.value == 0) setFieldValue(`${name}.${subName}.${index}.pepFamily`, new PEPAssoc());
            }}
            label={opt.display}
            checked={values[subName][index].isPEPFamily === opt.value}
          />
        ))}
      </FlexFields>
      <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.isPEPFamily`} />
      {/* Jeżeli 4 = tak */}
      {values[subName][index].isPEPFamily === 1 && (
        <FlexFields cols={3}>
          <div>
            <Input
              disabled={isDisabled}
              name={`${name}.${subName}.${index}.pepFamily.PEPFullname`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Imię i nazwisko (PEP)"
              value={values[subName][index].pepFamily.PEPFullname || ''}
              error={errorChecker('pepFamily', 'PEPFullname')}
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.pepFamily.PEPFullname`} />
          </div>
          <div>
            <Input
              disabled={isDisabled}
              name={`${name}.${subName}.${index}.pepFamily.position`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Zajmowane stanowisko"
              value={values[subName][index].pepFamily.position || ''}
              error={errorChecker('pepFamily', 'position')}
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.pepFamily.position`} />
          </div>
          <div>
            <FieldHeading noMargin style={{ lineHeight: '18px' }} size="s">
              Data zwolnienia stanowiska
            </FieldHeading>
            <DateTimePicker
              disabled={isDisabled}
              onlyDays
              wider
              minDate={new Date('1900-01-01')}
              handleDate={(date) => setFieldValue(`${name}.${subName}.${index}.pepFamily.positionReleaseDate`, date)}
              initialDate={values[subName][index].pepFamily.positionReleaseDate || null}
              resetOnInitialChange
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.pepFamily.positionReleaseDate`} />
          </div>
        </FlexFields>
      )}

      {/* 5 */}
      <FieldHeading mbSmall size="s">
        Czy osoba podpisująca umowę jest bliskim współpracownikiem osoby zajmującej eksponowane stanowisko polityczne?
        <TooltipModal title="Informacja">
          <div className="content">
            <p>
              Osoba znana jako bliski współpracownik osoby zajmującej eksponowane stanowisko polityczne – rozumie się
              przez to:
            </p>
            <ul>
              <li>
                osoby fizyczne będące beneficjentami rzeczywistymi osób prawnych, jednostek organizacyjnych
                nieposiadających osobowości prawnej lub trustów wspólnie z osobą zajmującą eksponowane stanowisko
                polityczne lub utrzymujące z taką osobą inne bliskie stosunki związane z prowadzoną działalnością
                gospodarczą
              </li>
              <li>
                osoby fizyczne będące jedynym beneficjentem rzeczywistym osób prawnych, jednostek organizacyjnych
                nieposiadających osobowości prawnej lub trustu, o których wiadomo, że zostały utworzone w celu uzyskania
                faktycznej korzyści przez osobę zajmującą eksponowane stanowisko polityczne
              </li>
            </ul>
          </div>
        </TooltipModal>
      </FieldHeading>
      <FlexFields autoFit>
        {options.genericYesNoOptions.map((opt) => (
          <Radio
            disabled={isDisabled}
            key={opt.value}
            name={`${name}.${subName}.${index}.isPEPAssociate`}
            value={opt.value}
            onChange={(e) => {
              setFieldValue(`${name}.${subName}.${index}.isPEPAssociate`, opt.value);
              // Clean if off
              if (e.target.value == 0) setFieldValue(`${name}.${subName}.${index}.pepAssociate`, new PEPAssoc());
            }}
            label={opt.display}
            checked={values[subName][index].isPEPAssociate === opt.value}
          />
        ))}
      </FlexFields>
      <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.isPEPAssociate`} />
      {/* Jeżeli 5 = tak */}
      {values[subName][index].isPEPAssociate === 1 && (
        <FlexFields cols={3}>
          <div>
            <Input
              disabled={isDisabled}
              name={`${name}.${subName}.${index}.pepAssociate.PEPFullname`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Imię i nazwisko (PEP)"
              value={values[subName][index].pepAssociate.PEPFullname || ''}
              error={errorChecker('pepAssociate', 'PEPFullname')}
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.pepAssociate.PEPFullname`} />
          </div>
          <div>
            <Input
              disabled={isDisabled}
              name={`${name}.${subName}.${index}.pepAssociate.position`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Zajmowane stanowisko"
              value={values[subName][index].pepAssociate.position || ''}
              error={errorChecker('pepAssociate', 'position')}
            />
            <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.pepAssociate.position`} />
          </div>
          <div>
            <FieldHeading noMargin style={{ lineHeight: '18px' }} size="s">
              Data zwolnienia stanowiska
            </FieldHeading>
            <DateTimePicker
              disabled={isDisabled}
              onlyDays
              wider
              minDate={new Date('1900-01-01')}
              handleDate={(date) => setFieldValue(`${name}.${subName}.${index}.pepAssociate.positionReleaseDate`, date)}
              initialDate={values[subName][index].pepAssociate.positionReleaseDate || null}
              resetOnInitialChange
            />
            <ErrorMessage
              component={ErrorLabel}
              name={`${name}.${subName}.${index}.pepAssociate.positionReleaseDate`}
            />
          </div>
        </FlexFields>
      )}
      <FieldHeading mbSmall size="m">
        Podusmowanie
      </FieldHeading>
      <Checkbox
        disabled={isDisabled}
        name={`${name}.${subName}.${index}.dataValidationConfirmation`}
        handleChange
        onChange={(e) => setFieldValue(`${name}.${subName}.${index}.dataValidationConfirmation`, e.target.checked)}
        onBlur={handleBlur}
        label="Oświadczam, że zweryfikowałam/em poprawność danych z dokumentu tożsamości"
        value={values[subName][index].dataValidationConfirmation || false}
        checked={values[subName][index].dataValidationConfirmation || false}
      />
      <ErrorMessage component={ErrorLabel} name={`${name}.${subName}.${index}.dataValidationConfirmation`} />
    </Block>
  );
};
