import styled, { css } from 'styled-components';
import { breakpoints, colors } from 'theme/styles';
import closeIcon from 'assets/icons/close.svg';

export default styled.button.attrs({
  type: 'button',
})`
  width: 1.8rem;
  height: 1.8rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-bottom-left-radius: 4px;
  background-color: ${colors.gray};
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: 60% 50%;
  background-size: 80% 80%;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.orange};
  }

  ${({ personPosition }) =>
    personPosition &&
    css`
      border-radius: 4px;

      @media (max-width: ${breakpoints.mobile}) {
        order: 9999;
        position: static;
        border: 1px solid ${colors.orange};
        padding: 0.6rem;
        background-color: transparent;
        background-image: unset;
        height: auto;
        width: 100%;
        max-width: 20rem;
        margin: 0 auto;
        align-self: center;
      }

      @media (min-width: ${breakpoints.mobile}) {
        margin: 0;
        font-size: 0;
        top: 50%;
        transform: translate(60%, -50%) scale(0.85);
      }
    `}
`;
