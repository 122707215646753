import { useEffect, useState } from 'react';
import markerSDK from '@marker.io/browser';
import { useSelector } from 'react-redux';

export default function useMarkerio() {
  const [widget, setWidget] = useState(null);

  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    if (!widget)
      markerSDK
        .loadWidget({
          project: '6622657b35ce4fa869367313',
        })
        .then(setWidget);

    return () => {
      if (widget) {
        widget.unload();
        setWidget(null);
      }
    };
  }, [widget]);

  useEffect(() => {
    if (!widget) return;

    let reporter = {
      fullName: user.fullName || 'Brak danych',
      email: user.email || 'brak@impresspro.pl',
    };

    widget.setReporter(reporter);
  }, [user.fullName, user.email, widget]);

  return widget;
}
