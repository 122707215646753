/* eslint-disable camelcase */
import axios from 'axios';
import makeRequest, { requestWithFiles } from 'services/makeRequest';

const { CancelToken } = axios;
let cancelExportToken;

// Endpoints urls
const ADD_LEAD_URL = '/lead/add';
const GET_LEAD_URL = '/lead/';
const GET_SORTED_LEADS_URL = '/lead/filter';
const UPDATE_LEAD_URL = '/lead/update';
const TAKE_LEAD_URL = '/lead/take';
const REJECT_LEAD_PH_URL = '/lead/reject-ph';

const REASSIGN_LEAD = '/lead/reassign';
const FORGET_LEAD = '/lead/forget';

const IMPORT_LEADS_CHECK_URL = '/lead/import-leads-check';
const IMPORT_LEADS_URL = '/lead/import-leads';
const EXPORT_LEADS_URL = '/lead/export-leads';
const EXPORT_EXAMPLE_LEADS_URL = '/lead/import-leads-template';

// New exports
const EXPORT_LEADS_BY_IDS_URL = '/lead/export';

// Tranfer leads
const TRANSFER_LEAD_URL = '/lead-transfer/simple';
const TRANSFER_LEADS_BULK_URL = '/lead-transfer/bulk';

// Register account in pep crm
const REGISTER_ACCOUNT_CRM_URL = '/lead/create-lead-merchant';

const ONBOARDING_URL = '/signing-contract';
const REMOVE_CRM_ACCOUNT_URL = '/lead/reset-crm-fields';
const UPDATE_CRM_ACCOUNT_URL = '/lead/update-crm-account';

const RENEW_LEAD_URL = '/lead/re-add';

const updateCrmAccountById = (leadId, crmId) => {
  return makeRequest({
    method: 'post',
    url: `${UPDATE_CRM_ACCOUNT_URL}/${leadId}`,
    data: {
      crmAccountId: crmId,
    },
  });
};

const removeCrmAccountBinding = (leadId) => {
  return makeRequest({
    method: 'get',
    url: `${REMOVE_CRM_ACCOUNT_URL}/${leadId}`,
  });
};

const getOnboardingPointFile = (onboardingId, mediaId) =>
  makeRequest({
    method: 'get',
    url: `${ONBOARDING_URL}/${onboardingId}/media/${mediaId}`,
    responseType: 'blob',
  });

const deleteOnbordingPointFile = (onboardingId, mediaId) =>
  makeRequest({
    method: 'get',
    url: `${ONBOARDING_URL}/${onboardingId}/media/${mediaId}/delete`,
  });

const saveOnbordingPointFiles = (onboardingId, pointId, data) =>
  requestWithFiles({
    method: 'post',
    url: `${ONBOARDING_URL}/${onboardingId}/media/${pointId}`,
    data,
  });

const getOnboardingPersonInChargeAgreementFile = (id) =>
  makeRequest({
    method: 'get',
    url: `${ONBOARDING_URL}/${id}/signatory-agreement`,
    responseType: 'blob',
  });

const getOnboardingAgreementFile = (id) =>
  makeRequest({
    method: 'get',
    url: `${ONBOARDING_URL}/${id}/agreement`,
    responseType: 'blob',
  });

const getOnboardingXML = (onboardingId) => {
  return makeRequest({
    method: 'get',
    url: `${ONBOARDING_URL}/${onboardingId}/xml`,
    responseType: 'blob',
  });
};

const getOnboarding = (onboardingId) => {
  return makeRequest({
    method: 'get',
    url: `${ONBOARDING_URL}/${onboardingId}`,
  });
};

const saveOnboarding = (onboardingId, data, isSave) => {
  const params = {};
  if (!isSave) params.crm = 1;

  return requestWithFiles({
    method: 'post',
    url: `${ONBOARDING_URL}/${onboardingId}`,
    data,
    params,
  });
};

const resendOnboarding = (onboardingId) => {
  return requestWithFiles({
    method: 'get',
    url: `${ONBOARDING_URL}/${onboardingId}/resend`,
  });
};

const registerAccountCrm = (leadId) => {
  return makeRequest({
    method: 'get',
    url: `${REGISTER_ACCOUNT_CRM_URL}/${leadId}`,
  });
};

const rejectLeadByPh = (id) => {
  return makeRequest({
    method: 'get',
    url: `${REJECT_LEAD_PH_URL}/${id}`,
  });
};

// data has transferFromUserID and transferToUserId
const transferLeadBulk = (data) => {
  return makeRequest({
    method: 'post',
    url: TRANSFER_LEADS_BULK_URL,
    data,
  });
};

const transferLead = (data) => {
  return makeRequest({
    method: 'post',
    url: TRANSFER_LEAD_URL,
    data,
  });
};

const forgetLead = (leadId) => {
  return makeRequest({
    method: 'get',
    url: `${FORGET_LEAD}/${leadId}`,
  });
};

const addLead = (data) => {
  return makeRequest({
    method: 'post',
    url: ADD_LEAD_URL,
    data,
  });
};

const takeLead = (id) => {
  return makeRequest({
    method: 'post',
    url: TAKE_LEAD_URL,
    data: {
      leadId: id,
    },
  });
};

const getLead = (id) => {
  return makeRequest({
    method: 'get',
    url: `${GET_LEAD_URL + id}`,
  });
};

const getSortedLeads = (query, signal) => {
  return makeRequest({
    method: 'get',
    url: GET_SORTED_LEADS_URL,
    params: query,
    signal,
  });
};

const updateLead = (id, data) => {
  return makeRequest({
    method: 'post',
    url: `${UPDATE_LEAD_URL}/${id}`,
    data,
  });
};

const reassigneLead = (id) => {
  return makeRequest({
    method: 'get',
    url: `${REASSIGN_LEAD}/${id}`,
  });
};

// NEW EXPORT
const exportLeadsExcel = (partnerId = undefined, leadFilterParams) => {
  return makeRequest({
    method: 'POST',
    url: EXPORT_LEADS_BY_IDS_URL,
    data: {
      partnerId,
    },
    params: leadFilterParams,
    timeout: 1000 * 60 * 30, // 30minut test, bo 0 wcześniejsze i tak wywalało się po minucie
    responseType: 'blob',
    cancelToken: new CancelToken(function executor(c) {
      cancelExportToken = c;
    }),
  });
};

// IMPORT / EXPORT
const importLeadsCheck = (data) => {
  return requestWithFiles({
    method: 'post',
    url: IMPORT_LEADS_CHECK_URL,
    data,
  });
};

const importLeads = (filePath) => {
  return makeRequest({
    method: 'post',
    url: IMPORT_LEADS_URL,
    data: {
      filePath,
    },
  });
};

const exportLeads = () => {
  return makeRequest({
    method: 'get',
    url: EXPORT_LEADS_URL,
    responseType: 'blob',
  });
};

const getExampleCsv = () => {
  return makeRequest({
    method: 'get',
    url: EXPORT_EXAMPLE_LEADS_URL,
    responseType: 'blob',
  });
};

const renewLead = (leadId) => {
  return makeRequest({
    method: 'get',
    url: `${RENEW_LEAD_URL}/${leadId}`,
  });
};

export const leadService = {
  addLead,
  getLead,
  getSortedLeads,
  updateLead,
  takeLead,
  reassigneLead,
  importLeadsCheck,
  importLeads,
  exportLeads,
  getExampleCsv,
  exportLeadsExcel,
  cancelExport: () => cancelExportToken(),
  forgetLead,
  transferLead,
  transferLeadBulk,
  rejectLeadByPh,
  registerAccountCrm,
  getOnboarding,
  saveOnboarding,
  getOnboardingXML,
  getOnboardingAgreementFile,
  getOnboardingPersonInChargeAgreementFile,
  removeCrmAccountBinding,
  resendOnboarding,
  updateCrmAccountById,
  renewLead,
  saveOnbordingPointFiles,
  deleteOnbordingPointFile,
  getOnboardingPointFile,
};
