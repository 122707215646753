/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, font } from 'theme/styles';
import Tooltip from './Tooltip';

const Radio = ({ name, placeholder, onChange, className, value, error, label, checked, disabled, tooltip }) => {
  return (
    <RadioLabel disabled={disabled}>
      <StyledRadio
        name={name}
        type="radio"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={className}
        error={error}
        checked={checked}
        disabled={disabled}
      />
      <span className="label" />
      {Array.isArray(label) ? label.map((el, i) => <span key={i}>{el}</span>) : <span>{label}</span>}
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </RadioLabel>
  );
};

Radio.defaultProps = {
  className: '',
  placeholder: '',
  error: false,
  disabled: false,
  tooltip: '',
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  error: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default Radio;

// Styled components:

const RadioLabel = styled.label`
  display: block;
  font-size: ${font.size.xm};
  color: ${colors.black};
  line-height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  user-select: none;

  & > span.label {
    display: block;
    float: left;
    margin-right: 10px;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${colors.white};
    border: 1px solid ${colors.orange};

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      background-color: ${colors.orange};
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.15s ease-out;
    }
  }

  svg {
    color: ${colors.red};
    margin-left: 10px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${colors.darkGray};
      & > span.label {
        border-color: currentColor;
      }
      & > span.label::after {
        background-color: currentColor;
      }
    `}
`;

const StyledRadio = styled.input`
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  display: none;

  &:checked + span.label::after {
    transform: translate(-50%, -50%) scale(1);
  }
`;
