import styled, { css } from 'styled-components';
import { font, breakpoints, colors } from 'theme/styles';

export const OneLineButtonsWrapper = styled.div`
  margin-bottom: -20px;

  button {
    display: inline-block;
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const baseTableMixin = css`
  table {
    width: 100%;
    min-width: 300px;
    border-collapse: collapse;
    text-align: center;
    font-size: ${font.size.m};
    color: ${colors.black};

    span.inactive {
      font-size: ${font.size.small};
      color: ${colors.red};
    }

    tr {
      th:nth-child(2),
      td:nth-child(2) {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
      }

      th,
      td {
        padding: 0 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      th {
        position: sticky;
        top: 0;
        height: 50px;
        background-color: ${colors.white};
        font-weight: ${font.weight.semibold};
      }
    }

    tfoot {
      position: sticky;
      inset-block-end: 0;

      td {
        background-color: ${colors.white};
      }
    }

    tbody,
    tfoot {
      background-color: ${colors.blueGray};

      tr {
        height: 40px;

        &:nth-of-type(even) {
          background-color: ${colors.white};
        }

        td > svg {
          color: ${colors.orange};
        }

        td .icons {
          display: inline-block;
          min-width: 60px;
          color: ${colors.orange};

          svg {
            cursor: pointer;
            margin: 0 7px;
          }
        }
      }
    }
  }
`;

export const TableWrapperCommon = styled.div`
  width: 100%;
  height: 100%;
  max-height: 75vh;
  margin: 20px auto;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  @media (orientation: landscape) {
    /* max-height: unset; */
  }
`;

export const TableCommon = styled.table`
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
  position: relative;
  z-index: 10;

  tr {
    border-bottom: 2px solid ${colors.white};
    th,
    td {
      padding-left: 8px;
      padding-right: 8px;

      &:not(:first-child) {
        border-left: 1px solid ${colors.white};
      }
    }
  }

  thead {
    background-color: ${colors.white};

    tr {
      height: 60px;
      text-align: center;

      th {
        position: sticky;
        top: 0;
        z-index: 20;
        background-color: ${colors.white};
        font-weight: ${font.weight.semibold};

        &.noSearch {
          padding-bottom: 28px;
        }
      }
    }

    tr.advancedSearchRow {
      height: 34px;
    }
  }

  tbody,
  tfoot {
    background-color: ${colors.blueGray};

    tr {
      height: 40px;
      cursor: pointer;

      &:nth-of-type(even) {
        background-color: ${colors.white};
      }
      &:hover {
        background-color: ${colors.green}10;
      }
    }

    td {
      text-align: center;
      font-size: ${font.size.s};
      color: ${colors.black};
      & > a {
        color: ${colors.mediumGray};
      }

      &.has-onboarding {
        background-color: ${colors.selectBlueLight};
      }
    }
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const WithFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > :last-child {
    display: none;
  }

  @media (max-width: ${breakpoints.filters}) {
    & > :last-child {
      display: block;
    }
  }
`;

export const TableSorter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  z-index: 19;
  flex: 1;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin-top: 30px;
    justify-content: space-evenly;
  }

  @media (max-width: ${breakpoints.filters}) {
    justify-content: space-between;
  }
`;

export const SortItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & + & {
    margin-left: 20px;
  }

  & > span {
    font-size: ${font.size.s};
    font-weight: ${font.weight.semibold};
  }

  select,
  input {
    width: 100%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex: 0 1 40%;
    margin-bottom: 20px;

    & + & {
      margin-left: 0;
    }
  }

  @media (max-width: ${breakpoints.filters}) {
    position: relative;
    margin: 0;
    flex: 0 1 47.5%;
    margin-bottom: 10px;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    flex-direction: column;

    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const BottomItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${font.size.s};
`;

export const TdWithTitle = styled.td`
  position: relative;
  font-size: ${(props) => (props.small ? '1.2rem !important' : 'inherit')};

  & > span {
    position: absolute;
    display: none;

    &:active {
      background-color: red;
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }

  &:hover > span {
    display: block;
    background-color: ${colors.white};
    border: 1px solid ${colors.orange};
    top: 90%;
    padding: 4px 8px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
  }
`;
