import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Block from 'components/UI/Block';
import Heading from 'components/UI/Heading';

const TwoColsTemplate = ({ leftSide, rightSide, leftTitle, rightTitle }) => (
  <Wrapper>
    <Column>
      <StyledHeading size="xxl">{leftTitle}</StyledHeading>
      <Block>{leftSide}</Block>
    </Column>
    <Column>
      <StyledHeading size="xl">{rightTitle}</StyledHeading>
      <Block>{rightSide}</Block>
    </Column>
  </Wrapper>
);

TwoColsTemplate.propTypes = {
  leftSide: PropTypes.node.isRequired,
  rightSide: PropTypes.node.isRequired,
  leftTitle: PropTypes.string.isRequired,
  rightTitle: PropTypes.string.isRequired,
};

export default TwoColsTemplate;

// styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    & > * {
      width: 100%;
      max-width: calc(50% - 15px);
      flex: 0 1 calc(50% - 15px);
    }
  }
`;

const Column = styled.div`
  padding-top: 15px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 45px;
`;
