import { productService } from 'services/product';
import { sortProducts } from 'utils/helpers';
import * as types from './types';

export const getAllProducts = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_PRODUCTS_REQUEST });

  return productService
    .getAll()
    .then((payload) => {
      payload.data.sort(sortProducts);
      payload.data = payload.data.map((prod) => {
        const temp = [];
        if (prod.ambassadorVisible === '1') temp.push('AMBASADOR');
        if (prod.softProducentVisible === '1') temp.push('PRODUCENT_SOFT');
        if (prod.casherVisible === '1') temp.push('CASHER');
        return { ...prod, partnerProgram: temp };
      });
      dispatch({ type: types.GET_ALL_PRODUCTS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_PRODUCTS_FAILURE, err });
    });
};

export const clearProducts = () => (dispatch) => {
  dispatch({ type: types.CLEAR_PRODUCTS });
};
