import * as types from './types';

const initialState = {
  isAuthenticated: false,
  isUserLoaded: false,
  isLoading: false,
  data: {
    role: {
      slug: '',
    },
    files: {},
  },
  authError: null,
  loginError: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loginError: null,
      };
    case types.AUTH_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        loginError: action.err.response.data.error,
      };
    case types.GET_ME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUserLoaded: true,
        data: action.payload.data,
        authError: null,
      };
    case types.GET_ME_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        isUserLoaded: false,
        authError: action.err.response.data.error,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        authError: null,
      };
    case types.LOGOUT_SUCCESS:
      return initialState;
    case types.UPDATE_REQUEST:
    case types.UPDATE_SUCCESS:
    case types.UPDATE_FAILURE:
    default:
      return state;
  }
};

export default userReducer;
