import * as types from './types';

const initialState = {
  trainings: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
  },
  currentTraining: {
    data: null,
  },
  isLoading: false,
  error: null,
  uploadProgress: null,
};

const trainingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TRAINING_MATERIAL_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
      };
    case types.GET_TRAINING_MATERIALS_REQUEST:
      return {
        ...state,
        isLoading: true,
        trainings: {
          ...state.trainings,
        },
      };
    case types.GET_TRAINING_MATERIALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        trainings: {
          ...state.trainings,
          data: action.payload.data,
        },
      };
    case types.GET_TRAINING_MATERIALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.err.response.data.error,
        trainings: {
          ...state.trainings,
        },
      };
    case types.GET_TRAINING_MATERIAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        currentTraining: {
          ...state.currentTraining,
        },
      };
    case types.GET_TRAINING_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        currentTraining: {
          ...state.currentTraining,
          data: action.payload.data,
        },
      };
    case types.GET_TRAINING_MATERIAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.err.response.data.error.message,
        currentTraining: {
          ...state.currentTraining,
        },
      };
    case types.SET_CURRENT_TRAINING_MATERIAL:
      return {
        ...state,
        isLoading: false,
        error: null,
        currentTraining: {
          data: action.payload,
        },
      };
    case types.CLEAR_CURRENT_TRAINING_MATERIAL:
      return {
        ...state,
        isLoading: false,
        error: null,
        currentTraining: {
          data: null,
        },
      };
    case types.CLEAR_TRAINING:
      return initialState;
    default:
      return state;
  }
};

export default trainingReducer;
