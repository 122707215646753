import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import { adminService } from 'services/admin';
// import { vacationSchema } from 'utils/validation';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import useFetchSuggestions from 'hooks/useFetchSuggestions';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import InputAuto from 'components/UI/InputAuto';
import Loading from 'components/UI/Loading';
import Chip from 'components/UI/Chip';
import CloseButton from 'components/UI/CloseButton';
import Input from 'components/UI/Input';
import Checkbox from 'components/UI/Checkbox';

const OptionsLeads = ({ refetch }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [merchantsList, fetchMerchants] = useFetchSuggestions();
  const [partnersList, fetchPartners] = useFetchSuggestions({ type: 'partners' });
  const [askForPassword, setAskForPassword] = useState(false);
  const [masterPassword, setMasterPassword] = useState('');

  const options = useSelector((s) => s.admin.spOptions);
  const isLoadingOptions = useSelector((s) => s.admin.spOptions.isLoading);
  const isErrorOptions = useSelector((s) => s.admin.spOptions.error);

  const handleClose = (resetForm) => {
    setSuccess(false);
    setError('');
    resetForm({});
  };

  // If given user is included in autoRegisterCrmMerchantUsers then delete it there as well
  const handleAutoTakeLeadsUserDelete = (id, values, setFieldValue) => {
    const newValue = values.autoTakeLeadsUsers.filter((el) => el.id !== id);
    setFieldValue('autoTakeLeadsUsers', newValue);

    if (!values.autoRegisterCrmMerchantUsers.some((u) => u.id === id)) return;
    const newRegisterCrmValue = values.autoRegisterCrmMerchantUsers.filter((el) => el.id !== id);
    setFieldValue('autoRegisterCrmMerchantUsers', newRegisterCrmValue);
  };

  const handleAutoCrmRegisterHandler = (val, item, values, setFieldValue) => {
    if (!values.autoRegisterCrmMerchantUsers.some((u) => u.id === item.id)) {
      const { id, name, surname } = item;
      setFieldValue('autoRegisterCrmMerchantUsers', [...values.autoRegisterCrmMerchantUsers, { id, name, surname }]);

      if (values.autoTakeLeadsUsers.some((u) => u.id === item.id)) return;
      setFieldValue('autoTakeLeadsUsers', [...values.autoTakeLeadsUsers, { id, name, surname }]);
    }
    setFieldValue('autoRegisterCrmMerchantUsersSearch', '');
  };

  const handleSubmit = (values) => {
    setError('');
    setLoading(true);

    const payload = {
      masterPassword,
      autoTakeLeadsUsers: JSON.stringify(values.autoTakeLeadsUsers.map((u) => u.id)),
      autoRegisterCrmMerchantUsers: JSON.stringify(values.autoRegisterCrmMerchantUsers.map((u) => u.id)),
      autoExcludeFilterLeadsPartner: JSON.stringify(values.autoExcludeFilterLeadsPartner.map((u) => u.id)),
      formPwob: values.formPwob,
      generalPromotion: values.generalPromotion,
      blockAddLeadButton: values.blockAddLeadButton,
    };

    const thenFn = (data) => {
      console.log(data);

      if (data.data?.isOk) setSuccess(true);
      else setError('Coś poszło nie tak.');

      setLoading(false);
    };

    const catchFn = (err) => {
      console.log(err);
      setError(err.response.data.error.message);
      setLoading(false);
    };

    adminService
      .setSpOptions(payload)
      .then(thenFn)
      .catch(catchFn)
      .finally(() => {
        setAskForPassword(false);
        setMasterPassword('');
        refetch();
      });
  };

  const autoTakeLeadsUsers = options.data.autoTakeLeadsUsersAssoc || [];
  const autoRegisterCrmMerchantUsers = options.data.autoRegisterCrmMerchantUsersAssoc || [];
  const autoExcludeFilterLeadsPartner = options.data.autoExcludeFilterLeadsPartnerUsersAssoc || [];
  const { formPwob = 0, blockAddLeadButton = 0, generalPromotion = 0 } = options.data;

  return (
    <>
      <FormWrapperCommon>
        {(loading || isLoadingOptions) && <Loading absolute />}
        {isErrorOptions && (
          <ErrorPopup>
            <Heading>Błąd</Heading>
            <p style={{ marginTop: '2rem' }}> {isErrorOptions}</p>
          </ErrorPopup>
        )}
        <Heading size="xl">Ustawienia globalne leadowe</Heading>
        {!isLoadingOptions && !isErrorOptions && (
          <Formik
            initialValues={{
              autoTakeLeadsUsers,
              autoTakeLeadsUsersSearch: '',

              autoRegisterCrmMerchantUsers,
              autoRegisterCrmMerchantUsersSearch: '',

              autoExcludeFilterLeadsPartner,
              autoExcludeFilterLeadsPartnerSearch: '',

              formPwob,
              generalPromotion,
              blockAddLeadButton,
            }}
            enableReinitialize
            // validationSchema={}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleBlur, resetForm, setFieldValue, setFieldTouched }) => (
              <>
                {success && (
                  <SuccessPopup>
                    <Heading>Pomyślnie zapisano ustawienia</Heading>
                    <div className="buttons">
                      <Button onClick={() => handleClose(resetForm)}>Zamknij</Button>
                    </div>
                  </SuccessPopup>
                )}
                {error && (
                  <ErrorPopup>
                    <Heading>Wystapił błąd</Heading>
                    <p style={{ marginTop: '2rem' }}>{error}</p>
                    <div className="buttons">
                      <Button gray onClick={() => handleClose(resetForm)}>
                        Zamknij
                      </Button>
                    </div>
                  </ErrorPopup>
                )}
                <StyledForm>
                  <FieldHeading size="m">
                    Lista Przedstawicieli dla których leady zostają automatycznie podjęte
                  </FieldHeading>
                  <InputAuto
                    suggestions={merchantsList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="autoTakeLeadsUsersSearch"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    onChange={(e) => fetchMerchants(e.target.value, 'merchantPep')}
                    onSelect={(val, item) => {
                      if (!values.autoTakeLeadsUsers.some((u) => u.id === item.id)) {
                        const { id, name, surname } = item;
                        setFieldValue('autoTakeLeadsUsers', [...values.autoTakeLeadsUsers, { id, name, surname }]);
                      }
                      setFieldValue('autoTakeLeadsUsersSearch', '');
                    }}
                    label="Znajdź przedstawiciela"
                    value={values.autoTakeLeadsUsersSearch || ''}
                    error={touched.autoTakeLeadsUsersSearch && errors.autoTakeLeadsUsersSearch}
                  />
                  <ErrorMessage component={ErrorLabel} name="autoTakeLeadsUsersSearch" />
                  <div>
                    {values.autoTakeLeadsUsers &&
                      values.autoTakeLeadsUsers.map((user) => (
                        <Chip
                          key={`${user.id}`}
                          onClick={() => handleAutoTakeLeadsUserDelete(user.id, values, setFieldValue)}
                        >
                          {`${user.name} ${user.surname}`}
                        </Chip>
                      ))}
                  </div>
                  <FieldHeading size="m">
                    Lista Przedstawicieli dla których leada zostają automatycznie zarejestrowane w CRM
                  </FieldHeading>
                  <InputAuto
                    suggestions={merchantsList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="autoRegisterCrmMerchantUsersSearch"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    onChange={(e) => fetchMerchants(e.target.value, 'merchantPep')}
                    onSelect={(val, item) => handleAutoCrmRegisterHandler(val, item, values, setFieldValue)}
                    label="Znajdź przedstawiciela"
                    value={values.autoRegisterCrmMerchantUsersSearch || ''}
                    error={touched.autoRegisterCrmMerchantUsersSearch && errors.autoRegisterCrmMerchantUsersSearch}
                  />
                  <ErrorMessage component={ErrorLabel} name="autoRegisterCrmMerchantUsersSearch" />
                  <div>
                    {values.autoRegisterCrmMerchantUsers &&
                      values.autoRegisterCrmMerchantUsers.map((user) => (
                        <Chip
                          key={`${user.id}`}
                          onClick={() => {
                            const newValue = values.autoRegisterCrmMerchantUsers.filter((el) => el.id !== user.id);
                            setFieldValue('autoRegisterCrmMerchantUsers', newValue);
                          }}
                        >
                          {`${user.name} ${user.surname}`}
                        </Chip>
                      ))}
                  </div>
                  <FieldHeading size="m">
                    Lista Partnerów do weryfikacji przy zastosowaniu filtrowania po &quot;Leady z PeP&quot;
                  </FieldHeading>
                  <InputAuto
                    suggestions={partnersList}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="autoExcludeFilterLeadsPartnerSearch"
                    type="text"
                    display={['name']}
                    onBlur={handleBlur}
                    onChange={(e) => fetchPartners(e.target.value)}
                    onSelect={(val, item) => {
                      if (!values.autoExcludeFilterLeadsPartner.some((u) => u.id === item.id)) {
                        const { id, name } = item;
                        setFieldValue('autoExcludeFilterLeadsPartner', [
                          ...values.autoExcludeFilterLeadsPartner,
                          { id, name },
                        ]);
                      }
                      setFieldValue('autoExcludeFilterLeadsPartnerSearch', '');
                    }}
                    label="Znajdź partnera"
                    value={values.autoExcludeFilterLeadsPartnerSearch || ''}
                    error={touched.autoExcludeFilterLeadsPartnerSearch && errors.autoExcludeFilterLeadsPartnerSearch}
                  />
                  <ErrorMessage component={ErrorLabel} name="autoExcludeFilterLeadsPartnerSearch" />
                  <div>
                    {values.autoExcludeFilterLeadsPartner &&
                      values.autoExcludeFilterLeadsPartner.map((user) => (
                        <Chip
                          key={`${user.id}`}
                          onClick={() => {
                            const newValue = values.autoExcludeFilterLeadsPartner.filter((el) => el.id !== user.id);
                            setFieldValue('autoExcludeFilterLeadsPartner', newValue);
                          }}
                        >
                          {`${user.name}`}
                        </Chip>
                      ))}
                  </div>
                  <StyledRow>
                    <div>
                      <FieldHeading medium size="m">
                        Formularz - program PWOB
                      </FieldHeading>
                      <Checkbox
                        name="formPwob"
                        onChange={(e) => setFieldValue('formPwob', e.target.checked ? 1 : 0)}
                        onBlur={handleBlur}
                        label="Aktywny"
                        value={String(values.formPwob)}
                        checked={values.formPwob === 1}
                        error={Boolean(touched.formPwob && errors.formPwob)}
                      />
                    </div>
                    <div>
                      <FieldHeading medium size="m">
                        Formularz - promocja ogólna
                      </FieldHeading>
                      <Checkbox
                        name="generalPromotion"
                        onChange={(e) => setFieldValue('generalPromotion', e.target.checked ? 1 : 0)}
                        onBlur={handleBlur}
                        label="Aktywny"
                        value={String(values.generalPromotion)}
                        checked={values.generalPromotion === 1}
                        error={Boolean(touched.generalPromotion && errors.generalPromotion)}
                      />
                    </div>
                  </StyledRow>
                  <FieldHeading medium size="m">
                    Dodawanie leadów - blokada buttona &quot;Dodaj potencjalnego Klienta&quot;
                  </FieldHeading>
                  <Checkbox
                    name="blockAddLeadButton"
                    onChange={(e) => setFieldValue('blockAddLeadButton', e.target.checked ? 1 : 0)}
                    onBlur={handleBlur}
                    label="Zablokuj"
                    value={String(values.blockAddLeadButton)}
                    checked={values.blockAddLeadButton === 1}
                    error={Boolean(touched.blockAddLeadButton && errors.blockAddLeadButton)}
                  />
                  <Button
                    style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }}
                    type="button"
                    onClick={() => setAskForPassword(true)}
                  >
                    Zapisz ustawienia
                  </Button>
                  {askForPassword && (
                    <SuccessPopup fixed>
                      <CloseButton onClick={() => setAskForPassword(false)} />
                      <Heading style={{ marginBottom: '2rem' }}>Akcja wymaga hasła</Heading>
                      <div style={{ width: '25rem' }}>
                        <Input
                          name="masterPassword"
                          type="password"
                          onChange={(e) => setMasterPassword(e.target.value)}
                          onBlur={() => {}}
                          label="Master-password"
                          value={masterPassword}
                          error={false}
                        />
                      </div>
                      <div className="buttons">
                        <Button type="submit">Zapisz ustawienia</Button>
                      </div>
                    </SuccessPopup>
                  )}
                </StyledForm>
              </>
            )}
          </Formik>
        )}
      </FormWrapperCommon>
    </>
  );
};

OptionsLeads.defaultProps = {};

OptionsLeads.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default OptionsLeads;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;

  input {
    max-width: 30rem;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;
