import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';
import NoData from 'components/UI/NoData';
import { mapStatusObjShort } from 'config/constants';
import { displayDate, displayTime, getTrendIcon } from 'utils/helpers';

const UsersTable = ({ data, headers, offset, handleEdit, handleTerminal, handleLoginAs, companyUsersTable }) => {
  const actionsCount = [handleEdit, handleLoginAs, handleTerminal].filter(Boolean).length;

  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => {
              switch (header.property) {
                case 'createdAt':
                  return <col key={header.property} className={`${header.property}-wide`} />;
                default:
                  return <col key={header.property} className={header.property} />;
              }
            })}
            {actionsCount > 0 && <col className="actions" />}
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
              {actionsCount > 0 && <th key="edit">Edycja</th>}
            </tr>
          </thead>
          <tbody>
            {data.map((user, index) => (
              <tr key={user.id} style={{ backgroundColor: user.someError ? colors.lightGray : null }}>
                <td>{offset + index + 1}</td>
                {headers.map(header => {
                  const [trendIcon, trendName] = getTrendIcon(user.trend);

                  switch (header.property) {
                    case 'createdAt':
                      return <td key={user.id + header.property}>{displayDate(user[header.property])}</td>;
                    case 'feSubPartner':
                      if (user.someError) {
                        return (
                          <td key={user.id + header.property}>
                            <FontAwesomeIcon title="Błąd użytkownika" icon="times" />
                          </td>
                        );
                      }
                      return (
                        <td key={user.id + header.property}>
                          <FontAwesomeIcon
                            title={user.feSubPartner ? 'Sub-partner' : 'Partner'}
                            icon={user.feSubPartner ? 'level-up-alt' : 'user-tie'}
                            rotation={user.feSubPartner ? 90 : 0}
                          />
                        </td>
                      );

                    case 'fullName':
                      return <td key={user.id + header.property}>{`${user.name} ${user.surname}`}</td>;
                    case 'roleName':
                      return (
                        <td style={{ fontWeight: user.mainPartner && 'bold' }} key={user.id + header.property}>
                          {user.role.name} {user.mainPartner && 'główny'} {user.coordinator === '1' && '(Faktury)'}
                        </td>
                      );
                    case 'active':
                      return (
                        <td key={user.id + header.property}>
                          {user.deletedAt === null ? (
                            <>
                              {user.active === '1' ? <FontAwesomeIcon title="Status: aktywny" icon="check" /> : null}
                              {companyUsersTable && (
                                <FontAwesomeIcon style={{ marginLeft: '1em' }} title={trendName} icon={trendIcon} />
                              )}
                            </>
                          ) : (
                            <span className="inactive" title={displayTime(user.deletedAt)}>
                              Nieaktywny od {displayDate(user.deletedAt)}
                            </span>
                          )}
                        </td>
                      );
                    case 'status':
                      return (
                        <td key={user.id + header.property}>
                          {mapStatusObjShort[user[header.property]] || user[header.property]}
                        </td>
                      );
                    default:
                      return <td key={user.id + header.property}>{user[header.property]}</td>;
                  }
                })}
                {actionsCount > 0 && (
                  <td
                    key="edit"
                    style={{
                      width: actionsCount > 2 ? `${20 + actionsCount * 32}px` : '100px',
                    }}
                  >
                    <span className="icons">
                      {handleEdit && <FontAwesomeIcon title="Edytuj" icon="edit" onClick={() => handleEdit(user.id)} />}
                      {handleTerminal && (
                        <FontAwesomeIcon title="Kopiuj link" icon="clipboard" onClick={() => handleTerminal(user.id)} />
                      )}
                      {handleLoginAs && (
                        <FontAwesomeIcon title="Zaloguj jako" icon="sign-in-alt" onClick={() => handleLoginAs(user)} />
                      )}
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData />
      )}
    </TableWrapper>
  );
};

UsersTable.defaultProps = {
  handleEdit: false,
  handleTerminal: false,
  handleLoginAs: false,
  companyUsersTable: false,
};

UsersTable.propTypes = {
  companyUsersTable: PropTypes.bool,
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleTerminal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleLoginAs: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default UsersTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 720px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;

    tr {
      th,
      td {
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 35px;
        &:nth-of-type(even) {
          background-color: ${colors.white};
        }
      }

      td {
        font-size: ${font.size.s};
        text-transform: none;
        color: ${colors.black};

        & > svg {
          color: ${colors.orange};
        }
        & > .icons {
          color: ${colors.orange};
          font-size: ${font.size.m};
          svg {
            cursor: pointer;
            margin: 0 7px;
          }
        }
      }
    }
  }
`;
