/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { displayDate, displayTime, formatMoney, removeNullsAndEmpty, resolveObjProp } from 'utils/helpers';
import { TableCommon, TableWrapperCommon } from 'components/layout/Tables/TableComponents';
import { useUrl } from 'utils/hooks';
import NoData from 'components/UI/NoData';
import Loading from 'components/UI/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyString from 'components/UI/CopyString';
import { colors, font } from 'theme/styles';

const IntegrationOrdersTable = ({ data, headers, offset, isLoading, actions, handleRowClick, hideActions }) => {
  const [advancedSearch, setAdvancedSearch] = useUrl('advancedSearch');

  const getInitialValues = () => {
    const initialObject = headers.reduce((o, prop) => {
      if (prop.advancedSearch) o[`${prop.advancedSearch}-search`] = '';
      return o;
    }, {});

    if (!advancedSearch) return initialObject;
    const tempArr = advancedSearch.split('|').map(el => el.split(':'));
    tempArr.forEach(el => {
      const [propName, value] = el;
      initialObject[`${propName}-search`] = value;
    });
    return initialObject;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    onSubmit: values => {
      let outputStr = '';
      const filtered = removeNullsAndEmpty({ ...values });
      Object.entries(filtered).forEach(([key, val]) => {
        outputStr += `${key.replace('-search', '')}:${val}|`;
      });
      outputStr = outputStr.slice(0, -1);
      setAdvancedSearch(outputStr);
    },
  });

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col className="lp" />
          {headers.map(header => (
            <col key={header.property} className={header.property} />
          ))}
          {!hideActions && actions && <col className="actions" />}
        </colgroup>
        <thead>
          <tr>
            <th className="noSearch">LP</th>
            {headers.map(header => (
              <th key={header.property} className={header.advancedSearch ? null : 'noSearch'}>
                {header.displayName}
                {header.advancedSearch ? (
                  <StyledSearch
                    type="text"
                    placeholder={header.advancedSearchPlaceholder || 'Szukaj...'}
                    name={`${header.advancedSearch}-search`}
                    id={`${header.advancedSearch}-search`}
                    onChange={formik.handleChange}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.target.blur();
                      }
                    }}
                    onBlur={() => {
                      if (formik.dirty) formik.handleSubmit();
                    }}
                    value={formik.values[`${header.advancedSearch}-search`]}
                  />
                ) : null}
              </th>
            ))}
            {!hideActions && actions && <th className="noSearch">Akcje</th>}
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            data.map((item, index) => (
              <tr
                key={item.zlecenie_id}
                onClick={e => {
                  if (!e.target.closest('.icons')) handleRowClick(item);
                }}
              >
                <td>{offset + index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = data.id + header.property;
                  let propValue = resolveObjProp(header.property, item);
                  if (!propValue && header.or) propValue = resolveObjProp(header.or, item);
                  if (header.type === 'date' && propValue) propValue = displayDate(propValue);
                  if (header.type === 'date-time' && propValue) propValue = displayTime(propValue);
                  if (header.type === 'currency' && propValue) propValue = formatMoney(propValue);
                  if (header.map && propValue) propValue = header.map[propValue] || propValue;
                  if (header.type === 'status' && propValue)
                    propValue =
                      propValue === '1' || propValue === true ? <FontAwesomeIcon title="Aktywny" icon="check" /> : null;
                  return (
                    <td className={header.cellClass} key={uniqueKey} style={header.style}>
                      {propValue}
                      {header.copy && <CopyString text={propValue} />}
                      {header.property === 'typ_zlecenia' && item.hasAttachment && (
                        <FontAwesomeIcon
                          style={{ marginLeft: '0.75em', color: colors.orange }}
                          size="sm"
                          title="Posiada załącznik"
                          icon="paperclip"
                        />
                      )}
                    </td>
                  );
                })}
                {!hideActions && actions && (
                  <td>
                    <span className="icons">
                      {actions.map(action => {
                        const canSee = action.canSeeAction ? action.canSeeAction(item) : true;
                        return canSee ? (
                          <FontAwesomeIcon
                            key={item.id + action.icon}
                            title={action.title}
                            icon={action.icon}
                            color={action.color}
                            onClick={() => action.handler(item)}
                          />
                        ) : null;
                      })}
                    </span>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      {isLoading && <Loading />}
      {!isLoading && data.length === 0 && <NoData />}
    </TableWrapper>
  );
};

IntegrationOrdersTable.defaultProps = {
  actions: false,
  hideActions: false,
};

IntegrationOrdersTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hideActions: PropTypes.bool,
  actions: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool]),
  handleRowClick: PropTypes.func.isRequired,
};

export default IntegrationOrdersTable;

const StyledSearch = styled.input`
  display: block;
  padding: 0 4px;
  width: 100%;
  margin-top: 5px;
`;
const TableWrapper = styled(TableWrapperCommon)``;
const Table = styled(TableCommon)`
  .icons {
    color: ${colors.orange};
    font-size: ${font.size.m};
    display: flex;
    justify-content: space-around;

    svg {
      cursor: pointer;
      margin: 0 7px;
    }
  }
`;
