export const GET_ALL_RECOMMENDED_USERS_REQUEST = 'GET_ALL_RECOMMENDED_USERS_REQUEST';
export const GET_ALL_RECOMMENDED_USERS_SUCCESS = 'GET_ALL_RECOMMENDED_USERS_SUCCESS';
export const GET_ALL_RECOMMENDED_USERS_FAILURE = 'GET_ALL_RECOMMENDED_USERS_FAILURE';

export const GET_RECOMMENDED_USER_REQUEST = 'GET_RECOMMENDED_USER_REQUEST';
export const GET_RECOMMENDED_USER_SUCCESS = 'GET_RECOMMENDED_USER_SUCCESS';
export const GET_RECOMMENDED_USER_FAILURE = 'GET_RECOMMENDED_USER_FAILURE';

export const SET_CURRENT_RECOMMENDED_USER = 'SET_CURRENT_RECOMMENDED_USER';

export const CLEAR_CURRENT_RECOMMENDED_USER = 'CLEAR_CURRENT_RECOMMENDED_USER';

export const CLEAR_RECOMMENDED_STATE = 'CLEAR_RECOMMENDED_STATE';
