/* eslint-disable class-methods-use-this */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import options from 'components/forms/LeadMerchant/others/config';
import matrixArr from 'components/forms/LeadMerchant/others/configAML';
import mcc from 'components/forms/LeadMerchant/others/mcc';

// import { formatMoney } from 'utils/helpers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class BillingPdf {
  constructor(data) {
    if (!data) throw new Error('Obiekt konfiguracyjny jest wymagany.');

    this.data = data;

    this.pdf = null;
    this.pdfDefinition = null;
    this.style = {
      lineWidth: 0.5,
      lineColor: '#FF0000',
      tableFill: '#dddddd',
    };

    this.iconsSize = 12;
    this.iconsColor = '#F18819';

    this.checkbox = `<svg width="${this.iconsSize}" height="${this.iconsSize}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="${this.iconsColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="${this.iconsColor}"> <title id="checkboxIconTitle">Checkbox (selected)</title> <rect x="21" y="3" width="18" height="18" rx="1" transform="rotate(90 21 3)"/></svg>`;
    this.checkboxChecked = `<svg width="${this.iconsSize}" height="${this.iconsSize}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="${this.iconsColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="${this.iconsColor}"> <title id="checkboxIconTitle">Checkbox (selected)</title> <rect x="21" y="3" width="18" height="18" rx="1" transform="rotate(90 21 3)"/> <path d="M6.66666 ${this.iconsSize}.6667L9.99999 16L17.3333 8.66669"/></svg>`;
    this.radio = `<svg width="${this.iconsSize}" height="${this.iconsSize}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="${this.iconsColor}" d="M${this.iconsSize} 22a10 10 0 1 1 10-10 10 10 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8 8 0 0 0-8-8z"/></svg>`;
    this.radioChecked = `<svg width="${this.iconsSize}" height="${this.iconsSize}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="${this.iconsColor}"><path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/><path d="M12 7a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"/></g></svg>`;
    this.lineSvg = `<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="10" y2="0" stroke="black" /></svg>`;
  }

  _createCheck(type = 'radio', checked, str = '', w = 'auto') {
    let svg = checked ? this.checkboxChecked : this.checkbox;
    if (type === 'radio') svg = checked ? this.radioChecked : this.radio;

    return {
      width: w,
      margin: [-3, 0, 7, 0],
      table: {
        widths: [1, 'auto'],
        body: [
          [
            {
              svg,
              margin: [-3, 3, 0, 0],
              border: [false, false, false, false],
            },
            { margin: [3, 5, 0, 0], border: [false, false, false, false], text: str },
          ],
        ],
      },
    };
  }

  _createContactPersonFields(person) {
    const functionDisplay = options.personFunctionOptions.find(f => f.value === person.function);
    return {
      columns: [
        { width: '*', stack: [{ text: 'Imię', style: 'fieldHeading' }, { text: person.name ? person.name : '-' }] },
        {
          width: '*',
          stack: [{ text: 'Nazwisko', style: 'fieldHeading' }, { text: person.lastname ? person.lastname : '-' }],
        },
        {
          width: '*',
          stack: [{ text: 'Adres email', style: 'fieldHeading' }, { text: person.email ? person.email : '-' }],
        },
        {
          width: 90,
          stack: [{ text: 'Telefon', style: 'fieldHeading' }, { text: person.phone ? person.phone : '-' }],
        },
        {
          width: 90,
          stack: [
            { text: 'Funkcja', style: 'fieldHeading' },
            {
              text: person.function ? functionDisplay.display : '-',
            },
          ],
        },
      ],
    };
  }

  _createAdressFields(address) {
    return [
      {
        columns: [
          {
            stack: [
              { text: 'Nazwa ulicy', style: 'fieldHeading' },
              { text: address.streetName ? address.streetName : '-' },
            ],
          },
          {
            stack: [
              { text: 'Numer budynku', style: 'fieldHeading' },
              { text: address.buildingNumber ? address.buildingNumber : '-' },
            ],
          },
          {
            stack: [
              { text: 'Numer lokalu', style: 'fieldHeading' },
              { text: address.apartmentNumber ? address.apartmentNumber : '-' },
            ],
          },
        ],
      },
      {
        columns: [
          { stack: [{ text: 'Miasto', style: 'fieldHeading' }, { text: address.city ? address.city : '-' }] },
          {
            stack: [{ text: 'Kod pocztowy', style: 'fieldHeading' }, { text: address.zipCode ? address.zipCode : '-' }],
          },
          { stack: [{ text: 'Kraj', style: 'fieldHeading' }, { text: address.country ? address.country : '-' }] },
        ],
      },
      {
        columns: [
          { stack: [{ text: 'Powiat', style: 'fieldHeading' }, { text: address.county ? address.county : '-' }] },
          { stack: [{ text: 'Gmina', style: 'fieldHeading' }, { text: address.community ? address.community : '-' }] },
          {
            stack: [
              { text: 'Województwo', style: 'fieldHeading' },
              {
                text: address.voivodeship
                  ? address.voivodeship.charAt(0).toUpperCase() + address.voivodeship.slice(1).toLowerCase()
                  : '-',
              },
            ],
          },
        ],
      },
      { text: '', style: 'gap' },
    ];
  }

  _createTerminalFields(terminal, i) {
    const notFirst = i !== 0;
    return {
      // unbreakable: true,
      stack: [
        notFirst ? { text: '', style: 'gap' } : null,
        {
          columns: [
            [
              { text: 'Typ terminala', style: ['block'] },
              this._createChecks(options.terminalTypeOptions, 'radio', terminal.type),
            ],
            [
              { text: 'Rodzaj łączności', style: ['block'] },
              this._createChecks(options.terminalConnectionOptions, 'radio', terminal.connectionType),
            ],
          ],
        },
        { text: null, style: 'gap' },
        {
          columns: [
            [
              { text: 'Montaż terminala', style: ['block'] },
              this._createChecks(options.terminalInstallationOptions, 'radio', terminal.installationType),
            ],
            [{ text: 'Liczba terminali', style: ['block'] }, { text: terminal.count }],
          ],
        },
        { text: null, style: 'gap' },
        { text: 'Uwagi', style: ['gapSmall', 'block'] },
        { text: terminal.notes ? terminal.notes : 'Brak' },
      ],
    };
  }

  _createPointField(point, i, arr) {
    const pickedMcc = mcc.find(m => m.value === point.industry);
    const { ponPt, sob, niedz } = point.workSchedule;
    return {
      unbreakable: true,
      stack: [
        { text: `# Punkt sprzedaży ${arr.length > 1 ? '- #' + (i + 1) : ''}`, style: ['section'] },
        {
          columns: [
            {
              stack: [
                {
                  text: 'Nazwa punktu',
                  style: ['block'],
                },
                {
                  text: point.name,
                  style: ['field'],
                  bold: true,
                },
              ],
            },
            {
              stack: [
                {
                  text: 'Rachunek bankowy punktu',
                  style: ['block'],
                },
                {
                  text: point.pointBankAccount,
                  style: ['field'],
                  characterSpacing: 0.2,
                  bold: true,
                },
              ],
            },
            { stack: [] },
          ],
        },
        {
          text: 'Adres punktu',
          style: ['block'],
        },
        point.address.sameAs
          ? {
              text: 'Taki sam jak siedziby',
              style: 'field',
            }
          : this._createAdressFields(point.address),
        { text: 'Osoba kontaktowa', style: ['block'] },
        {
          // unbreakable: true,
          stack: [
            {
              columns: [
                {
                  stack: [
                    { text: 'Imię', style: 'fieldHeading' },
                    { text: point.contactPerson.name ? point.contactPerson.name : '-' },
                  ],
                },
                {
                  stack: [
                    { text: 'Nazwisko', style: 'fieldHeading' },
                    { text: point.contactPerson.lastname ? point.contactPerson.lastname : '-' },
                  ],
                },
                {
                  stack: [
                    { text: 'Numer telefonu', style: 'fieldHeading' },
                    { text: point.contactPerson.phone ? point.contactPerson.phone : '-' },
                  ],
                },
              ],
            },
            { text: null, style: 'gap' },
          ],
        },
        {
          stack: [
            {
              text: 'Branża',
              style: ['block'],
            },
            {
              text: pickedMcc ? pickedMcc.display : '-',
              style: ['field'],
            },
          ],
        },
        {
          // unbreakable: true,
          stack: [
            {
              text: 'Godziny otwarcia',
              style: ['gap', 'block'],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      stack: [
                        { text: 'Pon-Pt', bold: true },
                        ponPt.from || ponPt.to
                          ? { text: `od:   ${ponPt.from ? ponPt.from : '-'}   do:   ${ponPt.to ? ponPt.to : '-'}` }
                          : { text: 'Nie podano' },
                      ],
                    },
                    {
                      stack: [
                        { text: 'Sobota', bold: true },
                        sob.from || sob.to
                          ? { text: `od:   ${sob.from ? sob.from : '-'}   do:   ${sob.to ? sob.to : '-'}` }
                          : { text: 'Nie podano' },
                      ],
                    },
                    {
                      stack: [
                        { text: 'Niedziela', bold: true },
                        niedz.from || niedz.to
                          ? { text: `od:   ${niedz.from ? niedz.from : '-'}   do:   ${niedz.to ? niedz.to : '-'}` }
                          : { text: 'Nie podano' },
                      ],
                    },
                  ],
                },
                { text: null, style: 'gap' },
              ],
            },
          ],
        },
        { text: null, style: 'gap' },
        { text: null, style: 'gap' },
        {
          stack: [
            {
              text: 'Terminal',
              style: ['title'],
            },
            ...point.terminals.map(t => this._createTerminalFields(t)),
          ],
        },
      ],
    };
  }

  _sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  _createChecks(option, type = 'radio', value, maxInRow = 3, maxLettersInRow = 35) {
    const allLetters = option.reduce((acc, b) => acc + b.display.length, 0);
    const rowsByLetters = Math.ceil(allLetters / maxLettersInRow);
    const rowsByElements = Math.ceil(option.length / maxInRow);
    const rows = Math.max(rowsByLetters, rowsByElements);
    const elementsInRow = Math.ceil(option.length / rows);
    const chunks = this._sliceIntoChunks(option, elementsInRow);

    return chunks.map(ch => ({
      columns: ch.map(item => {
        const isChecked = Array.isArray(value)
          ? value.findIndex(v => v === item.nameValue) !== -1
          : item.value === value;
        return this._createCheck(type, isChecked, item.display, rows > 1 ? '*' : 'auto');
      }),
    }));
  }

  // STEPS
  _createStep1() {
    return {
      unbreakable: true,
      stack: [
        { text: '# Dane firmowe', style: ['gapTopBig', 'section'] },
        {
          stack: [
            {
              text: 'Adres email',
            },
            {
              text: ' (na wskazany adres zostanie wysłana umowa do podpisu)',
              style: ['orange', 'bold', 'small'],
            },
          ],
          style: ['block'],
        },
        {
          text: this.data.addresses.email,
          style: ['field'],
        },
        {
          text: 'Adres siedziby',
          style: ['block'],
        },
        this._createAdressFields(this.data.addresses.main),
        {
          text: 'Adres do faktur',
          style: ['block'],
        },
        this.data.addresses.invoices.sameAs
          ? {
              text: 'Taki sam jak siedziby',
              style: 'field',
            }
          : this._createAdressFields(this.data.addresses.invoices),
        {
          text: 'Adres do korespondencji',
          style: ['block'],
        },
        this.data.addresses.mailling.sameAs
          ? {
              text: 'Taki sam jak siedziby',
              style: 'field',
            }
          : this._createAdressFields(this.data.addresses.mailling),
      ],
      style: 'spaceBottomBig',
    };
  }

  _createStep2() {
    return {
      unbreakable: true,
      stack: [
        { text: '# Rozliczenia', style: ['section'] },
        {
          text: 'Numer rachunku bankowego',
          style: ['block'],
        },
        {
          text: this.data.settlements.bankAccount,
          style: ['field'],
          characterSpacing: 0.2,
          bold: true,
        },
        {
          columns: [
            [
              { text: 'Program Polska Bezgotówkowa', style: ['block'] },
              this._createChecks(options.pbgOptions, 'radio', this.data.settlements.pbg),
            ],
            [
              { text: 'Poziom serwisu', style: ['block'] },
              this._createChecks(options.serviceTypeOptions, 'radio', this.data.settlements.serviceType),
            ],
          ],
        },
        this.data.settlements.pbg === 1 && this.data.settlements.pbgPwob
          ? [
              { text: 'Promocja Polska Bezgotówkowa', style: ['block'] },
              this._createChecks(options.pbgPromoPwobOptions, 'radio', this.data.settlements.pbgPwob, 3, 70),
            ]
          : null,
        {
          columns: [
            [
              { text: 'Ulga instalacyjna dla okresu współpracy powyżej', style: ['block'] },
              this._createChecks(options.periodOptions, 'radio', this.data.settlements.period),
            ],
            [
              { text: 'Rodzaj działalności', style: ['block'] },
              this._createChecks(options.businessLineOptions, 'radio', this.data.settlements.businessLine),
            ],
          ],
        },
        {
          columns: [
            [
              { text: 'Forma prawna', style: ['block'] },
              this.data.version === 'OLD'
                ? this._createChecks(options.legalFormOptions, 'radio', this.data.settlements.legalForm, 2)
                : {
                    text: matrixArr.find(el => el.value === this.data.settlements.legalFormAML)?.name || '-',
                    style: ['field'],
                  },
            ],
            [],
          ],
        },
      ],
    };
  }

  _createStep3() {
    const labelsWidth = 150;
    const valuesWidth = 57;
    return {
      pageBreak: 'before',
      unbreakable: true,
      stack: [
        { text: '# Warunki handlowe', style: ['section'] },
        {
          columns: [
            {
              width: labelsWidth,
              text: 'Model rozliczenia:',
              style: ['inlineWithCheck', 'block'],
            },
            this._createChecks(
              [
                { display: 'Prowizja', value: 'commission' },
                { display: 'Marża stała', value: 'margin' },
              ],
              'radio',
              this.data.terms.model,
            ),
          ],
        },
        { text: null, style: ['gap'] },
        this.data.terms.model === 'commission'
          ? [
              {
                columns: [
                  {
                    width: labelsWidth,
                    text: 'Wartość prowizji:',
                    style: ['block'],
                  },
                  {
                    width: valuesWidth,
                    text: this.data.terms.commission.fee + ' %',
                    style: ['block'],
                  },
                  {
                    columns: [
                      {
                        width: 12,
                        svg: this.data.terms.commission.individualTermsFee ? this.checkboxChecked : this.checkbox,
                        margin: [3, 10, 0, 0],
                      },
                      { text: 'Warunki indywidualne', margin: [7, 11, 0, 0] },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: labelsWidth,
                    text: 'Opłata stała:',
                    style: ['block'],
                  },
                  {
                    width: valuesWidth,
                    text: this.data.terms.commission.fixedFee + ' zł',
                    style: ['block'],
                  },
                  {
                    columns: [
                      {
                        width: 12,
                        svg: this.data.terms.commission.individualTermsFixedFee ? this.checkboxChecked : this.checkbox,
                        margin: [3, 10, 0, 0],
                      },
                      { text: 'Warunki indywidualne', margin: [7, 11, 0, 0] },
                    ],
                  },
                ],
              },
            ]
          : null,
        this.data.terms.model === 'margin'
          ? {
              columns: [
                {
                  width: labelsWidth,
                  text: 'Marża stała:',
                  style: ['block'],
                },
                {
                  width: valuesWidth,
                  text: this.data.terms.margin.value + ' %',
                  style: ['block'],
                },
                {
                  columns: [
                    {
                      width: 12,
                      svg: this.data.terms.margin.individualTermsMargin ? this.checkboxChecked : this.checkbox,
                      margin: [3, 10, 0, 0],
                    },
                    { text: 'Warunki indywidualne', margin: [7, 11, 0, 0] },
                  ],
                },
              ],
            }
          : null,
        { text: null, style: ['gap'] },
        this.data.terms.terminalPinPad !== 0
          ? {
              columns: [
                {
                  width: labelsWidth,
                  text: 'Najem PinPad:',
                  style: ['block'],
                },
                {
                  width: valuesWidth,
                  text: this.data.terms.terminalPinPad + ' zł',
                  style: ['block'],
                },
                {
                  columns: [
                    {
                      width: 12,
                      svg: this.data.terms.individualTerminalPinPad ? this.checkboxChecked : this.checkbox,
                      margin: [3, 10, 0, 0],
                    },
                    { text: 'Warunki indywidualne', margin: [7, 11, 0, 0] },
                  ],
                },
              ],
            }
          : null,
        {
          columns: [
            {
              width: labelsWidth,
              text: 'Najem terminal stacjonarny:',
              style: ['block'],
            },
            {
              width: valuesWidth,
              text: this.data.terms.terminalStationary + ' zł',
              style: ['block'],
            },
            {
              columns: [
                {
                  width: 12,
                  svg: this.data.terms.individualTerminalStationary ? this.checkboxChecked : this.checkbox,
                  margin: [3, 10, 0, 0],
                },
                { text: 'Warunki indywidualne', margin: [7, 11, 0, 0] },
              ],
            },
          ],
        },
        {
          columns: [
            {
              width: labelsWidth,
              text: 'Najem terminal przenośny:',
              style: ['block'],
            },
            {
              width: valuesWidth,
              text: this.data.terms.terminalMobile + ' zł',
              style: ['block'],
            },
            {
              columns: [
                {
                  width: 12,
                  svg: this.data.terms.individualTerminalMobile ? this.checkboxChecked : this.checkbox,
                  margin: [3, 10, 0, 0],
                },
                { text: 'Warunki indywidualne', margin: [7, 11, 0, 0] },
              ],
            },
          ],
        },
      ],
    };
  }

  _createStep4() {
    return {
      stack: [
        { text: '# Osoby kontaktowe', style: ['gapTopBig', 'section'] },
        {
          unbreakable: true,
          stack: [
            { text: 'Kontakt w sprawach finansowych', style: ['block'] },
            this._createContactPersonFields(this.data.contact.financialMatters),
            { text: null, style: 'gap' },
          ],
        },
        {
          unbreakable: true,
          stack: [
            { text: 'Kontakt w sprawach operacyjnych', style: ['block'] },
            this.data.contact.operationalMatters.sameAs
              ? { text: 'Taki sam jak w sprawach finansowych', style: 'field' }
              : [this._createContactPersonFields(this.data.contact.operationalMatters), { text: null, style: 'gap' }],
          ],
        },
        {
          unbreakable: true,
          stack: [
            { text: 'Kontakt w sprawach biznesowych', style: ['block'] },
            this.data.contact.businessMatters.sameAs
              ? { text: 'Taki sam jak w sprawach finansowych', style: 'field' }
              : [this._createContactPersonFields(this.data.contact.businessMatters), { text: null, style: 'gap' }],
          ],
        },
        this.data.contact.owners[0]
          ? {
              unbreakable: true,
              stack: [
                { text: 'Właściciel', style: ['block'] },
                this.data.contact.owners[0].sameAs
                  ? { text: 'Taki sam jak w sprawach finansowych', style: 'field' }
                  : {
                      stack: [
                        ...this.data.contact.owners.map(o => this._createContactPersonFields(o)),
                        { text: null, style: 'gap' },
                      ],
                    },
              ],
            }
          : null,
        {
          unbreakable: true,
          stack: [
            { text: 'Osoba zarządzająca', style: ['block'] },
            this.data.contact.personInCharge.sameAs
              ? { text: 'Taki sam jak w sprawach finansowych', style: 'field' }
              : [this._createContactPersonFields(this.data.contact.personInCharge), { text: null, style: 'gap' }],
          ],
        },
        {
          unbreakable: true,
          stack: [
            { text: 'Osoba podpisująca umowę', style: ['block'] },
            this.data.contact.signatory[0].sameAs
              ? { text: 'Taki sam jak w sprawach finansowych', style: 'field' }
              : {
                  stack: [
                    ...this.data.contact.signatory.map(s => this._createContactPersonFields(s)),
                    { text: null, style: 'gap' },
                  ],
                },
          ],
        },
      ],
    };
  }

  _createStep5() {
    return {
      stack: [this.data.points.map(this._createPointField.bind(this))],
    };
  }

  _createStep6() {
    return {
      pageBreak: 'before',
      style: 'spaceBottomHuge',
      stack: [
        { text: '# Produkty i usługi dodatkowe', style: ['section'] },
        { text: null, style: ['gap'] },
        this._createChecks(options.productsAndServicesOptions, 'checkbox', this.data.products.items, 2),
      ],
    };
  }

  _createFinalInfo() {
    return {
      stack: [
        { text: '# Informacje', style: ['section'] },
        { text: null, style: ['gap'] },
        {
          text:
            'Przedstawiona oferta ma charakter informacyjny, nie stanowi oferty handlowej w rozumieniu art. 66 § 1 Kodeksu Cywilnego (t.j. Dz. U. z 2020 r. poz. 1740, 2320, z 2021 r. poz. 1509, 2459).\n\n\n\n',
        },
        { text: 'Strony oświadczają, że zarejestrowane dane są zgodne ze stanem faktycznym.\n\n\n\n' },
        {
          columns: [
            {
              stack: [
                { text: 'Podpis Partnera PEP', style: ['spaceBottomBig'] },
                {
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 125,
                      h: 0,
                      r: 8,
                      lineWidth: 0.5,
                    },
                  ],
                },
              ],
            },
            {
              stack: [
                { text: 'Podpis klienta', style: ['spaceBottomBig'] },
                {
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 125,
                      h: 0,
                      r: 8,
                      lineWidth: 0.5,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  }

  _createContent() {
    return [
      {
        text: `Podsumowanie danych`,
        style: ['title'],
      },
      {
        text: `${this.data.name} / ${this.data.nip}`,
        style: ['gap', 'title'],
      },
      this._createStep1(),
      this._createStep2(),
      this._createStep3(),
      this._createStep4(),
      this._createStep5(),
      this._createStep6(),
      this._createFinalInfo(),
    ];
  }

  _createDefinition() {
    return {
      info: {
        title: `Formularz rejestracyjny umowy w CRM`,
        author: 'PeP - Formularze',
      },
      pageSize: 'A4',
      pageMargins: [30, 30, 30, 30],
      content: this._createContent(),
      defaultStyle: {
        fontSize: 9,
        lineHeight: 1.2,
      },
      styles: {
        title: {
          fontSize: 12,
          bold: true,
        },
        section: {
          fontSize: 12,
          bold: true,
          color: '#F18819',
          margin: [0, 0, 0, 0],
        },
        block: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 0],
        },
        inlineWithCheck: {
          margin: [0, 6, 0, 0],
        },
        field: {
          //   bold: true,
          margin: [0, 4, 0, 8],
        },
        fieldHeading: {
          fontSize: 8,
          margin: [0, 5, 0, 0],
        },
        small: {
          fontSize: 8,
        },
        left: {
          alignment: 'left',
        },
        center: {
          alignment: 'center',
        },
        right: {
          alignment: 'right',
        },
        bold: {
          bold: true,
        },
        orange: {
          color: '#F18819',
        },
        spaceBottomHuge: {
          margin: [0, 0, 0, 40],
        },
        spaceBottomBig: {
          margin: [0, 0, 0, 20],
        },
        spaceBottom: {
          margin: [0, 0, 0, 12],
        },
        spaceBottomSmall: {
          margin: [0, 0, 0, 6],
        },
        gap: {
          margin: [0, 0, 0, 6],
        },
        gapSmall: {
          margin: [0, 0, 0, 3],
        },
        gapTop: {
          margin: [0, 6, 0, 0],
        },
        gapTopBig: {
          margin: [0, 20, 0, 0],
        },
      },
    };
  }

  getPdf() {
    return this.pdf;
  }

  getDefinition() {
    return this.pdfDefinition;
  }

  createDefinition() {
    const definition = this._createDefinition();
    this.pdfDefinition = definition;
    return definition;
  }

  build() {
    this.createDefinition();
    this.pdf = pdfMake.createPdf(this.pdfDefinition);
    return this.pdf;
  }

  download(filename) {
    if (!this.pdf) throw new Error('Brak wygenerowanego pdf');
    try {
      this.pdf.download(filename || `Test.pdf`);
    } catch (err) {
      throw new Error(err);
    }
  }
}
