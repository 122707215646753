import { integrationDocsService } from 'services/integrationDocs';
import * as types from './types';

export const getIntegrationDocs = query => dispatch => {
  dispatch({ type: types.GET_INTEGRATION_DOCS_REQUEST });

  integrationDocsService
    .getIntegrationDocs(query)
    .then(payload => {
      dispatch({ type: types.GET_INTEGRATION_DOCS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_INTEGRATION_DOCS_FAILURE, err });
    });
};

export const getIntegrationDoc = id => dispatch => {
  dispatch({ type: types.GET_INTEGRATION_DOC_REQUEST });

  integrationDocsService
    .getIntegrationDoc(id)
    .then(payload => {
      dispatch({ type: types.GET_INTEGRATION_DOC_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_INTEGRATION_DOC_FAILURE, err });
    });
};

export const onUploadProgress = progress => dispatch => {
  dispatch({ type: types.SET_INTEGRATION_DOC_UPLOAD_PROGRESS, payload: progress });
};

export const setCurrentIntegrationDoc = doc => dispatch => {
  dispatch({ type: types.SET_CURRENT_INTEGRATION_DOC, payload: doc });
};

export const clearCurrentIntegrationDoc = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_INTEGRATION_DOC });
};

export const clearIntegrationDocs = () => dispatch => {
  dispatch({ type: types.CLEAR_INTEGRATION_DOC });
};
