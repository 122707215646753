import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addNotificationWidget,
  setNotificationsWidget,
  toggle,
  markAsReadedOne,
  clearReducer,
  markAsReadedAllWidget,
  clearNotificationsWidget,
} from 'redux/notifications';
import { colors } from 'theme/styles';
import { routes } from 'routes';
import { setError } from 'redux/socket';
import { socket } from 'services/socket';
import N from 'components/UI/Notifications';
import Heading from 'components/UI/Heading';
import SimpleButton from 'components/UI/SimpleButton';

// const mapModeToRead = mode => {};
// const LIMIT = 20;

export default function Notification() {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [noMore, setNoMore] = useState(false);

  const isSocketConnected = useSelector(state => state.socket.connected);
  const isUserLoaded = useSelector(state => state.user.isUserLoaded);
  const notifications = useSelector(s => s.notifications.dataWidget);
  const isOpen = useSelector(state => state.notifications.isOpen);

  // const [mode, setMode] = useState('all');

  useEffect(() => {
    if (!isSocketConnected) return () => {};

    const handleFn = (response) => {
      if (!response) return;
      if (response.id) dispatch(addNotificationWidget(response));
    }
    socket.on('notifications:list', handleFn);

    return () => {
      socket.off('notifications:list', handleFn);
      dispatch(clearReducer());
    };
  }, [dispatch, isSocketConnected]);

  useEffect(() => {
    if (!isSocketConnected) return () => {};
    socket.emit(
      'notifications:list',
      {
        offset: 0,
        limit: 20,
        onlyNotSeen: true,
      },
      response => {
        dispatch(clearNotificationsWidget());
        if (!response) return;
        if (response.data && response.data.length) dispatch(setNotificationsWidget(response.data));
      },
    );
    return () => {};
  }, [dispatch, isSocketConnected]);

  const onRead = (type, id) => {
    if (!isSocketConnected) return;
    socket.emit(
      'notifications:mark-as-read',
      {
        ...(type === 'all' && { markAllAsRead: true }),
        ...(type === 'allLocal' && { notifications: JSON.stringify(notifications.map(n => n.id)) }),
        ...(type === 'single' && { notifications: JSON.stringify([id]) }),
      },
      response => {
        if (response.isOk) {
          if (type === 'single') dispatch(markAsReadedOne(id));
          else dispatch(markAsReadedAllWidget());
        } else {
          const error = response.error?.name || 'Wystąpił błąd.';
          dispatch(setError(error));
        }
      },
    );
  };

  const handleClick = n => {
    onRead('single', n.id);
    dispatch(toggle());
    history.push(routes.notifications, { showNotification: { ...n } });
  };

  // Render

  if (!isUserLoaded) return null;

  return (
    <N isOpen={isOpen}>
      <N.Wrapper isOpen={isOpen}>
        <N.Header>
          <Heading color={colors.mediumGray} size="xm">
            Powiadomienia
          </Heading>
          <SimpleButton
            underline
            red
            onClick={() => {
              onRead('allLocal');
            }}
            disabled={notifications.length === 0 || false}
          >
            Wyczyść wszystko
          </SimpleButton>
          {/* <Dropdown>
            <Dropdown.Item
              disabled={notifications.filter(n => Boolean(!n.isSeen)).length === 0 || false}
              onClick={() => onRead('allLocal')}
            >
              Oznacz widoczne jako przeczytane
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item color={colors.red} onClick={() => onRead('all')}>
              Oznacz wszystkie jako przeczytane
            </Dropdown.Item>
          </Dropdown> */}
          {/* <Dropdown.Divider />
            <Dropdown.Item color={colors.darkGray} disabled>Filtrowanie wg. typu: </Dropdown.Item>
            <Dropdown.Item active={mode === 'all'} onClick={() => setMode('all')}>
              Wszystkie
            </Dropdown.Item>
            <Dropdown.Item active={mode === 'info'} onClick={() => setMode('info')}>
              Informacyjne
            </Dropdown.Item>
            <Dropdown.Item active={mode === 'succes'} onClick={() => setMode('succes')}>
              Sukces
            </Dropdown.Item>
            <Dropdown.Item active={mode === 'warn'} onClick={() => setMode('warn')}>
              Ostrzeżenia
            </Dropdown.Item>
            <Dropdown.Item active={mode === 'error'} onClick={() => setMode('error')}>
              Błędy
            </Dropdown.Item> */}
          {/* <Dropdown.Divider />
            <Dropdown.Item color={colors.red} onClick={() => {}}>
              Usuń wszystkie powiadomienia
            </Dropdown.Item> */}
          <N.HeaderCloseIcon onClick={() => dispatch(toggle())} />
        </N.Header>
        <N.List>
          {notifications.length > 0 ? (
            notifications.map(n => (
              <N.Item
                disableExpand
                key={n.id}
                id={n.id}
                type={n.type || ''}
                title={n.subject || ''}
                text={n.content || ''}
                // isSeen={Boolean(n.isSeen)}
                date={n.createdAt}
                onClick={() => handleClick(n)}
              />
            ))
          ) : (
            <Heading color={colors.mediumGray} centered size="xm">
              Brak powiadomień
            </Heading>
          )}
        </N.List>
        <N.Footer>
          {/* {notifications.length > 0 && ( */}
          <SimpleButton
            underline
            blue
            onClick={() => {
              dispatch(toggle());
              history.push(routes.notifications);
            }}
            // disabled={noMore}
          >
            Przejdź do powiadomień
            {/* {noMore ? 'Brak starszych powiadomień' : 'Pokaż więcej'} */}
          </SimpleButton>
          {/* )} */}
        </N.Footer>
      </N.Wrapper>
    </N>
  );
}
