/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/UI/Button';
import SimpleCheckbox from 'components/UI/SimpleCheckbox';

const CountiesList = ({ counties, initialPicked, saveHandler }) => {
  const [pickedCounties, setPickedCounties] = useState(initialPicked);
  const addCountie = countie => setPickedCounties(old => [...old, countie]);
  const removeCountie = countie => setPickedCounties(old => old.filter(c => c.id !== countie.id));

  return (
    <Wrapper>
      <List>
        {counties
          .map(rawC => {
            if (pickedCounties.some(picked => picked.id === rawC.id)) rawC.isChecked = true;
            else rawC.isChecked = false;
            return rawC;
          })
          .map(c => (
            <SimpleCheckbox
              key={c.id}
              item={c}
              onChange={e => (e.target.checked ? addCountie(c) : removeCountie(c))}
            />
          ))}
      </List>
      <Button small secondary centered onClick={() => saveHandler(pickedCounties)}>
        Dodaj
      </Button>
    </Wrapper>
  );
};

CountiesList.defaultProps = {
  initialPicked: [],
};

CountiesList.propTypes = {
  counties: PropTypes.instanceOf(Array).isRequired,
  initialPicked: PropTypes.instanceOf(Array),
  saveHandler: PropTypes.func.isRequired,
};

export default CountiesList;

// styled components

const Wrapper = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const List = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;
