import * as types from './types';

const initialState = {
  info: {
    data: {},
    isLoading: false,
    error: '',
  },
  history: {
    data: [],
    isLoading: false,
    error: '',
  },
  notes: {
    data: [],
    isLoading: false,
    error: '',
  },
};

const clientPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLIENT_PARTNER_INFO_REQUEST:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: true,
        },
      };
    case types.GET_CLIENT_PARTNER_INFO_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_CLIENT_PARTNER_INFO_FAILURE:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_CLIENT_PARTNER_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: true,
        },
      };
    case types.GET_CLIENT_PARTNER_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_CLIENT_PARTNER_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: false,
          error: action.err?.response?.data?.error || action.err,
        },
      };
    case types.GET_CLIENT_PARTNER_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: true,
        },
      };
    case types.GET_CLIENT_PARTNER_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_CLIENT_PARTNER_NOTES_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    default:
      return state;
  }
};

export default clientPartnerReducer;
