import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notificationsService } from 'services/notifications';
import { breakpoints, colors, font } from 'theme/styles';
import { notificationsTypes } from 'config/constants';
import { displayTimeUtc, download } from 'utils/helpers';
import SimpleButton from 'components/UI/SimpleButton';
import Heading from '../Heading';

const TYPES = notificationsTypes.reduce((obj, el) => {
  if (!Object.hasOwnProperty.call(obj, el.name)) obj[el.name] = el.name;
  return obj;
}, {});

const handleTypeColor = type => {
  switch (type) {
    case TYPES.INFO:
      return `color: ${colors.notiBlue};`;
    case TYPES.ERROR:
      return `color: ${colors.notiError};`;
       case TYPES.WARNING:
      return `color: ${colors.notiWarn};`;
    case TYPES.SUCCESS:
      return `color: ${colors.notiSucc};`;
    default:
      return `color: ${colors.mediumGray};`;
  }
};

const getIcon = type => notificationsTypes.find(el => el.name === type).icon || null;

const handleDownload = (id, fileName) => {
  notificationsService
    .getNotificationsFile(id)
    .then(res => download(res, fileName))
    .catch(err => console.dir(err));
};

const NotificationItem = ({
  id,
  type,
  title,
  text,
  isSeen,
  onClick,
  advanced,
  attachment,
  date,
  marked,
  disableExpand,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(marked);
  // const textRef = useRef(null);
  // const [expandable, setExpandable] = useState(false);

  // const calcExpandable = useCallback(() => {
  //   if (!textRef.current) return;
  //   if (textRef.current.getBoundingClientRect().width > textRef.current.parentElement.getBoundingClientRect().width)
  //     setExpandable(true);
  // }, []);

  // useEffect(() => {
  //   calcExpandable();

  //   const debouncedHandleResize = debounce(function handleResize() {
  //     calcExpandable();
  //   }, 1000);

  //   window.addEventListener('resize', debouncedHandleResize);
  //   return () => window.removeEventListener('resize', debouncedHandleResize);
  // }, [calcExpandable]);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper
      type={type}
      {...rest}
      title={`${title}\n\n${text}\n\n${displayTimeUtc(date)}`}
      isSeen={isSeen}
      isExpanded={isExpanded}
      marked={marked}
      onClick={() => {
        // if (advanced && marked ? true : expandable) setIsExpanded(s => !s);
        if (!disableExpand) setIsExpanded(s => !s);
        onClick(id);
      }}
    >
      <StyledIcon icon={getIcon(type)} fixedWidth />
      <Content>
        <Heading size="m" style={{ marginBottom: '2px' }}>
          {title}
        </Heading>
        {/* { text && !isExpanded && (
          <p className="short">
            <span ref={textRef}>{text}</span>
          </p>
        )} */}
        {text && isExpanded && <p>{text}</p>}
      </Content>
      {advanced && (
        <Details>
          <div>
             {displayTimeUtc(date)} &nbsp; {<FontAwesomeIcon icon={isExpanded ? 'chevron-up' : 'chevron-down'} />}
          </div>
          {attachment && (
            <div>
              <SimpleButton
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  handleDownload(id, attachment);
                }}
              >
                Pobierz załącznik
              </SimpleButton>
            </div>
          )}
        </Details>
      )}
      {/* <XIcon icon="times" fixedWidth size="lg" onClick={onXClick} /> */}
    </Wrapper>
  );
};

NotificationItem.defaultProps = {
  text: '',
  type: '',
  children: null,
  onClick: () => {},
  advanced: false,
  date: '',
  attachment: '',
  isSeen: false,
  marked: false,
  disableExpand: false,
};

NotificationItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  isSeen: PropTypes.bool,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)),
  onClick: PropTypes.func,
  advanced: PropTypes.bool,
  date: PropTypes.string,
  attachment: PropTypes.string,
  marked: PropTypes.bool,
  disableExpand: PropTypes.bool,
};

export default NotificationItem;

// const XIcon = styled(FontAwesomeIcon)`
//   position: absolute;
//   top: 50%;
//   right: 0.5rem;
//   transform: translateY(-50%);
//   opacity: 0.25;
//   font-weight: 400;
//   color: ${colors.mediumGray};
//   cursor: pointer;
//   padding: 0.5rem;

//   &:hover {
//     opacity: 0.75 !important;
//   }
// `;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0 0.5rem;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 1rem;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid ${colors.blueGray};
  cursor: pointer;
  transition: background-color 0.3s;

  & + & {
    margin-top: 0.5rem;
  }

  &:hover {
    background-color: ${colors.lighterGray};
  }

  ${({ type }) => handleTypeColor(type)}

  ${({ isSeen }) =>
    isSeen &&
    css`
      opacity: 0.5;
    `}

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      opacity: 1;
    `}

      ${({ marked }) =>
        marked &&
        css`
          border-color: ${colors.greenText};
          order: 1;
        `}
    
  ${StyledIcon} {
    display: none;

    @media (min-width: ${breakpoints.mobileMinimum}) {
      display: block;
    }
  }
`;

const Content = styled.div`
  padding-right: 1rem;
  min-width: 0;
  flex: 1;

  @media (min-width: ${breakpoints.mobileMinimum}) {
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 1px solid ${colors.blueGray};
  }

  ${Heading} {
    line-height: 1.2;
    color: inherit;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: ${font.size.s};
  }

  p.short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Details = styled.div`
  margin: 0 0.5rem;
  flex-shrink: 0;
  align-self: flex-start;
  text-align: right;
  color: ${colors.darkGray};
`;
