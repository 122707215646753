import styled from 'styled-components';
import { font, colors } from 'theme/styles';

const ErrorLabel = styled.span`
  display: block;
  font-size: ${font.size.xs};
  color: ${colors.red};
  line-height: 1;
  transform: translateY(-8px);
  margin-bottom: -1rem;
  margin-top: 0px;
`;

export default ErrorLabel;
