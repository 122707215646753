/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeNullsAndEmpty, createLink } from 'utils/helpers';
import { BASE_URL } from 'config';
import { useUrl, useUrlOrLocaleStorage } from 'utils/hooks';
import { leadService } from 'services/lead';
import { getSortedLeads } from 'redux/lead';
import { getStructures, getPepStructures } from 'redux/admin';
import { logout } from 'redux/user';
import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import { colors } from 'theme/styles';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import DateFromTo from 'components/UI/DateFromTo';
import Select from 'components/UI/Select';
import SidePanel from 'components/layout/SidePanel';
import AddLead from 'components/forms/AddLead';
import LeadsTable from 'components/layout/Tables/LeadsTable';
import Pagination from 'components/UI/Pagination';
import Portal from 'components/logic/Portal';
import SuccessPopup from 'components/layout/SuccessPopup';
import Heading from 'components/UI/Heading';
import Loading from 'components/UI/Loading';
import TemplateBanner from 'templates/partials/TemplateBanner';

const LeadsPage = ({
  showDeleted,
  getStructures,
  getPepStructures,
  structures,
  // pepStructures,
  userRole,
  isLoading,
  getSortedLeads,
  leadsData,
  // leadsError,
  leadsCount,
  totalPages,
  currentPage,
  files,
}) => {
  // First element in array isLoading default param when isMerchant === true
  const stageEnumForMerchant = ['reported', 'assigned'];
  const isAdmin = userRole === 'admin';
  const isDsp = userRole === 'dsp';
  const isMerchant = userRole === 'merchantPep';
  const isPartner = userRole === 'partner';
  const isDr = userRole === 'dr';
  const isGuest = userRole === 'guest';

  const userData = useSelector((s) => s.user.data);

  const products = useSelector((s) => s.products.data);
  const blockAddLeadButton = useSelector((s) => s.user.data.blockAddLeadButton);

  const [bannerIsVisible, setBannerIsVisible] = useState(true);
  const [bannerPath, setBannerPath] = useState(null);

  const [exporting, setExporting] = useState(false);
  const [exportingError, setExportingError] = useState(null);

  // const [search, setSearch] = useUrl('search');
  const [leadFilterParams, setLeadFilterParams] = useState(null);
  const [isNewLeadVisible, setNewLeadVisible] = useState(false);
  const [sort, setSort] = useUrl('sort');
  const [structure, setStructure] = useUrl('structure');
  const [pickedProducts, setPickedProducts] = useUrl('products');

  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useUrl('page');
  const [limit, setLimit] = useUrl('limit');
  const [status, setStatus] = useUrl('status');
  const [excludePepPartner, setExcludePepPartner] = useUrlOrLocaleStorage('excludePepPartner', false, 'filterLeads3');
  const [date, setDate] = useUrl('date');
  const [, setStage] = useUrl('stage');

  const location = useLocation();
  const history = useHistory();

  const canExportLeads = () => {
    if (['admin', 'dsp', 'dr'].includes(userRole)) return true;
    if (['partner'].includes(userRole) && userData?.company?.partnerLeadExportsAccess === '1') return true;
    return false;
  };

  const activeBanner = useMemo(() => {
    if (!['merchantPep', 'partner'].includes(userRole)) return false;

    const closedBannersIds = JSON.parse(window.localStorage.getItem('banner'))?.closedBannersIds || [];
    let banner = null;
    let baseArr = null;

    if (['partner'].includes(userRole)) baseArr = files?.banners || null;
    else if (['merchantPep'].includes(userRole)) baseArr = files?.bannersPh || null;

    if (!baseArr) return false;

    baseArr = baseArr.filter((banner) => !closedBannersIds.includes(banner.id));
    banner = baseArr[Math.floor(Math.random() * baseArr.length)];
    
    if (!banner) return false;

    const banners = banner.fileName.split('###') || null;
    if (!banners) return false;

    banner.closed = false;
    banner.bannerMobile = banners[0];
    banner.bannerDesktop = banners[1];
    return banner;
  }, [files, userRole]);

  useEffect(() => {
    let path = files.partnerRootPath;
    if (['merchantPep'].includes(userRole)) path = files.phRootPath;
    setBannerPath(path);
  }, [files, userRole]);

  useEffect(() => {
    const searchObj = queryString.parse(location.search);
    const invalidMerchantUrl = !searchObj.stage || !stageEnumForMerchant.includes(searchObj.stage);
    if (isMerchant && invalidMerchantUrl) {
      setStage(stageEnumForMerchant[1]);
    }
  });

  const closeBannerHandler = () => {
    setBannerIsVisible(false);
    const closedBannersIds = [activeBanner.id];
    const existingIds = JSON.parse(window.localStorage.getItem('banner'))?.closedBannersIds;
    if (existingIds) closedBannersIds.push(...existingIds.filter((id) => id !== activeBanner.id));
    const item = { closedBannersIds };
    window.localStorage.setItem('banner', JSON.stringify(item));
  };

  const exportLeads = useCallback(() => {
    setExporting(true);
    setExportingError(null);
    // const ids = leadsData.map(lead => lead.id);
    leadService
      .exportLeadsExcel(undefined, leadFilterParams)
      .then((res) => {
        createLink(res, 'leads');
        setExporting(false);
      })
      .catch((err) => {
        setExportingError('Coś poszło nie tak. Skontaktuj się z administratorem.');
        console.log(err);
      });
  }, [leadFilterParams]);

  const cancelExport = () => {
    leadService.cancelExport();
    setExporting(false);
  };

  const headers = [
    { displayName: 'Zgłoszony', property: 'createdAt' },
    { displayName: 'LEAD ID', property: 'frontNumLead', advancedSearch: 'frontNumLead' },
    { displayName: 'NIP', property: 'nip', advancedSearch: 'nip' },
    { displayName: 'Nazwa firmy', property: 'companyName', advancedSearch: 'companyName' },
    // { displayName: 'Osoba Kontaktowa', property: 'contactPerson' },
    (isDsp || isMerchant || isAdmin || isGuest || isDr) && {
      displayName: 'Partner',
      property: 'userCompanyName',
      advancedSearch: 'userCompanyName',
    },
    !isMerchant && {
      displayName: 'Przypisany',
      property: 'queue',
      advancedSearch: 'assignedFullName',
    },
    (isDsp || isAdmin || isGuest || isDr) && {
      displayName: 'Podjęty',
      property: 'taken',
      advancedSearch: 'takenFullName',
    },
    (isDsp || isMerchant || isAdmin || isGuest || isDr) && {
      displayName: 'Leadmaster',
      property: 'leadMaster',
      advancedSearch: 'userCompanyLeadMasterAssocFullName',
    },
    (isDsp || isMerchant || isAdmin || isGuest || isPartner || isDr) && {
      displayName: 'Zgłosił',
      property: 'user',
      advancedSearch: 'addedBy',
    },
    { displayName: 'Status PeP', property: 'status' },
  ].filter(Boolean);

  const getAllLeads = useCallback(
    (signal) => {
      const queryData = queryString.parse(location.search);
      if (!queryData.excludePePartner) queryData.excludePepPartner = excludePepPartner;
      const queryDataStr = JSON.stringify(queryData);
      const validQueryData = removeNullsAndEmpty(queryData);
      const validQueryDataStr = JSON.stringify(validQueryData);
      if (queryDataStr !== validQueryDataStr) {
        history.replace(`${location.pathname}?${queryString.stringify(validQueryData)}`);
      } else {
        getSortedLeads({ ...queryData, showDeleted }, signal);
        setLeadFilterParams(queryData);
      }
    },
    [getSortedLeads, location.search, history, showDeleted, location.pathname, excludePepPartner],
  );

  useEffect(() => {
    const controller = new AbortController();

    const searchObj = queryString.parse(location.search);
    const invalidMerchantUrl = !searchObj.stage || !stageEnumForMerchant.includes(searchObj.stage);
    if (isMerchant && invalidMerchantUrl) return;
    getAllLeads(controller.signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllLeads]);

  useEffect(() => {
    if (['admin', 'dsp', 'guest'].includes(userRole)) {
      getStructures();
      getPepStructures();
    }
  }, [getStructures, getPepStructures, userRole]);

  const toggleNewLeadPanel = () => setNewLeadVisible(!isNewLeadVisible);
  const handleSort = (e) => setSort(e.target.value);
  const handleStatus = (e) => setStatus(e.target.value);
  const handleLimit = (e) => setLimit(e.target.value);
  const handleStructure = (e) => setStructure(e.target.value);
  const handleExcludePepPartner = (e) => setExcludePepPartner(e.target.value);

  const handleProducts = (e) => {
    const val = e.target.value;
    if (val === 'CLEAN') return setPickedProducts('');
    if (!pickedProducts) return setPickedProducts(val);
    const prods = pickedProducts.split('|');
    const tempArr = prods.includes(val) ? prods.filter((v) => v !== val) : [...prods, val];
    return setPickedProducts(tempArr.join('|'));
  };

  const LeadsTableSnippet = (
    <LeadsTable
      userRole={userRole}
      data={leadsData}
      headers={headers}
      offset={(currentPage - 1) * (limit || 20)}
      isLoading={isLoading}
    />
  );

  // Tu poleci cała logika w przyszłości
  const disableAddLead = () => {
    return blockAddLeadButton == 1;
  };

  return (
    <PageTemplate>
      <TopSection>
        <div>
          <Button
            onClick={toggleNewLeadPanel}
            disabled={disableAddLead() || ['merchantPep', 'guest'].includes(userRole)}
          >
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} />
            Dodaj potencjalnego Klienta
          </Button>
          {disableAddLead() && (
            <strong style={{ marginTop: '10px', display: 'inline-block' }} className="color-red">
              Możliwość dodawania leadów zawieszona systemowo.
            </strong>
          )}
        </div>
        <TableSorter>
          <SortItem>
            <span>
              Produkty
              {pickedProducts && <span className="color-red"> ({pickedProducts.split('|').length})</span>}
            </span>
            <Select small maxWidth="100px" value="" onChange={handleProducts}>
              <option value="">&nbsp;</option>
              {products &&
                products.map((product) => {
                  const isPicked = pickedProducts && pickedProducts.split('|').includes(product.id);
                  const isHidden = product.isVisible === '0';
                  const isAmbasador = product.ambassadorVisible === '1';
                  const isSoft = product.softProducentVisible === '1';
                  const isCasher = product.casherVisible === '1';
                  return (
                    <option
                      key={product.id}
                      value={product.id}
                      style={isPicked ? { background: colors.selectBlueLight } : {}}
                    >
                      {isPicked ? `${String.fromCharCode(215)} ${product.name}` : product.name}{' '}
                      {['admin', 'dsp'].includes(userRole) && (
                        <>
                          {isHidden && '- NIEWIDOCZNY'}
                          {isAmbasador && '- AMBASADOR'}
                          {isSoft && '- PROD. SOFT'}
                          {isCasher && '- KASIARZE'}
                        </>
                      )}
                    </option>
                  );
                })}
              <option value="CLEAN" style={{ background: colors.selectBlue, color: colors.white }}>
                Zresetuj
              </option>
            </Select>
          </SortItem>
          {(isAdmin || isDsp || isGuest) && (
            <SortItem>
              <span>Struktura</span>
              <Select small value={structure || ''} onChange={handleStructure}>
                <option value="">&nbsp;</option>
                {structures &&
                  structures.map((structure) => (
                    <option key={structure.id} value={structure.id}>
                      {structure.region}
                    </option>
                  ))}
              </Select>
            </SortItem>
          )}
          {/* {isMerchant && (
            <SortItem>
              <span>Stan</span>
              <Select small value={stage || ''} onChange={handleStage}>
                {!isMerchant ? <option value="">&nbsp;</option> : null}
                <option value="reported">Zgłoszone</option>
                <option value="assigned">Przypisane</option>
              </Select>
            </SortItem>
          )} */}
          <SortItem>
            <span>Status</span>
            <Select small value={status || ''} onChange={handleStatus}>
              <option value="">&nbsp;</option>
              <option value="new">Nowy</option>
              <option value="taken">Podjęty</option>
              <option value="duplicate">Zduplikowany</option>
              <option value="rejected_pep">Odrzucony - PeP</option>
              <option value="rejected_client">Odrzucony - Klient</option>
              <option value="rejected_ph">Odrzucony - PH</option>
              <option value="success">Terminal zainstalowano</option>
              <option value="import">Zaimportowany</option>
              <option value="in_progress">W trakcie</option>
              <option value="inactive">Nieaktywny</option>f
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={sort || 'DESC'} onChange={handleSort}>
              <option value="DESC">Najnowsze</option>
              <option value="ASC">Najstarsze</option>
            </Select>
          </SortItem>
          {['admin', 'dsp'].includes(userRole) && (
            <SortItem>
              <span>Leady z PeP</span>
              <Select small value={excludePepPartner || ''} onChange={handleExcludePepPartner}>
                <option value="true">Nie</option>
                <option value="false">Tak</option>
              </Select>
            </SortItem>
          )}
          <SortItem>
            <span>Okres leadowy</span>
            <DateFromTo handleDate={setDate} date={date} />
          </SortItem>
          {/* <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem> */}
        </TableSorter>
      </TopSection>
      {activeBanner && bannerPath ? (
        <TemplateBanner
          showBanner={
            Boolean(!activeBanner.closed && bannerIsVisible) &&
            ['partner', 'subPartner', 'merchantPep'].includes(userRole)
          }
        >
          <TemplateBanner.BannerHorizontal
            href={activeBanner.link || '#banner'}
            target={activeBanner.link ? '_blank' : undefined}
          >
            <TemplateBanner.Close onClick={closeBannerHandler} />
            <img
              src={`${BASE_URL}${bannerPath}${activeBanner.bannerMobile}`}
              alt={`${activeBanner.bannerMobile}-mobile`}
            />
          </TemplateBanner.BannerHorizontal>
          <TemplateBanner.Content>{LeadsTableSnippet}</TemplateBanner.Content>
          <TemplateBanner.BannerVertical
            href={activeBanner.link || '#banner'}
            target={activeBanner.link ? '_blank' : undefined}
          >
            <TemplateBanner.Close onClick={closeBannerHandler} />
            <img
              src={`${BASE_URL}${bannerPath}${activeBanner.bannerDesktop}`}
              alt={`${activeBanner.bannerMobile}-desktop`}
            />
          </TemplateBanner.BannerVertical>
        </TemplateBanner>
      ) : (
        LeadsTableSnippet
      )}
      {canExportLeads() && (
        <div style={{ marginBottom: '20px' }}>
          <Button small secondary disabled={!leadsData.length} onClick={exportLeads}>
            Eksportuj leady
          </Button>
        </div>
      )}
      <BottomSection>
        <BottomItem>Wszystkich leadów: {leadsCount}</BottomItem>
        <Pagination allowPageSet currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={limit || ''} onChange={handleLimit}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      <SidePanel toggleClose={toggleNewLeadPanel} isVisible={isNewLeadVisible}>
        <AddLead toggleClose={toggleNewLeadPanel} refetchLeads={getAllLeads} />
      </SidePanel>
      <Portal>
        {exporting && (
          <SuccessPopup fixed>
            {exportingError ? (
              <>
                <Heading>{exportingError}</Heading>
                <div className="buttons">
                  <Button
                    onClick={() => {
                      setExporting(false);
                      setExportingError(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Heading>Eksportuje leady...</Heading>
                <p>
                  Ta operacja może potrwać nawet kilka minut.
                  <br />
                  Nie zamykaj okna / karty przeglądarki.
                </p>
                <Loading />
                <div className="buttons">
                  <Button onClick={cancelExport}>Anuluj</Button>
                </div>
              </>
            )}
          </SuccessPopup>
        )}
      </Portal>
    </PageTemplate>
  );
};

LeadsPage.defaultProps = {
  showDeleted: null,
  structures: [],
  // pepStructures: [],
  leadsData: [],
  leadsCount: 0,
  totalPages: 0,
  currentPage: 1,
  files: {},
  // leadsError: null,
};

const mapStateToProps = (state) => ({
  structures: state.admin.structures.data,
  pepStructures: state.admin.pepStructure.data,
  userRole: state.user.data.role.slug,
  files: state.user.data.files,
  isLoading: state.leads.allLeads.isLoading,
  leadsError: state.leads.allLeads.error,
  leadsData: state.leads.allLeads.data,
  leadsCount: state.leads.allLeads.count,
  totalPages: state.leads.allLeads.totalPages,
  currentPage: state.leads.allLeads.currentPage,
});

const mapDispatchToProps = {
  getSortedLeads,
  getStructures,
  getPepStructures,
  logout,
};

LeadsPage.propTypes = {
  showDeleted: PropTypes.bool,
  getStructures: PropTypes.func.isRequired,
  getPepStructures: PropTypes.func.isRequired,
  structures: PropTypes.arrayOf(PropTypes.object),
  // pepStructures: PropTypes.arrayOf(PropTypes.object),
  userRole: PropTypes.string.isRequired,
  files: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool.isRequired,
  // leadsError: PropTypes.instanceOf(Object),
  leadsData: PropTypes.arrayOf(PropTypes.object),
  leadsCount: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  getSortedLeads: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsPage);
