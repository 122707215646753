/* eslint-disable no-param-reassign */
import moment from 'moment';
import { mapStatusObjShort, mapStatusToColor, rolesOrder } from 'config/constants';

export const resolveObjProp = (path, obj, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

export const removeNulls = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeNulls(val);
    else if (val == null) delete obj[key];
  });
  return { ...obj };
};

export const removeNullsAndEmpty = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeNulls(val);
    else if (val == null || val === '') delete obj[key];
  });
  return { ...obj };
};

export const sortProducts = (a, b) => {
  if (Object.hasOwnProperty.call(a, 'position') && Object.hasOwnProperty.call(b, 'position')) {
    return a.position - b.position;
  }
  return new Date(b.createdAt) - new Date(a.createdAt);
};

export const sortDateFromDateTo = (a, b) => {
  const aDateFrom = new Date(a.dateFrom);
  const bDateFrom = new Date(b.dateFrom);
  const aDateTo = new Date(a.dateTo);
  const bDateTo = new Date(b.dateTo);
  const n = bDateFrom - aDateFrom;
  return n !== 0 ? n : bDateTo - aDateTo;
};

export const sortDateToDateFrom = (a, b) => {
  const aDateFrom = new Date(a.dateFrom);
  const bDateFrom = new Date(b.dateFrom);
  const aDateTo = new Date(a.dateTo);
  const bDateTo = new Date(b.dateTo);
  const n = bDateTo - aDateTo;
  return n !== 0 ? n : bDateFrom - aDateFrom;
};

export const sortChallengesForPartner = (a, b) => {
  const active = Number(b.isActive) - Number(a.isActive);
  return active !== 0 ? active : Number(b.special) - Number(a.special);
};

export const sortByCreatedAt = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);

export const sortActiveThenCreatedAt = (a, b) => {
  const active = Number(b.active) - Number(a.active);
  return active !== 0 ? active : sortByCreatedAt(a, b);
};

export const sortRoles = (a, b) => rolesOrder[b.slug] - rolesOrder[a.slug];

export const sortAlphabetically = (data, sortBy) =>
  data.sort((a, b) => (a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1));

export const sortByProp = (data, sortBy) => {
  data.sort((a, b) => {
    const ra = resolveObjProp(sortBy, a);
    const rb = resolveObjProp(sortBy, b);
    if (typeof ra === 'string') return ra.toLowerCase() > rb.toLowerCase() ? 1 : -1;
    return ra > rb ? 1 : -1;
  });
};

export const validateValuesByRole = (values, role) => {
  const copyValues = { ...values };
  delete copyValues.countieSearch;
  delete copyValues.dedicatedPhSearch;
  delete copyValues.roleSlug;

  copyValues.leadMaster = copyValues.leadMaster?.id || copyValues.leadMaster || undefined;
  copyValues.companyId = copyValues.companyId?.id || copyValues.companyId || undefined;
  copyValues.dedicatedPhs =
    Array.isArray(copyValues.dedicatedPhs) && copyValues.dedicatedPhs?.length > 0
      ? JSON.stringify(copyValues.dedicatedPhs.map((p) => p.id))
      : copyValues.dedicatedPhs;

  if (role.slug !== 'merchantPep') {
    copyValues.counties = [];
  } else {
    copyValues.counties = JSON.stringify(copyValues.counties.map((c) => c.id));
  }

  if (role.slug !== 'partner' && role.slug !== 'subPartner') {
    delete copyValues.companyId;
    delete copyValues.dedicatedPhs;
  }

  if (role.slug !== 'partner') {
    delete copyValues.mainPartner;
    delete copyValues.coordinator;
  }

  return copyValues;
};

export const copyToClipboard = (text) => {
  const tempTextarea = document.createElement('textarea');
  document.body.appendChild(tempTextarea);
  tempTextarea.value = text;
  tempTextarea.select();
  document.execCommand('copy');
  document.body.removeChild(tempTextarea);
};

export const mapLeadsToStatus = (data) => {
  const tempData = data.reduce((obj, curr) => {
    Object.entries(curr.sorted_leads).forEach((lead) => {
      const status = lead[0];
      const leadsArr = lead[1].leads;
      if (Object.hasOwnProperty.call(obj, status)) obj[status].push(...leadsArr);
      else obj[status] = [...leadsArr];
    });
    return obj;
  }, {});

  tempData.all = Object.values(tempData).flat();

  return tempData;
};

export const transformToChartData = (data) => {
  const chartData = [];
  Object.entries(data).forEach((lead) => {
    const id = mapStatusObjShort[lead[0]] || lead[0];
    const color = mapStatusToColor[lead[0]] || '';
    const dataArr = lead[1];
    const dataArrChart = dataArr.reduce((arr, curr) => {
      arr.push({
        x: moment(curr.createdAt).format('YYYY-MM-DD'),
        y: 1,
      });
      return arr;
    }, []);
    chartData.push({
      id,
      color,
      data: dataArrChart,
    });
  });

  const tmp = sortAlphabetically(chartData, 'id');

  // Wstaw "Wszystkie" zawsze na początek, JEŻELI na koniec to:
  // if (fromIndex > -1) tmp.splice(tmp.length, 0, tmp.splice(fromIndex, 1)[0]);

  const fromIndex = tmp.findIndex((e) => e.id === mapStatusObjShort.all);
  if (fromIndex > -1) tmp.splice(0, 0, tmp.splice(fromIndex, 1)[0]);

  return tmp;
};

export const groupBy = (elements, interval, between) => {
  const startDate = moment(between[0]);
  const endDate = moment(between[1]);
  const f = 'YYYY-MM-DD';
  const uniqueDates = [];

  if (interval === '1d') {
    uniqueDates.push(startDate.format(f));
    for (let i = 0; i <= 31; i += 1) {
      const d = startDate.add(1, 'day');
      uniqueDates.push(d.format(f));
      if (d >= endDate) break;
    }
  } else {
    uniqueDates.push(startDate.startOf('month').format(f));
    for (let i = 0; i < 12; i += 1) {
      const d = startDate.startOf('month').add(1, 'month');
      uniqueDates.push(d.format(f));
      if (d >= endDate.startOf('month')) break;
    }
  }

  return elements.map((status) => ({
    ...status,
    data: uniqueDates.map((uniqDate) => ({
      x: uniqDate,
      y: status.data.filter((el) => {
        if (interval === '1d') return el.x === uniqDate;
        return moment(el.x).startOf('month').format(f) === uniqDate;
      }).length,
    })),
  }));
};

export const sortInvoices = (a, b) => {
  const dateA = a.FvN_DataDodania || a.DBF_DataWyst;
  const dateB = b.FvN_DataDodania || b.DBF_DataWyst;
  return new Date(dateB) - new Date(dateA);
};

export const sortBillingsDetails = (a, b) =>
  new Date(b.RoE_DataRejestracjiLeada) - new Date(a.RoE_DataRejestracjiLeada);

export const createLink = (res, prefix = 'sp_') => {
  // eslint-disable-next-line no-undef
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${prefix}__${moment().format('DD_MM_YY')}.xlsx`);
  document.body.appendChild(link);
  link.click();
};

export const download = (res, name) => {
  // eslint-disable-next-line no-undef
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export const countUniquePropValues = (arr, propName) =>
  arr.reduce((acc, el) => {
    const prop = el[propName];
    if (Object.prototype.hasOwnProperty.call(acc, prop)) {
      acc[prop] += 1;
    } else {
      acc[prop] = 1;
    }
    return acc;
  }, {});

export const formatMoney = (value, skipSymbol = false) => {
  if (typeof value === 'string') value = parseFloat(value);
  return Intl.NumberFormat(
    'pl-Pl',
    skipSymbol
      ? {}
      : {
          style: 'currency',
          currency: 'PLN',
          currencyDisplay: 'code',
        },
  ).format(value);
};

export const displayDate = (d) => moment(d).format('YYYY-MM-DD');
export const displayDateUtc = (d) => moment.utc(d).format('YYYY-MM-DD');
export const displayTime = (d) => moment(d).format('YYYY-MM-DD HH:mm');
export const displayTimeUtc = (d) => moment.utc(d).format('YYYY-MM-DD HH:mm');
export const displayTimeWithSeconds = (d) => moment(d).format('YYYY-MM-DD HH:mm:ss');
export const displayTimeWithSecondsUtc = (d) => moment.utc(d).format('YYYY-MM-DD HH:mm:ss');
export const displayHour = (d) => moment(d).format('HH:mm:ss');
export const displayHourUtc = (d) => moment.utc(d).format('HH:mm:ss');

export const getTrendIcon = (trend) => {
  let icon = 'times';
  let display = 'Trend: Błąd';
  let displayShort = ' Błąd';

  switch (trend) {
    case 'UP':
      icon = 'long-arrow-alt-up';
      display = 'Trend: rosnący';
      displayShort = 'Rosnący';
      break;
    case 'DOWN':
      icon = 'long-arrow-alt-down';
      display = 'Trend: malejący';
      displayShort = 'Malejący';
      break;
    case '=':
    case 'BALANCE':
      icon = 'balance-scale';
      display = 'Trend: balans';
      displayShort = 'Balans';
      break;
    case 'ZERO':
      icon = 'exclamation';
      display = 'Trend: brak nowych leadów';
      displayShort = 'Brak nowych leadów';
      break;
    default:
      break;
  }

  return [icon, display, displayShort];
};

const accentsMap = new Map([
  ['A', 'Ą'],
  ['a', 'ą'],
  ['C', 'Ć'],
  ['c', 'ć'],
  ['E', 'Ę'],
  ['e', 'ę'],
  ['L', 'Ł'],
  ['l', 'ł'],
  ['N', 'Ń'],
  ['n', 'ń'],
  ['O', 'Ó'],
  ['o', 'ó'],
  ['S', 'Ś'],
  ['s', 'ś'],
  ['Z', 'Ż|Ź'],
  ['z', 'ż|ź'],
]);

export const replaceAccents = (text) =>
  [...accentsMap].reduce((acc, [key]) => acc.replace(new RegExp(accentsMap.get(key), 'g'), key), text);

export const hslBaseColorFromString = (stringInput) => {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    // eslint-disable-next-line no-bitwise
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return stringUniqueHash % 360;
};

export const uniqElementsInArray = (a) => [...new Set(a)];

export const assignMessageToUser = (users, messages) => {
  if (Array.isArray(messages)) {
    return messages.map((msg) => {
      const messagesUsers = messages.map((m) => m.user || false).filter(Boolean);
      const user = uniqElementsInArray([...users, messagesUsers]).find((usr) => usr.id === msg.userId);
      return { ...msg, user };
    });
  }

  const user = messages.user
    ? uniqElementsInArray([...users, messages.user]).find((usr) => usr.id === messages.userId)
    : users.find((usr) => usr.id === messages.userId);
  return {
    ...messages,
    user,
  };
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const insertInArray = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

export const humanFileSize = (size) => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const mapUsersToCorespondingPartner = (incomingData) => {
  let data = JSON.parse(JSON.stringify(incomingData));
  const dataCopy = JSON.parse(JSON.stringify(incomingData));
  const partnersById = {};
  const subPartnersByPatronId = {};

  data.forEach((user) => {
    if (user.role.slug === 'partner') partnersById[user.id] = user;
    else if (user.role.slug === 'subPartner') {
      user.feSubPartner = true;
      if (Object.hasOwnProperty.call(subPartnersByPatronId, user.patronId)) {
        subPartnersByPatronId[user.patronId].push(user);
      } else {
        subPartnersByPatronId[user.patronId] = [user];
      }
    }
  });

  if (Object.entries(partnersById)) {
    // Sort partners by fullName before adding subpartners
    const sortedPartners = Object.entries(partnersById).sort((a, b) =>
      a[1].fullName.toLowerCase() > b[1].fullName.toLowerCase() ? 1 : -1,
    );

    data = sortedPartners.reduce((arr, [prop, val]) => {
      arr.push(val);

      if (subPartnersByPatronId[prop]) {
        // Sort sub-partners by fullName before push
        sortAlphabetically(subPartnersByPatronId[prop], 'fullName');
        arr.push(...subPartnersByPatronId[prop]);
      }
      return arr;
    }, []);
  }

  // Check if someone is not missing
  const missingUsers = dataCopy.filter((orgU) => !data.some((newU) => newU.id === orgU.id));

  if (missingUsers.length > 0) {
    missingUsers.forEach((u) => {
      delete u.feSubPartner;
      u.someError = true;
    });
    data = [...data, ...missingUsers];
  }

  return data;
};

export const transformNipForAlior = (nip) => {
  const digits = nip.split('').map((digit) => {
    let newDigit = parseInt(digit) + 2;
    if (newDigit === 10) newDigit = 0;
    if (newDigit === 11) newDigit = 1;
    return newDigit;
  });

  const letterMap = {
    0: 'B',
    1: 'Z',
    2: 'T',
    3: 'V',
    4: 'E',
    5: 'N',
    6: 'R',
    7: 'X',
    8: 'A',
    9: 'W',
  };

  for (let i = 1; i < digits.length; i += 2) {
    digits[i] = letterMap[digits[i]];
  }

  return digits.join('');
};
