import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { userService } from 'services/user';
import { getUserInfo } from 'redux/user';
import ImageInput from 'components/UI/ImageInput';

const UploadAvatar = ({ getUserInfo }) => {
  const handleSubmit = values => {
    userService
      .updateAvatar(values)
      .then(res => {
        console.log(res);
        getUserInfo();
      })
      .catch(err => console.log(err));
  };
  return (
    <Formik
      initialValues={{
        avatar: null,
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values);
        resetForm({});
        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <Field name="avatar" id="avatar" accept="image/png, image/jpeg" component={ImageInput} />
        </Form>
      )}
    </Formik>
  );
};

UploadAvatar.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
};

export default connect(null, { getUserInfo })(UploadAvatar);
