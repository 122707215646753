import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chip from 'components/UI/Chip';

const UsersList = ({ users, onRemove }) => {
  return (
    <Wrapper>
      {users.map(user => (
        <Chip key={`${user.id}`} onClick={() => onRemove(user.id)}>
          {`${user.name} ${user.surname}`}
        </Chip>
      ))}
    </Wrapper>
  );
};

UsersList.propTypes = {
  users: PropTypes.instanceOf(Array).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default UsersList;

// styled components

const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;
