import styled from 'styled-components';
import { font, colors } from 'theme/styles';

const Heading = styled.h2`
  font-size: ${({ size }) => font.size[size] || font.size.l};
  font-weight: ${font.weight.semibold};
  line-height: 25px;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  color: ${({ color }) => color || colors.black};
  margin: ${({ centered }) => (centered ? '0 auto' : '0')};
  white-space: pre-wrap;

  & > span.orange {
    color: ${colors.orange};
  }
`;

export default Heading;
