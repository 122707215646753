/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'theme/styles';



const SuccessPopup = ({ children, blur, className, fixed }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Wrapper blur={blur} fixed={fixed}>
      <Card className={className}>{children}</Card>
    </Wrapper>
  );
};

SuccessPopup.defaultProps = {
  blur: true,
  className: '',
  fixed: false,
};

SuccessPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  blur: PropTypes.bool,
  fixed: PropTypes.bool,
  className: PropTypes.string,
};

export default SuccessPopup;

// styled components

const Wrapper = styled.div`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  background: ${colors.lighterGray}60;
  ${({ blur }) => blur && `backdrop-filter: blur(2px);`}

  @media (max-width: 1200px) {
    position: fixed;
  }

  @media (max-width: 500px) {
    padding: 20px;

    /* position: fixed;
    top: 63px;
    width: 100%;
    height: calc(100vh - 63px); */
  }
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: auto;
  min-height: 240px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  box-shadow: 0px 3px 15px #00000010;
  border-radius: 14px;

  div.buttons {
    width: 100%;
    max-width: 440px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    button {
      margin: 0 10px 15px 10px;
    }

    @media (max-width: 950px) {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      button {
        min-width: 160px;
        max-width: 70%;
      }
    }
  }

  p {
    text-align: center;
    margin-bottom: 1em;
  }

  p + p {
    margin-top: 0;
  }

  & > h2 {
    color: ${colors.orange};
  }
`;

export const PopupScroller = styled.div`
  width: calc(100% + 2rem);
  max-height: 30vh;
  overflow: auto;
  padding-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;