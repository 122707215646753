/* eslint-disable camelcase */
import makeRequest from 'services/makeRequest';

const CLIENT_BASE_URL = '/companies';

const GET_HISTORY_ENDPOINT = 'logs';
const GET_NOTES_ENDPOINT = 'notes';
const ADD_NOTE_ENDPOINT = 'note';
const FORGET_ENDPOINT = 'forget';

const getClientPartnerInfo = id =>
  makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${id}`,
  });

const getClientPartnerHistory = id =>
  makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${GET_HISTORY_ENDPOINT}/${id}`,
  });

const getClientPartnerNotes = id =>
  makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${id}/${GET_NOTES_ENDPOINT}`,
  });

const addClientPartnerNote = (id, text) =>
  makeRequest({
    method: 'post',
    url: `${CLIENT_BASE_URL}/${id}/${ADD_NOTE_ENDPOINT}`,
    data: {
      text,
    },
  });

const forgetClientPartner = id =>
  makeRequest({
    method: 'get',
    url: `${CLIENT_BASE_URL}/${FORGET_ENDPOINT}/${id}`,
  });

export const clientPartnerService = {
  getClientPartnerInfo,
  getClientPartnerHistory,
  getClientPartnerNotes,
  addClientPartnerNote,
  forgetClientPartner,
};
