import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import background from 'assets/tlo.png';
import logo from 'assets/pep_logo_2020.svg';

const AuthTemplate = ({ children }) => (
  <StyledContainer>
    <Wrapper>
      <img className="pep-logo" src={logo} alt="Polskie ePłatności" />
      <AuthCard>{children}</AuthCard>
    </Wrapper>
  </StyledContainer>
);

AuthTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default AuthTemplate;

// styled components

const StyledContainer = styled.main`
  width: 100%;
  min-height: 100vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 720px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  .pep-logo {
    width: 100%;
    max-width: 290px;
    object-fit: contain;
    margin: 20px 0 35px 0;
    @media (min-width: 500px) {
      margin: 50px 0;
    }
    @media (min-width: 768px) {
      margin: 80px 0;
    }
  }
`;

const AuthCard = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  box-shadow: 0px 3px 15px #00000010;
`;
