import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LeadMerchantStepSchemas } from 'utils/validation';
import { leadService } from 'services/lead';
import { routes } from 'routes';
import { clearCurrentOnboarding, getOnboarding, setOnboarding } from 'redux/lead';
import { canSave, formatMerchantValues } from 'components/forms/LeadMerchant/others/helpers';
import { download } from 'utils/helpers';
import { jsConfetti } from 'components/logic/Confetti';
import InvoiceFactory from 'utils/pdf';
import FormikStepper from 'components/forms/FormikStepper';
import Step1 from 'components/forms/LeadMerchant/LeadMerchantStep1';
import Step2 from 'components/forms/LeadMerchant/LeadMerchantStep2';
import Step3 from 'components/forms/LeadMerchant/LeadMerchantStep3';
import Step4 from 'components/forms/LeadMerchant/LeadMerchantStep4';
import Step5 from 'components/forms/LeadMerchant/LeadMerchantStep5';
import Step6 from 'components/forms/LeadMerchant/LeadMerchantStep6';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import initialValues, {
  demoViewOnboardingData,
  termsWithoutPbg,
} from 'components/forms/LeadMerchant/others/initialValues';
import CopyString from 'components/UI/CopyString';
import Place from './others/Place';
import matrixArr from './others/configAML';
import moment from 'moment';

const checkIfGusIsEmpty = (gus) => {
  if (gus.city && gus.house_no && gus.postal_code && gus.postal_code !== '00-000') return false;
  return true;
};

const mapGusLegalFormToCrm = (gusLegalFormId) => {
  if (!gusLegalFormId) return null;
  const matchedForm = matrixArr.find((lf) => lf.legalFormGusCodes.includes(Number(gusLegalFormId)));
  // Pozostałe nieobsługiwane kody mają trafiac do Jednostka samorządu terytorialnego nieposiadająca osobowości prawnej - 100100
  return matchedForm ? matchedForm.value : 100100;
};

export const uuidV4Regex = /[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}/i;

const LeadMerchant = ({
  onboardingData,
  getOnboarding,
  setOnboarding,
  clearCurrentOnboarding,
  isError,
  isLoading,
  isDemoMode,
}) => {
  const history = useHistory();
  const myRef = useRef(null);
  const { id } = useParams();

  const [debugMode, setDebugMode] = useState(false);

  const user = useSelector((state) => state.user.data);
  const userRole = useSelector((state) => state.user.data.role.slug);

  const [viewMode, setViewMode] = useState(['admin', 'dsp'].includes(userRole));
  const [formData, setFormData] = useState(initialValues);
  const [config, setConfig] = useState({});
  const [isReady, setIsReady] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [draftSuccess, setDraftSuccess] = useState(false);
  const [draftError, setDraftError] = useState(null);

  const executeScroll = () => myRef?.current?.scrollIntoView(true);

  const closePopup = () => {
    setSuccess(false);
    setResendSuccess(false);
    setError(false);
    setErrorCode(false);
    setDraftSuccess(false);
    setDraftError(false);
  };

  useEffect(() => {
    if (isDemoMode) setOnboarding(demoViewOnboardingData);
    else getOnboarding(id);
    return () => clearCurrentOnboarding();
  }, [getOnboarding, setOnboarding, clearCurrentOnboarding, id, isDemoMode]);

  useEffect(() => {
    if (!onboardingData) return;

    let version = initialValues.version;
    let versionNumber = initialValues.versionNumber;

    const { gus } = onboardingData;
    const isGusEmpty = checkIfGusIsEmpty(gus);
    let tmpData = initialValues;
    let { agreement } = initialValues.settlements;
    let { agreement: signatoryAgreement } = initialValues.contact;

    tmpData.settlements.legalFormAML = mapGusLegalFormToCrm(gus.legal_form || null);

    if (Object.keys(onboardingData.data).length !== 0) {
      // Poprawki gdy inny format zapisany w bazie
      if (onboardingData.data.points[0].terminals[0].count === undefined) {
        onboardingData.data.points.map((p) => {
          const point = p;
          point.terminals = p.terminals.map((t) => ({ ...t, count: 1 }));
          return point;
        });
      }

      // Jeżeli istnieją jakies zapisane dane
      tmpData = onboardingData.data;

      // Jeżeli w zapisanych danych brakuje wersjonowania to:
      if (!onboardingData.data.version) version = 'OLD';

      // Jeżeli istnieje wersja to skorzystaj
      if (onboardingData.data.versionNumber) versionNumber = onboardingData.data.versionNumber;
      if (!onboardingData.data.versionNumber) versionNumber = null;

      if (version === 'OLD')
        tmpData.settlements.legalFormAML =
          matrixArr.find((el) => el.oldValue === tmpData.settlements.legalForm)?.value || -1;

      if (onboardingData.agreement) {
        agreement = {
          name: onboardingData.agreement.slice(11),
          download: true,
        };
      }

      if (onboardingData.signatoryAgreement) {
        signatoryAgreement = {
          name: onboardingData.signatoryAgreement.slice(11),
          download: true,
        };
      }

      if (onboardingData.media) tmpData.media = onboardingData.media || [];
    }

    if (isDemoMode) setViewMode(false);
    else setViewMode(['admin', 'dsp'].includes(userRole) || onboardingData.status === 'SUCCESS');

    const isPbg = tmpData.settlements.pbg === 1;

    setFormData({
      ...initialValues,
      ...tmpData,
      version,
      versionNumber,
      terms: {
        ...initialValues.terms,
        ...tmpData.terms,
        commission: {
          ...(isPbg ? initialValues.terms.commission : termsWithoutPbg.commission),
          ...tmpData.terms.commission,
        },
        margin: {
          ...(isPbg ? initialValues.terms.margin : termsWithoutPbg.margin),
          ...tmpData.terms.margin,
        },
      },
      settlements: {
        ...initialValues.settlements,
        ...tmpData.settlements,
        agreement,
      },
      addresses: {
        ...tmpData.addresses,
        main: new Place({
          streetName: gus.street,
          buildingNumber: gus.house_no,
          apartmentNumber: gus.flaat_no,
          city: gus.city,
          zipCode: gus.postal_code,
          country: 'Polska',
          voivodeship: gus.voidoeship,
          county: gus.community,
          community: gus.county,
        }),
      },
      contact: {
        ...initialValues.contact,
        ...tmpData.contact,
        agreement: signatoryAgreement,
      },
      settings: {
        createdAt: onboardingData.data?.settings?.createdAt || moment().format('YYYY-MM-DD HH:mm'),
        finishedAt: onboardingData.data?.settings?.finishedAt || null,
        lastSaveAt: onboardingData.data?.settings?.lastSaveAt || null,
        canPwobOptions: onboardingData.data?.settings?.canPwobOptions || onboardingData.formPwob,
      },
      media: tmpData.media || [],
    });

    const defaultConfig = {
      // From app-settings
      renderFormPwob: onboardingData.formPwob,
      generalPromotion: onboardingData.generalPromotion,
      // Others
      dontMessWithValues: onboardingData.status === 'SUCCESS',
      isGusEmpty,
    };

    if (Object.keys(onboardingData.data).length !== 0) {
      setConfig({
        ...defaultConfig,

        saved: true,
        // Step 1
        invoicesAddress: !tmpData.addresses.invoices.sameAs,
        maillingAddress: !tmpData.addresses.mailling.sameAs,
        // Step 3
        operational: !tmpData.contact.operationalMatters.sameAs,
        business: !tmpData.contact.businessMatters.sameAs,
        owners: !tmpData.contact.owners.some((el) => el.sameAs),
        preson: !tmpData.contact.personInCharge.sameAs,
        signatory: !tmpData.contact.signatory.some((el) => el.sameAs),
        // Step 4
        pointsAddresses: tmpData.points.map((p) => Boolean(!p.address.sameAs)),
        // Others
      });
    } else {
      setConfig((c) => ({
        ...c,
        ...defaultConfig,

        // Step 1
        invoicesAddress: isGusEmpty,
        maillingAddress: isGusEmpty,
        // Step 4
        pointsAddresses: [isGusEmpty],
      }));
    }

    setIsReady(true);
  }, [isDemoMode, onboardingData, userRole]);

  // Error dla getOnboarding
  useEffect(() => {
    if (isError && isError.status === 404) history.replace(routes.leads);
  }, [isError, history]);

  const saveOrSend = (values, isSave, formikBag) => {
    const agreement = values.settlements.agreementFile || null;
    const signatoryAgreement = values.contact.agreementFile || null;
    const newValues = formatMerchantValues(values);

    // Points Files
    const pointsFiles = values.files || null;
    let pointsFilesGroupedByPoint = null;
    let additionalPromises = [];

    if (pointsFiles)
      pointsFilesGroupedByPoint = pointsFiles.reduce((acc, file) => {
        if (!acc[file.pointId]) acc[file.pointId] = [];
        acc[file.pointId].push(file);
        return acc;
      }, {});

    if (pointsFilesGroupedByPoint)
      additionalPromises = Object.keys(pointsFilesGroupedByPoint).map((pointId) =>
        leadService.saveOnbordingPointFiles(id, pointId, { media: pointsFilesGroupedByPoint[pointId] }).then((res) => {
          formikBag.setFieldValue('files', []);
          formikBag.setFieldValue('media', res.data);
        }),
      );
    // Points Files End

    if (isSave) newValues.settings.lastSaveAt = moment().format('YYYY-MM-DD HH:mm');
    else newValues.settings.finishedAt = moment().format('YYYY-MM-DD HH:mm');

    setError('');
    setDraftError('');
    setErrorCode('');
    setLoading(true);

    Promise.all([
      leadService.saveOnboarding(
        id,
        {
          data: JSON.stringify(newValues),
          agreement,
          signatoryAgreement,
        },
        isSave,
      ),
      ...additionalPromises,
    ])
      .then((data) => {
        // Data is array of responses - info for feature me
        console.log(data);
        if (isSave) setDraftSuccess(true);
        else {
          setSuccess(true);
          if (user?.company?.partnerProgram?.includes('AMBASADOR')) {
            jsConfetti.show();
            jsConfetti.addConfetti().finally(() => jsConfetti.hide());
          }
        }
      })
      .catch((err) => {
        console.log(err);
        const error = err.response.data.error.message || 'Wystąpił błąd';
        const code = error.match(uuidV4Regex)?.[0] || null;
        if (code) setErrorCode(code);
        if (isSave) setDraftError(error);
        else setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendForm = () => {
    setError('');
    setDraftError('');
    setErrorCode('');
    setLoading(true);

    leadService
      .resendOnboarding(id)
      .then((data) => {
        console.log(data);
        getOnboarding(id);
        setResendSuccess(true);
      })
      .catch((err) => {
        console.log(err);

        const error = err.response.data.error.message || 'Wystąpił błąd';
        const code = error.match(uuidV4Regex)?.[0] || null;

        if (code) setErrorCode(code);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const save = (values, formikBag) => saveOrSend(values, true, formikBag);
  const submit = (values, formikBag) => saveOrSend(values, false, formikBag);

  const getXml = () => {
    leadService
      .getOnboardingXML(id)
      .then((res) => {
        download(res, 'forms.xml');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPdf = () => {
    let formPdf = null;
    const data = {
      ...formData,
      name: onboardingData.gus.name,
      nip: onboardingData.gus.nip,
    };

    if (formData.version === 'AML') formPdf = new InvoiceFactory.MerchantFormAML(data);
    else formPdf = new InvoiceFactory.MerchantForm(data);

    formPdf.build();
    formPdf.download(`Rejestracja umowy w CRM - ${onboardingData.gus.nip}`);
  };

  return (
    <Wrapper>
      {isError && !isLoading && <ErrorPopup>{isError.message}</ErrorPopup>}
      {(loading || isLoading) && <Loading fixed />}
      {resendSuccess && (
        <SuccessPopup fixed>
          <Heading>Formularz wysłany ponownie</Heading>
          <p>{success}</p>
          <div className="buttons">
            <Button onClick={closePopup}>Zamknij</Button>
          </div>
        </SuccessPopup>
      )}
      {draftSuccess && (
        <SuccessPopup fixed>
          <Heading>Formularz został zapisany</Heading>
          <p>{draftSuccess}</p>
          <div className="buttons">
            <Button onClick={closePopup}>Zamknij</Button>
          </div>
        </SuccessPopup>
      )}
      {draftError && (
        <ErrorPopup fixed>
          <Heading>Wystapił błąd podczas zapisywania</Heading>
          <p>{draftError}</p>
          <div className="buttons">
            <Button onClick={closePopup}>Spróbuj ponownie</Button>
          </div>
        </ErrorPopup>
      )}
      {success && (
        <SuccessPopup fixed>
          <Heading>Lead został zarejestrowany</Heading>
          <p>{success}</p>
          <div className="buttons">
            <Button onClick={() => history.push(routes.leadDetails.replace(':id', onboardingData.leadId))}>
              Zobacz leada
            </Button>
            <Button onClick={() => history.push(routes.leads)}>Zamknij</Button>
          </div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup fixed>
          <Heading style={{ marginBottom: '1rem' }}>UWAGA! Wystąpił błąd rejestracji danych</Heading>
          <p>
            Kod błędu:{' '}
            <span style={{ fontWeight: '600' }} className="color-green">
              {errorCode || error}
            </span>
            {errorCode && <CopyString text={errorCode} />}
          </p>
          <p>
            Wyślij wiadomość na adres{' '}
            <a className="color-orange" style={{ fontWeight: '600' }} href="mailto:formularz@pep.pl">
              formularz@pep.pl
            </a>{' '}
            i przekaż kod błędu oraz NIP rejestrowanego klienta.
          </p>
          <p>Skontaktujemy się z Tobą w celu rozwiązania problemu.</p>
          <div className="buttons">
            <Button onClick={closePopup}>Spróbuj ponownie</Button>
            <Button onClick={() => history.push(routes.leads)}>Wróć do listy leadów</Button>
          </div>
        </ErrorPopup>
      )}
      <ScrollHook onClick={() => setDebugMode(!debugMode)} ref={myRef} />
      {isReady && !isLoading && !isError && (
        <FormikStepper
          initialTouched={{
            settlements: {
              agreement: true,
            },
            contact: {
              signatoryAgreement: true,
            },
          }}
          loading={loading || isLoading}
          title="Rejestracja umowy w CRM"
          onStepChange={executeScroll}
          enableReinitialize
          initialValues={formData}
          onSubmit={submit}
          viewMode={viewMode}
          additionalTitle={
            onboardingData && (
              <Heading>
                {onboardingData.gus.name} / <span className="color-orange">{onboardingData.gus.nip}</span>
              </Heading>
            )
          }
          isDemoMode={isDemoMode}
          additionalButtons={(formik) => {
            if (isDemoMode) return null;
            return (
              <>
                {onboardingData && onboardingData.crmStatus === 0 && ['admin', 'dsp'].includes(userRole) && (
                  <Button
                    small
                    green
                    type="button"
                    onClick={() => resendForm(formik.values)}
                    disabled={loading || isLoading || resendSuccess}
                  >
                    Wyślij ponownie dane do CRM
                  </Button>
                )}
                {['admin'].includes(userRole) && onboardingData && Object.keys(onboardingData.data).length > 0 && (
                  <Button
                    style={{ marginBottom: '10px' }}
                    right
                    small
                    green
                    type="button"
                    onClick={getXml}
                    disabled={loading || isLoading}
                  >
                    Pobierz XML
                  </Button>
                )}
                {onboardingData && onboardingData.status === 'SUCCESS' && (
                  <Button small type="button" onClick={getPdf} disabled={loading || isLoading}>
                    Pobierz PDF
                  </Button>
                )}
                {!viewMode && (
                  <Button
                    small
                    secondary
                    type="button"
                    // disabled={!formik.isValid}
                    disabled={!canSave(formik.touched, formik.errors) || loading || isLoading}
                    title={formik.isValid ? null : 'Niektóre pola zawierają błędy lub wymagają uzupełnienia'}
                    onClick={() => save(formik.values, formik)}
                  >
                    Zapisz szkic
                  </Button>
                )}
              </>
            );
          }}
        >
          <Step1
            debugMode={debugMode}
            viewMode={viewMode}
            validationSchema={LeadMerchantStepSchemas.step1}
            title="Dane firmowe"
            config={config}
            setConfig={setConfig}
          />
          <Step2
            debugMode={debugMode}
            viewMode={viewMode}
            validationSchema={LeadMerchantStepSchemas.step2}
            title="Rozliczenia"
            config={config}
            setConfig={setConfig}
          />
          <Step3
            debugMode={debugMode}
            viewMode={viewMode}
            validationSchema={LeadMerchantStepSchemas.step3}
            title="Warunki handlowe"
            config={config}
            setConfig={setConfig}
          />
          <Step4
            debugMode={debugMode}
            viewMode={viewMode}
            validationSchema={LeadMerchantStepSchemas.step4}
            title="Osoby kontaktowe"
            config={config}
            setConfig={setConfig}
          />
          <Step5
            debugMode={debugMode}
            viewMode={viewMode}
            validationSchema={LeadMerchantStepSchemas.step5}
            title="Punkty sprzedaży"
            config={config}
            setConfig={setConfig}
          />
          <Step6
            debugMode={debugMode}
            viewMode={viewMode}
            validationSchema={LeadMerchantStepSchemas.step6}
            title="Produkty i usługi dodatkowe"
          />
        </FormikStepper>
      )}
    </Wrapper>
  );
};

LeadMerchant.defaultProps = {
  onboardingData: null,
  isError: null,
  isDemoMode: false,
};

LeadMerchant.propTypes = {
  getOnboarding: PropTypes.func.isRequired,
  setOnboarding: PropTypes.func.isRequired,
  clearCurrentOnboarding: PropTypes.func.isRequired,
  onboardingData: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.instanceOf(Object),
  isDemoMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  onboardingData: state.leads.currentOnboarding.data,
  isLoading: state.leads.currentOnboarding.isLoading,
  isError: state.leads.currentOnboarding.error,
});

const mapDispatchToProps = {
  getOnboarding,
  clearCurrentOnboarding,
  setOnboarding,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadMerchant);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
`;

const ScrollHook = styled.span`
  position: absolute;
  left: 0;
  top: -200px;
`;
