import { chatService } from 'services/chat';
import { sortByCreatedAt } from 'utils/helpers';
import * as types from './types';

export const archiveConversation = conversationId => dispatch => {
  dispatch({ type: types.ARCHIVE_CONVERSATION, payload: conversationId });
};

export const unarchiveConversation = conversationId => dispatch => {
  dispatch({ type: types.UNARCHIVE_CONVERSATION, payload: conversationId });
};

export const setArchivedConversations = conversations => dispatch => {
  dispatch({ type: types.SET_ARCHIVED_CONVERSATIONS, payload: conversations });
};

export const setChatMode = mode => dispatch => {
  dispatch({ type: types.SET_MODE, payload: mode });
};

export const setLocalLastSeenMessages = convId => dispatch => {
  dispatch({ type: types.SET_LOCAL_LAST_SEEN_MESSAGES, payload: convId });
};

export const setIsLoadingLeads = val => dispatch => {
  dispatch({ type: types.IS_LOADING_LEADS, payload: val });
};

export const setIsLoadingPatrons = val => dispatch => {
  dispatch({ type: types.IS_LOADING_PATRONS, payload: val });
};

export const setTime = serverTime => dispatch => {
  dispatch({ type: types.SET_TIME, payload: serverTime });
};

export const updateLastSeendInDetails = (loggedUserId, conversationId) => dispatch => {
  dispatch({
    type: types.UPDATE_LAST_SEEN_IN_CONVERSATION_DETAILS,
    payload: { loggedUserId, conversationId },
  });
};

export const clearPreviewConversations = loggedUserId => dispatch => {
  dispatch({ type: types.CLEAR_PREVIEW_CONVERSATIONS, payload: loggedUserId });
};

export const setConversationUsers = (users, convId) => dispatch => {
  dispatch({ type: types.SET_CONVERSATION_USERS, payload: { users, convId } });
};

export const checkUnreadConversations = loggedUserId => dispatch => {
  dispatch({ type: types.CHECK_UNREAD_CONVERSATIONS, payload: loggedUserId });
};

export const setPanelActive = panelState => dispatch => {
  dispatch({ type: types.SET_PANEL_ACTIVE, payload: panelState });
};

export const setMessagesLoading = loadingState => dispatch => {
  dispatch({ type: types.SET_ACTIVE_CONVERSATION_MESSAGES_LOADING, payload: loadingState });
};

export const setConversationsLoading = loadingState => dispatch => {
  dispatch({ type: types.SET_CONVERSATIONS_LOADING, payload: loadingState });
};

export const setConversationsError = errorMsg => dispatch => {
  dispatch({ type: types.SET_CONVERSATIONS_ERROR, payload: errorMsg });
};

export const addConversation = caonversation => dispatch => {
  dispatch({ type: types.ADD_CONVERSATION, payload: caonversation });
};

export const setConversations = conversations => dispatch => {
  dispatch({ type: types.SET_CONVERSATIONS, payload: conversations });
};

export const setActiveConversation = conversation => dispatch => {
  dispatch({ type: types.SET_ACTIVE_CONVERSATION, payload: conversation });
};

export const loadMoreConversations = conversations => dispatch => {
  dispatch({ type: types.ADD_PREVIOUS_CONVERSATIONS, payload: conversations });
};

export const loadMoreArchives = conversations => dispatch => {
  dispatch({ type: types.ADD_PREVIOUS_ARCHIVES, payload: conversations });
};

export const setActiveConversationMessages = messages => dispatch => {
  dispatch({ type: types.SET_ACTIVE_CONVERSATION_MESSAGES, payload: messages });
};

export const addMessage = (message, conversationId = false) => dispatch => {
  dispatch({ type: types.ADD_MESSAGE, payload: { message, conversationId } });
};

export const addPreviousMessages = (messages, conversationId) => dispatch => {
  dispatch({ type: types.ADD_PREVIOUS_MESSAGES, payload: messages, conversationId });
};

export const clearActiveConversation = () => dispatch => {
  dispatch({ type: types.CLEAR_ACTIVE_CONVERSATION });
};

export const getSystemMessages = () => dispatch => {
  dispatch({ type: types.GET_SYSTEM_MESSAGES_REQUEST });
  return chatService
    .getSystemMessages()
    .then(payload => {
      payload.data.sort(sortByCreatedAt);
      dispatch({ type: types.GET_SYSTEM_MESSAGES_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_SYSTEM_MESSAGES_FAILURE, err });
    });
};

export const clearCurrentSystemMessage = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_SYSTEM_CONVERSATION });
};

export const clearConversations = () => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: types.CLEAR_CONVERSATIONS });
  if (state.user.data.serverUTCTime) dispatch(setTime(state.user.data.serverUTCTime));
};

export const clearChat = () => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: types.CLEAR_CHAT });
  if (state.user.data.serverUTCTime) dispatch(setTime(state.user.data.serverUTCTime));
};

export const setNewMsgInfo = payload => dispatch => {
  dispatch({ type: types.SET_NEW_MESSAGES, payload });
};

export const updateConversations = payload => dispatch => {
  dispatch({ type: types.UPDATE_CONVERSATIONS, payload });
};