import React from 'react';
import Heading from 'components/UI/Heading';
import styled, { css } from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import placeholder from 'assets/avatar-placeholder.png';
// import { useDoubleConfirmPopup } from 'utils/hooks';
import { colors, font } from 'theme/styles';
import { Row, Col } from 'components/UI/Structure';
// import Button from 'components/UI/Button';
import { partnerCooperationOptions, partnerProgramOptions } from 'config/constants';
import { getTrendIcon } from 'utils/helpers';

const ClientPartnerInfo = ({
  data,
  posInfo,
  // userRole
}) => {
  // const [Popup, popupTrigger] = useDoubleConfirmPopup({
  //   firstQuestion: `Czy chcesz zapomnieć klienta ${data.companyName} ?`,
  //   secondQuestion: `Zmiana jest nieodwracalna, klient ${data.companyName} oraz wszystkie zgłoszone leady zostaną zapomniane.\n Czy jesteś pewien?`,
  //   submitHandler: () => clientService.forgetClient(data.id),
  //   redirectAfter: '/',
  // });

  // eslint-disable-next-line no-unused-vars
  const [a, b, trendNameShort] = getTrendIcon(data.trend);

  return (
    <>
      {/* <Popup /> */}
      <InnerHeader>
        <img src={data.avatarUrl || placeholder} alt="Avatar" />
        <Heading centered>{data.name}</Heading>
      </InnerHeader>
      <Divider heading="Informacje podstawowe" />
      <InnerBody>
        <Row>
          <Col>
            <Label>NIP:</Label>
            <Text>{data.nip}</Text>
          </Col>
          <Col>
            <Label>Trend:</Label>
            <Text>{trendNameShort}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Miasto:</Label>
            <Text>{data.city}</Text>
          </Col>
          <Col>
            <Label>Kod pocztowy:</Label>
            <Text>{data.postCode}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Adres:</Label>
            <Text>
              {data.street} {data.numBuilding} {data.numLocal && ` / ${data.numLocal}`}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Utworzono:</Label>
            <Text>{moment(data.createdAt).format('DD.MM.YYYY - HH:mm')}</Text>
          </Col>
          <Col>
            <Label>Ost. aktualizacja:</Label>
            <Text>
              {data.updatedAt && data.updatedAt !== data.createdAt
                ? moment(data.updatedAt).format('DD.MM.YYYY - HH:mm')
                : '---'}
            </Text>
          </Col>
        </Row>
        <Divider heading="Informacje dodatkowe" />
        <Row>
          {data.leadMasterAssoc && (
            <Col>
              <Label>Leadmaster:</Label>
              <Text>{data.leadMasterAssoc.fullName}</Text>
              <Text>{data.leadMasterAssoc.email}</Text>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Label>Aktywne typy współpracy:</Label>
            {data.cooperation
              ? JSON.parse(data.cooperation).map(c => (
                  <Text key={c}> &bull; {partnerCooperationOptions.find(typ => typ.value === c).display}</Text>
                ))
              : 'Brak'}
          </Col>
          <Col>
            <Label>Aktywne kanał partnerskie:</Label>
            {data.partnerProgram
              ? JSON.parse(data.partnerProgram).map(c => (
                  <Text key={c}> &bull; {partnerProgramOptions.find(pp => pp.value === c).display}</Text>
                ))
              : 'Brak'}
          </Col>
        </Row>
        <Row>
          {data.dedicatedPhAssoc && data.dedicatedPhAssoc.length > 0 && (
            <Col>
              <Label>Przedstawiciele dedykowani:</Label>
              {data.dedicatedPhAssoc.map(dp => (
                <div key={dp.id}>
                  <Text>{dp.fullName}</Text>
                  <Text>{dp.email}</Text> <br />
                </div>
              ))}
            </Col>
          )}
        </Row>
        <Divider heading="Informacje POS" />
        <Row>
          <Col>
            <Label>Liczba POS bieżący miesiąc:</Label>
            <Text>{posInfo.LiczbaPOS_BiezacyMiesiac || '-'}</Text>
          </Col>
          <Col>
            <Label>Liczba POS bieżący kwartał:</Label>
            <Text>{posInfo.LiczbaPOS_BiezacyKwartal || '-'}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Liczba Aktywnych POS w bieżącym kwartale</Label>
            <Text>{posInfo.LiczbaAktywnychPOS_BiezacyKwartal || '-'} / {posInfo.LiczbaAktywnychPOS_Total || '-'}</Text>
          </Col>
        </Row>
        {/* {['admin'].includes(userRole) && (
          <>
            <Divider />
            <Row>
              <Button small gray centered onClick={popupTrigger}>
                Zapomnij klienta
              </Button>
            </Row>
          </>
        )} */}
      </InnerBody>
    </>
  );
};

ClientPartnerInfo.defaultProps = {
  posInfo: true,
};

ClientPartnerInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  posInfo: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  // userRole: PropTypes.string.isRequired,
};

export default ClientPartnerInfo;

const InnerHeader = styled.div`
  text-align: center;

  img {
    max-width: 30%;
    display: inline-block;
    border-radius: 50%;
    margin: 20px auto;
  }

  ${Heading} {
    max-width: 75%;
  }
`;

const InnerBody = styled.div`
  p {
    margin: 0;
  }

  ${Row} {
    margin-left: 0;
    margin-right: 0;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const Label = styled.p`
  letter-spacing: 0.5px;
  color: ${colors.mediumGray};
  font-size: ${font.size.s};
`;

export const Text = styled.p`
  font-weight: ${font.weight.semibold};
  color: ${colors.darkBlue};
  font-size: ${font.size.m};
`;

const Divider = styled.span`
  position: relative;
  display: block;
  border: none;
  border-top: 1px dashed ${colors.orange};
  margin-top: 30px;
  margin-bottom: 30px;

  ${({ heading }) =>
    heading &&
    css`
      &::before {
        content: '${heading}';
        display: inline-block;
        background-color: ${colors.white};
        color: ${colors.orange};
        font-weight: ${font.weight.semibold};
        padding: 0 0.5em;
        position: absolute;
        left: 50%;
        top: calc(50% - 2px);
        transform: translate(-50%, -50%);
      }
  `}
`;
