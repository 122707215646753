/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'moment/locale/pl';
import { connect } from 'react-redux';
import { colors, font } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translateProps, historyActions, historyTypes } from 'config/constants';
import Button from 'components/UI/Button';
import HorizontalLine from 'components/UI/HorizontalLine';
import NoData from 'components/UI/NoData';

const getTitle = (log) => {
  const { extra = {} } = log;

  if (log.actionType === historyActions.EDIT_PROFIL_USER_COMPANY._TYPE)
    return (
      <p title={log.leadId}>
        {historyActions[log.actionType]?.display || ''} - {log.prevData?.fullName || 'brak danych'}
      </p>
    );

  return <p title={log.leadId}>{historyActions[log.actionType]?.display || ''}</p>;
};

const createAdditionalInfo = (log) => (
  <>
    {log.leadId && (
      <p>
        <span>Lead id: </span>
        {log.leadId}
      </p>
    )}
    {historyTypes[log.authType] && (
      <p>
        <span>Sposób autoryzacji: </span>
        {historyTypes[log.authType]?.display}
      </p>
    )}
    {log.authUser && (
      <div>
        <HorizontalLine title="Akcja wykonana przez:" />
        <p>
          <span>Imię i nazwisko: </span>
          {log.authUser.fullName}
        </p>
        <p>
          <span>Email: </span>
          {log.authUser.email}
        </p>
        <p>
          <span>Rola: </span>
          {log.authUser.role.name}
        </p>
      </div>
    )}
  </>
);

const mapAssocObject = (person) => <p key={person.fullName}>{person.fullName}</p>;
const mapArray = (el) => <p key={el}>{el}</p>;

const createDiffTable = (log) => {
  const entries = Object.entries(log.diff || {});
  const propsTester = entries.filter((diff) => !['updatedAt'].includes(diff[0]));

  if (propsTester.length === 0) return <strong style={{ color: colors.red }}>Brak wykrytych zmian</strong>;

  return (
    <HistoryItemDiffTable>
      <thead>
        <tr>
          <th className="propName">Pole</th>
          <th>Przed edycją</th>
          <th>Po edycji</th>
        </tr>
      </thead>
      <tbody>
        {entries
          .filter((diff) => !['updatedAt'].includes(diff[0]))
          .map((diff) => {
            const propName = diff[0];
            const assocProps = ['leadMasterAssoc'];

            let oldProp = diff[1] ? diff[1].__old : '';
            let newProp = diff[1] ? diff[1].__new : '';

            if (propName === 'dedicatedPhs') {
              oldProp = Array.isArray(log.prevData.dedicatedPhs) ? log.prevData.dedicatedPhs.map(mapArray) : oldProp;
              newProp = Array.isArray(log.nextData.dedicatedPhs) ? log.nextData.dedicatedPhs.map(mapArray) : newProp;
            }

            if (propName === 'dedicatedPhAssoc') {
              oldProp = Array.isArray(log.prevData.dedicatedPhAssoc)
                ? log.prevData.dedicatedPhAssoc.map(mapAssocObject)
                : diff[1]?.fullName?.__old || '';
              newProp = Array.isArray(log.nextData.dedicatedPhAssoc)
                ? log.nextData.dedicatedPhAssoc.map(mapAssocObject)
                : diff[1]?.fullName?.__new || '';
            }

            if (assocProps.includes(propName)) {
              oldProp = diff[1].fullName ? diff[1].fullName.__old : '';
              newProp = diff[1].fullName ? diff[1].fullName.__new : '';
            }

            // If prop value is array
            if (Array.isArray(diff[1] && diff[1].length > 0)) {
              oldProp = log.prevData[propName].map((el) => el.name || el).join('\n') || '';
              newProp = log.currentData[propName].map((el) => el.name || el).join('\n') || '';
            }

            return (
              <tr key={propName}>
                <td>{translateProps[propName] || propName}</td>
                <td>{oldProp}</td>
                <td>{newProp}</td>
              </tr>
            );
          })}
      </tbody>
    </HistoryItemDiffTable>
  );
};

const createExtra = (log) => {
  return log.extra && log.extra.length > 0 ? (
    <>
      <HorizontalLine title="Extra" />
      {log.extra.map((e, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={i}>{e.info}</p>
      ))}
      {/* W przyśłości jeżeli pojawi się coś takiego to wyeksportować do osobnej funkcji z switchem */}
      {log.actionType === historyActions.CREATED_USER_BULK_IMPORT._TYPE && log.extra[0]?.data?.length > 0 && (
        <div>
          {log.extra[0].data.map((u) => (
            <p key={u.id}>
              {u.name} {u.surname} — {u.email}
            </p>
          ))}
        </div>
      )}
    </>
  ) : null;
};

const createDetails = (log) => {
  switch (log.actionType) {
    case historyActions.EDIT_PROFIL_USER_COMPANY._TYPE:
    case historyActions.EDIT_COMPANY._TYPE:
    case historyActions.TRANSFER_USER_COMPANY._TYPE:
    case historyActions.EDIT_AVATAR_USER_COMPANY._TYPE:
      return createDiffTable(log);
    default:
      return null;
  }
};

const ClientHistory = ({ historyData, userRole }) => {
  const [openTabs, setOpenTabs] = useState({});
  const [showLimit, setShowLimit] = useState(50);

  const openTab = (id) => {
    const isOpen = !!openTabs[id];
    setOpenTabs((state) => {
      return { ...state, [id]: !isOpen };
    });
  };

  const sortedMonths = useMemo(
    () =>
      historyData.slice(0, showLimit).reduce((obj, next) => {
        const created = moment(next.createdAt).format('YYYY-MM');
        if (Object.hasOwnProperty.call(obj, created)) obj[created].push(next);
        else obj[created] = [next];
        return obj;
      }, {}),
    [historyData, showLimit],
  );

  return (
    <HistoryWrapper
    // onScroll={handleScroll}
    >
      {historyData.length ? (
        Object.keys(sortedMonths).map((month) => (
          <HistoryMonth key={month}>
            <span>{moment(month).format('MMMM, YYYY')}</span>
            {sortedMonths[month].map((log) => {
              const logMoment = moment(log.createdAt).locale('pl');
              return (
                <HistoryItem key={log.id}>
                  <HistoryItemTop>
                    <HistoryItemDate>
                      <span>{logMoment.format('DD MMM')}</span>
                      <span>{logMoment.format('HH:mm')}</span>
                    </HistoryItemDate>
                    <StyledHistoryIcon icon={historyActions[log.actionType]?.icon || 'info-circle'} fixedWidth />
                    <HistoryItemDescription>
                      {getTitle(log)}
                      <p>
                        Akcja wykonana przez:&nbsp;
                        <span className="actionBy">
                          {log.authUser ? log.authUser.fullName : historyTypes[log.authType]?.display}
                        </span>
                      </p>
                    </HistoryItemDescription>
                    {['admin', 'dsp'].includes(userRole) && (
                      <HistoryItemShowMore onClick={() => openTab(log.id)}>Zobacz szczegóły</HistoryItemShowMore>
                    )}
                  </HistoryItemTop>
                  {['admin', 'dsp'].includes(userRole) && (
                    <HistoryItemBottom isOpen={openTabs[log.id]}>
                      {createAdditionalInfo(log)}
                      {createDetails(log)}
                      {createExtra(log)}
                    </HistoryItemBottom>
                  )}
                </HistoryItem>
              );
            })}
          </HistoryMonth>
        ))
      ) : (
        <NoData message="Brak historii do wyświetlenia" />
      )}

      {showLimit < historyData.length && (
        <Button small secondary centered onClick={() => setShowLimit(showLimit + 25)}>
          Pokaż więcej
        </Button>
      )}
    </HistoryWrapper>
  );
};

ClientHistory.defaultProps = {
  historyData: [],
};

ClientHistory.propTypes = {
  historyData: PropTypes.instanceOf(Array),
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: state.user.data.role.slug,
});

export default connect(mapStateToProps)(ClientHistory);

const HistoryWrapper = styled.div`
  overflow-y: auto;
  max-height: 75vh;
  margin-right: -10px;
  padding-right: 20px;
`;

const HistoryMonth = styled.div`
  & > span {
    text-transform: capitalize;
    display: block;
    padding: 8px 38px;
    margin-bottom: 15px;
    font-size: ${font.size.xl};
    color: ${colors.darkGray};
    letter-spacing: 1px;
    background: linear-gradient(to right, ${colors.lighterGray}, transparent);
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
const HistoryItemShowMore = styled.div`
  text-align: right;
  color: ${colors.orange};
  font-weight: ${font.weight.semibold};
  cursor: pointer;
  user-select: 'none';
`;

const HistoryItemDate = styled.div`
  flex: 0 0 50px;
  max-width: 50px;
  margin-right: 15px;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }

  span {
    display: block;
  }

  span:last-child {
    font-size: ${font.size.s};
    color: ${colors.mediumGray};
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }
`;

const HistoryItem = styled.div`
  position: relative;
  padding-left: 30px;
  margin-left: 8px;
  border-left: 2px dashed ${colors.lightGray};
  padding-bottom: 10px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -9px;
    top: 5px;
    width: 16px;
    height: 16px;
    border: 2px solid ${colors.orange};
    background-color: #ffffff;
    padding: 2px;
    border-radius: 50%;
    background-clip: content-box;
  }

  &:hover&::before {
    background-color: ${colors.orange};
  }

  &:last-of-type {
    border-left-color: transparent;

    &::before {
      background-color: ${colors.orange};
      background-clip: initial;
    }
  }

  /* &:hover {
    background: linear-gradient(
      to right,
      transparent 0%,
      ${colors.lighterGray} 15%,
      ${colors.lighterGray} 85%,
      transparent
    );
  } */

  &:hover ${HistoryItemDate} span:last-child {
    opacity: 1;
  }
`;

const HistoryItemTop = styled.div`
  display: flex;
  align-items: baseline;
`;

const HistoryItemBottom = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  margin-bottom: 2rem;
  background-color: ${colors.blueGray};
  padding: 8px 10px;
  margin-top: 2rem;

  p {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 5px;

    span:first-child {
      font-weight: ${font.weight.semibold};
    }
  }

  hr {
    border-top: 1px solid ${colors.gray};
  }
`;

const StyledHistoryIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: 2px;
  flex: 0 1 20px;
  margin-right: 15px;
  text-align: center;
  color: ${colors.mediumGray};
`;

const HistoryItemDescription = styled.div`
  flex-grow: 1;

  p {
    margin-top: 0;
  }

  p:first-child {
    color: ${colors.orange};
    font-weight: ${font.weight.semibold};
    font-size: ${font.size.l};
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const HistoryItemDiffTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  th {
    text-align: left;
    width: 40%;

    &.propName {
      width: auto;
    }
  }

  th,
  td {
    border: 1px solid ${colors.gray};
  }

  td,
  th {
    padding: 4px 8px;
    white-space: pre-wrap;
  }
`;
