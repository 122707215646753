import makeRequest from 'services/makeRequest';

const GET_USERS_SUGGESTIONS_URL = '/dashboard/users/search-form-suggestion';

const getUsersSuggestions = (search, roleSlug = null, limit = 20) => {
  return makeRequest({
    method: 'get',
    url: `${GET_USERS_SUGGESTIONS_URL}`,
    params: {
      search,
      roleSlug,
      limit,
    },
  });
};

// const getPepUsersSuggestions = (search, roleSlug = null) => {
//   return makeRequest({
//     method: 'get',
//     url: `${GET_PEP_USERS_SUGGESTIONS_URL}`,
//     params: {
//       search,
//       roleSlug
//     },
//   });
// };

export const suggestionsService = {
  getUsersSuggestions,
};
