import { statisticService } from 'services/raports';
import * as types from './types';

export const getLeadsStatistics = (dateFrom, dateTo, pickedProducts, structure) => dispatch => {
  dispatch({ type: types.GET_LEADS_STATISTICS_REQUEST });

  return statisticService
    .getLeadsStatistics(dateFrom, dateTo, pickedProducts, structure)
    .then(payload => {
      dispatch({ type: types.GET_LEADS_STATISTICS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_LEADS_STATISTICS_FAILURE, err });
    });
};

export const getSettlementStatistics = (dateFrom, dateTo, patronId = '') => dispatch => {
  dispatch({ type: types.GET_SETTLEMENT_STATISTICS_REQUEST });

  return statisticService
    .getSettlementStatistics(dateFrom, dateTo, patronId)
    .then(payload => {
      dispatch({ type: types.GET_SETTLEMENT_STATISTICS_SUCCESS, payload });
    })
    .catch(err => {
      console.log(err)
      dispatch({ type: types.GET_SETTLEMENT_STATISTICS_FAILURE, err });
    });
};

export const clearStatistics = () => dispatch => {
  dispatch({ type: types.CLEAR_STATISTICS });
};
