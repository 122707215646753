import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { TableWrapperCommon, baseTableMixin } from 'components/layout/Tables/TableComponents';
import { resolveObjProp } from 'utils/helpers';
import NoData from 'components/UI/NoData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'theme/styles';
import CopyString from 'components/UI/CopyString';

const handleRound = (propValue) => Math.round(propValue * 100) / 100;

const LeadsMerchantTable = ({ headers, data, offset, handleRowClick, actions }) => {
  const total = data.reduce((acc, row) => (acc += Object.values(row.headers).reduce((a, v) => a + v, 0)), 0);

  const totalByMonth = data.reduce((acc, row) => {
    Object.entries(row.headers).forEach(([key, val]) => {
      acc[key] = acc[key] ? acc[key] + val : val;
    });
    return acc;
  }, {});

  const avgByMonth = Object.values(totalByMonth).map((val) => {
    return data.length > 0 ? val / data.length : 0;
  });

  return (
    <TableWrapper>
      {data.length > 0 ? (
        <>
          <table>
            <colgroup>
              <col className="lp" />
              {headers.map((header) => (
                <col key={header.property} className={header.class ? header.class : header.property} />
              ))}
              {actions && <col className="actions" />}
            </colgroup>
            <thead>
              <tr>
                <th>LP</th>
                {headers.map((header) => (
                  <th className={header.headerClass} key={header.property}>
                    {header.displayName}
                  </th>
                ))}
                {actions && <th key="edit">Akcje</th>}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <StyledRow key={item.id || index} onClick={handleRowClick ? () => handleRowClick(item) : undefined}>
                  <td>{offset + index + 1}</td>
                  {headers.map((header) => {
                    const uniqueKey = item.id + header.property;
                    let propValue = resolveObjProp(header.property, item);

                    if (header.mapValue) propValue = header.mapValue(header.property, propValue, item);
                    if ((header.property === 'totalLeads' || header.property === 'avgLeads') && propValue)
                      propValue = handleRound(propValue);

                    const style = {
                      ...header.style,
                      ...(propValue == 0 && { color: colors.textDark }),
                    };
                    return (
                      <td className={header.cellClass} style={style || null} key={uniqueKey}>
                        {propValue === null ? '-' : propValue}
                        {header.copy && <CopyString text={propValue} />}
                      </td>
                    );
                  })}
                  {actions && (
                    <td key="edit">
                      <span className="icons">
                        {actions.map((action) => (
                          <FontAwesomeIcon
                            key={item.id + action.icon}
                            title={action.title}
                            icon={action.icon}
                            color={action.color}
                            onClick={() => action.handler(item)}
                          />
                        ))}
                      </span>
                    </td>
                  )}
                </StyledRow>
              ))}
            </tbody>
            <tfoot>
              <StyledRow>
                <td></td>
                <td style={{ fontWeight: '700', textAlign: 'left' }}>SUMA:</td>
                {Object.values(totalByMonth).map((val, index) => (
                  <td key={index} style={{ fontWeight: '600'}}>{val}</td>
                ))}
                <td style={{ fontWeight: '700', color: colors.red }}>{total}</td>
                <td></td>
              </StyledRow>
              <StyledRow>
                <td></td>
                <td style={{ fontWeight: '700', textAlign: 'left' }}>ŚREDNIA:</td>
                {Object.values(avgByMonth).map((val, index) => (
                  <td key={index} style={{ fontWeight: '600'}}>{handleRound(val)}</td>
                ))}
                <td></td>
                <td></td>
              </StyledRow>
            </tfoot>
          </table>
        </>
      ) : (
        <NoData message="Brak danych" />
      )}
    </TableWrapper>
  );
};

// const extraData = [
//   [
//     ...Object.values(totalByMonth).map((val) => ({
//       display: val,
//       style: { fontWeight: '600' },
//     })),
//     { display: total, style: { fontWeight: '700', color: colors.red } },
//     { display: '' },
//   ],
//   [
//     { display: '' },
//     { display: 'ŚREDNIA', style: { fontWeight: '700', textAlign: 'left' } },
//     ...Object.values(avgByMonth).map((val) => ({
//       display: val,
//       style: { fontWeight: '600' },
//       mapValue: handleRound,
//     })),
//     { display: '' },
//     { display: '' },
//   ],
// ];
LeadsMerchantTable.defaultProps = {
  handleRowClick: undefined,
  actions: false,
  offset: 0,
};

LeadsMerchantTable.propTypes = {
  handleRowClick: PropTypes.func,
  headers: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number,
  data: PropTypes.instanceOf(Array).isRequired,
  actions: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool]),
};

export default LeadsMerchantTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  ${baseTableMixin}
`;

const StyledRow = styled.tr`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${colors.green}10 !important;
      }
    `}
`;
