import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { assignMessageToUser, humanFileSize } from 'utils/helpers';
import { addMessage, setConversationUsers } from 'redux/chat';
import { useDispatch, useSelector } from 'react-redux';
import { MessagesListTypes } from 'config/constants';
import { messageSchema } from 'utils/validation';
import { colors, font } from 'theme/styles';
import { getUserInfo } from 'redux/user';
import { socket } from 'services/socket';
import Textarea from 'components/UI/Textarea';

const SendMessage = ({ newConversationLeadId, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sendInput = useRef(null);
  const formikRef = useRef(null);
  const fileInputRef = useRef(null);

  const isSocketConnected = useSelector(state => state.socket.connected);
  const activeConversation = useSelector(s => s.chat.activeConversation.conversation);
  const me = useSelector(s => s.user.data);

  useEffect(() => {
    if (sendInput.current) sendInput.current.focus();
    if (formikRef.current) formikRef.current.resetForm();
  }, [activeConversation.id]);

  const onFormKeyUp = (e, handleSubmit) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) handleSubmit();
  };

  const handleAttachment = () => {
    fileInputRef.current.click();
  };

  // const handleChangeFile = event => {
  //   const fileUploaded = event.target.files[0];
  //   setAttachment(fileUploaded);
  //   console.log(fileUploaded);
  // };

  const handleSubmit = values => {
    // return console.log(values);
    if (!isSocketConnected) return;

    const message = values.text.trim();
    const { attachment } = values;

    let idToSend;

    switch (type) {
      case MessagesListTypes.active:
        if (activeConversation.type === 'LEAD') idToSend = activeConversation.lead.id;
        // Dla wiadomości leadowych wysyłamy id leada
        else idToSend = activeConversation.id; // Dla innych wysyłamy id konwersacji
        break;
      case MessagesListTypes.newLead:
        idToSend = newConversationLeadId;
        break;
      case MessagesListTypes.newPatron:
        idToSend = 'bez znaczenia ale truthy';
        break;
      default:
        idToSend = false;
    }

    if (!idToSend) return;

    // Jeżeli pierwsza wiadomość do patrona (utworzenie konwersacji),
    // lub kolejna wiadomość do patrona
    // Tu chyba trzeba zweryfikowac czy nie odpisujemy komuś jako PATRON (tj my dla wielu, wtedy podać conversationId)
    if (
      type === MessagesListTypes.newPatron ||
      (type === MessagesListTypes.active && activeConversation.type === 'PATRON')
    ) {
      let iAmPatronToSomeone = false;
      if (activeConversation && activeConversation.users) {
        iAmPatronToSomeone = !activeConversation.users.some(u => u.id === me.patronId);
      }

      socket.emit(
        'chat:patron:message',
        {
          message,
          ...(iAmPatronToSomeone && { conversationId: activeConversation.id }),
          ...(attachment && {
            attachment,
            attachmentName: attachment.name.split('.')[0],
            attachmentSize: attachment.size,
            attachmentExt: attachment.name.split('.')[1],
            attachmentFullName: attachment.name,
          }),
        },
        response => {
          if (!response.isOk) return;
          if (type === MessagesListTypes.newPatron) {
            dispatch(getUserInfo());
            history.replace(`/contact?type=patron&conversation=${response.data.wsConversationId}`);
          } else dispatch(addMessage(assignMessageToUser(activeConversation.users, response.data)));
        },
      );
    } else {
      socket.emit(
        'chat:lead:message',
        {
          message,
          leadId: idToSend,
          ...(attachment && {
            attachment,
            attachmentName: attachment.name.split('.')[0],
            attachmentSize: attachment.size,
            attachmentExt: attachment.name.split('.')[1],
            attachmentFullName: attachment.name,
          }),
        },
        response => {
          if (!response.isOk) return;
          if (newConversationLeadId)
            history.replace(`/contact?type=lead&conversation=${response.data.wsConversationId}`);
          else {
            const included = activeConversation.users.some(u => u.id === me.id);

            if (!included) {
              const users = [...activeConversation.users, { ...me }];
              dispatch(setConversationUsers(users, response.data.wsConversationId));
              dispatch(addMessage(assignMessageToUser(users, response.data)));
            } else dispatch(addMessage(assignMessageToUser(activeConversation.users, response.data)));
          }
        },
      );
    }

    if (sendInput.current) sendInput.current.focus();
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        text: '',
        attachment: '',
      }}
      validationSchema={messageSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values);
        setSubmitting(false);
        resetForm({});
      }}
    >
      {({ values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
        <Form onKeyUp={e => onFormKeyUp(e, handleSubmit)}>
          <SendRow>
            <StyledAttachmentButton type="button" onClick={handleAttachment} filled={values.attachment}>
              <FontAwesomeIcon icon="file-upload" />
            </StyledAttachmentButton>
            <input
              type="file"
              name="attachment"
              ref={fileInputRef}
              onChange={e => setFieldValue('attachment', e.currentTarget.files[0])}
              style={{ display: 'none' }}
            />
            <StyledTextarea
              ref={sendInput}
              name="text"
              disabled={!isSocketConnected}
              onChange={handleChange}
              onBlur={handleBlur}
              width="100%"
              locked
              value={values.text || ''}
            />
            <StyledButton type="submit">
              <FontAwesomeIcon icon="paper-plane" />
            </StyledButton>
          </SendRow>
          {values.attachment && (
            <AttachmentInfo>
              <FontAwesomeIcon
                icon="times"
                onClick={() => {
                  setFieldValue('attachment', '');
                  fileInputRef.current.value = '';
                }}
              />
              <p>{values.attachment.name}</p>
              <span>{humanFileSize(values.attachment.size)}</span>
            </AttachmentInfo>
          )}
        </Form>
      )}
    </Formik>
  );
};

SendMessage.defaultProps = {
  newConversationLeadId: null,
};

SendMessage.propTypes = {
  newConversationLeadId: PropTypes.string,
  type: PropTypes.string.isRequired,
};

const AttachmentInfo = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 2rem;

  p {
    color: ${colors.orange};
  }

  span {
    font-weight: ${font.weight.semibold};
  }

  svg {
    color: ${colors.red};
    cursor: pointer;
  }
`;

const SendRow = styled.div`
  display: flex;
  height: 4rem;
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 10rem;
  color: ${colors.green};
  background: ${colors.white};
  border-radius: 0 5px 5px 0;
  border: 1px solid ${colors.green};
  text-align: center;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: 0.5s ease;
  margin-left: 1rem;

  &:hover,
  &:focus,
  &:active {
    color: ${colors.white};
    background: ${colors.green};
  }
`;

const StyledAttachmentButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 5rem;
  color: ${colors.orange};
  background: ${colors.white};
  border-radius: 5px 0 0 5px;
  border: 1px solid ${colors.lightGray};
  text-align: center;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: 0.5s ease;

  ${props =>
    props.filled &&
    css`
      color: ${colors.white};
      background: ${colors.orange};
      border-right-color: currentColor;
    `}
  &:hover,
  &:focus,
  &:active {
    color: ${colors.white};
    background: ${colors.orange};
    border-right-color: currentColor;
  }
`;

const StyledTextarea = styled(Textarea)`
  height: 100%;
  overflow: hidden;
  border-left: none;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &:focus {
    outline: none;
    border-color: ${colors.orange};
  }
`;

export default SendMessage;
