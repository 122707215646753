/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, colors } from 'theme/styles';
import plusIcon from 'assets/icons/plus.svg';
import minusIcon from 'assets/icons/minus.svg';

const NumberInput = ({
  field: { name, value, onBlur, onChange },
  form: { setFieldValue },
  disabled,
}) => {
  const increment = () => {
    if (!disabled) {
      const newValue = Number(value) + 1;
      setFieldValue(name, Number(newValue));
    }
  };

  const decrement = () => {
    if (value > 1 && !disabled) {
      const newValue = Number(value) - 1;
      setFieldValue(name, Number(newValue));
    }
  };
  return (
    <InputWrapper>
      <StyledInput
        disabled={disabled}
        type="number"
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        value={value}
      />
      <ChangeButton icon={minusIcon} onClick={decrement} />
      <ChangeButton icon={plusIcon} onClick={increment} />
    </InputWrapper>
  );
};

NumberInput.defaultProps = {
  disabled: false,
};

NumberInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default NumberInput;

// styled components

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  margin: 0;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  max-width: 100px;
  width: 100%;
  height: 25px;
  border: none;
  border-bottom: 1px solid ${colors.mediumGray};
  font-size: ${font.size.s};
  color: ${({ disabled }) => (disabled ? colors.darkGray : colors.black)};
  background: transparent;
  line-height: 1.1;
  text-align: center;
  padding: 0;
  margin: 0;

  &:disabled ~ * {
    background-color: ${colors.lightGray};
  }
  
  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.orange};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const ChangeButton = styled.div`
  width: 25px;
  height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${colors.orange};
  background-image: ${({ icon }) => `url(${icon})`};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 50% 50%;
  margin-left: 10px;
`;
