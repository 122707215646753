import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { colors, font } from 'theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';
import NoData from 'components/UI/NoData';

const VacationsTable = ({ headers, data, offset, handleEdit, handleDelete }) => {
  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => (
              <col key={header.property} className={header.property} />
            ))}
            <col className="actions" />
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const inProgress = moment().isBefore(moment(item.endDate, ['YYYY-MM-DD']).add(1, 'd'));
              return (
                <tr key={item.id} style={!inProgress ? { color: colors.mediumGray } : {}}>
                  <td>{offset + index + 1}</td>
                  {headers.map(header => {
                    const uniqueKey = item.id + header.property;
                    switch (header.property) {
                      case 'createdAt':
                      case 'endDate':
                      case 'startDate':
                        return (
                          <td key={uniqueKey}>{moment(item[header.property], ['YYYY-MM-DD']).format('YYYY-MM-DD')}</td>
                        );
                      case 'active':
                        return (
                          <td key={uniqueKey}>{item.active === '1' ? <FontAwesomeIcon title="Aktywny" icon="check" /> : ''}</td>
                        );
                      case 'user':
                      case 'reported':
                        return (
                          <td key={uniqueKey}>
                            {item[header.property]?.name} {item[header.property]?.surname}
                          </td>
                        );
                      default:
                        if (header.subProperty) {
                          return (
                            <td key={uniqueKey}>
                              {item[header.property] ? item[header.property][header.subProperty] : ''}
                            </td>
                          );
                        }
                        return <td key={uniqueKey}>{item[header.property]}</td>;
                    }
                  })}
                  <td key="edit">
                    <span className="icons">
                      <FontAwesomeIcon title="Edytuj" icon="edit" onClick={() => handleEdit(item.id)} />
                      <FontAwesomeIcon title="Usuń" icon="trash-alt" onClick={() => handleDelete(item)} />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoData message="Brak danych" />
      )}
    </TableWrapper>
  );
};

VacationsTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default VacationsTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 450px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;
    text-align: center;

    @media (min-width: 940px) {
      col.actions {
        width: 140px;
      }
    }

    tr {
      th:first-child,
      td:first-child {
        text-align: left;
        padding-left: 20px;
      }

      th,
      td {
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};

          /* Prevent actions incons wrap on small screens */
          &:last-child {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 40px;
        color: ${colors.black};

        &:nth-of-type(even) {
          background-color: ${colors.white};
        }

        td {
          font-size: ${font.size.s};
          text-transform: none;

          & > svg {
            color: ${colors.orange};
          }

          & > .icons {
            display: inline-block;
            min-width: 60px;
            color: ${colors.orange};
            svg {
              cursor: pointer;
              margin: 0 7px;
            }
          }
        }
      }
    }
  }
`;
