import styled from 'styled-components';
import { colors } from 'theme/styles';
import Message from './Message';
import MessagesList from './MessagesList';
import Conversation from './Conversation';
import ConversationsList from './ConversationsList';

const Chat = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  color: ${colors.textBlack};
  /* height: 100%;
  max-height: calc(100vh - 10rem); */
  height: calc(100vh - 10rem);
  margin-bottom: 20px;
  overflow: hidden;
`;

Chat.Message = Message;
Chat.MessagesList = MessagesList;
Chat.Conversation = Conversation;
Chat.ConversationsList = ConversationsList;

export default Chat;
