import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import NoData from 'components/UI/NoData';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { displayDate, displayTime, getTrendIcon } from 'utils/helpers';
import { useSelector } from 'react-redux';

const CompaniesTable = ({ data, headers, offset, handleEdit, handleFees, handleLeadsExport }) => {
  const history = useHistory();
  const userRole = useSelector(state => state.user.data.role.slug);

  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => {
              switch (header.property) {
                case 'createdAt':
                  return <col key={header.property} className={`${header.property}-wide`} />;
                default:
                  return <col key={header.property} className={header.property} />;
              }
            })}
            <col className="actions" />
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
              <th key="edit">Edycja</th>
            </tr>
          </thead>
          <tbody>
            {data.map((company, index) => (
              <tr key={company.id}>
                <td>{offset + index + 1}</td>
                {headers.map(header => {
                  const [trendIcon, trendName] = getTrendIcon(company.trend);

                  switch (header.property) {
                    case 'createdAt':
                      return <td key={company.id + header.property}> {displayDate(company[header.property])} </td>;
                    case 'active':
                      return (
                        <td key={company.id + header.property}>
                          {company.deletedAt === null ? (
                            <>
                              <FontAwesomeIcon title="Status: aktywny" icon="check" />
                              <FontAwesomeIcon style={{ marginLeft: '1em' }} title={trendName} icon={trendIcon} />
                            </>
                          ) : (
                            <span className="inactive" title={displayTime(company.deletedAt)}>
                              Nieaktywny od {displayDate(company.deletedAt)}
                            </span>
                          )}
                        </td>
                      );
                    case 'leadMaster':
                      return (
                        <td key={company.id + header.property}>
                          {company.leadMasterAssoc
                            ? `${company.leadMasterAssoc.name} ${company.leadMasterAssoc.surname}`
                            : ''}
                        </td>
                      );
                    default:
                      return <td key={company.id + header.property}>{company[header.property]}</td>;
                  }
                })}
                <td key="edit" style={{ width: ['admin', 'dsp'].includes(userRole) ? '200px' : '180px', padding: '0' }}>
                  <span className="icons">
                    {handleEdit && (
                      <FontAwesomeIcon title="Edytuj" icon="edit" onClick={() => handleEdit(company.id)} />
                    )}
                    <FontAwesomeIcon
                      title="Lista użytkowników"
                      icon="list"
                      onClick={() =>
                        history.push({
                          pathname: routes.adminCompanyUsers,
                          state: { company },
                        })
                      }
                    />
                    {['admin', 'dsp', 'guest'].includes(userRole) && (
                      <FontAwesomeIcon
                        title="Karta Partnera"
                        icon="address-book"
                        onClick={() =>
                          history.push({
                            pathname: routes.clientPartner.replace(':id', company.id),
                          })
                        }
                      />
                    )}
                    <FontAwesomeIcon
                      title="Analityka"
                      icon="chart-line"
                      onClick={() =>
                        history.push({
                          pathname: `${routes.adminCompanyAnalysis}`,
                          state: { company },
                        })
                      }
                    />
                    <FontAwesomeIcon
                      title="Indywidualne stawki produktowe"
                      icon="wallet"
                      onClick={() => handleFees(company.id)}
                    />
                    <FontAwesomeIcon
                      title="Fakturowanie"
                      icon="file-invoice"
                      onClick={() =>
                        history.push({
                          pathname: `${routes.partnerInvoicing}`,
                          state: { company },
                        })
                      }
                    />
                    {handleLeadsExport && (
                      <FontAwesomeIcon
                        title="Export leadow - Excel"
                        icon="file-excel"
                        onClick={() => handleLeadsExport(company.id)}
                      />
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData />
      )}
    </TableWrapper>
  );
};

CompaniesTable.defaultProps = {
  handleLeadsExport: false,
  handleEdit: false,
};

CompaniesTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleFees: PropTypes.func.isRequired,
  handleLeadsExport: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default CompaniesTable;

// styled components
const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 720px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;

    col.actions {
      width: 145px;
    }

    th,
    td {
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;

      &:not(:first-child) {
        border-left: 2px solid ${colors.white};
      }
    }

    thead {
      background-color: ${colors.white};
      tr {
        height: 50px;
        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 40px;

        &:nth-of-type(even) {
          background-color: ${colors.white};
        }
      }

      td {
        font-size: ${font.size.s};
        text-transform: none;
        color: ${colors.black};

        & > svg {
          color: ${colors.orange};
        }

        & > .icons {
          color: ${colors.orange};
          font-size: ${font.size.m};
          svg {
            cursor: pointer;
            margin: 0 7px;
          }
        }
      }
    }
  }
`;
