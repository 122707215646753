import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BASE_URL } from 'config';
import { breakpoints } from 'theme/styles';
import Heading from 'components/UI/Heading';
import EditProfile from 'components/forms/EditProfile';
import UserSettings from 'components/forms/UserSettings';
import Avatar from 'components/UI/Avatar';
import ChangePassword from 'components/forms/ChangePassword';
import LeadsExport from 'components/layout/LeadsExport';
import UserIntegrations from './UserIntegrations';

const UserProfile = ({ user }) => {
  const AVATAR_URL = `${BASE_URL}/storage/users/avatar/`;
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    if (user.avatar !== null) {
      setAvatarUrl(AVATAR_URL + user.avatar);
    }
  }, [AVATAR_URL, user.avatar]);

  return (
    <Wrapper>
      <Column>
        <Heading size="xl">{user.role}</Heading>
        <InnerWrapper>
          <Avatar url={avatarUrl} />
          <UserDetails>
            <EditProfile userData={user} />
          </UserDetails>
        </InnerWrapper>
        <Heading>Zarządzanie</Heading>
        <InnerWrapper>
          <ChangePassword userId={user.id} />
        </InnerWrapper>
        {['admin', 'dsp'].includes(user.roleSlug) && (
          <>
            <Heading>Export leadów</Heading>
            <div style={{ margin: '20px 0' }}>
              <LeadsExport />
            </div>
          </>
        )}
      </Column>
      <Column>
        <UserSettings />
        <UserIntegrations />
      </Column>
    </Wrapper>
  );
};

UserProfile.defaultProps = {
  user: {
    avatar: null,
  },
};

UserProfile.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.string,
    role: PropTypes.string.isRequired,
    roleSlug: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

export default UserProfile;

// styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 0 1 100%;
  height: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    flex: 0 1 50%;
    max-width: 50%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;

  @media (max-width: ${breakpoints.mobileSmall}) {
    &:first-of-type {
      align-items: center;
      flex-direction: column;
    }
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 20px;

  @media (max-width: ${breakpoints.mobileSmall}) {
    margin-top: 30px;
    padding: 0;
    align-self: stretch;
  }
`;
