import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, font } from 'theme/styles';
import { copyToClipboard } from 'utils/helpers';
import { TableWrapperCommon, TdWithTitle } from 'components/layout/Tables/TableComponents';
import { duplicateTypes } from 'config/constants';
import NoData from 'components/UI/NoData';
// import Tooltip from 'components/UI/Tooltip';

const ApiKeysTable = ({ headers, data, offset, handleEdit, handleDelete }) => {
  const [clickedIcon, setClickedIcon] = useState(null);

  const handleCopy = (id, key) => {
    setClickedIcon(id);
    setTimeout(() => setClickedIcon(null), 1500);
    copyToClipboard(key);
  };

  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => (
              <col key={header.property} className={header.property} />
            ))}
            <col className="actions" />
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {data.map((apiKey, index) => (
              <tr key={apiKey.id}>
                <td>{offset + index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = apiKey.id + header.property;
                  let type;
                  switch (header.property) {
                    case 'active':
                      return (
                        <td key={uniqueKey}>
                          {apiKey.active === '1' ? <FontAwesomeIcon title="Aktywny" icon="check" /> : ''}
                        </td>
                      );
                    case 'key':
                      type = duplicateTypes.find(type => type.value === apiKey.duplicateType);
                      return (
                        <TdWithTitle small key={uniqueKey}>
                          {`${apiKey.key.substring(0, 40)}...`}
                          <span>{apiKey.key}</span>
                          {type ? <FontAwesomeIcon title={type.display} icon={type.icon} /> : null}
                        </TdWithTitle>
                      );
                    case 'user':
                      return <td key={uniqueKey}>{apiKey.user ? apiKey.user.email : 'Klucz globalny'}</td>;
                    case 'date':
                      return <td key={uniqueKey}>{moment(apiKey.updatedAt).format('L')}</td>;
                    default:
                      return <td key={uniqueKey}>{apiKey[header.property]}</td>;
                  }
                })}
                <td key="edit">
                  <span className="icons">
                    <FontAwesomeIcon
                      title="Kopiuj klucz"
                      icon={clickedIcon === apiKey.id ? 'clipboard-check' : 'clipboard'}
                      onClick={() => handleCopy(apiKey.id, apiKey.key)}
                    />
                    <FontAwesomeIcon title="Edytuj" icon="edit" onClick={() => handleEdit(apiKey.id)} />
                    <FontAwesomeIcon title="Usuń" icon="trash-alt" onClick={() => handleDelete(apiKey.id)} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData message="Brak kluczy" />
      )}
    </TableWrapper>
  );
};

ApiKeysTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ApiKeysTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 450px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;
    text-align: center;

    @media (min-width: 940px) {
      col.actions {
        width: 140px;
      }
    }

    tr {
      th:first-child,
      td:first-child {
        text-align: left;
        padding-left: 20px;
      }

      th,
      td {
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};

          /* Prevent actions incons wrap on small screens */
          &:last-child {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 40px;

        &:nth-of-type(even) {
          background-color: ${colors.white};
        }

        td {
          font-size: ${font.size.m};
          text-transform: none;
          color: ${colors.black};

          & > svg {
            color: ${colors.orange};
          }

          & > .icons {
            display: inline-block;
            min-width: 60px;
            color: ${colors.orange};
            svg {
              cursor: pointer;
              margin: 0 7px;
            }
          }
        }
      }
    }
  }
`;
