import { useState } from 'react';

const initialPag = {
  count: 0,
  totalPages: 0,
  nextPage: 0,
  currentPage: 0,
  previousPage: 0,
};

export default (initialPagination = initialPag) => {
  const [pag, setPagination] = useState(initialPagination);
  const [perPage, setPerPage] = useState('20');
  const [page, setPage] = useState('1');

  const handlePerPage = e => {
    setPerPage(e.target.value);
    setPage('1');
  };

  const pagination = { ...pag, page, perPage };

  return { pagination, handlePerPage, setPage, setPagination };
};
