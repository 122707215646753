import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import MainTemplate from 'templates/MainTemplate';
import store from 'redux/store';
import RootRouter from './RootRouter';

const Root = () => (
  <Provider store={store}>
    <BrowserRouter>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <MainTemplate>
          <RootRouter />
        </MainTemplate>
      </StyleSheetManager>
    </BrowserRouter>
  </Provider>
);


// This implements the default behavior from styled-components v5 (removes warning for proptypes)
function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}


export default Root;
