import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
// import { connect } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import { adminService } from 'services/admin';
import { vacationSchema } from 'utils/validation';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import useFetchSuggestions from 'hooks/useFetchSuggestions';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import DateTimePicker from 'components/UI/DateTimePicker';
import InputAuto from 'components/UI/InputAuto';
import Checkbox from 'components/UI/Checkbox';
import Loading from 'components/UI/Loading';

const VacationForm = ({ toggleClose, initialValues, editMode, refetch, reportedBy }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [merchantsList, fetchMerchants] = useFetchSuggestions();

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleClose = resetForm => {
    setSuccess(false);
    setError('');
    resetForm({});
    toggleClose();
  };

  const handleSubmit = values => {
    setError('');
    setLoading(true);

    const validValues = {
      startDate: moment(values.startDate, ['YYYY-MM-DD']).format('DD-MM-YYYY'),
      endDate: moment(values.endDate, ['YYYY-MM-DD']).format('DD-MM-YYYY'),
      active: values.active ? '1' : '0',
    };

    if (!editMode) {
      validValues.reportedBy = reportedBy;
      validValues.userId = values.user;
      validValues.deputyPh = values.deputyPh;
    }

    const thenFn = data => {
      console.log(data);
      setSuccess(true);
      setLoading(false);
      refetch();
    };

    const catchFn = err => {
      console.log(err);
      setError(err.response.data.error.message);
      setLoading(false);
    };

    if (initialValues.id) {
      adminService
        .updateVacation(initialValues.id, validValues)
        .then(thenFn)
        .catch(catchFn);
    } else {
      adminService
        .addVacation(validValues)
        .then(thenFn)
        .catch(catchFn);
    }
  };

  const { user, startDate, endDate, deputyPhAssoc: deputyPh = '' } = initialValues;
  const active = initialValues.active === '1';

  return (
    <FormWrapperCommon>
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Dodaj urlop</Heading>
      <Formik
        initialValues={{
          user,
          startDate,
          endDate,
          active,
          deputyPh,
        }}
        enableReinitialize
        validationSchema={vacationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm, setFieldValue, setFieldTouched }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>{editMode ? 'Zaktualizowano urlop' : 'Dodano urlop'}</Heading>
                {/* {success} */}
                <div className="buttons">
                  {!editMode && <Button onClick={() => handleReturn(true, resetForm)}>Dodaj kolejne</Button>}
                  <Button gray={!editMode} onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>{editMode ? 'Błąd podczas aktualizacji' : 'Błąd podczas dodawania urlopu'}</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                  <Button gray onClick={() => handleClose(resetForm)}>
                    Zamknij
                  </Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <FieldHeading big size="m">
                {editMode ? ' Zaktualizuj urlop' : ' Wypełnij dane dotyczące urlopu'}
              </FieldHeading>
              <InputAuto
                suggestions={merchantsList}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onlyFromList
                pickProp="id"
                name="user"
                type="text"
                display={['name', 'surname', 'email']}
                onBlur={handleBlur}
                onChange={e => fetchMerchants(e.target.value, 'merchantPep')}
                label="Przedstawiciel handlowy"
                value={values.user || ''}
                error={touched.user && errors.user}
                disabled={editMode}
              />
              <ErrorMessage component={ErrorLabel} name="user" />
              <InputAuto
                suggestions={merchantsList}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onlyFromList
                pickProp="id"
                name="deputyPh"
                type="text"
                display={['name', 'surname', 'email']}
                onBlur={handleBlur}
                onChange={e => fetchMerchants(e.target.value, 'merchantPep')}
                label="Zastępca"
                value={values.deputyPh || ''}
                error={touched.deputyPh && errors.deputyPh}
                disabled={editMode}
              />
              <ErrorMessage component={ErrorLabel} name="deputyPh" />
              <FieldHeading size="s">Data rozpoczęcia urlopu</FieldHeading>
              <DateTimePicker
                onlyDays
                handleDate={date => setFieldValue('startDate', date)}
                initialDate={values.startDate}
                isMinDate={false}
                resetOnInitialChange
              />
              <ErrorMessage component={ErrorLabel} name="startDate" />
              <FieldHeading size="s">Data zakończenia urlopu</FieldHeading>
              <DateTimePicker
                onlyDays
                handleDate={date => setFieldValue('endDate', date)}
                initialDate={values.endDate}
                isMinDate={false}
                resetOnInitialChange
              />
              <ErrorMessage component={ErrorLabel} name="endDate" />

              {editMode && (
                <>
                  <FieldHeading size="s">Status urlopu</FieldHeading>
                  <Checkbox
                    disabled={!editMode}
                    name="active"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Aktywny"
                    value={values.active}
                    checked={values.active}
                    error={Boolean(touched.active && errors.active)}
                  />
                  <ErrorMessage component={ErrorLabel} name="active" />
                </>
              )}
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </FormWrapperCommon>
  );
};

VacationForm.defaultProps = {
  initialValues: {
    id: '',
    user: '',
    userId: '',
    startDate: '',
    endDate: '',
    active: '1',
    deputyPh: '',
    deputyPhAssoc: '',
  },
  editMode: false,
};

VacationForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    userId: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    // deputyPhAssoc: PropTypes.string,
    deputyPhAssoc: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  editMode: PropTypes.bool,
  toggleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  reportedBy: PropTypes.string.isRequired,
};

// const mapStateToProps = state => ({});
// const mapDispatchToProps = {};
// export default connect(mapStateToProps, mapDispatchToProps)(VacationForm);

export default VacationForm;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
