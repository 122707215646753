import * as types from './types';

const initialState = {
  currentLead: {
    isLoading: false,
    data: null,
    error: null,
  },
  currentOnboarding: {
    isLoading: false,
    data: null,
    error: null,
  },
  allLeads: {
    isLoading: false,
    data: [],
    count: 0,
    totalPages: 0,
    currentPage: 0,
    error: null,
  },
};

const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LEAD_ONBOARDING_DATA:
      return {
        ...state,
        currentOnboarding: {
          ...state.currentOnboarding,
          isLoading: false,
          data: {
            ...action.data,
            data: JSON.parse(JSON.parse(action.data.data)),
          },
          error: null,
        },
      };
    case types.GET_LEAD_ONBOARDING_REQUEST:
      return {
        ...state,
        currentOnboarding: {
          ...state.currentOnboarding,
          isLoading: true,
          data: null,
          error: null,
        },
      };
    case types.GET_LEAD_ONBOARDING_SUCCESS:
      return {
        ...state,
        currentOnboarding: {
          ...state.currentOnboarding,
          isLoading: false,
          data: {
            ...action.payload.data,
            data: JSON.parse(JSON.parse(action.payload.data.data)),
          },
          error: null,
        },
      };
    case types.GET_LEAD_ONBOARDING_FAILURE:
      return {
        ...state,
        currentOnboarding: {
          ...state.currentOnboarding,
          isLoading: false,
          data: null,
          error: action.err.response.data.error,
        },
      };
    case types.CLEAR_CURRENT_ONBOARDING:
      return {
        ...state,
        currentOnboarding: initialState.currentOnboarding,
      };
    case types.GET_LEAD_REQUEST:
      return {
        ...state,
        currentLead: {
          ...state.currentLead,
          isLoading: true,
          data: null,
          error: null,
        },
      };
    case types.GET_LEAD_SUCCESS:
      return {
        ...state,
        currentLead: {
          ...state.currentLead,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_LEAD_FAILURE:
      return {
        ...state,
        currentLead: {
          ...state.currentLead,
          isLoading: false,
          data: null,
          error: action.err.response.data.error,
        },
      };
    case types.GET_SORTED_LEAD_REQUEST:
      return {
        ...state,
        allLeads: {
          ...state.allLeads,
          isLoading: true,
        },
      };
    case types.GET_SORTED_LEAD_SUCCESS:
      return {
        ...state,
        allLeads: {
          ...state.allLeads,
          isLoading: false,
          data: action.payload.data.rows,
          count: action.payload.data.count,
          totalPages: action.payload.data.pagination.totalPages,
          currentPage: action.payload.data.pagination.currentPage,
          error: null,
        },
      };
    case types.GET_SORTED_LEAD_FAILURE:
      return {
        ...state,
        allLeads: {
          ...state.allLeads,
          isLoading: false,
          data: [],
          count: 0,
          totalPages: 0,
          currentPage: 0,
          error: action.err.response.data.error,
        },
      };
    case types.GET_SORTED_LEAD_CANCEL:
      return {
        ...state,
        allLeads: {
          ...state.allLeads,
          isLoading: true,
        },
      };
    case types.UPDATE_CURRENT_LEAD:
      return {
        ...state,
        currentLead: {
          ...state.currentLead,
          data: {
            ...state.currentLead.data,
            ...action.payload,
          },
        },
      };
    case types.CLEAR_LEADS:
      return initialState;
    default:
      return state;
  }
};

export default leadReducer;
