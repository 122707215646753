import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, FieldArray } from 'formik';
import { Block } from 'components/UI/Structure';
import { FieldHeading, FlexFields } from 'components/forms/FormComponents';
import options from 'components/forms/LeadMerchant/others/config';
import ErrorLabel from 'components/UI/ErrorLabel';
import Checkbox from 'components/UI/Checkbox';
import Textarea from 'components/UI/Textarea';

const LeadMerchantStep6 = ({ values, touched, errors, handleChange, handleBlur, viewMode, debugMode }) => {
  const { products: stepValues } = values;
  const { products: stepTouched = {} } = touched;
  const { products: stepErrors = {} } = errors;

  const validProductsAndServices = useMemo(() => {
    const pointsIndustries = values.points.map((point) => point.industry);
    return options.productsAndServicesOptions.filter((el) => {
      if (el.exclusiveMcc) {
        return el.exclusiveMcc.some((mcc) => pointsIndustries.includes(mcc));
      }
      return true;
    });
  }, [values.points]);

  return (
    <>
      <Block noInteract={viewMode}>
        <FieldHeading mb size="l">
          Produkty i usługi dodatkowe
        </FieldHeading>
        <FieldArray
          name="products.items"
          render={(arrayHelpers) => (
            <FlexFields cols={2}>
              {validProductsAndServices.map((item) => (
                <Checkbox
                  tooltip={item.tooltip}
                  key={item.nameValue}
                  name="products.items"
                  disabled={viewMode}
                  onChange={(e) => {
                    if (e.target.checked) arrayHelpers.push(item.nameValue);
                    else arrayHelpers.remove(stepValues.items.indexOf(item.nameValue));
                  }}
                  onBlur={handleBlur}
                  label={item.display}
                  value={item.display}
                  checked={stepValues.items.includes(item.nameValue)}
                />
              ))}
            </FlexFields>
          )}
        />
        {stepErrors.items && <ErrorLabel style={{ marginTop: '1rem' }}>{stepErrors.items}</ErrorLabel>}
        <FieldHeading medium size="m">
          Notatki
        </FieldHeading>
        <Textarea
          disabled={viewMode}
          name="products.comment"
          onChange={handleChange}
          onBlur={handleBlur}
          width="100%"
          locked
          height="92px"
          value={stepValues.comment || ''}
          error={stepTouched.notes && stepErrors.notes}
        />
        <ErrorMessage component={ErrorLabel} name="products.comment" />
      </Block>
      <FieldHeading size="s" style={{ textAlign: 'right' }}>
        <span className="color-orange">Oświadczam, że dane zawarte w formularzu są zgodę ze stanem faktycznym.</span>
      </FieldHeading>
      {debugMode && (
        <>
          <Block>
            <div>
              <pre>Version: {JSON.stringify(values.version || '???', null, 4)}</pre>
              <pre>Version Number: {JSON.stringify(values.versionNumber || '???', null, 4)}</pre>
            </div>
          </Block>
          <Block>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <pre>Values: {JSON.stringify(stepValues, null, 4)}</pre>
              <pre>Errors: {JSON.stringify(errors, null, 4)}</pre>
              <pre>Touched: {JSON.stringify(touched, null, 4)}</pre>
            </div>
          </Block>
        </>
      )}
    </>
  );
};

LeadMerchantStep6.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  handleChange: () => {},
  handleBlur: () => {},
  debugMode: false,
};

LeadMerchantStep6.propTypes = {
  values: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  viewMode: PropTypes.bool.isRequired,
  debugMode: PropTypes.bool,
};

export default LeadMerchantStep6;
