import styled, { keyframes } from 'styled-components';
import { colors } from 'theme/styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export default styled.div`
  display: block;
  padding: 0.8rem 1.6rem;
  background-color: ${colors.white};
  border: 1px solid ${colors.orange};
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  animation: ${props => props.show && fadeIn} 0.5s linear forwards;
`;
