import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { adminUserSchema } from 'utils/validation';
import { adminService } from 'services/admin';
import { removeNulls, validateValuesByRole } from 'utils/helpers';
import { suggestionsService } from 'services/suggestions';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Select from 'components/UI/Select';
import Loading from 'components/UI/Loading';
import InputAuto from 'components/UI/InputAuto';
import Chip from 'components/UI/Chip';
import CountiesList from 'components/layout/CountiesList';
import InputWithIcon from 'components/layout/Forms/InputWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import Checkbox from 'components/UI/Checkbox';

const EditUser = ({
  loggedUser,
  structures,
  roles,
  countiesSuggestions,
  pepStructureSuggestions,
  userData,
  toggleClose,
  refetchUsers,
  refetchPepStructures,
}) => {
  const userRole = useSelector(state => state.user.data.role.slug);

  const [countieBoxVisible, setCountieBoxVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [companiesSug, setCompaniesSug] = useState([]);
  const [error, setError] = useState('');
  const [phsSugg, setPhsSugg] = useState([]);
  const [usersSugg, setUsersSugg] = useState([]);

  const toggleCountieBoxVisibility = () => setCountieBoxVisible(state => !state);

  const fetchCompanies = inputValue => {
    if (inputValue.length <= 2) return;
    adminService
      .getAllCompanies({
        limit: 10,
        page: 1,
        showDeleted: false,
        search: inputValue,
      })
      .then(payload => setCompaniesSug(payload.data.rows))
      .catch(err => {
        console.log(err);
      });
  };

  const fetchUsers = (inputValue, role, setList) => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue, role, 20)
      .then(payload => setList(payload.data))
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    const excludeForDsp = ['admin', 'dsp', 'skf'];
    switch (loggedUser.role.slug) {
      case 'dsp':
        setFilteredRoles(roles.filter(role => !excludeForDsp.includes(role.slug)));
        break;
      default:
        setFilteredRoles(roles);
    }
  }, [roles, loggedUser.role.slug]);

  const handleReturn = () => {
    setSuccess(false);
    setError('');
    toggleClose();
  };

  const handleSubmit = values => {
    const selectedRole = roles.find(r => r.id === values.roleId);
    let newValues = removeNulls(values);

    newValues = validateValuesByRole(values, selectedRole);

    setError('');
    setLoading(true);
    adminService
      .editUser(userData.id, newValues)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchUsers();
        refetchPepStructures();
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  const handleDeleteOrRestoreUser = () => {
    setError('');
    setLoading(true);

    const handleError = err => {
      console.log(err);
      setError(err.response.data.error.message);
    };

    const handleResponse = data => {
      console.log(data);
      setSuccess(true);
      refetchUsers();
      refetchPepStructures();
    };

    if (userData.deletedAt) {
      // Przyróć
      adminService
        .restoreUser(userData.id)
        .then(handleResponse)
        .catch(handleError)
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Usuń
      adminService
        .deleteUser(userData.id)
        .then(handleResponse)
        .catch(handleError)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const { name, surname, email, phone, structureId, roleId, patron, active, counties, coordinator } = userData;
  const roleSlug = roles.find(r => r.id === userData.roleId).slug || '';
  const companyId = userData.company || '';
  const leadMaster = userData.dedicatedLeadMaster || '';
  const mainPartner = userData.mainPartner ? '1' : '0';
  const dedicatedPhs = userData.dedicatedPhs || [];

  return (
    <Wrapper>
      {success && (
        <SuccessPopup>
          <Heading>Dane zaktualizowane</Heading>
          <div className="buttons">
            <Button onClick={handleReturn}>Zamknij</Button>
          </div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup>
          <Heading>Błąd edycji użytkownika</Heading>
          <p>{error}</p>
          <div className="buttons">
            <Button onClick={handleReturn}>Spróbuj ponownie</Button>
          </div>
        </ErrorPopup>
      )}
      {loading && <Loading absolute />}
      <Heading size="xl">Zarządzanie użytkownikiem</Heading>
      <Formik
        initialValues={{
          active,
          name,
          surname,
          email,
          structureId: structureId !== null ? structureId : '',
          roleId,
          roleSlug,
          phone,
          patron: patron || '',
          counties: counties || [],
          countieSearch: '',
          companyId,
          leadMaster,
          mainPartner,
          coordinator,
          dedicatedPhSearch: '',
          dedicatedPhs,
        }}
        validationSchema={adminUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          initialValues,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => (
          <StyledForm>
            <FieldHeading big size="m">
              Wprowadź dane:
            </FieldHeading>
            <Input
              name="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Imię"
              value={values.name || ''}
              error={touched.name && errors.name}
            />
            <ErrorMessage component={ErrorLabel} name="name" />
            <Input
              name="surname"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Nazwisko"
              value={values.surname || ''}
              error={touched.surname && errors.surname}
            />
            <ErrorMessage component={ErrorLabel} name="surname" />
            <Input
              name="phone"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Numer Telefonu"
              value={values.phone || ''}
              error={touched.phone && errors.phone}
            />
            <ErrorMessage component={ErrorLabel} name="phone" />
            <Input
              name="email"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Adres email"
              value={values.email || ''}
              error={touched.email && errors.email}
            />
            <ErrorMessage component={ErrorLabel} name="email" />
            <InputAuto
              suggestions={usersSugg}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onlyFromList
              pickProp="email"
              name="patron"
              type="text"
              display={['name', 'surname', 'email']}
              onBlur={handleBlur}
              onChange={e => fetchUsers(e.target.value, null, setUsersSugg)}
              label="Opiekun"
              value={values.patron || ''}
              error={touched.patron && errors.patron}
            />
            <ErrorMessage component={ErrorLabel} name="patron" />
            <Select
              name="roleId"
              value={values.roleId || ''}
              onChange={e => {
                setFieldValue('roleSlug', e.target[e.target.selectedIndex].getAttribute('data-slug'));
                setFieldValue('companyId', initialValues.companyId || initialValues.companyId.id || '');
                setFieldValue('leadMaster', initialValues.leadMaster || initialValues.leadMaster.id || '');
                // setFieldValue('dedicatedPhs', initialValues.dedicatedPhs);
                handleChange(e);
              }}
              onBlur={handleBlur}
              label="Rola użytkownika"
            >
              <option key="default" value="">
                {' '}
              </option>
              {filteredRoles.map(role => (
                <option key={role.id} value={role.id} data-slug={role.slug}>
                  {role.name}
                </option>
              ))}
            </Select>
            <ErrorMessage component={ErrorLabel} name="roleId" />
            {values.roleSlug && values.roleSlug === 'merchantPep' && (
              <>
                <InputWithIcon>
                  <InputAuto
                    suggestions={countiesSuggestions}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="countieSearch"
                    type="text"
                    display={['name']}
                    onBlur={handleBlur}
                    onSelect={(val, item) => {
                      if (!values.counties.some(c => c.id === item.id)) {
                        setFieldValue('counties', [...values.counties, item]);
                      }
                      setFieldValue('countieSearch', '');
                    }}
                    label="Dodaj powiat"
                    value={values.countieSearch || ''}
                    error={touched.countieSearch && errors.countieSearch}
                  />
                  <FontAwesomeIcon icon="list" onClick={toggleCountieBoxVisibility} />
                </InputWithIcon>
                <div style={{ marginBottom: '10px' }}>
                  {countieBoxVisible && (
                    <CountiesList
                      initialPicked={values.counties}
                      counties={countiesSuggestions}
                      closeHandler={() => setCountieBoxVisible(false)}
                      saveHandler={pickedCounties => {
                        toggleCountieBoxVisibility();
                        const uniquePicks = pickedCounties.filter(el => !values.counties.some(c => c.id === el.id));
                        setFieldValue('counties', [...values.counties, ...uniquePicks]);
                      }}
                    />
                  )}
                </div>
                <div>
                  {values.counties &&
                    values.counties.map(c => (
                      <Chip
                        key={`${c.id}`}
                        onClick={() => {
                          const newValue = values.counties.filter(el => el.id !== c.id);
                          setFieldValue('counties', newValue);
                        }}
                      >
                        {c.name}
                      </Chip>
                    ))}
                </div>
              </>
            )}
            {values.roleSlug && (values.roleSlug === 'partner' || values.roleSlug === 'subPartner') && (
              <>
                <InputAuto
                  suggestions={companiesSug}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onlyFromList
                  pickProp="id"
                  name="companyId"
                  type="text"
                  display={['name']}
                  onBlur={handleBlur}
                  onChange={e => fetchCompanies(e.target.value)}
                  label="Firma"
                  value={values.companyId || ''}
                  error={touched.companyId && errors.companyId}
                />
                <ErrorMessage component={ErrorLabel} name="companyId" />
                {values.roleSlug === 'partner' && (
                  <>
                    <Checkbox
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      name="mainPartner"
                      onChange={e => setFieldValue('mainPartner', e.target.checked ? '1' : '0')}
                      onBlur={handleBlur}
                      label="Partner główny"
                      value={values.mainPartner}
                      checked={values.mainPartner === '1'}
                      error={touched.mainPartner && errors.mainPartner}
                    />
                    <Checkbox
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      name="coordinator"
                      onChange={e => setFieldValue('coordinator', e.target.checked ? '1' : '0')}
                      onBlur={handleBlur}
                      label="Faktury"
                      value={values.coordinator}
                      checked={values.coordinator === '1'}
                      error={touched.coordinator && errors.coordinator}
                    />
                  </>
                )}
                <InputAuto
                  suggestions={pepStructureSuggestions}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onlyFromList
                  pickProp="id"
                  name="leadMaster"
                  type="text"
                  display={['name', 'surname', 'email']}
                  onBlur={handleBlur}
                  label="Leadmaster"
                  value={values.leadMaster || ''}
                  error={touched.leadMaster && errors.leadMaster}
                />
                <ErrorMessage component={ErrorLabel} name="leadMaster" />
                <InputAuto
                  suggestions={phsSugg}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onlyFromList
                  pickProp="id"
                  name="dedicatedPhSearch"
                  type="text"
                  display={['name', 'surname', 'email']}
                  onBlur={handleBlur}
                  onChange={e => fetchUsers(e.target.value, 'merchantPep', setPhsSugg)}
                  onSelect={(val, item) => {
                    if (!values.dedicatedPhs.some(dph => dph.id === item.id)) {
                      const { id, name, surname } = item;
                      setFieldValue('dedicatedPhs', [...values.dedicatedPhs, { id, name, surname }]);
                    }
                    setFieldValue('dedicatedPhSearch', '');
                  }}
                  label="Przedstawiciele dedykowani"
                  value={values.dedicatedPhSearch || ''}
                  error={touched.dedicatedPhSearch && errors.dedicatedPhSearch}
                />
                <ErrorMessage component={ErrorLabel} name="dedicatedPhs" />
                <div>
                  {values.dedicatedPhs &&
                    values.dedicatedPhs.map(dph => (
                      <Chip
                        key={`${dph.id}`}
                        onClick={() => {
                          const newValue = values.dedicatedPhs.filter(el => el.id !== dph.id);
                          setFieldValue('dedicatedPhs', newValue);
                        }}
                      >
                        {`${dph.name} ${dph.surname}`}
                      </Chip>
                    ))}
                </div>
              </>
            )}
            <Select
              name="structureId"
              value={values.structureId || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Struktura"
            >
              <option key="default" value={null}>
                {' '}
              </option>
              {structures.map(structure => (
                <option key={structure.id} value={structure.id}>
                  {structure.region}
                </option>
              ))}
            </Select>
            <ErrorMessage component={ErrorLabel} name="structureId" />
            <ButtonsWrapper>
              {values.active === '1' ? (
                <Button
                  style={{ minWidth: '160px' }}
                  gray
                  type="button"
                  onClick={() => {
                    setFieldValue('active', '0');
                    handleSubmit();
                  }}
                >
                  Dezaktywuj użytkownika
                </Button>
              ) : (
                <Button
                  style={{ minWidth: '160px' }}
                  secondary
                  type="button"
                  onClick={() => {
                    setFieldValue('active', '1');
                    handleSubmit();
                  }}
                >
                  Aktywuj użytkownika
                </Button>
              )}
              <Button style={{ minWidth: '160px' }} type="submit">
                Prześlij
              </Button>
            </ButtonsWrapper>
            {['admin'].includes(userRole) && userData.active === '0' && (
              <ButtonsWrapper>
                <Button tertiary style={{ minWidth: '160px' }} type="button" onClick={handleDeleteOrRestoreUser}>
                  {userData.deletedAt ? 'Przywróć użytkownika' : 'Usuń użytkownika'}
                </Button>
              </ButtonsWrapper>
            )}
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};

EditUser.propTypes = {
  loggedUser: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  countiesSuggestions: PropTypes.instanceOf(Array).isRequired,
  pepStructureSuggestions: PropTypes.instanceOf(Array).isRequired,
  structures: PropTypes.instanceOf(Array).isRequired,
  userData: PropTypes.instanceOf(Object).isRequired,
  toggleClose: PropTypes.func.isRequired,
  refetchUsers: PropTypes.func.isRequired,
  refetchPepStructures: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  roles: state.admin.roles.data,
  countiesSuggestions: state.admin.counties.data,
  pepStructureSuggestions: state.admin.pepStructure.data,
  structures: state.admin.structures.data,
});

export default connect(mapStateToProps)(EditUser);

// styled components

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    button {
      margin-bottom: 20px;
    }
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
