/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { colors, font } from 'theme/styles';
import Input from 'components/UI/Input';

const InputAuto = ({
  value,
  suggestions,
  name,
  display,
  setFieldValue,
  setFieldTouched,
  onlyFromList,
  onBlur,
  onChange,
  onSelect,
  afterSelect,
  pickProp,
  pickDisplay,
  isSortItem,
  excludeFromDisplay,
  ...rest
}) => {
  const [localVal, setLocalVal] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const isInitialMount = useRef(true);

  const renderDisplay = (item) => {
    const temp = display.filter((el) => !excludeFromDisplay.includes(el));
    return item ? temp.map((el) => (el === 'email' ? `— ${item[el]}` : `${item[el]}`)).join(' ') : '';
  };

  useEffect(() => {
    if (typeof value === 'string') {
      setLocalVal(value);
    } else {
      setLocalVal(renderDisplay(value));
      setSelectedItem(value);
      setFieldValue(name, value[pickProp], true);
    }
  }, []);

  useEffect(() => {
    if (value !== '') return;
    if (isInitialMount.current) isInitialMount.current = false;
    else {
      setLocalVal('');
      setSelectedItem(null);
    }
  }, [value]);

  const handleChange = (e) => {
    const val = e.target.value;
    setLocalVal(val);
    if (!onlyFromList) {
      setFieldValue(name, val, true);
    }
    onChange(e, val);
  };

  const handleSelect = (val, item) => {
    if (onSelect) {
      onSelect(val, item);
      if (isSortItem) {
        setLocalVal(renderDisplay(item));
        setSelectedItem(item);
      } else {
        setLocalVal('');
      }
    } else {
      setFieldValue(name, val, true);
      setSelectedItem(item);
      if (pickDisplay) {
        setLocalVal(Array.isArray(pickDisplay) ? pickDisplay.map((p) => item[p]).join(' ') : item[pickDisplay]);
      } else {
        setLocalVal(renderDisplay(item));
      }
    }
    afterSelect(val, item);
  };

  const handleBlur = (e) => {
    setFieldTouched(name, true, true);
    if (onlyFromList) {
      if (e.target.value === '') {
        setFieldValue(name, '', true);
        setSelectedItem(null);
        if (isSortItem) onSelect(e.target.value, null);
      } else if (value === '') {
        setLocalVal('');
      } else if (pickDisplay) {
        setLocalVal(
          Array.isArray(pickDisplay) ? pickDisplay.map((p) => selectedItem[p]).join(' ') : selectedItem[pickDisplay],
        );
      } else {
        setLocalVal(renderDisplay(selectedItem));
      }
    }
    onBlur(e);
  };

  return (
    <Wrapper isSortItem={isSortItem}>
      <Autocomplete
        items={suggestions}
        renderItem={(item, isHighlighted) => (
          <div
            key={item.id || item.value}
            style={{
              background: isHighlighted ? `${colors.orange}` : `${colors.white}`,
              color: isHighlighted ? `${colors.white}` : `${colors.black}`,
              padding: '0 10px',
            }}
          >
            {renderDisplay(item)}
          </div>
        )}
        getItemValue={(item) => item[pickProp]}
        onSelect={handleSelect}
        onChange={handleChange}
        value={localVal}
        inputProps={{
          onBlur: (e) => handleBlur(e),
          name,
          ...rest,
        }}
        renderInput={(inputProps) => <Input {...inputProps} />}
        renderMenu={(items, value) => {
          const show = value.length > 2 && items.length > 0;
          return <StyledMenu show={show}>{items}</StyledMenu>;
        }}
        shouldItemRender={(item, value) => {
          const escapedValue = value.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const searchString = display.map((showProp) => item[showProp]).join(' ');
          const index = searchString.toLowerCase().search(escapedValue);
          return index >= 0;
        }}
        wrapperStyle={{
          display: 'block',
          position: 'relative',
        }}
      />
    </Wrapper>
  );
};

InputAuto.defaultProps = {
  suggestions: [],
  value: '',
  type: 'text',
  className: '',
  placeholder: '',
  width: '',
  error: false,
  disabled: false,
  onlyFromList: false,
  onChange: () => {},
  afterSelect: () => {},
  onSelect: false,
  isSortItem: false,
  pickDisplay: '',
  excludeFromDisplay: [],
};

InputAuto.propTypes = {
  display: PropTypes.arrayOf(PropTypes.string).isRequired,
  pickDisplay: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  suggestions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  excludeFromDisplay: PropTypes.instanceOf(Array),
  type: PropTypes.oneOf(['text', 'password', 'date']),
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  pickProp: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  onlyFromList: PropTypes.bool,
  isSortItem: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  afterSelect: PropTypes.func,
  onSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default InputAuto;

// Styled components
const Wrapper = styled.div`
  ${({ isSortItem }) =>
    isSortItem &&
    css`
      * {
        margin: 0;
        padding: 0;
      }
    `}
`;

const StyledMenu = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100%;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% - 12px);
  left: 0;
  z-index: 25;
  background: ${colors.white};
  border: 1px solid ${colors.orange};
  font-size: ${font.size.s};
  cursor: pointer;
`;
