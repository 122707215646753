import * as types from './types';

const initialState = {
  // invoices: {
  //   data: {
  //     count: 0,
  //     rows: [],
  //     pagination: {
  //       totalPages: 0,
  //       nextPage: 0,
  //       currentPage: 0,
  //       previousPage: 0,
  //     },
  //   },
  //   isLoading: false,
  //   error: null,
  // },
  invoices: {
    data: [],
    isLoading: false,
    error: null,
  },
  billings: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  currentInvoice: {
    data: null,
    isLoading: false,
    error: null,
  },
  currentBilling: {
    data: null,
    isLoading: false,
    error: null,
  },
  currentInvoiceTemplate: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const invoicingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_INVOICES_REQUEST:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoading: true,
        },
      };
    case types.GET_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_ALL_INVOICES_FAILURE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };

    case types.GET_BILLINGS_REQUEST:
      return {
        ...state,
        billings: {
          ...state.billings,
          isLoading: true,
        },
      };
    case types.GET_BILLINGS_SUCCESS:
      return {
        ...state,
        billings: {
          ...state.billings,
          isLoading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case types.GET_BILLINGS_FAILURE:
      return {
        ...state,
        billings: {
          ...state.billings,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };

    case types.GET_BILLING_REQUEST:
      return {
        ...state,
        currentBilling: {
          ...state.currentBilling,
          isLoading: true,
        },
      };
    case types.SET_CURRENT_BILLING:
    case types.GET_BILLING_SUCCESS:
      return {
        ...state,
        currentBilling: {
          ...state.currentBilling,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case types.GET_BILLING_FAILURE:
      return {
        ...state,
        currentBilling: {
          ...state.currentBilling,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };

    case types.GET_INVOICE_REQUEST:
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          isLoading: true,
        },
      };
    case types.SET_CURRENT_INVOICE:
    case types.GET_INVOICE_SUCCESS:
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_INVOICE_FAILURE:
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };

    case types.GET_INVOICE_TEMPLATE_REQUEST:
      return {
        ...state,
        currentInvoiceTemplate: {
          ...state.currentInvoiceTemplate,
          isLoading: true,
        },
      };
    case types.SET_CURRENT_INVOICE_TEMPLATE:
    case types.GET_INVOICE_TEMPLATE_SUCCESS:
      return {
        ...state,
        currentInvoiceTemplate: {
          ...state.currentInvoiceTemplate,
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_INVOICE_TEMPLATE_FAILURE:
      return {
        ...state,
        currentInvoiceTemplate: {
          ...state.currentInvoiceTemplate,
          isLoading: false,
          error: action.err.response.data.error.message,
        },
      };

    case types.CLEAR_INVOICING:
      return initialState;
    default:
      return state;
  }
};

export default invoicingReducer;
