import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getMerchantLeadsAnalyticsDetails, clearCurrentAnalyticsDetails } from 'redux/admin';
import { TopSection, BottomItem, BottomSection, TableSorter, SortItem } from 'components/layout/Tables/TableComponents';
import { toHoursAndMinutes } from 'views/MerchantsAnalyticsPage';
import { sortByProp } from 'utils/helpers';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import ExtendedTable from 'components/layout/Tables/ExtendedTable';
import Select from 'components/UI/Select';
import Heading from 'components/UI/Heading';
import { routes } from 'routes';

const handleMapValueToMinutes = (fieldName, fieldValue, rowData) => {
  if ((rowData.takenDate === null && fieldName === 'calc.takenTime') || fieldValue === false) return '-';
  if (fieldValue === 0) return '< 1 min';
  if (fieldValue >= 60) return toHoursAndMinutes(fieldValue);
  return `${fieldValue} min`;
};

const headers = [
  { displayName: 'LEAD ID', property: 'frontNumLead' },
  { displayName: 'Data otrzymania', property: 'assignedPhDate', type: 'date-time-seconds', class: 'auto', useToSort: true },
  { displayName: 'Data podjęcia', property: 'takenDate', type: 'date-time-seconds', class: 'auto', useToSort: true },
  {
    displayName: 'Czas podjęcia ',
    property: 'calc.takenTime',
    class: 'auto',
    mapValue: handleMapValueToMinutes,
    useToSort: true,
  },
  {
    displayName: 'Czas rejestracji',
    property: 'calc.crmTimeRegister',
    class: 'auto',
    mapValue: handleMapValueToMinutes,
    useToSort: true,
  },
];

const MerchantsAnalyticsDetailsPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const ph = useSelector(state => state.admin.merchantsLeadsAnalyticsDetails.ph);
  const data = useSelector(state => state.admin.merchantsLeadsAnalyticsDetails.data);
  const isLoading = useSelector(state => state.admin.merchantsLeadsAnalyticsDetails.isLoading);
  const error = useSelector(state => state.admin.merchantsLeadsAnalyticsDetails.error);

  const [period, setPeriod] = useState(() => (location.state?.period ? `${location.state.period}_days` : '30_days'));
  const [order, setOrder] = useState('DESC');
  const [sortBy, setSortBy] = useState('calc.takenTime');

  const pickedData = useMemo(() => {
    const tempData = [...data[period].leads];
    if (sortBy) sortByProp(tempData, sortBy);
    if (order === 'DESC') tempData.reverse();
    return tempData;
  }, [data, order, period, sortBy]);

  useEffect(() => {
    dispatch(getMerchantLeadsAnalyticsDetails(id));
    return () => dispatch(clearCurrentAnalyticsDetails());
  }, [dispatch, id]);

  const handlePeriod = e => {
    setPeriod(e.target.value);
  };

  const handleSortBy = e => {
    setSortBy(e.target.value);
  };

  const handleOrder = e => {
    setOrder(e.target.value);
  };

  const showErrorPopup = error => <ErrorPopup fixed>{error.message}</ErrorPopup>;

  return (
    <PageTemplate>
      <TopSection style={{ alignItems: 'flex-start' }}>
        <Heading size="xxl">
          Analiza działań handlowca
          {ph ? <div className="color-orange">{`${ph.name} ${ph.surname}`}</div> : null}
        </Heading>
        <TableSorter>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={order} onChange={handleOrder}>
              <option value="DESC">Malejąco</option>
              <option value="ASC">Rosnąco</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj po</span>
            <Select small value={sortBy} onChange={handleSortBy}>
              <option value="">&nbsp;</option>
              {headers
                .filter(s => s.useToSort)
                .map(s => (
                  <option key={s.property} value={s.property}>
                    {s.displayName}
                  </option>
                ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Okres</span>
            <Select small value={period} onChange={handlePeriod}>
              <option value="30_days">30 dni</option>
              <option value="90_days">90 dni</option>
              <option value="365_days">365 dni</option>
            </Select>
          </SortItem>
        </TableSorter>
      </TopSection>
      {!isLoading && error && showErrorPopup(error)}
      {isLoading ? (
        <Loading />
      ) : (
        <ExtendedTable
          handleRowClick={item => history.push(routes.leadDetails.replace(':id', item.leadId))}
          data={pickedData || []}
          headers={headers}
        />
      )}
      <BottomSection>
        <BottomItem>Wszystkich: {pickedData.length || '-'}</BottomItem>
      </BottomSection>
    </PageTemplate>
  );
};

MerchantsAnalyticsDetailsPage.defaultProps = {};

MerchantsAnalyticsDetailsPage.propTypes = {};

export default MerchantsAnalyticsDetailsPage;
