import styled from 'styled-components';
import { colors, font } from 'theme/styles';

export default styled.button`
  font-size: ${font.size.s};
  font-weight: ${font.weight.default};
  color: ${colors.red};
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  display: inline-block;
  cursor: pointer;
`;
