import styled from 'styled-components';
import { colors, font } from 'theme/styles';

export default styled.div`
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
  }

  svg {
    cursor: pointer;
    margin-left: 10px;
    color: ${colors.orange};
    font-size: ${font.size.xl};
    opacity: .7;
    transition: opacity 0.15s ease-in;

    &:hover {
      opacity: 1;
    }
  }
`;
