import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllCompanies, getCompany, clearCurrentCompany, getPepStructures } from 'redux/admin';
import { routes } from 'routes';
import { logout } from 'redux/user';
import { leadService } from 'services/lead';
import { createLink } from 'utils/helpers';
import { adminService } from 'services/admin';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import SidePanel from 'components/layout/SidePanel';
import CompaniesTable from 'components/layout/Tables/CompaniesTable';
import Pagination from 'components/UI/Pagination';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import AddCompany from 'components/forms/Admin/AddCompany';
import EditCompany from 'components/forms/Admin/EditCompany';
import Portal from 'components/logic/Portal';
import SuccessPopup from 'components/layout/SuccessPopup';
import Heading from 'components/UI/Heading';

import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import styled from 'styled-components';
import InputAuto from 'components/UI/InputAuto';
import { suggestionsService } from 'services/suggestions';
import { partnerProgramOptions } from 'config/constants';

const headers = [
  { displayName: 'Data założenia', property: 'createdAt' },
  { displayName: 'NIP', property: 'nip' },
  { displayName: 'Nazwa', property: 'name' },
  { displayName: 'Leadmaster', property: 'leadMaster' },
  { displayName: 'Miasto', property: 'city' },
  // { displayName: 'IBAN', property: 'iban' },
  // { displayName: 'REGON', property: 'regon' },
  { displayName: 'Status', property: 'active' },
];

const AdminCompaniesPage = ({
  getAllCompanies,
  getCompany,
  clearCurrentCompany,
  companiesData,
  companiesCount,
  currentCompany,
  getPepStructures,
  totalPages,
  currentPage,
  isLoading,
  error,
  logout,
}) => {
  const history = useHistory();
  const location = useLocation();
  const userRole = useSelector(state => state.user.data.role.slug);

  const [leadmastersSugg, setLeadmastersSugg] = useState([]);

  const [isNewCompanyVisible, setNewCompanyVisible] = useState(false);
  const [isEditCompanyVisible, setEditCompanyVisible] = useState(false);

  const [exportType, setExportType] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [exportingError, setExportingError] = useState(null);

  const [page, setPage] = useState(location.state?.adminDashboardQuery?.page || '1');
  const [perPage, setPerPage] = useState(location.state?.adminDashboardQuery?.limit || '20');
  const [search, setSearch] = useState(location.state?.adminDashboardQuery?.search || '');
  const [status, setStatus] = useState(location.state?.adminDashboardQuery?.showDeleted || 'false');
  const [sort, setSort] = useState(location.state?.adminDashboardQuery?.sort || 'DESC');
  const [searchLeadmaster, setSearchLeadmaster] = useState(location.state?.adminDashboardQuery?.leadMaster || '');
  const [searchLeadmasterFullname, setSearchLeadmasterFullname] = useState(
    location.state?.adminDashboardQuery?.searchLeadmasterFullname || '',
  );
  const [searchTrend, setSearchTrend] = useState(location.state?.adminDashboardQuery?.sortTrend || '');
  const [searchPartnerProgram, setSearchPartnerProgram] = useState(
    location.state?.adminDashboardQuery?.partnerProgram || '',
  );

  const test = useCallback(
    e => {
      e.preventDefault();
      history.replace();
    },
    [history],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', test);
    return () => window.removeEventListener('beforeunload', test);
  }, [test]);

  const query = useMemo(
    () => ({
      limit: perPage,
      page,
      sort,
      ...(status && { showDeleted: status }),
      ...(searchLeadmaster && { leadMaster: searchLeadmaster }),
      ...(searchTrend && { sortTrend: searchTrend }),
      ...(searchPartnerProgram && { partnerProgram: searchPartnerProgram }),
      ...(search && { search }),
    }),
    [perPage, page, status, sort, search, searchLeadmaster, searchTrend, searchPartnerProgram],
  );

  useEffect(() => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      adminDashboardQuery: { ...query, searchLeadmasterFullname },
    });
  }, [history, query, searchLeadmasterFullname]);

  const getCompaniesData = useCallback(() => {
    getAllCompanies(query);
  }, [getAllCompanies, query]);

  useEffect(() => {
    getCompaniesData();
    return () => clearCurrentCompany();
  }, [getCompaniesData, clearCurrentCompany]);

  useEffect(() => {
    getPepStructures();
  }, [getPepStructures]);

  const toggleNewCompanyPanel = () => setNewCompanyVisible(!isNewCompanyVisible);

  const closeEditCompanyPanel = () => {
    setEditCompanyVisible(false);
    clearCurrentCompany();

    if (history.location.state && history.location.state.showCompany) {
      const state = { ...history.location.state };
      delete state.showCompany;
      history.replace({ ...history.location, state });
    }
  };

  const openEditCompanyPanel = useCallback(id => getCompany(id), [getCompany]);

  // Set options if company passed in state
  useEffect(() => {
    if (!history.location.state?.showCompany) return;
    const companyId = history.location.state?.showCompany?.id || null;
    const name = history.location.state?.showCompany?.name || null;
    if (companyId) openEditCompanyPanel(companyId);
    if (name) setSearch(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentCompany) setEditCompanyVisible(true);
  }, [currentCompany]);

  const openFeesCompanyPanel = id => {
    history.push(routes.partnerTerms.replace(':id', id));
  };

  const handleLeadsExport = useCallback(id => {
    setExporting(true);
    setExportingError(null);
    setExportType('leads');

    leadService
      .exportLeadsExcel(id)
      .then(res => {
        createLink(res, 'leads');
        setExporting(false);
      })
      .catch(err => {
        console.error(err);
        if (err.response && err.response.status === 404) setExportingError('Brak danych.');
        else setExportingError('Coś poszło nie tak. Skontaktuj się z administratorem.');
      });
  }, []);

  const handlePartnersExport = useCallback(
    withUsers => {
      setExporting(true);
      setExportingError(null);
      setExportType('partners');

      adminService
        .exportPartnersExcel(
          withUsers,
          search || null,
          status,
          searchLeadmaster || null,
          searchTrend || null,
          sort || null,
        )
        .then(res => {
          createLink(res, 'partners');
          setExporting(false);
        })
        .catch(err => {
          console.error(err);
          if (err.response && err.response.status === 404) setExportingError('Brak danych.');
          else setExportingError('Coś poszło nie tak. Skontaktuj się z administratorem.');
        });
    },
    [search, status, searchLeadmaster, searchTrend, sort],
  );

  const cancelExport = () => {
    if (exportType === 'leads') leadService.cancelExport();
    else if (exportType === 'partners') adminService.cancelPartnerExport();
    setExporting(false);
  };

  const handlePerPage = e => {
    setPerPage(e.target.value);
    setPage('1');
  };

  const handleSearch = value => {
    setSearch(value);
    setPage(1);
  };

  const handleTopBarElement = (e, set) => {
    console.log(e.target.value)
    set(e.target.value);
    setPage(1);
  };

  const fetchDsps = inputValue => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue, 'dsp')
      .then(payload => setLeadmastersSugg(payload.data))
      .catch(err => {
        console.log(err);
      });
  };

  const showErrorPopup = error => {
    if (error.status === 401) {
      return (
        <ErrorPopup fixed>
          <span>Twoja sesja wygasła. Odśwież stronę i zaloguj się ponownie</span>
          <Button
            onClick={() => {
              logout();
              history.push('/login');
            }}
          >
            Przejdź do logowania
          </Button>
        </ErrorPopup>
      );
    }
    return <ErrorPopup fixed>{error.message}</ErrorPopup>;
  };

  return (
    <PageTemplate>
      <TopSection>
        {['admin', 'dsp'].includes(userRole) && (
          <Button onClick={toggleNewCompanyPanel}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj partnera
          </Button>
        )}

        <TableSorter>
          <SortItem>
            <span>Leadmaster</span>
            <InputAuto
              isSortItem
              suggestions={leadmastersSugg}
              setFieldValue={() => {}}
              setFieldTouched={() => {}}
              onBlur={() => {}}
              onlyFromList
              name="leadmasterSearch"
              type="text"
              label=""
              display={['name', 'surname', 'email', 'id']}
              excludeFromDisplay={['email', 'id']}
              pickDisplay={['name', 'surname']}
              pickProp="id"
              onSelect={(pickedProp, pickedObj) => {
                setSearchLeadmaster(pickedProp);
                if (pickedObj) setSearchLeadmasterFullname(pickedObj.fullName);
              }}
              value={searchLeadmasterFullname}
              onChange={e => fetchDsps(e.target.value)}
            />
          </SortItem>
          <SortItem>
            <span>Kanał partnerski</span>
            <Select small value={searchPartnerProgram} onChange={e => handleTopBarElement(e, setSearchPartnerProgram)}>
              <option value="">&nbsp;</option>
              {partnerProgramOptions.map(p => (
                <option key={p.value} value={p.value}>
                  {p.display}
                </option>
              ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Trend</span>
            <Select small value={searchTrend} onChange={e => handleTopBarElement(e, setSearchTrend)}>
              <option value="">&nbsp;</option>
              <option value="UP">Rosnący</option>
              <option value="DOWN">Malejący</option>
              <option value="BALANCE">Zbalansowany</option>
              <option value="ZERO">Brak leadów</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={e => handleTopBarElement(e, setStatus)}>
              <option value="false">Aktywne</option>
              <option value="true">Nieaktywne</option>
              <option value="">Wszystkie</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={sort} onChange={e => handleTopBarElement(e, setSort)}>
              <option value="DESC">Najnowsze</option>
              <option value="ASC">Najstarsze</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {!isLoading && error && showErrorPopup(error)}
      {isLoading ? (
        <Loading />
      ) : (
        <CompaniesTable
          {...(['admin', 'dsp'].includes(userRole) && { handleEdit: openEditCompanyPanel })}
          {...(['admin', 'dsp'].includes(userRole) && { handleLeadsExport })}
          handleFees={openFeesCompanyPanel}
          data={companiesData}
          headers={headers}
          offset={(currentPage - 1) * (perPage || 20)}
        />
      )}
      {['admin', 'dsp'].includes(userRole) && (
        <ExportButtonsWrapper>
          <Button small secondary onClick={() => handlePartnersExport(false)}>
            Eksportuj partnerów
          </Button>
          <Button small secondary onClick={() => handlePartnersExport(true)}>
            Eksportuj partnerów z użytkownikami
          </Button>
        </ExportButtonsWrapper>
      )}
      <BottomSection>
        <BottomItem>Wszystkich firm: {companiesCount}</BottomItem>
        <Pagination allowPageSet currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      <SidePanel toggleClose={closeEditCompanyPanel} isVisible={isEditCompanyVisible}>
        {currentCompany !== null && isEditCompanyVisible && (
          <EditCompany
            toggleClose={closeEditCompanyPanel}
            refetchCompanies={getCompaniesData}
            companyData={currentCompany}
          />
        )}
      </SidePanel>
      <SidePanel toggleClose={toggleNewCompanyPanel} isVisible={isNewCompanyVisible}>
        <AddCompany refetchCompanies={getCompaniesData} />
      </SidePanel>
      <Portal>
        {exporting && (
          <SuccessPopup fixed>
            {exportingError ? (
              <>
                <Heading>{exportingError}</Heading>
                <div className="buttons">
                  <Button
                    onClick={() => {
                      setExporting(false);
                      setExportingError(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Heading>Eksportuje {exportType === 'leads' ? 'leady...' : 'partnerów...'}</Heading>
                <p>
                  Ta operacja może potrwać nawet kilka minut.
                  <br />
                  Nie zamykaj okna / karty przeglądarki.
                </p>
                <Loading />
                <div className="buttons">
                  <Button onClick={cancelExport}>Anuluj</Button>
                </div>
              </>
            )}
          </SuccessPopup>
        )}
      </Portal>
    </PageTemplate>
  );
};

AdminCompaniesPage.defaultProps = {
  totalPages: 1,
  currentPage: 1,
  currentCompany: null,
  companiesCount: 0,
  error: null,
};

AdminCompaniesPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  companiesData: PropTypes.instanceOf(Array).isRequired,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  currentCompany: PropTypes.instanceOf(Object),
  companiesCount: PropTypes.number,
  error: PropTypes.instanceOf(Object),
  getAllCompanies: PropTypes.func.isRequired,
  getPepStructures: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  clearCurrentCompany: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.admin.companies.isLoading,
  companiesData: state.admin.companies.data.rows,
  companiesCount: state.admin.companies.data.count,
  totalPages: state.admin.companies.data.pagination.totalPages,
  currentPage: state.admin.companies.data.pagination.currentPage,
  currentCompany: state.admin.currentCompany.data,
  error: state.admin.companies.error,
  pepStructures: state.admin.pepStructure.data,
});

const mapDispatchToProps = {
  getAllCompanies,
  getCompany,
  logout,
  clearCurrentCompany,
  getPepStructures,
};

const ExportButtonsWrapper = styled.div`
  button {
    display: inline-block;
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdminCompaniesPage);
