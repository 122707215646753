import makeRequest, { requestWithFiles } from 'services/makeRequest';

const BASE_URL = '/integration-order';

const getIntegrationOrders = params =>
  makeRequest({
    method: 'get',
    url: BASE_URL,
    params,
  });

const getIntegrationOrderDetails = id =>
  makeRequest({
    method: 'get',
    url: `${BASE_URL}/${id}`,
  });

const updateIntegrationOrderStatus = (id, statusId) =>
  makeRequest({
    method: 'post',
    url: `${BASE_URL}/${id}`,
    params: {
      status: statusId,
    },
  });

const getIntegrationOrderAttachment = id =>
  makeRequest({
    method: 'get',
    url: `${BASE_URL}/download-attachment/${id}`,
    responseType: 'blob',
  });

const addIntegrationOrderAttachment = (orderId, data) =>
  requestWithFiles({
    method: 'post',
    url: `${BASE_URL}/upload-attachment/${orderId}`,
    data,
  });

const deleteIntegrationOrderAttachment = id =>
  makeRequest({
    method: 'get',
    url: `${BASE_URL}/delete-attachment/${id}`,
  });

export const integrationOrdersService = {
  getIntegrationOrders,
  getIntegrationOrderDetails,
  updateIntegrationOrderStatus,
  getIntegrationOrderAttachment,
  addIntegrationOrderAttachment,
  deleteIntegrationOrderAttachment,
};
