import makeRequest, { requestWithFiles } from 'services/makeRequest';

const BASE_URL = '/integration-assignment-documents';

const getIntegrationDocs = params =>
  makeRequest({
    method: 'get',
    url: BASE_URL,
    params,
  });

const getIntegrationDoc = id =>
  makeRequest({
    method: 'get',
    url: `${BASE_URL}/${id}`,
  });

const getIntegrationDocFile = id =>
  makeRequest({
    method: 'get',
    url: `${BASE_URL}/${id}/download`,
    responseType: 'blob',
  });

const addIntegrationDoc = data =>
  requestWithFiles({
    method: 'post',
    url: BASE_URL,
    data,
  });

const updateIntegrationDoc = (id, data) =>
  makeRequest({
    method: 'put',
    url: `${BASE_URL}/${id}`,
    data,
  });

const deleteIntegrationDoc = id =>
  makeRequest({
    method: 'delete',
    url: `${BASE_URL}/${id}`,
  });

export const integrationDocsService = {
  getIntegrationDocs,
  getIntegrationDoc,
  getIntegrationDocFile,
  addIntegrationDoc,
  updateIntegrationDoc,
  deleteIntegrationDoc,
};
