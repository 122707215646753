import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PageTemplate from 'templates/PageTemplate';
import VimeoPlayer from 'components/logic/VimeoPlayer';
import Heading from 'components/UI/Heading';
import { TopSection } from 'components/layout/Tables/TableComponents';

const VimeoPlayerPage = () => {
  const location = useLocation();
  const history = useHistory();

  const videoLink = location.state.videoLink || false;
  const title = location.state.title || false;
  const description = location.state.description || false;

  useEffect(() => {
    if (!videoLink) history.go(-1);
  }, [history, videoLink]);

  if (!videoLink) return null;

  return (
    <PageTemplate>
      <TopSection>
        <div>
          {title && <Heading size="xxl">{title}</Heading>}
          {description && <p style={{ marginTop: '2rem' }}>{description}</p>}
        </div>
      </TopSection>
      <VimeoPlayer vimeoLink={videoLink} />
    </PageTemplate>
  );
};

export default VimeoPlayerPage;
