import makeRequest, { requestWithFiles } from 'services/makeRequest';

const MARKETING_BASE_URL = '/marketing-materials';

const getMarketingMaterials = params =>
  makeRequest({
    method: 'get',
    url: MARKETING_BASE_URL,
    params,
  });

const getMarketingMaterial = id =>
  makeRequest({
    method: 'get',
    url: `${MARKETING_BASE_URL}/${id}`,
  });

const getMarketingMaterialFile = id =>
  makeRequest({
    method: 'get',
    url: `${MARKETING_BASE_URL}/${id}/download`,
    responseType: 'blob',
  });

const addMarketingMaterial = data =>
  requestWithFiles({
    method: 'post',
    url: MARKETING_BASE_URL,
    data,
  });

const updateMarketingMaterial = (id, data) =>
  makeRequest({
    method: 'put',
    url: `${MARKETING_BASE_URL}/${id}`,
    data,
  });

const deleteMarketingMaterial = id =>
  makeRequest({
    method: 'delete',
    url: `${MARKETING_BASE_URL}/${id}`,
  });

export const marketingService = {
  getMarketingMaterials,
  getMarketingMaterial,
  getMarketingMaterialFile,
  addMarketingMaterial,
  updateMarketingMaterial,
  deleteMarketingMaterial,
};
