/* eslint-disable camelcase */
import makeRequest from 'services/makeRequest';

const INVOICE_BASE_URL = '/invoice';

const INVOICE_TEMPLATE_BASE_URL = '/invoice-schema';
const BILLINGS_BASE_URL = `${INVOICE_BASE_URL}/billings`;
const BILLINGS_RAW_BASE_URL = `/billings`;

const INVOICING_PROPOSALS_BASE_URL = '/invoice/proposals';
const INVOICING_OPT_BASE_URL = '/invoice/opt';

const getBillingXML = (id) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICE_BASE_URL}/${BILLINGS_RAW_BASE_URL}/${id}/excel`,
    responseType: 'blob',
  });
};

const setInvoiceTemplateReject = (id) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICE_BASE_URL}/${id}/reject`,
  });
};

const setInvoiceTemplateNumber = (id, data) => {
  return makeRequest({
    method: 'post',
    url: `${INVOICE_BASE_URL}/${id}/update`,
    data,
    timeout: 0,
    responseType: 'blob',
  });
};

const getAcceptedInvoicePdf = (id) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICING_PROPOSALS_BASE_URL}/${id}/file`,
    timeout: 0,
    responseType: 'blob',
  });
};

const getInvoiceTemplatePdf = (id, queryData = null) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICE_BASE_URL}/${id}/pdf`,
    timeout: 0,
    responseType: 'blob',
    params: queryData,
  });
};

const getInvoices = (queryData) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICE_BASE_URL}`,
    params: queryData,
  });
};

const getBillings = (queryData) => {
  return makeRequest({
    method: 'get',
    url: `${BILLINGS_BASE_URL}`,
    params: queryData,
  });
};

const getBillingDetails = (id) => {
  return makeRequest({
    method: 'get',
    url: `${BILLINGS_BASE_URL}/${id}`,
  });
};

const getInvoice = (id) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICING_OPT_BASE_URL}/${id}`,
  });
};

const getInvoicePdf = (id) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICING_OPT_BASE_URL}/${id}/file`,
    timeout: 0,
    responseType: 'blob',
  });
};

const getInvoiceTemplate = (id) => {
  return makeRequest({
    method: 'get',
    url: `${INVOICE_TEMPLATE_BASE_URL}/${id}`,
  });
};

const updateInvoiceTemplate = (id, data) => {
  return makeRequest({
    method: 'put',
    url: `${INVOICE_TEMPLATE_BASE_URL}/${id}`,
    data,
  });
};

const acceptInvoiceTemplate = (id, data) => {
  return makeRequest({
    method: 'post',
    url: `${INVOICE_TEMPLATE_BASE_URL}/${id}`,
    data,
  });
};

export const invoicingService = {
  getInvoices,
  getInvoice,
  getBillings,
  getBillingDetails,
  getInvoiceTemplate,
  updateInvoiceTemplate,
  acceptInvoiceTemplate,
  getInvoicePdf,
  getInvoiceTemplatePdf,
  setInvoiceTemplateNumber,
  setInvoiceTemplateReject,
  getAcceptedInvoicePdf,
  getBillingXML,
};
