import makeRequest from 'services/makeRequest';

// const ADD_CHALLENGE_URL = '/challenge';
// const EDIT_CHALLENGE_URL = '/challenge';
// const GET_CHALLENGE_URL = '/challenge';
// const DELETE_CHALLENGE_URL = '/challenge';

const BASE_CHALLENGE_URL = '/challenge';
const PARTNER_CHALLENGE_URL = '/challenge/partner';

const getAllChallenges = () => {
  return makeRequest({
    method: 'get',
    url: BASE_CHALLENGE_URL,
  });
};

const getActiveChallenges = () => {
  return makeRequest({
    method: 'get',
    url: BASE_CHALLENGE_URL,
    query: {
      active: true,
    },
  });
};

const getChallengePartner = id => {
  return makeRequest({
    method: 'get',
    url: `${PARTNER_CHALLENGE_URL}/${id}`,
  });
};

const addChallenge = data => {
  return makeRequest({
    method: 'post',
    url: BASE_CHALLENGE_URL,
    data,
  });
};

const editChallenge = (id, data) => {
  return makeRequest({
    method: 'put',
    url: `${BASE_CHALLENGE_URL}/${id}`,
    data,
  });
};

const deleteChallegne = id => {
  return makeRequest({
    method: 'delete',
    url: `${BASE_CHALLENGE_URL}/${id}`,
  });
};

export const challengeService = {
  getAllChallenges,
  getActiveChallenges,
  getChallengePartner,
  addChallenge,
  editChallenge,
  deleteChallegne,
};
