import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { editApiKeySchema } from 'utils/validation';
import { adminService } from 'services/admin';
import { suggestionsService } from 'services/suggestions';
import { FormWrapperCommon, FieldHeading } from 'components/forms/FormComponents';
import { duplicateTypes } from 'config/constants';
import Input from 'components/UI/Input';
import InputAuto from 'components/UI/InputAuto';
import Checkbox from 'components/UI/Checkbox';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import Select from 'components/UI/Select';

const EditKey = ({ data, refetchKeys, handleClose }) => {
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const { name, user, active, id, duplicateType} = data;

  const fetchUsers = inputValue => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue)
      .then(payload => setFetchedUsers(payload.data))
      .catch(err => {
        console.log(err);
      });
  };

  const handleReturn = () => {
    setSuccess(false);
    setError('');
  };

  const handleSubmit = values => {
    const valuesCopy = { ...values };
    delete valuesCopy.user;
    setError('');
    setLoading(true);
    adminService
      .updateApiKey(id, valuesCopy)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchKeys();
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {success && (
        <SuccessPopup>
          <Heading>Klucz został zaktualizowany</Heading>
          {typeof success !== 'boolean' && success}
          <div className="buttons">
            <Button onClick={handleClose}>Wróć</Button>
          </div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup>
          <Heading>Błąd aktualizacji klucza</Heading>
          <p>{error}</p>
          <div className="buttons">
            <Button onClick={handleReturn}>Spróbuj ponownie</Button>
          </div>
        </ErrorPopup>
      )}
      {loading && <Loading absolute />}
      <Heading size="xl">Zaktualizuj klucz</Heading>
      <Formik
        initialValues={{
          name,
          active,
          user,
          duplicateType
        }}
        validationSchema={editApiKeySchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSubmit(values);
          resetForm({});
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <StyledForm>
            <FieldHeading big size="m">
              Wprowadź dane:
            </FieldHeading>
            <Input
              name="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Nazwa"
              value={values.name || ''}
              error={touched.name && errors.name}
            />
            <ErrorMessage component={ErrorLabel} name="name" />
            <InputAuto
              disabled
              suggestions={fetchedUsers}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onlyFromList
              pickProp="id"
              name="user"
              type="text"
              display={['name', 'surname', 'email']}
              onChange={e => fetchUsers(e.target.value)}
              onBlur={handleBlur}
              label="Użytkownik"
              value={values.user || ''}
              error={touched.user && errors.user}
            />
            <ErrorMessage component={ErrorLabel} name="user" />
            <Select
                name="duplicateType"
                value={values.duplicateType}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Typ duplikacji"
                preFilled
              >
                {duplicateTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.display}
                  </option>
                ))}
              </Select>
              <ErrorMessage component={ErrorLabel} name="duplicateType" />
            <br style={{ marginTop: '10px' }} />
            <Checkbox
              name="active"
              type="text"
              onChange={e => {
                if (e.target.checked) {
                  setFieldValue('active', '1');
                } else {
                  setFieldValue('active', '0');
                }
              }}
              onBlur={handleBlur}
              label="Aktywny"
              value={values.active}
              checked={values.active === '1'}
              error={touched.active && errors.active}
            />
            <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
              Prześlij
            </Button>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};

EditKey.propTypes = {
  refetchKeys: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditKey;

// styled components

const Wrapper = styled(FormWrapperCommon)`
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 500px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
