/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { partnerService } from 'services/partner';
import { getAllProducts } from 'redux/product';
import { getLeadsStatistics, getSettlementStatistics } from 'redux/raports';
import { getRoles, getStructures } from 'redux/admin';
import { groupBy, mapLeadsToStatus, transformToChartData } from 'utils/helpers';
import { colors } from 'theme/styles';
import { TopSection, TableSorter, SortItem } from 'components/layout/Tables/TableComponents';
import PageTemplate from 'templates/PageTemplate';
// import Settlement from 'components/layout/Settlement';
import LinearChart from 'components/layout/LinearChart';
import Select from 'components/UI/Select';
import DateFromTo from 'components/UI/DateFromTo';
import Loading from 'components/UI/Loading';
import ErrorsWrapper from 'components/UI/ErrorsWrapper';
import LeadsFilter from 'components/forms/Statistics/LeadsFilter';
// import SettlementFilter from 'components/forms/Statistics/SettlementFilter';
import NoData from 'components/UI/NoData';
import Heading from 'components/UI/Heading';

const RaportsPage = ({
  getAllProducts,
  getLeadsStatistics,
  // getSettlementStatistics,
  getRoles,
  getStructures,
  isLoadingProds,
  errorProds,
  productsData,
  isLoadingStats,
  errorStats,
  statisticsData,
  // isLoadingSettlement,
  // errorSettlement,
  // settlementData,
  roles,
  currentUser,
  structures,
}) => {
  const [title, setTitle] = useState(null);
  const [interval, setInterval] = useState('month');
  const [datePicker, setDatePicker] = useState(null);
  const [date, setDate] = useState(null);
  const [pickedProducts, setPickedProducts] = useState(null);
  const [pickedStructure, setPickedStructure] = useState(null);
  const [localQuery, setLocalQuery] = useState(null);
  const [posInfo, setPosInfo] = useState(false);
  const [isAllowedToSeePosInfo, setIsAllowedToSeePosInfo] = useState(false);

  const currentRole = currentUser.role.slug;
  const intervalMapObj = {
    week: 'za ostatni tydzień',
    month: 'za ostatnie 31 dni',
    quarter: 'za ostatnie 3 miesiące',
    year: 'za ostatni rok',
  };

  // const [patron, setPatron] = useState(null);
  useEffect(() => {
    if (!['partner'].includes(currentUser.role.slug) || !currentUser.mainPartner || !currentUser.company) return;
    setIsAllowedToSeePosInfo(true);
    partnerService
      .getPosInfo(currentUser.company.nip)
      .then(response => {
        setPosInfo(response.data);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    getAllProducts();
    getRoles();
    getStructures();
  }, [getAllProducts, getRoles]);

  const fetchStats = useCallback(() => {
    getLeadsStatistics(date[0], date[1], pickedProducts, pickedStructure);
  }, [date, pickedProducts, pickedStructure]);

  // useEffect(() => {
  //   if (!patron) return;

  //   let tempPatron = patron;
  //   if (['partner'].includes(currentUser.role.slug)) {
  //     tempPatron = currentUser.id;
  //   } else if (['subPartner'].includes(currentUser.role.slug)) {
  //     tempPatron = currentUser.patronId;
  //   }

  //   getSettlementStatistics(
  //     moment()
  //       .startOf('month')
  //       .format('YYYY-MM-DD'),
  //     moment().format('YYYY-MM-DD'),
  //     patron || tempPatron,
  //   );
  // }, [getSettlementStatistics, patron]);

  useEffect(() => {
    if (!date) return;
    fetchStats();
  }, [date, fetchStats]);

  const memoizedData = useMemo(() => {
    if (!date || statisticsData.length === 0) return [];
    let data = [...statisticsData];

    if (localQuery && localQuery.roleId && ['admin', 'guest'].includes(currentRole)) {
      data = data.filter(el => el.roleId === localQuery.roleId);
    }

    if (localQuery && localQuery.partnerId && ['admin', 'dsp', 'guest'].includes(currentRole)) {
      data = data.filter(el => el.companyId === localQuery.partnerId);
    }

    if (localQuery && localQuery.userId && ['admin', 'dsp', 'partner', 'dr', 'guest'].includes(currentRole)) {
      data = data.filter(el => el.id === localQuery.userId);
    }

    data = mapLeadsToStatus(data);
    data = transformToChartData(data);
    const diff = moment(date[1]).diff(moment(date[0]), 'days');
    return diff > 31 ? groupBy(data, '1m', date) : groupBy(data, '1d', date);
  }, [statisticsData, localQuery]);

  useEffect(() => {
    if (interval === null) return;
    setTitle(`Podsumowanie ${intervalMapObj[interval]}`);
    const dateNow = moment();
    let dateFrom;
    let dateTo = dateNow;
    switch (interval) {
      case 'week':
        dateFrom = moment().subtract(6, 'days');
        break;
      case 'month':
        dateFrom = moment().subtract(1, 'month');
        break;
      case 'quarter':
        dateFrom = moment()
          .subtract(1, 'quarter')
          .startOf('month');
        // dateTo = moment(dateFrom).add(1, 'quarter').subtract(1, 'month');
        dateTo = moment(dateNow);
        break;
      case 'year':
      default:
        dateFrom = moment()
          .subtract(1, 'year')
          .startOf('month');
        // dateTo = moment(dateFrom).add(1, 'year');
        dateTo = moment(dateNow);
    }
    setDate([dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD')]);
  }, [interval]);

  useEffect(() => {
    if (!datePicker) return;
    if (datePicker.includes('')) {
      setInterval(() => 'month');
      return;
    }
    setTitle(`Podsumowanie za okres ${datePicker.join(' - ')}`);
    setDate(datePicker);
    setInterval(null);
  }, [datePicker]);

  const handleInterval = e => {
    setInterval(e.target.value);
  };

  return (
    <PageTemplate>
      <TopSection>
        <TableSorter>
          <SortItem>
            <span>Okres</span>
            <Select small value={interval || ''} onChange={handleInterval}>
              <option value="" disabled>
                ---
              </option>
              <option value="week">Ostatni tydzień</option>
              <option value="month">Ostatnie 31 dni</option>
              <option value="quarter">Ostatnie 3 miesiące</option>
              <option value="year">Ostatni rok</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Data</span>
            <DateFromTo handleDate={setDatePicker} />
          </SortItem>
        </TableSorter>
      </TopSection>
      <Wrapper>
        {isAllowedToSeePosInfo && (
          <Block>
            <Heading centered size="xl" style={{ marginBottom: '3rem' }}>
              Informacje o terminalach
            </Heading>
            <Row>
              <Col>
                <SimpleBlock>
                  <Heading centered>Liczba POS bieżący miesiąc:</Heading>
                  <Heading centered size="xxxxl" color={colors.orange} style={{ marginTop: '2rem' }}>
                    {posInfo.LiczbaPOS_BiezacyMiesiac || '-'}
                  </Heading>
                </SimpleBlock>
              </Col>
              <Col>
                <SimpleBlock>
                  <Heading centered>Liczba POS bieżący kwartał:</Heading>
                  <Heading centered size="xxxxl" color={colors.orange} style={{ marginTop: '2rem' }}>
                    {posInfo.LiczbaPOS_BiezacyKwartal || '-'}
                  </Heading>
                </SimpleBlock>
              </Col>
              <Col>
                <SimpleBlock>
                  <Heading centered>Liczba Aktywnych POS w bieżącym kwartale:</Heading>
                  <Heading centered size="xxxxl" color={colors.orange} style={{ marginTop: '2rem' }}>
                    {posInfo.LiczbaAktywnychPOS_BiezacyKwartal || '-'} / {posInfo.LiczbaAktywnychPOS_Total || '-'}
                  </Heading>
                </SimpleBlock>
              </Col>
            </Row>
          </Block>
        )}
        <Block>
          {title && (
            <Heading size="xl" centered>
              {title}
            </Heading>
          )}
          {errorStats && <ErrorsWrapper>{errorStats.message}</ErrorsWrapper>}
          {isLoadingStats && <Loading />}
          {!isLoadingStats && memoizedData.length > 0 ? (
            <LinearChart data={memoizedData} title={title} date={date} />
          ) : (
            <NoData />
          )}
          {errorProds && <ErrorsWrapper>{errorProds.message}</ErrorsWrapper>}
          {isLoadingProds ? (
            <Loading />
          ) : (
            <LeadsFilter
              suggestions={statisticsData}
              roles={roles}
              structures={structures}
              currentRole={currentRole}
              setPickedProducts={setPickedProducts}
              setPickedStructure={setPickedStructure}
              setLocalQuery={setLocalQuery}
              products={productsData.sort((a, b) => b.active - a.active)}
            />
          )}
        </Block>
      </Wrapper>
    </PageTemplate>
  );
};

RaportsPage.defaultProps = {
  errorStats: null,
  // errorSettlement: null,
};

RaportsPage.propTypes = {
  getLeadsStatistics: PropTypes.func.isRequired,
  // getSettlementStatistics: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getStructures: PropTypes.func.isRequired,

  isLoadingProds: PropTypes.bool.isRequired,
  errorProds: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  productsData: PropTypes.instanceOf(Array).isRequired,

  isLoadingStats: PropTypes.bool.isRequired,
  errorStats: PropTypes.instanceOf(Object),
  statisticsData: PropTypes.instanceOf(Array).isRequired,

  // isLoadingSettlement: PropTypes.bool.isRequired,
  // errorSettlement: PropTypes.instanceOf(Object),
  // settlementData: PropTypes.instanceOf(Object).isRequired,

  roles: PropTypes.instanceOf(Array).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  structures: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  isLoadingProds: state.products.isLoading,
  errorProds: state.products.error,
  productsData: state.products.data,

  isLoadingStats: state.raport.statistics.isLoading,
  errorStats: state.raport.statistics.error,
  statisticsData: state.raport.statistics.data,

  isLoadingSettlement: state.raport.settlement.isLoading,
  errorSettlement: state.raport.settlement.error,
  settlementData: state.raport.settlement.data,

  roles: state.admin.roles.data,
  currentUser: state.user.data,
  structures: state.admin.structures.data,
});

const mapDispatchToProps = {
  getLeadsStatistics,
  getSettlementStatistics,
  getAllProducts,
  getRoles,
  getStructures,
};

export default connect(mapStateToProps, mapDispatchToProps)(RaportsPage);

// styled components

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  /* & > *:not(:last-child) {
    margin-bottom: 3rem;
  } */

  & > * {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }

  /* @media (min-width: 769px) {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  } */
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;

  @media (min-width: 769px) {
    gap: 3rem;
  }
`;

const Col = styled.div`
  flex: 1 0 25rem;

  @media (min-width: 769px) {
    gap: 3rem;
  }
`;

// const ChartWrapper = styled.section`
//   margin-left: auto;
//   margin-right: auto;
// `;

// const ColLeft = styled.section`
//   @media (min-width: 769px) {
//     padding-right: 15px;
//     flex: 0 1 40%;
//     max-width: 40%;
//   }
// `;

// const ColRight = styled.section`
//   @media (min-width: 769px) {
//     padding-left: 15px;
//     flex: 0 1 60%;
//     max-width: 60%;
//   }
// `;

const Block = styled.div`
  width: 100%;
  box-shadow: 0px 3px 15px #00000010;
  padding: 2rem;
  background: ${colors.white};

  @media (min-width: 769px) {
    padding: 3rem;
  }
`;

const SimpleBlock = styled.div`
  padding: 2rem 2rem 3rem 2rem;
  background: ${colors.lighterGray};

  @media (min-width: 769px) {
    padding: 3rem 3rem 4rem 3rem;
    height: 100%;
  }
`;
