import { marketingService } from 'services/marketing';
import * as types from './types';

export const getMarketingMaterials = query => dispatch => {
  dispatch({ type: types.GET_MARKETING_MATERIALS_REQUEST });

  marketingService
    .getMarketingMaterials(query)
    .then(payload => {
      dispatch({ type: types.GET_MARKETING_MATERIALS_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_MARKETING_MATERIALS_FAILURE, err });
    });
};

export const getMarketingMaterial = id => dispatch => {
  dispatch({ type: types.GET_MARKETING_MATERIAL_REQUEST });

  marketingService
    .getMarketingMaterial(id)
    .then(payload => {
      dispatch({ type: types.GET_MARKETING_MATERIAL_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_MARKETING_MATERIAL_FAILURE, err });
    });
};

export const onUploadProgress = progress => dispatch => {
  dispatch({ type: types.SET_MARKETING_MATERIAL_UPLOAD_PROGRESS, payload: progress });
};

export const setCurrentMarketingMaterial = material => dispatch => {
  dispatch({ type: types.SET_CURRENT_MARKETING_MATERIAL, payload: material });
};

export const clearCurrentMarketingMaterial = () => dispatch => {
  dispatch({ type: types.CLEAR_CURRENT_MARKETING_MATERIAL });
};

export const clearMarketing = () => dispatch => {
  dispatch({ type: types.CLEAR_MARKETING });
};
