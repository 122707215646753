import styled, { css } from 'styled-components';
import { font, colors } from 'theme/styles';

const Button = styled.button`
  width: auto;
  min-width: 200px;
  min-height: 40px;
  border-radius: 5px;
  padding: 4px 20px;
  margin: 0;
  user-select: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: no-wrap;
  font-size: ${font.size.s};
  font-weight: ${font.weight.default};
  color: ${colors.white};
  background: linear-gradient(to bottom, ${colors.orange} 0%, ${colors.red} 100%);
  background-size: auto 200%;
  transition: 0.5s ease;

  &:not(:disabled):hover {
    background-position: bottom center;
    color: ${colors.white};
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ small }) =>
    small &&
    css`
      min-height: unset;
      padding: 0 20px;
      height: 27px;
      min-width: 128px;
      border-radius: 3px;
      font-size: ${font.size.s};
      font-weight: ${font.weight.default};
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      background: ${colors.white};
      color: ${colors.orange};
      border: 1px solid ${colors.orange};

      &:not(:disabled):hover {
        background-position: bottom center;
        color: ${colors.orange};
        text-decoration: none;
      }

      &:disabled {
        background: ${colors.white};
        opacity: 0.25;
      }
    `}

  ${({ tertiary }) =>
    tertiary &&
    css`
      background-position: bottom center;
    `}

  ${({ centered }) =>
    centered &&
    css`
      margin: 0 auto;
    `}

  ${({ right }) =>
    right &&
    css`
      margin: 0 0 0 auto;
    `}

  ${({ green }) =>
    green &&
    css`
      background: ${colors.white};
      color: ${colors.green};
      border: 1px solid ${colors.green};

      &:disabled {
        opacity: 0.25;
      }

      &:not(:disabled):hover {
        background: ${colors.green};
        color: ${colors.white};
      }
    `}


  ${({ greenFilled }) =>
    greenFilled &&
    css`
      background: ${colors.green};
      color: ${colors.white};
      border: 1px solid ${colors.green};

      &:disabled {
        opacity: 0.25;
      }

      &:not(:disabled):hover {
        background: ${colors.white};
        color: ${colors.green};
      }
    `}

  ${({ gray }) =>
    gray &&
    css`
      background: ${colors.white};
      color: ${colors.darkGray};
      border: 1px solid ${colors.darkGray};

      &:not(:disabled):hover {
        background: ${colors.darkGray};
        color: ${colors.white};
      }
    `}
    
`;

export default Button;
