import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toggle } from 'redux/notifications';
import N from 'components/UI/Notifications';

const NotificationBell = ({ additionalAction }) => {
  const notifications = useSelector(s => s.notifications.dataWidget);
  const notSeen = notifications.filter(n => Boolean(!n.isSeen));
  const dispatch = useDispatch();

  return (
    <N.BellWrapper
      shake={Boolean(notSeen.length)}
      onClick={() => {
        dispatch(toggle());
        additionalAction();
      }}
    >
      <FontAwesomeIcon icon="bell" size="xl" transform={{ rotate: -22 }} />
      {notSeen.length > 0 && <N.Badge>{notSeen.length}</N.Badge>}
    </N.BellWrapper>
  );
};

NotificationBell.defaultProps = {
  additionalAction: () => {},
};

NotificationBell.propTypes = {
  additionalAction: PropTypes.func,
};

export default NotificationBell;
