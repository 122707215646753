import styled, { css } from 'styled-components';
import Heading from 'components/UI/Heading';
import { breakpoints, colors } from 'theme/styles';

export const FormWrapperCommon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 0;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;

  & > :first-child {
    margin-right: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > :first-child {
      margin-right: 0;
    }
  }
`;

export const AlignCheckbox = styled.div`
  margin-bottom: -20px;

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}

  & + * {
    margin-top: 40px;
  }

  @media (min-width: ${breakpoints.mobileSmall}) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + * {
      margin-top: 20px;
    }

    ${({ noMargin }) =>
      noMargin &&
      css`
        margin-bottom: -20px;
      `}
  }
`;

export const TimeFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  & > span {
    font-size: 0.8em;
  }

  & > * + * {
    margin-left: 10px;
  }
`;

export const FlexFields = styled.div`
  --gap: ${({ cols }) => (cols > 4 ? '10px' : '15px')};
  display: flex;
  flex-direction: column;
  position: relative;

  & + button {
    margin-top: 20px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    ${({ separator }) =>
      separator &&
      css`
        & + & {
          border-top: 1px dashed ${colors.orange};
          margin-top: 1.5rem;
          padding-top: 1.5rem;
        }
      `}
  }

  @media (min-width: ${breakpoints.mobileSmall}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: calc(var(--gap) * -1);
    margin-right: calc(var(--gap) * -1);

    & > * {
      margin-left: var(--gap);
      margin-right: var(--gap);
      flex: 0 1 calc(50% - var(--gap) * 2);
    }

    ${({ autoFit }) =>
      autoFit &&
      css`
        & > * {
          margin-right: 20px;
          flex: 0 1 auto;
        }
      `}
  }

  @media (min-width: ${breakpoints.mobile}) {
    & > * {
      flex: 0 1
        ${({ cols }) => {
          const colW = (100 / cols).toFixed(4);
          return cols ? `calc( ${colW}% -  var(--gap) * 2)` : 'calc(33.333% - var(--gap) * 2)';
        }};
    }

    ${({ autoFit }) =>
      autoFit &&
      css`
        & > * {
          margin-right: 20px;
          flex: 0 1 auto;
        }
      `}
  }
`;

export const FormColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 500px;
  }
`;

export const FieldHeading = styled(Heading)`
  margin-top: 15px;
  margin-bottom: 5px;

  .sub-heading {
    font-size: 0.6em;
    color: ${colors.orange}
  }

  ${({ big }) =>
    big &&
    css`
      margin-top: 5px;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
    `}

  ${({ medium }) =>
    medium &&
    css`
      margin-top: 15px;
      margin-bottom: 15px;
    `}

  ${({ mb }) =>
    mb &&
    css`
      margin-top: 0;
      margin-bottom: 20px;
    `}

  ${({ mbSmall }) =>
    mbSmall &&
    css`
      margin-top: 0;
      margin-bottom: 10px;
    `}

    ${({ noMargin }) =>
      noMargin &&
      css`
        margin-top: 0;
        margin-bottom: 0;
      `}

`;
