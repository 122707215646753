export const colors = {
  orange: '#F18819',
  orangeSubtle: '#fff7ed',
  red: '#CC3333',
  green: '#84b049',
  greenDark: '#54b049',
  greenText: '#2e7d32',
  blue: '#1B1464',
  blueLight: '#4e3c93',
  blueLighter: '#e8ebfa',
  darkBlue: '#1B143C',
  darkGray: '#777777',
  mediumGray: '#707070',
  gray: '#bebebe',
  lightGray: '#e0e0e0',
  lighterGray: '#f8f8f8',
  blueGray: '#F0F3F7',
  blueGrayLight: '#f7f9fb',

  white: '#ffffff',
  black: '#000000',
  textBlack: '#111111',
  textDark: '#999999',
  backgroundTransparent: '#1d1d1d25',

  selectBlue: '#3b91fa',
  selectBlueLight: '#d7e9fe',

  notiError: '#DC2A2A',
  notiErrorLight: '#FF5458',
  notiErrorAccent: '#DC2A2A',

  notiWarn: '#D2460C',
  notiWarnLight: '#FFAB00',
  notiWarnAccent: '#C83B00',

  notiBlue: '#2A7AB0',
  notiBlueLight: '#02AFCC',
  notiBlueAccent: '#2570A3',

  notiSucc: '#01854B',
  notiSuccLight: '#00C9A1',
  notiSuccAccent: '#017944',

  headerGray1: '#505050',
  headerGray2: '#656565',
};

export const font = {
  size: {
    xxs: '1rem',
    xs: '1.1rem',
    small: '1.2rem',
    s: '1.3rem',
    xm: '1.4rem',
    m: '1.5rem',
    default: '1.6rem',
    l: '1.8rem',
    xl: '2rem',
    xxl: '2.6rem',
    xxxl: '3rem',
    xxxxl: '4rem',
  },
  weight: {
    default: '400',
    semibold: '600',
    bold: '700',
  },
};

export const breakpoints = {
  header: '768px',
  adminPage: '600px',
  mobile: '768px',
  mobileSmall: '600px',
  mobileTiny: '500px',
  mobileMinimum: '400px',
  filters: '500px',
  tablet: '992px',
  desktop: '1200px',
  notificationsDesktop: '1550px',
  desktopBig: '1650px',
};
