export const SET_PANEL_ACTIVE = 'SET_PANEL_ACTIVE';

export const SET_TIME = 'SET_TIME';

export const SET_MODE = 'SET_MODE';
export const ARCHIVE_CONVERSATION = 'ARCHIVE_CONVERSATION';
export const UNARCHIVE_CONVERSATION = 'UNARCHIVE_CONVERSATION';

export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_CONVERSATIONS_LOADING = 'SET_CONVERSATIONS_LOADING';
export const SET_CONVERSATIONS_ERROR = 'SET_CONVERSATIONS_ERROR';

export const SET_ARCHIVED_CONVERSATIONS = 'SET_ARCHIVED_CONVERSATIONS';
export const SET_ARCHIVED_CONVERSATIONS_LOADING = 'SET_ARCHIVED_CONVERSATIONS_LOADING';
export const SET_ARCHIVED_CONVERSATIONS_ERROR = 'SET_ARCHIVED_CONVERSATIONS_ERROR';

export const SET_LOCAL_LAST_SEEN_MESSAGES = 'SET_LOCAL_LAST_SEEN_MESSAGES';

export const CHECK_UNREAD_CONVERSATIONS = 'CHECK_UNREAD_CONVERSATIONS';

export const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION';
export const SET_ACTIVE_CONVERSATION_MESSAGES = 'SET_ACTIVE_CONVERSATION_MESSAGES';
export const INJECT_UNREADED_MESSAGE_DIVIDER = 'INJECT_UNREADED_MESSAGE_DIVIDER';
export const SET_ACTIVE_CONVERSATION_MESSAGES_LOADING = 'SET_ACTIVE_CONVERSATION_MESSAGES_LOADING';
export const SET_CONVERSATION_USERS = 'SET_CONVERSATION_USERS';

export const ADD_PREVIOUS_CONVERSATIONS = 'ADD_PREVIOUS_CONVERSATIONS';
export const ADD_PREVIOUS_ARCHIVES = 'ADD_PREVIOUS_ARCHIVES'

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_PREVIOUS_MESSAGES = 'ADD_PREVIOUS_MESSAGES';

export const CLEAR_ACTIVE_CONVERSATION = 'CLEAR_ACTIVE_CONVERSATION';
export const CLEAR_PREVIEW_CONVERSATIONS = 'CLEAR_PREVIEW_CONVERSATIONS';

export const IS_LOADING_PATRONS = 'IS_LOADING_PATRONS';
export const IS_LOADING_LEADS = 'IS_LOADING_LEADS';

export const STATUSES = {
  INITIAL_LOADED: 'INITIAL_LOADED',
  NEW: 'NEW',
  PREVIOUS: 'PREVIOUS',
};

export const SET_NEW_MESSAGES = 'SET_NEW_MESSAGES';
export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';
export const UPDATE_LAST_SEEN_IN_CONVERSATION_DETAILS = 'UPDATE_LAST_SEEN_IN_CONVERSATION_DETAILS';

export const GET_SYSTEM_MESSAGES_REQUEST = 'GET_SYSTEM_MESSAGES_REQUEST';
export const GET_SYSTEM_MESSAGES_SUCCESS = 'GET_SYSTEM_MESSAGES_SUCCESS';
export const GET_SYSTEM_MESSAGES_FAILURE = 'GET_SYSTEM_MESSAGES_FAILURE';

export const CLEAR_CURRENT_SYSTEM_CONVERSATION = 'CLEAR_CURRENT_SYSTEM_CONVERSATION';
export const CLEAR_CURRENT_CONVERSATION = 'CLEAR_CURRENT_CONVERSATION';
export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS';
export const CLEAR_CHAT = 'CLEAR_CHAT';
