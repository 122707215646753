export default {
    invoiceNumber: '',
  
    createDate: '',
    releaseDate: '',
  
    company: {
      name: '',
      street: '',
      zipCode: '',
      city: '',
      nip: 1111111111,
    },
  
    purchaser: {
      name: '',
      street: '',
      zipCode: '',
      city: '',
      nip: '',
      csk: '',
    },
  
    products: [
      {
        name: '',
        ean: '',
        sumbol: null,
        quantity: 1,
        quantityUnit: 'szt',
        unitPrice: 0,
        discountInPerc: 0,
        vatRate: 0,
      },
    ],
  
    summaryTable: {
      vatRate: 0,
      netto: 0,
      vat: 0,
      burtto: 0,
    },
  
    totalMoney: '0',
    totalMoneyString: '0',

    paymentDeadline: '',
    paymentMethod: '',
    paymentAccountNumber: '',
    stagedBy: '',
  };