import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'routes';
import { colors, breakpoints } from 'theme/styles';
import { ReactComponent as Przelewy24Logo } from 'assets/icons/przelewy24.svg';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';

const MerchantPanelPage = () => {
  return (
    <PageTemplate>
      <CardsWrapper>
        <Card to={routes.przelewy24}>
          <Przelewy24Logo />
          <Heading centered>Leady do Przelewy24</Heading>
        </Card>
      </CardsWrapper>
    </PageTemplate>
  );
};

export default MerchantPanelPage;

// styled components

const CardsWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Card = styled(Link)`
  display: block;
  height: 171px;
  width: 260px;
  margin: 10px;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  background: ${colors.white};
  border-radius: 14px;
  box-shadow: 0px 3px 15px #00000010;
  text-decoration: none;

  img {
    height: 54px;
    margin-bottom: 10px;
  }

  svg {
    height: 54px;
    width: auto;
    margin-bottom: 10px;
    fill: ${colors.orange};
    transition: fill 0.3s;
  }

  &:hover svg {
    fill: ${colors.red};
  }

  @media (max-width: ${breakpoints.DrPanelPage}) {
    width: 100%;
  }
`;

// const AdminActionsSecton = styled.div`
//   margin-top: 50px;
//   display: flex;
//   justify-content: center;
// `;
