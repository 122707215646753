export const routes = {
  home: '/',
  leads: '/leads',
  login: '/login',
  test: '/test',
  user: '/user',
  raports: '/raports',
  contact: '/contact',
  collaboration: '/collaboration',
  marketing: '/marketing',
  documents: '/documents',
  player: '/player',
  trainingMaterials: '/training-materials',
  trainingMaterialsDetails: '/training-materials/:id',
  integrationOrders: '/integration-orders',
  integrationOrderDetails: '/integration-orders/:id',
  notifications: '/notifications',
  analytics: '/analytics',
  merchantsAnalytics: '/merchant-analytics',
  merchantsLeadsAnalytics: '/merchants-leads-analytics',
  merchantsAnalyticsDetails: '/merchant-analytics/:id',

  leadDetails: '/lead/details/:id',
  leadOnboarding: '/lead/onboarding/:id/',

  client: '/client/:id',
  clientHistory: '/client/:id/history',
  clientNotes: '/client/:id/notes',
  clientDocs: '/client/:id/docs',

  clientPartner: '/client-partner/:id',
  clientPartnerHistory: '/client-partner/:id/history',
  clientPartnerNotes: '/client-partner/:id/notes',

  partner: '/partner',
  partnerUsers: '/partner/users-management',
  partnerChallenges: '/partner/challenges',
  partnerInvoicing: '/partner/invoicing',
  partnerInvoicingId: '/partner/invoicing/:id',
  partnerInvoicingDetails: '/partner/invoicing/:type/:id',
  partnerTerms: '/partner/terms/:id',
  partnerBillings: '/partner/rozliczenia/:id',

  adminGuest: '/admin-guest',

  admin: '/admin',
  adminUsers: '/admin/users-management',
  adminProducts: '/admin/products-management',
  adminApi: '/admin/api-management',
  adminChallenges: '/admin/challenges',
  adminCompanies: '/admin/companies-management',
  adminCompanyUsers: '/admin/companies-management/users',
  // adminCompanyProducts: '/admin/companies-management/products',
  adminCompanyAnalysis: '/admin/companies-management/analysis',
  adminSystemMessages: '/admin/system-messages',
  adminLeadsArchive: '/admin/leads-archive',
  adminZipcodes: '/admin/zipcodes',
  adminVacations: '/admin/vacations',
  adminTransferLeads: '/admin/transfer-leads',
  adminNotifications: '/admin/notifications',
  adminEmailTemplates: '/admin/email-templates',
  adminCountyAnalysis: '/admin/county-analysis',
  adminCron: '/admin/cron',
  adminCronDetails: '/admin/cron/:id',
  adminClarity: '/admin/clarity',
  adminOptions: '/admin/options',
  adminOptionsTabLeads: '/admin/options/leads',
  adminOptionsTabPartners: '/admin/options/partners',
  adminOptionsTabCrons: '/admin/options/crons',
  adminOptionsTabOthers: '/admin/options/others',
  adminDemos: '/admin/demos',

  adminDemoCrmForm: '/admin/demos/onboarding/:id',

  drPanel: '/dr',

  merchantPanel: '/merchant',
  przelewy24: '/leads-p24',

  setPassword: '/set-password/:id/:token',
  resetPassword: '/reset-password',
  resetPasswordConfirm: '/auth/reset-password/:id/:token',
};
