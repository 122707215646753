import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { FormWrapperCommon } from 'components/forms/FormComponents';
import { leadService } from 'services/lead';
import { transferLeadSchema } from 'utils/validation';
import InputAuto from 'components/UI/InputAuto';
import ErrorLabel from 'components/UI/ErrorLabel';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import useFetchSuggestions from 'hooks/useFetchSuggestions';
import useRequestState from 'hooks/useRequestState';
// import { useHistory } from 'react-router-dom';
import { colors } from 'theme/styles';

const TransferLeadForm = ({ leadId, transferUserId }) => {
  const [{ success, error }, onRequest] = useRequestState();
  const [phsSugg, fetchUsers] = useFetchSuggestions();
  // const history = useHistory();

  const handleSubmit = values => {
    onRequest('start');

    leadService
      .transferLead(values)
      .then(res => {
        console.log(res);
        const message = `Lead został przeniesiony \n\n Poprzednio: ${res.data.fromUser.fullName}\n Teraz: ${res.data.toUser.fullName}`;
        onRequest('success', message);
        // history.push('/leads');
      })
      .catch(err => {
        console.dir(err);
        onRequest(
          'error',
          err?.response?.data?.error?.message || 'Coś poszło nie tak. Spróbuj ponownie.',
        );
      });
  };

  return (
    <FormWrapperCommon>
      {success && (
        <Heading color={colors.orange} size="l" centered>
          {success}
        </Heading>
      )}
      {error && (
        <Heading color={colors.red} size="l" centered>
          {error}
        </Heading>
      )}
      {!success && !error && (
        <>
          <Heading size="xl" centered>
            Transferuj leada na innego użytkownika
          </Heading>
          <Formik
            initialValues={{
              leadId,
              transferUserId,
            }}
            validationSchema={transferLeadSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
              <StyledForm>
                <InputAuto
                  suggestions={phsSugg}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onlyFromList
                  pickProp="id"
                  name="transferUserId"
                  type="text"
                  display={['name', 'surname', 'email']}
                  onBlur={handleBlur}
                  onChange={e => fetchUsers(e.target.value)}
                  label="Transferuj leada na:"
                  value={values.transferUserId || ''}
                  error={touched.transferUserId && errors.transferUserId}
                />
                <ErrorMessage component={ErrorLabel} name="transferUserId" />
                <ErrorMessage component={ErrorLabel} name="leadId" />
                <Button
                  style={{ margin: '40px auto 0', width: '100%', maxWidth: '200px' }}
                  type="submit"
                >
                  Prześlij
                </Button>
              </StyledForm>
            )}
          </Formik>
        </>
      )}
    </FormWrapperCommon>
  );
};

TransferLeadForm.defaultProps = {
  transferUserId: '',
};

TransferLeadForm.propTypes = {
  leadId: PropTypes.string.isRequired,
  transferUserId: PropTypes.string,
};

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
`;
export default TransferLeadForm;
