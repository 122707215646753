import * as types from './types';

const initialState = {
  users: {
    data: [],
    isLoading: false,
    error: null,
  },
  currentUser: {
    data: null,
    isLoading: false,
    error: '',
  },
  terms: {
    data: {
      externalDb: [],
      partner: null,
    },
    isLoading: false,
    error: null,
  },
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PARTNER_TERMS_REQUEST:
      return {
        ...state,
        terms: {
          ...state.terms,
          isLoading: true,
        },
      };
    case types.GET_PARTNER_TERMS_SUCCESS:
      return {
        ...state,
        terms: {
          ...state.terms,
          data: action.payload.data,
          isLoading: false,
          error: null,
        },
      };
    case types.GET_PARTNER_TERMS_FAILURE:
      return {
        ...state,
        terms: {
          ...state.terms,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
      };
    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.data,
          isLoading: false,
          error: null,
        },
      };
    case types.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_USER_REQUEST:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: true,
        },
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          isLoading: false,
          data: action.payload.data,
          error: '',
        },
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        currentUser: {
          isLoading: false,
          data: null,
          error: action.err.response.data.error.message,
        },
      };
    case types.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          isLoading: false,
          data: action.payload,
          error: '',
        },
      };
    case types.CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...initialState.currentUser,
        },
      };

    case types.CLEAR_PARTNER:
      return initialState;
    default:
      return state;
  }
};

export default partnerReducer;
