export default class Person {
  constructor({ type = -1, fn = 1, name = '', lastname = '', email = '', phone = '', sameAs = true }) {
    this.name = name;
    this.lastname = lastname;
    this.email = email;
    this.phone = phone;
    this.type = type;
    this.function = fn;
    this.sameAs = sameAs;
  }
}
