import * as types from './types';

const initialState = {
  recommendedUsers: {
    data: {
      count: 0,
      rows: [],
      pagination: {
        totalPages: 0,
        nextPage: 0,
        currentPage: 0,
        previousPage: 0,
      },
    },
    isLoading: false,
    error: null,
  },
  currentRecommendedUser: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const collaborationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_RECOMMENDED_USERS_REQUEST:
      return {
        ...state,
        recommendedUsers: {
          ...state.recommendedUsers,
          isLoading: true,
          error: null,
        },
      };
    case types.GET_ALL_RECOMMENDED_USERS_SUCCESS:
      return {
        ...state,
        recommendedUsers: {
          ...state.recommendedUsers,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case types.GET_ALL_RECOMMENDED_USERS_FAILURE:
      return {
        ...state,
        recommendedUsers: {
          ...state.recommendedUsers,
          isLoading: false,
          error: action.err.response.data.error,
        },
      };
    case types.GET_RECOMMENDED_USER_REQUEST:
      return {
        ...state,
        currentRecommendedUser: {
          ...state.currentRecommendedUser,
          isLoading: true,
          error: null,
        },
      };
    case types.GET_RECOMMENDED_USER_SUCCESS:
      return {
        ...state,
        currentRecommendedUser: {
          isLoading: false,
          data: action.payload.data,
        },
      };
    case types.GET_RECOMMENDED_USER_FAILURE:
      return {
        ...state,
        currentRecommendedUser: {
          isLoading: false,
          data: null,
          error: action.err.response.data.error.message,
        },
      };
    case types.SET_CURRENT_RECOMMENDED_USER:
      return {
        ...state,
        currentRecommendedUser: {
          isLoading: false,
          data: action.payload,
          error: '',
        },
      };
    case types.CLEAR_CURRENT_RECOMMENDED_USER:
      return {
        ...state,
        currentRecommendedUser: {
          ...initialState.currentRecommendedUser,
        },
      };

    case types.CLEAR_RECOMMENDED_STATE:
      return initialState;
    default:
      return state;
  }
};

export default collaborationReducer;
