import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { logout } from 'redux/user';
import Header from 'components/layout/Header';

const PageTemplate = ({ children, logout }) => (
  <>
    <Header handleLogout={logout} />
    <Wrapper>
      <MainContainer>{children}</MainContainer>
    </Wrapper>
  </>
);

const mapStateToProps = () => ({});

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { logout })(PageTemplate);

// styled components

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0 15px;
`;

const MainContainer = styled.main`
  width: 100%;
  max-width: 1400px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  padding-top: 80px;
`;
