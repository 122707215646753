import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage, FieldArray } from 'formik';
import { adminCompanySchema } from 'utils/validation';
import { adminService } from 'services/admin';
import { removeNullsAndEmpty } from 'utils/helpers';
import { partnerCooperationOptions, partnerProgramOptions } from 'config/constants';
import { FormWrapperCommon, Columns, FieldHeading, FormColumn } from 'components/forms/FormComponents';
import { TwoColumns } from 'components/UI/Structure';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import InputAuto from 'components/UI/InputAuto';
import Checkbox from 'components/UI/Checkbox';
import Chip from 'components/UI/Chip';

const AddCompany = ({ refetchCompanies, pepStructureSuggestions }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleReturn = (doReset, resetForm) => {
    setSuccess(false);
    setError('');
    if (doReset) resetForm({});
  };

  const handleSubmit = values => {
    const newValues = removeNullsAndEmpty(values);

    newValues.cooperation = JSON.stringify(values.cooperation);
    newValues.partnerProgram = JSON.stringify(values.partnerProgram);
    newValues.dedicatedPhs = JSON.stringify(values.dedicatedPhs.map(dph => dph.id));

    setError('');
    setLoading(true);

    adminService
      .addCompany(newValues)
      .then(data => {
        console.log(data);
        setSuccess(true);
        setLoading(false);
        refetchCompanies();
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error.message);
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {loading ? <Loading absolute /> : ''}
      <Heading size="xl">Dodaj partnera</Heading>
      <Formik
        initialValues={{
          name: '',
          nip: '',
          // regon: '',
          // iban: '',
          krs: '',
          postCode: '',
          city: '',
          street: '',
          numBuilding: '',
          numLocal: '',
          leadMaster: '',
          harvesting: '',
          dedicatedPhs: [],
          dedicatedPhSearch: '',
          cooperation: [],
          partnerProgram: [],
          invoiceAccess: '0',
          teamManagement: '0',
          partnerLeadExportsAccess: '0'
        }}
        validationSchema={adminCompanySchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>Dodałeś firmę</Heading>
                <div className="buttons">
                  <Button onClick={() => handleReturn(true, resetForm)}>Wróć</Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Błąd dodawania firmy</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReturn(false, resetForm)}>Spróbuj ponownie</Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <Columns>
                <FormColumn>
                  <FieldHeading big size="m">
                    Wprowadź dane:
                  </FieldHeading>
                  <Input
                    name="name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nazwa"
                    value={values.name || ''}
                    error={touched.name && errors.name}
                  />
                  <ErrorMessage component={ErrorLabel} name="name" />
                  <Input
                    name="nip"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer NIP"
                    value={values.nip || ''}
                    error={touched.nip && errors.nip}
                  />
                  <ErrorMessage component={ErrorLabel} name="nip" />
                  {/* <Input
                    name="iban"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer IBAN"
                    value={values.iban || ''}
                    error={touched.iban && errors.iban}
                  />
                  <ErrorMessage component={ErrorLabel} name="iban" />
                  <Input
                    name="regon"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer REGON"
                    value={values.regon || ''}
                    error={touched.regon && errors.regon}
                  />
                  <ErrorMessage component={ErrorLabel} name="regon" /> */}
                  <Input
                    name="krs"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer KRS"
                    value={values.krs || ''}
                    error={touched.krs && errors.krs}
                  />
                  <ErrorMessage component={ErrorLabel} name="krs" />
                  <InputAuto
                    suggestions={pepStructureSuggestions}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="leadMaster"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    label="Lead master"
                    value={values.leadMaster || ''}
                    error={touched.leadMaster && errors.leadMaster}
                  />
                  <ErrorMessage component={ErrorLabel} name="leadMaster" />
                  <InputAuto
                    suggestions={pepStructureSuggestions}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="dedicatedPhSearch"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    onSelect={(val, item) => {
                      if (!values.dedicatedPhs.some(dph => dph.id === item.id)) {
                        const { id, name, surname } = item;
                        setFieldValue('dedicatedPhs', [...values.dedicatedPhs, { id, name, surname }]);
                      }
                      setFieldValue('dedicatedPhSearch', '');
                    }}
                    label="Przedstawiciele dedykowani"
                    value={values.dedicatedPhSearch || ''}
                    error={touched.dedicatedPhSearch && errors.dedicatedPhSearch}
                  />
                  <ErrorMessage component={ErrorLabel} name="dedicatedPhs" />
                  <div>
                    {values.dedicatedPhs &&
                      values.dedicatedPhs.map(dph => (
                        <Chip
                          key={`${dph.id}`}
                          onClick={() => {
                            const newValue = values.dedicatedPhs.filter(el => el.id !== dph.id);
                            setFieldValue('dedicatedPhs', newValue);
                          }}
                        >
                          {`${dph.name} ${dph.surname}`}
                        </Chip>
                      ))}
                  </div>
                  {/* <InputAuto
                    suggestions={pepStructureSuggestions}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="operator"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    label="Przedstawiciel dedykowany"
                    value={values.operator || ''}
                    error={touched.operator && errors.operator}
                  />
                  <ErrorMessage component={ErrorLabel} name="operator" /> */}
                  <InputAuto
                    suggestions={pepStructureSuggestions}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onlyFromList
                    pickProp="id"
                    name="harvesting"
                    type="text"
                    display={['name', 'surname', 'email']}
                    onBlur={handleBlur}
                    label="Osoba pozyskująca"
                    value={values.harvesting || ''}
                    error={touched.harvesting && errors.harvesting}
                  />
                  <ErrorMessage component={ErrorLabel} name="harvesting" />
                  <TwoColumns>
                    <div>
                      <FieldHeading medium size="m">
                        Typ współpracy
                      </FieldHeading>
                      <FieldArray
                        name="cooperation"
                        render={arrayHelpers => (
                          <div>
                            {partnerCooperationOptions.map(item => (
                              <Checkbox
                                key={item.value}
                                name="cooperation"
                                onChange={e => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(item.value);
                                  } else {
                                    const idx = values.cooperation.indexOf(item.value);
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                                onBlur={handleBlur}
                                label={item.display}
                                value={item.value}
                                checked={values.cooperation ? values.cooperation.includes(item.value) : false}
                              />
                            ))}
                          </div>
                        )}
                      />
                      <ErrorMessage component={ErrorLabel} name="cooperation" />
                    </div>
                    <div>
                      <FieldHeading medium size="m">
                        Kanał partnerski
                      </FieldHeading>
                      <FieldArray
                        name="partnerProgram"
                        render={arrayHelpers => (
                          <div>
                            {partnerProgramOptions.map(item => (
                              <Checkbox
                                key={item.value}
                                name="partnerProgram"
                                onChange={e => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(item.value);
                                  } else {
                                    const idx = values.partnerProgram.indexOf(item.value);
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                                onBlur={handleBlur}
                                label={item.display}
                                value={item.value}
                                checked={values.partnerProgram ? values.partnerProgram.includes(item.value) : false}
                              />
                            ))}
                          </div>
                        )}
                      />
                      <ErrorMessage component={ErrorLabel} name="partnerProgram" />
                    </div>
                  </TwoColumns>
                </FormColumn>
                <FormColumn>
                  <FieldHeading big size="m">
                    Dane adresowe:
                  </FieldHeading>
                  <Input
                    name="postCode"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Kod pocztowy"
                    value={values.postCode || ''}
                    error={touched.postCode && errors.postCode}
                  />
                  <ErrorMessage component={ErrorLabel} name="postCode" />
                  <Input
                    name="city"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Miasto"
                    value={values.city || ''}
                    error={touched.city && errors.city}
                  />
                  <ErrorMessage component={ErrorLabel} name="city" />
                  <Input
                    name="street"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Ulica"
                    value={values.street || ''}
                    error={touched.street && errors.street}
                  />
                  <ErrorMessage component={ErrorLabel} name="street" />
                  <Input
                    name="numBuilding"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer budynku"
                    value={values.numBuilding || ''}
                    error={touched.numBuilding && errors.numBuilding}
                  />
                  <ErrorMessage component={ErrorLabel} name="numBuilding" />
                  <Input
                    name="numLocal"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Numer lokalu"
                    value={values.numLocal || ''}
                    error={touched.numLocal && errors.numLocal}
                  />
                  <ErrorMessage component={ErrorLabel} name="numLocal" />
                  <FieldHeading size="m">Ustawienia dodatkowe:</FieldHeading>
                  <Checkbox
                    style={{ marginTop: '10px' }}
                    name="invoiceAccess"
                    onChange={e => setFieldValue('invoiceAccess', e.target.checked ? '1' : '0')}
                    onBlur={handleBlur}
                    label="Dostęp do fakturowania"
                    value={values.invoiceAccess}
                    checked={values.invoiceAccess === '1'}
                    error={touched.invoiceAccess && errors.invoiceAccess}
                  />
                  <Checkbox
                    name="teamManagement"
                    onChange={e => setFieldValue('teamManagement', e.target.checked ? '1' : '0')}
                    onBlur={handleBlur}
                    label="Dostęp do zarządzania zespołem"
                    value={values.teamManagement}
                    checked={values.teamManagement === '1'}
                    error={touched.teamManagement && errors.teamManagement}
                  />
                  <Checkbox
                    name="partnerLeadExportsAccess"
                    onChange={e => setFieldValue('partnerLeadExportsAccess', e.target.checked ? '1' : '0')}
                    onBlur={handleBlur}
                    label="Dostęp do eksportu leadów"
                    value={values.partnerLeadExportsAccess}
                    checked={values.partnerLeadExportsAccess === '1'}
                    error={touched.partnerLeadExportsAccess && errors.partnerLeadExportsAccess}
                  />
                </FormColumn>
              </Columns>
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Prześlij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

AddCompany.propTypes = {
  pepStructureSuggestions: PropTypes.instanceOf(Array).isRequired,
  refetchCompanies: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pepStructureSuggestions: state.admin.pepStructure.data,
});

export default connect(mapStateToProps)(AddCompany);

// styled components
const Wrapper = styled(FormWrapperCommon)``;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-bottom: 60px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
