export class AmlPerson {
  constructor({
    peselExist = true,
    pesel = '',
    birthdayDate = '',
    countryOfBirth = '',
    identityCardExpirationDateExist = true,
    identityCardNumber = '',
    identityCardReleaseDate = '',
    identityCardExpirationDate = '',
    nationalities = [{ id: 'PL', display: 'Polska' }],
  } = {}) {
    this.peselExist = peselExist;
    this.pesel = pesel;
    this.birthdayDate = birthdayDate;

    this.identityCardNumber = identityCardNumber;
    this.identityCardReleaseDate = identityCardReleaseDate;
    this.identityCardExpirationDateExist = identityCardExpirationDateExist;
    this.identityCardExpirationDate = identityCardExpirationDate;

    this.countryOfBirth = countryOfBirth;
    this.nationalities = nationalities;
  }
}

export class PEP {
  constructor({ position = '', positionReleaseDate = '' } = {}) {
    this.position = position;
    this.positionReleaseDate = positionReleaseDate;
  }
}

export class PEPAssoc {
  constructor({ PEPFullname = '', position = '', positionReleaseDate = '' } = {}) {
    this.PEPFullname = PEPFullname;
    this.position = position;
    this.positionReleaseDate = positionReleaseDate;
  }
}

export default class DrukAML {
  constructor({
    isAuthorizedMerchant = 1, // Zakładamy że domyślnie TAK, nie dajemy możliwości wyboru na ten moment
    merchant = new AmlPerson(),

    isBeneficialOwner = null,

    isPEP = null,
    pep = new PEP(),

    isPEPFamily = null,
    pepFamily = new PEPAssoc(),

    isPEPAssociate = null,
    pepAssociate = new PEPAssoc(),

    dataValidationConfirmation = false,
  } = {}) {
    this.isAuthorizedMerchant = isAuthorizedMerchant;
    this.merchant = merchant;
    this.isBeneficialOwner = isBeneficialOwner;
    this.isPEP = isPEP;
    this.pep = pep;
    this.isPEPFamily = isPEPFamily;
    this.pepFamily = pepFamily;
    this.isPEPAssociate = isPEPAssociate;
    this.pepAssociate = pepAssociate;
    this.dataValidationConfirmation = dataValidationConfirmation;
  }
}
