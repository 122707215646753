import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'theme/styles';

const Close = styled.span`
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: ${colors.white};
  background: ${colors.darkBlue};
  border: 2px solid currentColor;
  border-radius: 4px;
  opacity: 0.7;
  transition: opacity 0.4s;

  @media screen and (min-width: 800px) {
    width: 22px;
    height: 22px;
  }

  @media screen and (min-width: 1200px) {
    width: 24px;
    height: 24px;
  }

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 2px;
    background-color: currentColor;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const Content = styled.div`
  flex: 1;
`;

const BannerVertical = styled.a`
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  margin-left: 30px;
  flex: 0 0 300px;
`;

const BannerHorizontal = styled.a`
  position: relative;

  img {
    display: block;
    width: 100%;
  }
`;

// Main Component
const TemplateBannerWrapper = styled.div`
  width: 100%;
  flex: 1;

  ${BannerVertical} {
    display: none;
  }

  ${BannerHorizontal} {
    display: ${({ showBanner }) => (showBanner ? 'block' : 'none')};
  }

  @media screen and (min-width: 1200px) {
    display: flex;

    ${BannerVertical} {
      display: ${({ showBanner }) => (showBanner ? 'block' : 'none')};
    }

    ${BannerHorizontal} {
      display: none;
    }
  }
`;

const TemplateBanner = ({ children, showBanner }) => {
  return <TemplateBannerWrapper showBanner={showBanner}>{children}</TemplateBannerWrapper>;
};

TemplateBanner.propTypes = {
  showBanner: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};

TemplateBanner.BannerVertical = BannerVertical;
TemplateBanner.BannerHorizontal = BannerHorizontal;
TemplateBanner.Content = Content;
TemplateBanner.Close = Close;

export default TemplateBanner;
