import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import SuccessPopup from 'components/layout/SuccessPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';

export const useConversationUrl = () => {
  const location = useLocation();
  const history = useHistory();

  const setValue = (newValue) => {
    history.push(`/contact?conversation=${newValue}`);
  };

  const value = queryString.parse(location.search).conversation;

  return [value, setValue];
};

export const useUrl = (key, initialValue) => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;

  const setValue = (newValue) => {
    let sortingData = {};

    switch (key) {
      case 'date':
        sortingData = {
          ...queryString.parse(location.search),
          date_from: newValue[0],
          date_to: newValue[1],
        };
        break;
      default:
        sortingData = {
          ...queryString.parse(location.search),
          [key]: newValue,
        };
    }

    if (key !== 'page') delete sortingData.page;

    Object.keys(sortingData).forEach((k) => sortingData[k] === '' && delete sortingData[k]);
    const query = queryString.stringify(sortingData);
    history.push(`${path}?${query}`);
  };

  const value = queryString.parse(location.search)[key] || initialValue;

  return [value, setValue];
};

export const useUrlOrLocaleStorage = (key, initialValue, prefix = 'filterLeads') => {
  const storageKey = `${prefix}_${key}`;
  const savedValue = localStorage.getItem(storageKey);
  const storedValue = JSON.parse(savedValue) || null;

  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;

  const value = queryString.parse(location.search || storedValue)[key] || initialValue;

  const setValue = (newValue) => {
    let sortingData = {};

    switch (key) {
      case 'date':
        sortingData = {
          ...queryString.parse(location.search),
          date_from: newValue[0],
          date_to: newValue[1],
        };
        break;
      default:
        sortingData = {
          ...queryString.parse(location.search),
          [key]: newValue,
        };
    }

    if (key !== 'page') delete sortingData.page;

    Object.keys(sortingData).forEach((k) => sortingData[k] === '' && delete sortingData[k]);

    const orgQuery = queryString.parse(location.search);
    const fullQueryObj = { ...orgQuery, ...sortingData };

    const fullQuery = queryString.stringify(fullQueryObj);
    const localQuery = queryString.stringify(sortingData);

    history.push(`${path}?${fullQuery}`);
    localStorage.setItem(storageKey, JSON.stringify(localQuery));
  };

  useEffect(() => {
    setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value, setValue];
};

export const useDoubleConfirmPopup = ({
  firstQuestion,
  secondQuestion,
  submitHandler,
  redirectAfter = false,
  beforeSubmit,
  afterSubmit,
}) => {
  const history = useHistory();
  const [firstConfirm, setFirstConfirm] = useState(false);
  const [finalConfirm, setFinalConfirm] = useState(false);
  const [error, setError] = useState(false);

  const trigger = () => setFirstConfirm(true);

  const cancel = () => {
    setFirstConfirm(false);
    setFinalConfirm(false);
    setError(false);
  };

  const submit = async () => {
    if (beforeSubmit) beforeSubmit();

    await submitHandler()
      .then(() => {
        setFirstConfirm(false);
        setFinalConfirm(false);
        if (redirectAfter) history.replace(redirectAfter);
      })
      .catch((e) => {
        console.log(e);
        if (afterSubmit) afterSubmit();
        setFirstConfirm(false);
        setFinalConfirm(false);
        setError('Coś poszło nie tak');
      });
  };

  const Popup = () => {
    return firstConfirm || finalConfirm || error ? (
      <SuccessPopup fixed style={{ textAlign: 'center' }}>
        {firstConfirm && (
          <>
            <Heading centered>{firstQuestion}</Heading>
            <div className="buttons">
              <Button
                secondary
                onClick={() => {
                  setFirstConfirm(false);
                  setFinalConfirm(true);
                }}
              >
                Potwierdzam
              </Button>
              <Button onClick={cancel}>Anuluj</Button>
            </div>
          </>
        )}
        {finalConfirm && (
          <>
            <Heading centered>{secondQuestion}</Heading>
            <div className="buttons">
              <Button secondary onClick={submit}>
                Na pewno
              </Button>
              <Button onClick={cancel}>Anuluj</Button>
            </div>
          </>
        )}
        {error && (
          <>
            <Heading centered>{error}</Heading>
            <div className="buttons">
              <Button onClick={cancel}>Zamknij</Button>
            </div>
          </>
        )}
      </SuccessPopup>
    ) : null;
  };

  return [Popup, trigger];
};
