export const GET_CLIENT_INFO_REQUEST = 'GET_CLIENT_INFO_REQUEST';
export const GET_CLIENT_INFO_SUCCESS = 'GET_CLIENT_INFO_SUCCESS';
export const GET_CLIENT_INFO_FAILURE = 'GET_CLIENT_INFO_FAILURE';

export const GET_CLIENT_HISTORY_REQUEST = 'GET_CLIENT_HISTORY_REQUEST';
export const GET_CLIENT_HISTORY_SUCCESS = 'GET_CLIENT_HISTORY_SUCCESS';
export const GET_CLIENT_HISTORY_FAILURE = 'GET_CLIENT_HISTORY_FAILURE';

export const GET_CLIENT_NOTES_REQUEST = 'GET_CLIENT_NOTES_REQUEST';
export const GET_CLIENT_NOTES_SUCCESS = 'GET_CLIENT_NOTES_SUCCESS';
export const GET_CLIENT_NOTES_FAILURE = 'GET_CLIENT_NOTES_FAILURE';

export const GET_CLIENT_DOCS_REQUEST = 'GET_CLIENT_DOCS_REQUEST';
export const GET_CLIENT_DOCS_SUCCESS = 'GET_CLIENT_DOCS_SUCCESS';
export const GET_CLIENT_DOCS_FAILURE = 'GET_CLIENT_DOCS_FAILURE';