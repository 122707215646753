import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMerchantsLeadsAnalyticsPh, getStructures } from 'redux/admin';
import { TopSection, BottomItem, BottomSection, TableSorter, SortItem } from 'components/layout/Tables/TableComponents';
import { createLink, sortByProp } from 'utils/helpers';
import { adminService } from 'services/admin';
import PageTemplate from 'templates/PageTemplate';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import Select from 'components/UI/Select';
import Heading from 'components/UI/Heading';
import SearchInput from 'components/UI/SearchInput';
import LeadsMerchantTable from 'components/layout/Tables/LeadsMerchantTable';
import Portal from 'components/logic/Portal';
import SuccessPopup from 'components/layout/SuccessPopup';
import Button from 'components/UI/Button';

const translateDate = (header) => {
  const [month, year] = header.split('_');
  const date = new Date(`${year}-${month}-01`);
  const monthLocale = date.toLocaleString('default', { month: 'short' });
  return `${monthLocale} '${year.slice(2)}`;
};

const MerchantsLeadsAnalyticsPage = () => {
  const dispatch = useDispatch();

  const structures = useSelector((state) => state.admin.structures.data);
  const { rows, headers } = useSelector((state) => state.admin.merchantsLeadsAnalyticsPh.data);
  const isLoading = useSelector((state) => state.admin.merchantsLeadsAnalyticsPh.isLoading);
  const error = useSelector((state) => state.admin.merchantsLeadsAnalyticsPh.error);

  const [structure, setStructure] = useState('');
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('DESC');
  const [sortBy, setSortBy] = useState('');

  const [exporting, setExporting] = useState(false);
  const [exportingError, setExportingError] = useState(null);

  useEffect(() => {
    dispatch(getStructures());
    dispatch(getMerchantsLeadsAnalyticsPh());
  }, [dispatch]);

  const tableHeaders = [
    { displayName: 'Przedstawiciel', property: 'fullname' },
    ...headers.map((header) => ({ displayName: translateDate(header), property: `headers.${header}` })),
    { displayName: 'SUMA', property: 'totalLeads', style: { fontWeight: '600' } },
    { displayName: 'ŚREDNIA', property: 'avgLeads', style: { fontWeight: '600' } },
  ];

  const filteredRows = useMemo(() => {
    const filteredRows = rows.filter((row) => {
      let isOk = true;
      if (structure && row.structureId !== structure) isOk = false;
      if (search && !row.fullname.toLowerCase().includes(search.trim().toLowerCase())) isOk = false;
      return isOk;
    });
    // if (sortBy && sortBy === 'avg') sortByProp(filteredRows, 'avgLeads');
    if (sortBy && sortBy === 'sum') sortByProp(filteredRows, 'totalLeads');
    if (order === 'DESC') filteredRows.reverse();
    return filteredRows;
  }, [rows, structure, search, order, sortBy]);

  const handleSet = (e, set) => set(e.target.value);

  const showErrorPopup = (error) => <ErrorPopup fixed>{error.message}</ErrorPopup>;

  const handleExport = useCallback(() => {
    setExporting(true);
    setExportingError(null);
    adminService
      .exportMerchantAnalyticsPhs(filteredRows)
      .then((res) => {
        createLink(res, 'p24leads');
        setExporting(false);
      })
      .catch((err) => {
        setExportingError('Coś poszło nie tak. Skontaktuj się z administratorem.');
        console.log(err);
      });
  }, [filteredRows]);

  const cancelExport = () => {
    adminService.cancelExportMerchantAnalyticsPhs();
    setExporting(false);
  };

  return (
    <PageTemplate>
      <TopSection style={{ alignItems: 'flex-start' }}>
        <Heading size="xxl">Analiza nowych leadów</Heading>
        <TableSorter>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={order} onChange={(e) => handleSet(e, setOrder)}>
              <option value="DESC">Malejąco</option>
              <option value="ASC">Rosnąco</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj po</span>
            <Select small value={sortBy} onChange={(e) => handleSet(e, setSortBy)}>
              <option value="alph">Alfabetycznie</option>
              <option value="sum">Suma leadów za 12 msc &nbsp;</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Struktura</span>
            <Select small value={structure || ''} onChange={(e) => handleSet(e, setStructure)}>
              <option value="">&nbsp;</option>
              {structures &&
                structures.map((structure) => (
                  <option key={structure.id} value={structure.id}>
                    {structure.region}
                  </option>
                ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={(val) => setSearch(val)} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {!isLoading && error && showErrorPopup(error)}
      {isLoading ? <Loading /> : <LeadsMerchantTable data={filteredRows} headers={tableHeaders} offset={0} />}
      <BottomSection>
        <Button small secondary onClick={handleExport}>
          Eksportuj
        </Button>
        <BottomItem>Wszystkich: {filteredRows.length}</BottomItem>
      </BottomSection>
      <Portal>
        {exporting && (
          <SuccessPopup fixed>
            {exportingError ? (
              <>
                <Heading>{exportingError}</Heading>
                <div className="buttons">
                  <Button
                    onClick={() => {
                      setExporting(false);
                      setExportingError(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Heading>Eksportuje leady...</Heading>
                <p>
                  Ta operacja może potrwać nawet kilka minut.
                  <br />
                  Nie zamykaj okna / karty przeglądarki.
                </p>
                <Loading />
                <div className="buttons">
                  <Button onClick={cancelExport}>Anuluj</Button>
                </div>
              </>
            )}
          </SuccessPopup>
        )}
      </Portal>
    </PageTemplate>
  );
};

MerchantsLeadsAnalyticsPage.defaultProps = {};

MerchantsLeadsAnalyticsPage.propTypes = {};

export default MerchantsLeadsAnalyticsPage;
