import Place from './Place';
import ContactPerson from './ContactPerson';
import Terminal from './Terminal';

const defaultWorkSchedule = {
  ponPt: {
    from: '',
    to: '',
  },
  sob: {
    from: '',
    to: '',
  },
  niedz: {
    from: '',
    to: '',
  },
};

export default class Point {
  constructor({
    name = '',
    isAddressSameAsMain = true,
    address = new Place({ sameAs: isAddressSameAsMain }),
    contactPerson = new ContactPerson(),
    industry = -1,
    workSchedule = defaultWorkSchedule,
    terminals = [new Terminal()],
    pointBankAccount = '',
    notes = '',
  }) {
    this.name = name;
    this.address = address;
    this.contactPerson = contactPerson;
    this.industry = industry;
    this.workSchedule = workSchedule;
    this.terminals = terminals;
    this.pointBankAccount = pointBankAccount;
    this.notes = notes
  }
}
