import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { colors, font } from 'theme/styles';
import NoData from 'components/UI/NoData';
import { TableWrapperCommon } from 'components/layout/Tables/TableComponents';

const SystemMessagesTable = ({ headers, data, offset }) => {
  const mapGroupToDisplay = {
    'partners-subpartners': 'Partnerzy + sub-partnerzy',
    partners: 'Partnerzy',
    DSP: 'Dsp',
    PH: 'Przedstawiciele Handlowi',
    all: 'Wszyscy',
    custom: 'Grupa indywidualna',
  };
  return (
    <TableWrapper>
      {data.length > 0 ? (
        <table>
          <colgroup>
            <col className="lp" />
            {headers.map(header => (
              <col key={header.property} className={header.property} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <th>LP</th>
              {headers.map(header => (
                <th key={header.property}>{header.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((msg, index) => (
              <tr key={msg.id}>
                <td>{offset + index + 1}</td>
                {headers.map(header => {
                  const uniqueKey = msg.id + header.property;
                  switch (header.property) {
                    case 'group':
                      return <td key={uniqueKey}>{mapGroupToDisplay[msg[header.property]]}</td>;
                    case 'fromUser':
                      return (
                        <td key={uniqueKey}>
                          {`${msg[header.property].name} ${msg[header.property].surname}`}
                        </td>
                      );
                    case 'createdAt':
                      return (
                        <td key={uniqueKey}>{moment(msg[header.property]).format('YYYY-MM-DD')}</td>
                      );
                    default:
                      return <td key={uniqueKey}>{msg[header.property]}</td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData message="Brak powiadomień systemowych" />
      )}
    </TableWrapper>
  );
};

SystemMessagesTable.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  offset: PropTypes.number.isRequired,
};

export default SystemMessagesTable;

// styled components

const TableWrapper = styled(TableWrapperCommon)`
  table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
    position: relative;
    z-index: 10;
    text-align: center;

    @media (min-width: 940px) {
      col.actions {
        width: 140px;
      }
    }

    tr {
      th:first-child,
      td:first-child {
        text-align: left;
        padding-left: 20px;
      }

      th,
      td {
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 2px solid ${colors.white};
        }
      }
    }

    thead {
      background-color: ${colors.white};

      tr {
        height: 50px;

        th {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${colors.white};
          font-weight: ${font.weight.semibold};

          &:last-child {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
    }

    tbody {
      background-color: ${colors.blueGray};

      tr {
        height: 40px;

        &:nth-of-type(even) {
          background-color: ${colors.white};
        }

        td {
          font-size: ${font.size.m};
          text-transform: none;
          color: ${colors.black};

          & > svg {
            color: ${colors.orange};
          }

          & > .icons {
            display: inline-block;
            min-width: 60px;
            color: ${colors.orange};
            svg {
              cursor: pointer;
              margin: 0 7px;
            }
          }
        }
      }
    }
  }
`;
