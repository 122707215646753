import React from 'react';
import { Formik, Form } from 'formik';

import { noteSchema } from 'utils/validation';
import PropTypes from 'prop-types';
import Textarea from 'components/UI/Textarea';
import Button from 'components/UI/Button';

const AddNote = ({ handleSubmit }) => {
  return (
    <Formik
      initialValues={{
        text: '',
      }}
      validationSchema={noteSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values);
        resetForm({});
      }}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form>
          <Textarea
            name="text"
            onChange={handleChange}
            onBlur={handleBlur}
            width="100%"
            locked
            height="60px"
            placeholder="Dodaj notatkę"
            value={values.text || ''}
          />
          <Button style={{ marginLeft: 'auto' }} type="submit">
            Wyślij
          </Button>
        </Form>
      )}
    </Formik>
  );
};

AddNote.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default AddNote;
