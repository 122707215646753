import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { suggestionsService } from 'services/suggestions';
import { systemMessageSchema } from 'utils/validation';
import { FormWrapperCommon, Columns, FieldHeading, FormColumn } from 'components/forms/FormComponents';
import { commonFilesMimeTypes } from 'config/constants';
import { socket } from 'services/socket';
import Input from 'components/UI/Input';
import SuccessPopup from 'components/layout/SuccessPopup';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import Heading from 'components/UI/Heading';
import ErrorLabel from 'components/UI/ErrorLabel';
import Loading from 'components/UI/Loading';
import Textarea from 'components/UI/Textarea';
import Radio from 'components/UI/Radio';
import InputAuto from 'components/UI/InputAuto';
import UsersList from 'components/layout/UsersList';
import FileInput from 'components/UI/FileInput';
import { useSelector } from 'react-redux';

const radioOptions = [
  {
    propName: 'admin',
    label: 'Admin',
  },
  {
    propName: 'dsp',
    label: 'Dsp',
  },
  {
    propName: 'merchantPep',
    label: 'Przedstawiciel handlowy',
  },
  {
    propName: 'dr',
    label: 'Dyrektor regionalny',
  },
  {
    propName: 'partner',
    label: 'Partner',
  },
  {
    propName: 'partnerTypeLead',
    label: 'Partner (Lead)',
    type: 'group',
  },
  {
    propName: 'partnerTypeInstallTerminal',
    label: 'Partner (Instalacja terminala)',
    type: 'group',
  },
  {
    propName: 'partnerTypeCanSigningContract',
    label: 'Partner (Podpisywanie umów)',
    type: 'group',
  },
  {
    propName: 'subPartner',
    label: 'Sub-partner',
  },
  {
    propName: 'partnerAndSubPartner',
    label: 'Partner + sub-partnerzy',
  },
  {
    propName: 'partnerAmbasador',
    label: 'Ambasadorzy',
    type: 'group'
  },
  {
    propName: 'all',
    label: 'Wszyscy użytkowicy',
  },
  {
    propName: 'custom',
    label: 'Wybrani indywidualnie',
  },
];

const AddSystemMessage = ({ closePanel }) => {
  const [usersSuggestions, setUsersSuggestions] = useState([]);
  const [pickedUsers, setPickedUsers] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const isSocketConnected = useSelector((state) => state.socket.connected);

  const handleReset = (resetForm) => {
    setSuccess(false);
    setError('');
    resetForm({});
    setPickedUsers([]);
  };

  const handleSubmit = (values) => {
    if (!isSocketConnected) return;

    setError('');
    setLoading(true);

    const { attachment } = values;

    const newValues = { ...values };
    delete newValues.userSearch;

    if (values.group !== 'custom') delete newValues.userIds;

    const payload = {
      subject: newValues.title,
      content: newValues.message,
      type: 'DEFAULT',
      attachment,
      ...(attachment ? { attachmentExt: attachment.name.split('.').pop() } : {}),
    };

    const option = radioOptions.find((o) => o.propName === values.group);

    if (values.group === 'custom') payload.users = newValues.userIds;
    else if (option.type === 'group') payload.group = newValues.group;
    else payload.role = newValues.group;

    socket.emit('notifications:create', payload, (response) => {
      console.log(response);
      setLoading(false);
      if (response.isOk) setSuccess(true);
      else {
        setError(response.error ? response.error.name : 'Wystąpił błąd');
        if (response.error) console.log(response.error.name);
      }
    });
  };

  const removeUserFromPicked = (id) => {
    setPickedUsers((state) => state.filter((user) => user.id !== id));
  };

  const fetchUsers = (inputValue) => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue)
      .then((payload) => setUsersSuggestions(payload.data))
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Wrapper>
      {loading && <Loading absolute />}
      <Heading size="xl">Wyślij nową wiadomość</Heading>
      <Formik
        initialValues={{
          title: '',
          message: '',
          group: 'custom',
          userIds: [],
          userSearch: '',
          attachment: '',
        }}
        validationSchema={systemMessageSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm }) => (
          <>
            {success && (
              <SuccessPopup>
                <Heading>Wiadomość wysłana</Heading>
                {typeof success !== 'boolean' && success}
                <div className="buttons">
                  <Button
                    onClick={() => {
                      handleReset(resetForm);
                      closePanel();
                    }}
                  >
                    Wróć
                  </Button>
                </div>
              </SuccessPopup>
            )}
            {error && (
              <ErrorPopup>
                <Heading>Błąd, nie udało się wysłać wiadomości</Heading>
                <p>{error}</p>
                <div className="buttons">
                  <Button onClick={() => handleReset(resetForm)}>Spróbuj ponownie</Button>
                </div>
              </ErrorPopup>
            )}
            <StyledForm>
              <Columns>
                <FormColumn>
                  <FieldHeading big size="m">
                    Wiadomość:
                  </FieldHeading>
                  <Input
                    name="title"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Tytuł"
                    value={values.title}
                    error={touched.title && errors.title}
                  />
                  <ErrorMessage component={ErrorLabel} name="title" />
                  <FieldHeading size="m">Treść:</FieldHeading>
                  <Textarea
                    name="message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    locked
                    height="120px"
                    value={values.message}
                  />
                  <ErrorMessage component={ErrorLabel} name="message" />
                  <FieldHeading size="s">Dodaj załącznik</FieldHeading>
                  <Field name="attachment" id="attachment" accept={commonFilesMimeTypes} component={FileInput} />
                  <ErrorMessage style={{ marginTop: '1.4rem' }} component={ErrorLabel} name="attachment" />
                </FormColumn>
                <FormColumn>
                  <FieldHeading big size="m">
                    Wyślij do osób z grupy:
                  </FieldHeading>
                  {radioOptions.map((option) => (
                    <Radio
                      key={option.propName}
                      name="group"
                      value={option.propName}
                      onChange={() => setFieldValue('group', option.propName)}
                      label={option.label}
                      checked={values.group === option.propName}
                    />
                  ))}
                  <ErrorMessage component={ErrorLabel} name="group" />
                  {values.group === 'custom' && (
                    <>
                      <InputAuto
                        suggestions={usersSuggestions}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        onlyFromList
                        pickProp="id"
                        name="userSearch"
                        type="text"
                        display={['name', 'surname', 'email']}
                        onBlur={handleBlur}
                        onChange={(e) => fetchUsers(e.target.value)}
                        onSelect={(val, item) => {
                          if (!values.userIds.some((id) => id === item.id)) {
                            setFieldValue('userIds', [...values.userIds, item.id]);
                            setPickedUsers((state) => [...state, item]);
                          }
                          setFieldValue('userSearch', '');
                        }}
                        label="Szukaj użytkownika"
                        value={values.userSearch || ''}
                        error={touched.userSearch && errors.userSearch}
                      />
                      <ErrorMessage component={ErrorLabel} name="userIds" />
                    </>
                  )}
                </FormColumn>
              </Columns>
              {values.group === 'custom' && pickedUsers.length > 0 && (
                <>
                  <FieldHeading size="m">Wyślij do:</FieldHeading>
                  <UsersList
                    users={pickedUsers}
                    onRemove={(id) => {
                      setFieldValue(
                        'userIds',
                        values.userIds.filter((el) => el !== id),
                      );
                      removeUserFromPicked(id);
                    }}
                  />
                </>
              )}
              <Button style={{ marginTop: '20px', width: '200px', marginLeft: 'auto' }} type="submit">
                Wyślij
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

AddSystemMessage.defaultProps = {};

AddSystemMessage.propTypes = {
  closePanel: PropTypes.func.isRequired,
};

export default AddSystemMessage;

// styled components

const Wrapper = styled(FormWrapperCommon)``;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`;
