import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { connect } from 'react-redux';
import { logout } from 'redux/user';
import ErrorPopup from 'components/layout/ErrorPopup';
import Button from 'components/UI/Button';
import makeRequest from 'services/makeRequest';

// AuthErrorPopup implements axios response interceptor for handling 401 auth errors.
// Basicly it check for error status and render popup with information and logout button

const AuthErrorPopup = ({ logout }) => {
  const [error, setError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const authInterceptor = makeRequest.interceptors.response.use(
      (response) => response,
      (error) => {
        
        if (axios.isCancel(error)) return Promise.reject(error);

        const errorResponseStr = JSON.stringify(error.response.data);
        const isAuthError = errorResponseStr.indexOf('401') > 0 || errorResponseStr.indexOf('tokenu') > 0;
        if (isAuthError && error.response.config.url !== '/user/me') {
          setError(401);
        } else {
          setError(false);
        }
        return Promise.reject(error);
      },
    );
    return () => makeRequest.interceptors.response.eject(authInterceptor);
  }, []);

  return error && error.status && error.status !== 401 && location !== routes.login ? (
    <ErrorPopup fixed>
      <span>Twoja sesja wygasła. Odśwież stronę i zaloguj się ponownie</span>
      <Button
        onClick={() => {
          setError(false);
          logout();
        }}
      >
        Przejdź do logowania
      </Button>
    </ErrorPopup>
  ) : null;
};

AuthErrorPopup.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  logout,
};

export default connect(null, mapDispatchToProps)(AuthErrorPopup);
