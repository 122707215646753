import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { colors, font } from 'theme/styles';
import {
  clearActiveConversation,
  loadMoreConversations,
  setPanelActive,
  setChatMode,
  loadMoreArchives,
} from 'redux/chat';
import { chatModes } from 'config/constants';
import { socket } from 'services/socket';
import closeIcon from 'assets/icons/close-24px.svg';
import Conversation from './Conversation';
import Dropdown from '../Dropdown';
import Heading from '../Heading';
import SimpleButton from '../SimpleButton';
import Loading from '../Loading';

const CONVERSATIONS_LIMIT = 20;

const getConversationName = (conv, myId = false) => {
  switch (conv.type) {
    case 'LEAD':
      return `Rozmowa do leada: ${conv?.lead?.frontNumLead}`;
    case 'PATRON':
      if (myId && conv.users) return `Rozmowa z opiekunem - ${conv.users.find(u => u.id !== myId)?.fullName}`;
      return 'Rozmowa z opiekunem';
    default:
      return 'Rozmowa z ...';
  }
};

const ConversationsList = ({ conversations }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isSocketConnected = useSelector(state => state.socket.connected);

  const [, rerenderState] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [noMore, setNoMore] = useState({
    lead: false,
    leadArchive: false,
    patron: false,
    patronArchive: false,
  });
  const [offset, setOffset] = useState({
    lead: CONVERSATIONS_LIMIT,
    leadArchive: CONVERSATIONS_LIMIT,
    patron: CONVERSATIONS_LIMIT,
    patronArchive: CONVERSATIONS_LIMIT,
  });

  const conversationsByType = conversations.reduce((obj, c) => {
    // eslint-disable-next-line no-param-reassign
    obj[c.type] = obj[c.type] ? obj[c.type] + 1 : 1;
    return obj;
  }, {});

  const patronConversationId = useSelector(s => s.user.data.patronConversationId);
  // const activeConversationType = useSelector(s => s.chat.activeConversation.conversation.type);
  const activeConversationId = useSelector(s => s.chat.activeConversation.conversation.id);
  const isLoadingConversations = useSelector(s => s.chat.allConversations.isLoading);
  const isLoadingLeads = useSelector(s => s.chat.isLoadingLeads);
  const isLoadingPatrons = useSelector(s => s.chat.isLoadingPatrons);
  const initialLoaded = useSelector(s => s.chat.allConversations.initialLoaded);
  const unreadConversations = useSelector(state => state.chat.unreadConversations);
  const chatMode = useSelector(state => state.chat.chatMode);
  const myId = useSelector(s => s.user.data.id);

  // Rerender widoku w celu aktualizacji czasu co 10 sekund
  useEffect(() => {
    const rerenderInterval = setInterval(() => rerenderState(s => !s), 10000);
    return () => clearInterval(rerenderInterval);
  });

  const handleLoadMore = () => {
    if (!isSocketConnected) return;

    setIsLoadingMore(true);

    if (chatMode === chatModes.normal) {
      // Normalne

      if (!noMore.lead)
        socket.emit('chat:lead:conversations', { offset: offset.lead }, response => {
          setIsLoadingMore(false);
          if (response.data && response.data.length) dispatch(loadMoreConversations(response.data));
          if ((response.data && response.data.length < CONVERSATIONS_LIMIT) || !response.isOk)
            setNoMore(s => ({ ...s, lead: true }));
          setOffset(o => ({ ...o, lead: o.lead + CONVERSATIONS_LIMIT }));
        });

      if (!noMore.patron)
        socket.emit('chat:patron:conversations', { offset: offset.patron }, response => {
          setIsLoadingMore(false);
          if (response.data && response.data.length) dispatch(loadMoreConversations(response.data));
          if ((response.data && response.data.length < CONVERSATIONS_LIMIT) || !response.isOk)
            setNoMore(s => ({ ...s, patron: true }));
          setOffset(o => ({ ...o, patron: o.patron + CONVERSATIONS_LIMIT }));
        });
    } else if (chatMode === chatModes.archive) {
      // Archiwum

      if (!noMore.leadArchive)
        socket.emit('chat:lead:archives', { offset: offset.leadArchive }, response => {
          setIsLoadingMore(false);
          if (response.data && response.data.length) dispatch(loadMoreArchives(response.data));
          if ((response.data && response.data.length < CONVERSATIONS_LIMIT) || !response.isOk)
            setNoMore(s => ({ ...s, leadArchive: true }));
          setOffset(o => ({ ...o, leadArchive: o.leadArchive + CONVERSATIONS_LIMIT }));
        });

      if (!noMore.patronArchive)
        socket.emit('chat:patron:archives', { offset: offset.patronArchive }, response => {
          setIsLoadingMore(false);
          if (response.data && response.data.length) dispatch(loadMoreArchives(response.data));
          if ((response.data && response.data.length < CONVERSATIONS_LIMIT) || !response.isOk)
            setNoMore(s => ({ ...s, patronArchive: true }));
          setOffset(o => ({ ...o, patronArchive: o.patronArchive + CONVERSATIONS_LIMIT }));
        });
    }
  };

  const handleSelectConversation = conv => {
    if (activeConversationId === conv.id) return;
    history.replace({ search: `?type=${conv.type.toLowerCase()}&conversation=${conv.id}` });
    dispatch(setPanelActive(false));
  };

  const handlePatronConversationStart = () => {
    dispatch(clearActiveConversation());
    if (patronConversationId) history.push(`/contact?type=patron&conversation=${patronConversationId}`);
    else history.push(`/contact?newPatronConversation`);
  };

  return (
    <Wrapper>
      <Header>
        <StyledHeading style={{ marginRight: 'auto' }} size="xl">
          {chatMode === chatModes.normal ? `Wiadomości` : 'Archiwum'}
        </StyledHeading>
        <SimpleButtonRwd style={{ marginRight: '1rem' }} type="button" onClick={handlePatronConversationStart}>
          Rozmowa z opiekunem
        </SimpleButtonRwd>
        <ButtonsWrapper>
          <Dropdown>
            <Dropdown.Item
              disabled={chatMode === chatModes.normal}
              onClick={() => dispatch(setChatMode(chatModes.normal))}
            >
              Wiadomości
            </Dropdown.Item>
            <Dropdown.Item
              disabled={chatMode === chatModes.archive}
              onClick={() => dispatch(setChatMode(chatModes.archive))}
            >
              Archiwum
            </Dropdown.Item>
            <DropdownItemRwd color={colors.red} onClick={handlePatronConversationStart}>
              Rozmowa z opiekunem
            </DropdownItemRwd>
          </Dropdown>
          <CloseButton onClick={() => dispatch(setPanelActive(false))} />
        </ButtonsWrapper>
      </Header>
      {initialLoaded && conversations.length === 0 && <Heading>Brak konwersacji</Heading>}
      <ConversationsListElement>
        {(isLoadingConversations || isLoadingLeads || isLoadingPatrons || isLoadingMore) && <Loading absolute />}
        {conversations
          .filter(c => c.users && c.users.some(u => u.id === myId))
          .map(conv => {
            const { lastMessage = false, users = [], lastMessageAt = false, lead, id, updatedAt, details } = conv;
            return (
              <Conversation
                unread={unreadConversations.includes(id)}
                isActive={activeConversationId === id}
                key={id}
                id={id}
                name={getConversationName(conv, myId)}
                users={users}
                lastMessage={lastMessage}
                updatedAt={updatedAt}
                lead={lead}
                lastMessageAt={lastMessageAt}
                details={details}
                onClick={() => handleSelectConversation(conv)}
              />
            );
          })}
      </ConversationsListElement>
      {(conversationsByType?.LEAD >= CONVERSATIONS_LIMIT || conversationsByType?.PATRON >= CONVERSATIONS_LIMIT) && (
        <LoadmMoreWrapper>
          <SimpleButton
            underline
            blue
            onClick={handleLoadMore}
            disabled={
              isLoadingMore || chatMode === chatModes.normal
                ? noMore.lead && noMore.patron
                : noMore.leadArchive && noMore.patronArchive
            }
          >
            {noMore.lead && noMore.patron ? 'Brak dodatkowych konwersacji' : 'Pokaż więcej'}
          </SimpleButton>
        </LoadmMoreWrapper>
      )}
    </Wrapper>
  );
};

ConversationsList.defaultProps = {};

ConversationsList.propTypes = {
  conversations: PropTypes.instanceOf(Array).isRequired,
};

export default ConversationsList;

const DropdownItemRwd = styled(Dropdown.Item)`
  display: none;
  @media (max-width: 420px), (min-width: 769px) and (max-width: 1060px) {
    display: block;
  }
`;
const SimpleButtonRwd = styled(SimpleButton)`
  @media (max-width: 420px), (min-width: 769px) and (max-width: 1060px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.blueLighter};
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 1.5rem;
  }
`;
const ConversationsListElement = styled.ul`
  position: relative;
  list-style: none;
  flex: 1;
  padding-right: 1rem;
  margin-right: -1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;
const LoadmMoreWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const StyledHeading = styled(Heading)`
  font-weight: ${font.weight.bold};
`;

const CloseButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    background-image: url(${closeIcon});
    background-position: 50% 50%;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.5;
    }
  }
`;
