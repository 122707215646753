import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Label, Text } from 'components/layout/Client/ClientInfo';
import Heading from 'components/UI/Heading';
import { colors } from 'theme/styles';

// const renderNode = nodeEl => {
//   const childNodes = Array.from(nodeEl.children);
//   return (
//     <Account key={nodeEl.textContent}>
//       {childNodes &&
//         childNodes.length > 0 &&
//         childNodes.map(n => (
//           <AccoutNode key={n.tagName}>
//             <span>{n.tagName}:</span> {n.children.length > 0 ? renderNode(n) : n.textContent || '-'}
//           </AccoutNode>
//         ))}
//     </Account>
//   );
// };

const CrmAccountDisplay = ({ acc }) => {
  const render = nodeName => acc.querySelector(nodeName)?.textContent || '-';

  return (
    <Wrapper>
      <div style={{ marginBottom: '1rem' }}>
        <Heading color={colors.orange} style={{ marginBottom: '1rem' }} size="l">
          CRM Account
        </Heading>
        <ColsWrapper>
          <div>
            <Label>NIP</Label>
            <Text>{render('NIP')}</Text>
          </div>
          <div>
            <Label>Id</Label>
            <Text>{render('Id')}</Text>
          </div>
        </ColsWrapper>
      </div>
      <ColsWrapper>
        <Col>
          <Heading color={colors.orange} style={{ marginBottom: '1rem' }} size="l">
            Dane ogólne
          </Heading>
          <Label>Nazwa</Label>
          <Text>{render('Nazwa')}</Text>
          <Label>Osoba kontaktowa</Label>
          <Text>{render('OsobaKontaktowa')}</Text>
          <Label>Status</Label>
          <Text>{render('Status')}</Text>
          <Label>Status Value</Label>
          <Text>{render('StatusValue')}</Text>
          <Label>Data utworzenia</Label>
          <Text>{render('DataUtworzenia')}</Text>
          <Label>Data modyfikacji</Label>
          <Text>{render('DataModyfikacji')}</Text>
          <Label>Data ostatniej zmiany</Label>
          <Text>{render('DataOstatniejZmianyStatusu')}</Text>
        </Col>
        <Col>
          <Heading color={colors.orange} style={{ marginBottom: '1rem' }} size="l">
            Dane adresowe
          </Heading>
          <Label>Miasto</Label>
          <Text>{render('Miasto')}</Text>
          <Label>Ulica</Label>
          <Text>{render('Ulica')}</Text>
          <Label>Kod pocztowy</Label>
          <Text>{render('KodPocztowy')}</Text>
          <Label>Województwo</Label>
          <Text>{render('Wojewodztwo')}</Text>
        </Col>
        <Col>
          <Heading color={colors.orange} style={{ marginBottom: '1rem' }} size="l">
            PH Opieki Biz
          </Heading>
          <Label>Name</Label>
          <Text>{render('PhOpBiz Name')}</Text>
          <Label>NrPH</Label>
          <Text>{render('PhOpBiz NrPH')}</Text>
          <Label>Email</Label>
          <Text>{render('PhOpBiz Email')}</Text>
          <Label>Telefon</Label>
          <Text>{render('PhOpBiz Telefon')}</Text>
          <Label>Region</Label>
          <Text>{render('PhOpBiz Region')}</Text>
        </Col>
        <Col>
          <Heading color={colors.orange} style={{ marginBottom: '1rem' }} size="l">
            PH Opieki Poz
          </Heading>
          <Label>Name</Label>
          <Text>{render('PhPoz Name')}</Text>
          <Label>NrPH</Label>
          <Text>{render('PhPoz NrPH')}</Text>
          <Label>Email</Label>
          <Text>{render('PhPoz Email')}</Text>
          <Label>Telefon</Label>
          <Text>{render('PhPoz Telefon')}</Text>
          <Label>Region</Label>
          <Text>{render('PhPoz Region')}</Text>
        </Col>
      </ColsWrapper>
    </Wrapper>
  );
};

CrmAccountDisplay.propTypes = {
  acc: PropTypes.instanceOf(Object).isRequired,
};

export default CrmAccountDisplay;

const Wrapper = styled.div`
  margin-top: 2rem;

  & + & {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid ${colors.gray};
  }

  ${Label} {
  }
  ${Text} {
    margin-bottom: 1rem;
  }
`;

const ColsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  & > * {
    flex: 1 0 30rem;
    max-width: 30rem;
  }
`;
const Col = styled.div``;
