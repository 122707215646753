import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { InvoiceNumberFormSchema } from 'utils/validation';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import { FieldHeading, FormWrapperCommon } from 'components/forms/FormComponents';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import moment from 'moment';
import DateTimePicker from 'components/UI/DateTimePicker';

const InvoiceNumberForm = ({ handleReset, handleSubmit, initialValues, activeItem }) => {
  const { invoiceNumber, issueDate } = initialValues;

  const startDate = moment(activeItem.FvN_DataDodania)
    .startOf('month')
    .subtract(1, 'days')
    .toDate();

  const endDate = moment().toDate();

  return (
    <Wrapper>
      <Heading size="xl">Wprowadź numer faktury</Heading>
      <Formik
        initialValues={{
          invoiceNumber,
          issueDate,
        }}
        validationSchema={InvoiceNumberFormSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSubmit(values);
          resetForm({});
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <StyledForm>
            <Input
              name="invoiceNumber"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Numer dokumentu (faktury)"
              value={values.invoiceNumber}
              error={touched.invoiceNumber && errors.invoiceNumber}
            />
            <ErrorMessage component={ErrorLabel} name="invoiceNumber" />
            <FieldHeading size="s">Data wystawienia</FieldHeading>
            <DateTimePicker
              onlyDays
              handleDate={date => setFieldValue('issueDate', date)}
              minDate={startDate}
              maxDate={endDate}
            />
            <ErrorMessage component={ErrorLabel} name="issueDate" />
            <br />
            <div className="buttons">
              <Button onClick={handleReset}>Anuluj</Button>
              <Button type="submit">Zapisz</Button>
            </div>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};

InvoiceNumberForm.defaultProps = {
  initialValues: {
    invoiceNumber: '',
    issueDate: '',
  },
};

InvoiceNumberForm.propTypes = {
  initialValues: PropTypes.instanceOf(Object),
  activeItem: PropTypes.instanceOf(Object).isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default InvoiceNumberForm;

// styled components
const Wrapper = styled(FormWrapperCommon)``;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .buttons {
    margin: auto;
  }
`;
