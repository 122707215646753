import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import {
  getAllUsers,
  getUser,
  clearCurrentUser,
  getStructures,
  getRoles,
  getCounties,
  getPepStructures,
} from 'redux/admin';
import { logout } from 'redux/user';
import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import usePopup from 'hooks/usePopup';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import SearchInput from 'components/UI/SearchInput';
import AddUser from 'components/forms/Admin/AddUser';
import SidePanel from 'components/layout/SidePanel';
import EditUser from 'components/forms/Admin/EditUser';
import UsersTable from 'components/layout/Tables/UsersTable';
import Pagination from 'components/UI/Pagination';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import LoginAsMaster from 'components/logic/LoginAsMaster';
import UsersBulkImport from 'components/forms/Admin/UsersBulkImport';

const AdminUsersPage = ({
  loggedUser,
  getAllUsers,
  getUser,
  getStructures,
  getRoles,
  getCounties,
  getPepStructures,
  structures,
  roles,
  usersData,
  usersCount,
  totalPages,
  currentPage,
  isLoading,
  error,
  logout,
  currentUser,
  clearCurrentUser,
}) => {
  const userRole = loggedUser.role.slug;
  const history = useHistory();

  const [isNewUserVisible, setNewUserVisible] = useState(false);
  const [isEditUserVisible, setEditUserVisible] = useState(false);
  const [page, setPage] = useState('1');
  const [perPage, setPerPage] = useState('20');
  const [search, setSearch] = useState('');
  const [structure, setStructure] = useState('');
  const [sort, setSort] = useState('DESC');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('active');

  const [filteredRoles, setFilteredRoles] = useState([]);

  const [loginAsUserData, setLoginAsUserData] = useState('');
  const [ImportBulkPopup, toggleImportBulkPopup, setIsVisibleImportBulkPopup] = usePopup();

  const openEditUserPanel = useCallback(
    id => {
      setEditUserVisible(true);
      getUser(id);
    },
    [getUser],
  );

  useEffect(() => {
    const excludeDsp = ['admin', 'dsp'];
    switch (loggedUser.role.slug) {
      case 'dsp':
        setFilteredRoles(roles.filter(role => !excludeDsp.includes(role.slug)));
        break;
      default:
        setFilteredRoles(roles);
    }
  }, [roles, loggedUser.role.slug]);

  useEffect(() => {
    getStructures();
    getRoles();
    getCounties();
    getPepStructures();
  }, [getStructures, getRoles, getCounties, getPepStructures]);

  const getUsersData = useCallback(() => {
    const query = {
      search,
      structure,
      role,
      sort,
      status,
    };
    getAllUsers(perPage, page, query);
  }, [search, structure, role, sort, status, getAllUsers, perPage, page]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  const toggleNewUserPanel = () => {
    setNewUserVisible(!isNewUserVisible);
  };

  const closeEditUserPanel = useCallback(() => {
    setEditUserVisible(false);
    clearCurrentUser();
  }, [clearCurrentUser]);

  const handlePerPage = e => {
    setPerPage(e.target.value);
  };

  const handleSearch = value => {
    setSearch(value);
    setPage(1);
  };

  const handleTopBarElement = (e, set) => {
    set(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    clearCurrentUser();
    const userId = history.location.state?.showUser?.id || null;
    const email = history.location.state?.showUser?.email || null;
    if (email) setSearch(email);
    if (userId) openEditUserPanel(userId);
  }, [history, clearCurrentUser, openEditUserPanel]);

  const showErrorPopup = error => {
    if (error.status === 401) {
      return (
        <ErrorPopup fixed>
          <span>Twoja sesja wygasła. Odśwież stronę i zaloguj się ponownie</span>
          <Button onClick={logout}>Przejdź do logowania</Button>
        </ErrorPopup>
      );
    }
    return <ErrorPopup>{error.message}</ErrorPopup>;
  };

  const usersBulkImportHanlder = () => {
    toggleImportBulkPopup();
  };

  const headers = [
    { displayName: 'Data założenia', property: 'createdAt' },
    { displayName: 'Użytkownik', property: 'fullName' },
    { displayName: 'Telefon', property: 'phone' },
    { displayName: 'Email', property: 'email' },
    { displayName: 'Rola', property: 'roleName' },
    { displayName: 'Aktywny', property: 'active' },
  ];

  return (
    <PageTemplate>
      {loginAsUserData && <LoginAsMaster loginAs={loginAsUserData} />}
      <ImportBulkPopup>
        <UsersBulkImport onSucces={() => setIsVisibleImportBulkPopup(false)} />
      </ImportBulkPopup>
      <TopSection>
        {['admin', 'dsp'].includes(userRole) && (
          <Button onClick={toggleNewUserPanel}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj użytkownika
          </Button>
        )}
        <TableSorter>
          <SortItem>
            <span>Struktura</span>
            <Select small value={structure || ''} onChange={e => handleTopBarElement(e, setStructure)}>
              <option value=""> </option>
              {structures &&
                structures.map(structure => (
                  <option key={structure.id} value={structure.id}>
                    {structure.region}
                  </option>
                ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Sortuj</span>
            <Select small value={sort} onChange={e => handleTopBarElement(e, setSort)}>
              <option value="DESC">Najnowsze</option>
              <option value="ASC">Najstarsze</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Rola użytkownika</span>
            <Select small value={role || ''} onChange={e => handleTopBarElement(e, setRole)}>
              <option value=""> </option>
              {roles &&
                filteredRoles.map(role => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
            </Select>
          </SortItem>
          <SortItem>
            <span>Status</span>
            <Select small value={status} onChange={e => handleTopBarElement(e, setStatus)}>
              <option value="active">Aktywni</option>
              <option value="inactive">Nieaktywni</option>
              {['admin'].includes(userRole) && <option value="deleted">Usunięci</option>}
            </Select>
          </SortItem>
          <SortItem>
            <span>Szukaj</span>
            <SearchInput handleSearch={handleSearch} value={search} />
          </SortItem>
        </TableSorter>
      </TopSection>
      {!isLoading && error && showErrorPopup(error)}
      {isLoading ? (
        <Loading />
      ) : (
        <UsersTable
          {...(['admin', 'dsp'].includes(userRole) && { handleEdit: openEditUserPanel })}
          handleLoginAs={userRole === 'admin' && setLoginAsUserData}
          data={usersData}
          headers={headers}
          offset={(currentPage - 1) * (perPage || 20)}
        />
      )}
      {userRole === 'admin' && (
        <FooterButtonsWrapper>
          <Button small secondary onClick={usersBulkImportHanlder}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Importuj użytkowników z pliku CSV
          </Button>
        </FooterButtonsWrapper>
      )}
      <BottomSection>
        <BottomItem>Wszystkich użytkowników: {usersCount}</BottomItem>
        <Pagination allowPageSet currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={perPage || ''} onChange={handlePerPage}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      <SidePanel small toggleClose={closeEditUserPanel} isVisible={isEditUserVisible}>
        {currentUser !== null && (
          <EditUser
            toggleClose={closeEditUserPanel}
            refetchUsers={getUsersData}
            refetchPepStructures={getPepStructures}
            userData={currentUser}
            structures={structures}
            roles={roles}
            loggedUser={loggedUser}
          />
        )}
      </SidePanel>
      <SidePanel small toggleClose={toggleNewUserPanel} isVisible={isNewUserVisible}>
        {isNewUserVisible && (
          <AddUser
            refetchUsers={getUsersData}
            refetchPepStructures={getPepStructures}
            structures={structures}
            roles={roles}
            loggedUser={loggedUser}
          />
        )}
      </SidePanel>
    </PageTemplate>
  );
};

const FooterButtonsWrapper = styled.div`
  button {
    display: inline-block;
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

AdminUsersPage.defaultProps = {
  totalPages: 1,
  currentPage: 1,
  currentUser: null,
  usersCount: 0,
  error: null,
};

AdminUsersPage.propTypes = {
  loggedUser: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  usersData: PropTypes.instanceOf(Array).isRequired,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  currentUser: PropTypes.instanceOf(Object),
  usersCount: PropTypes.number,
  error: PropTypes.instanceOf(Object),
  getAllUsers: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  clearCurrentUser: PropTypes.func.isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  structures: PropTypes.instanceOf(Array).isRequired,
  getStructures: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getCounties: PropTypes.func.isRequired,
  getPepStructures: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loggedUser: state.user.data,
  isLoading: state.admin.users.isLoading,
  usersData: state.admin.users.data.rows,
  currentUser: state.admin.currentUser.data,
  usersCount: state.admin.users.data.count,
  totalPages: state.admin.users.data.pagination.totalPages,
  currentPage: state.admin.users.data.pagination.currentPage,
  error: state.admin.users.error,
  roles: state.admin.roles.data,
  structures: state.admin.structures.data,
});

const mapDispatchToProps = {
  getAllUsers,
  getUser,
  logout,
  clearCurrentUser,
  getStructures,
  getRoles,
  getCounties,
  getPepStructures,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);
