import makeRequest from 'services/makeRequest';

const NOTIFICATIONS_BASE_URL = '/notification';

const getNotificationsFile = id =>
  makeRequest({
    method: 'get',
    url: `${NOTIFICATIONS_BASE_URL}/download?notificationId=${id}`,
    responseType: 'blob',
  });

export const notificationsService = {
  getNotificationsFile,
};
