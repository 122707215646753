import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { font, colors, breakpoints } from 'theme/styles';
import { BASE_URL } from 'config';
import { routes } from 'routes';
import { ReactComponent as PepLogo } from 'assets/pep_logo_2020_white.svg';
import { ReactComponent as AmbasadorLogo } from 'assets/logo-ambasador.svg';
import placeholder from 'assets/avatar-placeholder.png';
import NotificationBell from 'components/UI/Notifications/NotificationBell';
import NavButton from 'components/UI/NavButton';
import Hamburger from 'components/UI/Hamburger';

const getHeaderBackground = () => {
  const { hostname } = window.location;
  if (hostname === 'test-partner.pep.pl')
    return `linear-gradient(180deg, ${colors.headerGray1} 0%, ${colors.headerGray2} 100%);`;
  if (hostname === 'localhost') return `linear-gradient(180deg, ${colors.notiBlueAccent} 0%, ${colors.notiBlue} 100%);`;
  return `linear-gradient(180deg, ${colors.orange} 0%, ${colors.red} 100%);`;
};

const showAmbasadorLogo = (user) => {
  if (user?.company?.partnerProgram?.includes('AMBASADOR')) return true;
  return false;
};

const Header = ({ handleLogout }) => {
  const AVATAR_URL = `${BASE_URL}/storage/users/avatar/`;
  const [activeUserMenu, setActiveUserMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');

  const unreadConversations = useSelector((state) => state.chat.unreadConversations);
  const user = useSelector((state) => state.user.data);
  const userRole = user.role.slug;

  useEffect(() => {
    if (user.avatar !== null) {
      setAvatarUrl(AVATAR_URL + user.avatar);
    }
  }, [AVATAR_URL, user.avatar]);

  useEffect(() => {
    document.body.style.overflow = activeMenu ? 'hidden' : 'unset';
  }, [activeMenu]);

  const ref = useRef(null);

  const toggleUserMenu = () => {
    setActiveUserMenu(!activeUserMenu);
  };

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  return (
    <Wrapper>
      <Container>
        <NavLink to="/leads">
          <Logo />
        </NavLink>
        <Navigation active={activeMenu}>
          <MainMenu>
            {!['skf'].includes(userRole) && (
              <NavButton to="/leads" activeClassName="active">
                Strona Główna
              </NavButton>
            )}
            {['skf'].includes(userRole) && (
              <NavButton to={routes.integrationOrders} activeClassName="active">
                Zlecenia integracyjne
              </NavButton>
            )}
            {['admin', 'dsp'].includes(userRole) && (
              <NavButton to={routes.admin} activeClassName="active">
                Panel Administracyjny
              </NavButton>
            )}
            {['guest'].includes(userRole) && (
              <NavButton to={routes.adminGuest} activeClassName="active">
                Panel Administracyjny Gościa
              </NavButton>
            )}
            {['dr'].includes(userRole) && (
              <NavButton to={routes.drPanel} activeClassName="active">
                Panel zarządzania
              </NavButton>
            )}
            {['merchantPep'].includes(userRole) && (
              <NavButton to={routes.merchantPanel} activeClassName="active">
                Panel handlowca
              </NavButton>
            )}
            {['partner', 'subPartner'].includes(userRole) && (
              <>
                {user.canInstallTerminal && (
                  <NavButton to={routes.integrationOrders} activeClassName="active">
                    Zlecenia
                  </NavButton>
                )}
                <NavButton to="/partner" activeClassName="active">
                  Panel Partnera
                </NavButton>
              </>
            )}
            {!['skf', 'merchantPep'].includes(userRole) && (
              <NavButton to="/raports" activeClassName="active">
                Raporty
              </NavButton>
            )}
            <NavButton
              to="/contact"
              activeClassName="active"
              badge={unreadConversations.length > 0 ? unreadConversations.length : null}
            >
              Wiadomości
            </NavButton>
          </MainMenu>
          <UserMenu active={activeUserMenu} menuWidth={ref.current ? ref.current.offsetWidth : 149}>
            {showAmbasadorLogo(user) && <LogoAmbasador />}
            <NotificationBell additionalAction={() => activeMenu && setActiveMenu(false)} />
            <User onClick={toggleUserMenu}>
              <img src={avatarUrl || placeholder} alt="user avatar" />
              <span>{user.fullName}</span>
            </User>
            <div ref={ref} className="user-menu">
              <NavButton exact to="/user" activeClassName="active">
                Twój profil
              </NavButton>
              <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
            </div>
          </UserMenu>
          <Overlay onClick={toggleMenu} onTouchEnd={() => setTimeout(toggleMenu, 100)} />
        </Navigation>
        <Hamburger active={activeMenu} onClick={toggleMenu} />
      </Container>
    </Wrapper>
  );
};

Header.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default Header;

// styled components

const Wrapper = styled.header`
  position: fixed;
  z-index: 10050;
  top: 0;
  left: 0;
  width: 100%;
  height: 63px;
  padding: 0 15px;
  background: ${getHeaderBackground()};
  border-bottom: 1px solid #fff;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  overflow-x: hidden;
`;

const Logo = styled(PepLogo)`
  width: 160px;
  display: block;
  height: auto;
  margin-right: 20px;
  object-fit: contain;
`;

const LogoAmbasador = styled(AmbasadorLogo)`
  position: fixed;
  right: 5rem;
  top: 31px;
  transform: translateY(-50%);

  width: 5rem;
  display: block;
  height: auto;
  fill: #ffffff;
  margin-left: auto;
  margin-right: 2rem;

  @media (max-width: 350px) {
    display: none !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    position: static;
    transform: none;
  }

  @media (min-width: ${breakpoints.desktopBig}) {
    display: block;
    right: 2rem;
  }
`;

const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 63px;
  left: 0;
  width: 100%;
  height: calc(100vh - 63px);
  background-color: ${colors.backgroundTransparent};
  z-index: -1;
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}) {
    background-color: #cd3531;
    flex-direction: column;
    position: absolute;
    top: 63px;
    left: 0;
    overflow: hidden;
    max-height: ${({ active }) => (active ? '300px' : '0')};
    transition: max-height 0.2s ease-out;
    box-shadow: 0px 5px 15px ${colors.black}50;
  }

  ${(props) =>
    props.active &&
    css`
      ${Overlay} {
        display: block;

        @media (min-width: ${breakpoints.tablet}) {
          display: none;
        }
      }
    `};
`;

const MainMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;

    & > ${NavButton} {
      margin: 10px 0;
      font-size: ${font.size.m};
    }
  }
`;

const UserMenu = styled.div`
  display: flex;
  user-select: none;
  transition: transform 0.3s ease;
  transform: ${({ active, menuWidth }) => (active ? 'translateX(0)' : `translateX(${menuWidth}px)`)};
  flex-direction: row;
  align-items: center;

  @media (max-width: 900px) {
    transform: translateX(0);
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    transform: none;
    margin-left: 0;
    padding: 0 20px 20px 20px;
    align-items: flex-start;
  }

  & > div.user-menu {
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 0;
    margin: 0;
    width: 149px;

    @media (max-width: ${breakpoints.tablet}) {
      /* margin-top: 15px; */
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      & > * {
        margin: 10px 0;
        font-size: ${font.size.m};
      }
    }
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;

  @media (max-width: 900px) {
    display: none;
  }

  img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
  }

  span {
    font-size: ${font.size.s};
    color: ${colors.white};
    text-transform: capitalize;
  }
`;

const MenuItem = styled.span`
  color: ${colors.white};
  position: relative;
  font-size: ${font.size.s};
  text-decoration: none;
  margin: 0 5px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 0;
    background-color: #fff;
    bottom: -2px;
    left: 0;
    transition: width 0.15s ease-in;
  }

  &.active,
  &:hover {
    color: #fff;
    text-decoration: none;
    &::before {
      width: 100%;
    }
  }
`;
