import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllInvoices, getAllBillings, setCurrentInvoice, setCurrentInvoiceTemplate } from 'redux/invoicing';
import { removeNullsAndEmpty, download, displayDate } from 'utils/helpers';
// import { invoiceStatus } from 'config/constants';
import { invoicingService } from 'services/invoicing';
// import useTopSection from 'hooks/useTopSection';
import usePagination from 'hooks/usePagination';
import {
  // SortItem,
  // TableSorter,
  TopSection,
  OneLineButtonsWrapper,
  BottomSection,
  BottomItem,
} from 'components/layout/Tables/TableComponents';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
// import DateFromTo from 'components/UI/DateFromTo';
import InvoiceTable from 'components/layout/Tables/InvoiceTable';
import BillingsTable from 'components/layout/Tables/BillingsTable';
import Pagination from 'components/UI/Pagination';
import InvoiceNumberForm from 'components/forms/Partner/InvoiceNumberForm';
import Heading from 'components/UI/Heading';
import ErrorPopup from 'components/layout/ErrorPopup';
import usePopup from 'hooks/usePopup';

const getHeaders = (role) => ({
  common: [
    { displayName: 'Typ dokumentu', property: 'type' },
    { displayName: 'Data wygenerowania', property: 'FvN_DataDodania', type: 'date' },
    { displayName: 'Status dokumentu', property: 'status.FvN_StatusOpis' },
    { displayName: 'Numer faktury', property: 'DBF_NumerXL', or: 'FvN_NumerObcy' },
    { displayName: 'Data wystawienia', property: 'DBF_DataWyst', or: 'FvN_DataWystawienia', type: 'date' },
    { displayName: 'Termin płatności', property: 'DBF_Termin', type: 'date', or: 'FvN_TerminPlatnosci' },
    {
      displayName: 'Wartość netto',
      property: 'DBF_Netto',
      or: 'summaryTable.netto',
      type: 'currency',
      class: 'right',
    },
    { displayName: 'Data pobrania', property: 'DBF_DataPobraniaSP', or: 'downloadDate', type: 'date' },
  ],
  billings: [
    { displayName: 'Partner', property: 'RoN_PartnerNazwa' },
    { displayName: 'NIP', property: 'RoN_PartnerNIP' },
    { displayName: 'Data wygenerowania', property: 'RoN_DataGenerowania', type: 'date' },
    { displayName: 'Rozliczenie za okres', property: 'RoN_RozliczenieZa' },
    { displayName: 'Typ Rozliczenia', property: 'RoN_RodzajRozliczenia' },
    ['admin', 'dsp', 'dr'].includes(role) && {
      displayName: 'Data podglądu',
      property: 'RoN_DataPobraniaSP',
      type: 'time',
    },
  ].filter(Boolean),
});

const PartnerInvoicingPage = ({ invoicesState, billingsState, getAllInvoices, getAllBillings, userRole }) => {
  const history = useHistory();
  const company = useRef(history.location.state?.company);
  const [error, setError] = useState(null);
  const [activeView, setActiveView] = useState('invoices');
  const { pagination, handlePerPage, setPage, setPagination } = usePagination();
  // const { topSectionState, handleChange, handleSpecial } = useTopSection([
  //   { name: 'status' },
  //   { name: 'docType' },
  //   { name: 'date', type: 'date' },
  // ]);
  const [FvNumberPopup, toggleFvNumberPopup, setFvNumberPopupVisibility] = usePopup();
  const [activeItem, setActiveItem] = useState(null);
  const [fnNumberConfirmation, setFnNumberConfirmation] = useState(false);
  const [FvRejectPopup, toggleFvRejectPopup, setFvRejectPopupVisibility] = usePopup({ type: 'error' });

  const fetchDataCb = useCallback(() => {
    const query = removeNullsAndEmpty({
      // ...topSectionState,
      // date_from: topSectionState.date[0],
      // date_to: topSectionState.date[1],
      // date: null,
      limit: pagination.perPage,
      page: pagination.page,
      // companyNip: 1000000001,
      companyNip: company.current?.nip,
    });

    if (activeView === 'invoices') {
      const queryCopy = { ...query };
      delete queryCopy.limit;
      delete queryCopy.page;
      getAllInvoices(queryCopy);
    } else {
      getAllBillings(query);
    }
  }, [activeView, getAllInvoices, getAllBillings, pagination.perPage, pagination.page]);

  useEffect(() => {
    let error = null;
    if (invoicesState.error) error = invoicesState.error;
    if (billingsState.error) error = billingsState.error;
    setError(error);
  }, [invoicesState.error, billingsState.error]);

  useEffect(() => {
    fetchDataCb();
  }, [fetchDataCb]);

  useEffect(() => {
    setPagination((prevPag) => ({
      ...prevPag,
      ...billingsState.data.pagination,
      count: billingsState.data.count,
    }));
  }, [billingsState.data, setPagination]);

  const handleDownload = (item) => {
    invoicingService
      .getInvoicePdf(item.DBF_Id)
      .then((res) => {
        download(res, `${item.DBF_NumerXL}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetItemState = () => {
    setFvNumberPopupVisibility(false);
    setFvRejectPopupVisibility(false);
    setFnNumberConfirmation(false);
    setActiveItem(null);
  };

  const handleTemplateDownload = (item) => {
    if (item.FvN_NumerObcy) {
      // Zapisana w bazie
      invoicingService
        .getAcceptedInvoicePdf(item.FvN_ID)
        .then((res) => {
          download(res, `${displayDate(item.FvN_DataDodania)}.pdf`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Podgląd
      invoicingService
        .getInvoiceTemplatePdf(item.FvN_ID, { companyNip: company.current?.nip })
        .then((res) => {
          download(res, `${displayDate(item.FvN_DataDodania)}-podgląd.pdf`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleTemplateReject = (item) => {
    setActiveItem(item);
    toggleFvRejectPopup();
  };

  const handleTemplateNumber = (item) => {
    setActiveItem(item);
    toggleFvNumberPopup();
  };

  const sendTemplateNumber = (values) => {
    if (!activeItem) return;

    const number = values.invoiceNumber;
    const issueDate = moment(values.issueDate).format('YYYY-MM-DD');

    invoicingService
      .setInvoiceTemplateNumber(activeItem.FvN_ID, { number, issueDate })
      .then((res) => {
        console.log(res);
        download(res, `Faktura - ${number}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        resetItemState();
        fetchDataCb();
      });
  };

  const sendRejection = () => {
    if (!activeItem) return;

    invoicingService
      .setInvoiceTemplateReject(activeItem.FvN_ID)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        resetItemState();
        fetchDataCb();
      });
  };

  const handleBillingDetails = (item) => {
    history.push(`/partner/rozliczenia/${item.RoN_ID}`);
  };

  return (
    <PageTemplate>
      <FvRejectPopup>
        <Heading size="xl">Odrzuć fakturę z dnia: {activeItem && displayDate(activeItem.FvN_DataDodania)}</Heading>
        <p>Jeżeli znalazłeś błąd w wygenerowanej fakturze możesz ją odrzucić.</p>
        <div className="buttons">
          <Button onClick={resetItemState}>Przerwij</Button>
          <Button onClick={sendRejection}>Odrzuć dokument</Button>
        </div>
      </FvRejectPopup>
      <FvNumberPopup>
        {!fnNumberConfirmation && (
          <>
            <Heading size="xl">Faktura z dnia: {activeItem && displayDate(activeItem.FvN_DataDodania)}</Heading>
            <p>Przechodząc dalej oświadczasz, że faktura została wygenerowana w sposób prawidłowy.</p>
            <p>
              W kolejnym etapie zostaniesz poproszony o wprowadzenie numeru identyfikacyjnego oraz daty wystawienia
              dokumentu.
            </p>
            <div className="buttons">
              <Button onClick={resetItemState}>Przerwij</Button>
              <Button onClick={() => setFnNumberConfirmation(true)}>Uzupełnij numer</Button>
            </div>
          </>
        )}
        {fnNumberConfirmation && (
          <InvoiceNumberForm handleSubmit={sendTemplateNumber} handleReset={resetItemState} activeItem={activeItem} />
        )}
      </FvNumberPopup>

      {!invoicesState.isLoading && !billingsState.isLoading && error && (
        <ErrorPopup fixed>
          <Heading>Wystąpił błąd</Heading>
          <p>{error.message || error}</p>
          <div className="buttons">
            <Button onClick={() => history.goBack()}>Wróć</Button>
          </div>
        </ErrorPopup>
      )}

      {company.current && <Heading>Tryb podglądu dla partnera: {company.current.name}</Heading>}

      <TopSection>
        <OneLineButtonsWrapper>
          <Button onClick={() => setActiveView('invoices')} disabled={activeView === 'invoices'}>
            Faktury
          </Button>
          <Button onClick={() => setActiveView('billings')} disabled={activeView === 'billings'}>
            Rozliczenia
          </Button>
        </OneLineButtonsWrapper>
        {/* <TableSorter>
          <SortItem>
            <span>Typ dokumentu</span>
            <Select small name="docType" value={topSectionState.docType} onChange={handleChange}>
              <option value="">Wszystkie</option>
              <option value="invoice">Samofaktura</option>
              <option value="template">Propozycja</option>
            </Select>
          </SortItem>
          <SortItem>
            <span>Data generowania</span>
            <DateFromTo handleDate={val => handleSpecial(val, 'date')} />
          </SortItem>
          <SortItem>
            <span>Status dokumentu</span>
            <Select small name="status" value={topSectionState.status} onChange={handleChange}>
              <option value="">&nbsp;</option>
              {invoiceStatus.map(status => (
                <option key={status.value} value={status.value}>
                  {status.display}
                </option>
              ))}
            </Select>
          </SortItem>
        </TableSorter> */}
      </TopSection>
      {activeView === 'invoices' ? (
        <InvoiceTable
          headers={getHeaders(userRole).common}
          data={invoicesState.data}
          handleTemplateDownload={handleTemplateDownload}
          handleTemplateNumber={handleTemplateNumber}
          handleTemplateReject={handleTemplateReject}
          handleDownload={handleDownload}
          isLoading={invoicesState.isLoading}
          userRole={userRole}
        />
      ) : (
        <BillingsTable
          headers={getHeaders(userRole).billings}
          data={billingsState.data.rows}
          handleDetails={handleBillingDetails}
          isLoading={billingsState.isLoading}
        />
      )}
      {activeView !== 'invoices' && (
        <BottomSection>
          <BottomItem>Elementów: {pagination.count}</BottomItem>
          <Pagination currentPage={pagination.currentPage} maxPages={pagination.totalPages} handlePage={setPage} />
          <BottomItem>
            <span>Wyświetlaj na stronie:</span>
            <Select small value={pagination.perPage} onChange={handlePerPage}>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="80">80</option>
              <option value="100">100</option>
            </Select>
          </BottomItem>
        </BottomSection>
      )}
    </PageTemplate>
  );
};

PartnerInvoicingPage.defaultProps = {};

PartnerInvoicingPage.propTypes = {
  // Redux state
  invoicesState: PropTypes.instanceOf(Object).isRequired,
  billingsState: PropTypes.instanceOf(Object).isRequired,
  userRole: PropTypes.string.isRequired,
  // Redux actions
  getAllInvoices: PropTypes.func.isRequired,
  getAllBillings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoicesState: state.invoicing.invoices,
  billingsState: state.invoicing.billings,
  userRole: state.user.data.role.slug,
});

const mapDispatchToProps = {
  getAllInvoices,
  getAllBillings,
  setCurrentInvoice,
  setCurrentInvoiceTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerInvoicingPage);
