/* eslint-disable class-methods-use-this */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class InvoicePdf {
  constructor(data) {
    if (!data) throw new Error('Obiekt konfiguracyjny jest wymagany.');

    this.data = data;

    this.pdf = null;
    this.pdfDefinition = null;

    this.style = {
      lineWidth: 0.5,
      lineColor: '#999999',
      tableFill: '#dddddd',
    };
  }

  _createLine(size) {
    const options = {
      x1: 0,
      y1: 0,
      x2: 595 - 2 * 40,
      y2: 0,
      extra: {},
    };

    if (size === 'small') {
      options.x1 = 0;
      options.x2 = 40;
      options.extra = { style: 'tableSeparator' };
    } else if (size === 'header' || size === 'footer') {
      options.x1 = 40;
      options.x2 = 595 - 40;
    }

    return {
      canvas: [
        {
          type: 'line',
          lineWidth: this.style.lineWidth,
          lineColor: this.style.lineColor,
          ...options,
        },
      ],
      ...options.extra,
    };
  }

  _createProductsRows(products) {
    return products.map((product, i) => [
      {
        text: `${i + 1}`,
        style: ['center', 'tableBig', 'tablePadding'],
      },
      {
        text: product.name,
        style: ['left'],
      },
      {
        text: product.quantity,
        style: ['center', 'tableBig', 'tablePadding'],
      },
      {
        text: `${product.quantityUnit}.`,
        style: ['center', 'tableBig', 'tablePadding'],
      },
      {
        text: product.unitPrice,
        style: ['center', 'tableBig', 'tablePadding'],
      },
      {
        text: `${(product.unitPrice * parseInt(product.quantity, 10)).toFixed(2)}`,
        style: ['center', 'tableBig', 'tablePadding'],
      },
      {
        text: product.vatRate,
        style: ['center', 'tableBig', 'tablePadding'],
      },
      {
        text: ((product.vatRate / 100) * product.unitPrice).toFixed(2),
        style: ['center', 'tableBig', 'tablePadding'],
      },
      {
        text: (
          (product.vatRate / 100) * product.unitPrice +
          product.unitPrice * parseInt(product.quantity, 10)
        ).toFixed(2),
        style: ['center', 'tableBig', 'tablePadding'],
      },
    ]);
  }

  _createSummaryRows() {
    return [
      [
        {
          colSpan: 5,
          text: 'Razem',
          style: ['bold', 'right', 'tableBig'],
          border: [false, false, false, false],
        },
        {},
        {},
        {},
        {},
        {
          text: this.data.summaryTable.netto,
          style: ['center', 'bold', 'tableBig'],
        },
        {
          text: '',
          fillColor: '#000000',
        },
        {
          text: this.data.summaryTable.vat,
          style: ['center', 'bold', 'tableBig'],
        },
        {
          text: this.data.summaryTable.burtto,
          style: ['center', 'bold', 'tableBig'],
        },
      ],
      [
        {
          colSpan: 5,
          text: 'w tym',
          style: ['bold', 'right', 'tableBig'],
          border: [false, false, false, false],
        },
        {},
        {},
        {},
        {},
        {
          text: this.data.summaryTable.netto,
          style: ['center', 'tableBig'],
        },
        {
          text: '23',
          style: ['center', 'tableBig'],
        },
        {
          text: this.data.summaryTable.vat,
          style: ['center', 'tableBig'],
        },
        {
          text: this.data.summaryTable.burtto,
          style: ['center', 'tableBig'],
        },
      ],
    ];
  }

  _createFooter() {
    const that = this;
    return (currentPage, pageCount) => {
      return [that._createLine('footer'), { text: currentPage.toString() + '/' + pageCount, style: 'footer' }];
    };
  }

  _createMainTable() {
    return {
      table: {
        widths: ['4%', '32%', '5%', '5%', '16%', '13%', '5%', '7%', '13%'],
        headerRows: 2,
        body: [
          [
            {
              rowSpan: 2,
              text: 'Lp.',
              style: ['tableHeadAlign', 'tableHeadCenter'],
            },
            {
              rowSpan: 2,
              text: 'Nazwa towaru lub usługi',
              style: ['tableHeadAlign', 'tableHeadCenter'],
            },
            {
              rowSpan: 2,
              text: 'Ilość',
              style: ['tableHeadCenter'],
            },
            {
              rowSpan: 2,
              text: 'J.m.',
              style: ['tableHeadCenter'],
            },
            {
              rowSpan: 2,
              text: 'Cena jednostki\nnetto\n(zł)',
              style: ['tableHeadCenter'],
            },
            {
              rowSpan: 2,
              text: 'Wartość\nbez podatku\n(zł)',
              style: ['tableHeadCenter'],
            },
            { colSpan: 2, text: 'Podatek', style: ['tableHeadCenter'] },
            {},
            {
              rowSpan: 2,
              text: 'Wartość\nz podatkiem\n(zł)',
              style: ['tableHeadCenter'],
            },
          ],
          [
            '',
            '',
            '',
            '',
            '',
            '',
            {
              text: '%',
              style: ['tableHeadCenter'],
            },
            {
              text: 'Kwota\n(zł)',
              style: ['tableHeadCenter'],
            },
            '',
          ],
          ...this._createProductsRows(this.data.products),
          ...this._createSummaryRows(this.data.summary),
        ],
      },
      layout: {
        hLineWidth: function() {
          return 0.5;
        },
        vLineWidth: function() {
          return 0.5;
        },
      },
      style: ['table', 'spaceBottomSmall'],
    };
  }

  _createBottomPart() {
    return [
      {
        text: [{ text: 'Do zapłaty ogółem: ', style: ['bold'] }, { text: this.data.totalMoney }],
      },
      {
        text: [{ text: 'Do zapłaty słownie: ' }, { text: `${this.data.totalMoneyString}` }],
        style: 'spaceBottomSmall',
      },
      {
        text: [{ text: 'Sposób zapłaty: ' }, { text: `${this.data.paymentMethod}` }],
      },
      { text: 'Konto i termin płatności: wg warunków z umowy', style: 'spaceBottomMedium' },
      {
        text: [
          { text: 'Wystawił: ', style: ['bold'] },
          { text: 'Faktura wystawiona w imieniu i na rzecz podatnika przez Polskie ePłatności Sp. z o.o. zgodnie z zawartą umową.' },
        ],
      },
    ];
  }

  _createContent() {
    return [
      {
        text: `Faktura nr: ${this.data.invoiceNumber}`,
        style: ['title', 'center'],
      },
      {
        text: `Data wystawienia: ${this.data.createDate}`,
        style: ['center', 'spaceBottomSmall'],
      },
      {
        text: `Data zakończenia dostawy/wykonania usługi: ${this.data.releaseDate}`,
        style: ['center', 'spaceBottom'],
      },
      {
        columns: [{ text: '' }, { text: 'samofakturowanie', style: 'bold' }],
        style: 'spaceBottomSmall',
      },
      {
        columns: [
          {
            width: '50%',
            stack: [
              { text: 'Sprzedawca', style: 'bold' },
              { text: this.data.company.name },
              { text: `${this.data.company.zipCode} ${this.data.company.city}` },
              { text: `ul. ${this.data.company.street}` },
              { text: `NIP: ${this.data.company.nip}` },
            ],
          },
          {
            width: '50%',
            stack: [
              { text: 'Nabywca', style: 'bold' },
              { text: this.data.purchaser.name },
              { text: this.data.purchaser.street },
              { text: `${this.data.purchaser.zipCode} ${this.data.purchaser.city}` },
              { text: `NIP: ${this.data.purchaser.nip}` },
            ],
          },
        ],
        style: 'spaceBottomBig',
      },
      this._createMainTable(),
      this._createBottomPart(),
    ];
  }

  _createDefinition() {
    return {
      info: {
        title: `Faktura nr: ${this.data.invoiceNumber}`,
        subject: `Faktura dla ${this.data.company.name}`,
        author: 'PeP - Samofakturowanie',
      },
      pageSize: 'A4',
      footer: this._createFooter(),
      content: this._createContent(),
      defaultStyle: {
        fontSize: 8,
        lineHeight: 1.2,
      },
      styles: {
        header: {
          margin: [40, 10],
        },
        footer: {
          margin: [40, 12],
          alignment: 'right',
          fontSize: 12,
        },
        title: {
          fontSize: 10,
          bold: true,
          margin: [0, 24, 0, 12],
        },
        table: {
          fontSize: 8,
          alignment: 'right',
        },
        tableBig: {
          fontSize: 10,
        },
        tablePadding: {
          margin: [0, 5, 0, 5],
        },
        tableHeadAlign: {
          margin: [0, 18, 0, 0],
        },
        tableHeadCenter: {
          alignment: 'center',
          fontSize: 9,
          bold: true,
          margin: [0, 2, 0, 0],
        },
        tableSeparator: {
          margin: [0, 1, 0, 2],
        },
        productRow: {
          margin: [0, 5, 0, 5],
        },
        left: {
          alignment: 'left',
        },
        center: {
          alignment: 'center',
        },
        right: {
          alignment: 'right',
        },
        bold: {
          bold: true,
        },
        spaceBottomBig: {
          margin: [0, 0, 0, 60],
        },
        spaceBottomMedium: {
          margin: [0, 0, 0, 48],
        },
        spaceBottom: {
          margin: [0, 0, 0, 24],
        },
        spaceBottomSmall: {
          margin: [0, 0, 0, 12],
        },
      },
    };
  }

  getPdf() {
    return this.pdf;
  }

  getDefinition() {
    return this.pdfDefinition;
  }

  createDefinition() {
    const definition = this._createDefinition();
    this.pdfDefinition = definition;
  }

  build() {
    this.createDefinition();
    this.pdf = pdfMake.createPdf(this.pdfDefinition);
    return this.pdf;
  }

  download() {
    if (!this.pdf) throw new Error('Brak wygenerowanego pdf');

    try {
      this.pdf.download();
    } catch (err) {
      throw new Error(err);
    }
  }
}
