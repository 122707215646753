import React from 'react';
import Heading from 'components/UI/Heading';
import styled, { css } from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'routes';
import { clientService } from 'services/client';
import { useDoubleConfirmPopup } from 'utils/hooks';
import { colors, font } from 'theme/styles';
import { Row, Col } from 'components/UI/Structure';
import placeholder from 'assets/avatar-placeholder.png';
import Button from 'components/UI/Button';

const gusPlaceholder = 'Brak';

const ClientInfo = ({ clientData, userRole }) => {
  const history = useHistory();

  const [Popup, popupTrigger] = useDoubleConfirmPopup({
    firstQuestion: `Czy chcesz zapomnieć klienta ${clientData.companyName} ?`,
    secondQuestion: `Zmiana jest nieodwracalna, klient ${clientData.companyName} oraz wszystkie zgłoszone leady zostaną zapomniane.\n Czy jesteś pewien?`,
    submitHandler: () => clientService.forgetClient(clientData.id),
    redirectAfter: '/',
  });

  return (
    <>
      <Popup />
      <InnerHeader>
        <img src={clientData.avatarUrl || placeholder} alt="Avatar" />
        <Heading centered>{clientData.companyName}</Heading>
      </InnerHeader>
      <Divider />
      <InnerBody>
        <Row>
          <Col>
            <Label>NIP:</Label>
            {['admin', 'dsp'].includes(userRole) ? (
              <Text
                style={{ color: colors.orange, cursor: 'pointer' }}
                onClick={() => history.push(routes.adminClarity, { nip: clientData.nip })}
              >
                {clientData.nip}
                <FontAwesomeIcon style={{ marginLeft: '0.5rem' }} size="sm" title="Wyszukaj w CRM" icon="search" />
              </Text>
            ) : (
              <Text>{clientData.nip}</Text>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Telefon:</Label>
            <Text>{clientData.phone}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Kod pocztowy:</Label>
            <Text>{clientData.postCode}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Utworzono:</Label>
            <Text>{moment(clientData.createdAt).format('DD.MM.YYYY - HH:mm')}</Text>
          </Col>
          <Col>
            <Label>Ost. aktualizacja:</Label>
            <Text>
              {clientData.updatedAt && clientData.updatedAt !== clientData.createdAt
                ? moment(clientData.updatedAt).format('DD.MM.YYYY - HH:mm')
                : '---'}
            </Text>
          </Col>
        </Row>
        <Divider heading="Dane z GUS" />
        <Row>
          <Col>
            <Label>Pełna nazwa:</Label>
            <Text> {clientData.gus?.name || gusPlaceholder} </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>REGON:</Label>
            <Text>
              {' '}
              {clientData.gus?.regon || clientData.gus?.regon14 || clientData.gus?.regon9 || gusPlaceholder}{' '}
            </Text>
          </Col>
          <Col>
            <Label>Ulica:</Label>
            <Text> {clientData.gus?.street || gusPlaceholder} </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Nr Budynku:</Label>
            <Text> {clientData.gus?.house_no || gusPlaceholder} </Text>
          </Col>
          <Col>
            <Label>Nr Lokalu: </Label>
            <Text> {clientData.gus?.flaat_no || gusPlaceholder} </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Kod pocztowy:</Label>
            <Text> {clientData.gus?.postal_code || gusPlaceholder} </Text>
          </Col>
          <Col>
            <Label>Miasto:</Label>
            <Text> {clientData.gus?.city || gusPlaceholder} </Text>
          </Col>
        </Row>
        {['admin'].includes(userRole) && (
          <>
            <Divider />
            <Row>
              <Button small gray centered onClick={popupTrigger}>
                Zapomnij klienta
              </Button>
            </Row>
          </>
        )}
      </InnerBody>
    </>
  );
};

ClientInfo.propTypes = {
  clientData: PropTypes.instanceOf(Object).isRequired,
  userRole: PropTypes.string.isRequired,
};

export default ClientInfo;

const InnerHeader = styled.div`
  text-align: center;

  img {
    max-width: 30%;
    display: inline-block;
    border-radius: 50%;
    margin: 20px auto;
  }

  ${Heading} {
    max-width: 75%;
  }
`;

const InnerBody = styled.div`
  p {
    margin: 0;
  }

  ${Row} {
    margin-left: 0;
    margin-right: 0;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const Label = styled.p`
  letter-spacing: 0.5px;
  color: ${colors.mediumGray};
  font-size: ${font.size.s};
`;

export const Text = styled.p`
  font-weight: ${font.weight.semibold};
  color: ${colors.darkBlue};
  font-size: ${font.size.m};
`;

const Divider = styled.span`
  position: relative;
  display: block;
  border: none;
  border-top: 1px dashed ${colors.orange};
  margin-top: 30px;
  margin-bottom: 30px;

  ${({ heading }) =>
    heading &&
    css`
      &::before {
        content: '${heading}';
        display: inline-block;
        background-color: ${colors.white};
        color: ${colors.orange};
        font-weight: ${font.weight.semibold};
        padding: 0 0.5em;
        position: absolute;
        left: 50%;
        top: calc(50% - 2px);
        transform: translate(-50%, -50%);
      }
  `}
`;
