import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, FieldArray } from 'formik';
import { removeNullsAndEmpty } from 'utils/helpers';
import { suggestionsService } from 'services/suggestions';
import Checkbox from 'components/UI/Checkbox';
import Select from 'components/UI/Select';
import Button from 'components/UI/Button';
import InputAuto from 'components/UI/InputAuto';
import { adminService } from 'services/admin';

const LeadsFilter = ({
  setPickedProducts,
  setPickedStructure,
  setLocalQuery,
  products,
  roles,
  currentRole,
  structures,
}) => {
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [fetchedParnetrs, setFetchedPartners] = useState([]);

  const activeProducts = products.filter(product => product.isVisible === '1');

  const fetchUsers = inputValue => {
    if (inputValue.length <= 2) return;
    suggestionsService
      .getUsersSuggestions(inputValue)
      .then(payload => setFetchedUsers(payload.data))
      .catch(err => {
        console.log(err);
      });
  };

  const fetchCompanies = inputValue => {
    if (inputValue.length <= 2) return;
    adminService
      .getAllCompanies({
        limit: 10,
        page: 1,
        showDeleted: false,
        search: inputValue,
      })
      .then(payload => setFetchedPartners(payload.data.rows))
      .catch(err => {
        console.log(err);
      });
  };

  const onSubmit = values => {
    const pickedProducts = values.products;
    const { roleId, userId, partnerId } = values;
    const local = { roleId, userId, partnerId };
    setPickedProducts(pickedProducts.length === 0 ? null : JSON.stringify([...pickedProducts]));
    setPickedStructure(values.structureId);
    setLocalQuery(removeNullsAndEmpty(local));
  };

  return (
    <Formik
      initialValues={{
        products: [],
        roleId: '',
        userId: '',
        partnerId: '',
        structureId: '',
      }}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
        <StyledForm>
          <SearchWrapper>
            {['admin', 'dsp', 'guest'].includes(currentRole) && (
              <InputAuto
                suggestions={fetchedUsers}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                pickProp="id"
                pickDisplay={['name', 'surname']}
                display={['name', 'surname', 'email']}
                name="userId"
                type="text"
                onChange={e => fetchUsers(e.target.value)}
                onBlur={handleBlur}
                onlyFromList
                label="Szukaj użytkownika"
                value={values.userId || ''}
              />
            )}
            {['admin', 'dsp', 'guest'].includes(currentRole) && (
              <InputAuto
                suggestions={fetchedParnetrs}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                pickProp="id"
                pickDisplay={['name']}
                display={['name']}
                name="partnerId"
                type="text"
                onChange={e => fetchCompanies(e.target.value)}
                onBlur={handleBlur}
                label="Szukaj partnera"
                onlyFromList
                value={values.partnerId || ''}
              />
            )}
            {['admin', 'guest'].includes(currentRole) && (
              <Select
                name="roleId"
                value={values.roleId || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Rola użytkownika"
              >
                <option key="default" value="">
                  &nbsp;
                </option>
                {roles
                  .filter(role => ['dsp', 'partner', 'merchantPep'].includes(role.slug))
                  .map(role => (
                    <option key={role.id} value={role.id} data-slug={role.slug}>
                      {role.name}
                    </option>
                  ))}
              </Select>
            )}
            {['admin', 'dsp', 'guest'].includes(currentRole) && (
              <Select
                name="structureId"
                value={values.structureId || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Struktura"
              >
                <option key="default" value="">
                  Cała Polska
                </option>
                {structures
                  .filter(el => el.region !== 'Cała Polska')
                  .map(structure => (
                    <option key={structure.id} value={structure.id}>
                      {structure.region}
                    </option>
                  ))}
              </Select>
            )}
          </SearchWrapper>
          <FieldArray
            name="products"
            render={arrayHelpers => (
              <ProductsList>
                {activeProducts.map(item => (
                  <li key={item.id} className={item.active === '1' ? 'active' : ''}>
                    <Checkbox
                      key={item.id}
                      name="products"
                      onChange={e => {
                        if (e.target.checked) arrayHelpers.push(item.id);
                        else arrayHelpers.remove(values.products.indexOf(item.id));
                      }}
                      onBlur={handleBlur}
                      label={item.name}
                      value={item.id}
                      checked={values.products ? values.products.includes(item.id) : false}
                    />
                  </li>
                ))}
              </ProductsList>
            )}
          />
          <ButtonWrapper>
            <Button style={{ margin: '0 auto' }} type="submit">
              Filtruj
            </Button>
          </ButtonWrapper>
        </StyledForm>
      )}
    </Formik>
  );
};

LeadsFilter.defaultProps = {
  roles: [],
  products: [],
  structures: [],
};
LeadsFilter.propTypes = {
  roles: PropTypes.instanceOf(Array),
  products: PropTypes.instanceOf(Array),
  structures: PropTypes.instanceOf(Array),
  currentRole: PropTypes.string.isRequired,
  setPickedProducts: PropTypes.func.isRequired,
  setPickedStructure: PropTypes.func.isRequired,
  setLocalQuery: PropTypes.func.isRequired,
};

export default LeadsFilter;

// styled components
const StyledForm = styled(Form)`
  padding-top: 1rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem 2rem 2rem;

  & > * {
    max-width: 20rem;
    width: 100%;
  }

  @media (min-width: 600px) {
    & > * {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 3rem;
`;

const ProductsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none;

  max-height: 300px;
  overflow-y: auto;

  li {
    margin: 0;
    padding: 0 2rem;
    width: 100%;

    overflow: hidden;
    white-space: nowrap;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(.active) label {
      color: #00000080;
    }

    @media (min-width: 600px) {
      flex: 0 1 50%;
    }
    @media (min-width: 769px) and (max-width: 1000px) {
      flex: 0 1 100%;
    }
    @media (min-width: 1001px) and (max-width: 1300px) {
      flex: 0 1 50%;
    }
    @media (min-width: 1301px) {
      flex: 0 1 33%;
    }
  }
`;
