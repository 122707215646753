/* eslint-disable no-param-reassign */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { ResponsiveLine } from '@nivo/line';
import { mapStatusObjShort } from 'config/constants';
import { colors, font } from 'theme/styles';

const LinearChart = ({ data, date }) => {
  const [localData, setLocalData] = useState(data);
  const [legend, setLegend] = useState({});

  useEffect(() => {
    const tmp = data.reduce((prev, curr) => {
      prev[curr.id] = curr.id === mapStatusObjShort.all;
      return prev;
    }, {});
    setLegend(tmp);
  }, [data]);

  const config = useMemo(() => {
    if (!date) return {};
    const diff = moment(date[1]).diff(moment(date[0]), 'days');
    switch (true) {
      case diff <= 7:
        return {
          precision: 'day',
          tickValues: 'every 1 day',
          displayFormat: '%d',
        };
      case diff <= 31:
        return {
          precision: 'day',
          tickValues: 'every 2 days',
          displayFormat: '%d',
        };
      case diff > 31:
      default:
        return {
          precision: 'month',
          tickValues: 'every 1 month',
          displayFormat: '%m',
        };
    }
  }, [date]);

  const legendClickHandler = ev => {
    const { name, checked } = ev.target;

    const entries = Object.entries(legend);
    const clicked = entries.find(el => el[0] === name);

    // Jeżeli ostatni zaznaczony i chcemy go oznaczyć to:
    if (entries.filter(e => e[1] === true).length === 1 && clicked[1]) return;

    setLegend(pl => ({ ...pl, [name]: checked }));
  };

  useEffect(() => {
    if (Object.keys(legend).length === 0) return;

    // Kopia, bo dzieją się dziwne rzeczy...
    const dataCopy = JSON.parse(JSON.stringify(data));
    setLocalData(dataCopy.filter(el => legend[el.id]));

    // setLocalData(
    //   dataCopy.map(el => {
    //     if (!legend[el.id]) {
    //       if (el.data.length === 0) {
    //         el.data = JSON.parse(el.dataCopy);
    //         delete el.dataCopy;
    //       } else {
    //         el.dataCopy = JSON.stringify(el.data);
    //         el.data = [];
    //       }
    //     }
    //     return { ...el };
    //   }),
    // );
  }, [legend, data]);

  return (
    <Wrapper>
      <ChartWrapper>
        <ResponsiveLine
          key={Math.random()}
          data={localData}
          colors={localData.map(el => el.color)}
          margin={{ top: 40, right: 20, bottom: 40, left: 40 }}
          enableSlices="x"
          enableGridX={false}
          // gridYValues={ticks}
          curve="monotoneX"
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: config.precision,
          }}
          xFormat="time:%Y-%m-%d"
          axisBottom={{
            format: config.displayFormat,
            tickValues: config.tickValues,
            legend: 't',
            legendPosition: 'end',
            legendOffset: 15,
          }}
          yScale={{
            type: 'linear',
          }}
          axisLeft={{
            orient: 'left',
            // tickValues: ticks,
            legend: 'Ilość',
            legendPosition: 'middle',
            legendOffset: -35,
          }}
          enablePointLabel={false}
          pointSize={6}
          pointBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
          }}
          useMesh={false}
          debugMesh
          animate={false}
        />
      </ChartWrapper>
      <LegendWrapper>
        {data.map(el => (
          <CheckboxLabel key={el.id} color={el.color}>
            <input type="checkbox" name={el.id} onChange={legendClickHandler} checked={legend[el.id] || false} />
            <span />
            {el.id}
          </CheckboxLabel>
        ))}
      </LegendWrapper>
    </Wrapper>
  );
};

LinearChart.defaultProps = {
  date: [],
};

LinearChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.arrayOf(PropTypes.string),
};

export default LinearChart;

// styled components

const Wrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  border-radius: 12px;
`;

const ChartWrapper = styled.div`
  height: 30rem;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
`;

const CheckboxLabel = styled.label`
  display: block;
  font-size: ${font.size.s};
  color: ${colors.black};
  line-height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  padding-left: 2rem;

  input {
    display: none;
    &:checked + span::after {
      opacity: 1;
    }
  }

  & > span {
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background: ${colors.white};
    border: 1px solid ${props => props.color || '#eee'};
    border-radius: 50%;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${props => props.color || '#eee'};
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }
  }
`;
