/* eslint-disable no-param-reassign */
import { adminService } from 'services/admin';
import { sortRoles, sortActiveThenCreatedAt, sortAlphabetically, mapUsersToCorespondingPartner } from 'utils/helpers';
import * as types from './types';

export const setClearityLoading = (state) => (dispatch) => dispatch({ type: types.SET_CLEARITY_LOADING, payload: state });
export const setClearityError = (error) => (dispatch) => dispatch({ type: types.SET_CLEARITY_ERROR, payload: error });
export const clearClearity = () => (dispatch) => dispatch({ type: types.CLEAR_CLEARITY });
export const clearClearityCrmCheck = () => (dispatch) => dispatch({ type: types.CLEAR_CLEARITY_CRM_CHECK });
export const clearCurrentAnalyticsDetails = () => (dispatch) =>
  dispatch({ type: types.CLEAR_CURRENT_MERCHANT_ANALYTICS });

export const getSpOptions = (data) => (dispatch) => {
  dispatch({ type: types.GET_SP_OPTIONS_REQUEST });
  return adminService
    .getSpOptions(data)
    .then((payload) => {
      dispatch({ type: types.GET_SP_OPTIONS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_SP_OPTIONS_FAILURE, err });
    });
};

export const graylogCheck = (id) => (dispatch) => {
  dispatch({ type: types.GET_GRAYLOG_LOG_REQUEST });
  return adminService
    .getGraylog(id)
    .then((payload) => {
      dispatch({ type: types.GET_GRAYLOG_LOG_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_GRAYLOG_LOG_FAILURE, err });
    });
};

export const crmCheck = (nip) => (dispatch) => {
  dispatch({ type: types.GET_CRM_CHECK_REQUEST });
  return adminService
    .crmCheck(nip)
    .then((payload) => {
      dispatch({ type: types.GET_CRM_CHECK_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_CRM_CHECK_FAILURE, err });
    });
};

export const getMerchantLeadsAnalyticsDetails = (id, params) => (dispatch) => {
  dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_REQUEST });
  return adminService
    .getMerchantAnalytics(id, params)
    .then((payload) => {
      dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_DETAILS_FAILURE, err });
    });
};

export const getMerchantsLeadsAnalytics = (params) => (dispatch) => {
  dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_REQUEST });
  return adminService
    .getMerchantsAnalytics(params)
    .then((payload) => {
      dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_FAILURE, err });
    });
};

export const getMerchantsLeadsAnalyticsPh = (params) => (dispatch) => {
  dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_PH_REQUEST });
  return adminService
    .getMerchantAnalyticsPhs(params)
    .then((payload) => {
      const rows = [];
      const allHeaders = Object.keys(payload.data.leadsByMonthAndUser).reverse();
      const allUsersEntries = Object.entries(payload.data.totalUsers);
      const dataHeaderEntries = Object.entries(payload.data.leadsByMonthAndUser);
      const allButEmptyHeaders = allHeaders.reduce((acc, value) => ({ ...acc, [value]: 0 }), {});
      // Create array of all users
      allUsersEntries.forEach(([userId, userRest]) => {
        // Prefill all months with 0 values
        const headers = { ...allButEmptyHeaders };
        // Find out if some months have a user included, if so count numLeads in this period
        dataHeaderEntries.forEach(([entryDate, entryValue]) => {
          if (entryValue[userId]) headers[entryDate] += entryValue[userId].numLeads;
        });
        rows.push({ id: userId, ...userRest, fullname: `${userRest.name} ${userRest.surname}`, headers });
      });
      payload.data.rows = sortAlphabetically(rows, 'fullname').reverse()
      payload.data.headers = allHeaders;
      dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_PH_SUCCESS, payload });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: types.GET_MERCHANTS_LEADS_ANALYTICS_PH_FAILURE, err });
    });
};

export const setCurrentCronError = (error) => (dispatch) =>
  dispatch({ type: types.SET_CURRENT_CRON_ERROR, payload: error });

export const setCurrentCronLoading = (loading) => (dispatch) =>
  dispatch({ type: types.SET_CURRENT_CRON_LOADING, payload: loading });

export const clearCurrentCron = () => (dispatch) => dispatch({ type: types.CLEAR_CURRENT_CRON });

export const getCurrentCronLogs = (id, query) => (dispatch) => {
  dispatch({ type: types.GET_CRON_LOGS_REQUEST });
  return adminService
    .getCronLogs(id, query)
    .then((payload) => {
      dispatch({ type: types.GET_CRON_LOGS_SUCCESS, payload: payload.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_CRON_LOGS_FAILURE, err });
    });
};

export const getCurrentCron = (id) => (dispatch) => {
  dispatch({ type: types.GET_CRON_REQUEST });
  return adminService
    .getCron(id)
    .then((payload) => {
      dispatch({ type: types.GET_CRON_SUCCESS, payload: payload.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_CRON_FAILURE, err });
    });
};

export const setCronsListError = (error) => (dispatch) => dispatch({ type: types.SET_ALL_CRONS_ERROR, payload: error });
export const setCronsListLoading = (loading) => (dispatch) =>
  dispatch({ type: types.SET_ALL_CRONS_LOADING, payload: loading });
export const clearCronList = () => (dispatch) => dispatch({ type: types.CLEAR_ALL_CRONS });

export const getCronsList = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_CRONS_REQUEST });

  return adminService
    .getCronsList()
    .then((payload) => {
      dispatch({ type: types.GET_ALL_CRONS_SUCCESS, payload: payload.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_CRONS_FAILURE, err });
    });
};

export const getCompanyAnalytics = (id, query) => (dispatch) => {
  dispatch({ type: types.GET_COMPANY_ANALYTICS_REQUEST });
  return adminService
    .getCompanyAnalysis(id, query)
    .then((payload) => {
      dispatch({ type: types.GET_COMPANY_ANALYTICS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_COMPANY_ANALYTICS_FAILURE, err });
    });
};

export const getCountyAnalytics = (query) => (dispatch) => {
  dispatch({ type: types.GET_COUNTY_ANALYTICS_REQUEST });
  return adminService
    .getCountyAnalytics(query)
    .then((payload) => {
      payload.data = sortAlphabetically(payload.data, 'name');
      dispatch({ type: types.GET_COUNTY_ANALYTICS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_COUNTY_ANALYTICS_FAILURE, err });
    });
};

export const getEmailTemplates = () => (dispatch) => {
  dispatch({ type: types.GET_EMAIL_TEMPLATES_REQUEST });

  return adminService
    .getEmailTemplates()
    .then((payload) => {
      payload.data = payload.data.map((email) => ({
        ...email,
        available_vars: JSON.parse(JSON.parse(email.available_vars)),
      }));
      sortAlphabetically(payload.data, 'name');
      dispatch({ type: types.GET_EMAIL_TEMPLATES_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_EMAIL_TEMPLATES_FAILURE, err });
    });
};

export const getNotifications = () => (dispatch) => {
  dispatch({ type: types.GET_NOTIFICATIONS_REQUEST });
  return adminService
    .getNotifications()
    .then((payload) => {
      dispatch({ type: types.GET_NOTIFICATIONS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_NOTIFICATIONS_FAILURE, err });
    });
};

export const getAllApiKeys =
  (perPage = 20, page = 1, query) =>
  (dispatch) => {
    dispatch({ type: types.GET_ALL_API_KEYS_REQUEST });
    return adminService
      .getAllApiKeys(perPage, page, query)
      .then((payload) => {
        payload.data.rows.sort(sortActiveThenCreatedAt);
        dispatch({ type: types.GET_ALL_API_KEYS_SUCCESS, payload });
      })
      .catch((err) => {
        dispatch({ type: types.GET_ALL_API_KEYS_FAILURE, err });
      });
  };

export const getApiKey = (id) => (dispatch) => {
  dispatch({ type: types.GET_SINGLE_API_KEY_REQUEST });
  return adminService
    .getApiKey(id)
    .then((payload) => {
      dispatch({ type: types.GET_SINGLE_API_KEY_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_SINGLE_API_KEY_FAILURE, err });
    });
};

export const getStructures = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_STRUCTURES_REQUEST });
  return adminService
    .getAllStructures()
    .then((payload) => {
      dispatch({ type: types.GET_ALL_STRUCTURES_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_STRUCTURES_FAILURE, err });
    });
};

export const getPepStructures = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_PEP_STRUCTURES_REQUEST });
  return adminService
    .getAllPepStructures()
    .then((payload) => {
      dispatch({ type: types.GET_ALL_PEP_STRUCTURES_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_PEP_STRUCTURES_FAILURE, err });
    });
};

export const getRoles = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_ROLES_REQUEST });
  return adminService
    .getAllRoles()
    .then((payload) => {
      payload.data.sort(sortRoles);
      dispatch({ type: types.GET_ALL_ROLES_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_ROLES_FAILURE, err });
    });
};

export const getCounties = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_COUNTIES_REQUEST });
  return adminService
    .getAllCounties()
    .then((payload) => {
      dispatch({ type: types.GET_ALL_COUNTIES_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_COUNTIES_FAILURE, err });
    });
};

export const getAllUsers =
  (perPage = 20, page = 1, query) =>
  (dispatch) => {
    dispatch({ type: types.GET_ALL_USERS_REQUEST });
    return adminService
      .getAllUsers(perPage, page, query)
      .then((payload) => {
        dispatch({ type: types.GET_ALL_USERS_SUCCESS, payload });
      })
      .catch((err) => {
        dispatch({ type: types.GET_ALL_USERS_FAILURE, err });
      });
  };

export const getUser = (id) => (dispatch) => {
  dispatch({ type: types.GET_USER_REQUEST });
  return adminService
    .getUser(id)
    .then((payload) => {
      dispatch({ type: types.GET_USER_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_USER_FAILURE, err });
    });
};

export const getCompaniesSuggestions = () => (dispatch) => {
  dispatch({ type: types.GET_COMPANIES_SUGGESTIONS_REQUEST });
  return adminService
    .getAllCompanies()
    .then((payload) => {
      dispatch({ type: types.GET_COMPANIES_SUGGESTIONS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_COMPANIES_SUGGESTIONS_FAILURE, err });
    });
};

//  VACATION

export const getVacations = (query) => (dispatch) => {
  dispatch({ type: types.GET_ALL_VACATIONS_REQUEST });
  return adminService
    .getVacations(query)
    .then((payload) => {
      dispatch({ type: types.GET_ALL_VACATIONS_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_VACATIONS_FAILURE, err });
    });
};

export const getVacation = (id) => (dispatch) => {
  dispatch({ type: types.GET_VACATION_REQUEST });
  return adminService
    .getVacation(id)
    .then((payload) => {
      dispatch({ type: types.GET_VACATION_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_VACATION_FAILURE, err });
    });
};

export const setCurrentVacation = (vacation) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_VACATION, payload: vacation });
};

export const clearCurrentVacation = () => (dispatch) => {
  dispatch({ type: types.CLEAR_CURRENT_VACATION });
};

//  VACATION END

export const getAllCompanies = (query) => (dispatch) => {
  dispatch({ type: types.GET_ALL_COMPANIES_REQUEST });
  return adminService
    .getAllCompanies(query)
    .then((payload) => {
      dispatch({ type: types.GET_ALL_COMPANIES_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ALL_COMPANIES_FAILURE, err });
    });
};

export const getCompany = (id) => (dispatch) => {
  dispatch({ type: types.GET_COMPANY_REQUEST });
  return adminService
    .getCompany(id)
    .then((payload) => {
      dispatch({ type: types.GET_COMPANY_SUCCESS, payload });
    })
    .catch((err) => {
      dispatch({ type: types.GET_COMPANY_FAILURE, err });
    });
};

export const getCompanyUsers = (id, data) => (dispatch) => {
  dispatch({ type: types.GET_COMPANY_USERS_REQUEST });
  return adminService
    .getCompanyUsers(id, data)
    .then((payload) => {
      const modifiedUsers = mapUsersToCorespondingPartner(payload.data);
      dispatch({ type: types.GET_COMPANY_USERS_SUCCESS, payload: modifiedUsers });
    })
    .catch((err) => {
      dispatch({ type: types.GET_COMPANY_USERS_FAILURE, err });
    });
};

export const setCurrentCompany = (company) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_COMPANY, payload: company });
};

export const clearCurrentUser = () => (dispatch) => {
  dispatch({ type: types.CLEAR_CURRENT_USER });
};

export const clearCurrentCompany = () => (dispatch) => {
  dispatch({ type: types.CLEAR_CURRENT_COMPANY });
};

export const clearAdmin = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ADMIN });
};
