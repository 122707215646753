/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import { clientPartnerService } from 'services/clientPartner';
import { diff } from 'json-diff';
import { sortByCreatedAt } from 'utils/helpers';
import * as types from './types';

export const getClientPartnerInfo = id => dispatch => {
  dispatch({ type: types.GET_CLIENT_PARTNER_INFO_REQUEST });
  return clientPartnerService
    .getClientPartnerInfo(id)
    .then(payload => {
      dispatch({ type: types.GET_CLIENT_PARTNER_INFO_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_CLIENT_PARTNER_INFO_FAILURE, err });
    });
};

export const getClientPartnerHistory = id => dispatch => {
  dispatch({ type: types.GET_CLIENT_PARTNER_HISTORY_REQUEST });
  return clientPartnerService
    .getClientPartnerHistory(id)
    .then(payload => {
      payload.data = payload.data.sort(sortByCreatedAt).map(log => {
        const prevData = JSON.parse(JSON.parse(log.prevData));
        const nextData = JSON.parse(JSON.parse(log.currentData));
        const extra = JSON.parse(JSON.parse(log.extra));
        return {
          ...log,
          prevData,
          nextData,
          extra,
          diff: diff(prevData, nextData),
        };
      });
      dispatch({ type: types.GET_CLIENT_PARTNER_HISTORY_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_CLIENT_PARTNER_HISTORY_FAILURE, err });
    });
};

export const getClientPartnerNotes = id => dispatch => {
  dispatch({ type: types.GET_CLIENT_PARTNER_NOTES_REQUEST });
  return clientPartnerService
    .getClientPartnerNotes(id)
    .then(payload => {
      dispatch({ type: types.GET_CLIENT_PARTNER_NOTES_SUCCESS, payload });
    })
    .catch(err => {
      dispatch({ type: types.GET_CLIENT_PARTNER_NOTES_FAILURE, err });
    });
};
