import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getAllProducts } from 'redux/product';
import { challengeService } from 'services/challenge';
import { getAllChallenges, setCurrentChallenge, clearCurrentChallenge } from 'redux/challenge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopSection } from 'components/layout/Tables/TableComponents';
import { breakpoints } from 'theme/styles';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import SidePanel from 'components/layout/SidePanel';
import Loading from 'components/UI/Loading';
import ErrorPopup from 'components/layout/ErrorPopup';
import AddChallenge from 'components/forms/Admin/AddChallenge';
import EditChallenge from 'components/forms/Admin/EditChallenge';
import ChallengesTable from 'components/layout/Tables/ChallengesTable';
import SuccessPopup from 'components/layout/SuccessPopup';
import Heading from 'components/UI/Heading';

const headers = [
  { displayName: 'Nazwa', property: 'name' },
  { displayName: 'Data rozpoczęcia', property: 'dateFrom' },
  { displayName: 'Data zakończenia', property: 'dateTo' },
  { displayName: 'Specjalne', property: 'special' },
  { displayName: 'Typ', property: 'type' },
];

const AdminChallengesPage = ({
  getAllChallenges,
  getAllProducts,
  clearCurrentChallenge,
  setCurrentChallenge,
  productsData,
  isLoadingProducts,
  errorProducts,
  challengesData,
  isLoadingChallenges,
  errorChallenges,
  currentChallenge,
}) => {
  const [isNewchallengeVisible, setNewchallengeVisible] = useState(false);
  const [isEditChallengeVisible, setEditChallengeVisible] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);
  const [deleteError, setDeleteError] = useState('');

  const activeProducts = productsData.filter(product => product.active === '1' && product.isVisible === '1');

  useEffect(() => {
    getAllChallenges();
    getAllProducts();
  }, [getAllProducts, getAllChallenges]);

  const toggleNewchallengePanel = () => setNewchallengeVisible(state => !state);

  const closeEditchallengePanel = () => {
    setEditChallengeVisible(false);
    clearCurrentChallenge();
  };

  const openEditchallengePanel = pickedChallenge => {
    setCurrentChallenge(pickedChallenge);
    setEditChallengeVisible(true);
  };

  const handleDelete = useCallback(
    id => {
      setDeleteError('');
      challengeService
        .deleteChallegne(id)
        .then(() => {
          setChallengeToDelete(null);
          getAllChallenges();
        })
        .catch(err => {
          setDeleteError(err.response.data.error.message);
        });
    },
    [getAllChallenges],
  );

  return (
    <PageTemplate>
      <StyledTopSection>
        <Heading size="xxl">Zarządzanie wyzwaniami</Heading>
        <Button onClick={toggleNewchallengePanel}>
          <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} /> Dodaj wyzwanie
        </Button>
      </StyledTopSection>
      {!isLoadingChallenges && errorChallenges && <ErrorPopup fixed>{errorChallenges.message}</ErrorPopup>}
      {!isLoadingProducts && errorProducts && <ErrorPopup fixed>{errorProducts.message}</ErrorPopup>}
      {isLoadingChallenges ? (
        <Loading />
      ) : (
        <ChallengesTable
          handleEdit={openEditchallengePanel}
          handleDelete={setChallengeToDelete}
          data={challengesData}
          headers={headers}
          offset={0}
        />
      )}
      <SidePanel toggleClose={closeEditchallengePanel} isVisible={isEditChallengeVisible}>
        {currentChallenge && (
          <EditChallenge
            products={activeProducts}
            currentChallenge={currentChallenge}
            refetchChallenges={getAllChallenges}
            closePanel={closeEditchallengePanel}
          />
        )}
      </SidePanel>
      <SidePanel toggleClose={toggleNewchallengePanel} isVisible={isNewchallengeVisible}>
        <AddChallenge
          products={activeProducts}
          refetchChallenges={getAllChallenges}
          closePanel={toggleNewchallengePanel}
        />
      </SidePanel>
      {challengeToDelete && (
        <SuccessPopup fixed>
          <span>Czy na pewno chcesz usunąć wyzwanie {challengeToDelete.name} ?</span>
          {deleteError !== '' && (
            <span style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{deleteError}</span>
          )}
          <div className="buttons">
            <Button
              secondary
              onClick={() => {
                setDeleteError('');
                setChallengeToDelete(null);
              }}
            >
              Anuluj
            </Button>
            <Button onClick={() => handleDelete(challengeToDelete.id)}>
              {deleteError ? 'Spróbuj ponownie' : 'Usuń'}
            </Button>
          </div>
        </SuccessPopup>
      )}
    </PageTemplate>
  );
};

AdminChallengesPage.defaultProps = {};

AdminChallengesPage.propTypes = {
  // Props
  isLoadingProducts: PropTypes.bool.isRequired,
  isLoadingChallenges: PropTypes.bool.isRequired,
  productsData: PropTypes.instanceOf(Array).isRequired,
  challengesData: PropTypes.instanceOf(Array).isRequired,
  errorProducts: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  errorChallenges: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  currentChallenge: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]).isRequired,
  // Actions
  getAllChallenges: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  clearCurrentChallenge: PropTypes.func.isRequired,
  setCurrentChallenge: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  productsData: state.products.data,
  isLoadingProducts: state.products.isLoading,
  errorProducts: state.products.error,
  challengesData: state.challenge.challenges.data,
  isLoadingChallenges: state.challenge.challenges.isLoading,
  errorChallenges: state.challenge.challenges.error,
  currentChallenge: state.challenge.currentChallenge,
});

const mapDispatchToProps = {
  getAllChallenges,
  getAllProducts,
  clearCurrentChallenge,
  setCurrentChallenge,
};

const StyledTopSection = styled(TopSection)`
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.mobileSmall}) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdminChallengesPage);
