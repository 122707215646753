import makeRequest from 'services/makeRequest';

const GET_PUBLIC_OPTIONS_URL = '/app-settings/public';

const getPublicOptions = () =>
  makeRequest({
    method: 'get',
    url: GET_PUBLIC_OPTIONS_URL,
  });

export const optionsService = {
  getPublicOptions,
};
