/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { createLink } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUrl } from 'utils/hooks';
import { get24Leads } from 'redux/leadP24';
import { TableSorter, SortItem, BottomItem, BottomSection, TopSection } from 'components/layout/Tables/TableComponents';
import { p24LeadService } from 'services/leadP24';
import PageTemplate from 'templates/PageTemplate';
import Button from 'components/UI/Button';
import DateFromTo from 'components/UI/DateFromTo';
import Select from 'components/UI/Select';
import SidePanel from 'components/layout/SidePanel';
import ExtendedTable from 'components/layout/Tables/ExtendedTable';
import Pagination from 'components/UI/Pagination';
import Portal from 'components/logic/Portal';
import SuccessPopup from 'components/layout/SuccessPopup';
import Heading from 'components/UI/Heading';
import Loading from 'components/UI/Loading';
import P24LeadForm from 'components/forms/P24LeadForm';
import { useLocation } from 'react-router-dom';
import { p24OffersOptions } from 'config/constants';

const handleMapOffer = (_fieldName, fieldValue) => {
  return fieldValue ? p24OffersOptions.find((o) => o.value === fieldValue)?.display || '-' : '-';
};

const LeadsP24Page = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const userRole = useSelector((s) => s.user.data.role.slug);

  const isLoading = useSelector((s) => s.p24Leads.allP24Leads.isLoading);
  const leadsData = useSelector((s) => s.p24Leads.allP24Leads.data);
  const leadsCount = useSelector((s) => s.p24Leads.allP24Leads.count);
  const totalPages = useSelector((s) => s.p24Leads.allP24Leads.totalPages);
  const currentPage = useSelector((s) => s.p24Leads.allP24Leads.currentPage);

  const [exporting, setExporting] = useState(false);
  const [exportingError, setExportingError] = useState(null);
  const [isNewLeadVisible, setNewLeadVisible] = useState(false);

  // const [search, setSearch] = useUrl('search');
  const [leadFilterParams, setLeadFilterParams] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useUrl('page');
  const [limit, setLimit] = useUrl('limit');
  const [date, setDate] = useUrl('date');

  const toggleNewLeadPanel = () => setNewLeadVisible(!isNewLeadVisible);
  const handleLimit = (e) => setLimit(e.target.value);

  const exportLeads = useCallback(() => {
    setExporting(true);
    setExportingError(null);

    p24LeadService
      .exportP24LeadsExcel(leadFilterParams)
      .then((res) => {
        createLink(res, 'p24leads');
        setExporting(false);
      })
      .catch((err) => {
        setExportingError('Coś poszło nie tak. Skontaktuj się z administratorem.');
        console.log(err);
      });
  }, [leadFilterParams]);

  const cancelExport = () => {
    p24LeadService.cancelExport();
    setExporting(false);
  };

  const getAllP24Leads = useCallback(() => {
    const queryData = queryString.parse(location.search);
    if (queryData.date_to && queryData.date_from) {
      queryData.startDate = queryData.date_from;
      queryData.endDate = queryData.date_to;
      delete queryData.date_from;
      delete queryData.date_to;
      setLeadFilterParams({ startDate: queryData.startDate, endDate: queryData.endDate });
    } else {
      setLeadFilterParams(null);
    }
    dispatch(get24Leads(queryData));
  }, [dispatch, location.search]);

  useEffect(() => {
    getAllP24Leads();
  }, [getAllP24Leads]);

  const headers = [
    { displayName: 'Zgłoszony', property: 'createdAt', type: 'date-time', class: 'wider' },
    { displayName: 'NIP', property: 'nip' },
    { displayName: 'Imię i nazwisko', property: 'fullName' },
    { displayName: 'E-mail', property: 'email' },
    { displayName: 'Telefon', property: 'phone' },
    { displayName: 'Oferta', property: 'offer', mapValue: handleMapOffer },
    ['admin'].includes(userRole) && {
      displayName: 'Źródło',
      property: 'source',
    },
    {
      displayName: 'URL',
      property: 'www',
    },
    ['admin'].includes(userRole) && {
      displayName: 'Zgłosił',
      property: 'ph.fullName',
    },
  ].filter(Boolean);

  return (
    <PageTemplate>
      <TopSection>
        {['merchantPep'].includes(userRole) && (
          <Button onClick={toggleNewLeadPanel}>
            <FontAwesomeIcon icon="plus-circle" style={{ marginRight: '6px' }} />
            Dodaj lead do Przelewy24
          </Button>
        )}
        <TableSorter>
          <SortItem>
            <span>Okres leadowy</span>
            <DateFromTo handleDate={setDate} date={date} />
          </SortItem>
        </TableSorter>
      </TopSection>
      <ExtendedTable
        data={leadsData}
        headers={headers}
        offset={(currentPage - 1) * (limit || 20)}
        isLoading={isLoading}
      />
      {['admin'].includes(userRole) && (
        <div style={{ marginBottom: '20px' }}>
          <Button small secondary disabled={!leadsData.length} onClick={exportLeads}>
            Eksportuj leady
          </Button>
        </div>
      )}
      <BottomSection>
        <BottomItem>Wszystkich leadów: {leadsCount}</BottomItem>
        <Pagination allowPageSet currentPage={currentPage} maxPages={totalPages} handlePage={setPage} />
        <BottomItem>
          <span>Wyświetlaj na stronie:</span>
          <Select small value={limit || ''} onChange={handleLimit}>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Select>
        </BottomItem>
      </BottomSection>
      <SidePanel small toggleClose={toggleNewLeadPanel} isVisible={isNewLeadVisible}>
        <P24LeadForm toggleClose={toggleNewLeadPanel} refetch={getAllP24Leads} />
      </SidePanel>
      <Portal>
        {exporting && (
          <SuccessPopup fixed>
            {exportingError ? (
              <>
                <Heading>{exportingError}</Heading>
                <div className="buttons">
                  <Button
                    onClick={() => {
                      setExporting(false);
                      setExportingError(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Heading>Eksportuje leady...</Heading>
                <p>
                  Ta operacja może potrwać nawet kilka minut.
                  <br />
                  Nie zamykaj okna / karty przeglądarki.
                </p>
                <Loading />
                <div className="buttons">
                  <Button onClick={cancelExport}>Anuluj</Button>
                </div>
              </>
            )}
          </SuccessPopup>
        )}
      </Portal>
    </PageTemplate>
  );
};

LeadsP24Page.defaultProps = {};
LeadsP24Page.propTypes = {};

export default LeadsP24Page;
