import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'routes';
import { colors, breakpoints } from 'theme/styles';
import { ReactComponent as UsersIcon } from 'assets/icons/admin-users.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as MarketingIcon } from 'assets/icons/marketing.svg';
import { ReactComponent as Przelewy24Logo } from 'assets/icons/przelewy24.svg';
import PageTemplate from 'templates/PageTemplate';
import Heading from 'components/UI/Heading';

const AdminGuestPage = () => {
  return (
    <PageTemplate>
      <CardsWrapper>
        <Card to={routes.adminCompanies}>
          <CompanyIcon />
          <Heading centered> Zarządzanie partnerami </Heading>
        </Card>
        <Card to={routes.adminUsers}>
          <UsersIcon />
          <Heading centered>Zarządzanie użytkownikami</Heading>
        </Card>
        <Card to={routes.marketing}>
          <MarketingIcon />
          <Heading centered>Materiały marketingowe</Heading>
        </Card>
        <Card to={routes.przelewy24}>
          <Przelewy24Logo />
          <Heading centered>Leady do Przelewy24</Heading>
        </Card>
      </CardsWrapper>
    </PageTemplate>
  );
};

AdminGuestPage.defaultProps = {};

AdminGuestPage.propTypes = {};

export default AdminGuestPage;

// styled components

const CardsWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Card = styled(Link)`
  display: block;
  height: 171px;
  width: 260px;
  margin: 10px;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  background: ${colors.white};
  border-radius: 14px;
  box-shadow: 0px 3px 15px #00000010;
  text-decoration: none;

  img {
    height: 54px;
    margin-bottom: 10px;
  }

  svg {
    height: 54px;
    width: auto;
    margin-bottom: 10px;
    fill: ${colors.orange};
    color: ${colors.orange};
    transition: fill 0.3s, color 0.3s;
  }

  &:hover svg {
    fill: ${colors.red};
    color: ${colors.red};
  }

  @media (max-width: ${breakpoints.adminPage}) {
    width: 100%;
  }
`;