import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import { leadService } from 'services/lead';
import { adminService } from 'services/admin';
import { transferLeadsBulkSchema } from 'utils/validation';
import InputAuto from 'components/UI/InputAuto';
import ErrorLabel from 'components/UI/ErrorLabel';
import Button from 'components/UI/Button';
import useFetchSuggestions from 'hooks/useFetchSuggestions';
import useRequestState from 'hooks/useRequestState';
import usePopup from 'hooks/usePopup';
import { colors } from 'theme/styles';
import { FormWrapperCommon, Columns, FormColumn, FieldHeading } from 'components/forms/FormComponents';
import Checkbox from 'components/UI/Checkbox';
import Radio from 'components/UI/Radio';
import Loading from 'components/UI/Loading';

const TransferLeadsForm = () => {
  const [{ success, error, loading }, onRequest] = useRequestState();
  const [SuccessPopup, toggleSuccessPopup] = usePopup();
  const [ErrorPopup, toggleErrorPopup] = usePopup({ type: 'error' });

  const [fromUsersList, setFromUsersList] = useState([]);
  const [toUsersList, setToUsersList] = useState([]);
  const [partnersList, fetchPartners] = useFetchSuggestions({ type: 'partners' });

  const handleSubmit = (values, resetForm) => {
    onRequest('start');

    const { transferTo } = values;
    const transferFromJSON = JSON.stringify(values.transferFrom);

    leadService
      .transferLeadBulk({ transferFrom: transferFromJSON, transferTo })
      .then(res => {
        console.log(res);

        resetForm();
        setFromUsersList([]);
        setToUsersList([]);

        const { to, leadCount, from } = res.data;
        let message = '';

        if (from.length === 1) {
          message = `Przeniesiono ${leadCount} leadów od ${from[0].fullName} do ${to.fullName}.`;
        } else {
          message = `Przeniesiono ${leadCount} leadów od ${from.length} użytkowników do ${to.fullName}.`;
        }

        onRequest('success', message);
        toggleSuccessPopup();
      })
      .catch(err => {
        console.dir(err);
        onRequest('error', err?.response?.data?.error?.message || 'Coś poszło nie tak. Spróbuj ponownie.');
        toggleErrorPopup();
      });
  };

  const handleAfterSelect = (id, side) => {
    adminService
      .getCompanyUsers(id)
      .then(res => {
        if (side === 'from') {
          setFromUsersList(res.data);
        } else {
          setToUsersList(res.data);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <FormWrapperCommon>
      {loading && <Loading absolute />}
      <SuccessPopup fixed title="Transfer zakończony sukcesem">
        <p>{success}</p>
        <div className="buttons">
          <Button onClick={toggleSuccessPopup}>Ok</Button>
        </div>
      </SuccessPopup>
      <ErrorPopup fixed title="Wystąpił problem podczas transferu">
        <p>{error}</p>
        <div className="buttons">
          <Button onClick={toggleErrorPopup}>Spróbuj ponownie</Button>
        </div>
      </ErrorPopup>
      <Formik
        initialValues={{
          transferFromPartner: '',
          transferFrom: [],
          transferToPartner: '',
          transferTo: '',
        }}
        validationSchema={transferLeadsBulkSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, resetForm);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
            <Columns>
              <FormColumn>
                <FieldHeading size="m">Transferuj leady od:</FieldHeading>
                <InputAuto
                  suggestions={partnersList}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onlyFromList
                  pickProp="id"
                  name="transferFromPartner"
                  type="text"
                  display={['name']}
                  onBlur={handleBlur}
                  onChange={e => fetchPartners(e.target.value)}
                  afterSelect={id => handleAfterSelect(id, 'from')}
                  label="Partner"
                  value={values.transferFromPartner || ''}
                  error={touched.transferFromPartner && errors.transferFromPartner}
                />
                <ErrorMessage component={ErrorLabel} name="transferFrom" />
                {fromUsersList.length > 0 && (
                  <FieldArray
                    name="transferFrom"
                    render={arrayHelpers => (
                      <>
                        <UsersList>
                          {fromUsersList.map(item => (
                            <Item key={item.id}>
                              <Checkbox
                                name="transferFrom"
                                onChange={e => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(item.id);
                                  } else {
                                    const idx = values.transferFrom.indexOf(item.id);
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                                onBlur={handleBlur}
                                label={item.fullName}
                                value={item.id}
                                checked={values.transferFrom.includes(item.id)}
                              />
                            </Item>
                          ))}
                        </UsersList>
                        <ButtonWrapper>
                          <Button
                            type="button"
                            small
                            secondary
                            onClick={() => {
                              setFieldValue(
                                'transferFrom',
                                fromUsersList.map(u => u.id),
                              );
                            }}
                          >
                            Zaznacz wszystko
                          </Button>
                          <Button
                            type="button"
                            small
                            secondary
                            onClick={() => {
                              setFieldValue('transferFrom', []);
                            }}
                          >
                            Odznacz wszystko
                          </Button>
                        </ButtonWrapper>
                      </>
                    )}
                  />
                )}
                {fromUsersList.length === 0 && values.transferFromPartner && (
                  <p>Partner nie posiada przypisanych użytkowników</p>
                )}
              </FormColumn>
              <FormColumn>
                <FieldHeading size="m">Transferuj leady do:</FieldHeading>
                <InputAuto
                  suggestions={partnersList}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onlyFromList
                  pickProp="id"
                  name="transferToPartner"
                  type="text"
                  display={['name']}
                  onBlur={handleBlur}
                  onChange={e => fetchPartners(e.target.value)}
                  afterSelect={id => handleAfterSelect(id, 'to')}
                  label="Partner"
                  value={values.transferToPartner || ''}
                  error={touched.transferToPartner && errors.transferToPartner}
                />
                <ErrorMessage component={ErrorLabel} name="transferTo" />
                {toUsersList.length > 0 && (
                  <UsersList>
                    {toUsersList.map(item => (
                      <Item key={item.id}>
                        <Radio
                          name="transferTo"
                          value={item.id}
                          onChange={() => setFieldValue('transferTo', item.id)}
                          label={item.fullName}
                          checked={values.transferTo === item.id}
                        />
                      </Item>
                    ))}
                  </UsersList>
                )}
                {toUsersList.length === 0 && values.transferToPartner && (
                  <p>Partner nie posiada przypisanych użytkowników</p>
                )}
              </FormColumn>
            </Columns>
            <Button style={{ margin: '40px auto 0', width: '100%', maxWidth: '200px' }} type="submit">
              Transferuj
            </Button>
          </Form>
        )}
      </Formik>
    </FormWrapperCommon>
  );
};

TransferLeadsForm.defaultProps = {};

TransferLeadsForm.propTypes = {};

export default TransferLeadsForm;

const UsersList = styled.ul`
  list-style: none;
  margin: 20px 0;
  padding: 0;

  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;

const Item = styled.li`
  margin-right: 20px;

  label {
    padding: 5px;
    margin: 0;
  }

  &:first-of-type {
    margin-top: 10px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.lighterGray};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;

  ${Button} + ${Button} {
    margin-left: 20px;
  }
`;
