import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'theme/styles';
import CloseButton from 'components/UI/CloseButton';

const SidePanel = ({ toggleClose, isVisible, children, small }) => {
  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'unset';
  }, [isVisible]);

  return (
    <Wrapper visible={isVisible}>
      <Container small={small} isVisible={isVisible}>
        <CloseButton onClick={toggleClose} />
        <InnerWrapper small={small}>{children}</InnerWrapper>
      </Container>
    </Wrapper>
  );
};
SidePanel.defaultProps = {
  small: false,
};

SidePanel.propTypes = {
  toggleClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  small: PropTypes.bool,
};

export default SidePanel;

const Wrapper = styled.div`
  width: ${({ visible }) => (visible ? '100%' : 0)};
  height: 100%;
  background-color: ${colors.backgroundTransparent};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  overflow-y: hidden;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: calc(100% - 63px);
  position: fixed;
  left: 0;
  top: 63px;
  width: ${({ small }) =>
    small ? 'calc(600px + (50vw - 740px))' : 'calc(900px + (50vw - 740px))'};
  min-width: ${({ small }) => (small ? '600px' : '900px')};
  box-shadow: 10px 0px 20px ${colors.backgroundTransparent};
  transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(-110%)')};
  background: ${colors.lighterGray};
  transition: transform 0.4s ease-out;
  z-index: 30;
  overflow-y: hidden;
  overflow-x: hidden;

  @media (max-width: 900px) {
    justify-content: flex-start;
    width: 100%;
    max-width: ${({ small }) => small && '600px'};
    min-width: 0;
  }
  @media (max-width: 768px) {
    max-width: unset;
  }
`;

const InnerWrapper = styled.div`
  width: ${({ small }) => (small ? '600px' : '900px')};
  height: 100%;
  /* flex-grow: 1; */
  overflow-y: auto;
  padding: 40px 80px 40px 40px;
  position: relative;
  
  /* @media (max-width: 900px) {
    padding-bottom: 100px;
  } */

  @media (max-width: 768px) {
    padding: 40px;
    width: 100%;
  }

  @media (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;