import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'theme/styles';
import { ErrorMessage } from 'formik';
import { Block } from 'components/UI/Structure';
import { FieldHeading, FlexFields } from 'components/forms/FormComponents';
import { leadService } from 'services/lead';
import { download } from 'utils/helpers';
import Input from 'components/UI/Input';
import ErrorLabel from 'components/UI/ErrorLabel';
import Radio from 'components/UI/Radio';
import options from 'components/forms/LeadMerchant/others/config';
import Tooltip from 'components/UI/Tooltip';
import FileDropzone from 'components/UI/FileDropzone';
import ResetButton from 'components/UI/ResetButton';
import Select from 'components/UI/Select';
import matrixArr from './others/configAML';
import defaultInitialValues, { termsWithoutPbg } from './others/initialValues';

const getLegalFormConfig = (values) => {
  let legalForm;
  let legalFormObj;

  if (values.version === 'AML') {
    legalForm = values.settlements.legalFormAML;
    legalFormObj = matrixArr.find((opt) => opt.value === legalForm) || false;
  } else {
    legalForm = values.settlements.legalForm;
    legalFormObj = options.legalFormOptions.find((opt) => opt.value === legalForm) || false;
  }

  return legalFormObj;
};

const LeadMerchantStep2 = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  validateForm,
  viewMode,
  debugMode,
  initialValues,
  config,
  // setConfig,
}) => {
  const { id: contractId } = useParams();
  const { settlements: stepValues } = values;
  const { settlements: stepTouched = {} } = touched;
  const { settlements: stepErrors = {} } = errors;

  const [addFileError, setAddFileError] = useState('');
  const periodOptions =
    config.dontMessWithValues && stepValues.period === 1 ? options.periodOptions : options.periodOptionsNo12;

  const subOptions = useMemo(() => {
    return matrixArr.filter((lf) => lf.subOption && lf.subOptionForCodes.includes(stepValues.legalFormAML));
  }, [stepValues.legalFormAML]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    if (stepValues.pbg !== null) validateForm();
  }, [stepValues.pbg, validateForm]);

  const resetFile = useCallback(() => {
    setFieldValue('settlements.agreementFile', initialValues.settlements.agreementFile || '');
    setFieldValue('settlements.agreement', initialValues.settlements.agreement || '');
  }, [setFieldValue, initialValues.settlements.agreementFile, initialValues.settlements.agreement]);

  useEffect(() => {
    if (!getLegalFormConfig(values).agreement) resetFile();
    setFieldValue('contact.agreementFile', initialValues.contact.agreementFile || '');
    setFieldValue('contact.agreement', initialValues.contact.agreement || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepValues.legalForm, stepValues.legalFormAML, resetFile]);

  useEffect(() => {
    if (config.dontMessWithValues) return;
    if (!config.generalPromotion && stepValues.generalPromotion != -1) setFieldValue('settlements.generalPromotion', -1);
    if (stepValues.period === 1) setFieldValue('settlements.period', 2);
  }, [
    config.dontMessWithValues,
    config.generalPromotion,
    setFieldValue,
    stepValues.generalPromotion,
    stepValues.period,
  ]);

  const handlePbgChange = useCallback(
    (val) => {
      setFieldValue('settlements.pbg', val);
      setFieldValue('settlements.pbgPwob', defaultInitialValues.settlements.pbgPwob);

      // // Jeżeli jakiekolwiek warunki indywidualne były ustawione to niczego nie zmieniamy
      // if (
      //   values.terms.commission.individualTermsFee ||
      //   values.terms.commission.individualTermsFixedFee ||
      //   values.terms.margin.individualTermsMargin ||
      //   values.terms.individualTermsOthers ||
      //   values.terms.individualTerminalPinPad ||
      //   values.terms.individualTerminalStationary ||
      //   values.terms.individualTerminalMobile
      // )
      //   return;

      // if (val === 1 && stepValues.period === 1) setFieldValue('settlements.period', 2);
      if (val === 1) {
        // TAK
        setFieldValue('terms', { ...defaultInitialValues.terms });
        // setFieldValue('settlements.pbgPwob2', 0);
        if (config.renderFormPwob) setFieldValue('settlements.pbgPwobOptions', 0);
        setFieldValue('settlements.generalPromotion', -1);
      } else {
        // NIE
        setFieldValue('terms', { ...termsWithoutPbg });
        // setFieldValue('settlements.pbgPwob2', defaultInitialValues.settlements.pbgPwob2);
        if (config.generalPromotion) setFieldValue('settlements.generalPromotion', 0);
        setFieldValue('settlements.pbgPwobOptions', defaultInitialValues.settlements.pbgPwobOptions);
      }
    },
    [setFieldValue, config.renderFormPwob, config.generalPromotion],
  );

  const handlePwobChange = useCallback(
    (val) => {
      setFieldValue('settlements.pbgPwob', val);

      if (val > 0) {
        // Ustaw pakiet: silver = 2
        setFieldValue('settlements.serviceType', 2);
      }
    },
    [setFieldValue],
  );

  const handleAgreementDownload = useCallback(
    (fileName) => {
      leadService
        .getOnboardingAgreementFile(contractId)
        .then((res) => {
          download(res, fileName);
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    [contractId],
  );

  return (
    <>
      <Block noInteract={viewMode}>
        <FieldHeading mb size="m" style={{ marginBottom: '-20px' }}>
          Numer rachunku bankowego
        </FieldHeading>
        <FlexFields cols={2}>
          <div>
            <Input
              disabled={viewMode}
              name="settlements.bankAccount"
              type="text"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue('settlements.bankAccount', stepValues.bankAccount.trim());
              }}
              label=""
              value={stepValues.bankAccount || ''}
              error={stepTouched.bankAccount && stepErrors.bankAccount}
            />
            <ErrorMessage component={ErrorLabel} name="settlements.bankAccount" />
          </div>
        </FlexFields>
        <FlexFields cols={2}>
          {/* <div>
            <FieldHeading medium size="m">
              Poziom rozliczeń
            </FieldHeading>
            <FlexFields autoFit>
              {options.billingSystemOptions.map(opt => (
                <Radio
                  disabled={viewMode}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name="settlements.billingSystem"
                  value={opt.value}
                  onChange={() => setFieldValue('settlements.billingSystem', opt.value)}
                  label={opt.display}
                  checked={stepValues.billingSystem === opt.value}
                />
              ))}
            </FlexFields>
          </div> */}
          {/* <div>
            <FieldHeading medium size="m">
              Poziom fakturowania
            </FieldHeading>
            <FlexFields autoFit>
              {options.invoicingSystemOptions.map(opt => (
                <Radio
                  disabled={viewMode}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name="settlements.invoicingSystem"
                  value={opt.value}
                  onChange={() => setFieldValue('settlements.invoicingSystem', opt.value)}
                  label={opt.display}
                  checked={stepValues.invoicingSystem === opt.value}
                />
              ))}
            </FlexFields>
          </div> */}
          <div>
            <FieldHeading medium size="m">
              Program Polska Bezgotówkowa
              <Tooltip width={240}>
                Wskazanie wartości <span className="color-orange">Tak</span> skutkować będzie zgłoszeniem klienta do
                Programu Polska Bezgotówkowa
              </Tooltip>
            </FieldHeading>
            <FlexFields autoFit>
              {options.pbgOptions.map((opt) => (
                <Radio
                  disabled={viewMode}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name="settlements.pbg"
                  value={opt.value}
                  onChange={() => handlePbgChange(opt.value)}
                  label={opt.display}
                  checked={stepValues.pbg === opt.value}
                />
              ))}
            </FlexFields>
            <ErrorMessage style={{ marginTop: '4px' }} component={ErrorLabel} name="settlements.pbg" />
          </div>
          <div>
            <FieldHeading medium size="m">
              Poziom serwisu
              <Tooltip width={240}>
                Opłaty miesięczne za poziom serwisu
                <br />
                Economic - 0,00 zł
                <br />
                Silver - 2,90 zł
                <br />
                Gold - 5,90 zł
              </Tooltip>
            </FieldHeading>
            <FlexFields autoFit>
              {options.serviceTypeOptions.map((opt) => (
                <Radio
                  disabled={viewMode || stepValues.pbgPwob > 0}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name="settlements.serviceType"
                  value={opt.value}
                  onChange={() => setFieldValue('settlements.serviceType', opt.value)}
                  label={opt.display}
                  checked={stepValues.serviceType === opt.value}
                />
              ))}
            </FlexFields>
          </div>

          {stepValues.pbg === 1 && stepValues.pbgPwob !== 0 && (
            <div style={{ flex: '0 1 100%' }}>
              <FieldHeading medium size="m">
                Promocja PWOB
              </FieldHeading>
              <FlexFields autoFit>
                {options.pbgPromoPwobOptions.map((opt) => (
                  <Radio
                    disabled={viewMode}
                    key={opt.value}
                    name="settlements.pbgPwob"
                    value={opt.value}
                    onChange={() => handlePwobChange(opt.value)}
                    label={opt.display}
                    checked={stepValues.pbgPwob === opt.value}
                  />
                ))}
              </FlexFields>
            </div>
          )}

          {config.dontMessWithValues && stepValues.pbg === 1 && stepValues.pbgPwob2 !== -1 && (
            <div style={{ flex: '0 1 100%' }}>
              <FieldHeading medium size="m">
                Promocja 12 m-cy za 1 zł po PWOB
              </FieldHeading>
              <FlexFields autoFit>
                {options.pbgPromoPwobTakNieOptions.map((opt) => (
                  <Radio
                    disabled={viewMode}
                    key={opt.value}
                    name="settlements.pbgPwob2"
                    value={opt.value}
                    onChange={() => setFieldValue('settlements.pbgPwob2', opt.value)}
                    label={opt.display}
                    checked={stepValues.pbgPwob2 === opt.value}
                  />
                ))}
              </FlexFields>
            </div>
          )}

          {((config.renderFormPwob && stepValues.pbgPwobOptions !== -1) ||
            (config.dontMessWithValues && stepValues.pbgPwobOptions !== -1)) && (
            <div style={{ flex: '0 1 100%' }}>
              <FieldHeading medium size="m">
                Promocja Polska Bezgotówkowa
              </FieldHeading>
              <FlexFields autoFit>
                {options.pbgPromoPwobTakNieOptions.map((opt) => (
                  <Radio
                    disabled={viewMode}
                    key={opt.value}
                    name="settlements.pbgPwobOptions"
                    value={opt.value}
                    onChange={() => setFieldValue('settlements.pbgPwobOptions', opt.value)}
                    label={opt.display}
                    checked={stepValues.pbgPwobOptions === opt.value}
                  />
                ))}
              </FlexFields>
            </div>
          )}

          {((config.generalPromotion && stepValues.generalPromotion !== -1) ||
            (config.dontMessWithValues && stepValues.generalPromotion !== -1)) && (
            <div style={{ flex: '0 1 100%' }}>
              <FieldHeading medium size="m">
                Promocja ogólna
              </FieldHeading>
              <FlexFields autoFit>
                {options.generalPromotionOptions.map((opt) => (
                  <Radio
                    disabled={viewMode}
                    key={opt.value}
                    name="settlements.generalPromotion"
                    value={opt.value}
                    onChange={() => setFieldValue('settlements.generalPromotion', opt.value)}
                    label={opt.display}
                    checked={stepValues.generalPromotion === opt.value}
                  />
                ))}
              </FlexFields>
            </div>
          )}

          <div>
            <FieldHeading medium size="m">
              Ulga instalacyjna dla okresu współpracy powyżej
            </FieldHeading>
            <FlexFields autoFit>
              {periodOptions.map((opt) => (
                <Radio
                  disabled={viewMode}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name="settlements.period"
                  value={opt.value}
                  onChange={() => setFieldValue('settlements.period', opt.value)}
                  label={opt.display}
                  checked={stepValues.period === opt.value}
                />
              ))}
            </FlexFields>
          </div>
          <div>
            <FieldHeading medium size="m">
              Rodzaj działalności
            </FieldHeading>
            <FlexFields autoFit>
              {options.businessLineOptions.map((opt) => (
                <Radio
                  disabled={viewMode}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name="settlements.businessLine"
                  value={opt.value}
                  onChange={() => setFieldValue('settlements.businessLine', opt.value)}
                  label={opt.display}
                  checked={stepValues.businessLine === opt.value}
                />
              ))}
            </FlexFields>
          </div>
          <div>
            <FieldHeading medium size="m">
              Forma prawna
            </FieldHeading>
            <FlexFields autoFit>
              <div>
                <Select
                  title={matrixArr.find((el) => el.value === stepValues.legalFormAML)?.name || ''}
                  disabled={viewMode}
                  style={{ marginTop: '0' }}
                  name="legalFormAML"
                  value={stepValues.legalFormAML || ''}
                  onChange={(e) => setFieldValue('settlements.legalFormAML', Number(e.target.value))}
                  onBlur={handleBlur}
                  label="Wybierz z listy"
                >
                  <option value="-1">&nbsp;</option>
                  {matrixArr.map((opt) => (
                    <option style={{ display: opt.subOption ? 'none' : 'block' }} key={opt.value} value={opt.value}>
                      {opt.name}
                    </option>
                  ))}
                </Select>
                <ErrorMessage component={ErrorLabel} name="settlements.legalFormAML" />
              </div>
              {subOptions.length > 0 && (
                <div>
                  <Select
                    preFilled
                    disabled={viewMode}
                    style={{ marginTop: '0' }}
                    name="legalFormAMLDetails"
                    value={stepValues.legalFormAMLDetails || ''}
                    onChange={(e) => setFieldValue('settlements.legalFormAML', Number(e.target.value))}
                    onBlur={handleBlur}
                    label="Specyzuj forme prawną"
                  >
                    <option value="-1">Nie dotyczy</option>
                    {subOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.name}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage component={ErrorLabel} name="settlements.legalFormAMLDetails" />
                </div>
              )}
            </FlexFields>
          </div>
          {/* <div>
            <FieldHeading medium size="m">
              Forma prawna
            </FieldHeading>
            <FlexFields cols={2} style={{ maxWidth: '460px' }}>
              {options.legalFormOptions.map(opt => (
                <Radio
                  disabled={viewMode}
                  tooltip={opt.tooltip}
                  key={opt.value}
                  name="settlements.legalForm"
                  value={opt.value}
                  onChange={() => setFieldValue('settlements.legalForm', opt.value)}
                  label={opt.display}
                  checked={stepValues.legalForm === opt.value}
                />
              ))}
            </FlexFields>
            <ErrorMessage style={{ marginTop: '4px' }} component={ErrorLabel} name="settlements.legalForm" />
          </div> */}
          {getLegalFormConfig(values).agreement && (
            <div>
              <FieldHeading medium size="m">
                Umowa spółki cywilnej
                <Tooltip width={240}>
                  Umowa <span className="color-orange">spółki cywilnej</span> musi zostać zweryfikowana - plik
                  maksymalnie 10mb
                </Tooltip>
              </FieldHeading>
              <FileDropzone
                handleBlur={handleBlur}
                onDrop={(acceptedFiles, fileRejections) => {
                  fileRejections.forEach((file) => {
                    file.errors.forEach((err) => {
                      if (err.code === 'file-too-large') setAddFileError('Plik jest za duży, maksymalnie 10mb');
                      if (err.code === 'file-invalid-type')
                        setAddFileError('Plik ma niepoprawny format, tylko pliki PDF');
                    });
                  });

                  if (acceptedFiles.length > 0) {
                    const file = acceptedFiles[0];
                    setAddFileError('');
                    setFieldValue('settlements.agreementFile', file);
                    setFieldValue('settlements.agreement', {
                      name: file.name,
                      download: false,
                    });
                  }
                }}
                disabled={viewMode}
                name="settlements.agreementFile"
                accept="application/pdf"
                label="Dodaj umowę"
                hide={Boolean(values.settlements.agreement)}
              />
              {values.settlements.agreement && values.settlements.agreement.name && (
                <>
                  <File
                    download={values.settlements.agreement.download}
                    onClick={() =>
                      values.settlements.agreement.download
                        ? handleAgreementDownload(values.settlements.agreement.name)
                        : undefined
                    }
                  >
                    <FontAwesomeIcon fixedWidth icon="file-download" />
                    <span>{values.settlements.agreement.name}</span>
                  </File>
                  {!values.settlements.agreement.download && (
                    <ResetButton type="button" onClick={resetFile}>
                      Usuń
                    </ResetButton>
                  )}
                </>
              )}
              {addFileError && <ErrorLabel style={{ marginTop: '2rem' }}>{addFileError}</ErrorLabel>}
              <ErrorMessage style={{ marginTop: '2rem' }} component={ErrorLabel} name="settlements.agreement" />
            </div>
          )}
        </FlexFields>
      </Block>
      {debugMode && (
        <>
          <Block>
            <div>
              <pre>Version: {JSON.stringify(values.version || '???', null, 4)}</pre>
              <pre>Version Number: {JSON.stringify(values.versionNumber || '???', null, 4)}</pre>
            </div>
          </Block>
          <Block>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <pre>Step values: {JSON.stringify(stepValues, null, 4)}</pre>
              <pre>Errors: {JSON.stringify(errors, null, 4)}</pre>
              <pre>Touched: {JSON.stringify(touched, null, 4)}</pre>
            </div>
          </Block>
        </>
      )}
    </>
  );
};

LeadMerchantStep2.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  config: {},
  handleChange: () => {},
  handleBlur: () => {},
  setFieldValue: () => {},
  validateForm: () => {},
  // setConfig: () => {},
  debugMode: false,
  initialValues: {},
};

LeadMerchantStep2.propTypes = {
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  initialValues: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  validateForm: PropTypes.func,
  // setConfig: PropTypes.func,
  viewMode: PropTypes.bool.isRequired,
  debugMode: PropTypes.bool,
};

export default LeadMerchantStep2;

const File = styled.div`
  &[download] {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    color: ${colors.orange};
    margin-right: 0.5rem;
  }
`;
